import React, { Component } from "react";
import { CheckCircle, Clock, Eye } from "react-feather";
import { Link } from "react-router-dom";
import Pagination from "../../../components/Pagination";
export default class Table extends Component {
  render() {
    const { onValidate, loading, data, currentPage, onSelectedItem, onPageChange } =
      this.props;

    return (
      <div>
        <div className="table-responsive table-striped">
          <table className="table">
            <thead className="text-uppercase">
              <tr>
                <th>Aksi</th>
                <th>Validasi Indigenous</th>
                <th>Nama Faskes</th>
                <th>Nama Pasien</th>
                <th>Asal Desa Penularan</th>
                <th>Tanggal Kunjungan</th>
                <th>Jenis Parasit</th>
                <th>Keterangan</th>
              </tr>
            </thead>
            <tbody>
              {!loading && data && data.pe_cases && data.pe_cases.length > 0 ? (
                data.pe_cases.map((e) => {
                  return (
                    <tr key={e.id}>
                      <td>
                        {e.indigenous_status ? (
                          <button
                            className="btn btn-outline-success btn-sm font-weight-bold mr-3"
                            onClick={() => {
                              onSelectedItem(e);
                              onValidate();
                            }}
                            style={{ cursor: "pointer" }}
                            data-toggle="modal"
                            data-target="#validateModal"
                          >
                            <CheckCircle size={15} /> Sudah divalidasi
                          </button>
                        ) : (
                          <button
                            className="btn btn-outline-warning btn-sm font-weight-bold mr-3"
                            onClick={() => {
                              onSelectedItem(e);
                              onValidate();
                            }}
                            style={{ cursor: "pointer" }}
                            data-toggle="modal"
                            data-target="#validateModal"
                          >
                            <Clock size={15} /> Validasi data
                          </button>
                        )}

                        <Link
                          to={`/detail-indigenous/${e.id}`}
                          className="btn btn-outline-info btn-sm font-weight-bold"
                        >
                          <Eye size={15} /> Detail
                        </Link>
                      </td>
                      <td>{e.indigenous_status}</td>
                      <td>{e.fasyankes_id?.split("|")[1]}</td>
                      <td>{e.name}</td>
                      <td>{e.urbanvillage_id?.split("|")[1]}</td>
                      <td>{e.patient_visite_date}</td>
                      <td>{e.parasyte?.toString()}</td>
                      <td>{e.indigenous_desc}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={14} className="text-center font-weight-bold">
                    {loading ? "loading..." : "No Data"}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="row">
            <div className="col-sm-12 col-md-5">
              {`Showing ${data.limit === "undefined" ? 0 : data.limit} of ${
                data.total === "undefined" ? 0 : data.total
              } Data`}
            </div>
            <div className="col-sm-12 col-md-7">
              {!loading && (
                <Pagination
                  style={{ float: "right", marginLeft: 10 }}
                  totalRecords={data.total}
                  pageLimit={data.limit}
                  pageNeighbours={2}
                  currentPage={currentPage}
                  onPageChange={(page, limit) =>
                    onPageChange(page, limit, "Regmal")
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
