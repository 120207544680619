import Button from "components/atoms/Button";
import InputDate from "components/atoms/InputDate";
import InputSelect from "components/atoms/InputSelect";
import TableDataComponent from "components/organisms/TableData";
import moment from "moment";
import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  LogisticInputStockOptions,
  MonthOptions,
  PageMode,
} from "utils/constant";
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import ActionButton from "components/atoms/ActionButton";
import {
  errorToastNotif,
  monthValueConverter,
  setDataToLocalStorage,
  successToastNotif,
} from "utils/common";
import { confirmAlert } from "react-confirm-alert";
import LogisticInputStock from "services/LogisticInputStock";
import { useEffect } from "react";

const LogisticInputStockService = new LogisticInputStock();

const Table = ({ changeMode }) => {
  const [filterState, setFilterState] = useState({
    filter_year: "",
    filter_month: "",
    filter_logistic: "",
  });
  const [logisticState, setLogisticState] = useState({
    page: 1,
    limit: 10,
    data: [],
    totalData: 0,
    currentPage: 1,
    nextPage: null,
    previousPage: null,
  });
  const [isLoading, setIsLoading] = useState(true);

  const { currentUser } = useSelector((state) => state.authReducer);

  const fetchInputLogisticData = async () => {
    setIsLoading(true);
    try {
      const response = await LogisticInputStockService.getAll({
        page: logisticState.page,
        limit: logisticState.limit,
        searchBy: `originator_id,year,month,logistic_type`,
        searchValue: `${currentUser?.id},${filterState.filter_year},${filterState.filter_month},${filterState.filter_logistic}`,
      });

      setLogisticState((prevState) => ({
        ...prevState,
        data: response.logistic_input_stocks,
        limit: response.limit,
        currentPage: response.currentPage,
        totalData: response.total,
        nextPage: response.nextPage,
        previousPage: response.previousPage,
      }));
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchInputLogisticData();
  }, [logisticState.page]);

  const onPageChange = (page) => {
    setLogisticState((prevState) => ({
      ...prevState,
      page: page,
    }));
  };

  const onRemoveData = (id) => {
    confirmAlert({
      title: "Apakah anda yakin ingin menghapus data?",
      message: "Data akan dihapus secara permanen",
      buttons: [
        {
          label: "Ya",
          onClick: async () => {
            await LogisticInputStockService.remove(id)
              .then((res) => {
                fetchInputLogisticData();
                successToastNotif("Data Input logistik berhasil dihapus");
              })
              .catch((err) => {
                console.log(err);
                errorToastNotif(err);
              });
          },
        },
        {
          label: "Tidak",
        },
      ],
    });
  };

  const onEditData = (item) => {
    setDataToLocalStorage("SELECTED_ITEM", JSON.stringify(item));
    changeMode(PageMode.edit);
  };

  const columns = [
    {
      header: "Aksi",
      accessorKey: "action",
      cell: ({ row }) => (
        <div className="button-actions">
          <ActionButton
            onClick={() => onEditData(row.original)}
            btnType={"edit"}
          />
          <ActionButton
            onClick={() => onRemoveData(row.original.id)}
            btnType={"delete"}
          />
        </div>
      ),
    },
    {
      header: "Provinsi",
      accessorKey: "province_id",
      cell: ({ row }) => row.original.province_id ? row.original.province_id?.split("|")[1] : "",
    },
    {
      header: "Kabupaten",
      accessorKey: "district_id",
      cell: ({ row }) => row.original.district_id ? row.original.district_id?.split("|")[1] : "",
    },
    {
      header: "Tahun",
      accessorKey: "year",
    },
    {
      header: "Bulan",
      accessorKey: "month",
      cell: ({ row }) => row.original.month ? monthValueConverter(row.original.month) : "",
    },
    {
      header: "Jenis Logistik",
      accessorKey: "logistic_type",
    },
    {
      header: "Stok",
      accessorKey: "stock",
    },
    {
      header: "Nomor Batch & Sumber Pendanaan",
      accessorKey: "batch_number",
    },
    {
      header: "Tanggal Kadaluwarsa",
      accessorKey: "ed_date",
    },
  ];

  const tableInstance = useReactTable({
    columns,
    data: logisticState?.data,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <>
      <h1 className="page-title">Input Logistik - Input Stok Kab/Kota</h1>
      {/* Filter section start */}
      <div className="card-filter">
        <div className="filter-item">
          <InputDate
            placeholder={"Pilih Tahun"}
            name={"filter_year"}
            dateFormat={"YYYY"}
            onChange={(e) => {
              const selectedYear = moment(e).format("YYYY");
              setFilterState({
                ...filterState,
                filter_year: selectedYear,
              });
            }}
            value={filterState.filter_year}
          />
        </div>
        <div className="filter-item">
          <InputSelect
            placeholder={"Pilih Bulan"}
            data={MonthOptions}
            onChange={(e) => {
              const selectedValue = e ? e.value : "";
              setFilterState({
                ...filterState,
                filter_month: selectedValue,
              });
            }}
            value={MonthOptions.find(
              (item) => item.value === filterState.filter_month
            )}
          />
        </div>
        <div className="filter-item">
          <InputSelect
            placeholder={"Pilih Logistik"}
            data={LogisticInputStockOptions}
            onChange={(e) => {
              const selectedValue = e ? e.value : "";
              setFilterState({
                ...filterState,
                filter_logistic: selectedValue,
              });
            }}
            value={LogisticInputStockOptions.find(
              (item) => item.value === filterState.filter_logistic
            )}
          />
        </div>
        <Button
          variant={"primary"}
          size={"normal"}
          onClick={() => fetchInputLogisticData()}
        >
          Terapkan
        </Button>
      </div>
      {/* Filter section end */}

      {/* Actions */}
      <div className="action-wrapper">
        <div className="action-button">
          {currentUser?.mode === "Online" && (
            <Button
              variant={"primary"}
              size={"normal"}
              onClick={() => changeMode(PageMode.add)}
            >
              Tambah Data Input Stok
            </Button>
          )}
        </div>
      </div>

      <TableDataComponent
        columnLength={columns?.length}
        tableInstance={tableInstance}
        onPageChange={onPageChange}
        loading={isLoading}
        currentPage={logisticState?.currentPage}
        limit={logisticState?.limit}
        nextPage={logisticState?.nextPage}
        previousPage={logisticState?.previousPage}
        total={logisticState?.totalData}
      />
    </>
  );
};

export default Table;
