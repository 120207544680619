import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { PageMode } from "utils/constant";
import TableComponent from "./components/Table";
import FormComponent from "./components/Form";

const CadreMMPComponent = ({ pageMode, changeMode }) => {
  const [selectedItem, setSelectedItem] = useState(null);

  const handlePageModeChange = (page) => {
    changeMode(page);
  };

  const handleEditData = (item) => {
    changeMode(PageMode.edit);
    setSelectedItem(item);
  };

  useEffect(() => {
    document.title = "SISMAL | Data Kader MMP";
  }, []);

  return (
    <>
      {pageMode === PageMode.view && (
        <TableComponent
          handleEditData={handleEditData}
          handlePageModeChange={handlePageModeChange}
        />
      )}
      {(pageMode === PageMode.add || pageMode === PageMode.edit) && (
        <FormComponent
          selectedItem={selectedItem}
          pageMode={pageMode}
          handlePageModeChange={handlePageModeChange}
        />
      )}
    </>
  );
};

export default CadreMMPComponent;
