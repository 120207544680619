import { sum } from "lodash";
import React from "react";
import { monthConverter } from "utils/common";
import { UserLevel } from "utils/constant";

const TableComponent = ({
  currentLevel,
  reportsData,
  tableRef,
  reportInfo,
}) => {
  // const calculateTotal = () => {
  //   if (
  //     currentLevel === UserLevel.National.toLowerCase() ||
  //     currentLevel === UserLevel.Province.toLocaleLowerCase()
  //   ) {
  //     return reportsData.reduce(
  //       (total, item) => {
  //         total.total_faskes += parseInt(item.total_faskes) || 0;
  //         total.total_faskes_zero_report +=
  //           parseInt(item.total_faskes_zero_report) || 0;

  //         return total;
  //       },
  //       {
  //         total_faskes: 0,
  //         total_faskes_zero_report: 0,
  //       }
  //     );
  //   } else {
  //     return reportsData.reduce(
  //       (total, item) =>
  //         total + (item.is_zero_reporting?.toLowerCase() === "ya" ? 1 : 0),
  //       0
  //     );
  //   }
  // };

  let levelName = "";
  if (currentLevel === "national") {
    levelName = "Nasional";
  } else if (currentLevel === "province") {
    levelName = "Provinsi";
  } else if (currentLevel === "district") {
    levelName = "Kabupaten/Kota";
  } else {
    levelName = "Fasyankes";
  }

  let periodeName = "";
  if (reportInfo?.statusPeriode === "yearly") {
    periodeName = "Tahunan";
  } else {
    periodeName = "Bulanan";
  }

  let data = reportInfo?.fasyankes;
  let parts = data?.split(",");
  let faskesName = parts?.map(function (part) {
    return part?.split("|")[1];
  });
  let result = faskesName?.join(", ");

  return (
    <div
      className="table-table-responsive"
      style={{ height: "560px", overflow: "scroll" }}
      ref={tableRef}
    >
      <table>
        <tr style={{ border: "none", padding: 0 }}>
          <th style={{ border: "none", paddingBottom: "0" }}>
            <h3>SISMAL v3, IDENTIFIKASI JENTIK</h3>
          </th>
        </tr>
      </table>
      <table>
        <tr style={{ border: "none" }}>
          <th
            width="200"
            style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}
          >
            Periode Laporan
          </th>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            : {periodeName}
          </th>
        </tr>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            Tahun
          </th>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            : {reportInfo?.periode?.year}
          </th>
        </tr>
        {reportInfo?.statusPeriode === "monthly" && (
          <tr style={{ border: "none" }}>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              Bulan
            </th>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              : {monthConverter(reportInfo?.periode?.month)}
            </th>
          </tr>
        )}
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            Level
          </th>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            : {levelName}
          </th>
        </tr>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            Provinsi
          </th>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            :{" "}
            {!reportInfo?.province
              ? "Semua Provinsi"
              : reportInfo?.province?.split("|")[1]}
          </th>
        </tr>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            Kab/Kota
          </th>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            :{" "}
            {!reportInfo?.district
              ? "Semua Kab/Kota"
              : reportInfo?.district?.split("|")[1]}
          </th>
        </tr>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingTop: 0 }}>Fasyankes</th>
          <th style={{ border: "none", paddingTop: 0 }}>
            : {!reportInfo?.fasyankes ? "Semua Fasyankes" : result}
          </th>
        </tr>
      </table>
      <table>
        <thead>
          <tr>
            <th rowSpan={2}>No.</th>
            <th rowSpan={2}>Provinsi</th>
            <th rowSpan={2}>Kabupaten/Kota</th>
            <th rowSpan={2}>Fasyankes</th>
            <th rowSpan={2}>Desa</th>
            {levelName === "Fasyankes" ? (
              <>
                <th rowSpan={2}>Dusun</th>
                <th rowSpan={2}>Tanggal Survey</th>
                <th rowSpan={2}>Jenis Tempat Perindukan</th>
                <th rowSpan={2}>Jumlah Cidukan</th>
                <th rowSpan={2}>Penemuan Jentik Anopheles (Ya/Tidak)</th>
                <th rowSpan={2}>Fase Jentik</th>
                <th rowSpan={2}>Kepadatan Jentik Per Cidukan</th>
                <th rowSpan={2}>Luas (m²) Tempat Perindukan</th>
                <th rowSpan={2}>Kedalaman (cm) Tempat Perindukan</th>
                <th rowSpan={2}>
                  Adanya tanaman pada tempat perindukan (Ya/Tidak)
                </th>
                <th rowSpan={2}>
                  Adanya sampah pada tempat perindukan (Ya/Tidak)
                </th>
                <th rowSpan={2}>
                  Sifat tempat perindukan (Permanen/Sementara)
                </th>
              </>
            ) : (
              <>
                <th colSpan={10}>Jumlah Tempat Perindukan</th>
                <th rowSpan={2}>
                  Jumlah Kab/Kota ditemukan Jentik Anopheles (Ya/Tidak)
                </th>
                <th rowSpan={2}>
                  Jumlah Kab/Kota dengan Tempat Perindukan Permanen
                </th>
                <th rowSpan={2}>
                  Jumlah Kab/Kota dengan Tempat Perindukan Sementara
                </th>
              </>
            )}
          </tr>
          {!(levelName === "Fasyankes") && (
            <tr>
              <th>Kolam</th>
              <th>Sungai</th>
              <th>Sawah</th>
              <th>Lagun</th>
              <th>Parit</th>
              <th>Bekas Galian</th>
              <th>Rawa</th>
              <th>Bekas Tambak</th>
              <th>Bekas Injakan Hewan</th>
              <th>Lainnya</th>
            </tr>
          )}
        </thead>
        <tbody>
          {reportsData?.length > 0 ? (
            reportsData?.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                {item?.province?
                  <td>{item?.province}</td>
                  :
                  <td>
                    {reportInfo?.province?.split("|")[1]}
                  </td>
                } 
                {item?.district?
                  <td>{item?.district}</td>
                  : (
                    <td>
                      {reportInfo?.district?.split("|")[1]}
                    </td>
                  )}
                {item?.fasyankes ? 
                  <td>{item?.fasyankes}</td>
                  :
                  <td>
                    {result}
                  </td>
                }
                <td>{item?.desa}</td>
                {levelName === "Fasyankes" ? (
                  <>
                    <td>{item?.dusun}</td>
                    <td>{item?.tanggal_survey}</td>
                    <td>{item?.jenis_tempat_perindukan}</td>
                    <td>{item?.total_cidukan}</td>
                    <td>{item?.penemuan_jentik_anopheles}</td>
                    <td>{item?.fase_jentik?.join(", ")}</td>
                    <td>{item?.kepadatan_jentik_per_cidukan}</td>
                    <td>{item?.luas_tempat_perindukan}</td>
                    <td>{item?.kedalaman_tempat_perindukan}</td>
                    <td>{item?.adanya_tanaman_tempat_perindukan}</td>
                    <td>{item?.adaya_sampah_tempat_perindukan}</td>
                    <td>{item?.sifat_tempat_perindukan}</td>
                  </>
                ) : (
                  <>
                    <td>{item?.kolam}</td>
                    <td>{item?.sungai}</td>
                    <td>{item?.sawah}</td>
                    <td>{item?.lagun}</td>
                    <td>{item?.parit}</td>
                    <td>{item?.bekas_galian}</td>
                    <td>{item?.rawa}</td>
                    <td>{item?.bekas_tambak}</td>
                    <td>{item?.bekas_injakan_hewan}</td>
                    <td>{item?.lainnya}</td>
                    <td>
                      {parseInt(item?.total_anopheles_ya) +
                        parseInt(item?.total_anopheles_tidak)}
                    </td>
                    <td>{item?.total_perindukan_permanen}</td>
                    <td>{item?.total_perindukan_sementara}</td>
                  </>
                )}
              </tr>
            ))
          ) : (
            <tr>
              <td>Tidak ada data</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TableComponent;
