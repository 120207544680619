import React from "react";
import { monthConverter } from "utils/common";
import { UserLevel } from "utils/constant";

const TableComponent = ({
  currentLevel,
  reportsData,
  tableRef,
  reportInfo,
}) => {
  const calculateTotal = () => {
    return reportsData.reduce(
      (total, item) => {
        total.total_sasaran_bumil += parseInt(item.sasaran_bumil ?? 0);
        total.total_sasaran_balita += parseInt(item.sasaran_balita ?? 0);
        total.total_bumil_k1 += parseInt(item.bumil_k1 ?? 0);
        total.total_bumil_scren += parseInt(item.bumil_scren ?? 0);
        total.total_bumil_scren_kelambu += parseInt(
          item.bumil_scren_kelambu ?? 0
        );
        total.total_kunj_balita_sakit += parseInt(item.kunj_balita_sakit ?? 0);
        total.total_kunj_balita_sakit_perika_mal += parseInt(
          item.kunj_balita_sakit_perika_mal ?? 0
        );

        return total;
      },
      {
        total_sasaran_bumil: 0,
        total_sasaran_balita: 0,
        total_bumil_k1: 0,
        total_bumil_scren: 0,
        total_bumil_scren_kelambu: 0,
        total_bumil_scren_kelambu: 0,
        total_kunj_balita_sakit: 0,
        total_kunj_balita_sakit_perika_mal: 0,
      }
    );
  };

  let levelName = "";
  if (currentLevel === "national") {
    levelName = "Nasional";
  } else if (currentLevel === "province") {
    levelName = "Provinsi";
  } else if (currentLevel === "district") {
    levelName = "Kabupaten/Kota";
  } else {
    levelName = "Fasyankes";
  }

  let periodeName = "";

  if (reportInfo?.statusPeriode === "yearly") {
    periodeName = "Tahunan";
  } else {
    periodeName = "Bulanan";
  }

  let data = reportInfo?.fasyankes;
  let parts = data?.split(",");
  let faskesName = parts?.map(function (part) {
    return part?.split("|")[1];
  });
  let result = faskesName?.join(", ");

  return (
    <div
      className="table-table-responsive"
      style={{ height: "560px", overflow: "scroll" }}
      ref={tableRef}
    >
      <table>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none" }}>
            <h3>SISMAL v3, LOGISTIK STOK OUT</h3>
          </th>
        </tr>
      </table>
      <table>
        <thead>
          <tr style={{ border: "none" }}>
            <th
              width="200"
              style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}
            >
              Periode Laporan
            </th>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              : {periodeName}
            </th>
          </tr>
          <tr style={{ border: "none" }}>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              Tahun
            </th>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              : {reportInfo?.periode?.year}
            </th>
          </tr>
          <tr style={{ border: "none" }}>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              Bulan
            </th>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              : {monthConverter(reportInfo?.periode?.month)}
            </th>
          </tr>
          <tr style={{ border: "none" }}>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              Level
            </th>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              : {levelName}
            </th>
          </tr>
          <tr style={{ border: "none" }}>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              Provinsi
            </th>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              :{" "}
              {!reportInfo?.province
                ? "Semua Provinsi"
                : reportInfo?.province?.split("|")[1]}
            </th>
          </tr>
          <tr style={{ border: "none" }}>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              Kab/Kota
            </th>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              :{" "}
              {!reportInfo?.district
                ? "Semua Kab/Kota"
                : reportInfo?.district?.split("|")[1]}
            </th>
          </tr>
          <tr style={{ border: "none" }}>
            <th style={{ border: "none", paddingTop: 0 }}>Fasyankes</th>
            <th style={{ border: "none", paddingTop: 0 }}>
              : {!reportInfo?.fasyankes ? "Semua Fasyankes" : result}
            </th>
          </tr>
        </thead>
      </table>
      {/* {renderTableByLevel()} */}
      <table>
        <thead>
          <tr>
            <th rowSpan={3}>No</th>
            {currentLevel === UserLevel.National.toLowerCase() && (
              <>
                <th rowSpan={3}>Provinsi</th>
                <th rowSpan={3}>Kabupaten/Kota</th>
              </>
            )}
            {currentLevel === UserLevel.Province.toLowerCase() && (
              <th rowSpan={3}>Kabupaten/Kota</th>
            )}
            {(currentLevel === UserLevel.National.toLowerCase() ||
              currentLevel === UserLevel.Province.toLowerCase()) && (
              <th rowSpan={3}>Jumlah Fasyankes</th>
            )}
            {(currentLevel === UserLevel.District.toLowerCase() ||
              currentLevel === UserLevel.Fasyankes.toLowerCase()) && (
              <th rowSpan={3}>Fasyankes</th>
            )}
            <th rowSpan={3}>
              Jumlah Faskes Melapor <br /> (Putus Stok / Tidak Putus Stok)
            </th>
            <th colSpan={4}>Jumlah Faskes Lebih Dari 7 Hari Putus Stok</th>
          </tr>
          <tr>
            <th colSpan={2}>DHP</th>
            <th colSpan={2}>Primaquin</th>
          </tr>
          <tr>
            <th>Ya</th>
            <th>Tidak</th>
            <th>Ya</th>
            <th>Tidak</th>
          </tr>
        </thead>
        <tbody>
          {reportsData?.length > 0 ? (
            reportsData?.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                {currentLevel === UserLevel.National.toLowerCase() && (
                  <>
                    <td>{item?.provinsi}</td>
                    <td>{item?.kabupaten}</td>
                  </>
                )}
                {currentLevel === UserLevel.Province.toLowerCase() && (
                  <td>{item?.kabupaten}</td>
                )}
                {(currentLevel === UserLevel.National.toLowerCase() ||
                  currentLevel === UserLevel.Province.toLowerCase()) && (
                  <td>{item?.faskes}</td>
                )}
                {(currentLevel === UserLevel.District.toLowerCase() ||
                  currentLevel === UserLevel.Fasyankes.toLowerCase()) && (
                  <td>{item?.faskes}</td>
                )}
                <td>{item?.faskes_melapor}</td>
                <td>
                  {currentLevel === "fasyankes"
                    ? item?.dhp === "Ya"
                      ? 1
                      : 0
                    : item?.dhp_ya}
                </td>
                <td>
                  {currentLevel === "fasyankes"
                    ? item?.dhp === "Tidak"
                      ? 1
                      : 0
                    : item?.dhp_tidak}
                </td>
                <td>
                  {currentLevel === "fasyankes"
                    ? item?.primaquin === "Ya"
                      ? 1
                      : 0
                    : item?.primaquin_ya}
                </td>
                <td>
                  {currentLevel === "fasyankes"
                    ? item?.primaquin === "Tidak"
                      ? 1
                      : 0
                    : item?.primaquin_tidak}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td>Tidak Ada Data</td>
            </tr>
          )}
          {reportsData?.length > 0 && (
            <tr style={{ backgroundColor: "#43766C" }}>
              <td
                style={{ color: "#fff" }}
                colSpan={currentLevel === "national" ? 3 : 2}
              >
                Total
              </td>
              {(currentLevel === UserLevel.National.toLowerCase() ||
                currentLevel === UserLevel.Province.toLowerCase()) && (
                <td style={{ color: "#fff" }}>
                  {reportsData?.reduce((a, b) => a + parseInt(b.faskes), 0)}
                </td>
              )}
              <td style={{ color: "#fff" }}>
                {reportsData?.reduce(
                  (a, b) => a + parseInt(b.faskes_melapor),
                  0
                )}
              </td>
              <td style={{ color: "#fff" }}>
                {reportsData?.reduce(
                  (a, b) =>
                    a +
                    parseInt(
                      currentLevel === "fasyankes"
                        ? b?.dhp === "Ya"
                          ? 1
                          : 0
                        : b?.dhp_ya
                    ),
                  0
                )}
              </td>
              <td style={{ color: "#fff" }}>
                {reportsData?.reduce(
                  (a, b) =>
                    a +
                    parseInt(
                      currentLevel === "fasyankes"
                        ? b?.dhp === "Tidak"
                          ? 1
                          : 0
                        : b?.dhp_tidak
                    ),
                  0
                )}
              </td>
              <td style={{ color: "#fff" }}>
                {reportsData?.reduce(
                  (a, b) =>
                    a +
                    parseInt(
                      currentLevel === "fasyankes"
                        ? b?.primaquin === "Ya"
                          ? 1
                          : 0
                        : b?.primaquin_ya
                    ),
                  0
                )}
              </td>
              <td style={{ color: "#fff" }}>
                {reportsData?.reduce(
                  (a, b) =>
                    a +
                    parseInt(
                      currentLevel === "fasyankes"
                        ? b?.primaquin === "Tidak"
                          ? 1
                          : 0
                        : b?.primaquin_tidak
                    ),
                  0
                )}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TableComponent;
