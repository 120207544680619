import { errHandler } from "../utils/common";
import api from "../utils/api";

export default class UKBM {
  getAllSR = (source, searchBy, searchValue) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/ukbm/${source}?searchBy=${searchBy}&searchValue=${searchValue}`)
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  getSSRbySR = (source, searchBy, searchValue) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/ukbm/${source}?searchBy=${searchBy}&searchValue=${searchValue}`)
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };
}
