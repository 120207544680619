import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { PageMode } from "utils/constant";
import TableComponent from "./components/Table";
import FormComponent from "./components/Form";

const RegisterMalaria1 = () => {
  const [pageMode, setPageMode] = useState(PageMode.view);

  useEffect(() => {
    document.title = "SISMAL | Register Malaria 1";
    window.scrollTo(0, 0);
  }, []);

  const changePageMode = (mode) => {
    setPageMode(mode);
  };

  return (
    <div className="dashboard-wrapper">
      {pageMode === PageMode.view && (
        <TableComponent changeMode={changePageMode} pageMode={pageMode} />
      )}
      {(pageMode === PageMode.add || pageMode === PageMode.edit) && (
        <FormComponent changeMode={changePageMode} pageMode={pageMode} />
      )}
    </div>
  );
};

export default RegisterMalaria1;
