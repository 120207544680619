import React, { Component } from "react";
import { ErrorMessage, PageMode } from "utils/constant";
import Table from "./components/Table";
import Form from "./components/Form";

import LogisticExpiredStock from "services/LogisticExpiredStock";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import { ArrowLeft } from "react-feather";
import Datetime from "react-datetime";
import { getCurrentUserFromLocalStorage } from "utils/common";

const LogisticExpiredStockService = new LogisticExpiredStock();

const logisticOpt = [
  { value: "DHP Dispersible", label: "DHP Dispersible" },
  { value: "DHP (Tablet)", label: "DHP (Tablet)" },
  { value: "Primaquin (Tablet)", label: "Primaquin (Tablet)" },
  { value: "Artesunat Inj (Vial)", label: "Artesunat Inj (Vial) " },
  { value: "Kina (Tablet)", label: "Kina (Tablet)" },
  { value: "Kina Inj (Ampul)", label: "Kina Inj (Ampul)" },
  { value: "Doksisiklin (Tablet)", label: "Doksisiklin (Tablet)" },
  { value: "RDT (Test)", label: "RDT (Test)" },
  { value: "LLIN (Lembar)", label: "LLIN (Lembar)" },
  { value: "Insektisida (Kg)", label: "Insektisida (Kg)" },
  { value: "Insektisida (Liter)", label: "Insektisida (Liter)" },
  { value: "Larvasida (Kg)", label: "Larvasida (Kg)" },
  { value: "larvasida (Liter)", label: "larvasida (Liter)" },
  { value: "Lab Kit (Paket)", label: "Lab Kit (Paket)" },
  { value: "Giemsa (Botol)", label: "Giemsa (Botol)" },
  { value: "Giemsa (CC)", label: "Giemsa (CC)" },
  { value: "Minyak Imersi (Botol)", label: "Minyak Imersi (Botol)" },
  { value: "Minyak Imersi (CC)", label: "Minyak Imersi (CC)" },
  { value: "Blood Slide (Box)", label: "Blood Slide (Box)" },
  { value: "Slide Box (Buah)", label: "Slide Box (Buah)" },
  { value: "Blood Lancet (Box)", label: "Blood Lancet (Box)" },
  { value: "Methanol (CC)", label: "Methanol (CC)" },
  { value: "Methanol (Botol)", label: "Methanol (Botol)" },
  { value: "Spray Can (Buah)", label: "Spray Can (Buah)" },
  { value: "Mist Blower (Buah)", label: "Mist Blower (Buah)" },
  {
    value: "Microscope Binocular (Buah)",
    label: "Microscope Binocular (Buah)",
  },
  {
    value: "Microscope Trinocular (Buah)",
    label: "Microscope Trinocular (Buah)",
  },
  {
    value: "Microscope Teaching (Buah)",
    label: "Microscope Teaching (Buah)",
  },
];

const monthOpt = [
  { value: "01", label: "Januari" },
  { value: "02", label: "Februari" },
  { value: "03", label: "Maret" },
  { value: "04", label: "April" },
  { value: "05", label: "Mei" },
  { value: "06", label: "Juni" },
  { value: "07", label: "Juli" },
  { value: "08", label: "Agustus" },
  { value: "09", label: "September" },
  { value: "10", label: "Oktober" },
  { value: "11", label: "November" },
  { value: "12", label: "Desember" },
];

export default class KabupatenExpiredStock extends Component {
  _isMounted = true;

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      limit: 10,
      data: [],
      mode: PageMode.view,
      loading: true,
      search: "",
      selectedItem: null,
      currentPage: 1,
      level: "District",

      selected_year: "",
      selected_month: "",
      selected_logistic: "",
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.getAllExpiredStock();
  }

  componentWillUnmount() {
    this._isMounted = true;
  }

  changeMode = (mode) => {
    this.setState({ mode }, () => {
      if (mode === PageMode.view) this.getAllExpiredStock();
    });
  };

  getAllExpiredStock = async () => {
    const currentUser = getCurrentUserFromLocalStorage();

    await LogisticExpiredStockService.getAll({
      page: this.state.page,
      limit: this.state.limit,
      searchBy: "originator_id,year,month,logistic_type",
      searchValue: `${currentUser.id},${this.state.selected_year},${this.state.selected_month},${this.state.selected_logistic}`,
    })
      .then((data) => {
        if (this._isMounted) {
          this.setState({
            loading: false,
            data,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
        });
      });
  };

  onFilterData = () => {
    const currentUser = getCurrentUserFromLocalStorage();

    this.setState({ loading: true, selectedItem: null }, async () => {
      await LogisticExpiredStockService.getAll({
        page: this.state.page,
        limit: this.state.limit,
        searchBy: "year,month,logistic_type,originator_id",
        searchValue: `${this.state.selected_year},${this.state.selected_month},${this.state.selected_logistic},${currentUser.id}`,
      })
        .then((data) => {
          if (this._isMounted) {
            this.setState({ loading: false, data });
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({ loading: false });
        });
    });
  };

  onEdit = (id) => {
    this.setState({ selectedItem: id }, () => {
      this.changeMode(PageMode.edit);
    });
  };

  onRemove = async (id) => {
    confirmAlert({
      title: "Anda yakin ingin Menghapus data?",
      message: "Are you sure want to delete this data?",
      buttons: [
        {
          label: "Ya",
          onClick: () => {
            LogisticExpiredStockService.remove(id)
              .then((res) => {
                this.getAllExpiredStock();
                toast.success("Data Expired Stok Berhasil Dihapus", {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              })
              .catch((err) => {
                console.log(err);

                if (err.resonse.data.message === ErrorMessage.UserNotVerified) {
                  toast.error("Akun anda belum diverifikasi", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                }

                if (
                  err.response.data.message ===
                  ErrorMessage.UserFaskesNotHaveAccess
                ) {
                  toast.error("Akun anda tidak memiliki akses", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                }
              });
          },
        },
        {
          label: "Tidak",
        },
      ],
    });
  };

  onPageChange = (page) => {
    this.setState({ page }, () => {
      this.getAllExpiredStock();
    });
  };

  onSearch = (e) => {
    const search = e.target.value;
    this.setState({ search }, () => {
      this.getAllExpiredStock();
    });
  };

  render() {
    const { data, mode, loading, page, selectedItem } = this.state;
    return (
      <div className="dashboard-wrapper">
        {mode !== PageMode.view && (
          <div
            className="my-3"
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.changeMode(PageMode.view);
            }}
          >
            <ArrowLeft />
            <span>Kembali</span>
          </div>
        )}

        <h1 className="title font-weight-bold">
          Input Logistik - Expired/Rusak (Kadaluwarsa) Kabupaten
        </h1>
        <div className="content-wrapper mt-4">
          {mode === PageMode.view && (
            <>
              <div className="filter-data">
                <div className="row justify-content-start py-3">
                  <div className="col-sm-6">
                    <form className="form-horizontal">
                      <div className="form-group row justify-content-start">
                        <label className="col-sm-3 col-form-label align-self-center">
                          Tahun
                        </label>
                        <div className="col-sm-4 align-self-center">
                          <Datetime
                            dateFormat="YYYY"
                            timeFormat={false}
                            closeOnClickOutside={true}
                            closeOnSelect={true}
                            onChange={(e) =>
                              this.setState({
                                selected_year: moment(e).format("YYYY"),
                              })
                            }
                            value={this.state.selected_year}
                            inputProps={{
                              name: "filter_year",
                              placeholder: "Pilih Tahun",
                              autoComplete: "off",
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group row justify-content-start">
                        <label className="col-sm-3 col-form-label align-self-center">
                          Bulan
                        </label>
                        <div className="col-sm-4 align-self-center">
                          <select
                            name="filter_month"
                            id="filter_month"
                            className="form-control"
                            onChange={(e) =>
                              this.setState({ selected_month: e.target.value })
                            }
                            value={this.state.selected_month}
                          >
                            <option value="" disabled selected>
                              Pilih Bulan
                            </option>
                            {monthOpt.map((item) => (
                              <option key={item.label} value={item.value}>
                                {item.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="form-group row justify-content-start">
                        <label className="col-sm-3 col-form-label align-self-center">
                          Jenis Logistik
                        </label>
                        <div className="col-sm-4 align-self-center">
                          <select
                            name="filter_month"
                            id="filter_month"
                            className="form-control"
                            onChange={(e) =>
                              this.setState({
                                selected_logistic: e.target.value,
                              })
                            }
                            value={this.state.selected_logistic}
                          >
                            <option value="" disabled selected>
                              Pilih Logistik
                            </option>
                            {logisticOpt.map((item) => (
                              <option key={item.label} value={item.value}>
                                {item.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="form-group row justify-content-start">
                        <div className="col-sm-7">
                          <button
                            className="btn btn-teal btn-block"
                            onClick={(e) => {
                              e.preventDefault();
                              this.onFilterData();
                            }}
                          >
                            Terapkan
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <Table
                data={data}
                loading={loading}
                currentPage={page}
                changeMode={this.changeMode}
                onEdit={this.onEdit}
                onRemove={this.onRemove}
                onSearch={this.onSearch}
                onPageChange={this.onPageChange}
              />
            </>
          )}
          {(mode === PageMode.add || mode === PageMode.edit) && (
            <Form
              mode={mode}
              changeMode={this.changeMode}
              expiredStock={selectedItem}
            />
          )}
        </div>
      </div>
    );
  }
}
