import {
  PAGE_MODE_ADD,
  PAGE_MODE_EDIT,
  PAGE_MODE_VIEW,
} from "redux/actions/actionType";
import { PageMode } from "utils/constant";

const initialState = {
  mode: PageMode.view,
};

const pageReducer = (state = initialState, action) => {
  switch (action.type) {
    case PAGE_MODE_VIEW:
      return { ...state, mode: PageMode.view };
    case PAGE_MODE_ADD:
      return { ...state, mode: PageMode.add };
    case PAGE_MODE_EDIT:
      return { ...state, mode: PageMode.edit };
    default:
      return state;
  }
};

export default pageReducer;
