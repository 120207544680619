import React, { Component } from "react";
import { PlusCircle } from "react-feather";
import { TableComponent as TableData } from "components";

export default class TableComponent extends Component {
  render() {
    const {
      data,
      changeMode,
      loading,
      onSearch,
      onEdit,
      onRemove,
      onPageChange,
    } = this.props;

    return (
      <div className="dashboard-wrapper">
        <h1 className="title font-weight-bold">Data Identifikasi Nyamuk</h1>
        <div className="py-3">
          <div className="row">
            <div className="col-sm-8">
              <button
                className="btn btn-teal font-weight-bold"
                onClick={() => changeMode("add")}
              >
                <PlusCircle className="mr-2" />
                Tambah Data Identifikasi Nyamuk
              </button>
            </div>
            <div className="col-sm-4">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control bg-light"
                  placeholder="Cari Nama Dusun..."
                  onChange={(e) => onSearch(e)}
                />
              </div>
            </div>
          </div>
        </div>

        <TableData
          loading={loading}
          data={data?.vector_mosquito_identifications}
          headers={[
            "Tanggal Survei",
            "Nama Desa",
            "Nama Dusun",
            "Pelaksana",
            "Lokasi",
            "Jenis Survey",
            "Ditemukan Nyamuk Anopheles",
            "Spesies Nyamuk Yang Ditemukan",
            "MBR Dalam Rumah",
            "MBR Luar Rumah",
            "MBR Dalam Kandang Atau Kebun"
          ]}
          cols={[
            "survey_date",
            ["urbanvillage_id", "|", 1],
            "sub_village",
            "implementation",
            "coordinate",
            "survey_type",
            "anopheles_mosquito_found",
            "mosquito_species",
            "mbr_inside_house",
            "mbr_outside_house",
            "mbr_garden",
          ]}
          btnEdit={{ onClick: onEdit }}
          btnRemove={{ onClick: onRemove }}
          currentPage={data?.current_page}
          total={data?.total}
          limit={data?.limit}
          nextPage={data?.next_page}
          previousPage={data?.previous_page}
          onPageChange={onPageChange}
        />
      </div>
    );
  }
}
