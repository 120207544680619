import React from "react";
import DateTime from "react-datetime";
import "react-datetime/css/react-datetime.css";

import styles from "./InputDate.module.scss";

const InputDate = ({
  label,
  name,
  error,
  isDisabled,
  value,
  onChange,
  onBlur,
  placeholder,
  errorMessage,
  isValidDate,
  dateFormat,
  isRequired,
  ...props
}) => {
  const inputClasses = [styles.input];

  if (error) {
    inputClasses.push(styles.error);
  }

  return (
    <div className={styles.formContainer}>
      {label && (
        <label htmlFor={label}>
          {label}{" "}
          {isRequired ? <sup style={{ color: "#D91919" }}>*</sup> : null}{" "}
        </label>
      )}
      <DateTime
        name={name}
        timeFormat={false}
        dateFormat={dateFormat}
        closeOnClickOutside={true}
        closeOnSelect={true}
        className={inputClasses.join(" ")}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        inputProps={{
          placeholder: placeholder,
          name: name,
          autoComplete: "off",
          disabled: isDisabled,
        }}
        isValidDate={isValidDate}
        {...props}
      />
      {error && <span className={styles.invalidInput}>{errorMessage}</span>}
    </div>
  );
};

export default InputDate;
