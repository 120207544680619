import React, { Component } from "react";
import { PageMode } from "utils/constant";
import Table from "./components/Table";
import Form from "./components/Form";
import LogisticRequestStock from "services/LogisticRequestStock";
import Datetime from "react-datetime";
import moment from "moment";

const LogisticReqStockService = new LogisticRequestStock();

const monthOpt = [
  { value: "01", label: "Januari" },
  { value: "02", label: "Februari" },
  { value: "03", label: "Maret" },
  { value: "04", label: "April" },
  { value: "05", label: "Mei" },
  { value: "06", label: "Juni" },
  { value: "07", label: "Juli" },
  { value: "08", label: "Agustus" },
  { value: "09", label: "September" },
  { value: "10", label: "Oktober" },
  { value: "11", label: "November" },
  { value: "12", label: "Desember" },
];

export default class ProvinsiRequestFullfilment extends Component {
  _isMounted = true;
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      limit: 10,
      data: [],
      mode: PageMode.view,
      loading: false,
      search: "",
      selectedItem: null,
      currentPage: 1,
      level: "Province",

      selected_year: moment().format("YYYY"),
      selected_month: moment().format("MM"),

      provincies: [],
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  changeMode = (mode) => {
    this.setState({ mode }, () => {
      if (mode === PageMode.view) this.getReqStockByProvince();
    });
  };

  getReqStockByProvince = () => {
    this.setState({ loading: true, selectedItem: null }, async () => {
      await LogisticReqStockService.getAll({
        page: this.state.page,
        limit: this.state.limit,
        searchBy: "letter_date,level",
        searchValue: `${this.state.selected_year},${this.state.level}`,
      })
        .then((data) => {
          if (this._isMounted) {
            this.setState({ loading: false, data });
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({ loading: false });
        });
    });
  };

  onEdit = (id) => {
    this.setState({ selectedItem: id }, () => {
      this.changeMode(PageMode.edit);
    });
  };

  onPageChange = (page) => {
    this.setState({ page }, () => {
      this.getReqStockByProvince();
    });
  };

  onSearch = (search) => {
    this.setState({ search }, () => {
      this.getReqStockByProvince();
    });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  mapper = (e) => {
    const options = [];
    e.map((x) => {
      options.push({ value: `${x.id}|${x.name}`, label: x.name });
    });
    return options;
  };

  getFile = (id, name) => {
    this.setState({ loading: true }, async () => {
      await LogisticReqStockService.getFile(id)
        .then((data) => {
          if (this._isMounted) {
            this.setState({ loading: false });
            const file = new Blob([data], { type: "application/pdf", name });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({ loading: false });
        });
    });
  };

  getLetterFile = (id, name) => {
    this.setState({ loading: true }, async () => {
      await LogisticReqStockService.getFullfillFile(id)
        .then((data) => {
          if (this._isMounted) {
            this.setState({ loading: true });
            const file = new Blob([data], { type: "application/pdf", name });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({ loading: false });
        });
    });
  };

  render() {
    const { data, mode, loading, page, selectedItem } = this.state;
    return (
      <div className="dashboard-wrapper">
        <h1 className="title font-weight-bold">
          Pemenuhan Permintaan Provinsi
        </h1>

        {mode === PageMode.view && (
          <>
            <div className="filter-data">
              <div className="row justify-content-start py-3">
                <div className="col-sm-6">
                  <form className="form-horizontal">
                    <div className="form-group row justify-content-start">
                      <label className="col-sm-3 col-form-label align-self-center">
                        Tahun
                      </label>
                      <div className="col-sm-4 align-self-center">
                        <Datetime
                          dateFormat="YYYY"
                          timeFormat={false}
                          closeOnClickOutside={true}
                          closeOnSelect={true}
                          onChange={(e) =>
                            this.setState({
                              selected_year: moment(e).format("YYYY"),
                            })
                          }
                          value={this.state.selected_year}
                          inputProps={{
                            name: "filter_year",
                            placeholder: "Pilih Tahun",
                            autoComplete: "off",
                          }}
                        />
                      </div>
                    </div>
                    <div className="form-group row justify-content-start">
                      <label className="col-sm-3 col-form-label align-self-center">
                        Pilih Bulan
                      </label>
                      <div className="col-sm-4 align-self-center">
                        <select
                          name="selected_province"
                          id="selected_province"
                          className="form-control"
                          onChange={(e) =>
                            this.setState({
                              selected_province: e.target.value,
                            })
                          }
                          value={this.state.selected_province}
                        >
                          <option value="" disabled selected>
                            --PILIH--
                          </option>
                          {monthOpt.map((item) => (
                            <option key={item.value} value={`${item.value}`}>
                              {item.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="form-group row justify-content-start">
                      <div className="col-sm-7">
                        <button
                          className="btn btn-teal btn-block"
                          onClick={(e) => {
                            e.preventDefault();
                            this.getReqStockByProvince();
                          }}
                          disabled={!this.state.selected_year}
                        >
                          Terapkan
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <Table
              data={data}
              loading={loading}
              currentPage={page}
              changeMode={this.changeMode}
              onEdit={this.onEdit}
              onSearch={this.onSearch}
              onPageChange={this.onPageChange}
              onGetFile={this.getFile}
              onGetLetter={this.getLetterFile}
            />
          </>
        )}

        {mode === PageMode.edit && (
          <Form
            mode={mode}
            changeMode={this.changeMode}
            requestStock={selectedItem}
          />
        )}
      </div>
    );
  }
}
