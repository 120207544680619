import { errHandler } from "../utils/common";
import api from "../utils/api";

export default class RegmalFirst {
  getAll = (
    opt = { page: 1, limit: 10, searchBy: null, searchValue: null }
  ) => {
    return new Promise((resolve, reject) => {
      api
        .get("/api/regmals/id", { params: opt })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  getOne = (id) => {
    return new Promise((resolve, reject) => {
      api
        .get("/api/regmal/id/" + id)
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  add = (payload) => {
    return new Promise((resolve, reject) => {
      api
        .post("/api/regmal?device=Online", payload)
        .then((res) => {
          api.post("/api/notification/incr/pe-case", {
            regionId: payload.fasyankes_id?.split("|")[0],
          });
          return res;
        })
        .then((res) => {
          if (payload.fasyankes_origin_id !== payload.fasyankes_id) {
            api.post("/api/notification/incr/feedback-case", {
              regionId: payload.fasyankes_origin_id?.split("|")[0],
            });
          }
          return res;
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  addBulk = (payload) => {
    return new Promise((resolve, reject) => {
      api
        .post("/api/regmal/bulk", payload, { params: { device: "Online" } })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  update = (payload, id) => {
    return new Promise((resolve, reject) => {
      api
        .patch(`/api/regmal/id/${id}?device=Online`, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  remove = (regmalId) => {
    return new Promise((resolve, reject) => {
      api
        .delete(`/api/regmal/${regmalId}?device=Online`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };
}
