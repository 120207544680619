import React, { useEffect, useState } from "react";
import InputField from "components/atoms/InputField";
import "./Laporan.scss";
import IcFileRed from "assets/icons/ic_file_red.svg";
import IcFileYellow from "assets/icons/ic_file_yellow.svg";
import IcFileBlue from "assets/icons/ic_file_blue.svg";
import IcFilePink from "assets/icons/ic_file_pink.svg";
import IcFileSkyBlue from "assets/icons/ic_file_skyblue.svg";
import EmptyDataIllustrator from "assets/illustrators/ill_empty_data.svg";
import Button from "components/atoms/Button";
import { useHistory } from "react-router-dom";
import { UserLevel } from "utils/constant";
import { useSelector } from "react-redux";

const LaporanPage = () => {
  const { currentUser } = useSelector((state) => state.authReducer);

  const history = useHistory();
  const [filterValue, setFilterValue] = useState("");

  const handleInputChange = (e) => {
    setFilterValue(e.target.value);
  };

  const laporanData = [
    {
      route: "zero-report",
      title: "Zero Report",
      level: [
        UserLevel.National,
        UserLevel.Province,
        UserLevel.District,
        UserLevel.Fasyankes,
      ],
    },
    {
      title: "Regmal1",
      route: "regmal1",
      level: [
        UserLevel.National,
        UserLevel.Province,
        UserLevel.District,
        UserLevel.Fasyankes,
      ],
    },
    {
      route: "rekap-regmal2",
      title: "Rekap Regmal-2",
      level: [
        UserLevel.National,
        UserLevel.Province,
        UserLevel.District,
        UserLevel.Fasyankes,
      ],
    },
    {
      route: "kasus-pe",
      title: "Kasus PE",
      level: [
        UserLevel.National,
        UserLevel.Province,
        UserLevel.District,
        UserLevel.Fasyankes,
      ],
    },
    {
      route: "malaria-kia",
      title: "Malaria - KIA",
      level: [
        UserLevel.National,
        UserLevel.Province,
        UserLevel.District,
        UserLevel.Fasyankes,
      ],
    },
    {
      route: "tarik-data1",
      title: "Tarik Data-1",
      level: [
        UserLevel.National,
        UserLevel.Province,
        UserLevel.District,
        UserLevel.Fasyankes,
      ],
    },
    {
      route: "tarik-data1-publik",
      title: "Tarik Data-1 Publik",
      level: [
        UserLevel.National,
        UserLevel.Province,
        UserLevel.District,
        UserLevel.Fasyankes,
      ],
    },
    {
      route: "tarik-data1-private",
      title: "Tarik Data-1 Private",
      level: [
        UserLevel.National,
        UserLevel.Province,
        UserLevel.District,
        UserLevel.Fasyankes,
      ],
    },
    {
      route: "logistik-stok",
      title: "Logistik Stok",
      level: [
        UserLevel.National,
        UserLevel.Province,
        UserLevel.District,
        UserLevel.Fasyankes,
      ],
    },
    {
      route: "logistik-ed",
      title: "Logistik ED",
      level: [
        UserLevel.National,
        UserLevel.Province,
        UserLevel.District,
        UserLevel.Fasyankes,
      ],
    },
    {
      route: "logistik-stok-out",
      title: "Logistik Stok Out",
      level: [
        UserLevel.National,
        UserLevel.Province,
        UserLevel.District,
        UserLevel.Fasyankes,
      ],
    },
    {
      route: "logistik-pemakaian",
      title: "Logistik Pemakaian",
      level: [UserLevel.National, UserLevel.Province],
    },
    {
      title: "Regmal2",
      route: "regmal2",
      level: [
        UserLevel.National,
        UserLevel.Province,
        UserLevel.District,
        UserLevel.Fasyankes,
      ],
    },
    {
      title: "Regmal 2 Public",
      route: "regmal2-public",
      level: [
        UserLevel.National,
        UserLevel.Province,
        UserLevel.District,
        UserLevel.Fasyankes,
      ],
    },
    {
      title: "Regmal 2 Private",
      route: "regmal2-private",
      level: [
        UserLevel.National,
        UserLevel.Province,
        UserLevel.District,
        UserLevel.Fasyankes,
      ],
    },
    {
      route: "rujukan",
      title: "Regmal 1 Rujukan",
      level: [
        UserLevel.National,
        UserLevel.Province,
        UserLevel.District,
        UserLevel.Fasyankes,
      ],
    },
    {
      route: "reseptifitas",
      title: "Reseptifitas dan Fokus",
      level: [
        UserLevel.National,
        UserLevel.Province,
        UserLevel.District,
        UserLevel.Fasyankes,
      ],
    },
    {
      route: "identifikasi-jentik",
      title: "Identifikasi Jentik",
      level: [
        UserLevel.National,
        UserLevel.Province,
        UserLevel.District,
        UserLevel.Fasyankes,
      ],
    },
    {
      route: "identifikasi-nyamuk",
      title: "Identifikasi Nyamuk",
      level: [
        UserLevel.National,
        UserLevel.Province,
        UserLevel.District,
        UserLevel.Fasyankes,
      ],
    },
    {
      route: "pengendalian-vektor",
      title: "Pengendalian Vektor",
      level: [
        UserLevel.National,
        UserLevel.Province,
        UserLevel.District,
        UserLevel.Fasyankes,
      ],
    },
    {
      route: "kelengkapan-perbulan-publik",
      title: "Kelengkapan Per Bulan Publik",
      level: [
        UserLevel.National,
        UserLevel.Province,
        UserLevel.District,
        UserLevel.Fasyankes,
      ],
    },
    {
      route: "kelengkapan-perbulan-private",
      title: "Kelengkapan Per Bulan Private",
      level: [
        UserLevel.National,
        UserLevel.Province,
        UserLevel.District,
        UserLevel.Fasyankes,
      ],
    },
    {
      route: "kelengkapan-perbulan-nasional",
      title: "Kelengkapan Per Bulan Nasional",
      level: [
        UserLevel.National,
        UserLevel.Province,
        UserLevel.District,
        UserLevel.Fasyankes,
      ],
    },
    {
      route: "data-dan-monitoring-kader",
      title: "Data dan Monitoring Kader",
      level: [
        UserLevel.National,
        UserLevel.Province,
        UserLevel.District,
        UserLevel.Fasyankes,
      ],
    },
    {
      route: "kinerja-kader",
      title: "Kinerja Kader",
      level: [
        UserLevel.National,
        UserLevel.Province,
        UserLevel.District,
        UserLevel.Fasyankes,
      ],
    },
    {
      route: "mmp-dan-monitoring-kader-mmp",
      title: "MMP dan Monitoring Kader MMP",
      level: [
        UserLevel.National,
        UserLevel.Province,
        UserLevel.District,
        UserLevel.Fasyankes,
      ],
    },
    {
      route: "data-dasar-penduduk",
      title: "Data Dasar Penduduk",
      level: [
        UserLevel.National,
        UserLevel.Province,
        UserLevel.District,
        UserLevel.Fasyankes,
      ],
    },
    {
      route: "kinerja-kader-mmp",
      title: "Kinerja Kader MMP",
      level: [
        UserLevel.National,
        UserLevel.Province,
        UserLevel.District,
        UserLevel.Fasyankes,
      ],
    },
    {
      route: "validasi-kasus-indigenous",
      title: "Validasi Kasus Indigenous",
      level: [
        UserLevel.National,
        UserLevel.Province,
        UserLevel.District,
        UserLevel.Fasyankes,
      ],
    },
  ];

  const onLaporanClick = (data) => {
    const module = data.route;
    history.push(`/laporan/${module}`);
  };

  const columnsIcon = [
    IcFileRed,
    IcFileYellow,
    IcFileBlue,
    IcFilePink,
    IcFileSkyBlue,
  ];

  const filteredLaporanData = laporanData.filter(
    (laporan) =>
      laporan.title.toLowerCase().includes(filterValue.toLowerCase()) &&
      laporan.level.includes(currentUser?.level)
  );

  const rows = [];
  for (let i = 0; i < filteredLaporanData.length; i += 5) {
    const rowCols = filteredLaporanData.slice(i, i + 5);
    rows.push(rowCols);
  }

  useEffect(() => {
    document.title = "SISMAL | Laporan";
  }, []);

  return (
    <div className="dashboard-wrapper">
      <div className="header">
        <h1>Laporan</h1>
        <div className="search-wrapper">
          <InputField
            placeholder={"Cari laporan disini"}
            hasShadow={true}
            value={filterValue}
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className="items-report">
        {rows.length === 0 ? (
          <div className="empty-data-container">
            <img src={EmptyDataIllustrator} alt="Empty Data illustrator" />
            <h3>Laporan yang Kamu Cari Tidak Ditemukan</h3>
            <p>Pastikan nama laporan yang kamu masukkan sudah benar</p>
            <Button
              variant={"primary"}
              size={"normal"}
              onClick={() =>
                this.setState({
                  filterValue: "",
                })
              }
            >
              Lihat Semua Laporan
            </Button>
          </div>
        ) : (
          rows.map((row, rowIndex) => (
            <div key={rowIndex}>
              <div
                className="row"
                style={{ gap: "16px", margin: "0" }}
                key={rowIndex}
              >
                {row.map((laporan, colIndex) => (
                  <div
                    onClick={() => onLaporanClick(laporan)}
                    className="col grid-item"
                    style={{ marginBottom: "16px" }}
                    key={colIndex}
                  >
                    <div className="icon-wrapper">
                      <img
                        src={columnsIcon[colIndex % columnsIcon.length]}
                        alt="Icon"
                      />
                    </div>
                    <h3>{laporan.title}</h3>
                  </div>
                ))}
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default LaporanPage;
