import React, { Component } from "react";
import { connect } from "react-redux";
import "./Form.scss";
import { ErrorMessage, PageMode, Source, requestStatus } from "utils/constant";
import InputField from "components/atoms/InputField";
import InputDate from "components/atoms/InputDate";
import Button from "components/atoms/Button";
import { Edit3, PlusCircle, Trash } from "react-feather";
import * as Yup from "yup";
import { Formik } from "formik";
import ModalDesaForm from "./ModalDesaForm";
import Wilayah from "services/Wilayah";
import Populasi from "services/Populasi";
import moment from "moment";
import {
  clearPopulation,
  deletePopulation,
  itemPopulationToEdit,
} from "redux/actions/populationAction";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";

const validationSchema = Yup.object().shape({
  year: Yup.string().required("Tahun wajib diisi"),
  operator_name: Yup.string().required("Nama operator wajib diisi"),
  phone_number: Yup.string().required("No Telepon wajib diisi"),
  email: Yup.string()
    .email("Format email tidak valid")
    .required("Email wajib diisi"),
});

const WilayahService = new Wilayah();
const PopulasiService = new Populasi();

export class Form extends Component {
  _isMounted = true;
  constructor(props) {
    super(props);
    this.state = {
      urbanvillages: [],
      isOpenModal: false,

      isSubmitting: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;

    const { population, setUrbanvillageToEdit } = this.props;

    if (this.props.mode === PageMode.edit) {
      const item = {
        have_urbanvillage_id: population?.have_urbanvillage_id,
        urbanvillage_name: population?.urbanvillage_id?.split("|")[1],
        urbanvillage_id: population?.urbanvillage_id?.split("|")[0],
        total_population: population?.total_population,
        reseptifitas: population?.reseptifitas,
      };

      setUrbanvillageToEdit(item);
    }
  }

  onYearChange = (year) => {
    const { user } = this.props;
    const subdistrict_id = user.subdistrict_id?.split("|")[0];
    if (user?.fasyankes_type_id === "jnsfas-01") {
      this.getUrbanvillagesBySubdistrict(subdistrict_id, year);
    }
  };

  nullConverter = (value) => {
    if (value === "") {
      return null;
    } else {
      return value;
    }
  };

  getUrbanvillagesBySubdistrict = async (id, year) => {
    try {
      // Mendapatkan daftar urbanvillages berdasarkan subdistrict_id
      const urbanvillagesResponse = await WilayahService.getAll(
        Source.URBANVILLAGE,
        {
          searchBy: "subdistrict_id",
          searchValue: id,
          page: 1,
          limit: 999,
        }
      );

      const urbanvillages = urbanvillagesResponse?.urbanvillage || [];
      const population = await PopulasiService.getAll({
        page: 1,
        limit: 999,
        searchValue: year,
        searchBy: "year",
      }).then((resData) => resData.populations);

      // Filter urbanvillages yang belum memiliki data populasi
      const urbanvillagesWithoutPopulation = urbanvillages.filter((village) => {
        const findInPopulation = population.find(
          (x) => x.urbanvillage_id?.split("|")[0] === village.id
        );
        return !findInPopulation;
      });

      // Set state urbanvillages sesuai hasil filter
      this.setState({
        urbanvillages: urbanvillagesWithoutPopulation,
      });
    } catch (error) {
      console.log(error);
    }
  };

  // Handle delete value
  handleDelete = (item) => {
    const { deleteUrbanvillageData } = this.props;
    const confirmedMessage = window.confirm(
      `Apakah kamu yakin ingin menghapus desa ${item.urbanvillage_name}`
    );
    if (confirmedMessage) {
      deleteUrbanvillageData(item.id);
      toast.success(`Desa ${item.urbanvillage_name} berhasil dihapus`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
        theme: "colored",
      });
    }
  };

  // For Split value
  splitVal = (val) => {
    return val?.split("|")[1];
  };

  render() {
    const { mode, user, population } = this.props;

    return (
      <div className="form-wrapper">
        <h1 className="page-title">Tambah Data Dasar Penduduk</h1>
        {/* Informasi Data */}
        <div className="data-information">
          <div className="data-item">
            <span className="label">Provinsi</span>
            <span className="value">
              {this.splitVal(
                mode !== PageMode.edit
                  ? user.province_id
                  : population.province_id
              )}
            </span>
          </div>
          <hr
            style={{
              backgroundColor: "#ECEDF0",
            }}
          />
          <div className="data-item">
            <span className="label">Kab/Kota</span>
            <span className="value">
              {this.splitVal(
                mode !== PageMode.edit
                  ? user.district_id
                  : population.district_id
              )}
            </span>
          </div>
          <hr
            style={{
              backgroundColor: "#ECEDF0",
            }}
          />
          <div className="data-item">
            <span className="label">Kecamatan</span>
            <span className="value">
              {this.splitVal(
                mode !== PageMode.edit
                  ? user.subdistrict_id
                  : population.subdistrict_id
              )}
            </span>
          </div>
          <hr
            style={{
              backgroundColor: "#ECEDF0",
            }}
          />
          <div className="data-item">
            <span className="label">Fasyankes</span>
            <span className="value">
              {this.splitVal(
                mode !== PageMode.edit
                  ? user.fasyankes_id
                  : population.fasyankes_id
              )}
            </span>
          </div>
        </div>

        {/* Form Data penduduk */}

        <Formik
          enableReinitialize={true}
          initialValues={{
            province_id: population ? population.province_id : user.province_id,
            district_id: population ? population.district_id : user.district_id,
            subdistrict_id: population
              ? population.subdistrict_id
              : user.subdistrict_id,
            fasyankes_id: population
              ? population.fasyankes_id
              : user.fasyankes_id,
            year: population ? population.year : "",
            operator_name: population ? population.operator_name : "",
            phone_number: population ? population.phone_number : "",
            email: population ? population.email : "",
          }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            this.setState({
              isSubmitting: true,
            });

            let basePayload = {
              province_id: this.nullConverter(values.province_id),
              district_id: this.nullConverter(values.district_id),
              subdistrict_id: this.nullConverter(values.subdistrict_id),
              fasyankes_id: this.nullConverter(values.fasyankes_id),
              year: this.nullConverter(values.year),
              operator_name: this.nullConverter(values.operator_name),
              phone_number: this.nullConverter(values.phone_number),
              email: this.nullConverter(values.email),
            };

            switch (this.props.mode) {
              case PageMode.add:
                Promise.all(
                  this.props.urbanvillagesData.map(async (item) => {
                    await PopulasiService.add({
                      ...basePayload,
                      have_urbanvillage_id: item.have_urbanvillage_id,
                      urbanvillage_id: `${item.urbanvillage_id}|${item.urbanvillage_name}`,
                      total_population: item.total_population,
                      reseptifitas: item.reseptifitas,
                    });
                  })
                )
                  .then((res) => {
                    this.props.setUrbanvillageToEdit(null);
                    this.props.clearUrbanvillagesData();
                    toast.success("Data Dasar Penduduk Berhasil di Tambah", {
                      position: "top-center",
                      autoClose: 5000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: 0,
                      theme: "colored",
                    });
                    this.setState({
                      isSubmitting: false,
                    });
                    this.props.changeMode(PageMode.view);
                  })
                  .catch((err) => {
                    console.log(err);
                    this.setState({
                      isSubmitting: false,
                    });
                    switch (err.response.status) {
                      case requestStatus.BadRequest:
                        toast.error(ErrorMessage.UserFaskesNotHaveAccess, {
                          position: "top-right",
                          autoClose: 5000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                        });
                        break;
                      case requestStatus.Forbidden:
                        toast.error(ErrorMessage.UserNotVerified, {
                          position: "top-right",
                          autoClose: 5000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                        });
                        break;
                      case requestStatus.Conflict:
                        toast.error(ErrorMessage.DataAlreadyExist, {
                          position: "top-right",
                          autoClose: 5000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                        });
                        break;
                      case requestStatus.ServerError:
                        toast.error(ErrorMessage.InternalServerError, {
                          position: "top-right",
                          autoClose: 5000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                        });
                      default:
                        break;
                    }
                  });
                break;
              case PageMode.edit:
                const payload = {
                  ...basePayload,
                  have_urbanvillage_id:
                    this.props.itemToEdit?.have_urbanvillage_id,
                  urbanvillage_id: `${this.props.itemToEdit?.urbanvillage_id}|${this.props.itemToEdit?.urbanvillage_name}`,
                  total_population: this.props.itemToEdit?.total_population,
                  reseptifitas: this.props.itemToEdit?.reseptifitas,
                };

                await PopulasiService.update(payload, this.props.population.id)
                  .then((res) => {
                    this.props.setUrbanvillageToEdit(null);
                    this.props.clearUrbanvillagesData();
                    toast.success("Data Dasar Berhasil diperbarui", {
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                    this.setState({
                      isSubmitting: false,
                    });
                    this.props.changeMode(PageMode.view);
                  })
                  .catch((err) => {
                    console.log(err);
                    this.setState({
                      isSubmitting: false,
                    });

                    if (err.response.status === requestStatus.BadRequest) {
                      toast.error(ErrorMessage.UserFaskesNotHaveAccess, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      });
                    }

                    if (err.response.status === requestStatus.Forbidden) {
                      toast.error(ErrorMessage.UserNotVerified, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      });
                    }

                    if (err.response.status === requestStatus.Conflict) {
                      toast.error(ErrorMessage.DataAlreadyExist, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      });
                    }
                    if (err.response.status === requestStatus.ServerError) {
                      toast.error(ErrorMessage.InternalServerError, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      });
                    }
                  });
                break;
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <div className="form-container">
              <div className="row">
                <div
                  className="col-sm-12 col-md-6"
                  style={{ marginBottom: "24px" }}
                >
                  <InputDate
                    label={"Tahun"}
                    placeholder={"Pilih Tahun"}
                    dateFormat={"YYYY"}
                    name={"year"}
                    onChange={(e) => {
                      const year = moment(e).format("YYYY");
                      handleChange({
                        target: { name: "year", value: year },
                      });
                      this.onYearChange(year);
                    }}
                    onBlur={handleBlur}
                    value={values.year}
                    error={errors.year && touched.year}
                    errorMessage={errors.year}
                    isRequired
                  />
                </div>
                <div
                  className="col-sm-12 col-md-6"
                  style={{ marginBottom: "24px" }}
                >
                  <InputField
                    type={"text"}
                    label={"Nama Petugas"}
                    placeholder={"Isi nama petugas disini"}
                    name={"operator_name"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.operator_name}
                    error={errors.operator_name && touched.operator_name}
                    errorMessage={errors.operator_name}
                    isRequired
                  />
                </div>
                <div
                  className="col-sm-12 col-md-6"
                  style={{ marginBottom: "24px" }}
                >
                  <InputField
                    type={"number"}
                    label={"No Telepon"}
                    placeholder={"Isi nomor telepon disini"}
                    name={"phone_number"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone_number}
                    error={errors.phone_number && touched.phone_number}
                    errorMessage={errors.phone_number}
                    isRequired
                  />
                </div>
                <div
                  className="col-sm-12 col-md-6"
                  style={{ marginBottom: "24px" }}
                >
                  <InputField
                    type={"text"}
                    label={"Email"}
                    placeholder={"Isi email disini"}
                    name={"email"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    error={errors.email && touched.email}
                    errorMessage={errors.email}
                    isRequired
                  />
                </div>
              </div>

              {/* For edit data */}
              {this.props.mode === PageMode.edit ? (
                <div className="list-data">
                  <div className="data-container">
                    {/* Nama Desa */}
                    <div className="list-wrapper">
                      <div>
                        <p className="text-label">Nama Desa</p>
                        <p className="text-value">
                          {this?.props?.itemToEdit?.urbanvillage_name}
                        </p>
                      </div>
                    </div>
                    {/* Kode Desa */}
                    <div className="list-wrapper">
                      <div>
                        <p className="text-label">Kode Desa</p>
                        <p className="text-value">
                          {this?.props?.itemToEdit?.urbanvillage_id}
                        </p>
                      </div>
                    </div>
                    {/* Jumlah Penduduk */}
                    <div className="list-wrapper">
                      <div>
                        <p className="text-label">Jumlah Penduduk</p>
                        <p className="text-value">
                          {this?.props?.itemToEdit?.total_population}
                        </p>
                      </div>
                    </div>
                    {/* Data Reseftifitas */}
                    <div className="list-wrapper">
                      <div>
                        <p className="text-label">Data Reseftifitas</p>
                        <p className="text-value">
                          {this?.props?.itemToEdit?.reseptifitas}
                        </p>
                      </div>
                    </div>
                    {/* Aksi */}
                    <div className="list-wrapper">
                      {/* Action Button */}
                      <button
                        data-toggle="modal"
                        data-target="#formDesaModal"
                        onClick={() => {
                          let item = {
                            have_urbanvillage_id:
                              this?.props?.itemToEdit.have_urbanvillage_id,
                            urbanvillage_name:
                              this?.props?.itemToEdit.urbanvillage_name,
                            urbanvillage_id:
                              this?.props?.itemToEdit.urbanvillage_id,
                            total_population:
                              this?.props?.itemToEdit.total_population,
                            reseptifitas: this?.props?.itemToEdit.reseptifitas,
                          };
                          this.props.setUrbanvillageToEdit(item);
                        }}
                      >
                        <Edit3
                          size={24}
                          style={{
                            marginRight: "8px",
                            color: "#0C928B",
                          }}
                        />
                        Edit
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}

              {/* List add desa */}
              {this.props.urbanvillagesData.length > 0 && (
                <div className="list-data">
                  {this.props.urbanvillagesData.map((item, index) => (
                    <div className="data-container" key={index}>
                      {/* Nama Desa */}
                      <div className="list-wrapper">
                        <div className="circle-outer">
                          <div className="circle-inner">
                            <p>{index + 1}</p>
                          </div>
                        </div>
                        <div>
                          <p className="text-label">Nama Desa</p>
                          <p className="text-value">{item.urbanvillage_name}</p>
                        </div>
                      </div>
                      {/* Kode Desa */}
                      <div className="list-wrapper">
                        <div>
                          <p className="text-label">Kode Desa</p>
                          <p className="text-value">{item.urbanvillage_id}</p>
                        </div>
                      </div>
                      {/* Jumlah Penduduk */}
                      <div className="list-wrapper">
                        <div>
                          <p className="text-label">Jumlah Penduduk</p>
                          <p className="text-value">{item.total_population}</p>
                        </div>
                      </div>
                      {/* Data Reseftifitas */}
                      <div className="list-wrapper">
                        <div>
                          <p className="text-label">Data Reseftifitas</p>
                          <p className="text-value">{item.reseptifitas}</p>
                        </div>
                      </div>
                      {/* Aksi */}
                      <div className="list-wrapper">
                        {/* Action Button */}
                        <button
                          data-toggle="modal"
                          data-target="#formDesaModal"
                          onClick={() => this.props.setUrbanvillageToEdit(item)}
                        >
                          <Edit3
                            size={24}
                            style={{
                              marginRight: "8px",
                              color: "#0C928B",
                            }}
                          />
                          Edit
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            this.handleDelete(item);
                          }}
                        >
                          <Trash
                            size={24}
                            style={{
                              marginRight: "8px",
                              color: "#0C928B",
                            }}
                          />
                          Hapus
                        </button>

                        {/* Modal Delete Confirm */}
                        {/* <ModalDelete itemToDelete={this.state.itemToDelete} /> */}
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {this.props.mode !== PageMode.edit ? (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Button
                    variant={"secondary"}
                    size={"normal"}
                    style={{ border: "1px solid #D0F4F2" }}
                    type={"button"}
                    data-toggle="modal"
                    data-target="#formDesaModal"
                  >
                    <PlusCircle style={{ marginRight: "3px" }} />
                    <span>Tambah Desa</span>
                  </Button>
                </div>
              ) : null}

              {/* Action Button */}
              <div className="action-button">
                <Button
                  variant={"tertiary"}
                  size={"normal"}
                  onClick={() => {
                    this.props.setUrbanvillageToEdit(null);
                    this.props.changeMode(PageMode.view);
                    this.props.clearUrbanvillagesData();
                  }}
                >
                  Batalkan
                </Button>
                <Button
                  variant={"primary"}
                  size={"normal"}
                  onClick={handleSubmit}
                  isDisabled={
                    this.props.urbanvillagesData.length === 0 &&
                    mode !== PageMode.edit
                  }
                >
                  {this.state.isSubmitting ? (
                    <BeatLoader color="#fff" size={10} />
                  ) : (
                    "Simpan Data"
                  )}
                </Button>
              </div>
            </div>
          )}
        </Formik>

        {/* Modal Data Desa Form */}
        <ModalDesaForm
          urbanvillages={this.state.urbanvillages}
          itemToEdit={this.props.itemToEdit}
          pageMode={this.props.mode}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authReducer.currentUser,
    urbanvillagesData: state.populationReducer.urbanVillages,
    itemToEdit: state.populationReducer.itemToEdit,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteUrbanvillageData: (id) => dispatch(deletePopulation(id)),
    setUrbanvillageToEdit: (payload) => dispatch(itemPopulationToEdit(payload)),
    clearUrbanvillagesData: () => dispatch(clearPopulation()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
