import { errHandler } from "utils/common";
import api from "utils/api";

export default class Analysis {
  getReportCompleteness = (
    opt = {
      date: null,
      level: null,
      level_id: null,
    }
  ) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/analysis/report-completeness`, { params: opt })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  getPositiveCase = (
    opt = {
      date: null,
      level: null,
      level_id: null,
    }
  ) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/analysis/positive-case`, { params: opt })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  getDeathTrends = (
    opt = {
      year: null,
      month: null,
      level: null,
      level_id: null,
      period: null,
    }
  ) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/analysis/tren-kematian`, { params: opt })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  getPositivityRate = (
    opt = {
      year: null,
      month: null,
      level: null,
      level_id: null,
      period: null,
    }
  ) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/analysis/positivity-rate`, { params: opt })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  getLaboratoryConfirmedSuspect = (
    opt = {
      date: null,
      level: null,
      level_id: null,
    }
  ) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/analysis/suspect-confirm`, { params: opt })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  getABER = (
    opt = {
      date: null,
      level: null,
      level_id: null,
    }
  ) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/analysis/aber`, { params: opt })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  getValidatedIndegenousCase = (
    opt = {
      date: null,
      level: null,
      level_id: null,
    }
  ) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/analysis/indegenous-case-validated`, { params: opt })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  getInspection = (
    opt = {
      level: null,
      level_id: null,
      period: null,
      year: null,
      month: null,
    }
  ) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/analysis/pemeriksaan`, { params: opt })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  getApiMoreOne = (
    opt = {
      date: null,
      level: null,
      level_id: null,
    }
  ) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/analysis/api-greater-1`, { params: opt })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  getApiLessOne = (
    opt = {
      date: null,
      level: null,
      level_id: null,
    }
  ) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/analysis/api-less-1`, { params: opt })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  getStandardMedication = (
    opt = {
      date: null,
      level: null,
      level_id: null,
    }
  ) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/analysis/standard-medication`, { params: opt })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  getProporsiJenisPlasmodiun = (
    opt = {
      date: null,
      level: null,
      level_id: null,
    }
  ) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/analysis/jenis-plasmodium`, { params: opt })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  getProporsiJenisMMP = (
    opt = {
      date: null,
      level: null,
      level_id: null,
    }
  ) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/analysis/proportion-mmp`, { params: opt })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  getKasusIndigenous = (
    opt = {
      date: null,
      level: null,
      level_id: null,
    }
  ) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/analysis/indegenous-case`, { params: opt })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  getReportLogisticCompleteness = (
    opt = {
      date: null,
      level: null,
      level_id: null,
    }
  ) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/analysis/report-completeness-logistic`, { params: opt })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  getPosCasePE = (
    opt = {
      date: null,
      level: null,
      level_id: null,
    }
  ) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/analysis/kasus-positif-pe`, { params: opt })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  getSKDMalariaTrends = (
    opt = {
      date: null,
      level: null,
      level_id: null,
    }
  ) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/analysis/tren-skd-malaria`, { params: opt })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  getProportionDiscoveryActivity = (
    opt = {
      date: null,
      level: null,
      level_id: null,
    }
  ) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/analysis/proportion-discovery-activity`, { params: opt })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  getProportionPositiveCaseOwnership = (
    opt = {
      date: null,
      level: null,
      level_id: null,
    }
  ) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/analysis/proportion-positive-case-ownership`, {
          params: opt,
        })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  getIndicatorLogisticOffStock = (
    opt = {
      date: null,
      level: null,
      level_id: null,
    }
  ) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/analysis/indicator-logistic-off-stock`, { params: opt })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  getDeaths = (
    opt = {
      date: null,
      level: null,
      level_id: null,
    }
  ) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/analysis/kematian`, { params: opt })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  getProportionPositiveCaseOperator = (
    opt = {
      date: null,
      level: null,
      level_id: null,
    }
  ) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/analysis/proportion-positive-case-operator`, { params: opt })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  getProportionCaseClassification = (
    opt = {
      date: null,
      level: null,
      level_id: null,
    }
  ) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/analysis/proportion-case-classification`, { params: opt })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  getCaderPerformance = (
    opt = {
      date: null,
      level: null,
      level_id: null,
    }
  ) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/analysis/cadre-performance`, { params: opt })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  getAnnualParasiteInsiden = (
    opt = {
      date: null,
      level: null,
      level_id: null,
    }
  ) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/analysis/api`, { params: opt })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  getKetepatanPE = (
    opt = {
      date: null,
      level: null,
      level_id: null,
    }
  ) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/analysis/ketepatan-laporan-pe`, { params: opt })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  getPetaEndemis = (
    opt = {
      date: null,
      level: null,
      level_id: null,
    }
  ) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/analysis/peta-endemis`, { params: opt })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  getPetaFokus = (
    opt = {
      date: null,
      level: null,
      level_id: null,
    }
  ) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/analysis/peta-fokus`, { params: opt })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  getPetaReseptifitas = (
    opt = {
      date: null,
      level: null,
      level_id: null,
    }
  ) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/analysis/peta-reseptifitas`, { params: opt })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}
