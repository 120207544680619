import { SHOW_NOTIF } from "redux/actions/actionType";

const initialState = { show: false };

const showNotifReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_NOTIF:
      return action.payload;
    default:
      return state;
  }
};

export default showNotifReducer;
