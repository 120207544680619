import React from "react";
import { setDataToLocalStorage } from "utils/common";
import InputRadio from "components/atoms/InputRadio";
import InputField from "components/atoms/InputField";
import Button from "components/atoms/Button";

const InterventionFormStep = ({ handlePrevStep, formik }) => {
  return (
    <>
      <section className="section-title">Intervensi</section>
      <div className="form-grouping">
        <InputRadio
          label="Apakah tersedia kader MMP?"
          name="cadre_available"
          options={[
            { label: "Ya", value: "Ya" },
            { label: "Tidak", value: "Tidak" },
          ]}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          selectedOption={formik.values.cadre_available}
          error={
            formik.errors.cadre_available && formik.touched.cadre_available
          }
          errorMessage={formik.errors.cadre_available}
          isRequired={true}
        />
        {formik.values.cadre_available === "Ya" && (
          <InputField
            label={"Berapa jumlah kader MMP?"}
            name={"total_cadre"}
            placeholder={"Masukkan jumlah kader MMP"}
            type={"number"}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.total_cadre}
            error={formik.errors.total_cadre && formik.touched.total_cadre}
            errorMessage={formik.errors.total_cadre}
            isRequired={true}
          />
        )}
        <InputRadio
          label="Apakah dilakukan distribusi Kelambu?"
          name="net_gift"
          options={[
            { label: "Ya", value: "Ya" },
            { label: "Tidak", value: "Tidak" },
          ]}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          selectedOption={formik.values.net_gift}
          error={formik.errors.net_gift && formik.touched.net_gift}
          errorMessage={formik.errors.net_gift}
          isRequired={true}
        />

        {formik.values.net_gift === "Ya" && (
          <InputField
            label={"Berapa jumlah kelambu yang di distribusikan?"}
            name={"total_net_distribution"}
            placeholder={"Masukkan jumlah kader MMP"}
            type={"number"}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.total_net_distribution}
            error={
              formik.errors.total_net_distribution &&
              formik.touched.total_net_distribution
            }
            errorMessage={formik.errors.total_net_distribution}
            isRequired={true}
          />
        )}

        <InputRadio
          label="Apakah dilaksanakan KIE?"
          name="kie"
          options={[
            { label: "Ya", value: "Ya" },
            { label: "Tidak", value: "Tidak" },
          ]}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          selectedOption={formik.values.kie}
          error={formik.errors.kie && formik.touched.kie}
          errorMessage={formik.errors.kie}
          isRequired={true}
        />
        <InputRadio
          label="Apakah dilaksanakan MBS rutin?"
          name="routine_mbs"
          options={[
            { label: "Ya", value: "Ya" },
            { label: "Tidak", value: "Tidak" },
          ]}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          selectedOption={formik.values.routine_mbs}
          error={formik.errors.routine_mbs && formik.touched.routine_mbs}
          errorMessage={formik.errors.routine_mbs}
          isRequired={true}
        />
        <InputRadio
          label="Apakah dilakukan pemantauan orang masuk (Skrining malaria)?"
          name="in_person_monitoring"
          options={[
            { label: "Ya", value: "Ya" },
            { label: "Tidak", value: "Tidak" },
          ]}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          selectedOption={formik.values.in_person_monitoring}
          error={
            formik.errors.in_person_monitoring &&
            formik.touched.in_person_monitoring
          }
          errorMessage={formik.errors.in_person_monitoring}
          isRequired={true}
        />
        <InputRadio
          label="Apakah dilakukan pemantauan orang keluar (Skrining malaria)?"
          name="out_person_monitoring"
          options={[
            { label: "Ya", value: "Ya" },
            { label: "Tidak", value: "Tidak" },
          ]}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          selectedOption={formik.values.out_person_monitoring}
          error={
            formik.errors.out_person_monitoring &&
            formik.touched.out_person_monitoring
          }
          errorMessage={formik.errors.out_person_monitoring}
          isRequired={true}
        />
        <div className="button_action">
          <Button
            variant={"tertiary"}
            size={"normal"}
            onClick={() => {
              handlePrevStep();
              setDataToLocalStorage(
                "MMP_INTERVENTION_DATA",
                JSON.stringify(formik.values)
              );
            }}
          >
            Kembali
          </Button>
          <Button
            variant={"primary"}
            size={"normal"}
            onClick={formik.handleSubmit}
            isDisabled={
              formik.isSubmitting ||
              !formik.values.kie ||
              !formik.values.routine_mbs ||
              !formik.values.in_person_monitoring ||
              !formik.values.out_person_monitoring
            }
          >
            Simpan Data
          </Button>
        </div>
      </div>
    </>
  );
};

export default InterventionFormStep;
