import React from "react";
import { useSelector } from "react-redux";
import { MmpTypeOptions, PageMode } from "utils/constant";
import InputSelect from "components/atoms/InputSelect";
import InputField from "components/atoms/InputField";
import MapsComponents from "components/Maps";
import Button from "components/atoms/Button";

const PopulationFormStep = ({
  handlePageModeChange,
  urbanvillages,
  formik,
  lastMmpId,
  mmpTypeCode,
  generateGroupMmpId,
  generateMmpTypeCode,
  handleMapsChange,
  handleNextStep,
}) => {
  const { currentUser } = useSelector((state) => state.authReducer);

  return (
    <>
      <section className="section-title">
        Data Populasi Khusus Malaria (Mobile Migrant Population-MMP)
      </section>
      <div className="form-grouping">
        <div className="row">
          <div className="col-6">
            <InputSelect
              label={"Nama Desa"}
              name={"urbanvillage_id"}
              placeholder={"Pilih Desa"}
              data={urbanvillages}
              onChange={(e) => {
                const selectedValue = e ? e.value : "";
                formik.handleChange("urbanvillage_id")(selectedValue);
                generateGroupMmpId();
              }}
              value={urbanvillages.find(
                (option) => option.value === formik.values.urbanvillage_id
              )}
              isMultipleSelect={false}
              onBlur={formik.handleBlur}
              error={
                formik.errors.urbanvillage_id && formik.touched.urbanvillage_id
              }
              errorMessage={formik?.errors?.urbanvillage_id}
              isRequired={true}
            />
          </div>
          <div className="col-6">
            <InputField
              label={"Nama Dusun"}
              name={"sub_village"}
              placeholder={"Masukkan nama dusun"}
              {...formik.getFieldProps("sub_village")}
              error={formik.errors.sub_village && formik.touched.sub_village}
              errorMessage={formik.errors.sub_village}
              isRequired={true}
            />
          </div>
        </div>
        <div className="maps-input">
          <label htmlFor="" style={{ marginBottom: "12px", fontWeight: "600" }}>
            Pilih Lokasi
          </label>
          <MapsComponents
            onChangeLocation={(address, coordinate) => {
              handleMapsChange(address, coordinate);
            }}
          />
          {/* <div className="warning">
            <p className="text-title">Perhatian</p>
            <ul>
              <li>
                Untuk Kasus Indigenous, titik koordinat adalah tempat penularan
              </li>
              <li>
                Untuk Kasus Import, titik koordinat adalah rumahd/domisili
                pasien
              </li>
              <li>
                Untuk hasil optimal Lokasi Maps, pastikan terhubung dengan
                internet
              </li>
            </ul>
          </div> */}
        </div>
        <InputField
          name={"coordinate"}
          placeholder={"Titik koordinat akan muncul disini"}
          value={formik.values.coordinate}
          readOnly={true}
        />
        <div className="row">
          <div className="col-6">
            <InputSelect
              label={"Jenis MMP"}
              name={"mmp_type"}
              placeholder={"Pilih Jenis MMP"}
              data={MmpTypeOptions}
              onChange={(e) => {
                const selectedValue = e ? e.value : "";
                formik.handleChange("mmp_type")(selectedValue);
                generateMmpTypeCode(selectedValue);
                generateGroupMmpId();
              }}
              value={MmpTypeOptions.find(
                (option) => option.value === formik.values.mmp_type
              )}
              onBlur={formik.handleBlur}
              error={formik.errors.mmp_type && formik.touched.mmp_type}
              errorMessage={formik.errors.mmp_type}
              isRequired={true}
            />
          </div>
          <div className="col-6">
            <InputField
              label={"Nama Ketua Kelompok"}
              name={"group_leader_name"}
              placeholder={"Masukkan nama ketua kelompok"}
              {...formik.getFieldProps("group_leader_name")}
              error={
                formik.errors.group_leader_name &&
                formik.touched.group_leader_name
              }
              errorMessage={formik.errors.group_leader_name}
              isRequired={true}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <InputField
              type={"number"}
              label={"Nomor HP Ketua Kelompok"}
              name={"group_leader_contact"}
              placeholder={"Masukkan nomor HP ketua kelompok"}
              {...formik.getFieldProps("group_leader_contact")}
              error={
                formik.errors.group_leader_contact &&
                formik.touched.group_leader_contact
              }
              errorMessage={formik.errors.group_leader_contact}
              isRequired={true}
            />
          </div>
          <div className="col-6">
            <InputField
              type={"number"}
              label={"Jumlah anggota"}
              name={"total_group_member"}
              placeholder={"Masukkan jumlah anggota kelompok"}
              {...formik.getFieldProps("total_group_member")}
              error={
                formik.errors.total_group_member &&
                formik.touched.total_group_member
              }
              errorMessage={formik.errors.total_group_member}
              isRequired={true}
            />
          </div>
        </div>
        <div className="mmp_group_id_wrapper">
          <div className="data_item">
            <p className="label">Kode Faskes</p>
            <p className="value">
              {currentUser?.fasyankes_id ? currentUser?.fasyankes_id : "-"}
            </p>
          </div>
          <div className="data_item">
            <p className="label">Kode Desa</p>
            <p className="value">
              {formik.values.urbanvillage_id
                ? formik.values.urbanvillage_id
                : "-"}
            </p>
          </div>
          <div className="data_item">
            <p className="label">Jenis MMP</p>
            <p className="value">
              {formik.values.mmp_type
                ? `${mmpTypeCode}|${formik.values.mmp_type}`
                : "-"}
            </p>
          </div>
          <div className="data_item">
            <p className="label">ID (Otomatis)</p>
            <p className="value">{lastMmpId ? lastMmpId : "-"}</p>
          </div>
        </div>
        <InputField
          label={"Jarak dari Fasyankes Terdekat (Kilometer / KM)"}
          placeholder={"Masukkan jarak dari fasyankes derdekat (Kilometer/KM)"}
          name={"fasyankes_distance"}
          {...formik.getFieldProps("fasyankes_distance")}
        />
        <InputField
          type={"textarea"}
          label={"Deskripsi akses ke fasyankes"}
          placeholder={"Masukkan deskripsi akses ke fasyankes"}
          name={"fasyankes_access_desc"}
          {...formik.getFieldProps("fasyankes_access_desc")}
        />
        <div className="button_action">
          <Button
            variant={"tertiary"}
            size={"normal"}
            onClick={() => handlePageModeChange(PageMode.view)}
          >
            Batalkan
          </Button>
          <Button
            variant={"primary"}
            size={"normal"}
            onClick={handleNextStep}
            isDisabled={
              !formik.values.urbanvillage_id ||
              !formik.values.sub_village ||
              !formik.values.mmp_type ||
              !formik.values.group_leader_name ||
              !formik.values.group_leader_contact ||
              !formik.values.total_group_member ||
              !formik.values.group_mmp_id
            }
          >
            Selanjutnya
          </Button>
        </div>
      </div>
    </>
  );
};

export default PopulationFormStep;
