import InputDate from "components/atoms/InputDate";
import InputField from "components/atoms/InputField";
import InputRadio from "components/atoms/InputRadio";
import InputSelect from "components/atoms/InputSelect";
import moment from "moment";
import React, { useEffect } from "react";
import {
  DiseaseDegreeOptions,
  GametositOptions,
  JobsListOptions,
  MedicationStatusOptions,
  PageMode,
  RecurrenceOptions,
  StandarMedicationReasonOptions,
  SymthomaticOptions,
  TreatmentOptions,
  YesAndNoOptions,
} from "utils/constant";
import Button from "components/atoms/Button";

const TreatmentStepForm = ({ onPrevStep, referenceData, formik, pageMode }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function getRecurrenceOptions(parasyteValue) {
    const commonOptions = [{ label: "Baru", value: "Baru" }];

    if (
      parasyteValue?.includes("P.Ovale") ||
      parasyteValue?.includes("P.Vivax") ||
      parasyteValue?.includes("P.Mix")
    ) {
      return [...commonOptions, { label: "Relaps", value: "Relaps" }];
    }

    return commonOptions;
  }

  return (
    <>
      <div className="row">
        <div className="col-sm-6">
          <InputSelect
            label={"Pekerjaan"}
            name={"job"}
            placeholder={"Pilih Pekerjaan"}
            data={JobsListOptions}
            onChange={(e) => {
              const selectedValue = e ? e.value : "";
              formik.handleChange("job")(selectedValue);
              if (selectedValue !== "Lainnya") {
                formik.setFieldValue("other_job", "");
              }
            }}
            onBlur={formik.handleBlur}
            value={JobsListOptions?.find(
              (item) => item.value === formik.values.job
            )}
            error={formik.errors.job && formik.touched.job}
            errorMessage={formik.errors.job}
            isRequired={true}
          />
          {formik.values.job === "Lainnya" && (
            <InputField
              name={"other_job"}
              placeholder={"Masukkan Pekerjaan lainnya"}
              {...formik.getFieldProps("other_job")}
              error={formik.errors.other_job && formik.touched.other_job}
              errorMessage={formik.errors.other_job}
            />
          )}
        </div>
        <div className="col-sm-6">
          <InputSelect
            label={"Derajat Penyakit Malaria"}
            name={"disease_degree"}
            placeholder={"Pilih Derajat Penyakit Malaria"}
            data={DiseaseDegreeOptions}
            onChange={(e) => {
              const selectedValue = e ? e.value : "";
              formik.handleChange("disease_degree")(selectedValue);
            }}
            onBlur={formik.handleBlur}
            value={DiseaseDegreeOptions?.find(
              (item) => item.value === formik.values.disease_degree
            )}
            error={
              formik.errors.disease_degree && formik.touched.disease_degree
            }
            errorMessage={formik.errors.disease_degree}
            isRequired={true}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <InputField
            label={"Suhu Tubuh (Celcius)"}
            name={"body_temperature"}
            placeholder={"Masukkan suhu tubuh dalam (Celcius)"}
            type={"number"}
            isDecimal={true}
            maxLengthVal={4}
            {...formik.getFieldProps("body_temperature")}
          />
        </div>
        <div className="col-sm-6">
          <InputSelect
            label={"Gejala"}
            name={"sympthomatic"}
            placeholder={"Pilih Gejala"}
            data={SymthomaticOptions}
            onChange={(e) => {
              const selectedValue = e ? e.value : "";
              formik.handleChange("sympthomatic")(selectedValue);
              if (selectedValue !== "Lainnya") {
                formik.setFieldValue("other_sympthomatic", "");
              }
              if (selectedValue !== "Demam") {
                formik.setFieldValue("sympthomatic_date", "");
              }
            }}
            onBlur={formik.handleBlur}
            value={SymthomaticOptions?.find(
              (item) => item.value === formik.values.sympthomatic
            )}
          />
          {formik.values.sympthomatic === "Lainnya" && (
            <InputField
              name={"other_sympthomatic"}
              placeholder={"Masukkan Gejala Lainnya"}
              type={"text"}
              {...formik.getFieldProps("other_sympthomatic")}
              error={
                formik.errors.other_sympthomatic &&
                formik.touched.other_sympthomatic
              }
              errorMessage={formik.errors.other_sympthomatic}
            />
          )}
        </div>
      </div>

      {formik.values.sympthomatic !== "Tidak bergejala" &&
        formik.values.sympthomatic !== "" && (
          <InputDate
            label={"Tanggal Mulai Gejala"}
            name={"sympthomatic_date"}
            placeholder={"Pilih Tanggal"}
            dateFormat={"YYYY-MM-DD"}
            onChange={(e) => {
              const selectedDate = moment(e).format("YYYY-MM-DD");
              formik.handleChange("sympthomatic_date")(selectedDate);
            }}
            onBlur={formik.handleBlur}
            value={formik.values.sympthomatic_date}
            error={
              formik.errors.sympthomatic_date &&
              formik.touched.sympthomatic_date
            }
            errorMessage={formik.errors.sympthomatic_date}
            isRequired={true}
          />
        )}

      <div className="row">
        <div className="col-sm-6">
          <InputSelect
            label={"Gametosit"}
            name={"gametosit"}
            placeholder={"Pilih Gametosit"}
            data={GametositOptions}
            onChange={(e) => {
              const selectedValue = e ? e.value : "";
              formik.handleChange("gametosit")(selectedValue);
            }}
            onBlur={formik.handleBlur}
            value={GametositOptions?.find(
              (item) => item.value === formik.values.gametosit
            )}
          />
        </div>
        <div className="col-sm-6">
          <InputField
            label={"Kepadatan Parasit (Per mikro liter)"}
            name={"parasyte_density"}
            type={"number"}
            placeholder={"Masukkan kepadatan parasit"}
            {...formik.getFieldProps("parasyte_density")}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <InputField
            label={"Berat Badan (Kg)"}
            name={"weight"}
            placeholder={"Masukkan berat badan"}
            type={"number"}
            maxLengthVal={3}
            {...formik.getFieldProps("weight")}
            error={formik.errors.weight && formik.touched.weight}
            errorMessage={formik.errors.weight}
            isRequired={true}
          />
        </div>
        <div className="col-sm-6">
          <InputSelect
            label={"Status Anemia"}
            name={"anemia_status"}
            placeholder={"Pilih Status Anemia"}
            data={YesAndNoOptions}
            onChange={(e) => {
              const selectedValue = e ? e.value : "";
              formik.handleChange("anemia_status")(selectedValue);
            }}
            onBlur={formik.handleBlur}
            value={YesAndNoOptions?.find(
              (item) => item.value === formik.values.anemia_status
            )}
          />
        </div>
      </div>

      <InputSelect
        label={"Kekambuhan"}
        name={"recurrence"}
        placeholder={"Pilih Kekambuhan"}
        data={getRecurrenceOptions(formik.values.parasyte)}
        onChange={(e) => {
          const selectedValue = e ? e.value : "";
          formik.handleChange("recurrence")(selectedValue);
        }}
        onBlur={formik.handleBlur}
        value={RecurrenceOptions?.find(
          (item) => item.value === formik.values.recurrence
        )}
        error={formik.errors.recurrence && formik.touched.recurrence}
        errorMessage={formik.errors.recurrence}
        isRequired={true}
      />

      <hr />
      <h3 className="subtitle-section">Pengobatan</h3>
      <InputDate
        label={"Tanggal Pengobatan"}
        name={"medication_date"}
        placeholder={"Pilih Tanggal"}
        dateFormat={"YYYY-MM-DD"}
        onChange={(e) => {
          const selectedDate = moment(e).format("YYYY-MM-DD");
          formik.handleChange("medication_date")(selectedDate);
        }}
        onBlur={formik.handleBlur}
        value={formik.values.medication_date}
      />
      <hr />
      <div className="row">
        <div className="col-sm-6">
          <p style={{ fontSize: "14px", fontWeight: "600", color: "#313131" }}>
            DHP Tablet (Tablet)
          </p>
        </div>
        <div className="col-sm-6">
          <InputField
            name={"dhp_tablet"}
            placeholder={"0"}
            type={"decimal"}
            {...formik.getFieldProps("dhp_tablet")}
            error={formik.errors.dhp_tablet && formik.touched.dhp_tablet}
            errorMessage={formik.errors.dhp_tablet}
          />
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-sm-6">
          <p style={{ fontSize: "14px", fontWeight: "600", color: "#313131" }}>
            Primaquin Tablet (Tablet)
          </p>
        </div>
        <div className="col-sm-6">
          <InputField
            name={"primaquin_tablet"}
            placeholder={"0"}
            type={"decimal"}
            {...formik.getFieldProps("primaquin_tablet")}
            error={
              formik.errors.primaquin_tablet && formik.touched.primaquin_tablet
            }
            errorMessage={formik.errors.primaquin_tablet}
          />
          <div className="warning-message" style={{ marginTop: "12px" }}>
            <div className="message">
              Primaquin tidak boleh diberikan kepada ibu hamil, menyusui, dan
              anak di bawah usia 6 bulan
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-sm-6">
          <p style={{ fontSize: "14px", fontWeight: "600", color: "#313131" }}>
            Artesunat Injeksi (Vial)
          </p>
        </div>
        <div className="col-sm-6">
          <InputField
            name={"artesunat_injeksi"}
            placeholder={"0"}
            type={"decimal"}
            {...formik.getFieldProps("artesunat_injeksi")}
            error={
              formik.errors.artesunat_injeksi &&
              formik.touched.artesunat_injeksi
            }
            errorMessage={formik.errors.artesunat_injeksi}
          />
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-sm-6">
          <p style={{ fontSize: "14px", fontWeight: "600", color: "#313131" }}>
            Kina Tablet (Tablet)
          </p>
        </div>
        <div className="col-sm-6">
          <InputField
            name={"kina_tablet"}
            placeholder={"0"}
            type={"decimal"}
            {...formik.getFieldProps("kina_tablet")}
            error={formik.errors.kina_tablet && formik.touched.kina_tablet}
            errorMessage={formik.errors.kina_tablet}
          />
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-sm-6">
          <p style={{ fontSize: "14px", fontWeight: "600", color: "#313131" }}>
            Kina Injeksi (Ampul)
          </p>
        </div>
        <div className="col-sm-6">
          <InputField
            name={"kina_injeksi"}
            placeholder={"0"}
            type={"decimal"}
            {...formik.getFieldProps("kina_injeksi")}
            error={formik.errors.kina_injeksi && formik.touched.kina_injeksi}
            errorMessage={formik.errors.kina_injeksi}
          />
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-sm-6">
          <p style={{ fontSize: "14px", fontWeight: "600", color: "#313131" }}>
            Dosisiklin (Tablet)
          </p>
        </div>
        <div className="col-sm-6">
          <InputField
            name={"doksisiklin"}
            placeholder={"0"}
            type={"decimal"}
            {...formik.getFieldProps("doksisiklin")}
            error={formik.errors.doksisiklin && formik.touched.doksisiklin}
            errorMessage={formik.errors.doksisiklin}
          />
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-sm-6">
          <p style={{ fontSize: "14px", fontWeight: "600", color: "#313131" }}>
            Tetrasiklin (Tablet)
          </p>
        </div>
        <div className="col-sm-6">
          <InputField
            name={"tetrasiklin"}
            placeholder={"0"}
            type={"decimal"}
            {...formik.getFieldProps("tetrasiklin")}
            error={formik.errors.tetrasiklin && formik.touched.tetrasiklin}
            errorMessage={formik.errors.tetrasiklin}
          />
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-sm-6">
          <p style={{ fontSize: "14px", fontWeight: "600", color: "#313131" }}>
            Klindamisin (Tablet)
          </p>
        </div>
        <div className="col-sm-6">
          <InputField
            name={"Klindamisin"}
            placeholder={"0"}
            type={"decimal"}
            {...formik.getFieldProps("klindamisin")}
            error={formik.errors.klindamisin && formik.touched.klindamisin}
            errorMessage={formik.errors.klindamisin}
          />
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-sm-6">
          <InputSelect
            label={"Status Pengobatan (otomatis)"}
            name={"standard_medication_status"}
            placeholder={"Status Pengobatan"}
            data={MedicationStatusOptions}
            onChange={(e) => {
              const selectedValue = e ? e.value : "";
              formik.handleChange("standard_medication_status")(selectedValue);
            }}
            onBlur={formik.handleBlur}
            value={MedicationStatusOptions?.find(
              (item) => item.value === formik.values.standard_medication_status
            )}
            disabled={true}
          />
        </div>
        <div className="col-sm-6">
          <InputSelect
            label={"Jika tidak diobati standar, alasannya?"}
            name={"not_standard_medication_reason"}
            placeholder={"Pilih Alasan"}
            data={StandarMedicationReasonOptions}
            onChange={(e) => {
              const selectedValue = e ? e.value : "";
              formik.handleChange("not_standard_medication_reason")(
                selectedValue
              );
              if (selectedValue !== "Lainnya") {
                formik.setFieldValue(
                  "other_not_standard_medication_reason",
                  ""
                );
              }
            }}
            onBlur={formik.handleBlur}
            value={StandarMedicationReasonOptions?.find(
              (item) =>
                item.value === formik.values.not_standard_medication_reason
            )}
          />
          {formik.values.not_standard_medication_reason === "Lainnya" && (
            <InputField
              name={"other_not_standard_medication_reason"}
              placeholder={"Masukkan Alasan Lainnya"}
              {...formik.getFieldProps("other_not_standard_medication_reason")}
              error={
                formik.errors.other_not_standard_medication_reason &&
                formik.touched.other_not_standard_medication_reason
              }
              errorMessage={formik.errors.other_not_standard_medication_reason}
            />
          )}
        </div>
      </div>
      <InputSelect
        label={"Perawatan"}
        name={"treatment"}
        placeholder={"Pilih Perawatan"}
        data={TreatmentOptions}
        onChange={(e) => {
          const selectedValue = e ? e.value : "";
          formik.handleChange("treatment")(selectedValue);
        }}
        onBlur={formik.handleBlur}
        value={TreatmentOptions?.find(
          (item) => item.value === formik.values.treatment
        )}
        error={formik.errors.treatment && formik.touched.treatment}
        errorMessage={formik.errors.treatment}
        isRequired={true}
      />

      <InputRadio
        label="Apakah Kematian dengan Malaria?"
        name="death_with_malaria"
        options={YesAndNoOptions}
        onChange={(e) => {
          const selectedValue = e.target.value;
          formik.handleChange("death_with_malaria")(selectedValue);
        }}
        onBlur={formik.handleBlur}
        selectedOption={formik.values.death_with_malaria}
        error={
          formik.errors.death_with_malaria && formik.touched.death_with_malaria
        }
        errorMessage={formik.errors.death_with_malaria}
        isRequired={true}
      />

      <InputRadio
        label="Apakah Dirujuk Ke Faskes Lain?"
        name="referred_to_other_faskes"
        options={YesAndNoOptions}
        onChange={(e) => {
          const selectedValue = e.target.value;
          formik.handleChange("referred_to_other_faskes")(selectedValue);
          if (selectedValue === "Tidak") {
            formik.setFieldValue("ref_province_id", "");
            formik.setFieldValue("ref_district_id", "");
            formik.setFieldValue("ref_fasyankes_id", "");
            formik.setFieldValue("ref_date", "");
            formik.setFieldValue("ref_reason", "");
          }
        }}
        disabled={pageMode === PageMode.edit && formik.values.referred_to_other_faskes === "Ya"}
        onBlur={formik.handleBlur}
        selectedOption={formik.values.referred_to_other_faskes}
        error={
          formik.errors.referred_to_other_faskes &&
          formik.touched.referred_to_other_faskes
        }
        errorMessage={formik.errors.referred_to_other_faskes}
        isRequired={true}
      />

      {formik.values.referred_to_other_faskes === "Ya" && (
        <>
          <InputField
            label={"Alasan Dirujuk"}
            name={"ref_reason"}
            placeholder={"Masukkan Alasan Dirujuk"}
            type={"textarea"}
            {...formik.getFieldProps("ref_reason")}
            error={formik.errors.ref_reason && formik.touched.ref_reason}
            errorMessage={formik.errors.ref_reason}
            isRequired={true}
          />
          <InputDate
            label={"Tanggal Dirujuk"}
            name={"ref_date"}
            placeholder={"Pilih Tanggal"}
            dateFormat={"YYYY-MM-DD"}
            onChange={(e) => {
              const selectedDate = moment(e).format("YYYY-MM-DD");
              formik.handleChange("ref_date")(selectedDate);
            }}
            onBlur={formik.handleBlur}
            value={formik.values.ref_date}
            error={formik.errors.ref_date && formik.touched.ref_date}
            errorMessage={formik.errors.ref_date}
            isRequired={true}
          />
          <InputSelect
            label={"Provinsi Rujukan"}
            name={"ref_province_id"}
            placeholder={"Pilih Provinsi"}
            data={referenceData.provincies}
            onChange={(e) => {
              const selectedValue = e ? e.value : "";
              formik.handleChange("ref_province_id")(selectedValue);
              if (selectedValue) {
                formik.setFieldValue("ref_district_id", "");
                formik.setFieldValue("ref_fasyankes_id", "");
              } else {
                formik.setFieldValue("ref_district_id", "");
                formik.setFieldValue("ref_fasyankes_id", "");
              }
            }}
            onBlur={formik.handleBlur}
            value={referenceData.provincies?.find(
              (item) => item.value === formik.values.ref_province_id
            )}
            error={
              formik.errors.ref_province_id && formik.touched.ref_province_id
            }
            errorMessage={formik.errors.ref_province_id}
            isRequired={true}
          />
          <InputSelect
            label={"Kabupaten/Kota Rujukan"}
            name={"ref_district_id"}
            placeholder={"Pilih Kab/Kota"}
            data={referenceData.districts}
            onChange={(e) => {
              const selectedValue = e ? e.value : "";
              formik.handleChange("ref_district_id")(selectedValue);
              if (selectedValue) {
                formik.setFieldValue("ref_fasyankes_id", "");
              } else {
                formik.setFieldValue("ref_fasyankes_id", "");
              }
            }}
            onBlur={formik.handleBlur}
            value={referenceData.districts?.find(
              (item) => item.value === formik.values.ref_district_id
            )}
            error={
              formik.errors.ref_district_id && formik.touched.ref_district_id
            }
            errorMessage={formik.errors.ref_district_id}
            isRequired={true}
            isDisabled={!formik.values.ref_province_id}
          />
          <InputSelect
            label={"Fasyankes Rujukan"}
            name={"ref_fasyankes_id"}
            placeholder={"Pilih Fasyankes"}
            data={referenceData.fasyankes}
            onChange={(e) => {
              const selectedValue = e ? e.value : "";
              formik.handleChange("ref_fasyankes_id")(selectedValue);
            }}
            onBlur={formik.handleBlur}
            value={referenceData.fasyankes?.find(
              (item) => item.value === formik.values.ref_fasyankes_id
            )}
            error={
              formik.errors.ref_fasyankes_id && formik.touched.ref_fasyankes_id
            }
            errorMessage={formik.errors.ref_fasyankes_id}
            isRequired={true}
            isDisabled={!formik.values.ref_district_id}
          />
        </>
      )}
      <hr />
      <div className="form_action">
        <Button variant={"tertiary"} size={"normal"} onClick={onPrevStep}>
          Kembali
        </Button>
        <Button
          variant={"primary"}
          size={"normal"}
          onClick={formik.handleSubmit}
          isDisabled={
            formik.isSubmitting ||
            !formik.values.job ||
            !formik.values.disease_degree ||
            !formik.values.weight ||
            !formik.values.treatment ||
            !formik.values.death_with_malaria ||
            !formik.values.referred_to_other_faskes ||
            (formik.values.referred_to_other_faskes === "Ya" &&
              (!formik.values.ref_reason ||
                !formik.values.ref_date ||
                !formik.values.ref_province_id ||
                !formik.values.ref_district_id ||
                !formik.values.ref_fasyankes_id)) ||
            (formik.values.sympthomatic !== "Tidak bergejala" &&
              formik.values.sympthomatic &&
              !formik.values.sympthomatic_date)
          }
        >
          Simpan Data
        </Button>
      </div>
    </>
  );
};

export default TreatmentStepForm;
