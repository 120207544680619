import { TableComponent } from "components";
import React, { Component } from "react";
import { PlusCircle } from "react-feather";

export default class Table extends Component {
  render() {
    const { cadreData, data, onEdit, onRemove, changeMode, loading, onPageChange } =
      this.props;

    return (
      <div>
        <div className="dashboard-wrapper">
          <h1 className="title font-weight-bold">Data kinerja kader endemis tinggi perbulan</h1>
          <div className="content-wrapper mt-4">
            <div className="row">
              <div className="col-sm-8">
                <button
                  className="btn btn-teal font-weight-bold"
                  onClick={() => changeMode("add")}
                >
                  <PlusCircle className="mr-2" />
                  Tambah Data Kinerja Kader
                </button>
              </div>
            </div>
            <hr />
            <div className="cadre-info">
              <h3>Nama Kader : {cadreData.name}</h3>
            </div>
            <hr />

            <TableComponent
              loading={loading}
              data={data?.cadre_performances}
              headers={[
                "Bulan",
                "Tahun",
                "Jumlah Rumah Tanggung Jawab",
                "Logistik Yang Diterima",
                "Jumlah pemeriksaan kader dalam bulan ini",
                "Jumlah Suspek",
                "Jumlah sasaran survey kontak",
                "Keaktifan Berdasarkan Target Kunjungan Rumah",
                "Keaktifan Berdasarkan Target Pemeriksaan",
              ]}
              cols={[
                "month",
                "year",
                "total_house_responsibility",
                "logistic_receive",
                "total_cadre_inspection",
                "total_suspect",
                "total_survei_target",
                "cadre_active",
                "cadre_active_by_cadre_inspection",
              ]}
              btnEdit={{ onClick: onEdit }}
              btnRemove={{ onClick: onRemove }}
              currentPage={data?.currentPage}
              total={data?.total}
              limit={data?.limit}
              nextPage={data?.nextPage}
              previousPage={data?.previousPag}
              onPageChange={onPageChange}
            />
            
          </div>
        </div>
      </div>
    );
  }
}
