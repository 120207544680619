import DashboardWrap from "components/DashboardWrap";
import React, { Component } from "react";
import FilterDataComponent from "./FilterData";
class DashboardPage extends Component {
  componentDidMount() {
    document.title = "SISMAL | Dashboard";
  }

  render() {
    return (
      <div className="dashboard-wrapper">
        <h1 className='title font-weight-bold'>Dashboard</h1>
        <div className="content-wrapper mt-4">
          <FilterDataComponent title="Selamat Datang di SISMAL V3"></FilterDataComponent>
        </div>
      </div>
    );
  }
}

export default DashboardPage;
