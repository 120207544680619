import api from "../utils/api";
import { errHandler } from "../utils/common";

export default class Notification {
  get = (opt = { regionId: "" }) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/notification/get`, { params: opt })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  reset = (opt = { regionId: "" }) => {
    return new Promise((resolve, reject) => {
      api
        .post(`/api/notification/reset`, opt)
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };
}
