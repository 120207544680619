import React, { Component, Fragment } from "react";
import { PlusCircle } from "react-feather";
import { Button, InputSelect, TableComponent } from "components";
import InputDateTime from "components/InputDateTime";
import moment from "moment";
import Wilayah from "services/Wilayah";
import { PageMode, Source, UserLevel } from "utils/constant";
import UserVerification from "services/UserVerification";
import { isEmpty } from "utils/common";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";

const WilayahService = new Wilayah();
const UserVerService = new UserVerification();

export default class Table extends Component {
  constructor(props) {
    super(props);
    this.state = {
      year: "",
      year_error: false,
      provincies: [],
      province_id: "",
      districts: [],
      district_id: "",

      page: 1,
      limit: 10,
      loading: false,
      searchValue: null,
      searchBy: null,
      data: [],

      selectedTab: 1,
    };
  }

  componentDidMount = () => {
    this._isMounted = true;
    const { user } = this.props;
    this.getProvincies();
    this.getDistricts();
    this.setState(
      {
        province_id: user.province_id?.split("|")[0],
        district_id: user.district_id?.split("|")[0],
        year: moment().format("YYYY"),
      },
      () => {
        this.getAllData();
      }
    );
  };

  onRemove = (id) => {
    confirmAlert({
      title: "Anda yakin ingin menghapus data ini?",
      message: "Are you sure want to delete this data?",
      buttons: [
        {
          label: "Ya",
          onClick: async () => {
            await UserVerService.remove(id)
              .then((res) => {
                this.getAllData();
                toast.success("Data verifikasi user berhasil dihapus", {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              })
              .catch((err) => {
                console.log(err);
              });
          },
        },
        {
          label: "Tidak",
        },
      ],
    });
  };

  getProvincies = async () => {
    await WilayahService.getAll(Source.PROVINCE, { page: 1, limit: 999 })
      .then((res) => {
        this.setState({
          provincies: res.province,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getDistricts = async (province_id) => {
    await WilayahService.getAll(Source.DISTRICT, {
      page: 1,
      limit: 999,
      searchBy: "province_id",
      searchValue: province_id,
    })
      .then((res) => {
        this.setState({
          districts: res.district,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onFilter = () => {
    this.props.onFilter(moment(this.state.year).format("YYYY"));
  };

  handleInputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  mapper = (e) => {
    const options = [];
    e.map((x) => {
      options.push({ value: `${x.id}`, label: x.name });
    });
    return options;
  };

  onPageChange = (page) => {
    let lvl;
    if (this.state.selectedTab === 2) {
      lvl = "District";
    } else if (this.state.selectedTab === 3) {
      lvl = "Province";
    } else {
      lvl = "Fasyankes";
    }
    this.setState({ page }, () => {
      this.getAllData(lvl);
    });
  };

  onSearch = (e) => {
    let lvl;
    if (this.state.selectedTab === 2) {
      lvl = "District";
    } else if (this.state.selectedTab === 3) {
      lvl = "Province";
    } else {
      lvl = "Fasyankes";
    }
    const val = e.target.value;
    if (val) {
      this.setState({ searchBy: "first_name", searchValue: `${val}` }, () => {
        this.getAllData(lvl);
      });
    } else {
      this.setState({ searchBy: null, searchValue: null }, () => {
        this.getAllData(lvl);
      });
    }
  };

  onFilter = (e) => {
    const { year, selectedTab } = this.state;
    if (isEmpty(year) || year === "Invalid date") {
      this.setState({ year_error: true });
    } else {
      this.setState({ year_error: false }, () => {
        let lvl;
        if (selectedTab === 2) {
          lvl = "District";
        } else if (selectedTab === 3) {
          lvl = "Province";
        } else {
          lvl = "Fasyankes";
        }
        this.getAllData(lvl);
      });
    }
  };

  getAllData = (lvl = "Fasyankes") => {
    this.setState({ loading: true, selectedItem: null }, async () => {
      const {
        page,
        limit,
        searchBy,
        searchValue,
        year,
        province_id,
        district_id,
      } = this.state;
      let srchBy = searchBy ? `,${searchBy}` : "";
      let srchVal = searchValue ? `,${searchValue}` : "";

      switch (lvl) {
        case "Fasyankes":
          srchBy += ",level,province_id";
          srchVal += `,${lvl},${province_id}`;
          if (district_id) {
            srchBy += ",district_id";
            srchVal += `,${district_id}`;
          }
          break;
        case "District":
          srchBy += ",level,province_id";
          srchVal += `,${lvl},${province_id}`;
          break;
        case "Province":
          srchBy += ",level";
          srchVal += `,${lvl}`;
      }

      await UserVerService.getAll({
        page,
        limit,
        searchValue: `${year}${srchVal}`,
        searchBy: `year${srchBy}`,
      })
        .then((data) => {
          if (this._isMounted) {
            this.setState({
              loading: false,
              data,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({ loading: false });
        });
    });
  };

  render() {
    const { changeMode, onEdit, user } = this.props;
    const {
      provincies,
      province_id,
      data,
      loading,
      selectedTab,
      districts,
      district_id,
      year_error,
    } = this.state;

    return (
      <div>
        <div className="d-flex justify-content-start py-3">
          <div>
            <ul
              className="nav nav-pills nav-success"
              id="pills-tab"
              role="tablist"
            >
              {/* Tab Fasyankes */}
              <li className="nav-item submenu">
                <a
                  className={`nav-link ${
                    selectedTab === 1 ? "show active" : ""
                  }`}
                  id="pills-fasyankes-tab"
                  data-toggle="pill"
                  href="#pills-fasyankes"
                  role="tab"
                  aria-controls="pills-fasyankes"
                  aria-selected="true"
                  onClick={() => {
                    this.setState(
                      {
                        selectedTab: 1,
                        year: moment().format("YYYY"),
                        province_id: province_id || 11,
                        district_id: district_id || 1101,
                        page: 1,
                        limit: 10,
                        loading: false,
                        searchValue: null,
                        searchBy: null,
                        data: [],
                      },
                      () => {
                        this.getAllData("Fasyankes");
                      }
                    );
                  }}
                >
                  Fasyankes
                </a>
              </li>
              {/* Tab District */}
              {(user.level === UserLevel.Province ||
                user.level === UserLevel.National) && (
                <li className="nav-item submenu">
                  <a
                    className={`nav-link ${
                      selectedTab === 2 ? "show active" : ""
                    }`}
                    id="pills-kab-tab"
                    data-toggle="pill"
                    href="#pills-kab"
                    role="tab"
                    aria-controls="pills-kab"
                    aria-selected="false"
                    onClick={() => {
                      this.setState(
                        {
                          selectedTab: 2,
                          page: 1,
                          limit: 10,
                          loading: false,
                          searchValue: null,
                          searchBy: null,
                          data: [],
                        },
                        () => {
                          this.getAllData("District");
                        }
                      );
                    }}
                  >
                    Kabupaten
                  </a>
                </li>
              )}
              {/* Tab Province */}
              {user.level === UserLevel.National && (
                <li className="nav-item submenu">
                  <a
                    className={`nav-link ${
                      selectedTab === 3 ? "show active" : ""
                    }`}
                    id="pills-prov-tab"
                    data-toggle="pill"
                    href="#pills-prov"
                    role="tab"
                    aria-controls="pills-prov"
                    aria-selected="false"
                    onClick={() => {
                      this.setState(
                        {
                          selectedTab: 3,
                          page: 1,
                          limit: 10,
                          loading: false,
                          searchValue: null,
                          searchBy: null,
                          data: [],
                        },
                        () => {
                          this.getAllData("Province");
                        }
                      );
                    }}
                  >
                    Provinsi
                  </a>
                </li>
              )}
            </ul>
          </div>
        </div>

        <div className="tab-content mt-2 mb-3" id="pills-tabContent">
          {/* Tab Fasyankes */}
          <div
            className={`tab-pane fade ${
              selectedTab === 1 ? "show active" : ""
            }`}
            id="pills-fasyankes"
            role="tabpanel"
            aria-labelledby="pills-fasyankes-tab"
          >
            <Fragment>
              <div className="row">
                <div className="col">
                  <div className="form-inline">
                    <InputDateTime
                      label="Tahun"
                      name="year"
                      dateFormat="YYYY"
                      onChange={(e) =>
                        this.handleInputChange({
                          preventDefault: () => {},
                          target: {
                            name: "year",
                            value: moment(e).format("YYYY"),
                          },
                        })
                      }
                      value={this.state.year}
                      isInline={true}
                      hasError={year_error}
                      errorMessage="Tahun wajib dipilih"
                    />
                    <InputSelect
                      label="Provinsi"
                      name="province_id"
                      placeholder="Pilih"
                      data={this.mapper(provincies)}
                      onChange={(e) => {
                        this.handleInputChange(e);
                        this.setState({
                          district_id: "",
                        });
                        this.getDistricts(e.target.value);
                      }}
                      value={province_id}
                      isInline={true}
                      isDisabled={user.level === "District" ? true : false}
                    />
                    <InputSelect
                      label="Kabupaten"
                      name="district_id"
                      placeholder="Pilih"
                      data={this.mapper(districts)}
                      onChange={this.handleInputChange}
                      value={district_id}
                      isInline={true}
                      isDisabled={user.level === "District" ? true : false}
                    />
                    <div className="form-group d-flex justify-content-end">
                      <Button onClick={this.onFilter} isPrimary>
                        Terapkan
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-8" style={{ padding: "10px" }}>
                  <button
                    className="btn btn-teal font-weight-bold"
                    onClick={() => changeMode(PageMode.add, "Fasyankes")}
                  >
                    <PlusCircle className="mr-2" />
                    Tambah Data
                  </button>
                </div>
                <div className="col-sm-4">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control bg-light"
                      placeholder="Cari nama..."
                      onChange={this.onSearch}
                    />
                  </div>
                </div>
              </div>
            </Fragment>
          </div>
          {/* Tab District */}
          {(user.level === UserLevel.Province ||
            user.level === UserLevel.National) && (
            <div
              className={`tab-pane fade ${
                selectedTab === 2 ? "show active" : ""
              }`}
              id="pills-kab"
              role="tabpanel"
              aria-labelledby="pills-kab-tab"
            >
              <Fragment>
                <div className="row">
                  <div className="col">
                    <div className="form-inline">
                      <InputDateTime
                        label="Tahun"
                        name="year"
                        dateFormat="YYYY"
                        onChange={(e) =>
                          this.handleInputChange({
                            preventDefault: () => {},
                            target: {
                              name: "year",
                              value: moment(e).format("YYYY"),
                            },
                          })
                        }
                        value={this.state.year}
                        isInline={true}
                        hasError={year_error}
                        errorMessage="Tahun wajib dipilih"
                      />

                      <InputSelect
                        label="Provinsi"
                        name="province_id"
                        placeholder="Pilih"
                        data={this.mapper(provincies)}
                        onChange={this.handleInputChange}
                        value={province_id}
                        isInline={true}
                        isDisabled={user.level === "District" ? true : false}
                      />

                      <div className="form-group d-flex justify-content-end">
                        <Button onClick={this.onFilter} isPrimary>
                          Terapkan
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-8" style={{ padding: "10px" }}>
                    <button
                      className="btn btn-teal font-weight-bold"
                      onClick={() => changeMode(PageMode.add, "District")}
                    >
                      <PlusCircle className="mr-2" />
                      Tambah Data
                    </button>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control bg-light"
                        placeholder="Cari nama..."
                        onChange={this.onSearch}
                      />
                    </div>
                  </div>
                </div>
              </Fragment>
            </div>
          )}
          {/* Tab Province */}
          {user.level === UserLevel.National && (
            <div
              className={`tab-pane fade ${
                selectedTab === 3 ? "show active" : ""
              }`}
              id="pills-prov"
              role="tabpanel"
              aria-labelledby="pills-prov-tab"
            >
              <Fragment>
                <div className="row">
                  <div className="col">
                    <div className="form-inline">
                      <InputDateTime
                        label="Tahun"
                        name="year"
                        dateFormat="YYYY"
                        onChange={(e) =>
                          this.handleInputChange({
                            preventDefault: () => {},
                            target: {
                              name: "year",
                              value: moment(e).format("YYYY"),
                            },
                          })
                        }
                        value={this.state.year}
                        isInline={true}
                        hasError={year_error}
                        errorMessage="Tahun wajib dipilih"
                      />
                      <div className="form-group d-flex justify-content-end">
                        <Button onClick={this.onFilter} isPrimary>
                          Terapkan
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-8" style={{ padding: "10px" }}>
                    <button
                      className="btn btn-teal font-weight-bold"
                      onClick={() => changeMode(PageMode.add, "Province")}
                    >
                      <PlusCircle className="mr-2" />
                      Tambah Data
                    </button>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control bg-light"
                        placeholder="Cari nama..."
                        onChange={this.onSearch}
                      />
                    </div>
                  </div>
                </div>
              </Fragment>
            </div>
          )}
        </div>

        <TableComponent
          loading={loading}
          data={data?.user_verifications}
          headers={["Nama Pengguna", "Username", "Tahun", "Mode"]}
          cols={["user.first_name", "user.username", "year", "mode"]}
          btnEdit={{ onClick: onEdit }}
          btnRemove={{ onClick: this.onRemove }}
          currentPage={data?.currentPage}
          total={data?.total}
          limit={data?.limit}
          nextPage={data?.nextPage}
          previousPage={data?.previousPag}
          onPageChange={this.onPageChange}
          withoutActionBtn={user.level === "District" ? true : false}
        />
      </div>
    );
  }
}
