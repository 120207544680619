import React, { useState, useEffect } from "react";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  PaginationState,
  getPaginationRowModel,
} from "@tanstack/react-table";
import InputSelect from "components/atoms/InputSelect";
import {
  ChevronLeft,
  ChevronRight,
  ChevronsLeft,
  ChevronsRight,
} from "react-feather";

import ZeroReport from "services/ZeroReport";
import TableDataComponent from "components/organisms/TableData";
const ZeroReportService = new ZeroReport();

export default function TableData({ periode, level }) {
  const [data, setData] = useState({
    page: 1,
    limit: 10,
    data: [],
    totalData: 0,
    currentPage: 1,
    nextPage: null,
    previousPage: null,
  });

  const zeroReportCol = [
    {
      header: "Provinsi",
      accessorKey: "province_id",
      // cell: ({ row }) => row.original.province_id?.split("|")[1],
    },
    {
      header: "Kab/Kota",
      accessorKey: "district_id",
      // cell: ({ row }) => row.original.district_id?.split("|")[1],
    },
    {
      header: "Faskes Pencatat",
      accessorKey: "fasyankes_id",
      cell: ({ row }) => row.original.fasyankes_id?.split("|")[1],
    },
    {
      header: "Tahun",
      accessorKey: "year",
    },
    {
      header: "Bulan",
      accessorKey: "month",
    },
    {
      header: "Zero Reporting?",
      accessorKey: "is_zero_reporting",
    },
  ];

  const table = useReactTable({
    columns: zeroReportCol,
    data: data?.data,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const fetchData = async () => {
    const response = await ZeroReportService.getAll({
      searchBy: 'fasyankes_id,year,month',
      searchValue: `${level.value},${periode.value.year},${periode.value.month}`,
    });
    setData((prevState) => ({
      ...prevState,
      data: response?.zero_reports,
      limit: response?.limit,
      currentPage: response?.currentPage,
      totalData: response?.total,
      nextPage: response?.nextPage,
      previousPage: response?.previousPage,
    }));
  };
  useEffect(() => {
    fetchData();
  }, [periode, level]);

  return (
    <div className="p-2">
      <div className="table-responsive">
        <TableDataComponent
          columnLength={zeroReportCol?.length}
          tableInstance={table}
          total={data?.total}
          currentPage={data?.currentPage}
          limit={data?.limit}
          nextPage={data?.nextPage}
          previousPage={data?.previousPage}
        />
      </div>
    </div>
  );
}
