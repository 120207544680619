import React from "react";
import { isEmpty, monthList } from "utils/common";
import { UserLevel } from "utils/constant";
import { sum } from "lodash";

const TableComponent = ({
  currentLevel,
  reportsData,
  tableRef,
  reportInfo,
}) => {
  const calculateTotal = () => {
    return reportsData.reduce(
      (total, item) => {
        total.total_sasaran_bumil += parseInt(item.sasaran_bumil ?? 0);
        total.total_sasaran_balita += parseInt(item.sasaran_balita ?? 0);
        total.total_bumil_k1 += parseInt(item.bumil_k1 ?? 0);
        total.total_bumil_scren += parseInt(item.bumil_scren ?? 0);
        total.total_bumil_scren_kelambu += parseInt(
          item.bumil_scren_kelambu ?? 0
        );
        total.total_kunj_balita_sakit += parseInt(item.kunj_balita_sakit ?? 0);
        total.total_kunj_balita_sakit_perika_mal += parseInt(
          item.kunj_balita_sakit_perika_mal ?? 0
        );
        return total;
      },
      {
        total_sasaran_bumil: 0,
        total_sasaran_balita: 0,
        total_bumil_k1: 0,
        total_bumil_scren: 0,
        total_bumil_scren_kelambu: 0,
        total_kunj_balita_sakit: 0,
        total_kunj_balita_sakit_perika_mal: 0,
      }
    );
  };

  let levelName = "";
  if (currentLevel === "national") {
    levelName = "Nasional";
  } else if (currentLevel === "province") {
    levelName = "Provinsi";
  } else if (currentLevel === "district") {
    levelName = "Kabupaten/Kota";
  } else {
    levelName = "Fasyankes";
  }

  let periodeName = "";

  if (reportInfo?.statusPeriode === "yearly") {
    periodeName = "Tahunan";
  } else {
    periodeName = "Bulanan";
  }

  let data = reportInfo?.fasyankes;
  let parts = data?.split(",");
  let faskesName = parts?.map(function (part) {
    return part?.split("|")[1];
  });
  let result = faskesName?.join(", ");

  function isNumberBetween(number, min, max) {
    return number >= min && number <= max;
  }

  const RenderBulanItems = ({ item }) => {
    return (
      <>
        {Array.from({ length: 12 }).map((_, i) => (
          <td
            style={{
              background: (!isNumberBetween(i + 1, parseInt(reportInfo?.periode?.month_start), parseInt(reportInfo?.periode?.month_end)) || isIfProp(item)) && "black",
              color: (!isNumberBetween(i + 1, parseInt(reportInfo?.periode?.month_start), parseInt(reportInfo?.periode?.month_end)) || isIfProp(item)) && "white",
            }}
            key={i + 1}
          >
            {item[`month_${i + 1}`] ?? 0}
          </td>
        ))}
      </>
    );
  };

  const RenderBulanItemsTotal = ({ reportsData, item }) => {
    return (
      <>
        {Array.from({ length: 12 }).map((_, i) => {
          const totalMonthUsage = reportsData
            ?.filter(
              (reportItem) => reportItem.parent_id === item.parent_id
            )
            .reduce(
              (sum, reportItem) =>
                sum + parseInt(reportItem[`month_${i + 1}`] ?? 0),
              0
            );
          return (
            <td
              style={{
                background:
                  !isNumberBetween(
                    i + 1,
                    parseInt(reportInfo?.periode?.month_start),
                    parseInt(reportInfo?.periode?.month_end)
                  ) && "black",
                color:
                  isNumberBetween(
                    i + 1,
                    parseInt(reportInfo?.periode?.month_start),
                    parseInt(reportInfo?.periode?.month_end)
                  ) && "white",
              }}
              key={i + 1}
            >
              {totalMonthUsage}
            </td>
          );
        })}
      </>
    );
  };

  const isIfProp = (item) => {
    return (
      `${item?.district_id}` === `Z999${item.province_id}` ||
      `${item?.area_id}` === `Z999${item.parent_id}`
    );
  };

  return (
    <div
      className="table-table-responsive"
      style={{ height: "560px", overflow: "scroll" }}
      ref={tableRef}
    >
      <table>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none" }}>
            <h3>SISMAL v3, LOGISTIK PEMAKAIAN</h3>
          </th>
        </tr>
      </table>
      <table>
        <thead>
          <tr style={{ border: "none" }}>
            <th
              width="200"
              style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}
            >
              Periode Laporan
            </th>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              : {periodeName}
            </th>
          </tr>
          <tr style={{ border: "none" }}>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              Tahun Penggunaan
            </th>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              : {reportInfo?.periode?.year}
            </th>
          </tr>
          <tr style={{ border: "none" }}>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              Bulan Penggunaan
            </th>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              : {reportInfo?.periode?.month_start} -{" "}
              {reportInfo?.periode?.month_end}
            </th>
          </tr>
          <tr style={{ border: "none" }}>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              Tahun Stok
            </th>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              : {reportInfo?.periodeStock?.year}
            </th>
          </tr>
          <tr style={{ border: "none" }}>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              Bulan Stok
            </th>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              : {reportInfo?.periodeStock?.month_start}
            </th>
          </tr>
          <tr style={{ border: "none" }}>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              Level
            </th>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              : {levelName}
            </th>
          </tr>
          <tr style={{ border: "none" }}>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              Provinsi
            </th>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              :{" "}
              {!reportInfo?.province
                ? "Semua Provinsi"
                : reportInfo?.province?.split("|")[1]}
            </th>
          </tr>
          <tr style={{ border: "none" }}>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              Kab/Kota
            </th>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              :{" "}
              {!reportInfo?.district
                ? "Semua Kab/Kota"
                : reportInfo?.district?.split("|")[1]}
            </th>
          </tr>
          <tr style={{ border: "none" }}>
            <th style={{ border: "none", paddingTop: 0 }}>Fasyankes</th>
            <th style={{ border: "none", paddingTop: 0 }}>
              : {!reportInfo?.fasyankes ? "Semua Fasyankes" : result}
            </th>
          </tr>
        </thead>
      </table>
      {/* {renderTableByLevel()} */}
      <table>
        <thead>
          <tr>
            <th rowSpan={3}>No</th>
            <th rowSpan={3}>Provinsi</th>
            <th rowSpan={3}>Kabupaten/Kota</th>
            {Array.from({ length: 12 }, (_, i) => (
              <th key={i + 1} rowSpan={3}>
                {monthList[i]}
                <br />
                (Penggunaan)
              </th>
            ))}
            <th rowSpan={3}>
              Total
              <br />
              (Penggunaan)
            </th>
            <th rowSpan={3}>Rata-rata Pemakaian</th>
            <th rowSpan={3}>Total Stok(Faskes + IFKab)</th>
            <th colSpan={9} style={{ textAlign: "center" }}>
              Stok IF_Kab / IF_Prop
            </th>
            <th rowSpan={3}>Jum Faskes</th>
          </tr>
          <tr>
            <th colSpan={2}>Batch 1</th>
            <th colSpan={2}>Batch 2</th>
            <th colSpan={2}>Batch 3</th>
            <th colSpan={2}>Batch 4</th>
            <th rowSpan={2}>Total Stok</th>
          </tr>
          <tr>
            <th>Stok</th>
            <th>Tgl_ED</th>
            <th>Stok</th>
            <th>Tgl_ED</th>
            <th>Stok</th>
            <th>Tgl_ED</th>
            <th>Stok</th>
            <th>Tgl_ED</th>
          </tr>
        </thead>
        <tbody>
          {reportsData?.length !== 0 ? (
            reportsData?.map((item, index) => (
              <>
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item?.parent_name}</td>
                  <td
                    style={{
                      background: isIfProp(item) && "black",
                    }}
                  >
                    {item?.area}
                  </td>
                  <RenderBulanItems item={item} />
                  <td
                    style={{
                      background: isIfProp(item) && "black",
                      color: isIfProp(item) && "white",
                    }}
                  >
                    {Array.from({ length: 12 })
                      .map((_, i) => parseInt(item[`month_${i + 1}`] ?? "0"))
                      .reduce((a, b) => a + b, 0)}
                  </td>
                  <td
                    style={{
                      background: isIfProp(item) && "black",
                      color: isIfProp(item) && "white",
                    }}
                  >
                    {item?.average ?? 0}
                  </td>
                  <td>{item?.total_faskes_kab ?? 0}</td>
                  <td>{item?.batch_1 ?? 0}</td>
                  <td>{item?.batch_1_ed_date}</td>
                  <td>{item?.batch_2 ?? 0}</td>
                  <td>{item?.batch_2_ed_date}</td>
                  <td>{item?.batch_3 ?? 0}</td>
                  <td>{item?.batch_3_ed_date}</td>
                  <td>{item?.batch_4 ?? 0}</td>
                  <td>{item?.batch_4_ed_date}</td>
                  <td>{item?.total_batch_stock ?? 0}</td>
                  <td>{item?.totfask}</td>
                </tr>
                {isIfProp(item) && (
                  <tr style={{ backgroundColor: "#43766C" }}>
                    <td style={{ color: "#FFF" }} colSpan={3}>
                      TOTAL
                    </td>
                    <RenderBulanItemsTotal item={item} reportsData={reportsData} />
                    <td style={{ color: "#FFF" }}>
                      {reportsData
                        ?.filter((i) => i.parent_id === item.parent_id)
                        .reduce(
                          (a, item) =>
                            a +
                            Array.from({ length: 12 })
                              .map((_, i) =>
                                parseInt(item[`month_${i + 1}`] ?? "0")
                              )
                              .reduce((a, b) => a + b, 0),
                          0
                        )}
                    </td>
                    <td style={{ color: "#FFF" }}>
                      {reportsData
                        ?.filter((i) => i.parent_id === item.parent_id)
                        .reduce((a, b) => a + parseFloat(b?.average ?? "0"), 0)
                        .toFixed(2)}
                    </td>
                    <td style={{ color: "#FFF" }}>
                      {reportsData
                        ?.filter((i) => i.parent_id === item.parent_id)
                        .reduce(
                          (a, b) => a + parseFloat(b?.total_faskes_kab ?? "0"),
                          0
                        )}
                    </td>
                    <td style={{ color: "#FFF" }} colSpan={2}>
                      {reportsData
                        ?.filter((i) => i.parent_id === item.parent_id)
                        .reduce((a, b) => a + parseFloat(b?.batch_1 ?? "0"), 0)}
                    </td>
                    <td style={{ color: "#FFF" }} colSpan={2}>
                      {reportsData
                        ?.filter((i) => i.parent_id === item.parent_id)
                        .reduce((a, b) => a + parseFloat(b?.batch_2 ?? "0"), 0)}
                    </td>
                    <td style={{ color: "#FFF" }} colSpan={2}>
                      {reportsData
                        ?.filter((i) => i.parent_id === item.parent_id)
                        .reduce((a, b) => a + parseFloat(b?.batch_3 ?? "0"), 0)}
                    </td>
                    <td style={{ color: "#FFF" }} colSpan={2}>
                      {reportsData
                        ?.filter((i) => i.parent_id === item.parent_id)
                        .reduce((a, b) => a + parseFloat(b?.batch_4 ?? "0"), 0)}
                    </td>
                    <td style={{ color: "#FFF" }}>
                      {reportsData
                        ?.filter((i) => i.parent_id === item.parent_id)
                        .reduce(
                          (a, b) => a + parseFloat(b?.total_batch_stock ?? "0"),
                          0
                        )}
                    </td>
                    <td style={{ color: "#FFF" }}>
                      {reportsData
                        ?.filter((i) => i.parent_id === item.parent_id)
                        .reduce((a, b) => a + parseInt(b?.totfask ?? "0"), 0)}
                    </td>
                  </tr>
                )}
              </>
            ))
          ) : (
            <tr>
              <td>Tidak Ada Data</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TableComponent;
