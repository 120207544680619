import React, { useEffect, useState } from "react";
import { PageMode } from "utils/constant";
import TableComponent from "./components/TableComponent";
import FormComponent from "./components/FormComponent";
import "./index.scss";

const FlickIdentification = () => {
  const [pageMode, setPageMode] = useState(PageMode.view);

  useEffect(() => {
    document.title = "SISMAL | Identifikasi Jentik";
  }, []);

  const changePageMode = (mode) => {
    setPageMode(mode);
  };

  return (
    <div className="dashboard-wrapper">
      {pageMode === PageMode.view && (
        <TableComponent changeMode={changePageMode} />
      )}
      {(pageMode === PageMode.add || pageMode === PageMode.edit) && (
        <FormComponent changeMode={changePageMode} pageMode={pageMode} />
      )}
    </div>
  );
};

export default FlickIdentification;
