import React, { Component } from "react";
import { Activity, Check, FileText, Shield } from "react-feather";
import Village from "./FormWizard/Village";
import InitialSituation from "./FormWizard/InitialSituation";
import Effort from "./FormWizard/Effort";
import PeFokus from "../../../../services/PeFokus";
import { PageMode } from "../../../../utils/constant";

const PeFokusService = new PeFokus();

export default class Form extends Component {
  _isMounted = true;
  constructor(props) {

    super(props);
    this.defaultValue = {
      step: 1,
      urbanvillage_id: { value: "", isValid: true, message: "" },
      focus_id: { value: "", isValid: true, message: "" },
      sub_village: { value: "", isValid: true, message: "" },

      number_of_residents: { value: "", isValid: true, message: "" },
      number_of_houses: { value: "", isValid: true, message: "" },
      date_investigation: { value: "", isValid: true, message: "" },
      health_service_description: { value: "", isValid: true, message: "" },
      date_net_distribution: { value: "", isValid: true, message: "" },
      net_number: { value: "", isValid: true, message: "" },
      date_last_irs: { value: "", isValid: true, message: "" },
      number_houses_irs: { value: "", isValid: true, message: "" },
      date_last_mbs: { value: "", isValid: true, message: "" },
      number_of_people_mbs: { value: "", isValid: true, message: "" },
      types_of_mosquito_breeding: { value: "", isValid: true, message: "" },

      attempt: { value: "", isValid: true, message: "" },
      date_irs: { value: "", isValid: true, message: "" },
      number_of_targeted_house_irs: { value: "", isValid: true, message: "" },
      number_of_houses_sprayed: { value: "", isValid: true, message: "" },
      net_distribution_date: { value: "", isValid: true, message: "" },
      number_of_targeted_house_net: { value: "", isValid: true, message: "" },
      number_of_distributed_net: { value: "", isValid: true, message: "" },
      date_activities: { value: "", isValid: true, message: "" },
      activities_name: { value: "", isValid: true, message: "" },
      kie_activities_name: { value: "", isValid: true, message: "" },

      types_of_breeding: [],
      disableIDFokusInput: true,
      disableOther: true,
      error: "",
    };
    this.state = Object.assign({}, this.defaultValue);
  }

  componentDidMount = () => {
    let obj = Object.assign({}, this.defaultValue);
    if (this.props.mode === PageMode.edit) {
      const { peFocus } = this.props;

      obj.urbanvillage_id.value = peFocus.urbanvillage_id;
      obj.focus_id.value = peFocus.focus_id;
      obj.sub_village.value = peFocus.sub_village;
      obj.number_of_residents.value = peFocus.number_of_residents;
      obj.number_of_houses.value = peFocus.number_of_houses;
      obj.date_investigation.value = peFocus.date_investigation;
      obj.health_service_description.value = peFocus.health_service_description;
      obj.date_net_distribution.value = peFocus.date_net_distribution;
      obj.net_number.value = peFocus.net_number;
      obj.date_last_irs.value = peFocus.date_last_irs;
      obj.number_houses_irs.value = peFocus.number_houses_irs;
      obj.date_last_mbs.value = peFocus.date_last_mbs;
      obj.number_of_people_mbs.value = peFocus.number_of_people_mbs;
      obj.types_of_mosquito_breeding.value = peFocus.types_of_mosquito_breeding;
      obj.attempt.value = peFocus.attempt;
      obj.date_irs.value = peFocus.date_irs;
      obj.number_of_targeted_house_irs.value = peFocus.number_of_targeted_house_irs;
      obj.number_of_houses_sprayed.value = peFocus.number_of_houses_sprayed;
      obj.net_distribution_date.value = peFocus.net_distribution_date;
      obj.number_of_distributed_net.value = peFocus.number_of_distributed_net;
      obj.date_activities.value = peFocus.date_activities;
      obj.activities_name.value = peFocus.activities_name;
      obj.kie_activities_name.value = peFocus.kie_activities_name;
    }
    this.setState(obj);
  }

  componentWillUnmount = () => {
    this._isMounted = false;
  };


  nextStep = () => {
    const { step } = this.state;
    this.setState({ step: step + 1 });
  };

  prevStep = () => {
    const { step } = this.state;
    this.setState({ step: step - 1 });
  };

  handleInputChange = (e) => {
    const target = e.target;

    let error = "";
    const isValid = e.target.value ? true : false;
    let value = target.value;

    if (target.name === "types_of_mosquito_breeding" && target) {
      this.setState({
        disableOther: false
      });
    }

    this.setState({ 
      [target.name]: {
        value,
        isValid,
      }, error
    });
  };
  handleChecked = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      this.state.types_of_breeding.push(value);
      this.setState({
        ...this.props.data, types_of_mosquito_breeding:
          { value: this.state.types_of_breeding }
      });
    }else{
      let index = this.state.types_of_mosquito_breeding.indexOf(e.target.value);
      this.state.types_of_mosquito_breeding.splice(index, 1);
      this.setState({ types_of_mosquito_breeding: this.state.types_of_mosquito_breeding });
    }
  }

  onFilterVillage = (e) => {
    e.preventDefault();
    const target = e.target;
    const index = target.selectedIndex;
    const dataset = target.options[index].dataset;
    this.setState({
      urbanvillage_id: {isValid: true, value: target.value},
      focus_id: {isValid: true, value: dataset.focus}
    });
  }

  onSave = async () => {
    const {
      urbanvillage_id,
      focus_id,
      sub_village,
      number_of_residents,
      number_of_houses,
      date_investigation,
      health_service_description,
      date_net_distribution,
      net_number,
      date_last_irs,
      number_houses_irs,
      date_last_mbs,
      number_of_people_mbs,
      types_of_mosquito_breeding,
      attempt,
      date_irs,
      number_of_targeted_house_irs,
      number_of_houses_sprayed,
      net_distribution_date,
      number_of_targeted_house_net,
      number_of_distributed_net,
      date_activities,
      activities_name,
      kie_activities_name,

    } = this.state;

    const payload = {
      urbanvillage_id: urbanvillage_id.value,
      focus_id: focus_id.value,
      sub_village: sub_village.value,
      number_of_residents: number_of_residents.value,
      number_of_houses: number_of_houses.value,
      date_investigation: date_investigation.value,
      health_service_description: health_service_description.value,
      date_net_distribution: date_net_distribution.value,
      net_number: net_number.value,
      date_last_irs: date_last_irs.value,
      number_houses_irs: number_houses_irs.value,
      date_last_mbs: date_last_mbs.value,
      number_of_people_mbs: number_of_people_mbs.value,
      types_of_mosquito_breeding: types_of_mosquito_breeding.value,
      attempt: attempt.value,
      date_irs: date_irs.value,
      number_of_targeted_house_irs: number_of_targeted_house_irs.value,
      number_of_houses_sprayed: number_of_houses_sprayed.value,
      net_distribution_date: net_distribution_date.value,
      number_of_distributed_net: number_of_distributed_net.value,
      number_of_targeted_house_net: number_of_targeted_house_net.value,
      date_activities: date_activities.value,
      activities_name: activities_name.value,
      kie_activities_name: kie_activities_name.value,
    };

    switch (this.props.mode) {
      case PageMode.add:
        await PeFokusService.add(payload)
          .then((resp) => {
            this.setState(this.defaultValue, () => {
              this.props.changeMode("view");
            });

          })
          .catch((err) => {
            console.log(err);
          });
        break;
      case PageMode.edit:
        await PeFokusService.update(payload, this.props.peFocus.id)
          .then((res) => {
            this.setState(this.defaultValue, () => {
              this.props.changeMode("view");
            });
          })
          .catch((err) => {
            console.log(err);
          });
        break;
    }
  };
  render() {
    const {
      peCase
    } = this.props;

    const {
      step,
      urbanvillage_id,
      focus_id,
      sub_village,
    } = this.state;

    const renderForm = () => {
      switch (this.state.step) {
        case 1:
          return (
            <Village
              nextStep={this.nextStep}
              onInputChange={this.handleInputChange}
              data={this.state}
              mode={this.props.mode}
            />
          );
        case 2:
          return (
            <InitialSituation
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              onInputChange={this.handleInputChange}
              data={this.state}
              handleChecked={this.handleChecked}
              mode={this.props.mode}
              
            />
          );
        case 3:
          return (
            <Effort
              onSave={this.onSave}
              prevStep={this.prevStep}
              onInputChange={this.handleInputChange}
              data={this.state}
              mode={this.props.mode}
              
            />
          );
        default:
          return null;
      }
    };


    const isEdit = this.props.mode;
    let btnDel;
    if (isEdit === "edit") {
      btnDel = <button
        className="btn btn-danger font-weight-bold mb-2"
        onClick={() => this.props.onRemove(this.props.peFocus.id)}
      >
        Hapus
      </button>;
    }

    return (
      <div className="container mt-4">
        <div className="row">
          <div className="col-sm-10 mx-auto">
            {btnDel}
            <div className="font-weight-bold d-flex justify-content-between">
              <p>Data Desa Fokus</p>
              <p>
                NOTE:<span className="text-danger">*(WAJIB DIISI)</span>
              </p>
            </div>
            <div className="form-wrap py-2">
              <div className="form-group">
                <label htmlFor="urbanvillage_id">
                  Desa <sup className="text-danger">*</sup>
                </label>
                <select
                  name="urbanvillage_id"
                  className="form-control"
                  value={urbanvillage_id.value}
                  onChange={(e) => this.onFilterVillage(e)}
                >
                  <option>Pilih</option>
                  {
                    peCase && peCase.map((item, index) => {
                      return (
                        <option
                          value={item.urbanvillage_id?.split("|")[0]}
                          key={index}
                          data-focus={item.focus_id}
                        >{
                            item.urbanvillage_id?.split("|")[1]
                          }
                        </option>
                      );
                    })
                  }
                </select>
                {this.state.village && (
                  <span className="text-danger">{this.state.village.message}</span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="focus_id">ID Fokus (Otomatis)</label>
                <input
                  type="text"
                  className="form-control"
                  value={focus_id.value}
                  disabled={this.state.disableIDFokusInput}
                  onChange={(e) => this.handleInputChange(e)}
                />
                {this.state.focus_id && (
                  <span className="text-danger">{this.state.focus_id.message}</span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="sub_village">
                  Nama Dusun <sup className="text-danger">*</sup>
                </label>
                <input
                  name="sub_village"
                  id="sub_village"
                  className="form-control"
                  value={sub_village.value}
                  onChange={(e) => this.handleInputChange(e)}
                />
                {this.state.sub_village && (
                  <span className="text-danger">{this.state.sub_village.message}</span>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 mx-auto">
                <div className="wizard-controller">
                  <div className="step-wrapper">
                    <div
                      className={
                        step !== 1
                          ? "step-indicator complete"
                          : "step-indicator active"
                      }
                    >
                      {step !== 1 ? <Check /> : <FileText />}
                    </div>
                    <p className={step > 1 ? "text-active" : ""}>
                      Desa
                    </p>
                  </div>
                  <hr className={step !== 1 ? "complete" : ""} />
                  <div className="step-wrapper">
                    <div
                      className={
                        step === 2
                          ? "step-indicator active"
                          : step > 2
                            ? "step-indicator complete"
                            : "step-indicator"
                      }
                    >
                      {step > 2 ? <Check /> : <Activity />}
                    </div>
                    <p className={step > 2 ? "text-active" : ""}>
                      Situasi Awal
                    </p>
                  </div>
                  <hr className={step > 2 ? "complete" : ""} />
                  <div className="step-wrapper">
                    <div
                      className={
                        step === 3
                          ? "step-indicator active"
                          : step > 3
                            ? "step-indicator complete"
                            : "step-indicator"
                      }
                    >
                      {step > 3 ? <Check /> : <Shield />}
                    </div>
                    <p className={step > 3 ? "text-active" : ""}>Upaya</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-content">{renderForm()}</div>
          </div>
        </div>
      </div>
    );
  }
}
