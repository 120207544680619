import React, { Component } from "react";
import { ErrorMessage, PageMode, requestStatus } from "utils/constant";
import { Button, InputField, InputSelect } from "components";
import { connect } from "react-redux";
import LogisticExpiredStock from "services/LogisticExpiredStock";
import InputDateTime from "components/InputDateTime";
import { toast } from "react-toastify";
import { getCurrentUserFromLocalStorage } from "utils/common";

const LogisticExpiredStockService = new LogisticExpiredStock();

class Form extends Component {
  _isMounted = true;

  constructor(props) {
    super(props);
    this.defaultValue = {
      province_id: {
        value: this.props.user.province_id,
        isValid: true,
        message: "",
      },
      district_id: {
        value: this.props.user.district_id,
        isValid: true,
        message: "",
      },
      subdistrict_id: {
        value: this.props.user.subdistrict_id,
        isValid: true,
        message: "",
      },
      fasyankes_id: {
        value: this.props.user.fasyankes_id,
        isValid: true,
        message: "",
      },
      year: { value: "", isValid: true, message: "" },
      month: { value: "", isValid: true, message: "" },
      logistic_type: { value: "", isValid: true, message: "" },
      report: { value: "", isValid: true, message: "" },
      total: { value: "", isValid: true, message: "" },
      level: { value: "Fasyankes", isValid: true, message: "" },

      setExpired: true,

      isLoading: false,
    };
    this.state = Object.assign({}, this.defaultValue);
  }

  componentDidMount() {
    this._isMounted = true;

    let obj = Object.assign({}, this.state);
    if (this.props.mode === PageMode.edit) {
      const { expiredStock } = this.props;

      obj.province_id.value = expiredStock.province_id;
      obj.district_id.value = expiredStock.district_id;
      obj.subdistrict_id.value = expiredStock.subdistrict_id;
      obj.fasyankes_id.value = expiredStock.fasyankes_id;
      obj.year.value = expiredStock.year;
      obj.month.value = expiredStock.month;
      obj.logistic_type.value = expiredStock.logistic_type;
      obj.report.value = expiredStock.report;
      obj.total.value = expiredStock.total;
      obj.level.value = expiredStock.level;

      switch (expiredStock.logistic_type) {
        case "Microscope Binocular (Buah)":
          this.setState({
            setExpired: false,
          });
          break;
        case "Microscope Trinocular (Buah)":
          this.setState({
            setExpired: false,
          });
          break;
        case "Microscope Teaching (Buah)":
          this.setState({
            setExpired: false,
          });
          break;
        case "Slide Box (Buah)":
          this.setState({
            setExpired: false,
          });
          break;
        case "Blood Slide (Box)":
          this.setState({
            setExpired: false,
          });
          break;
        case "Blood Lancet (Box)":
          this.setState({
            setExpired: false,
          });
          break;
        default:
          this.setState({
            setExpired: true,
          });
          break;
      }
    }
    this.setState(obj);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    let obj = Object.assign({}, this.state[name]);
    obj.value = value;

    this.logicFormState(name, value);

    this.setState({
      [name]: obj,
    });
  };

  logicFormState = (name, value) => {
    if (name === "logistic_type") {
      switch (value) {
        case "Microscope Binocular (Buah)":
          this.setState({
            setExpired: false,
          });
          break;
        case "Microscope Trinocular (Buah)":
          this.setState({
            setExpired: false,
          });
          break;
        case "Microscope Teaching (Buah)":
          this.setState({
            setExpired: false,
          });
          break;
        case "Slide Box (Buah)":
          this.setState({
            setExpired: false,
          });
          break;
        case "Blood Slide (Box)":
          this.setState({
            setExpired: false,
          });
          break;
        case "Blood Lancet (Box)":
          this.setState({
            setExpired: false,
          });
          break;
        default:
          this.setState({
            setExpired: true,
          });
          break;
      }
    }
  };

  onSave = async (e) => {
    e.preventDefault();

    const isValid = this.validationHandler();

    if (isValid) {
      this.setState({
        isLoading: true,
      });

      let payload = {
        province_id: this.state.province_id.value,
        district_id: this.state.district_id.value,
        subdistrict_id: this.state.subdistrict_id.value,
        fasyankes_id: this.state.fasyankes_id.value,
        year: this.state.year.value,
        month: this.state.month.value,
        logistic_type: this.state.logistic_type.value,
        report: this.state.report.value,
        total: this.state.total.value,
        level: this.state.level.value,
      };

      switch (this.props.mode) {
        case PageMode.add:
          await LogisticExpiredStockService.add(payload)
            .then((res) => {
              this.setState({
                isLoading: false,
              });

              this.setState(this.defaultValue, () => {
                this.props.changeMode("view");
              });
              toast.success("Data Expired Stok Berhasil Ditambahkan", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            })
            .catch((err) => {
              console.log(err);

              this.setState({
                isLoading: false,
              });

              if (err.response.status === requestStatus.BadRequest) {
                toast.error(ErrorMessage.UserFaskesNotHaveAccess, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }

              if (err.response.status === requestStatus.Forbidden) {
                toast.error(ErrorMessage.UserNotVerified, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }

              if (err.response.status === requestStatus.Conflict) {
                toast.error(ErrorMessage.DataAlreadyExist, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
              if (err.response.status === requestStatus.ServerError) {
                toast.error(ErrorMessage.InternalServerError, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
            });
          break;
        case PageMode.edit:
          await LogisticExpiredStockService.update(
            payload,
            this.props.expiredStock.id
          )
            .then((res) => {
              this.setState({
                isLoading: false,
              });

              this.setState(this.defaultValue, () => {
                this.props.changeMode("view");
              });
              toast.success("Data Expired Stok Berhasil Diupdate", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            })
            .catch((err) => {
              console.log(err);

              this.setState({
                isLoading: false,
              });

              if (err.response.status === requestStatus.BadRequest) {
                toast.error(ErrorMessage.UserFaskesNotHaveAccess, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }

              if (err.response.status === requestStatus.Forbidden) {
                toast.error(ErrorMessage.UserNotVerified, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }

              if (err.response.status === requestStatus.Conflict) {
                toast.error(ErrorMessage.DataAlreadyExist, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
              if (err.response.status === requestStatus.ServerError) {
                toast.error(ErrorMessage.InternalServerError, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
            });
          break;
      }
    }
  };

  validationHandler = () => {
    const { year, month, logistic_type, report, total } = this.state;

    let isValid = true;

    if (year.value === "") {
      year.isValid = false;
      year.message = "Tahun harus diisi";
      isValid = false;
    }

    if (month.value === "") {
      month.isValid = false;
      month.message = "Bulan harus diisi";
      isValid = false;
    }

    if (logistic_type.value === "") {
      logistic_type.isValid = false;
      logistic_type.message = "Tipe harus diisi";
      isValid = false;
    }

    if (report.value === "") {
      report.isValid = false;
      report.message = "Jenis Laporan Harus Diisi";
      isValid = false;
    }

    if (total.value === "") {
      total.isValid = false;
      total.message = "Total harus diisi";
      isValid = false;
    }

    this.setState({
      year,
      month,
      logistic_type,
      report,
      total,
    });

    return isValid;
  };

  render() {
    const reportOpt = () => {
      if (
        this.state.logistic_type.value.includes("Microscope Binocular (Buah)") ||
        this.state.logistic_type.value.includes("Blood Lancet (Box)") ||
        this.state.logistic_type.value.includes("Slide Box (Buah)") ||
        this.state.logistic_type.value.includes("Blood Slide (Box)") ||
        this.state.logistic_type.value.includes("Microscope Trinocular (Buah)") ||
        this.state.logistic_type.value.includes("Microscope Teaching (Buah)")
      ) {
        return [{ label: "Rusak", value: "Rusak" }];
      } else if (!this.state.setExpired) {
        return [{ label: "Expired", value: "Expired" }];
      } else {
        return [
          { label: "Rusak", value: "Rusak" },
          { label: "Expired", value: "Expired" },
        ];
      }
    };

    const currentUser = getCurrentUserFromLocalStorage();

    return (
      <div className="form-wrapper">
        <div className="row">
          <div className="col-sm-6">
            <table className="table">
              <tr>
                <td>PROVINSI</td>
                <th>:</th>
                <th>{this.props.user.province_id?.split("|")[1]}</th>
              </tr>
              <tr>
                <td>KAB/KOTA</td>
                <th>:</th>
                <th>{this.props.user.district_id?.split("|")[1]}</th>
              </tr>
              <tr>
                <td>FASYANKES</td>
                <th>:</th>
                <th>{this.props.user.fasyankes_id?.split("|")[1]}</th>
              </tr>
            </table>
          </div>
        </div>

        <p className="font-weight-bold d-flex justify-content-start">
          NOTE <span className="text-danger">*(WAJIB DIISI)</span>
        </p>

        <InputDateTime
          label="Tahun"
          name="year"
          dateFormat="YYYY"
          placeholder="Tahun"
          value={this.state.year.value}
          onChange={(e) =>
            this.handleInputChange({
              target: {
                name: "year",
                isValid: true,
                value: moment(e).format("YYYY"),
              },
            })
          }
          isRequired={true}
          hasError={!this.state.year.isValid}
          errorMessage={this.state.year.message}
          isDisabled={this.props.mode === PageMode.edit}
        />

        <InputSelect
          label="Bulan"
          name="month"
          placeholder="Pilih Bulan"
          data={[
            { value: "01", label: "Januari" },
            { value: "02", label: "Februari" },
            { value: "03", label: "Maret" },
            { value: "04", label: "April" },
            { value: "05", label: "Mei" },
            { value: "06", label: "Juni" },
            { value: "07", label: "Juli" },
            { value: "08", label: "Agustus" },
            { value: "09", label: "September" },
            { value: "10", label: "Oktober" },
            { value: "11", label: "November" },
            { value: "12", label: "Desember" },
          ]}
          onChange={this.handleInputChange}
          value={this.state.month.value}
          isRequired={true}
          hasError={!this.state.month.isValid}
          errorMessage={this.state.month.message}
          isDisabled={this.props.mode === PageMode.edit}
        />
        {currentUser && currentUser.fasyankes_type_id !== "jnsfas-01" && (
          <InputSelect
            label="Jenis Logistik"
            name="logistic_type"
            placeholder="Pilih Jenis Logistik"
            data={[
              { value: "DHP Dispersible", label: "DHP Dispersible" },
              { value: "DHP (Tablet)", label: "DHP (Tablet)" },
              { value: "Primaquin (Tablet)", label: "Primaquin (Tablet)" },
              { value: "Artesunat Inj (Vial)", label: "Artesunat Inj (Vial) " },
              { value: "Kina (Tablet)", label: "Kina (Tablet)" },
              { value: "Kina Inj (Ampul)", label: "Kina Inj (Ampul)" },
              { value: "Doksisiklin (Tablet)", label: "Doksisiklin (Tablet)" },
              { value: "RDT (Test)", label: "RDT (Test)" },
              { value: "Lab Kit (Paket)", label: "Lab Kit (Paket)" },
              { value: "Giemsa (CC)", label: "Giemsa (CC)" },
              { value: "Minyak Imersi (CC)", label: "Minyak Imersi (CC)" },
              { value: "Blood Slide (Box)", label: "Blood Slide (Box)" },
              { value: "Slide Box (Buah)", label: "Slide Box (Buah)" },
              { value: "Blood Lancet (Box)", label: "Blood Lancet (Box)" },
              { value: "Methanol (CC)", label: "Methanol (CC)" },
              {
                value: "Microscope Binocular (Buah)",
                label: "Microscope Binocular (Buah)",
              },
            ]}
            onChange={this.handleInputChange}
            value={this.state.logistic_type.value}
            isRequired={true}
            hasError={!this.state.logistic_type.isValid}
            errorMessage={this.state.logistic_type.message}
            isDisabled={this.props.mode === PageMode.edit}
          />
        )}

        {currentUser && currentUser.fasyankes_type_id === "jnsfas-01" && (
          <InputSelect
            label="Jenis Logistik"
            name="logistic_type"
            placeholder="Pilih Jenis Logistik"
            data={[
              { value: "DHP Dispersible", label: "DHP Dispersible" },
              { value: "DHP (Tablet)", label: "DHP (Tablet)" },
              { value: "Primaquin (Tablet)", label: "Primaquin (Tablet)" },
              { value: "Artesunat Inj (Vial)", label: "Artesunat Inj (Vial) " },
              { value: "Kina (Tablet)", label: "Kina (Tablet)" },
              { value: "Kina Inj (Ampul)", label: "Kina Inj (Ampul)" },
              { value: "Doksisiklin (Tablet)", label: "Doksisiklin (Tablet)" },
              { value: "RDT (Test)", label: "RDT (Test)" },
              { value: "LLIN (Lembar)", label: "LLIN (Lembar)" },
              { value: "Insektisida (Kg)", label: "Insektisida (Kg)" },
              { value: "Insektisida (Liter)", label: "Insektisida (Liter)" },
              { value: "Larvasida (Kg)", label: "Larvasida (Kg)" },
              { value: "Larvasida (Liter)", label: "Larvasida (Liter)" },
              { value: "Lab Kit (Paket)", label: "Lab Kit (Paket)" },
              { value: "Giemsa (CC)", label: "Giemsa (CC)" },
              { value: "Minyak Imersi (CC)", label: "Minyak Imersi (CC)" },
              { value: "Blood Slide (Box)", label: "Blood Slide (Box)" },
              { value: "Slide Box (Buah)", label: "Slide Box (Buah)" },
              { value: "Blood Lancet (Box)", label: "Blood Lancet (Box)" },
              { value: "Methanol (CC)", label: "Methanol (CC)" },
              { value: "Spray Can (Buah)", label: "Spray Can (Buah)" },
              { value: "Mist Blower (Buah)", label: "Mist Blower (Buah)" },
              {
                value: "Microscope Binocular (Buah)",
                label: "Microscope Binocular (Buah)",
              },
              {
                value: "Microscope Trinocular (Buah)",
                label: "Microscope Trinocular (Buah)",
              },
              {
                value: "Microscope Teaching (Buah)",
                label: "Microscope Teaching (Buah)",
              },
            ]}
            onChange={this.handleInputChange}
            value={this.state.logistic_type.value}
            isRequired={true}
            hasError={!this.state.logistic_type.isValid}
            errorMessage={this.state.logistic_type.message}
            isDisabled={this.props.mode === PageMode.edit}
          />
        )}

        <InputSelect
          label="Laporan"
          name="report"
          placeholder="Pilih"
          data={reportOpt()}
          onChange={this.handleInputChange}
          value={this.state.report.value}
          isRequired={true}
          hasError={!this.state.report.isValid}
          errorMessage={this.state.report.message}
          isDisabled={this.props.mode === PageMode.edit}
        />

        <InputField
          type="numberOnly"
          label="Total"
          name="total"
          placeholder="Stok"
          onChange={this.handleInputChange}
          value={this.state.total.value}
          isRequired={true}
          hasError={!this.state.total.isValid}
          errorMessage={this.state.total.message}
        />

        <div className="form-group d-flex justify-content-end">
          <Button onClick={() => this.props.changeMode("view")} isSecondary>
            Batalkan
          </Button>
          <Button
            onClick={this.onSave}
            isPrimary
            isDisabled={this.state.isLoading}
          >
            {this.state.isLoading ? "Mohon Tunggu..." : "Simpan Data"}
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authReducer.currentUser,
  };
};

export default connect(mapStateToProps)(Form);
