import React, { useState } from "react";
import { useEffect } from "react";
import { PageMode } from "utils/constant";
import Table from "./components/Table";
import Form from "./components/Form";
import "./InputStock.scss";

const InputStock = () => {
  const [pageMode, setPageMode] = useState(PageMode.view);

  useEffect(() => {
    document.title = "SISMAL | Input Stok Logistik Kab/Kota";
  }, []);

  const changePageMode = (mode) => {
    setPageMode(mode);
  };

  return (
    <div className="dashboard-wrapper">
      {pageMode === PageMode.view && <Table changeMode={changePageMode} />}
      {(pageMode === PageMode.add || pageMode === PageMode.edit) && (
        <Form changeMode={changePageMode} pageMode={pageMode} />
      )}
    </div>
  );
};

export default InputStock;
