import React from "react";
import { useEffect } from "react";

const TermCondition = () => {
  useEffect(() => {
    window.document.title = "SISMAL | Syarat dan Ketentuan Sismal V3";
  }, []);

  return (
    <div className="container">
      <div
        className="card"
        style={{
          marginTop: "20px",
          borderRadius: "8px",
          shadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
        }}
      >
        <div className="card-body">
          <div className="row">
            <div className="col-md-3 align-self-center">
              <img src="dist/img/logo/logo_kemkes.png" alt="" />
            </div>
            <div className="col-md-9 align-self-center">
              <h1 className="font-weight-bold">
                Syarat dan Ketentuan SISMAL versi 3
              </h1>
            </div>
          </div>
          <hr />
          <section className="general-term mt-4">
            <h3 className="font-weight-bold text-uppercase">
              A. Ketentuan Umum
            </h3>
            <ol>
              <li className="mt-3">
                Kementerian Kesehatan RI mempunyai tugas membantu Presiden dalam
                menyelenggarakan sebagian urusan pemerintahan di bidang
                kesehatan.
              </li>
              <li className="mt-3">
                SISMAL adalah sistem pelaporan data kasus malaria yang dimiliki
                oleh Kementerian Kesehatan. SISMAL bertujuan untuk memudahkan
                petugas Kesehatan melaporkan data kasus malaria baik pasien
                positif maupun negatif.
              </li>
              <li className="mt-3">
                Pengguna SISMAL adalah petugas program malaria pada tingkat
                fasilitas kesehatan, dinas kesehatan kabupaten/kota, dinas
                kesehatan provinsi dan kementerian kesehatan.
              </li>
              <li className="mt-3">
                Informasi umum seperti penggunaan perangkat untuk mengakses
                aplikasi, alamat ip berdasarkan negara akan terekam oleh sistem.
              </li>
              <li className="mt-3">
                Dengan menggunakan aplikasi SISMAL, pengguna setuju mengikuti
                syarat dan ketentuan berlaku tersebut.
              </li>
              <li className="mt-3">
                Dengan mengunduh aplikasi android SISMAL maka Pengguna dianggap
                telah memahami/mengerti dan menyetujui semua isi di dalam
                Persyaratan dan Ketentuan Penggunaan, Panduan Pengguna, dan
                ketentuan/peraturan terkait lainnya yang berlaku.
              </li>
            </ol>
          </section>
          <section className="user-access mt-4">
            <h3 className="font-weight-bold text-uppercase">
              B. Akses Pengguna
            </h3>
            <h5 className="font-weight-bold">1. Kewajiban Pengguna</h5>
            <ol>
              <li className="mt-3">
                Memenuhi ketentuan peraturan perundang-undangan dan kebijakan
                yang berlaku dalam akses media digital berupa aplikasi.
              </li>
              <li className="mt-3">
                Setiap Pengguna bertanggungjawab melindungi kerahasiaan hak
                akses, dan aktivitas lainnya pada aplikasi SISMAL.
              </li>
              <li className="mt-3">
                Setiap penyalahgunaan hak akses oleh pihak lain menjadi tanggung
                jawab pengguna itu sendiri.
              </li>
            </ol>
            <h5 className="font-weight-bold">2. Ketentuan Pengguna</h5>
            <ol>
              <li className="mt-3">
                Pengguna setuju bahwa transaksi yang dilakukan melalui SISMAL
                tidak boleh melanggar peraturan perundang-undangan yang berlaku
                di Indonesia.
              </li>
              <li className="mt-3">
                Pengguna wajib tunduk dan taat pada semua peraturan yang berlaku
                di Indonesia yang berhubungan dengan penggunaan jaringan dan
                komunikasi data baik di wilayah Indonesia maupun dari dan keluar
                wilayah Indonesia melalui aplikasi SISMAL.
              </li>
              <li className="mt-3">
                Pengguna bertanggungjawab penuh atas isi transaksi yang
                dilakukan dengan menggunakan SISMAL.
              </li>
              <li className="mt-3">
                Pengguna dilarang saling mengganggu proses transaksi dan/atau
                layanan lain yang dilakukan dalam SISMAL.
              </li>
              <li className="mt-3">
                Pengguna setuju bahwa usaha untuk memanipulasi data, mengacaukan
                sistem elektronik dan jaringannya adalah tindakan melanggar
                hukum.
              </li>
              <li className="mt-3">
                Pengguna SISMAL tidak diperkenankan untuk menyalahgunakan data
                yang terunggah pada aplikasi.
              </li>
            </ol>
            <h5 className="font-weight-bold">3. Pembatasan Akses Pengguna</h5>
            <ol>
              <li className="mt-3">
                Pengelola SISMAL berhak menunda/menghalangi
                sementara/membatalkan hak akses Pengguna apabila ditemukan
                adanya informasi/transaksi/aktivitas lain yang tidak dibenarkan
                sesuai ketentuan yang berlaku.
              </li>
              <li className="mt-3">
                Pengguna terlihat secara ilegal/mencurigakan mengakses aplikasi
                SISMAL, maka akan dilakukan pemblokiran.
              </li>
            </ol>
          </section>
          <section className="responsibility mt-4">
            <h3 className="font-weight-bold text-uppercase">
              C. Tanggung Jawab dan Akibat
            </h3>
            <ol>
              <li className="mt-3">
                Kementerian Kesehatan RI tidak menjamin aplikasi SISMAL
                berlangsung terus secara tepat, handal/tanpa adanya gangguan.
              </li>
              <li className="mt-3">
                Kementerian Kesehatan RI berusaha terus meningkatkan dan
                memperbaiki performance aplikasinya.
              </li>
              <li className="mt-3">
                Kementerian Kesehatan RI dapat membantu pengguna SISMAL terkait
                dengan penyelesaian kesalahan penggunaan atau penyelesaian
                keterbatasan fasilitas aplikasi namun tidak bertanggungjawab
                atas hasil yang diakibatkan oleh tindakannya.
              </li>
            </ol>
          </section>
          <section className="copyright mt-4">
            <h3 className="font-weight-bold text-uppercase">D. Hak Cipta</h3>
            <ol>
              <li className="mt-3">
                Pengguna atau pihak lain dilarang mengutip atau meng-copy
                sebagian atau seluruh isi yang terdapat di dalam aplikasi
                SISMAL tanpa ijin tertulis dari Kementerian Kesehatan RI.
                Pelanggaran atas ketentuan ini akan dituntut dan digugat
                berdasarkan peraturan hukum pidana dan perdata yang berlaku di
                Indonesia.
              </li>
              <li className="mt-3">
                Pengguna setuju tidak akan dengan cara apapun memanfaatkan,
                memperbanyak, atau berperan dalam penjualan/menyebarkan setiap
                isi yang diperoleh dari aplikasi SISMAL untuk kepentingan
                pribadi dan/atau komersial.
              </li>
            </ol>
          </section>
          <section className="changes mt-4">
            <h3 className="font-weight-bold text-uppercase">E. Perubahan</h3>
            <ol>
              <li className="mt-3">
                SISMAL berhak/dapat menambah, mengurangi, memperbaiki fasilitas
                yang disediakan aplikasi ini setiap saat, dengan atau tanpa
                pemberitahuan sebelumnya.
              </li>
              <li className="mt-3">
                Setiap penggunaan oleh pengguna merupakan penegasan terhadap
                janji pengguna untuk terikat dan tunduk kepada ketentuan yang
                telah diperbaiki/ditambah/dikurangi itu.
              </li>
              <li className="mt-3">
                Dengan maupun tanpa alasan, SISMAL berhak menghentikan
                penggunaan aplikasi tanpa menanggung kewajiban apapun kepada
                pengguna apabila penghentian operasional ini terpaksa dilakukan.
              </li>
            </ol>
          </section>
          <section className="other mt-4">
            <h3 className="font-weight-bold text-uppercase">F. Lain-Lain</h3>
            <ol>
              <li className="mt-3">
                Telah membaca dan memahami syarat dan kententuan berikut.
              </li>
              <li className="mt-3">
                Bersedia mengikuti proses penggunaan aplikasi.
              </li>
              <li className="mt-3">
                Mengizinkan akses perangkat lunak android dan desktop untuk
                membuka aplikasi
              </li>
            </ol>
          </section>
        </div>
      </div>
    </div>
  );
};

export default TermCondition;
