import React from "react";
import { monthConverter } from "utils/common";
import { UserLevel } from "utils/constant";

const TableComponent = ({
  currentLevel,
  reportsData,
  tableRef,
  reportInfo,
}) => {
  const calculateTotal = () => {
    if (
      currentLevel === UserLevel.National.toLowerCase() ||
      currentLevel === UserLevel.Province.toLocaleLowerCase()
    ) {
      return reportsData.reduce(
        (total, item) => {
          total.total_faskes += parseInt(item.total_faskes) || 0;
          total.total_faskes_zero_report +=
            parseInt(item.total_faskes_zero_report) || 0;

          return total;
        },
        {
          total_faskes: 0,
          total_faskes_zero_report: 0,
        }
      );
    } else {
      return reportsData.reduce(
        (total, item) =>
          total + (item.is_zero_reporting?.toLowerCase() === "ya" ? 1 : 0),
        0
      );
    }
  };

  const renderTableByLevel = () => {
    const total = calculateTotal();

    switch (currentLevel) {
      case UserLevel.National.toLowerCase():
        return (
          <>
            <thead>
              <tr>
                <th style={{ textAlign: "center", width: "80px" }}>No</th>
                <th>Provinsi</th>
                <th>Kab/Kota</th>
                <th>Tahun</th>
                <th>Total Faskes</th>
                <th>Jumlah Faskes yang Pernah Lapor Zero Reporting</th>
              </tr>
            </thead>
            <tbody>
              {reportsData.length !== 0 ? (
                reportsData?.map((item, index) => (
                  <tr key={index}>
                    <td style={{ width: "80px" }}>{index + 1}</td>
                    <td>{item?.province_name}</td>
                    <td>{item?.district_name}</td>
                    <td>{item?.year}</td>
                    <td>{item?.total_faskes}</td>
                    <td>{item?.total_faskes_zero_report}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={5}>Tidak Ada Data</td>
                </tr>
              )}
              <tr style={{ backgroundColor: "#43766C" }}>
                <td style={{ color: "#FFF" }} colSpan={4}>
                  Total
                </td>
                <td style={{ color: "#FFF" }}>{total.total_faskes}</td>
                <td style={{ color: "#FFF" }}>
                  {total.total_faskes_zero_report}
                </td>
              </tr>
            </tbody>
          </>
        );
      case UserLevel.Province.toLowerCase():
        return (
          <>
            <thead>
              <tr>
                <th>No</th>
                <th>Provinsi</th>
                <th>Kab/Kota</th>
                <th>Tahun</th>
                <th>Total Faskes</th>
                <th>Jumlah Faskes yang Pernah Lapor Zero Reporting</th>
              </tr>
            </thead>
            <tbody>
              {reportsData.length !== 0 ? (
                reportsData?.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item?.province_name}</td>
                    <td>{item?.district_name}</td>
                    <td>{item?.year}</td>
                    <td>{item?.total_faskes}</td>
                    <td>{item?.total_faskes_zero_report}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>Tidak Ada Data</td>
                </tr>
              )}
              <tr style={{ backgroundColor: "#43766C" }}>
                <td style={{ color: "#FFF" }} colSpan={4}>
                  Total
                </td>
                <td style={{ color: "#FFF" }}>{total.total_faskes}</td>
                <td style={{ color: "#FFF" }}>
                  {total.total_faskes_zero_report}
                </td>
              </tr>
            </tbody>
          </>
        );
      default:
        return (
          <>
            <thead>
              <tr>
                <th>No</th>
                <th>Provinsi</th>
                <th>Kab/Kota</th>
                <th>Faskes Pencatat</th>
                <th>Tahun</th>
                <th>Bulan</th>
                <th>Zero Reporting</th>
              </tr>
            </thead>
            <tbody>
              {reportsData.length !== 0 ? (
                reportsData?.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item?.province_name}</td>
                    <td>{item?.district_name}</td>
                    <td>{item?.fasyankes_name}</td>
                    <td>{item?.year}</td>
                    <td>{item?.month}</td>
                    <td>{item?.is_zero_reporting}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>Tidak Ada Data</td>
                </tr>
              )}
              <tr style={{ backgroundColor: "#43766C" }}>
                <td style={{ color: "#FFF" }} colSpan={6}>
                  Total
                </td>
                <td style={{ color: "#FFF" }}>{total}</td>
              </tr>
            </tbody>
          </>
        );
    }
  };

  let levelName = "";
  if (currentLevel === "national") {
    levelName = "Nasional";
  } else if (currentLevel === "province") {
    levelName = "Provinsi";
  } else if (currentLevel === "district") {
    levelName = "Kabupaten/Kota";
  } else {
    levelName = "Fasyankes";
  }

  let periodeName = "";
  if (reportInfo?.statusPeriode === "yearly") {
    periodeName = "Tahunan";
  } else {
    periodeName = "Bulanan";
  }

  let data = reportInfo?.fasyankes;
  let parts = data?.split(",");
  let faskesName = parts?.map(function (part) {
    return part?.split("|")[1];
  });
  let result = faskesName?.join(", ");

  return (
    <div
      className="table-table-responsive"
      style={{ height: "560px", overflow: "scroll" }}
      ref={tableRef}
    >
      <table>
        <tr style={{ border: "none", padding: 0 }}>
          <th style={{ border: "none", paddingBottom: "0" }}>
            <h3>SISMAL v3, ZERO REPORTING</h3>
          </th>
        </tr>
      </table>
      <table>
        <tr style={{ border: "none" }}>
          <th
            width="200"
            style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}
          >
            Periode Laporan
          </th>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            : {periodeName}
          </th>
        </tr>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            Tahun
          </th>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            : {reportInfo?.periode?.year}
          </th>
        </tr>
        {reportInfo?.statusPeriode === "monthly" && (
          <tr style={{ border: "none" }}>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              Bulan
            </th>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              : {monthConverter(reportInfo?.periode?.month)}
            </th>
          </tr>
        )}
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            Level
          </th>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            : {levelName}
          </th>
        </tr>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            Provinsi
          </th>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            :{" "}
            {!reportInfo?.province
              ? "Semua Provinsi"
              : reportInfo?.province?.split("|")[1]}
          </th>
        </tr>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            Kab/Kota
          </th>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            :{" "}
            {!reportInfo?.district
              ? "Semua Kab/Kota"
              : reportInfo?.district?.split("|")[1]}
          </th>
        </tr>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingTop: 0 }}>Fasyankes</th>
          <th style={{ border: "none", paddingTop: 0 }}>
            : {!reportInfo?.fasyankes ? "Semua Fasyankes" : result}
          </th>
        </tr>
      </table>
      <table>{renderTableByLevel()}</table>
    </div>
  );
};

export default TableComponent;
