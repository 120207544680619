import { errHandler } from "utils/common";
import api from "utils/api";

const apiUrl = "/api/logistic-request-stock";

export default class LogisticRequestStock {
  getAll = (opt = { page: 1, limit: 10, searchBy: "", searchValue: "" }) => {
    return new Promise((resolve, reject) => {
      api
        .get(`${apiUrl}s`, { params: opt })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  getFile = (id) => {
    return new Promise((resolve, reject) => {
      api
        .get(`${apiUrl}/file/${id}`, { responseType: "blob" })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  getFullfillFile = (id) => {
    return new Promise((resolve, reject) => {
      api
        .get(`${apiUrl}/file-fullfilled/${id}`, { responseType: "blob" })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  add = (payload) => {
    return new Promise((resolve, reject) => {
      const data = new FormData();
      for (const [key, val] of Object.entries(payload)) {
        data.append(key, val);
      }
      api
        .post(`${apiUrl}?device=Online`, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  update = (payload, id) => {
    return new Promise((resolve, reject) => {
      const data = new FormData();
      for (const [key, val] of Object.entries(payload)) {
        data.append(key, val);
      }
      api
        .patch(`${apiUrl}/${id}?device=Online`, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  remove = (id) => {
    return new Promise((resolve, reject) => {
      api
        .delete(`${apiUrl}/${id}?device=Online`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };
}
