import React from "react";

const PrivaryPolicy = () => {
  return (
    <div
      className="container"
      style={{
        textAlign: "justify",
        fontFamily: "Arial",
        lineHeight: "24px",
      }}
    >
      <p style={{ fontWeight: "bold" }}>Privacy Policy</p>
      <p style={{ fontWeight: "bold" }}>
        MOHON UNTUK MEMBACA SELURUH KEBIJAKAN PRIVASI YANG TERLAMPIR DENGAN
        CERMAT DAN SAKSAMA SEBELUM MENGGUNAKAN SETIAP FITUR DAN/ATAU LAYANAN
        YANG TERSEDIA DALAM APLIKASI SISMAL V.3
      </p>
      <p>
        Kementerian Kesehatan RI membangun aplikasi SISMAL sebagai aplikasi
        Gratis. LAYANAN ini disediakan oleh Kementerian Kesehatan RI tanpa biaya
        dan dimaksudkan untuk digunakan apa adanya.
      </p>

      <p>
        {" "}
        Halaman ini digunakan untuk memberi tahu pengunjung mengenai kebijakan
        kami dengan pengumpulan, penggunaan, dan pengungkapan Informasi Pribadi
        jika ada yang memutuskan untuk menggunakan Layanan kami.
      </p>

      <p>
        Jika Anda memilih untuk menggunakan Layanan kami, maka Anda menyetujui
        pengumpulan dan penggunaan informasi sehubungan dengan kebijakan ini.
        Informasi Pribadi yang kami kumpulkan digunakan untuk menyediakan dan
        meningkatkan Layanan. Kami tidak akan menggunakan atau membagikan
        informasi Anda dengan siapa pun kecuali sebagaimana dijelaskan dalam
        Kebijakan Privasi ini.
      </p>

      <p>
        {" "}
        Istilah yang digunakan dalam Kebijakan Privasi ini memiliki arti yang
        sama seperti dalam Syarat dan Ketentuan kami, yang dapat diakses di
        SISMAL kecuali ditentukan lain dalam Kebijakan Privasi ini.
      </p>

      <p>
        {" "}
        Apabila Pengguna tidak setuju terhadap salah satu, sebagian, atau
        seluruh isi yang tertuang dalam Kebijakan Privasi ini, maka Pengguna
        diperkenankan untuk menghapus SISMAL dalam perangkat elektronik
        dan/atau tidak mengakses SISMAL dan/atau tidak menggunakan SISMAL.
        SISMAL dilepaskan dari seluruh tanggung jawab dari seluruh kerugian
        yang Pengguna terima sehubungan keputusan untuk tidak menggunakan
        SISMAL ini.
      </p>
      <p style={{ fontWeight: "bold" }}>Izin Aplikasi</p>
      <ol>
        <li>
          Pada saat Pengguna mengunduh SISMAL, sistem akan meminta persetujuan
          Pengguna untuk mengaktifkan lokasi dan penyimpan local storage.
        </li>
        <li>
          Saat lokasi aktif,aplikasi dapat mengambil lokasi tekini posisi user.
        </li>
        <li>
          SISMAL akan meminta izin akses kepada Pengguna untuk menggunakan
          dokumen saat Pengguna mengunduh file berformat .env untuk sinkronisasi
          data ke server.
        </li>
      </ol>
      <p style={{ fontWeight: "bold" }}>Pengumpulan dan Penggunaan Informasi</p>
      <p>
        Untuk pengalaman yang lebih baik, saat menggunakan Layanan kami, kami
        mungkin meminta Anda untuk memberikan kami informasi pengenal pribadi
        tertentu. Informasi yang kami minta akan disimpan oleh kami dan
        digunakan seperti yang dijelaskan dalam kebijakan privasi ini.
      </p>

      <p>
        Aplikasi ini menggunakan layanan pihak ketiga yang dapat mengumpulkan
        informasi yang digunakan untuk mengidentifikasi Anda. Tautan ke
        kebijakan privasi penyedia layanan pihak ketiga yang digunakan oleh
        aplikasi
      </p>
      <ol>
        <li> Layanan Google Play</li>
      </ol>

      <p style={{ fontWeight: "bold" }}>Log Data</p>
      <p>
        Kami ingin memberi tahu Anda bahwa setiap kali Anda menggunakan Layanan
        kami, jika terjadi kesalahan dalam aplikasi, kami mengumpulkan data dan
        informasi (melalui produk pihak ketiga) di ponsel Anda yang disebut Data
        Log.
      </p>
      <p>
        {" "}
        Data Log ini dapat mencakup informasi seperti alamat Protokol Internet
        {("IP")} perangkat Anda, nama perangkat, versi sistem operasi, konfigurasi
        aplikasi saat menggunakan Layanan kami, waktu dan tanggal penggunaan
        Layanan oleh Anda, dan statistik lainnya .
      </p>
      <p style={{ fontWeight: "bold" }}>Cookies</p>
      <p>
        Cookies adalah file dengan sejumlah kecil data yang biasanya digunakan
        sebagai pengidentifikasi unik anonim. Ini dikirim ke browser Anda dari
        situs web yang Anda kunjungi dan disimpan di memori internal perangkat
        Anda.
      </p>

      <p>
        Layanan ini tidak menggunakan {"cookies"} ini secara eksplisit. Namun,
        aplikasi dapat menggunakan kode dan perpustakaan pihak ketiga yang
        menggunakan {"cookies"} untuk mengumpulkan informasi dan meningkatkan
        layanan mereka.
      </p>
      <p>
        {" "}
        Anda memiliki pilihan untuk menerima atau menolak cookie ini dan
        mengetahui kapan cookie dikirim ke perangkat Anda. Jika Anda memilih
        untuk menolak cookie kami, Anda mungkin tidak dapat menggunakan beberapa
        bagian dari Layanan ini.
      </p>

      <p style={{ fontWeight: "bold" }}>Penyedia jasa</p>
      <p>
        Kami dapat mempekerjakan perusahaan dan individu pihak ketiga karena
        alasan berikut:
      </p>
      <ol>
        <li>Untuk memfasilitasi Layanan kami.</li>
        <li>Untuk menyediakan Layanan atas nama kami.</li>
        <li>Untuk melakukan layanan terkait Layanan.</li>
        <li>
          Untuk membantu kami dalam menganalisis bagaimana Layanan kami
          digunakan.
        </li>
      </ol>
      <p>
        Kami ingin memberi tahu pengguna Layanan ini bahwa pihak ketiga ini
        memiliki akses ke Informasi Pribadi mereka. Alasannya adalah untuk
        melakukan tugas yang diberikan kepada mereka atas nama kita. Namun,
        mereka berkewajiban untuk tidak mengungkapkan atau menggunakan informasi
        tersebut untuk tujuan lain.
      </p>
      <p style={{ fontWeight: "bold" }}>Keamanan</p>
      <p>
        Kami menghargai kepercayaan Anda dalam memberikan Informasi Pribadi Anda
        kepada kami, oleh karena itu kami berusaha untuk menggunakan cara yang
        dapat diterima secara komersial untuk melindunginya. Tetapi ingat bahwa
        tidak ada metode transmisi melalui internet, atau metode penyimpanan
        elektronik yang 100% aman dan andal, dan kami tidak dapat menjamin
        keamanan mutlaknya.
      </p>
      <p style={{ fontWeight: "bold" }}>Tautan ke Situs Lain</p>
      <p>
        Layanan ini mungkin berisi tautan ke situs lain. Jika Anda mengklik
        tautan pihak ketiga, Anda akan diarahkan ke situs itu. Perhatikan bahwa
        situs eksternal ini tidak dioperasikan oleh kami. Oleh karena itu, kami
        sangat menyarankan Anda untuk meninjau Kebijakan Privasi situs web ini.
        Kami tidak memiliki kendali atas dan tidak bertanggung jawab atas
        konten, kebijakan privasi, atau praktik situs atau layanan pihak ketiga
        mana pun.
      </p>
      <p style={{ fontWeight: "bold" }}>Privasi Anak-anak</p>
      <p>
        Layanan ini tidak ditujukan kepada siapa pun yang berusia di bawah 13
        tahun. Kami tidak dengan sengaja mengumpulkan informasi pengenal pribadi
        dari anak-anak di bawah 13 tahun. Jika kami menemukan bahwa seorang anak
        di bawah 13 tahun telah memberi kami informasi pribadi, kami segera
        menghapusnya dari server kami.
      </p>
      <p>
        Jika Anda adalah orang tua atau wali dan Anda mengetahui bahwa anak Anda
        telah memberikan informasi pribadi kepada kami, silakan hubungi kami
        agar kami dapat melakukan tindakan yang diperlukan.
      </p>
      <p style={{ fontWeight: "bold" }}>Perubahan pada Kebijakan Privasi Ini</p>
      <p>
        Kami dapat memperbarui Kebijakan Privasi kami dari waktu ke waktu. Oleh
        karena itu, Anda disarankan untuk meninjau halaman ini secara berkala
        untuk setiap perubahan. Kami akan memberi tahu Anda tentang perubahan
        apa pun dengan memposting Kebijakan Privasi baru di halaman ini.
        Kebijakan ini berlaku mulai 2022-08-19.
      </p>
      <p style={{ fontWeight: "bold" }}>Hubungi kami</p>
      <p>
        Jika Anda memiliki pertanyaan atau saran tentang Kebijakan Privasi kami,
        jangan ragu untuk menghubungi kami di SISMAL@kemenkes.go.id.
      </p>
    </div>
  );
};

export default PrivaryPolicy;
