import React, { Component } from "react";
import { PageMode, Source } from "../../../utils/constant";
import "react-datetime/css/react-datetime.css";

import User from "../../../services/User";
import Wilayah from "../../../services/Wilayah";
import Fasyankes from "../../../services/Fasyankes";
import { Button, InputField, InputSelect } from "components";
import { toast } from "react-toastify";
import { isEmpty } from "utils/common";

const UserService = new User();
const WilayahService = new Wilayah();
const FasyankesService = new Fasyankes();
class Form extends Component {
  _isMounted = true;
  constructor(props) {
    super(props);
    this.defaultValue = {
      first_name: { value: "", isValid: true, message: "" },
      last_name: { value: "", isValid: true, message: "" },
      username: { value: "", isValid: true, message: "" },
      password: { value: "", isValid: true, message: "" },
      province_id: { value: "", isValid: true, message: "" },
      district_id: { value: "", isValid: true, message: "" },
      subdistrict_id: { value: "", isValid: true, message: "" },
      urbanvillage_id: { value: "", isValid: true, message: "" },
      fasyankes_id: { value: "", isValid: true, message: "" },
      role: { value: "", isValid: true, message: "" },
      level: { value: "", isValid: true, message: "" },
      is_active: { value: true, isValid: true, message: "" },

      confirm_password: { value: "", isValid: true, message: "" },

      provincies: [],
      districts: [],
      subdistricts: [],
      urbanvillages: [],
      fasyankes: [],

      errorMessage: "",
    };
    this.state = Object.assign({}, this.defaultValue);
  }

  componentDidMount() {
    this._isMounted = true;
    this.getProvincies();
    let obj = Object.assign({}, this.defaultValue);
    if (this.props.mode === PageMode.edit) {
      const { data } = this.props;

      obj.first_name.value = data.first_name;
      obj.last_name.value = data.last_name;
      obj.username.value = data.username;
      obj.password.value = data.password;
      obj.province_id.value = this.splitOrNull(data.province_id, 0);
      this.getDistrictsByProvince();
      obj.district_id.value = this.splitOrNull(data.district_id, 0);
      this.getSubDistrictsByDistrict();
      this.getFasyankesNonPKM();
      obj.subdistrict_id.value = this.splitOrNull(data.subdistrict_id, 0);
      this.getUrbanvillagesBySubDistrict();
      obj.urbanvillage_id.value = this.splitOrNull(data.urbanvillage_id, 0);
      this.getFasyankesBySubDistrict();
      obj.fasyankes_id.value = this.splitOrNull(data.fasyankes_id, 0);
      obj.role.value = data.role;
      obj.level.value = data.level;
      obj.role.level = data.level;
    }
    this.setState(obj);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    let obj = Object.assign({}, this.state[name]);
    obj.value = value;

    switch (name) {
      case "province_id":
        this.setState(
          {
            [name]: { value: value, isValid: true, message: "" },
            district_id: { value: "", isValid: true, message: "" },
            subdistrict_id: { value: "", isValid: true, message: "" },
            urbanvillage_id: { value: "", isValid: true, message: "" },
            fasyankes_id: { value: "", isValid: true, message: "" },
            districts: [],
            subdistricts: [],
            urbanvillages: [],
            fasyankes: [],
          },
          () => {
            this.getDistrictsByProvince();
          }
        );
        break;
      case "district_id":
        this.setState(
          {
            [name]: { value: value, isValid: true, message: "" },
            subdistrict_id: { value: "", isValid: true, message: "" },
            urbanvillage_id: { value: "", isValid: true, message: "" },
            fasyankes_id: { value: "", isValid: true, message: "" },
            subdistricts: [],
            urbanvillages: [],
            fasyankes: [],
          },
          () => {
            this.getSubDistrictsByDistrict();
            this.getFasyankesNonPKM();
          }
        );
        break;
      case "subdistrict_id":
        this.setState(
          {
            [name]: { value: value, isValid: true, message: "" },
            urbanvillage_id: { value: "", isValid: true, message: "" },
            fasyankes_id: { value: "", isValid: true, message: "" },
            urbanvillages: [],
            fasyankes: [],
          },
          () => {
            this.getFasyankesBySubDistrict();
            this.getUrbanvillagesBySubDistrict();
          }
        );
        break;
    }

    this.setState({
      [name]: obj,
    });
  };

  splitOrNull = (data, i) => {
    if (isEmpty(data)) {
      return null;
    }
    return data?.split("|")[i];
  };

  getProvincies = async () => {
    await WilayahService.getAll(Source.PROVINCE, { page: 1, limit: 999 })
      .then((res) => {
        this.setState({
          provincies: res.province,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getDistrictsByProvince = async () => {
    await WilayahService.getAll(Source.DISTRICT, {
      searchBy: "province_id",
      searchValue: this.state.province_id.value,
      page: 1,
      limit: 999,
    })
      .then((res) => {
        this.setState({
          districts: res.district,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getSubDistrictsByDistrict = async () => {
    await WilayahService.getAll(Source.SUBDISTRICT, {
      searchBy: "district_id",
      searchValue: this.state.district_id.value,
      page: 1,
      limit: 999,
    })
      .then((res) => {
        this.setState({
          subdistricts: res.subdistrict,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getUrbanvillagesBySubDistrict = async () => {
    await WilayahService.getAll(Source.URBANVILLAGE, {
      searchBy: "subdistrict_id",
      searchValue: this.state.subdistrict_id.value,
      page: 1,
      limit: 999,
    })
      .then((res) => {
        this.setState({
          urbanvillages: res.urbanvillage,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getFasyankesBySubDistrict = async () => {
    await FasyankesService.getAll(Source.FASYANKES, {
      searchBy: "subdistrict_id",
      searchValue: this.state.subdistrict_id.value,
      limit: 999,
      page: 1,
    })
      .then((res) => {
        this.setState({
          fasyankes: res.fasyankes,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getFasyankesNonPKM = async () => {
    await FasyankesService.getAll(Source.FASYANKES, {
      searchBy: "district_id",
      searchValue: this.state.district_id.value,
      excludePkm: true,
      limit: 999,
      page: 1,
    })
      .then((res) => {
        this.setState({
          fasyankes: res.fasyankes,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  validationHandler = () => {
    const {
      first_name,
      last_name,
      username,
      password,
      province_id,
      district_id,
      subdistrict_id,
      urbanvillage_id,
      fasyankes_id,
      role,
      level,
    } = this.state;

    let isValid = true;

    if (!first_name.value) {
      first_name.isValid = false;
      first_name.message = "Nama Depan tidak boleh kosong";
      isValid = false;
    }
    if (!last_name.value) {
      last_name.isValid = false;
      last_name.message = "Nama Belakang tidak boleh kosong";
      isValid = false;
    }
    if (!username.value) {
      username.isValid = false;
      username.message = "Username tidak boleh kosong";
      isValid = false;
    }

    if (this.props.mode !== PageMode.edit) {
      if (!password.value) {
        password.isValid = false;
        password.message = "Password tidak boleh kosong";
        isValid = false;
      }

      // if password not same
      if (password.value !== this.state.confirm_password.value) {
        password.isValid = false;
        password.message = "Password tidak sama";
        isValid = false;
      }
    }

    let prov,
      dist,
      fasyankes = true;
    switch (level.value) {
      case "National":
        prov = false;
        dist = false;
        fasyankes = false;
        break;
      case "Province":
        prov = true;
        dist = false;
        fasyankes = false;
        break;
      case "District":
        prov = true;
        dist = true;
        fasyankes = false;
        break;
      case "Fasyankes":
        prov = true;
        dist = true;
        fasyankes = true;
        break;
    }

    if (!province_id.value && prov) {
      province_id.isValid = false;
      province_id.message = "Provinsi tidak boleh kosong";
      isValid = false;
    }
    if (!district_id.value && dist) {
      district_id.isValid = false;
      district_id.message = "Kabupaten tidak boleh kosong";
      isValid = false;
    }
    if (!fasyankes_id.value && fasyankes) {
      fasyankes_id.isValid = false;
      fasyankes_id.message = "Fasyankes tidak boleh kosong";
      isValid = false;
    }
    if (!role.value) {
      role.isValid = false;
      role.message = "Role tidak boleh kosong";
      isValid = false;
    }
    if (!level.value) {
      level.isValid = false;
      level.message = "Level tidak boleh kosong";
      isValid = false;
    }

    this.setState({
      first_name,
      last_name,
      username,
      password,
      province_id,
      district_id,
      subdistrict_id,
      urbanvillage_id,
      fasyankes_id,
      role,
      level,
    });

    return isValid;
  };

  onSave = async (e) => {
    e.preventDefault();

    const isValid = this.validationHandler();
    let payload = {
      first_name: this.state.first_name.value,
      last_name: this.state.last_name.value,
      username: this.state.username.value,
      password: this.state.password.value,
      province_id: this.state.province_id.value,
      district_id: this.state.district_id.value,
      subdistrict_id: this.state.subdistrict_id.value,
      urbanvillage_id: this.state.urbanvillage_id.value,
      fasyankes_id: this.state.fasyankes_id.value,
      role: this.state.role.value,
      level: this.state.level.value,
      is_active: this.state.is_active.value,
    };
    if (isValid) {
      switch (this.props.mode) {
        case PageMode.add:
          await UserService.add(payload)
            .then((res) => {
              this.setState(this.defaultValue, () => {
                this.props.changeMode("view");
              });
              toast.success("Data Pengguna Berhasil Disimpan", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            })
            .catch((err) => {
              console.log(err);
            });
          break;
        case PageMode.edit:
          await UserService.update(payload, this.props.data.id)
            .then((res) => {
              this.setState(this.defaultValue, () => {
                this.props.changeMode("view");
              });
              toast.success("Data Pengguna Berhasil Diperbarui", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            })
            .catch((err) => {
              console.log(err);
            });
          break;
      }
    }
  };

  provSelect = () => {
    const { value } = this.state.level;
    return value === "" || value === "National";
  };

  distSelect = () => {
    const { value } = this.state.level;
    return value === "" || value === "National" || value === "Province";
  };

  fasyankesSelect = () => {
    const { value } = this.state.level;
    return (
      value === "" ||
      value === "National" ||
      value === "Province" ||
      value === "District"
    );
  };

  render() {
    const { changeMode } = this.props;

    return (
      <div className="dashboard-wrapper">
        <h1 className="title font-weight-bold">Manajemen Data Pengguna</h1>

        <p className="font-weight-bold d-flex justify-content-start">
          NOTE <span className="text-danger">*(WAJIB DIISI)</span>
        </p>

        <InputField
          label="Nama Depan"
          name="first_name"
          type="text"
          placeholder="Nama Depan"
          onChange={this.handleInputChange}
          value={this.state.first_name.value}
          isRequired={true}
          hasError={!this.state.first_name.isValid}
          errorMessage={this.state.first_name.message}
        />

        <InputField
          label="Nama Belakang"
          name="last_name"
          type="text"
          placeholder="Nama Belakang"
          onChange={this.handleInputChange}
          value={this.state.last_name.value}
          isRequired={true}
          hasError={!this.state.last_name.isValid}
          errorMessage={this.state.last_name.message}
        />

        <InputField
          label="Username"
          name="username"
          type="text"
          placeholder="Username"
          onChange={this.handleInputChange}
          value={this.state.username.value}
          isRequired={true}
          hasError={!this.state.username.isValid}
          errorMessage={this.state.username.message}
        />

        <InputSelect
          label="Hak Akses"
          name="role"
          placeholder="Pilih"
          onChange={this.handleInputChange}
          value={this.state.role.value}
          data={[
            { value: "Admin", label: "Administrator" },
            { value: "User", label: "Pengguna" },
            { value: "Viewer", label: "Tamu" },
          ]}
          isRequired={true}
          hasError={!this.state.role.isValid}
          errorMessage={this.state.role.message}
        />

        <InputSelect
          label="Level Pengguna"
          name="level"
          placeholder="Pilih"
          onChange={(e) => {
            const nullVal = { value: "", isValid: true, message: "" };
            this.handleInputChange(e);
            this.setState({
              province_id: { ...nullVal },
              district_id: { ...nullVal },
              subdistrict_id: { ...nullVal },
              urbanvillage_id: { ...nullVal },
              fasyankes_id: { ...nullVal },
            });
          }}
          value={this.state.level.value}
          data={[
            { value: "National", label: "Nasional / Pusat" },
            { value: "Province", label: "Provinsi / Daerah" },
            { value: "District", label: "Kabupaten / Kota" },
            { value: "Fasyankes", label: "Fasilitas Pelayanan Kesehatan" },
          ]}
          isRequired={true}
          hasError={!this.state.level.isValid}
          errorMessage={this.state.level.message}
        />

        <InputSelect
          label="Provinsi"
          name="province_id"
          placeholder="Pilih"
          onChange={this.handleInputChange}
          value={this.state.province_id.value}
          data={this.state.provincies}
          isDisabled={this.provSelect()}
          isRequired={!this.provSelect()}
          hasError={!this.state.province_id.isValid}
          errorMessage={this.state.province_id.message}
        />

        <InputSelect
          label="Kabupaten / Kota"
          name="district_id"
          placeholder="Pilih"
          onChange={this.handleInputChange}
          value={this.state.district_id.value}
          data={this.state.districts}
          hasError={!this.state.district_id.isValid}
          errorMessage={this.state.district_id.message}
          isDisabled={this.distSelect()}
          isRequired={!this.distSelect()}
        />

        <InputSelect
          label="Kecamatan"
          name="subdistrict_id"
          placeholder="Pilih"
          onChange={this.handleInputChange}
          value={this.state.subdistrict_id.value}
          data={this.state.subdistricts}
          isDisabled={this.fasyankesSelect()}
        />

        <InputSelect
          label="Kelurahan"
          name="urbanvillage_id"
          placeholder="Pilih"
          onChange={this.handleInputChange}
          value={this.state.urbanvillage_id.value}
          data={this.state.urbanvillages}
          isDisabled={this.fasyankesSelect()}
        />

        <InputSelect
          label="Fasyankes"
          name="fasyankes_id"
          placeholder="Pilih"
          onChange={this.handleInputChange}
          value={this.state.fasyankes_id.value}
          data={this.state.fasyankes}
          hasError={!this.state.fasyankes_id.isValid}
          errorMessage={this.state.fasyankes_id.message}
          isDisabled={this.fasyankesSelect()}
          isRequired={!this.fasyankesSelect()}
        />

        {this.props.mode !== PageMode.edit && (
          <>
            <InputField
              label="Password"
              name="password"
              type="password"
              placeholder="Buat Kata Sandi"
              onChange={this.handleInputChange}
              value={this.state.password.value}
              isRequired={true}
              hasError={!this.state.password.isValid}
              errorMessage={this.state.password.message}
            />

            <InputField
              label="Konfirmasi Password"
              name="confirm_password"
              type="password"
              placeholder="Konfirmasi Kata Sandi"
              onChange={this.handleInputChange}
              value={this.state.confirm_password.value}
              isRequired={true}
              hasError={!this.state.confirm_password.isValid}
              errorMessage={this.state.confirm_password.message}
            />
          </>
        )}

        <div className="form-group d-flex justify-content-end">
          <Button onClick={() => changeMode("view")} isSecondary>
            Batalkan
          </Button>
          <Button onClick={this.onSave} isPrimary>
            Simpan Data
          </Button>
        </div>
      </div>
    );
  }
}
export default Form;
