import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GroupMmp from "services/GroupMmp";
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import { PageMode } from "utils/constant";
import TableDataComponent from "components/organisms/TableData";
import ActionButton from "components/atoms/ActionButton";
import Button from "components/atoms/Button";
import InputField from "components/atoms/InputField";
import { setAddMode } from "redux/actions/pageAction";
import { removeDataFromLocalStorage } from "utils/common";

const KelompokMMPService = new GroupMmp();

const TableComponent = ({ handlePageModeChange, handleEditData }) => {
  const [state, setState] = useState({
    page: 1,
    limit: 10,
    searchKeyword: "",
    isLoading: true,
    data: [],
    totalData: 0,
    currentPage: 1,
    nextPage: null,
    previousPage: null,
  });

  const { currentUser } = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();

  const getAllKelompokMMP = async () => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    try {
      let searchBy = "fasyankes_id";
      let searchValue = currentUser?.fasyankes_id;

      if (state.searchKeyword !== "") {
        searchBy += ",urbanvillage_id";
        searchValue += `,${state.searchKeyword}`;
      }

      const response = await KelompokMMPService.getAll({
        page: state.page,
        limit: state.limit,
        searchBy,
        searchValue,
      });
      setState((prevState) => ({
        ...prevState,
        data: response.mmps,
        limit: response.limit,
        currentPage: response.currentPage,
        totalData: response.total,
        nextPage: response.nextPage,
        previousPage: response.previousPage,
      }));
    } catch (error) {
      console.log(error);
    } finally {
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    }
  };

  useEffect(() => {
    getAllKelompokMMP();
  }, [state.page, state.searchKeyword]);

  const onPageChange = (page) => {
    setState((prevState) => ({
      ...prevState,
      page: page,
    }));
  };

  const onDataSearch = (e) => {
    const keyword = e.target.value;
    setState({
      ...state,
      searchKeyword: keyword,
    });
  };

  const columns = [
    {
      header: "Aksi",
      accessorKey: "action",
      cell: ({ row }) => (
        <div className="button-actions">
          <ActionButton
            onClick={() => {
              const selectedData = row.original;
              handleEditData(selectedData);
            }}
            btnType={"edit"}
          />
        </div>
      ),
    },
    {
      header: "Desa",
      accessorKey: "urbanvillage_id",
      cell: ({ row }) => row.original.urbanvillage_id?.split("|")[1],
    },
    {
      header: "Dusun",
      accessorKey: "sub_village",
    },
    {
      header: "Jenis MMP",
      accessorKey: "mmp_type",
    },
    {
      header: "Nama Ketua Kelompok",
      accessorKey: "group_leader_name",
    },
    {
      header: "Kontak Ketua Kelompok",
      accessorKey: "group_leader_contact",
    },
    {
      header: "Jumlah Anggota Kelompok",
      accessorKey: "total_group_member",
    },
    {
      header: "ID Kelompok MMP",
      accessorKey: "mmp_group_id",
    },
    {
      header: "Jarak dari Fasyankes (KM)",
      accessorKey: "fasyankes_distance",
    },
  ];

  const tableInstance = useReactTable({
    columns,
    data: state?.data,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <>
      <div className="action-wrapper">
        <div className="action-button">
          {currentUser && currentUser.mode === "Online" && (
            <Button
              variant={"primary"}
              size={"normal"}
              onClick={() => {
                handlePageModeChange(PageMode.add);
                removeDataFromLocalStorage("MMP_POPULATION_DATA");
              }}
            >
              Tambah Data Kelompok MMP
            </Button>
          )}
        </div>
        <div className="action-search">
          <InputField
            placeholder={"Cari berdasarkan nama desa"}
            onChange={onDataSearch}
            value={state.searchKeyword}
          />
        </div>
      </div>
      <TableDataComponent
        columnLength={columns?.length}
        tableInstance={tableInstance}
        onPageChange={onPageChange}
        loading={state.isLoading}
        currentPage={state.currentPage}
        limit={state.limit}
        nextPage={state.nextPage}
        previousPage={state.previousPage}
        total={state.totalData}
      />
    </>
  );
};

export default TableComponent;
