import React from "react";
import PropTypes from "prop-types";

const InputField = ({
  label,
  name,
  isRequired,
  isHidden,
  isDisabled,
  isReadOnly,
  placeholder,
  type,
  onChange,
  onBlur,
  onKeyPress,
  value,
  hasError,
  errorMessage,
  ...props
}) => {
  let inputField;
  switch (type) {
    case "textarea":
      inputField = (
        <textarea
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          onKeyPress={onKeyPress}
          value={value}
          rows="4"
          className="form-control"
          disabled={isDisabled}
          readOnly={isReadOnly}
          autoComplete="off"
        ></textarea>
      );
      break;
    case "numberOnly":
      inputField = (
        <input
          type="text"
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          onKeyPress={(e) => {
            if (!/[0-9]/.test(e.key)) {
              e.preventDefault();
            }
          }}
          value={value}
          className="form-control"
          disabled={isDisabled}
          readOnly={isReadOnly}
          autoComplete="off"
          {...props}
        />
      );
      break;
    case "decimal":
      inputField = (
        <input
          type="text"
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          onKeyPress={(e) => {
            if (!/^\d*\.?\d*$/.test(e.target.value + e.key)) {
              e.preventDefault();
            }
          }}
          value={value}
          className="form-control"
          disabled={isDisabled}
          readOnly={isReadOnly}
          autoComplete="off"
          {...props}
        />
      );
      break;

    case "password":
      inputField = (
        <input
          type={"password"}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          onKeyPress={onKeyPress}
          value={value}
          className="form-control"
          disabled={isDisabled}
          readOnly={isReadOnly}
          autoComplete="off"
          {...props}
        />
      );
      break;

    default:
      inputField = (
        <input
          type={type}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          onKeyPress={onKeyPress}
          value={value}
          className="form-control"
          disabled={isDisabled}
          readOnly={isReadOnly}
          autoComplete="off"
          {...props}
        />
      );
      break;
  }
  return (
    <div
      className={`form-group ${hasError ? "has-error" : ""}`}
      style={isHidden ? { display: "none" } : {}}
    >
      <label>
        {label} {isRequired ? <sup className="text-danger">*</sup> : ""}
      </label>
      {inputField}
      {hasError && <small className="text-danger">{errorMessage}</small>}
    </div>
  );
};

InputField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  isRequired: PropTypes.bool,
  isHidden: PropTypes.bool,
  isDisabled: PropTypes.bool,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.string,
};

export default InputField;
