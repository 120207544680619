import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Route, Router, Switch } from "react-router-dom";
import { GuardRoutes } from "./components";
import { Login, MainApp } from "./pages";
import Landing from "pages/Landing";
import { loggedOut } from "./redux/actions/authAction";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import TermCondition from "pages/TermCondition";
import PrivacyPolicy from "pages/PrivacyPolicy";
import config from "utils/config";
import Maintenance from "pages/Maintenance";
import { Buffer } from 'buffer';
global.Buffer = Buffer;
class App extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { currentUser } = this.props.auth;
    const location = this.props.history.location.pathname;

    const isAuthenticated = currentUser ? currentUser.isAuthenticated : false;

    if (isAuthenticated) {
      try {
        if (location.includes("/login") || location.includes("/landing")) {
          window.browserHistory.push("/");
        }
      } catch (err) {
        console.log(err);
        if (location !== "/login" || location === "/landing") {
          this.props.history.push("/login?redirect=" + location);
        }
      }
    } else {
      this.props.loggedOut();
    }
  }

  render() {
    const { history } = this.props;
    return (
      <Fragment>
        <ToastContainer />
        {config.maintenanceMode ? (
          <Maintenance />
        ) : (
          <Router history={history}>
            <Switch>
              {/* <Route path="/example" component={ExamplePage} /> */}
              <Route path="/landing" component={Landing} exact />
              <Route path="/login" component={Login} exact />
              <Route path="/term-condition" component={TermCondition} />
              <Route path="/privacy-policy" component={PrivacyPolicy} />
              {/* route bellow only can access for authenticated user */}
              <GuardRoutes path="/" component={MainApp} />
            </Switch>
          </Router>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.authReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loggedOut: () => dispatch(loggedOut()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
