import React from "react";
import { useState } from "react";
import {
  errorToastNotif,
  getDataFromLocalStorage,
  nullConverter,
  successToastNotif,
} from "utils/common";
import { useFormik } from "formik";
import { PageMode, Source, YesAndNoOptions } from "utils/constant";
import DataDasarFaskes from "services/DataDasarFaskes";
import Fasyankes from "services/Fasyankes";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { BreadcrumbNav } from "components/molecules";
import InputDate from "components/atoms/InputDate";
import moment from "moment";
import InputSelect from "components/atoms/InputSelect";
import Button from "components/atoms/Button";
import MapsComponents from "components/Maps";
import InputField from "components/atoms/InputField";

const FasyankesService = new Fasyankes();
const DataDasarFaskesService = new DataDasarFaskes();

const FormComponent = ({ changeMode, pageMode }) => {
  const [fasyankesData, setFasyankesData] = useState([]);
  const [itemToEdit, setItemToEdit] = useState(null);

  const { currentUser } = useSelector((state) => state.authReducer);

  const formik = useFormik({
    initialValues: {
      year: "",
      is_faskes_malaria_service: "",
      is_faskes_malaria_medicine_service: "",
      fasyankes_id: "",
      address: "",
      coordinate: "",
    },
    validate: (values) => {
      const errors = {};

      if (!values.year) {
        errors.year = "Tahun wajib diisi";
      }

      if (!values.is_faskes_malaria_service) {
        errors.is_faskes_malaria_service =
          "Faskes pemberi layanan malaria wajib diisi";
      }

      if (!values.is_faskes_malaria_medicine_service) {
        errors.is_faskes_malaria_medicine_service =
          "Fasyankes rujukan obat malaria wajib diisi";
      }

      if (!values.fasyankes_id) {
        errors.fasyankes_id = "Fasyankes wajib diisi";
      }

      return errors;
    },
    onSubmit: async (values) => {
      let payload = {
        year: nullConverter(values.year),
        is_faskes_malaria_service: nullConverter(
          values.is_faskes_malaria_service
        ),
        is_faskes_malaria_medicine_service: nullConverter(
          values.is_faskes_malaria_medicine_service
        ),
        fasyankes_id: nullConverter(values.fasyankes_id),
        address: values.address,
        coordinate: nullConverter(values.coordinate),
      };
      switch (pageMode) {
        case PageMode.add:
          await DataDasarFaskesService.add(payload)
            .then((res) => {
              changeMode(PageMode.view);
              successToastNotif("Data Dasar Faskes Berhasil Ditambahkan");
            })
            .catch((err) => {
              console.log(err);
              errorToastNotif(err);
            });
          break;
        case PageMode.edit:
          await DataDasarFaskesService.update(payload, itemToEdit?.id)
            .then((res) => {
              changeMode(PageMode.view);
              successToastNotif("Data Dasar Faskes Berhasil Diupdate");
            })
            .catch((err) => {
              console.log(err);
              errorToastNotif(err);
            });
          break;
      }
    },
  });

  const fetchFasyankesData = async () => {
    try {
      const response = await FasyankesService.getAll(Source.FASYANKES, {
        searchBy: "district_id",
        searchValue: currentUser?.district_id?.split("|")[0],
        limit: 9999,
        page: 1,
        excludePkm: false,
      });

      const mappedData = response.fasyankes.map((item) => {
        return {
          label: item.name,
          value: `${item.id}|${item.name}`,
        };
      });

      setFasyankesData(mappedData);
    } catch (error) {
      console.log(error);
    }
  };

  const handleMapChange = (address, coordinate) => {
    const coordinateValue = Object.values(coordinate).toString();
    formik.setFieldValue("coordinate", coordinateValue);
  };

  useEffect(() => {
    fetchFasyankesData();
  }, []);

  useEffect(() => {
    if (pageMode === PageMode.edit) {
      const selectedItem = getDataFromLocalStorage("SELECTED_ITEM");
      const parsedData = JSON.parse(selectedItem);
      setItemToEdit(parsedData);

      formik.setValues({
        ...parsedData,
      });
    }
  }, []);

  return (
    <>
      <BreadcrumbNav
        items={[
          { label: "Data Dasar Faskes", link: "/data-dasar-faskes" },
          {
            label:
              pageMode === PageMode.add
                ? "Tambah Data Data Dasar Faskes"
                : "Edit Data Data Dasar Faskes",
            link: "#",
          },
        ]}
      />

      <h1 className="page-title">
        {pageMode === PageMode.add
          ? "Tambah Data Data Dasar Faskes"
          : "Edit Data Data Dasar Faskes"}
      </h1>

      <div className="form-grouping">
        <p
          className="warning"
          style={{ marginTop: "24px", marginBottom: "24px" }}
        >
          NOTE<span>*(WAJIB DIISI)</span>
        </p>

        <InputDate
          label={"Tahun"}
          name={"year"}
          placeholder={"Pilih Tahun"}
          dateFormat={"YYYY"}
          onChange={(e) => {
            const selectedYear = moment(e).format("YYYY");
            formik.handleChange("year")(selectedYear);
          }}
          onBlur={formik.handleBlur}
          value={formik.values.year}
          error={formik.errors.year && formik.touched.year}
          errorMessage={formik.errors.year}
          isRequired={true}
        />

        <InputSelect
          label={"Pilih Faskes"}
          name={"fasyankes_id"}
          placeholder={"Pilih Fasyankes"}
          data={fasyankesData}
          onChange={(e) => {
            const selectedValue = e ? e.value : "";
            formik.handleChange("fasyankes_id")(selectedValue);
          }}
          onBlur={formik.handleBlur}
          value={fasyankesData?.find(
            (item) => item.value === formik.values.fasyankes_id
          )}
          error={formik.errors.fasyankes_id && formik.touched.fasyankes_id}
          errorMessage={formik.errors.fasyankes_id}
          isRequired={true}
          isDisabled={pageMode === PageMode.edit}
        />

        <InputSelect
          label={"Faskes Pemberi Layanan Malaria?"}
          name={"is_faskes_malaria_service"}
          placeholder={"Pilih"}
          data={YesAndNoOptions}
          onChange={(e) => {
            const selectedValue = e ? e.value : "";
            formik.handleChange("is_faskes_malaria_service")(selectedValue);
          }}
          onBlur={formik.handleBlur}
          value={YesAndNoOptions?.find(
            (item) => item.value === formik.values.is_faskes_malaria_service
          )}
          error={
            formik.errors.is_faskes_malaria_service &&
            formik.touched.is_faskes_malaria_service
          }
          errorMessage={formik.errors.is_faskes_malaria_service}
          isRequired={true}
        />

        <InputSelect
          label={"Faskes Rujukan Obat Malaria?"}
          name={"is_faskes_malaria_medicine_service"}
          placeholder={"Pilih"}
          data={YesAndNoOptions}
          onChange={(e) => {
            const selectedValue = e ? e.value : "";
            formik.handleChange("is_faskes_malaria_medicine_service")(
              selectedValue
            );
          }}
          onBlur={formik.handleBlur}
          value={YesAndNoOptions?.find(
            (item) =>
              item.value === formik.values.is_faskes_malaria_medicine_service
          )}
          error={
            formik.errors.is_faskes_malaria_medicine_service &&
            formik.touched.is_faskes_malaria_medicine_service
          }
          errorMessage={formik.errors.is_faskes_malaria_medicine_service}
          isRequired={true}
        />

        <div className="maps-input">
          <label htmlFor="" style={{ marginBottom: "12px", fontWeight: "600" }}>
            Pilih Lokasi
          </label>
          <MapsComponents
            onChangeLocation={(address, coordinate) => {
              handleMapChange(address, coordinate);
            }}
          />
          {/* <div className="warning">
            <p className="text-title">Perhatian</p>
            <ul>
              <li>
                Untuk Kasus Indigenous, titik koordinat adalah tempat penularan
              </li>
              <li>
                Untuk Kasus Import, titik koordinat adalah rumahd/domisili
                pasien
              </li>
              <li>
                Untuk hasil optimal Lokasi Maps, pastikan terhubung dengan
                internet
              </li>
            </ul>
          </div> */}
        </div>
        <InputField
          name={"coordinate"}
          placeholder={"Titik koordinat akan muncul disini"}
          value={formik.values.coordinate}
          readOnly={true}
        />

        <div className="form_action">
          <Button
            variant={"tertiary"}
            size={"normal"}
            onClick={() => changeMode(PageMode.view)}
          >
            Batalkan
          </Button>
          <Button
            variant={"primary"}
            size={"normal"}
            onClick={formik.handleSubmit}
            isDisabled={
              formik.isSubmitting ||
              !formik.values.year ||
              !formik.values.fasyankes_id ||
              !formik.values.is_faskes_malaria_service ||
              !formik.values.is_faskes_malaria_medicine_service
            }
          >
            Simpan Data
          </Button>
        </div>
      </div>
    </>
  );
};

export default FormComponent;
