import React from "react";
import PropTypes from "prop-types";

const InputSelect = ({
  label,
  name,
  isRequired,
  isHidden,
  isDisabled,
  isGrouping,
  value,
  onChange,
  onFocus,
  onBlur,
  data,
  placeholder = "Pilih",
  hasError,
  errorMessage,
  disableOptById,
  isInline = false,
  ...props
}) => {
  return (
    <div
      className={`form-group ${hasError ? "has-error" : ""}`}
      style={isHidden ? { display: "none" } : {}}
    >
      <label className={isInline ? "mr-2" : ""}>
        {label} {isRequired ? <sup className="text-danger">*</sup> : ""}
      </label>
      <select
        name={name}
        className="form-control"
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        value={value}
        disabled={isDisabled}
        autoComplete="off"
        {...props}
      >
        <option value="">
          {placeholder}
        </option>
        {/* create optgroup */}
        {isGrouping && data && data.length > 0 ? (
          data?.map((group, index) => {
            return (
              <optgroup key={index} label={group.label}>
                {group.data?.map((item, index) => {
                  return (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  );
                })}
              </optgroup>
            );
          })
        ) : !data ? (
          <option value="" disabled>
            No Data
          </option>
        ) : (
          ""
        )}
        {/* create option */}
        {!isGrouping && data && data.length > 0 ? (
          data?.map((item, index) => {
            return (
              <option
                disabled={
                  disableOptById ? item.value === disableOptById : false
                }
                key={index}
                value={item.value || item.id}
              >
                {item.label || item.name || item.sr_name || item.ssr_name}
              </option>
            );
          })
        ) : !data ? (
          <option value="" disabled>
            No Data
          </option>
        ) : (
          ""
        )}
      </select>
      {hasError && <small className="text-danger">{errorMessage}</small>}
    </div>
  );
};

InputSelect.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  isHidden: PropTypes.bool,
  isDisabled: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  data: PropTypes.array,
};

InputSelect.defaultProps = {
  value: "",
  placeholder: "",
};

export default InputSelect;
