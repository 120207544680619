import React, { useState } from "react";
import { PageMode } from "utils/constant";
import "./index.scss";
import GroupMMPComponent from "./GroupMMP";
import CadreMMPComponent from "./CadreMMP";

const MMP = () => {
  const MODULE_NAME = {
    GroupMMP: "GROUP_MMP",
    CadreMMP: "CADRE_MMP",
  };

  const [activeModule, setActiveModule] = useState(MODULE_NAME.GroupMMP);
  const [pageMode, setPageMode] = useState(PageMode.view);

  const handleSetModuleClick = (module) => {
    setActiveModule(module);
  };

  const handlePageModeChange = (page) => {
    setPageMode(page);
  };

  return (
    <div className="dashboard-wrapper">
      {pageMode === PageMode.view && (
        <>
          <h1 className="page-title">Data MMP & Monitoring Kader MMP</h1>
          <div className="navigation-module" style={{ marginBottom: "24px" }}>
            <button
              onClick={() => handleSetModuleClick(MODULE_NAME.GroupMMP)}
              className={activeModule === MODULE_NAME.GroupMMP ? "active" : ""}
            >
              Kelompok MMP
            </button>
            <button
              onClick={() => handleSetModuleClick(MODULE_NAME.CadreMMP)}
              className={activeModule === MODULE_NAME.CadreMMP ? "active" : ""}
            >
              Data Kader MMP
            </button>
          </div>
        </>
      )}

      {/* Main content here */}
      <div className="main-content">
        {activeModule === MODULE_NAME.GroupMMP && (
          <GroupMMPComponent
            pageMode={pageMode}
            changeMode={handlePageModeChange}
          />
        )}
        {activeModule === MODULE_NAME.CadreMMP && (
          <CadreMMPComponent
            pageMode={pageMode}
            changeMode={handlePageModeChange}
          />
        )}
      </div>
    </div>
  );
};

export default MMP;
