import React, { Component } from "react";
import { PageMode } from "../../../utils/constant";
import DetailPeCase from "./components/DetailPeCase";
import TableRegmal from "./components/TableRegmal";
import Form from "./components/Form";
import PeKasus from "../../../services/PeKasus";
import FirstRegmal from "../../../services/RegmalFirst";
import { ArrowLeft } from "react-feather";
import { connect } from "react-redux";

const PeKasusService = new PeKasus();
const RegmalFirstService = new FirstRegmal();

class PeCases extends Component {
  _isMounted = true;

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      limit: 10,
      data: [],
      mode: "Regmal",
      loading: true,
      search: "",
      total: "",
      selectedItem: null,
      selectedRegmal: null,
      currentPage: 1,

      searchBy: null,
      searchValue: null,
    };
  }

  componentDidMount = () => {
    document.title = "SISMAL | PE Kasus";

    this._isMounted = true;
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  changeMode = (mode) => {
    this.setState({ mode }, () => {
      if (mode === PageMode.view) {
        this.getPeCase(this.state.selectedRegmal.id);
      }
    });
  };

  getPeCase = (regmalId) => {
    this.setState({ loading: true }, async () => {
      await PeKasusService.getAll({
        page: 1,
        limit: 1,
        searchBy: "regmal_id",
        searchValue: regmalId,
      })
        .then((data) => {
          if (this._isMounted) {
            this.setState({ loading: false, selectedItem: data.pe_cases[0] });
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({ loading: false });
        });
    });
  };

  getRegmalFirst = (searchBy = null, searchValue = null) => {
    let state = { loading: true, selectedItem: null };
    if (searchBy !== null && searchValue !== null) {
      state.searchBy = searchBy;
      state.searchValue = searchValue;
    }
    this.setState(state, async () => {
      const { page, limit } = this.state;
      await RegmalFirstService.getAll({
        page,
        limit,
        searchBy: this.state.searchBy,
        searchValue: this.state.searchValue,
      })
        .then((data) => {
          if (this._isMounted) {
            this.setState({ loading: false, data });
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({ loading: false });
        });
    });
  };

  onEdit = (peCases) => {
    this.setState({ selectedItem: peCases }, () => {
      this.changeMode(PageMode.edit);
    });
  };

  onRemove = async (id) => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      await PeKasusService.remove(id)
        .then(() => {
          this.changeMode(PageMode.view);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  onViewPeCase = (regmal) => {
    this.setState({ selectedRegmal: regmal }, () => {
      this.changeMode(PageMode.view);
    });
  };

  onSearch = (e) => {
    const search = e.target.value;
    this.setState({ search }, () => {
      this.getRegmalFirst("name", search);
    });
  };

  onPageChange = (page, tipe) => {
    if (tipe === "PeCase") {
      this.setState({ page }, () => {
        this.getPeCase();
      });
    } else {
      this.setState({ page }, () => {
        this.getRegmalFirst();
      });
    }
  };

  parentStateChanger = (stateName, value) => {
    this.setState({ [stateName]: value });
  };

  render() {
    const { data, mode, loading, page } = this.state;
    return (
      <>
        <div className="dashboard-wrapper">
          {mode !== "Regmal" && (
            <div
              className="my-3"
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.changeMode("Regmal");
              }}
            >
              <ArrowLeft />
              <span>Kembali</span>
            </div>
          )}

          <h1 className="title font-weight-bold">
            Data Penyelidikan Epidemiologi Kasus
          </h1>
          <div className="content-wrapper mt-4">
            {mode === "Regmal" && (
              <TableRegmal
                data={data}
                loading={loading}
                onEdit={this.onEdit}
                onRemove={this.onRemove}
                onSearch={this.onSearch}
                changeMode={this.changeMode}
                currentPage={page}
                onPageChange={this.onPageChange}
                onViewPeCase={this.onViewPeCase}
                getData={this.getRegmalFirst}
                parentStateChanger={this.parentStateChanger}
                user={this.props.user}
              />
            )}
            {mode === PageMode.view && (
              <DetailPeCase
                loading={loading}
                changeMode={this.changeMode}
                data={this.state.selectedItem}
                dataRegmal={this.state.selectedRegmal}
              />
            )}
            {(mode === PageMode.add || mode === PageMode.edit) && (
              <Form
                mode={mode}
                changeMode={this.changeMode}
                data={this.state.selectedItem}
                dataRegmal={this.state.selectedRegmal}
              />
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authReducer.currentUser,
  };
};

export default connect(mapStateToProps)(PeCases);
