import moment from "moment";
import React, { Component } from "react";
import { Activity, Check, FileText } from "react-feather";
import { getCurrentUserFromLocalStorage } from "utils/common";
import Fasyankes from "../../../../services/Fasyankes";
import GroupMmp from "../../../../services/GroupMmp";
import PeKasus from "../../../../services/PeKasus";
import Wilayah from "../../../../services/Wilayah";
import {
  ErrorMessage,
  PageMode,
  requestStatus,
  Source,
} from "../../../../utils/constant";
import InvestigationResult from "./FormWizard/InvestigationResult";
import Investigations from "./FormWizard/Investigations";
import { toast } from "react-toastify";

const WilayahService = new Wilayah();
const FasyankesService = new Fasyankes();
const PeKasusService = new PeKasus();
const MmpService = new GroupMmp();
// default attr with empty string or null
const defaultAttr = { value: "", isValid: true, message: "" };

export default class Form extends Component {
  _isMounted = true;

  constructor(props) {
    super(props);
    this.defaultValue = {
      step: 1,
      user: null,

      // investigation state
      total_microscopic_inspection_under_five_year: { ...defaultAttr },
      total_rdt_inspection_under_five_year: { ...defaultAttr },
      total_microscopic_inspection_over_five_year: { ...defaultAttr },
      total_rdt_inspection_over_five_year: { ...defaultAttr },
      case_interview_date: { ...defaultAttr },
      contact_survey: { ...defaultAttr },
      contact_survey_date: { ...defaultAttr },
      identify_mosquito_breeding_area: { ...defaultAttr },
      identify_mosquito_breeding_area_date: { ...defaultAttr },
      date_arrival: { ...defaultAttr },

      // invetigation result state
      recurrence: { ...defaultAttr },
      initial_case_date: { ...defaultAttr },
      transmission: { ...defaultAttr },
      transmission_origin: { ...defaultAttr },
      province_id: { ...defaultAttr },
      district_id: { ...defaultAttr },
      subdistrict_id: { ...defaultAttr },
      urbanvillage_id: { ...defaultAttr },
      sub_village: { ...defaultAttr },
      address_of_infection: { ...defaultAttr },
      address: { ...defaultAttr },
      phone_number: { ...defaultAttr },
      case_classification: { ...defaultAttr },
      fasyankes_id: { ...defaultAttr },
      country_origin: { ...defaultAttr },
      indigenous: { ...defaultAttr },
      coordinate: { ...defaultAttr },
      mmp_case: { ...defaultAttr },
      mmp_id: { ...defaultAttr },

      // state for disabled field
      enable_province_field: false,
      enable_district_field: false,
      enable_subdistrict_field: false,
      enable_fasyankes_field: false,
      enable_urbanvillage_field: false,
      enable_sub_village_name_field: false,
      enable_address_of_infection_field: false,
      enable_country_field: false,

      // state wilayah
      countries: [],
      provincies: [],
      districts: [],
      subdistricts: [],
      urbanvillages: [],
      fasyankes: [],

      mmps: [],
      isIDMMPShow: false,
      dateArrivalOpen: false,

      isLoading: false,
    };
    this.state = Object.assign({}, this.defaultValue);
  }

  nextStep = () => {
    const { step } = this.state;
    this.setState({ step: step + 1 });
  };

  prevStep = () => {
    const { step } = this.state;
    this.setState({ step: step - 1 });
  };

  coordinateSetState = (coordinate) => {
    this.setState({
      coordinate: {
        value: Object.values(coordinate).toString(),
        isvalid: true,
        message: "",
      },
    });
  };

  componentDidMount() {
    this._isMounted = true;
    this.getCountry();
    this.getProvincies();
    const user = getCurrentUserFromLocalStorage();
    if (this.props.mode === PageMode.edit) {
      const { data } = this.props;

      const stateParser = (val) => {
        return {
          value: val,
          isValid: true,
          message: "",
        };
      };
      const dateParser = (val) => {
        return val ? moment(val).format("YYYY-MM-DD") : null;
      };

      if (data?.transmission_origin === "Luar Wilayah Provinsi") {
        this.setState({
          dateArrivalOpen: true,
        });
      }

      if (data?.transmission_origin === "Luar Wilayah Kabupaten_Kota") {
        this.setState({
          dateArrivalOpen: true,
        });
      }
      this.setState({
        user,
        case_interview_date: stateParser(dateParser(data.case_interview_date)),
        contact_survey: stateParser(data.contact_survey),
        contact_survey_date: stateParser(dateParser(data.contact_survey_date)),
        identify_mosquito_breeding_area: stateParser(
          data.identify_mosquito_breeding_area
        ),
        identify_mosquito_breeding_area_date: stateParser(
          dateParser(data.identify_mosquito_breeding_area_date)
        ),
        recurrence: stateParser(data.recurrence),
        initial_case_date: stateParser(dateParser(data.initial_case_date)),
        transmission: stateParser(data.transmission),
        transmission_origin: stateParser(data.transmission_origin),
        province_id: stateParser(data.province_id),
        district_id: stateParser(data.district_id),
        subdistrict_id: stateParser(data.subdistrict_id),
        urbanvillage_id: stateParser(data.urbanvillage_id),
        sub_village: stateParser(data.sub_village),
        address_of_infection: stateParser(data.address_of_infection),
        coordinate: stateParser(data.coordinate),
        address: stateParser(data.address),
        phone_number: stateParser(data.phone_number),
        case_classification: stateParser(data.case_classification),
        fasyankes_id: stateParser(data.fasyankes_id),
        country_origin: stateParser(data.country_origin),
        indigenous: stateParser(data.indigenous),
        mmp_case: stateParser(data.mmp_case),
        mmp_id: stateParser(data.mmp_id),
        total_microscopic_inspection_under_five_year: stateParser(
          data.total_microscopic_inspection_under_five_year
        ),
        total_rdt_inspection_under_five_year: stateParser(
          data.total_rdt_inspection_under_five_year
        ),
        total_microscopic_inspection_over_five_year: stateParser(
          data.total_microscopic_inspection_over_five_year
        ),
        total_rdt_inspection_over_five_year: stateParser(
          data.total_rdt_inspection_over_five_year
        ),
        date_arrival: stateParser(dateParser(data.date_arrival)),
      });
    } else {
      this.setState({ user });
    }
  }

  handleInputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const isValid = value ? true : false;

    if (name === "survey_contact") {
      this.setState(
        {
          [name]: { value: "", isValid: true, message: "" },
        },
        () => {
          this.setState({
            survey_contact_date: { value: "", isValid: true, message: "" },
            total_microscopic_inspection_under_five_year: {
              value: "",
              isValid: true,
              message: "",
            },
            total_rdt_inspection_under_five_year: {
              value: "",
              isValid: true,
              message: "",
            },
            total_microscopic_inspection_over_five_year: {
              value: "",
              isValid: true,
              message: "",
            },
            total_rdt_inspection_over_five_year: {
              value: "",
              isValid: true,
              message: "",
            },
          });
        }
      );
    }

    if (name === "transmission") {
      this.setState(
        {
          [name]: {
            value,
            isValid: true,
            message: "",
          },
        },
        () => {
          this.setState({
            recurrence: {
              value: "",
              isValid: true,
              message: "",
            },
            initial_case_date: { ...defaultAttr },
            transmission_origin: {
              value: "",
              isValid: true,
              message: "",
            },
            province_id: { ...defaultAttr },
            district_id: { ...defaultAttr },
            subdistrict_id: { ...defaultAttr },
            fasyankes_id: { ...defaultAttr },
            urbanvillage_id: { ...defaultAttr },
            sub_village: { ...defaultAttr },
            address_of_infection: { ...defaultAttr },
            address: { ...defaultAttr },
            coordinate: { ...defaultAttr },
            mmp_case: { ...defaultAttr },
            mmp_id: { ...defaultAttr },
            country_origin: { ...defaultAttr },
          });
        }
      );
    }

    if (name === "transmission_origin") {
      this.setState({
        province_id: { ...defaultAttr },
        district_id: { ...defaultAttr },
        subdistrict_id: { ...defaultAttr },
        urbanvillage_id: { ...defaultAttr },
        fasyankes_id: { ...defaultAttr },
        sub_village: { ...defaultAttr },
        country_origin: { ...defaultAttr },
        address: { ...defaultAttr },
        districts: [],
        subdistricts: [],
        urbanvillages: [],
        fasyankes: [],
      });
      const user = this.state.user;
      const relaps = this.props.dataRegmal.recurrence === "Relaps" ? " Relaps" : "";

      switch (value) {
        case "Dalam Wilayah Puskesmas":
          this.setState({
            case_classification: {
              value: `Indigenous${relaps}`,
              isValid: true,
              message: "",
            },
            enable_province_field: false,
            enable_district_field: false,
            enable_subdistrict_field: false,
            enable_fasyankes_field: false,
            enable_urbanvillage_field: true,
            enable_sub_village_name_field: true,
            enable_address_of_infection_field: true,
            enable_country_field: false,
            province_id: { value: user.province_id, isValid: true },
            district_id: { value: user.district_id, isValid: true },
            subdistrict_id: { value: user.subdistrict_id, isValid: true },
            fasyankes_id: { value: user.fasyankes_id, isValid: true },
            urbanvillage_id: { ...defaultAttr },
            dateArrivalOpen: false,
          });

          this.getDistrictsByProvince(user.province_id?.split("|")[0]);
          this.getSubDistrictsByDistrict(user.district_id?.split("|")[0]);
          this.getFasyankesBySubDistrict(user.subdistrict_id?.split("|")[0]);
          this.getUrbanVillagesBySubDistrict(user.subdistrict_id?.split("|")[0]);
          break;
        case "Luar Wilayah Puskesmas":
          this.setState({
            case_classification: {
              value: `Indigenous${relaps}`,
              isValid: true,
              message: "",
            },
            enable_province_field: false,
            enable_district_field: false,
            enable_subdistrict_field: true,
            enable_fasyankes_field: true,
            enable_urbanvillage_field: true,
            enable_sub_village_name_field: true,
            enable_address_of_infection_field: true,
            enable_country_field: false,
            province_id: { value: user.province_id, isValid: true },
            district_id: { value: user.district_id, isValid: true },
            urbanvillage_id: { ...defaultAttr },
            dateArrivalOpen: false,
          });

          this.getDistrictsByProvince(user.province_id?.split("|")[0]);
          this.getSubDistrictsByDistrict(user.district_id?.split("|")[0]);
          break;
        case "Luar Wilayah Kabupaten_Kota":
          this.setState({
            case_classification: {
              value: `Import${relaps}`,
              isValid: true,
              message: "",
            },
            enable_province_field: false,
            enable_district_field: true,
            enable_subdistrict_field: true,
            enable_fasyankes_field: true,
            enable_urbanvillage_field: true,
            enable_sub_village_name_field: true,
            enable_address_of_infection_field: true,
            enable_country_field: false,
            province_id: { value: user.province_id, isValid: true },
            district_id: { ...defaultAttr },
            subdistrict_id: { ...defaultAttr },
            urbanvillage_id: { ...defaultAttr },
            dateArrivalOpen: true,
          });
          this.getDistrictsByProvince(user.province_id?.split("|")[0]);
          break;
        case "Luar Wilayah Provinsi":
          this.setState({
            case_classification: {
              value: `Import${relaps}`,
              isValid: true,
              message: "",
            },
            enable_province_field: true,
            enable_district_field: true,
            enable_subdistrict_field: true,
            enable_fasyankes_field: true,
            enable_urbanvillage_field: true,
            enable_sub_village_name_field: true,
            enable_address_of_infection_field: true,
            enable_country_field: false,
            province_id: { ...defaultAttr },
            district_id: { ...defaultAttr },
            subdistrict_id: { ...defaultAttr },
            urbanvillage_id: { ...defaultAttr },
            dateArrivalOpen: true,
          });
          break;
        case "Luar Wilayah Negara":
          this.setState({
            case_classification: {
              value: `Import${relaps}`,
              isValid: true,
              message: "",
            },
            enable_province_field: false,
            enable_district_field: false,
            enable_subdistrict_field: false,
            enable_fasyankes_field: false,
            enable_urbanvillage_field: false,
            enable_sub_village_name_field: false,
            enable_address_of_infection_field: false,
            enable_country_field: true,
            dateArrivalOpen: true,
          });
          this.setState({
            address_of_infection: {
              value: "",
              isValid: true,
              message: "",
            },
          });
          this.getCountry();
          break;

        default:
          this.setState({
            case_classification: {
              value: "",
              isValid: true,
              message: "",
            },
            enable_province_field: false,
            enable_district_field: false,
            enable_subdistrict_field: false,
            enable_fasyankes_field: false,
            enable_urbanvillage_field: false,
            enable_sub_village_name_field: false,
            enable_address_of_infection_field: false,
            enable_country_field: false,
            dateArrivalOpen: false,
          });
          break;
      }
    }

    if (name === "mmp_case") {
      if (value === "Tidak") {
        this.setState({ mmp_id: { value: "", isValid: true, message: "" } });
      }
    }

    this.setState({ [name]: { value, isValid } });
  };

  handleChangeProvince = (e) => {
    this.setState(
      {
        districts: [],
        subdistricts: [],
        urbanvillages: [],
        fasyankes: [],
        province_id: { value: e.value, isValid: true },
        district_id: { ...defaultAttr },
        subdistrict_id: { ...defaultAttr },
        urbanvillage_id: { ...defaultAttr },
        fasyankes_id: { ...defaultAttr },
      },
      () => {
        this.getDistrictsByProvince(e.value ? e.value?.split("|")[0] : null);
      }
    );
  };

  handleChangeDistrict = (e) => {
    this.setState(
      {
        subdistricts: [],
        urbanvillages: [],
        fasyankes: [],
        district_id: { value: e.value, isValid: true, message: "" },
        subdistrict_id: { ...defaultAttr },
        urbanvillage_id: { ...defaultAttr },
        fasyankes_id: { ...defaultAttr },
      },
      () => {
        this.getSubDistrictsByDistrict(e.value ? e.value?.split("|")[0] : null);
      }
    );
  };

  handleChangeSubDistrict = (e) => {
    this.setState(
      {
        urbanvillages: [],
        fasyankes: [],
        subdistrict_id: { value: e.value, isValid: true, message: "" },
        urbanvillage_id: { ...defaultAttr },
        fasyankes_id: { ...defaultAttr },
      },
      () => {
        this.getUrbanVillagesBySubDistrict(
          e.value ? e.value?.split("|")[0] : null
        );
        this.getFasyankesBySubDistrict(e.value ? e.value?.split("|")[0] : null);
      }
    );
  };

  handleChangeFaskes = (e) => {
    this.setState(
      {
        fasyankes_id: { value: e.value, isValid: true, message: "" },
        mmp_case: { ...defaultAttr },
      },
      () => {
        this.getMmpByFaskes(e.value);
      }
    );
  };

  handleChangeUrbanVillage = (e) => {
    this.setState({
      urbanvillage_id: { value: e.value, isValid: true, message: "" },
    }, () => {
      this.getMmpByFaskes(this.state.fasyankes_id.value);
    });
  };

  handleChangeCountry = (e) => {
    this.setState({
      country_origin: { value: e.value, isValid: true, message: "" },
    });
  };

  getCountry = async () => {
    await WilayahService.getAll(Source.COUNTRY, { page: 1, limit: 999 })
      .then((res) => {
        this.setState({
          countries: res.country,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getProvincies = async () => {
    await WilayahService.getAll(Source.PROVINCE, { page: 1, limit: 999 })
      .then((res) => {
        this.setState({
          provincies: res.province,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getDistrictsByProvince = (id) => {
    this.setState(async () => {
      await WilayahService.getAll(Source.DISTRICT, {
        searchBy: "province_id",
        searchValue: id,
        page: 1,
        limit: 999,
      })
        .then((res) => {
          this.setState({ districts: res.district });
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  getSubDistrictsByDistrict = async (id) => {
    await WilayahService.getAll(Source.SUBDISTRICT, {
      searchBy: "district_id",
      searchValue: id,
      page: 1,
      limit: 999,
    })
      .then((res) => {
        this.setState({
          subdistricts: res.subdistrict,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getUrbanVillagesBySubDistrict = async (id) => {
    await WilayahService.getAll(Source.URBANVILLAGE, {
      searchBy: "subdistrict_id",
      searchValue: id,
      page: 1,
      limit: 999,
    })
      .then((res) => {
        this.setState({
          urbanvillages: res.urbanvillage,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getFasyankesBySubDistrict = (id) => {
    this.setState(async () => {
      await FasyankesService.getAll("fasyankes", {
        searchBy: "subdistrict_id",
        searchValue: id,
      })
        .then((res) => {
          this.setState({ fasyankes: res.fasyankes });
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  getMmpByFaskes = (e) => {
    this.setState(async () => {
      await MmpService.getAll({
        limit: 999,
        searchBy: "fasyankes_id",
        searchValue: e,
      })
        .then((res) => {
          console.log(res);
          this.setState({ mmps: res.mmps });
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  nullConverter = (e) => {
    if (e === "") {
      return null;
    }
    return e;
  };

  onSave = async () => {
    this.setState({
      isLoading: true,
    });

    const payload = {
      originator_id: this.state.user.id,
      date_arrival: this.nullConverter(this.state.date_arrival.value),
      case_interview_date: this.nullConverter(
        this.state.case_interview_date.value
      ),
      contact_survey: this.nullConverter(this.state.contact_survey.value),
      contact_survey_date: this.nullConverter(
        this.state.contact_survey_date.value
      ),
      identify_mosquito_breeding_area: this.nullConverter(
        this.state.identify_mosquito_breeding_area.value
      ),
      identify_mosquito_breeding_area_date: this.nullConverter(
        this.state.identify_mosquito_breeding_area_date.value
      ),
      recurrence: this.nullConverter(this.props.dataRegmal.recurrence),
      initial_case_date: this.nullConverter(this.state.initial_case_date.value),
      transmission: this.nullConverter(this.state.transmission.value),
      transmission_origin: this.nullConverter(
        this.state.transmission_origin.value
      ),
      province_id: this.nullConverter(this.state.province_id.value),
      district_id: this.nullConverter(this.state.district_id.value),
      subdistrict_id: this.nullConverter(this.state.subdistrict_id.value),
      urbanvillage_id: this.nullConverter(this.state.urbanvillage_id.value),
      sub_village: this.state.sub_village.value,
      address_of_infection: this.state.address_of_infection.value,
      coordinate: this.state.coordinate.value,
      address: this.state.address.value,
      phone_number: this.state.phone_number.value,
      case_classification: this.nullConverter(
        this.state.case_classification.value
      ),
      fasyankes_id: this.state.fasyankes_id.value,
      country_origin: this.state.country_origin.value,
      indigenous: this.nullConverter(this.state.indigenous.value),
      mmp_case: this.nullConverter(this.state.mmp_case.value),
      mmp_id: this.state.mmp_id.value,
      total_microscopic_inspection_under_five_year: this.nullConverter(
        this.state.total_microscopic_inspection_under_five_year.value
      ),
      total_rdt_inspection_under_five_year: this.nullConverter(
        this.state.total_rdt_inspection_under_five_year.value
      ),
      total_microscopic_inspection_over_five_year: this.nullConverter(
        this.state.total_microscopic_inspection_over_five_year.value
      ),
      total_rdt_inspection_over_five_year: this.nullConverter(
        this.state.total_rdt_inspection_over_five_year.value
      ),
      regmal_id: this.props.dataRegmal.id,
    };

    switch (this.props.mode) {
      case PageMode.add:
        await PeKasusService.add(payload)
          .then((res) => {
            this.setState({ isLoading: false });
            this.setState(this.defaultValue, () => {
              this.props.changeMode("view");
            });
            toast.success("Data PE Kasus Berhasil Disimpan", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          })
          .catch((err) => {
            this.setState({ isLoading: false });
            console.log(err);
            if (err.response.status === requestStatus.BadRequest) {
              toast.error(ErrorMessage.UserFaskesNotHaveAccess, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
            if (err.response.status === requestStatus.Forbidden) {
              toast.error(ErrorMessage.UserNotVerified, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
            if (err.response.status === requestStatus.Conflict) {
              toast.error(ErrorMessage.DataAlreadyExist, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          });
        break;
      case PageMode.edit:
        await PeKasusService.update(payload, this.props.data.id)
          .then((res) => {
            this.setState({ isLoading: false });
            this.setState(this.defaultValue, () => {
              this.props.changeMode("view");
            });
            toast.success("Data PE Kasus Berhasil Diupdate", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          })
          .catch((err) => {
            this.setState({ isLoading: false });
            console.log(err);
            if (err.response.status === requestStatus.BadRequest) {
              toast.error(ErrorMessage.UserFaskesNotHaveAccess, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
            if (err.response.status === requestStatus.Forbidden) {
              toast.error(ErrorMessage.UserNotVerified, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
            if (err.response.status === requestStatus.Conflict) {
              toast.error(ErrorMessage.DataAlreadyExist, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          });
        break;
      default:
        return null;
    }
  };

  parentStateChanger = (stateName, value) => {
    this.setState({ [stateName]: value });
  };

  render() {
    const {
      step,
      countries,
      provincies,
      districts,
      subdistricts,
      urbanvillages,
      fasyankes,
      mmps,
      user,
    } = this.state;

    const { mode, data, changeMode, dataRegmal } = this.props;

    const renderForm = () => {
      switch (step) {
        case 1:
          return (
            <Investigations
              user={user}
              dataRegmal={dataRegmal}
              nextStep={this.nextStep}
              handleInputChange={this.handleInputChange}
              values={this.state}
              changeMode={changeMode}
              mode={mode}
              parentStateChanger={this.parentStateChanger}
            />
          );
        case 2:
          return (
            <InvestigationResult
              user={user}
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              handleInputChange={this.handleInputChange}
              handleChangeProvince={this.handleChangeProvince}
              handleChangeDistrict={this.handleChangeDistrict}
              handleChangeSubDistrict={this.handleChangeSubDistrict}
              handleChangeUrbanVillage={this.handleChangeUrbanVillage}
              handleChangeFaskes={this.handleChangeFaskes}
              handleChangeCountry={this.handleChangeCountry}
              values={this.state}
              countries={countries}
              provincies={provincies}
              districts={districts}
              subdistricts={subdistricts}
              urbanvillages={urbanvillages}
              fasyankes={fasyankes}
              mmps={mmps}
              submitFormData={this.onSave}
              setCoord={this.coordinateSetState}
              mode={mode}
              data={data}
              dataRegmal={dataRegmal}
              parentStateChanger={this.parentStateChanger}
            />
          );
        default:
          return null;
      }
    };
    return (
      <div className="mt-4">
        <div className="row">
          <div className="col-sm-12 mx-auto">
            <div className="row">
              <div className="col-sm-12 mx-auto">
                <div className="wizard-controller">
                  <div className="step-wrapper">
                    <div
                      className={
                        step !== 1
                          ? "step-indicator complete"
                          : "step-indicator active"
                      }
                    >
                      {step !== 1 ? <Check /> : <FileText />}
                    </div>
                    <p className={step > 1 ? "text-active" : ""}>
                      Penyelidikan
                    </p>
                  </div>

                  <hr className={step !== 1 ? "complete" : ""} />

                  <div
                    className="step-wrapper text-center"
                    style={{ marginTop: "10px" }}
                  >
                    <div
                      className={
                        step === 2
                          ? "step-indicator active"
                          : step > 2
                            ? "step-indicator complete"
                            : "step-indicator"
                      }
                    >
                      {step > 2 ? <Check /> : <Activity />}
                    </div>
                    <p className={step > 2 ? "text-active" : ""}>
                      Hasil Penyelidikan
                    </p>
                  </div>
                </div>

                <p
                  className="font-weight-bold d-flex justify-content-start"
                  style={{ paddingLeft: "10px" }}
                >
                  NOTE:
                  <span
                    className="text-danger"
                    style={{ color: "red" }}
                    onClick={this.filledForm}
                  >
                    *(WAJIB DIISI)
                  </span>
                </p>
              </div>
            </div>
            <div className="form-content">{renderForm()}</div>
          </div>
        </div>
      </div>
    );
  }
}
