import React, { Component } from "react";
import { PlusCircle } from "react-feather";
import { TableComponent } from "components";
import { getCurrentUserFromLocalStorage } from "utils/common";

export default class Table extends Component {
  render() {
    const { data, changeMode, loading, onEdit, onRemove, onPageChange } =
      this.props;
    const currentUser = getCurrentUserFromLocalStorage();

    return (
      <div>
        <div className="row">
          <div className="col-sm-8">
            {currentUser && currentUser.mode === "Online" && (
              <button
                className="btn btn-teal font-weight-bold"
                onClick={() => changeMode("add")}
              >
                <PlusCircle className="mr-2" />
                Tambah Data Logistik Expired/Rusak
              </button>
            )}
          </div>
        </div>

        {currentUser && currentUser.fasyankes_type_id === "jnsfas-01" && (
          <TableComponent
            loading={loading}
            data={data?.logistic_expired_stocks}
            headers={[
              "Provinsi",
              "Kabupaten",
              "Kecamatan",
              "Fasyankes",
              "Tahun",
              "Bulan",
              "Jenis Logistik",
              "Laporan",
              "Jumlah",
            ]}
            cols={[
              ["province_id", "|", 1],
              ["district_id", "|", 1],
              ["subdistrict_id", "|", 1],
              ["fasyankes_id", "|", 1],
              "year",
              "month",
              "logistic_type",
              "report",
              "total",
            ]}
            btnEdit={{ onClick: onEdit }}
            btnRemove={{ onClick: onRemove }}
            currentPage={data?.currentPage}
            total={data?.total}
            limit={data?.limit}
            nextPage={data?.nextPage}
            previousPage={data?.previousPag}
            onPageChange={onPageChange}
          />
        )}

        {currentUser && currentUser.fasyankes_type_id !== "jnsfas-01" && (
          <TableComponent
            loading={loading}
            data={data?.logistic_expired_stocks}
            headers={[
              "Provinsi",
              "Kabupaten",
              "Fasyankes",
              "Tahun",
              "Bulan",
              "Jenis Logistik",
              "Laporan",
              "Jumlah",
            ]}
            cols={[
              ["province_id", "|", 1],
              ["district_id", "|", 1],
              ["fasyankes_id", "|", 1],
              "year",
              "month",
              "logistic_type",
              "report",
              "total",
            ]}
            btnEdit={{ onClick: onEdit }}
            btnRemove={{ onClick: onRemove }}
            currentPage={data?.currentPage}
            total={data?.total}
            limit={data?.limit}
            nextPage={data?.nextPage}
            previousPage={data?.previousPag}
            onPageChange={onPageChange}
          />
        )}
      </div>
    );
  }
}
