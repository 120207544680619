import React from "react";
import PropTypes from "prop-types";

const InputPassword = ({ label,
  name,
  isRequired,
  isHidden,
  isDisabled,
  placeholder,
  onChange,
  onBlur,
  onFocus,
  value,
  hasError,
  errorMessage,
  type,
  onShowPassword,
  isReadOnly,
}) => {
  return (
    <div className={`form-group ${hasError ? "has-error" : ""}`} style={isHidden ? { display: "none" } : {}}>
      {label && <label>{label} {isRequired ? <sup className="text-danger">*</sup> : ""}</label>}
      <div className="input-icon">
        <input
          name={name}
          type={type}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          value={value}
          className="form-control"
          disabled={isDisabled}
          readOnly={isReadOnly}
        />
        <span
          className="input-icon-addon"
          onClick={onShowPassword}
        >
          <i
            className={
              type === "password"
                ? "far fa-eye"
                : "far fa-eye-slash"
            }
          />
        </span>
      </div>
      {hasError && <small className="text-danger">{errorMessage}</small>}
    </div>
  );
};

InputPassword.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  isHidden: PropTypes.bool,
  isDisabled: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyPress: PropTypes.func,
  value: PropTypes.string,
};

InputPassword.defaultProps = {
  label: "",
  name: "",
  value: "",
  type: "password"
};

export default InputPassword;