import React from "react";
import "./Form.scss";
import InputField from "components/atoms/InputField";
import InputSelect from "components/atoms/InputSelect";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useState } from "react";
import { errorToastNotif, nullConverter } from "utils/common";
import {
  CadreOriginOptions,
  CadreTupoksiOptions,
  EducationOptions,
  FundSourceCadreOptions,
  GenderOptions,
  PageMode,
  Source,
  YesAndNoOptions,
} from "utils/constant";
import Wilayah from "services/Wilayah";
import UKBM from "services/UKBM";
import { useEffect } from "react";
import InputCheckbox from "components/atoms/InputCheckbox";
import InputRadio from "components/atoms/InputRadio";
import InputDate from "components/atoms/InputDate";
import Button from "components/atoms/Button";
import Kader from "services/Kader";
import { toast } from "react-toastify";

const WilayahService = new Wilayah();
const UKBMSerice = new UKBM();
const KaderService = new Kader();

const Form = ({ mode, changeMode, cadre }) => {
  const [urbanvillages, setUrbanvillages] = useState([]);
  const [srOptions, setSrOptions] = useState([]);
  const [ssrOptions, setSsrOptions] = useState([]);

  const { currentUser } = useSelector((state) => state.authReducer);

  const formik = useFormik({
    initialValues: {
      is_foreign_nationality: "Tidak",
      not_have_ktp: "Tidak",
      ktp: "",
      cadre_origin: "",
      sr_id: "",
      ssr_id: "",
      name: "",
      age: "",
      gender: "",
      last_education: "",
      is_sk_cadre: "",
      sk_date: "",
      last_training_date: "",
      village_assignment: [],
      fund_source_cadre: "",
      other_fund_source_cadre: "",
      cadre_tupoksi: [],
      other_cadre_tupoksi: "",
    },
    validate: (values) => {
      const errors = {};

      if (values.ktp) {
        if (values.ktp.length < 16) {
          errors.ktp = "Nomor KTP harus 16 digit";
        }
      }

      if (values.cadre_origin === "Perdhaki") {
        if (!values.sr_id) {
          errors.sr_id = "SR wajib dipilih";
        }
        if (!values.ssr_id) {
          errors.ssr_id = "SSR wajib dipilih";
        }
      }

      if (!values.name) {
        errors.name = "Nama kader wajib diisi";
      }

      if (!values.age) {
        errors.age = "Usia kader wajib diisi";
      } else if (!/^[1-9][0-9]*$/.test(values.age)) {
        errors.age = "Usia tidak boleh di awali dengan 0";
      } else if (values.age.length > 2) {
        errors.age = "Usia maksimal 2 digit angka";
      }

      if (!values.gender) {
        errors.gender = "Jenis kelamin wajib diisi";
      }

      if (!values.last_education) {
        errors.last_education = "Pendidikan Terakhir wajib diisi";
      }

      if (!values.is_sk_cadre) {
        errors.is_sk_cadre = "Apakah mempunyai SK kader wajib dipilih";
      }

      if (
        !values.village_assignment ||
        values.village_assignment.length === 0
      ) {
        errors.village_assignment = "Desa tempat bertugas wajib diisi";
      }

      if (!values.fund_source_cadre) {
        errors.fund_source_cadre = "Sumber dana kader wajib diisi";
      }

      return errors;
    },
    onSubmit: async (values) => {
      const payload = {
        fasyankes_id: currentUser?.fasyankes_id,
        is_foreign_nationality: values.is_foreign_nationality,
        not_have_ktp: values.not_have_ktp,
        ktp: nullConverter(values.ktp),
        cadre_origin: values.cadre_origin,
        sr_id: values.sr_id,
        ssr_id: values.ssr_id,
        name: values.name,
        age: values.age,
        gender: values.gender,
        last_education: values.last_education,
        is_sk_cadre: nullConverter(values.is_sk_cadre),
        sk_date: nullConverter(values.sk_date),
        last_training_date: values.last_training_date,
        village_assignment: values.village_assignment.map((item) => item.value),
        fund_source_cadre:
          values.fund_source_cadre === "Lainnya"
            ? `Lainnya_${values.other_fund_source_cadre}`
            : values.fund_source_cadre,
        cadre_tupoksi: values.cadre_tupoksi.includes("Lainnya")
          ? [...values.cadre_tupoksi, values.other_cadre_tupoksi]
          : values.cadre_tupoksi.length === 0
            ? nullConverter(values.cadre_tupoksi)
            : values.cadre_tupoksi,
      };

      switch (mode) {
        case PageMode.add:
          await KaderService.add(payload)
            .then((res) => {
              changeMode(PageMode.view);
              toast.success("Data Kader Endemis Berhasil Ditambahkan", {
                position: "top-center",
                theme: "colored",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
            })
            .catch((err) => {
              errorToastNotif(err);
            });
          break;
        case PageMode.edit:
          await KaderService.update(payload, cadre.id)
            .then((res) => {
              changeMode(PageMode.view);
              toast.success("Data Kader Endemis Berhasil Diperbarui", {
                position: "top-center",
                theme: "colored",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
            })
            .catch((err) => {
              errorToastNotif(err);
            });
          break;
      }
    },
  });

  const getAllSrOptions = async () => {
    try {
      const response = await UKBMSerice.getAllSR("sr", "");
      setSrOptions(
        response.sr.map((item) => {
          return {
            label: item.sr_name,
            value: item.id,
          };
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getSsrOptionsBySr = async (value) => {
    try {
      const response = await UKBMSerice.getSSRbySR("ssr", "sr_id", value);
      setSsrOptions(
        response.ssr.map((item) => {
          return {
            label: item.ssr_name,
            value: item.id,
          };
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getUrbanvillagesByCurrentUser = async () => {
    try {
      const response = await WilayahService.getAll(Source.URBANVILLAGE, {
        searchBy: "subdistrict_id",
        searchValue: currentUser?.subdistrict_id?.split("|")[0],
        page: 1,
        limit: 999,
      });
      setUrbanvillages(
        response.urbanvillage.map((item) => {
          return {
            label: item.name,
            value: `${item.id}|${item.name}`,
          };
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUrbanvillagesByCurrentUser();
    getAllSrOptions();
  }, []);

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    const value = e.target.value;
    const newValues = isChecked
      ? [...formik.values.cadre_tupoksi, value]
      : formik.values.cadre_tupoksi.filter((item) => item !== value);
    formik.setFieldValue("cadre_tupoksi", newValues);
  };

  useEffect(() => {
    if (mode === PageMode.edit) {
      const village_assignments = cadre?.village_assignment.map((item) => {
        return {
          value: item,
          label: item?.split("|")[1],
        };
      });

      let fundSourceCadre = "";
      let otherFundSourceCadre = "";

      if (
        FundSourceCadreOptions.filter(
          (e) => e.value === cadre.fund_source_cadre
        ).length > 0
      ) {
        fundSourceCadre = cadre.fund_source_cadre;
      } else {
        fundSourceCadre = "Lainnya";
        otherFundSourceCadre = cadre.fund_source_cadre?.split("_")[1];
      }

      getSsrOptionsBySr(cadre.sr_id);

      formik.setValues({
        is_foreign_nationality: cadre.is_foreign_nationality,
        not_have_ktp: cadre.not_have_ktp,
        cadre_origin: cadre.cadre_origin,
        sr_id: cadre.sr_id,
        ssr_id: cadre.ssr_id,
        ktp: cadre.ktp,
        name: cadre.name,
        age: cadre.age,
        gender: cadre.gender,
        last_education: cadre.last_education,
        is_sk_cadre: cadre.is_sk_cadre,
        sk_date: cadre.sk_date && moment(cadre.sk_date).format("YYYY-MM-DD"),
        last_training_date:
          cadre.last_training_date &&
          moment(cadre.last_training_date).format("YYYY-MM-DD"),
        village_assignment: village_assignments,
        fund_source_cadre: fundSourceCadre,
        other_fund_source_cadre: otherFundSourceCadre,
        cadre_tupoksi: cadre.cadre_tupoksi || [],
        other_cadre_tupoksi:
          cadre.cadre_tupoksi && cadre.cadre_tupoksi.length > 0
            ? cadre.cadre_tupoksi[cadre.cadre_tupoksi.length - 1]
            : "",
      });
    }
  }, [cadre]);

  return (
    <div className="row">
      <div className="col-sm-12">
        <p className="required-text">
          NOTE:<span>*(WAJIB DIISI)</span>
        </p>
        <div className="form-wrapper">
          <InputField
            label={"NIK"}
            name={"ktp"}
            type={"number"}
            maxLengthVal={16}
            placeholder={"Masukkan Nomor KTP/NIk disini"}
            {...formik.getFieldProps("ktp")}
            error={
              formik.values.ktp !== "" &&
              formik.errors.ktp &&
              formik.touched.ktp
            }
            errorMessage={formik.values.ktp !== "" && formik.errors.ktp}
          />
          <InputSelect
            label={"Asal Kader"}
            name={"cadre_origin"}
            placeholder={"Pilih Asal Kader"}
            data={CadreOriginOptions}
            onChange={(e) => {
              const selectedValue = e ? e.value : "";
              formik.handleChange("cadre_origin")(selectedValue);
              if (selectedValue === "Bukan Perdhaki") {
                formik.setFieldValue("sr_id", "");
                formik.setFieldValue("ssr_id", "");
              }
            }}
            onBlur={formik.handleBlur}
            value={CadreOriginOptions.find(
              (option) => option.value === formik.values.cadre_origin
            )}
            error={formik.errors.cadre_origin && formik.touched.cadre_origin}
            errorMessage={formik.errors.cadre_origin}
            isRequired={true}
          />
          {formik.values.cadre_origin === "Perdhaki" && (
            <div className="row">
              <div className="col-sm-6">
                <InputSelect
                  label={"Pilih SR"}
                  name={"sr_id"}
                  placeholder={"Pilih SR"}
                  data={srOptions}
                  onChange={(e) => {
                    const selectedValue = e ? e.value : "";
                    formik.handleChange("sr_id")(selectedValue);
                    formik.handleChange("ssr_id")("");
                    getSsrOptionsBySr(selectedValue);
                  }}
                  onBlur={formik.handleBlur}
                  value={srOptions.find(
                    (option) => option.value === formik.values.sr_id
                  )}
                  error={formik.errors.sr_id && formik.touched.sr_id}
                  errorMessage={formik.errors.sr_id}
                  isRequired={true}
                />
              </div>
              <div className="col-sm-6">
                <InputSelect
                  label={"Pilih SSR"}
                  name={"ssr_id"}
                  placeholder={"Pilih SSR"}
                  data={formik.values.sr_id ? ssrOptions : []}
                  onChange={(e) => {
                    const selectedValue = e ? e.value : "";
                    formik.handleChange("ssr_id")(selectedValue);
                  }}
                  onBlur={formik.handleBlur}
                  value={ssrOptions.find(
                    (option) => option.value === formik.values.ssr_id
                  ) || null}
                  error={formik.errors.ssr_id && formik.touched.ssr_id}
                  errorMessage={formik.errors.ssr_id}
                  isRequired={true}
                />
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-sm-6">
              <InputField
                label={"Nama"}
                name={"name"}
                placeholder={"Masukkan Nama Kader"}
                type={"text"}
                {...formik.getFieldProps("name")}
                error={formik.errors.name && formik.touched.name}
                errorMessage={formik.errors.name}
                isRequired={true}
                disabled={mode === PageMode.edit}
              />
            </div>
            <div className="col-sm-6">
              <InputField
                label={"Usia"}
                name={"age"}
                placeholder={"Masukkan Usia Kader"}
                type={"number"}
                maxLengthVal={2}
                {...formik.getFieldProps("age")}
                error={formik.errors.age && formik.touched.age}
                errorMessage={formik.errors.age}
                isRequired={true}
                disabled={mode === PageMode.edit}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <InputSelect
                label={"Jenis Kelamin"}
                name={"gender"}
                placeholder={"Pilih Jenis kelamin"}
                data={GenderOptions}
                onChange={(e) => {
                  const selectedValue = e ? e.value : "";
                  formik.handleChange("gender")(selectedValue);
                }}
                onBlur={formik.handleBlur}
                value={GenderOptions.find(
                  (option) => option.value === formik.values.gender
                )}
                error={formik.errors.gender && formik.touched.gender}
                errorMessage={formik.errors.gender}
                isRequired={true}
                disabled={mode === PageMode.edit}
              />
            </div>
            <div className="col-sm-6">
              <InputSelect
                label={"Pendidikan Terakhir"}
                name={"gender"}
                placeholder={"Pilih Pendidikan Terakhir"}
                data={EducationOptions}
                onChange={(e) => {
                  const selectedValue = e ? e.value : "";
                  formik.handleChange("last_education")(selectedValue);
                }}
                onBlur={formik.handleBlur}
                value={EducationOptions.find(
                  (option) => option.value === formik.values.last_education
                )}
                error={
                  formik.errors.last_education && formik.touched.last_education
                }
                errorMessage={formik.errors.last_education}
                isRequired={true}
                disabled={mode === PageMode.edit}
              />
            </div>
          </div>

          <InputRadio
            label="Apakah mempunyai SK Penunjukan Kader?"
            name="is_sk_cadre"
            options={YesAndNoOptions}
            onChange={(e) => {
              const selectedValue = e.target.value;
              formik.handleChange("is_sk_cadre")(selectedValue);
              if (selectedValue !== "Ya") {
                formik.setFieldValue("sk_date", "");
              }
            }}
            onBlur={formik.handleBlur}
            selectedOption={formik.values.is_sk_cadre}
            error={formik.errors.is_sk_cadre && formik.touched.is_sk_cadre}
            errorMessage={formik.errors.is_sk_cadre}
            isRequired={true}
          />

          {formik.values.is_sk_cadre === "Ya" && (
            <InputDate
              dateFormat={"YYYY-MM-DD"}
              label={"Tanggal Penetapan SK"}
              name={"sk_date"}
              placeholder={"Masukkan tanggal penetapan SK"}
              onChange={(e) => {
                const selectedDate = moment(e).format("YYYY-MM-DD");
                formik.handleChange("sk_date")(selectedDate);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.sk_date}
            />
          )}

          <InputDate
            dateFormat={"YYYY-MM-DD"}
            label={"Tanggal Pelatihan Terakhir"}
            name={"last_training_date"}
            placeholder={"Masukkan tanggal pelatihan terakhir"}
            onChange={(e) => {
              const selectedDate = moment(e).format("YYYY-MM-DD");
              formik.handleChange("last_training_date")(selectedDate);
            }}
            onBlur={formik.handleBlur}
            value={formik.values.last_training_date}
          />

          <InputSelect
            label={"Pilih desa tempat bertugas"}
            name={"village_assignment"}
            placeholder={"Pilih Desa Tempat Bertugas"}
            data={urbanvillages}
            onChange={(selectedValue) => {
              formik.setFieldValue("village_assignment", selectedValue);
            }}
            onBlur={formik.handleBlur}
            value={formik.values.village_assignment}
            error={
              formik.errors.village_assignment &&
              formik.touched.village_assignment
            }
            errorMessage={formik.errors.village_assignment}
            isMultipleSelect={true}
            isRequired={true}
          />

          <InputSelect
            label={"Sumber Pendanaan Kader"}
            name={"fund_source_cadre"}
            placeholder={"Pilih sumber pendanaan kader"}
            data={FundSourceCadreOptions}
            onChange={(e) => {
              const selectedValue = e ? e.value : "";
              formik.handleChange("fund_source_cadre")(selectedValue);
            }}
            onBlur={formik.handleBlur}
            value={FundSourceCadreOptions.find(
              (option) => option.value === formik.values.fund_source_cadre
            )}
            error={
              formik.errors.fund_source_cadre &&
              formik.touched.fund_source_cadre
            }
            errorMessage={formik.errors.fund_source_cadre}
            isRequired={true}
          />

          {formik.values.fund_source_cadre === "Lainnya" && (
            <InputField
              name={"other_fund_source_cadre"}
              placeholder={"Masukkan nama sumber pendanaan"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.other_fund_source_cadre}
              error={
                formik.errors.other_fund_source_cadre &&
                formik.touched.other_fund_source_cadre
              }
              errorMessage={formik.errors.other_fund_source_cadre}
            />
          )}

          <InputCheckbox
            label="Tupoksi Kader"
            name="cadre_tupoksi"
            options={CadreTupoksiOptions}
            onChange={(e) => handleCheckboxChange(e)}
            onBlur={formik.handleBlur}
            selectedOption={formik.values.cadre_tupoksi}
          />

          {formik.values.cadre_tupoksi.includes("Lainnya") && (
            <InputField
              name={"other_cadre_tupoksi"}
              placeholder={"Masukkan tupoksi kader"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.other_cadre_tupoksi}
              error={
                formik.errors.other_cadre_tupoksi &&
                formik.touched.other_cadre_tupoksi
              }
              errorMessage={formik.errors.other_cadre_tupoksi}
            />
          )}

          <div className="form_action">
            <Button
              variant={"tertiary"}
              size={"normal"}
              onClick={() => changeMode(PageMode.view)}
            >
              Batalkan
            </Button>
            <Button
              variant={"primary"}
              size={"normal"}
              onClick={formik.handleSubmit}
              isDisabled={
                formik.isSubmitting ||
                !formik.values.name ||
                !formik.values.age ||
                !formik.values.gender ||
                !formik.values.last_education ||
                !formik.values.is_sk_cadre ||
                formik.values.village_assignment.length === 0 ||
                !formik.values.fund_source_cadre ||
                (formik.values.ktp !== "" && formik.errors.ktp)
              }
            >
              Simpan Data
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form;
