import { errHandler } from "utils/common";
import api from "utils/api";

export default class Achieved {
  getAll = (
    opt = {
      page: 1,
      limit: 10,  
      searchBy: null,
      searchValue: null,
    }
  ) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/kia_screening_achieveds`, { params: opt })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          errHandler(err);
        });
    });
  };

  getOne = (id) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/kia_screening_achieved/${id}`)
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  add = (payload) => {
    return new Promise((resolve, reject) => {
      api
        .post(`/api/kia_screening_achieved?device=Online`, payload)
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  update = (payload, id) => {
    return new Promise((resolve, reject) => {
      api
        .patch(`/api/kia_screening_achieved/${id}?device=Online`, payload)
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  remove = (id) => {
    return new Promise((resolve, reject) => {
      api
        .delete(`/api/kia_screening_achieved/${id}?device=Online`)
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  getTotalBumilBalita = (
    opt = {
      searchBy: null,
      searchValue: null,
    }
  ) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/kia_total_bumil_balita`, { params: opt })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          errHandler(err);
        });
    });
  };


}
