import React, { Component } from "react";
import {
  ErrorMessage,
  PageMode,
  requestStatus,
} from "../../../../utils/constant";
import { InputField, Button, InputSelect } from "components";
import InputDateTime from "components/InputDateTime";
import moment from "moment";
import { toast } from "react-toastify";
import Screening from "services/Screening";

const ScreeningService = new Screening();

export default class Form extends Component {
  _isMounted = true;
  constructor(props) {
    super(props);
    this.defaultValue = {
      year: { value: moment().format("YYYY"), isValid: true, message: "" },
      total_target_pregnant: { value: "", isValid: true, message: "" },
      total_target_toddler: { value: "", isValid: true, message: "" },

      isLoading: false,
    };
    this.state = Object.assign({}, this.defaultValue);
  }

  componentDidMount = () => {
    this._isMounted = true;

    let obj = Object.assign({}, this.defaultValue);
    if (this.props.mode === PageMode.edit) {
      const { data } = this.props;

      obj.year.value = data.year;
      obj.total_target_pregnant.value = data.total_target_pregnant;
      obj.total_target_toddler.value = data.total_target_toddler;
    }
    this.setState(obj);
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    let obj = Object.assign({}, this.state[name]);
    obj.value = value;

    this.setState({
      [name]: obj,
    });
  };

  /**
   * function to handle input validation
   */
  validationHandler = () => {
    const { year, total_target_pregnant, total_target_toddler } = this.state;

    let isValid = true;

    if (year.value === "") {
      year.isValid = false;
      year.message = "Tahun tidak boleh kosong";
      isValid = false;
    }

    if (total_target_pregnant.value === "") {
      total_target_pregnant.isValid = false;
      total_target_pregnant.message =
        "Jumlah sasaran ibu hamil tidak boleh kosong";
      isValid = false;
    }

    if (total_target_toddler.value === "") {
      total_target_toddler.isValid = false;
      total_target_toddler.message = "Jumlah sasaran kunjungan balita sakit tidak boleh kosong";
      isValid = false;
    }

    this.setState({
      year,
      total_target_pregnant,
      total_target_toddler,
    });

    return isValid;
  };

  errorNotif = (err) => {
    const message = err.response.data.message;
    switch (message) {
      case requestStatus.Forbidden:
        toast.error(ErrorMessage.UserNotVerified, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        break;
      case requestStatus.BadRequest:
        toast.error(ErrorMessage.UserFaskesNotHaveAccess, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        break;
      case requestStatus.Conflict:
        toast.error(ErrorMessage.DataAlreadyExist, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        break;

      default:
        toast.error(
          <>
            Gagal menyimpan data! <br />
            Error: {message}
          </>,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        break;
    }
  };

  nullConverter = (value) => {
    if (value === "") {
      return null;
    }
    return value;
  }

  onSave = async (e) => {
    e.preventDefault();

    const isValid = this.validationHandler();
    const { currentUser } = this.props;

    if (isValid) {

      this.setState({
        isLoading: true,
      });

      let payload = {
        fasyankes_id: this.nullConverter(currentUser.fasyankes_id),
        year: this.nullConverter(this.state.year.value),
        total_target_pregnant: this.nullConverter(this.state.total_target_pregnant.value),
        total_target_toddler: this.nullConverter(this.state.total_target_toddler.value),
      };

      switch (this.props.mode) {
        case PageMode.add:
          await ScreeningService.add(payload)
            .then((res) => {
              this.setState({
                isLoading: false,
              });

              this.setState(this.defaultValue, () => {
                this.props.changeMode("view");
              });
              toast.success("Data Berhasil ditambahkan", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            })
            .catch((err) => {
              this.setState({
                isLoading: false,
              });

              console.log(err);
              this.errorNotif(err);
            });
          break;
        case PageMode.edit:
          await ScreeningService.update(payload, this.props.data.id)
            .then((res) => {
              this.setState({
                isLoading: false,
              });

              this.setState(this.defaultValue, () => {
                this.props.changeMode("view");
              });
              toast.success("Data Berhasil diperbarui", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            })
            .catch((err) => {
              this.setState({
                isLoading: false,
              });

              console.log(err);
              this.errorNotif(err);
            });
          break;
      }
    }

  };

  mapper = (e) => {
    const options = [];
    e.map((x) => {
      options.push({ value: `${x.id}|${x.name}`, label: x.name });
    });
    return options;
  };

  render() {
    const { year, total_target_pregnant, total_target_toddler } = this.state;

    return (
      <div className="row">
        <div className="col-sm-12">
          <p className="font-weight-bold" onClick={this.onFillForm}>
            NOTE: <span className="text-danger">*(WAJIB DIISI)</span>
          </p>

          <div className="form-wrapper">
            <InputDateTime
              label="Tahun"
              name="year"
              dateFormat="YYYY"
              value={year.value}
              placeholder="Tanggal lahir"
              onChange={(e) =>
                this.handleInputChange({
                  target: {
                    name: "year",
                    value: moment(e).format("YYYY"),
                  },
                })
              }
              isRequired={true}
              hasError={!this.state.year.isValid}
              errorMessage={this.state.year.message}
              isDisabled={this.props.mode === PageMode.edit}
            />

            <InputField
              label="Jumlah sasaran ibu hamil"
              name="total_target_pregnant"
              type="numberOnly"
              onChange={this.handleInputChange}
              value={total_target_pregnant.value}
              placeholder="0"
              isRequired={true}
              hasError={!this.state.total_target_pregnant.isValid}
              errorMessage={this.state.total_target_pregnant.message}
            />

            <InputField
              label="Jumlah sasaran kunjungan balita sakit"
              name="total_target_toddler"
              type="numberOnly"
              onChange={this.handleInputChange}
              value={total_target_toddler.value}
              placeholder="0"
              isRequired={true}
              hasError={!this.state.total_target_toddler.isValid}
              errorMessage={this.state.total_target_toddler.message}
            />

            <div className="form-group d-flex justify-content-end">
              <Button onClick={() => this.props.changeMode("view")} isSecondary>
                Batalkan
              </Button>
              <Button
                onClick={this.onSave}
                isPrimary
                isDisabled={this.state.isLoading}
              >
                {this.state.isLoading ? "Mohon Tunggu..." : "Simpan Data"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
