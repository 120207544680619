import Dashboard from "./Dashboard";
import CadreMonitoring from "./DataKasus/CadreMonitoring";
import FUP from "./DataKasus/FUP";
import PeCases from "./DataKasus/PeCases";
import PeFocus from "./DataKasus/PeFocus";
import RegmalSecondComponent from "./DataKasus/RegmalSecond";
import Login from "./Login";
import MainApp from "./MainApp";
import User from "./User";
import moment from "moment";
import "moment/locale/id";
import UserVerification from "./UserVerification";

moment.locale("id");

export {
  Dashboard,
  Login,
  FUP,
  CadreMonitoring,
  PeCases,
  PeFocus,
  RegmalSecondComponent,
  MainApp,
  User,
  UserVerification,
};
