export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";

export const ADD_POPULATION = "ADD_POPULATION";
export const EDIT_POPULATION = "EDIT_POPULATION";
export const DELETE_POPULATION = "DELETE_POPULATION";
export const ITEM_POPULATION_TO_EDIT = "ITEM_POPULATION_TO_EDIT";
export const CLEAR_POPULATION = "CLEAR_POPULATION";

export const SET_MODAL_ADD_MODE = "SET_MODAL_ADD_MODE";
export const SET_MODAL_EDIT_MODE = "SET_MODAL_EDIT_MODE";

export const PAGE_MODE_VIEW = "PAGE_MODE_VIEW";
export const PAGE_MODE_ADD = "PAGE_MODE_ADD";
export const PAGE_MODE_EDIT = "PAGE_MODE_EDIT";

export const SHOW_NOTIF = "SHOW_NOTIF";
