import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import GroupMmp from "services/GroupMmp";
import { useSelector } from "react-redux";
import InputDate from "components/atoms/InputDate";
import moment from "moment";
import InputSelect from "components/atoms/InputSelect";
import InputField from "components/atoms/InputField";
import Button from "components/atoms/Button";
import { PageMode } from "utils/constant";
import { useParams } from "react-router-dom";
import MMPCadrePerfomance from "services/MMPCadrePerfomance";
import { toast } from "react-toastify";
import { errorToastNotif } from "utils/common";

const KelompokMMPService = new GroupMmp();
const KinerjaKaderMMPService = new MMPCadrePerfomance();

const FormComponent = ({ selectedItem, handlePageModeChange, pageMode }) => {
  const [groupMmps, setGroupMmps] = useState([]);

  const { currentUser } = useSelector((state) => state.authReducer);

  const { id } = useParams();

  const getAllKelompokMMP = async () => {
    await KelompokMMPService.getAll({
      limit: 9999,
      searchBy: "fasyankes_id",
      searchValue: currentUser?.fasyankes_id || "",
    })
      .then((res) => {
        const group_mmps = res.mmps.map((item) => {
          return {
            value: item.group_mmp_id,
            label: `${item.group_mmp_id} (${
              item.urbanvillage_id?.split("|")[1]
            }/${item.mmp_type}/${item.group_leader_name})`,
          };
        });
        setGroupMmps(group_mmps);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllKelompokMMP();
  }, []);

  const labelWithValue = (value) => ({ label: value, value: value });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      originator_id: selectedItem ? selectedItem.originator_id : "",
      mmp_cadre_id: id,
      year: selectedItem ? selectedItem.year : "",
      month: selectedItem ? labelWithValue(selectedItem.month) : "",
      total_person_responsibility: selectedItem
        ? selectedItem.total_person_responsibility
        : "",
      total_person_in: selectedItem ? selectedItem.total_person_in : "",
      total_person_out: selectedItem ? selectedItem.total_person_out : "",
      total_person_check_in: selectedItem
        ? selectedItem.total_person_check_in
        : "",
      total_person_check_out: selectedItem
        ? selectedItem.total_person_check_out
        : "",
      assignment_place_mmp_id: selectedItem
        ? labelWithValue(selectedItem.assignment_place_mmp_id)
        : "",
      total_group_member_stay: selectedItem
        ? selectedItem.total_group_member_stay
        : "",
    },
    validationSchema: Yup.object({
      year: Yup.string().required("Tahun wajib diisi"),
      month: Yup.object()
        .shape({
          label: Yup.string(),
          value: Yup.string(),
        })
        .required("Bulan wajib diisi"),
      assignment_place_mmp_id: Yup.object()
        .shape({
          label: Yup.string(),
          value: Yup.string(),
        })
        .required("Tempat bertugas wajib diisi"),
      total_person_responsibility: Yup.string().required(
        "Jumlah tanggung jawab kader wajib diisi"
      ),
      total_person_in: Yup.string().required("Jumlah orang masuk wajib diisi"),
      total_person_out: Yup.string().required(
        "Jumlah orang keluar wajib diisi"
      ),
      total_person_check_in: Yup.string().required(
        "Jumlah pemeriksaan pada orang yang masuk wajib diisi"
      ),
      total_person_check_out: Yup.string().required(
        "Jumlah pemeriksaan pada orang yang keluar wajib diisi"
      ),
      total_group_member_stay: Yup.string().required(
        "Jumlah pemeriksaan pada anggota kelompok yang berdiam dilokasi wajib diisi"
      ),
    }),
    onSubmit: async (values) => {
      const payload = {
        originator_id: values.originator_id,
        mmp_cadre_id: values.mmp_cadre_id,
        year: values.year,
        month: values.month.value,
        total_person_responsibility: values.total_person_responsibility,
        total_person_in: values.total_person_in,
        total_person_out: values.total_person_out,
        total_person_check_in: values.total_person_check_in,
        total_person_check_out: values.total_person_check_out,
        total_group_member_stay: values.total_group_member_stay,
        assignment_place_mmp_id: values.assignment_place_mmp_id.value,
      };

      switch (pageMode) {
        case PageMode.add:
          await KinerjaKaderMMPService.add(payload)
            .then((res) => {
              handlePageModeChange(PageMode.view);
              toast.success("Data kinerja kader MMP berhasil ditambahkan", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                theme: "colored",
                draggable: true,
              });
            })
            .catch((err) => {
              console.log(err);
              errorToastNotif(err);
            });
          break;
        case PageMode.edit:
          await KinerjaKaderMMPService.update(payload, selectedItem?.id)
            .then((res) => {
              handlePageModeChange(PageMode.view);
              toast.success("Data kader MMP berhasil di update", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                theme: "colored",
                draggable: true,
              });
            })
            .catch((err) => {
              console.log(err);
              errorToastNotif(err);
            });
          break;
      }
    },
  });

  return (
    <div className="form-grouping">
      <p
        className="warning"
        style={{ marginTop: "24px", marginBottom: "24px" }}
      >
        NOTE<span>*(WAJIB DIISI)</span>
      </p>

      <div className="row">
        <div className="col-sm-6">
          <InputDate
            label={"Tahun"}
            placeholder={"Pilih Tahun"}
            name={"year"}
            dateFormat={"YYYY"}
            onChange={(e) => {
              const selectedDate = moment(e).format("YYYY");
              formik.handleChange("year")(selectedDate);
            }}
            onBlur={formik.handleBlur}
            value={formik.values.year}
            isRequired={true}
            error={formik.errors.year && formik.touched.year}
            errorMessage={formik.errors.year}
          />
        </div>
        <div className="col-sm-6">
          <InputSelect
            label={"Bulan"}
            name={"month"}
            placeholder={"Pilih Bulan"}
            data={[
              { label: "Januari", value: "01" },
              { label: "Februari", value: "02" },
              { label: "Maret", value: "03" },
              { label: "April", value: "04" },
              { label: "Mei", value: "05" },
              { label: "Juni", value: "06" },
              { label: "Juli", value: "07" },
              { label: "Agustus", value: "08" },
              { label: "September", value: "09" },
              { label: "Oktober", value: "10" },
              { label: "November", value: "11" },
              { label: "Desember", value: "12" },
            ]}
            onChange={(selectedValue) => {
              formik.setFieldValue("month", selectedValue);
            }}
            onBlur={formik.handleBlur}
            value={formik.values.month}
            error={formik.errors.month && formik.touched.month}
            errorMessage={formik.errors.month}
            isRequired={true}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <InputSelect
            label={"ID MMP Tempat Bertugas"}
            name={"assignment_place_mmp_id"}
            placeholder={"Pilih ID MMP"}
            data={groupMmps}
            onChange={(selectedValue) => {
              formik.setFieldValue("assignment_place_mmp_id", selectedValue);
            }}
            onBlur={formik.handleBlur}
            value={formik.values.assignment_place_mmp_id}
            error={
              formik.errors.assignment_place_mmp_id &&
              formik.touched.assignment_place_mmp_id
            }
            errorMessage={formik.errors.assignment_place_mmp_id}
            isRequired={true}
          />
        </div>
        <div className="col-sm-6">
          <InputField
            label={"Jumlah Orang Yang Menjadi Tanggung Jawab Kader"}
            name={"total_person_responsibility"}
            placeholder={
              "Masukkan Jumlah Orang Yang Menjadi Tanggung Jawab Kader"
            }
            maxLengthVal={6}
            {...formik.getFieldProps("total_person_responsibility")}
            error={
              formik.errors.total_person_responsibility &&
              formik.errors.total_person_responsibility
            }
            errorMessage={formik.errors.total_person_responsibility}
            isRequired={true}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <InputField
            label={"Jumlah Orang Yang Masuk"}
            name={"total_person_in"}
            placeholder={"Masukkan Jumlah Orang Yang Masuk"}
            maxLengthVal={6}
            {...formik.getFieldProps("total_person_in")}
            error={
              formik.errors.total_person_in && formik.errors.total_person_in
            }
            errorMessage={formik.errors.total_person_in}
            isRequired={true}
          />
        </div>
        <div className="col-sm-6">
          <InputField
            label={"Jumlah Orang Yang Keluar"}
            name={"total_person_out"}
            placeholder={"Masukkan Jumlah Orang Yang Keluar"}
            maxLengthVal={6}
            {...formik.getFieldProps("total_person_out")}
            error={
              formik.errors.total_person_out && formik.errors.total_person_out
            }
            errorMessage={formik.errors.total_person_out}
            isRequired={true}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <InputField
            label={"Jumlah Pemeriksaan pada orang yang baru masuk"}
            name={"total_person_check_in"}
            placeholder={"Masukkan Jumlah"}
            maxLengthVal={6}
            {...formik.getFieldProps("total_person_check_in")}
            error={
              formik.errors.total_person_check_in &&
              formik.errors.total_person_check_in
            }
            errorMessage={formik.errors.total_person_check_in}
            isRequired={true}
          />
        </div>
        <div className="col-sm-6">
          <InputField
            label={"Jumlah Pemeriksaan pada orang yang baru keluar"}
            name={"total_person_check_out"}
            placeholder={"Masukkan Jumlah"}
            maxLengthVal={6}
            {...formik.getFieldProps("total_person_check_out")}
            error={
              formik.errors.total_person_check_out &&
              formik.errors.total_person_check_out
            }
            errorMessage={formik.errors.total_person_check_out}
            isRequired={true}
          />
        </div>
      </div>
      <InputField
        label={
          "Jumlah Pemeriksaan pada anggota kelompok yang berdiam di lokasi"
        }
        name={"total_group_member_stay"}
        placeholder={"Masukkan Jumlah"}
        maxLengthVal={6}
        {...formik.getFieldProps("total_group_member_stay")}
        error={
          formik.errors.total_group_member_stay &&
          formik.errors.total_group_member_stay
        }
        errorMessage={formik.errors.total_group_member_stay}
        isRequired={true}
      />

      <div className="button_action">
        <Button
          variant={"tertiary"}
          size={"normal"}
          onClick={() => handlePageModeChange(PageMode.view)}
        >
          Batalkan
        </Button>
        <Button
          variant={"primary"}
          size={"normal"}
          onClick={formik.handleSubmit}
        >
          Simpan Data
        </Button>
      </div>
    </div>
  );
};

export default FormComponent;
