import React, { Component } from "react";
import { PageMode } from "../../../../../utils/constant";
import { InputField, InputSelect, Button } from "../../../../../components";
import moment from "moment";
import InputDateTime from "components/InputDateTime";

export default class Investigations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isValid: false,
      errors: {},
    };
  }

  submitInvestigation = (e) => {
    e.preventDefault();
    const { contact_survey, identify_mosquito_breeding_area } =
      this.props.values;

    let isValid = true;

    if (
      !this.validationHandler(
        "case_interview_date",
        "Tanggal wawancara kasus wajib dipilih"
      )
    ) {
      isValid = false;
    }
    if (
      !this.validationHandler("contact_survey", "Survey kontak wajib dipilih")
    ) {
      isValid = false;
    }
    if (contact_survey.value === "Ya") {
      if (
        !this.validationHandler(
          "contact_survey_date",
          "Tanggal survei kontak wajib dipilih"
        )
      ) {
        isValid = false;
      }
    }
    if (
      !this.validationHandler(
        "identify_mosquito_breeding_area",
        "Identifikasi daerah perindukan nyamuk wajib dipilih"
      )
    ) {
      isValid = false;
    }
    if (identify_mosquito_breeding_area.value === "Ya") {
      if (
        !this.validationHandler(
          "identify_mosquito_breeding_area_date",
          "Tanggal identifikasi area pemeliharaan wajib dipilih"
        )
      ) {
        isValid = false;
      }
    }

    if (isValid) {
      this.props.nextStep();
    }
  };

  validationHandler = (name, errorMsg) => {
    const { parentStateChanger, values } = this.props;
    let isValid = true;
    if (
      values[name].value === "" ||
      values[name].value === null ||
      values[name].value === undefined
    ) {
      parentStateChanger(name, {
        value: "",
        isValid: false,
        message: `${errorMsg}`,
      });
      isValid = false;
    }
    return isValid;
  };

  render() {
    const {
      values,
      handleInputChange,
      changeMode,
      dataRegmal,
      parentStateChanger,
    } = this.props;

    return (
      <>
        <div className="form-wrapper">
          <div style={{ paddingLeft: "10px" }}>
            <div className="row">
              <div className="col-md-6">
                <table className="table">
                  <tr>
                    <td>NIK</td>
                    <td>:</td>
                    <td>
                      <b>{dataRegmal && dataRegmal.ktp}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Nama</td>
                    <td>:</td>
                    <td>
                      <b>{dataRegmal && dataRegmal.name}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Tanggal Kunjungan</td>
                    <td>:</td>
                    <td>
                      <b>
                        {dataRegmal &&
                          moment(dataRegmal.patient_visite_date).format(
                            "YYYY-MM-DD"
                          )}
                      </b>
                    </td>
                  </tr>
                  <tr>
                    <td>Faskes Pencatat</td>
                    <td>:</td>
                    <td>
                      <b>
                        {dataRegmal &&
                          dataRegmal.fasyankes_origin_id?.split("|")[1]}
                      </b>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>

          <div
            className="font-weight-bold mt-2"
            style={{ paddingLeft: "10px" }}
          >
            Penyelidikan Epidemiologi
          </div>

          <InputDateTime
            label="Tanggal wawancara kasus"
            name="case_interview_date"
            dateFormat="YYYY-MM-DD"
            placeholder="Pilih Tanggal Wawancara Kasus"
            onChange={(e) =>
              handleInputChange({
                preventDefault: () => {},
                target: {
                  name: "case_interview_date",
                  value: moment(e).format("YYYY-MM-DD"),
                },
              })
            }
            isValidDate={(current) => {
              const date = moment(dataRegmal.patient_visite_date);
              return current >= date;
            }}
            closeOnClickOutside={true}
            value={values.case_interview_date.value}
            isInline={true}
            hasError={!values.case_interview_date.isValid}
            errorMessage={values.case_interview_date.message}
            isRequired={true}
          />

          <InputSelect
            isDisabled={values.case_interview_date.value === ""}
            label="Survei Kontak"
            name="contact_survey"
            onChange={(e) => {
              const { value } = e.target;
              if (value === "") {
                parentStateChanger("contact_survey", {
                  value: "",
                  isValid: false,
                  message: "Survei kontak wajib dipilih",
                });
              } else {
                handleInputChange(e);
              }
              if (value === "Tidak") {
                parentStateChanger("contact_survey_date", {
                  value: "",
                  isValid: true,
                  message: "",
                });
              }
            }}
            value={values.contact_survey.value}
            placeholder="Pilih"
            data={[
              { value: "Ya", label: "Ya" },
              { value: "Tidak", label: "Tidak" },
            ]}
            isRequired={true}
            hasError={!values.contact_survey.isValid}
            errorMessage={values.contact_survey.message}
          />

          {values.contact_survey.value === "Ya" && (
            <InputDateTime
              label="Tanggal Survey Kontak"
              name="contact_survey_date"
              dateFormat="YYYY-MM-DD"
              timeFormat={false}
              placeholder="Pilih Tanggal Survey Kontak"
              onChange={(e) =>
                handleInputChange({
                  preventDefault: () => {},
                  target: {
                    name: "contact_survey_date",
                    value: moment(e).format("YYYY-MM-DD"),
                  },
                })
              }
              isValidDate={(current) => {
                const date = moment(values.case_interview_date.value);
                return current >= date;
              }}
              closeOnClickOutside={true}
              value={values.contact_survey_date.value}
              isInline={true}
              hasError={!values.contact_survey_date.isValid}
              errorMessage={values.contact_survey_date.message}
              isRequired={true}
            />
          )}

          {values.contact_survey.value === "Ya" && (
            <>
              <div
                className="font-weight-bold mt-2"
                style={{ paddingLeft: "10px" }}
              >
                Hasil pemeriksaan negatif dari survei kontak
              </div>
              <div
                className="font-weight-bold mt-2"
                style={{ paddingLeft: "10px" }}
              >
                Usia {"<"} 5 Tahun
              </div>
              <InputField
                label="Jumlah pemeriksaan mikroskopis (negatif)"
                name="total_microscopic_inspection_under_five_year"
                type="numberOnly"
                placeholder="0"
                onChange={handleInputChange}
                value={
                  values.total_microscopic_inspection_under_five_year.value
                }
              />
              <InputField
                label="Jumlah pemeriksaan RDT (negatif)*"
                name="total_rdt_inspection_under_five_year"
                type="numberOnly"
                placeholder="0"
                onChange={handleInputChange}
                value={values.total_rdt_inspection_under_five_year.value}
              />
              <div
                className="font-weight-bold mt-2"
                style={{ paddingLeft: "10px" }}
              >
                Usia {">="} 5 Tahun
              </div>
              <InputField
                label="Jumlah pemeriksaan mikroskopis (negatif)"
                name="total_microscopic_inspection_over_five_year"
                type="numberOnly"
                placeholder="0"
                onChange={handleInputChange}
                value={values.total_microscopic_inspection_over_five_year.value}
              />
              <InputField
                label="Jumlah pemeriksaan RDT (negatif)*"
                name="total_rdt_inspection_over_five_year"
                type="numberOnly"
                placeholder="0"
                onChange={handleInputChange}
                value={values.total_rdt_inspection_over_five_year.value}
              />
            </>
          )}

          <InputSelect
            label="Identifikasi daerah perindukan nyamuk"
            name="identify_mosquito_breeding_area"
            onChange={(e) => {
              const { value } = e.target;
              if (e.target.value === "") {
                parentStateChanger("identify_mosquito_breeding_area", {
                  value: "",
                  isValid: false,
                  message:
                    "Identifikasi daerah perindukan nyamuk wajib dipilih",
                });
              } else {
                handleInputChange(e);
              }
              if (value === "Tidak") {
                parentStateChanger("identify_mosquito_breeding_area_date", {
                  value: "",
                  isValid: true,
                  message: "",
                });
              }
            }}
            value={values.identify_mosquito_breeding_area.value}
            placeholder="Pilih"
            data={[
              { value: "Ya", label: "Ya" },
              { value: "Tidak", label: "Tidak" },
            ]}
            isRequired={true}
            hasError={!values.identify_mosquito_breeding_area.isValid}
            errorMessage={values.identify_mosquito_breeding_area.message}
          />

          {values.identify_mosquito_breeding_area.value === "Ya" && (
            <InputDateTime
              label="Tanggal indentifikasi daerah perindukan nyamuk"
              name="identify_mosquito_breeding_area_date"
              dateFormat="YYYY-MM-DD"
              timeFormat={false}
              placeholder="Pilih Tanggal"
              onChange={(e) =>
                handleInputChange({
                  preventDefault: () => {},
                  target: {
                    name: "identify_mosquito_breeding_area_date",
                    value: moment(e).format("YYYY-MM-DD"),
                  },
                })
              }
              closeOnClickOutside={true}
              value={values.identify_mosquito_breeding_area_date.value}
              isInline={true}
              hasError={!values.identify_mosquito_breeding_area_date.isValid}
              errorMessage={values.identify_mosquito_breeding_area_date.message}
              isRequired={true}
            />
          )}

          <div className="form-group d-flex justify-content-end">
            <Button onClick={() => changeMode(PageMode.view)} isSecondary>
              Batalkan
            </Button>
            <Button onClick={this.submitInvestigation} isPrimary>
              Lanjut
            </Button>
          </div>
        </div>
      </>
    );
  }
}
