import React from "react";
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";

const SideNavMenuComponent = ({ path, textMenu, icon }) => {
  const location = useLocation();
  const isActive = location.pathname === path;

  return (
    <li className={`nav-item ${isActive ? "active" : ""}`}>
      <Link to={path}>
        {icon}
        <p className="ml-2">{textMenu}</p>
      </Link>
    </li>
  );
};

export default SideNavMenuComponent;

SideNavMenuComponent.propTypes = {
  path: PropTypes.string.isRequired,
  textMenu: PropTypes.string.isRequired,
  icon: PropTypes.node,
};
