import React, { Component } from "react";
import { PageMode, UserLevel } from "utils/constant";
import Reporter from "services/Reporter";
import { Button, InputField } from "components";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import InputDateTime from "components/InputDateTime";
import moment from "moment";

const ReporterService = new Reporter();

class Form extends Component {
  _isMounted = true;

  constructor(props) {
    super(props);
    this.defaultValue = {
      province_id: {
        value: this.props.user.province_id,
        isValid: true,
        message: "",
      },
      district_id: {
        value: this.props.user.district_id,
        isValid: true,
        message: "",
      },
      subdistrict_id: {
        value: this.props.user.subdistrict_id,
        isValid: true,
        message: "",
      },
      fasyankes_id: {
        value: this.props.user.fasyankes_id,
        isValid: true,
        message: "",
      },
      year: { value: "", isValid: true, message: "" },
      operator_name: { value: "", isValid: true, message: "" },
      phone_number: { value: "", isValid: true, message: "" },
      email: { value: "", isValid: true, message: "" },

      isLoading: false,
    };
    this.state = Object.assign({}, this.defaultValue);
  }

  componentDidMount() {
    this._isMounted = true;
    let obj = Object.assign({}, this.state);
    if (this.props.mode === PageMode.edit) {
      const { data } = this.props;
      obj.province_id.value = data.province_id;
      obj.district_id.value = data.district_id;
      obj.subdistrict_id.value = data.subdistrict_id;
      obj.fasyankes_id.value = data.fasyankes_id;
      obj.year.value = data.year;
      obj.operator_name.value = data.operator_name;
      obj.phone_number.value = data.phone_number;
      obj.email.value = data.email;
    }
    this.setState(obj);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    let obj = Object.assign({}, this.state[name]);
    obj.value = value;

    this.setState({
      [name]: obj,
    });
  };

  erorrNotif = (status, res) => {
    let msg = "";
    switch (status) {
      case 403:
        if (res.message === "Access Denied: User Not Verified") {
          msg = "Akun anda belum diverifikasi, silahkan hubungi admin";
        } else {
          msg = res.message;
        }
        break;
      case 409:
        msg = "Data sudah ada ditahun yang sama";
        break;
      default:
        msg = res.message;
    }
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  onSave = async (e) => {
    e.preventDefault();
    const isValid = this.validationHandler();
    if (isValid) {
      this.setState({
        isLoading: true,
      });

      const { user } = this.props;
      let payload = {
        province_id: this.state.province_id.value,
        district_id: this.state.district_id.value,
        subdistrict_id: this.state.subdistrict_id.value,
        fasyankes_id: this.state.fasyankes_id.value,
        year: this.state.year.value,
        operator_name: this.state.operator_name.value,
        phone_number: this.state.phone_number.value,
        email: this.state.email.value,
        level: user.level,
      };

      switch (this.props.mode) {
        case PageMode.add:
          await ReporterService.add(payload)
            .then((res) => {
              this.setState({
                isLoading: false,
              });

              this.setState(this.defaultValue, () => {
                this.props.changeMode("view");
              });
              toast.success("Data Pelapor berhasil ditambahkan", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            })
            .catch((err) => {
              this.setState({
                isLoading: false,
              });

              console.log(err);
              this.erorrNotif(err.response.status, err.response.data);
            });
          break;
        case PageMode.edit:
          await ReporterService.update(payload, this.props.data.id)
            .then((res) => {
              this.setState({
                isLoading: false,
              });

              this.setState(this.defaultValue, () => {
                this.props.changeMode("view");
              });
              toast.success("Data Pelapor berhasil diperbarui", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            })
            .catch((err) => {
              this.setState({
                isLoading: false,
              });

              console.log(err);
            });
          break;
      }
    }
  };

  validationHandler = () => {
    const { year, operator_name, phone_number, email } = this.state;

    let isValid = true;

    if (!year.value) {
      year.isValid = false;
      year.message = "Tahun harus diisi";
      isValid = false;
    }

    if (!operator_name.value) {
      operator_name.isValid = false;
      operator_name.message = "Nama operator harus diisi";
      isValid = false;
    }

    if (!phone_number.value) {
      phone_number.isValid = false;
      phone_number.message = "Nomor telepon operator harus diisi";
      isValid = false;
    }

    if (!email.value) {
      email.isValid = false;
      email.message = "Email harus diisi";
      isValid = false;
    }

    this.setState({
      year,
      operator_name,
      phone_number,
      email,
    });

    return isValid;
  };

  render() {
    const { user, mode } = this.props;
    return (
      <div className="form-wrapper">
        <div className="row">
          <div className="col-sm-6">
            <table className="table">
              <tr>
                <th>PROVINSI</th>
                <th>:</th>
                <td>{user.province_id?.split("|")[1]}</td>
              </tr>
              {user.level === UserLevel.District && (
                <tr>
                  <th>KABUPATEN</th>
                  <th>:</th>
                  <td>{user.district_id?.split("|")[1]}</td>
                </tr>
              )}
              {user.level === UserLevel.Fasyankes && (
                <>
                  <tr>
                    <th>KECAMATAN</th>
                    <th>:</th>
                    <td>
                      {(user.subdistrict_id &&
                        user.subdistrict_id?.split("|")[1]) ||
                        ""}
                    </td>
                  </tr>
                  <tr>
                    <th>NAMA FASKES</th>
                    <th>:</th>
                    <td>
                      {(user.fasyankes_id && user.fasyankes_id?.split("|")[1]) ||
                        ""}
                    </td>
                  </tr>
                </>
              )}
            </table>
          </div>
        </div>

        <p className="font-weight-bold d-flex justify-content-start">
          NOTE <span className="text-danger">*(WAJIB DIISI)</span>
        </p>

        <InputDateTime
          label="Tahun"
          name="year"
          dateFormat="YYYY"
          placeholder="Tahun"
          value={this.state.year.value}
          onChange={(e) =>
            this.handleInputChange({
              target: {
                name: "year",
                isValid: true,
                value: moment(e).format("YYYY"),
              },
            })
          }
          isRequired={true}
          hasError={!this.state.year.isValid}
          errorMessage={this.state.year.message}
          isDisabled={mode === PageMode.edit ? true : false}
        />

        <InputField
          type="text"
          label="Nama Petugas"
          name="operator_name"
          placeholder="Nama Petugas"
          onChange={this.handleInputChange}
          value={this.state.operator_name.value}
          isRequired={true}
          hasError={!this.state.operator_name.isValid}
          errorMessage={this.state.operator_name.message}
        />

        <InputField
          type="numberOnly"
          label="Nomor Telepon"
          name="phone_number"
          placeholder="Nomor Telepon Petugas"
          onChange={this.handleInputChange}
          value={this.state.phone_number.value}
          isRequired={true}
          maxLength={16}
          hasError={!this.state.phone_number.isValid}
          errorMessage={this.state.phone_number.message}
        />

        <InputField
          type="text"
          label="Email"
          name="email"
          placeholder="Email Petugas"
          onChange={this.handleInputChange}
          value={this.state.email.value}
          isRequired={true}
          hasError={!this.state.email.isValid}
          errorMessage={this.state.email.message}
        />

        <div className="form-group d-flex justify-content-end">
          <Button onClick={() => this.props.changeMode("view")} isSecondary>
            Batalkan
          </Button>
          <Button
            onClick={this.onSave}
            isPrimary
            disabled={this.state.disabledSubmit}
          >
            {this.state.isLoading ? "Mohon Tunggu..." : "Simpan Data"}
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authReducer.currentUser,
  };
};

export default connect(mapStateToProps)(Form);
