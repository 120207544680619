import classNames from "classnames";
import React from "react";
import { monthConverter } from "utils/common";
import {
  CURRENT_USER_KEY,
  SpeciesMosquitoOptions,
  UserLevel,
} from "utils/constant";

const TableComponent = ({
  currentLevel,
  reportsData,
  tableRef,
  reportInfo,
}) => {
  // const calculateTotal = () => {
  //   if (
  //     currentLevel === UserLevel.National.toLowerCase() ||
  //     currentLevel === UserLevel.Province.toLocaleLowerCase()
  //   ) {
  //     return reportsData.reduce(
  //       (total, item) => {
  //         total.total_faskes += parseInt(item.total_faskes) || 0;
  //         total.total_faskes_zero_report +=
  //           parseInt(item.total_faskes_zero_report) || 0;

  //         return total;
  //       },
  //       {
  //         total_faskes: 0,
  //         total_faskes_zero_report: 0,
  //       }
  //     );
  //   } else {
  //     return reportsData.reduce(
  //       (total, item) =>
  //         total + (item.is_zero_reporting?.toLowerCase() === "ya" ? 1 : 0),
  //       0
  //     );
  //   }
  // };

  let levelName = "";
  if (currentLevel === "national") {
    levelName = "Nasional";
  } else if (currentLevel === "province") {
    levelName = "Provinsi";
  } else if (currentLevel === "district") {
    levelName = "Kabupaten/Kota";
  } else {
    levelName = "Fasyankes";
  }

  let periodeName = "";
  if (reportInfo?.statusPeriode === "yearly") {
    periodeName = "Tahunan";
  } else {
    periodeName = "Bulanan";
  }

  let data = reportInfo?.fasyankes;
  let parts = data?.split(",");
  let faskesName = parts?.map(function (part) {
    return part?.split("|")[1];
  });
  let result = faskesName?.join(", ");

  const renderTableByLevelUser = () => {
    switch (currentLevel) {
      case UserLevel.National.toLowerCase():
        return (
          <>
            <table>
              <thead>
                <tr>
                  <th rowSpan={2}>No.</th>
                  <th rowSpan={2}>Provinsi</th>
                  <th rowSpan={2}>Kabupaten/Kota</th>
                  <th rowSpan={2}>Jumlah Faskes</th>
                  <th rowSpan={2}>Jumlah Desa</th>
                  <th colSpan={2}>Jenis Survey</th>
                  <th rowSpan={2}>Jumlah Faskes Ditemukan Nyamuk Anopheles</th>
                  <th colSpan={SpeciesMosquitoOptions.length}>
                    Jumlah Species Nyamuk Yang Ditemukan
                  </th>
                </tr>
                <tr>
                  <th>Jumlah Tempat Yang Dilakukan Spot Survei</th>
                  <th>Jumlah Tempat Yang Dilakukan Longitudinal</th>
                  {SpeciesMosquitoOptions.map((item, index) => (
                    <th key={index}>{item.label}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {reportsData?.length > 0 ? (
                  reportsData?.map((item, index) => (
                    <tr key={item.id}>
                      <td>{index + 1}</td>
                      <td>{item?.province}</td>
                      <td>{item?.district}</td>
                      <td>{item?.jml_fasyankes}</td>
                      <td>{item?.jml_desa}</td>
                      <td>{item?.jml_spot_survey}</td>
                      <td>{item?.jml_longitudinal}</td>
                      <td>{item?.total_anopheles_ya}</td>
                      {SpeciesMosquitoOptions.map((row, index) => (
                        <td key={index}>{item[row.variable]}</td>
                      ))}
                    </tr>
                  ))
                ) : (
                  <div className="alert alert-danger">Tidak ada data</div>
                )}
              </tbody>
            </table>
          </>
        );
      case UserLevel.Province.toLowerCase():
        return (
          <>
            <table>
              <thead>
                <tr>
                  <th rowSpan={2}>No.</th>
                  <th rowSpan={2}>Kabupaten/Kota</th>
                  <th rowSpan={2}>Jumlah Faskes</th>
                  <th rowSpan={2}>Jumlah Desa</th>
                  <th colSpan={2}>Jenis Survey</th>
                  <th rowSpan={2}>Jumlah Faskes Ditemukan Nyamuk Anopheles</th>
                  <th colSpan={SpeciesMosquitoOptions.length}>
                    Jumlah Species Nyamuk Yang Ditemukan
                  </th>
                </tr>
                <tr>
                  <th>Jumlah Tempat Yang Dilakukan Spot Survei</th>
                  <th>Jumlah Tempat Yang Dilakukan Longitudinal</th>
                  {SpeciesMosquitoOptions.map((item, index) => (
                    <th key={index}>{item.label}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {reportsData?.length > 0 ? (
                  reportsData?.map((item, index) => (
                    <tr key={item.id}>
                      <td>{index + 1}</td>
                      <td>{item?.district}</td>
                      <td>{item?.jml_fasyankes}</td>
                      <td>{item?.jml_desa}</td>
                      <td>{item?.jml_spot_survey}</td>
                      <td>{item?.jml_longitudinal}</td>
                      <td>{item?.total_anopheles_ya}</td>
                      {SpeciesMosquitoOptions.map((row, index) => (
                        <td key={index}>{item[row.variable]}</td>
                      ))}
                    </tr>
                  ))
                ) : (
                  <div className="alert alert-danger">Tidak ada data</div>
                )}
              </tbody>
            </table>
          </>
        );
      case UserLevel.District.toLowerCase():
        return (
          <>
            <table>
              <thead>
                <tr>
                  <th rowSpan={2}>No.</th>
                  <th rowSpan={2}>Fasankes</th>
                  <th rowSpan={2}>Jumlah Desa</th>
                  <th colSpan={2}>Jenis Survey</th>
                  <th rowSpan={2}>Jumlah Faskes Ditemukan Nyamuk Anopheles</th>
                  <th colSpan={SpeciesMosquitoOptions.length}>
                    Jumlah Species Nyamuk Yang Ditemukan
                  </th>
                </tr>
                <tr>
                  <th>Jumlah Tempat Yang Dilakukan Spot Survei</th>
                  <th>Jumlah Tempat Yang Dilakukan Longitudinal</th>
                  {SpeciesMosquitoOptions.map((item, index) => (
                    <th key={index}>{item.label}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {reportsData?.length > 0 ? (
                  reportsData?.map((item, index) => (
                    <tr key={item.id}>
                      <td>{index + 1}</td>
                      <td>{item?.fasyankes}</td>
                      <td>{item?.jml_desa}</td>
                      <td>{item?.jml_spot_survey}</td>
                      <td>{item?.jml_longitudinal}</td>
                      <td>{item?.total_anopheles_ya}</td>
                      {SpeciesMosquitoOptions.map((row, index) => (
                        <td key={index}>{item[row.variable]}</td>
                      ))}
                    </tr>
                  ))
                ) : (
                  <div className="alert alert-danger">Tidak ada data</div>
                )}
              </tbody>
            </table>
          </>
        );
      default:
        return (
          <>
            <table>
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Nama Desa</th>
                  <th>Nama Dusun</th>
                  <th>Lokasi</th>
                  <th>Jenis Survey</th>
                  <th>Ditemukan Nyamuk Anopheles (Ya/Tidak)</th>
                  <th>Spesies Nyamuk Yang Ditemukan</th>
                  <th>MBR Dalam Rumah</th>
                  <th>MBR Luar Rumah</th>
                  <th>MBR Dalam Kandang Atau Kebun</th>
                </tr>
              </thead>
              <tbody>
                {reportsData.length > 0 ? (
                  reportsData?.map((item, index) => (
                    <tr key={item?.id}>
                      <td>{index + 1}</td>
                      <td>{item?.desa}</td>
                      <td>{item?.dusun}</td>
                      <td>{item?.lokasi}</td>
                      <td>{item?.jenis_survey}</td>
                      <td>{item?.penemuan_jentik_anopheles}</td>
                      <td>{item?.mbr_dalam_rumah}</td>
                      <td>{item?.mbr_luar_rumah}</td>
                      <td>{item?.mbr_dalam_kandang}</td>
                    </tr>
                  ))
                ) : (
                  <div className="alert alert-danger">Tidak ada data</div>
                )}
              </tbody>
            </table>
          </>
        );
    }
  };

  return (
    <div
      className="table-table-responsive"
      style={{ height: "560px", overflow: "scroll" }}
      ref={tableRef}
    >
      <table></table>
      <tr style={{ border: "none", padding: 0 }}>
        <th style={{ border: "none", paddingBottom: "0" }}>
          <h3>SISMAL v3, IDENTIFIKASI NYAMUK</h3>
        </th>
      </tr>
      <table>
        <tr style={{ border: "none" }}>
          <th
            width="200"
            style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}
          >
            Periode Laporan
          </th>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            : {periodeName}
          </th>
        </tr>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            Tahun
          </th>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            : {reportInfo?.periode?.year}
          </th>
        </tr>
        {reportInfo?.statusPeriode === "monthly" && (
          <tr style={{ border: "none" }}>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              Bulan
            </th>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              : {monthConverter(reportInfo?.periode?.month)}
            </th>
          </tr>
        )}
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            Level
          </th>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            : {levelName}
          </th>
        </tr>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            Provinsi
          </th>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            :{" "}
            {!reportInfo?.province
              ? "Semua Provinsi"
              : reportInfo?.province?.split("|")[1]}
          </th>
        </tr>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            Kab/Kota
          </th>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            :{" "}
            {!reportInfo?.district
              ? "Semua Kab/Kota"
              : reportInfo?.district?.split("|")[1]}
          </th>
        </tr>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingTop: 0 }}>Fasyankes</th>
          <th style={{ border: "none", paddingTop: 0 }}>
            : {!reportInfo?.fasyankes ? "Semua Fasyankes" : result}
          </th>
        </tr>
      </table>
      {renderTableByLevelUser()}
    </div>
  );
};

export default TableComponent;
