import React from "react";
import { monthConverter } from "utils/common";
import { UserLevel } from "utils/constant";

const TableComponent = ({
  reportsData,
  tableRef,
  currentLevel,
  reportInfo,
}) => {
  const calculateTotal = (province = "") => {
    const totals =
     typeof(province) !== "undefined" && province && province?.length > 0 
        ? reportsData.filter((item) => item.province_id === province)
        : reportsData;
    return totals.reduce(
      (total, item) => {
        total.dhp += parseInt(item.dhp ?? 0);
        total.dhp_dispersible += parseInt(item.dhp_dispersible ?? 0);
        total.prima += parseInt(item.prima ?? 0);
        total.arteinj += parseInt(item.arteinj ?? 0);
        total.kina += parseInt(item.kina ?? 0);
        total.kinainj += parseInt(item.kinainj ?? 0);
        total.doksi += parseInt(item.doksi ?? 0);
        total.rdt += parseInt(item.rdt ?? 0);
        total.kelambu += parseInt(item.kelambu ?? 0);
        total.inseckg += parseInt(item.inseckg ?? 0);
        total.inseclt += parseInt(item.inseclt ?? 0);
        total.larvakg += parseInt(item.larvakg ?? 0);
        total.larvalt += parseInt(item.larvalt ?? 0);
        total.labkit += parseInt(item.labkit ?? 0);
        total.giemsacc += parseInt(item.giemsacc ?? 0);
        total.imersicc += parseInt(item.imersicc ?? 0);
        total.bloodslide += parseInt(item.bloodslide ?? 0);
        total.slidebox += parseInt(item.slidebox ?? 0);
        total.bloodlancet += parseInt(item.bloodlancet ?? 0);
        total.metanolcc += parseInt(item.metanolcc ?? 0);
        total.spraycan += parseInt(item.spraycan ?? 0);
        total.mistblow += parseInt(item.mistblow ?? 0);
        total.micbino += parseInt(item.micbino ?? 0);
        total.mictri += parseInt(item.mictri ?? 0);
        total.micteac += parseInt(item.micteac ?? 0);

        return total;
      },
      {
        dhp: 0,
        dhp_dispersible: 0,
        prima: 0,
        arteinj: 0,
        kina: 0,
        kinainj: 0,
        doksi: 0,
        rdt: 0,
        kelambu: 0,
        inseckg: 0,
        inseclt: 0,
        larvakg: 0,
        larvalt: 0,
        labkit: 0,
        giemsacc: 0,
        imersicc: 0,
        bloodslide: 0,
        slidebox: 0,
        bloodlancet: 0,
        metanolcc: 0,
        spraycan: 0,
        mistblow: 0,
        micbino: 0,
        mictri: 0,
        micteac: 0,
      }
    );
  };

  const total = (province='') => calculateTotal(province);

  let levelName = "";
  if (currentLevel === "national") {
    levelName = "Nasional";
  } else if (currentLevel === "province") {
    levelName = "Provinsi";
  } else if (currentLevel === "district") {
    levelName = "Kabupaten/Kota";
  } else {
    levelName = "Fasyankes";
  }

  let periodeName = "";

  if (reportInfo?.statusPeriode === "yearly") {
    periodeName = "Tahunan";
  } else {
    periodeName = "Bulanan";
  }

  let data = reportInfo?.fasyankes;
  let parts = data?.split(",");
  let faskesName = parts?.map(function (part) {
    return part?.split("|")[1];
  });
  let result = faskesName?.join(", ");

  return (
    <div
      className="table-table-responsive"
      style={{ height: "560px", overflow: "scroll" }}
      ref={tableRef}
    >
      <table>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none" }}>
            <h3>SISMAL v3, LOGISTIK STOK</h3>
          </th>
        </tr>
      </table>
      <table>
        <thead>
          <tr style={{ border: "none" }}>
            <th
              width="200"
              style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}
            >
              Periode Laporan
            </th>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              : {periodeName}
            </th>
          </tr>
          <tr style={{ border: "none" }}>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              Tahun
            </th>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              : {reportInfo?.periode?.year}
            </th>
          </tr>
          <tr style={{ border: "none" }}>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              Bulan
            </th>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              : {monthConverter(reportInfo?.periode?.month)}
            </th>
          </tr>
          <tr style={{ border: "none" }}>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              Level
            </th>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              : {levelName}
            </th>
          </tr>
          <tr style={{ border: "none" }}>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              Provinsi
            </th>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              :{" "}
              {!reportInfo?.province
                ? "Semua Provinsi"
                : reportInfo?.province?.split("|")[1]}
            </th>
          </tr>
          <tr style={{ border: "none" }}>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              Kab/Kota
            </th>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              :{" "}
              {!reportInfo?.district
                ? "Semua Kab/Kota"
                : reportInfo?.district?.split("|")[1]}
            </th>
          </tr>
          <tr style={{ border: "none" }}>
            <th style={{ border: "none", paddingTop: 0 }}>Fasyankes</th>
            <th style={{ border: "none", paddingTop: 0 }}>
              : {!reportInfo?.fasyankes ? "Semua Fasyankes" : result}
            </th>
          </tr>
        </thead>
      </table>
      <table>
        <thead>
          <tr>
            <th rowSpan={2}>No.</th>
            {currentLevel === UserLevel.National.toLowerCase() ? (
              <th colSpan={2}>Area</th>
            ) : (
              <th rowSpan={2}>Area</th>
            )}
            <th colSpan={3}>DHP (Tablet)</th>
            <th colSpan={3}>DHP Dispersible</th>
            <th colSpan={3}>Primaquin</th>
            <th colSpan={3}>Artesunat Injeksi</th>
            <th colSpan={3}>Kina Tablet</th>
            <th colSpan={3}>Kina Injeksi</th>
            <th colSpan={3}>Doksisiklin</th>
            <th colSpan={3}>RDT</th>
            <th colSpan={3}>LLIN</th>
            <th colSpan={3}>Insektisida (Kg)</th>
            <th colSpan={3}>Insektisida (Lt)</th>
            <th colSpan={3}>Larvasida (Kg)</th>
            <th colSpan={3}>Larvaside (Lt)</th>
            <th colSpan={3}>Labkit (Paket)</th>
            <th colSpan={3}>Giemsa (CC)</th>
            <th colSpan={3}>Minyak Imersi (CC)</th>
            <th colSpan={3}>Methanol (CC)</th>
            <th colSpan={3}>Blood Slide (Box)</th>
            <th colSpan={3}>Slide Box (Buah)</th>
            <th colSpan={3}>Blood Lancet (Box)</th>
            <th colSpan={3}>Spray Can (Buah)</th>
            <th colSpan={3}>Mist Blower (Buah)</th>
            <th colSpan={3}>Microscope Binocular (Buah)</th>
            <th colSpan={3}>Microscope Trinocular (Buah)</th>
            <th colSpan={3}>Microscope Teaching (Buah)</th>
          </tr>
          <tr>
            {currentLevel === UserLevel.National.toLowerCase() && (
              <>
                <th>Provinsi</th>
                <th>Kabupaten</th>
              </>
            )}
            {/* DHP */}
            <th>Stok</th>
            <th>Tanggal ED Terdekat</th>
            <th>Nomor Batch & Sumber Pendanaan</th>
            {/* DHP Dispersible */}
            <th>Stok</th>
            <th>Tanggal ED Terdekat</th>
            <th>Nomor Batch & Sumber Pendanaan</th>
            {/* Primaquine */}
            <th>Stok</th>
            <th>Tanggal ED Terdekat</th>
            <th>Nomor Batch & Sumber Pendanaan</th>
            {/* Artesunat Injeksi */}
            <th>Stok</th>
            <th>Tanggal ED Terdekat</th>
            <th>Nomor Batch & Sumber Pendanaan</th>
            {/* Kina Tablet */}
            <th>Stok</th>
            <th>Tanggal ED Terdekat</th>
            <th>Nomor Batch & Sumber Pendanaan</th>
            {/* Kina Injeksi */}
            <th>Stok</th>
            <th>Tanggal ED Terdekat</th>
            <th>Nomor Batch & Sumber Pendanaan</th>
            {/* Doksisiklin */}
            <th>Stok</th>
            <th>Tanggal ED Terdekat</th>
            <th>Nomor Batch & Sumber Pendanaan</th>
            {/* RDT */}
            <th>Stok</th>
            <th>Tanggal ED Terdekat</th>
            <th>Nomor Batch & Sumber Pendanaan</th>
            {/* LLIN */}
            <th>Stok</th>
            <th>Tanggal ED Terdekat</th>
            <th>Nomor Batch & Sumber Pendanaan</th>
            {/* Insektisida (Kg) */}
            <th>Stok</th>
            <th>Tanggal ED Terdekat</th>
            <th>Nomor Batch & Sumber Pendanaan</th>
            {/* Insektisida (Lt) */}
            <th>Stok</th>
            <th>Tanggal ED Terdekat</th>
            <th>Nomor Batch & Sumber Pendanaan</th>
            {/* Larvasida (Kg) */}
            <th>Stok</th>
            <th>Tanggal ED Terdekat</th>
            <th>Nomor Batch & Sumber Pendanaan</th>
            {/* Larvasida (Lt) */}
            <th>Stok</th>
            <th>Tanggal ED Terdekat</th>
            <th>Nomor Batch & Sumber Pendanaan</th>
            {/* Labkit (Paket) */}
            <th>Stok</th>
            <th>Tanggal ED Terdekat</th>
            <th>Nomor Batch & Sumber Pendanaan</th>
            {/* Giemsa (CC) */}
            <th>Stok</th>
            <th>Tanggal ED Terdekat</th>
            <th>Nomor Batch & Sumber Pendanaan</th>
            {/* Minyak Imersi (CC) */}
            <th>Stok</th>
            <th>Tanggal ED Terdekat</th>
            <th>Nomor Batch & Sumber Pendanaan</th>
            {/* Methanol (CC) */}
            <th>Stok</th>
            <th>Tanggal ED Terdekat</th>
            <th>Nomor Batch & Sumber Pendanaan</th>
            {/* Blood slide (Box) */}
            <th>Stok</th>
            <th>Tanggal ED Terdekat</th>
            <th>Nomor Batch & Sumber Pendanaan</th>
            {/* Slide Box (Buah) */}
            <th>Stok</th>
            <th>Tanggal ED Terdekat</th>
            <th>Nomor Batch & Sumber Pendanaan</th>
            {/* Blood lancet (Box) */}
            <th>Stok</th>
            <th>Tanggal ED Terdekat</th>
            <th>Nomor Batch & Sumber Pendanaan</th>
            {/* Spray can */}
            <th>Stok</th>
            <th>Tanggal ED Terdekat</th>
            <th>Nomor Batch & Sumber Pendanaan</th>
            {/* Mist Blower */}
            <th>Stok</th>
            <th>Tanggal ED Terdekat</th>
            <th>Nomor Batch & Sumber Pendanaan</th>
            {/* Microscope binocular */}
            <th>Stok</th>
            <th>Tanggal ED Terdekat</th>
            <th>Nomor Batch & Sumber Pendanaan</th>
            {/* Microscope trinocular */}
            <th>Stok</th>
            <th>Tanggal ED Terdekat</th>
            <th>Nomor Batch & Sumber Pendanaan</th>
            {/* Microscope teaching */}
            <th>Stok</th>
            <th>Tanggal ED Terdekat</th>
            <th>Nomor Batch & Sumber Pendanaan</th>
          </tr>
        </thead>
        <tbody>
          {reportsData.length > 0 ? (
            reportsData?.map((item, index) => (
              <>
                <tr key={item?.id}>
                  <td>{index + 1}</td>
                  {currentLevel === UserLevel.National.toLowerCase() && (
                    <td>{item?.province}</td>
                  )}
                  <td>{item?.area}</td>
                  {/* DHP */}
                  <th>{item?.dhp}</th>
                  <th>{item?.dhp_ed_date}</th>
                  <th>{item?.dhp_batch_number}</th>
                  {/* DHP Dispersible */}
                  <th>{item?.dhp_dispersible}</th>
                  <th>{item?.dhp_dispersible_ed_date}</th>
                  <th>{item?.dhp_dispersible_batch_number}</th>
                  {/* Primaquine */}
                  <th>{item?.prima}</th>
                  <th>{item?.prima_ed_date}</th>
                  <th>{item?.prima_batch_number}</th>
                  {/* Artesunat Injeksi */}
                  <th>{item?.arteinj}</th>
                  <th>{item?.arteinj_ed_date}</th>
                  <th>{item?.arteinj_batch_number}</th>
                  {/* Kina Tablet */}
                  <th>{item?.kina}</th>
                  <th>{item?.kina_ed_date}</th>
                  <th>{item?.kina_batch_number}</th>
                  {/* Kina Injeksi */}
                  <th>{item?.kinainj}</th>
                  <th>{item?.kinainj_ed_date}</th>
                  <th>{item?.kinainj_batch_number}</th>
                  {/* Doksisiklin */}
                  <th>{item?.doksi}</th>
                  <th>{item?.doksi_ed_date}</th>
                  <th>{item?.doksi_batch_number}</th>
                  {/* RDT */}
                  <th>{item?.rdt}</th>
                  <th>{item?.rdt_ed_date}</th>
                  <th>{item?.rdt_batch_number}</th>
                  {/* LLIN */}
                  <th>{item?.kelambu}</th>
                  <th>{item?.kelambu_ed_date}</th>
                  <th>{item?.kelambu_batch_number}</th>
                  {/* Insektisida (Kg) */}
                  <th>{item?.inseckg}</th>
                  <th>{item?.inseckg_ed_date}</th>
                  <th>{item?.inseckg_batch_number}</th>
                  {/* Insektisida (Lt) */}
                  <th>{item?.inseclt}</th>
                  <th>{item?.inseclt_ed_date}</th>
                  <th>{item?.inseclt_batch_number}</th>
                  {/* Larvasida (Kg) */}
                  <th>{item?.larvakg}</th>
                  <th>{item?.larvakg_ed_date}</th>
                  <th>{item?.larvakg_batch_number}</th>
                  {/* Larvasida (Lt) */}
                  <th>{item?.larvalt}</th>
                  <th>{item?.larvalt_ed_date}</th>
                  <th>{item?.larvalt_batch_number}</th>
                  {/* Labkit (Paket) */}
                  <th>{item?.labkit}</th>
                  <th>{item?.labkit_ed_date}</th>
                  <th>{item?.labkit_batch_number}</th>
                  {/* Giemsa (CC) */}
                  <th>{item?.giemsacc}</th>
                  <th>{item?.giemsacc_ed_date}</th>
                  <th>{item?.giemsacc_batch_number}</th>
                  {/* Minyak Imersi (CC) */}
                  <th>{item?.imersicc}</th>
                  <th>{item?.imersicc_ed_date}</th>
                  <th>{item?.imersicc_batch_number}</th>
                  {/* Methanol (CC) */}
                  <th>{item?.metanolcc}</th>
                  <th>{item?.metanolcc_ed_date}</th>
                  <th>{item?.metanolcc_batch_number}</th>
                  {/* Blood slide (Box) */}
                  <th>{item?.bloodslide}</th>
                  <th>{item?.bloodslide_ed_date}</th>
                  <th>{item?.bloodslide_batch_number}</th>
                  {/* Slide Box (Buah) */}
                  <th>{item?.slidebox}</th>
                  <th>{item?.slidebox_ed_date}</th>
                  <th>{item?.slidebox_batch_number}</th>
                  {/* Blood lancet (Box) */}
                  <th>{item?.bloodlancet}</th>
                  <th>{item?.bloodlancet_ed_date}</th>
                  <th>{item?.bloodlancet_batch_number}</th>
                  {/* Spray can */}
                  <th>{item?.spraycan}</th>
                  <th>{item?.spraycan_ed_date}</th>
                  <th>{item?.spraycan_batch_number}</th>
                  {/* Mist Blower */}
                  <th>{item?.mistblow}</th>
                  <th>{item?.mistblow_ed_date}</th>
                  <th>{item?.mistblow_batch_number}</th>
                  {/* Microscope binocular */}
                  <th>{item?.micbino}</th>
                  <th>{item?.micbino_ed_date}</th>
                  <th>{item?.micbino_batch_number}</th>
                  {/* Microscope trinocular */}
                  <th>{item?.mictri}</th>
                  <th>{item?.mictri_ed_date}</th>
                  <th>{item?.mictri_catch}</th>
                  {/* Microscope teaching */}
                  <th>{item?.micteac}</th>
                  <th>{item?.micteac_ed_date}</th>
                  <th>{item?.micteac_batch_number}</th>
                </tr>
                {`${item.area}`.startsWith("IF_") &&  currentLevel === UserLevel.National.toLowerCase() && (
                  <tr style={{ backgroundColor: "#43766C" }}>
                    <td
                      style={{ color: "#FFF" }}
                      colSpan={
                        currentLevel === UserLevel.National.toLowerCase()
                          ? 3
                          : 2
                      }
                    >
                      TOTAL {item.province}
                    </td>
                    <td style={{ color: "#FFF" }} colSpan={3}>
                      {total(item.province_id).dhp}
                    </td>
                    <td style={{ color: "#FFF" }} colSpan={3}>
                      {total(item.province_id).dhp_dispersible}
                    </td>
                    <td style={{ color: "#FFF" }} colSpan={3}>
                      {total(item.province_id).prima}
                    </td>
                    <td style={{ color: "#FFF" }} colSpan={3}>
                      {total(item.province_id).arteinj}
                    </td>
                    <td style={{ color: "#FFF" }} colSpan={3}>
                      {total(item.province_id).kina}
                    </td>
                    <td style={{ color: "#FFF" }} colSpan={3}>
                      {total(item.province_id).kinainj}
                    </td>
                    <td style={{ color: "#FFF" }} colSpan={3}>
                      {total(item.province_id).doksi}
                    </td>
                    <td style={{ color: "#FFF" }} colSpan={3}>
                      {total(item.province_id).rdt}
                    </td>
                    <td style={{ color: "#FFF" }} colSpan={3}>
                      {total(item.province_id).kelambu}
                    </td>
                    <td style={{ color: "#FFF" }} colSpan={3}>
                      {total(item.province_id).inseckg}
                    </td>
                    <td style={{ color: "#FFF" }} colSpan={3}>
                      {total(item.province_id).inseclt}
                    </td>
                    <td style={{ color: "#FFF" }} colSpan={3}>
                      {total(item.province_id).larvakg}
                    </td>
                    <td style={{ color: "#FFF" }} colSpan={3}>
                      {total(item.province_id).larvalt}
                    </td>
                    <td style={{ color: "#FFF" }} colSpan={3}>
                      {total(item.province_id).labkit}
                    </td>
                    <td style={{ color: "#FFF" }} colSpan={3}>
                      {total(item.province_id).giemsacc}
                    </td>
                    <td style={{ color: "#FFF" }} colSpan={3}>
                      {total(item.province_id).imersicc}
                    </td>
                    <td style={{ color: "#FFF" }} colSpan={3}>
                      {total(item.province_id).metanolcc}
                    </td>
                    <td style={{ color: "#FFF" }} colSpan={3}>
                      {total(item.province_id).bloodslide}
                    </td>
                    <td style={{ color: "#FFF" }} colSpan={3}>
                      {total(item.province_id).slidebox}
                    </td>
                    <td style={{ color: "#FFF" }} colSpan={3}>
                      {total(item.province_id).bloodlancet}
                    </td>
                    <td style={{ color: "#FFF" }} colSpan={3}>
                      {total(item.province_id).spraycan}
                    </td>
                    <td style={{ color: "#FFF" }} colSpan={3}>
                      {total(item.province_id).mistblow}
                    </td>
                    <td style={{ color: "#FFF" }} colSpan={3}>
                      {total(item.province_id).micbino}
                    </td>
                    <td style={{ color: "#FFF" }} colSpan={3}>
                      {total(item.province_id).mictri}
                    </td>
                    <td style={{ color: "#FFF" }} colSpan={3}>
                      {total(item.province_id).micteac}
                    </td>
                  </tr>
                )}
              </>
            ))
          ) : (
            <tr>
              <td>Tidak ada data</td>
            </tr>
          )}
          <tr style={{ backgroundColor: "#43766C" }}>
            <td
              style={{ color: "#FFF" }}
              colSpan={
                currentLevel === UserLevel.National.toLowerCase() ? 3 : 2
              }
            >
              TOTAL KESELURUHAN
            </td>
            <td style={{ color: "#FFF" }} colSpan={3}>
              {total().dhp}
            </td>
            <td style={{ color: "#FFF" }} colSpan={3}>
              {total().dhp_dispersible}
            </td>
            <td style={{ color: "#FFF" }} colSpan={3}>
              {total().prima}
            </td>
            <td style={{ color: "#FFF" }} colSpan={3}>
              {total().arteinj}
            </td>
            <td style={{ color: "#FFF" }} colSpan={3}>
              {total().kina}
            </td>
            <td style={{ color: "#FFF" }} colSpan={3}>
              {total().kinainj}
            </td>
            <td style={{ color: "#FFF" }} colSpan={3}>
              {total().doksi}
            </td>
            <td style={{ color: "#FFF" }} colSpan={3}>
              {total().rdt}
            </td>
            <td style={{ color: "#FFF" }} colSpan={3}>
              {total().kelambu}
            </td>
            <td style={{ color: "#FFF" }} colSpan={3}>
              {total().inseckg}
            </td>
            <td style={{ color: "#FFF" }} colSpan={3}>
              {total().inseclt}
            </td>
            <td style={{ color: "#FFF" }} colSpan={3}>
              {total().larvakg}
            </td>
            <td style={{ color: "#FFF" }} colSpan={3}>
              {total().larvalt}
            </td>
            <td style={{ color: "#FFF" }} colSpan={3}>
              {total().labkit}
            </td>
            <td style={{ color: "#FFF" }} colSpan={3}>
              {total().giemsacc}
            </td>
            <td style={{ color: "#FFF" }} colSpan={3}>
              {total().imersicc}
            </td>
            <td style={{ color: "#FFF" }} colSpan={3}>
              {total().metanolcc}
            </td>
            <td style={{ color: "#FFF" }} colSpan={3}>
              {total().bloodslide}
            </td>
            <td style={{ color: "#FFF" }} colSpan={3}>
              {total().slidebox}
            </td>
            <td style={{ color: "#FFF" }} colSpan={3}>
              {total().bloodlancet}
            </td>
            <td style={{ color: "#FFF" }} colSpan={3}>
              {total().spraycan}
            </td>
            <td style={{ color: "#FFF" }} colSpan={3}>
              {total().mistblow}
            </td>
            <td style={{ color: "#FFF" }} colSpan={3}>
              {total().micbino}
            </td>
            <td style={{ color: "#FFF" }} colSpan={3}>
              {total().mictri}
            </td>
            <td style={{ color: "#FFF" }} colSpan={3}>
              {total().micteac}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default TableComponent;
