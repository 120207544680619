import React from "react";
import { useState } from "react";
import Kader from "services/Kader";
import RegmalFirst from "services/RegmalFirst";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { isEmpty } from "utils/common";
import { BreadcrumbNav } from "components/molecules";
import { ArrowLeft } from "react-feather";
import { Link } from "react-router-dom";

const KaderService = new Kader();
const RegmalFirstService = new RegmalFirst();

const DetailRegmal1 = () => {
  const [state, setState] = useState({
    regmal: null,
    cadre: [],
  });

  const params = useParams();

  const getRegmalData = async () => {
    try {
      const response = await RegmalFirstService.getOne(params.id);
      let cadres = [];

      if (response.cadres_id) {
        cadres = await Promise.all(
          response.cadres_id.map(async (id) => {
            const cadre = await KaderService.getOne(id);
            return cadre.name;
          })
        );
      }
      
      setState((prevState) => ({
        ...prevState,
        regmal: response,
        cadre: cadres
      }));

      
    } catch (error) {
      console.log(error);
    }
  };

  const getCadreData = async (cadreId) => {
    try {
      if (isEmpty(cadreId)) {
        return;
      }

      console.log(cadreId);
      cadreId.forEach(async (id) => {
        const response = await KaderService.getOne(id);
        console.log(response);
        
        setState((prevState) => ({
          ...prevState,
          cadre: response,
        }));
        
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getRegmalData();
    getCadreData(state.regmal?.cadres_id);
  }, []);

  return (
    <div className="dashboard-wrapper">
      {/* <BreadcrumbNav
        items={[
          { label: "Register Malaria 1", link: "/register-malaria-1" },
          {
            label: "Detail Data",
            link: "#",
          },
        ]}
      /> */}

      <Link
        to="/register-malaria-1"
        style={{ color: "black", textDecoration: "none" }}
      >
        <ArrowLeft />
        <span>Kembali</span>
      </Link>

      <h1 className="page-title">Detail Data Register Malaria 1</h1>
      <table className="table">
        <tr>
          <th>Warga Negara Asing</th>
          <td>:</td>
          <td>{state.regmal?.is_foreign_nationality}</td>
        </tr>
        <tr>
          <th>Tidak Memiliki KTP</th>
          <td>:</td>
          <td>{state.regmal?.not_have_ktp}</td>
        </tr>
        <tr>
          <th>NIK/No KTP</th>
          <td>:</td>
          <td>{state.regmal?.ktp}</td>
        </tr>
        <tr>
          <th>Nama</th>
          <td>:</td>
          <td>{state.regmal?.name}</td>
        </tr>
        <tr>
          <th>Usia</th>
          <td>:</td>
          <td>
            {state.regmal?.age} {state.regmal?.age_type}
          </td>
        </tr>
        <tr>
          <th>Jenis Kelamin</th>
          <td>:</td>
          <td>{state.regmal?.gender}</td>
        </tr>
        <tr>
          <th>Status Kehamilan</th>
          <td>:</td>
          <td>{state.regmal?.pregnancy}</td>
        </tr>
        <tr>
          <th>Provinsi Domisili</th>
          <td>:</td>
          <td>
            {isEmpty(state.regmal?.province_id)
              ? null
              : state.regmal?.province_id?.split("|")[1]}
          </td>
        </tr>
        <tr>
          <th>Kabupaten / Kota Domisili</th>
          <td>:</td>
          <td>
            {isEmpty(state.regmal?.district_id)
              ? null
              : state.regmal?.district_id?.split("|")[1]}
          </td>
        </tr>
        <tr>
          <th>Kecamatan Domisili</th>
          <td>:</td>
          <td>
            {isEmpty(state.regmal?.subdistrict_id)
              ? null
              : state.regmal?.subdistrict_id?.split("|")[1]}
          </td>
        </tr>
        <tr>
          <th>Fasyankes Domisili</th>
          <td>:</td>
          <td>
            {isEmpty(state.regmal?.fasyankes_id)
              ? null
              : state.regmal?.fasyankes_id?.split("|")[1]}
          </td>
        </tr>
        <tr>
          <th>Desa Domisili</th>
          <td>:</td>
          <td>
            {isEmpty(state.regmal?.urbanvillage_id)
              ? null
              : state.regmal?.urbanvillage_id?.split("|")[1]}
          </td>
        </tr>
        <tr>
          <th>Nama Dusun</th>
          <td>:</td>
          <td>
            {isEmpty(state.regmal?.sub_village)
              ? null
              : state.regmal?.sub_village}
          </td>
        </tr>
        <tr>
          <th>Alamat Lengkap</th>
          <td>:</td>
          <td>{state.regmal?.address}</td>
        </tr>
        <tr>
          <th>Pasien Berada diluar domisili?</th>
          <td>:</td>
          <td>{state.regmal?.is_domicile_outside}</td>
        </tr>
        <tr>
          <th>Provinsi KTP</th>
          <td>:</td>
          <td>
            {isEmpty(state.regmal?.domicile_province_id)
              ? null
              : state.regmal?.domicile_province_id?.split("|")[1]}
          </td>
        </tr>
        <tr>
          <th>Kabupaten / Kota KTP</th>
          <td>:</td>
          <td>
            {isEmpty(state.regmal?.domicile_district_id)
              ? null
              : state.regmal?.domicile_district_id?.split("|")[1]}
          </td>
        </tr>
        <tr>
          <th>Kecamatan KTP</th>
          <td>:</td>
          <td>
            {isEmpty(state.regmal?.domicile_subdistrict_id)
              ? null
              : state.regmal?.domicile_subdistrict_id?.split("|")[1]}
          </td>
        </tr>
        <tr>
          <th>Fasyankes KTP</th>
          <td>:</td>
          <td>
            {isEmpty(state.regmal?.domicile_fasyankes_id)
              ? null
              : state.regmal?.domicile_fasyankes_id?.split("|")[1]}
          </td>
        </tr>
        <tr>
          <th>Asal Penemuan</th>
          <td>:</td>
          <td>{state.regmal?.case_from}</td>
        </tr>
        <tr>
          <th>Kegiatan Penemuan</th>
          <td>:</td>
          <td>{state.regmal?.discovery_activities}</td>
        </tr>
        <tr>
          <th>Tanggal MBS</th>
          <td>:</td>
          <td>{state.regmal?.mbs_date}</td>
        </tr>
        <tr>
          <th>Tanggal Survey Kontak</th>
          <td>:</td>
          <td>{state.regmal?.survey_contact_date}</td>
        </tr>
        <tr>
          <th>Kasus Awal Survei Kontak</th>
          <td>:</td>
          <td>{state.regmal?.first_case_trigger_survey_contact}</td>
        </tr>
        <tr>
          <th>SR</th>
          <td>:</td>
          <td>{state.regmal?.sr}</td>
        </tr>
        <tr>
          <th>SSR</th>
          <td>:</td>
          <td>{state.regmal?.ssr}</td>
        </tr>
        <tr>
          <th>Petugas</th>
          <td>:</td>
          <td>{state.regmal?.house_visit}</td>
        </tr>
        <tr>
          <th>Kader</th>
          <td>:</td>
          <td>{state.cadre.join(", ")}</td>
        </tr>
        <tr>
          <th>Jenis Pemeriksaan</th>
          <td>:</td>
          <td>{state.regmal?.lab_checking_type}</td>
        </tr>
        <tr>
          <th>Jumlah RDT</th>
          <td>:</td>
          <td>{state.regmal?.total_rdt}</td>
        </tr>
        <tr>
          <th>Tanggal Kunjungan Pasien</th>
          <td>:</td>
          <td>{state.regmal?.patient_visite_date}</td>
        </tr>
        <tr>
          <th>Kode Slide</th>
          <td>:</td>
          <td>{state.regmal?.slide_code}</td>
        </tr>
        <tr>
          <th>Jenis Parasit</th>
          <td>:</td>
          <td>
            {state.regmal &&
              state.regmal?.parasyte &&
              state.regmal?.parasyte.join(", ")}
          </td>
        </tr>
        <tr>
          <th>Pekerjaan</th>
          <td>:</td>
          <td>{state.regmal?.job}</td>
        </tr>
        <tr>
          <th>Derajat Penyakit Malaria</th>
          <td>:</td>
          <td>{state.regmal?.disease_degree}</td>
        </tr>
        <tr>
          <th>Suhu Tubuh (Celcius)</th>
          <td>:</td>
          <td>{state.regmal?.body_temperature}</td>
        </tr>
        <tr>
          <th>Gejala</th>
          <td>:</td>
          <td>{state.regmal?.sympthomatic}</td>
        </tr>
        <tr>
          <th>Tanggal Gejala</th>
          <td>:</td>
          <td>{state.regmal?.sympthomatic_date}</td>
        </tr>
        <tr>
          <th>Kekambuhan</th>
          <td>:</td>
          <td>{state.regmal?.recurrence}</td>
        </tr>
        <tr>
          <th>Gametosit</th>
          <td>:</td>
          <td>{state.regmal?.gametosit}</td>
        </tr>
        <tr>
          <th>Kepadatan Parasit</th>
          <td>:</td>
          <td>{state.regmal?.parasyte_density}</td>
        </tr>
        <tr>
          <th>Berat Badan</th>
          <td>:</td>
          <td>{state.regmal?.weight}</td>
        </tr>
        <tr>
          <th>Status Anemia</th>
          <td>:</td>
          <td>{state.regmal?.anemia_status}</td>
        </tr>
        <tr>
          <th>Pengobatan</th>
          <td>:</td>
          <td>
            <ul>
              <li>
                DHP Tablet :{" "}
                <b>
                  (
                  {(state.regmal?.medication &&
                    state.regmal?.medication.dhp_tablet) ||
                    0}
                  )
                </b>
              </li>
              <li>
                Primaquin Tablet :{" "}
                <b>
                  (
                  {(state.regmal?.medication &&
                    state.regmal?.medication.primaquin_tablet) ||
                    0}
                  )
                </b>
              </li>
              <li>
                Artesunat Injeksi :{" "}
                <b>
                  (
                  {(state.regmal?.medication &&
                    state.regmal?.medication.artesunat_injeksi) ||
                    0}
                  )
                </b>
              </li>
              <li>
                Kina Tablet :{" "}
                <b>
                  (
                  {(state.regmal?.medication &&
                    state.regmal?.medication.kina_tablet) ||
                    0}
                  )
                </b>
              </li>
              <li>
                Kina Injeksi :{" "}
                <b>
                  (
                  {(state.regmal?.medication &&
                    state.regmal?.medication.kina_injeksi) ||
                    0}
                  )
                </b>
              </li>
              <li>
                Doksisiklin :{" "}
                <b>
                  (
                  {(state.regmal?.medication &&
                    state.regmal?.medication.doksisiklin) ||
                    0}
                  )
                </b>
              </li>
              <li>
                Tetrasiklin :{" "}
                <b>
                  (
                  {(state.regmal?.medication &&
                    state.regmal?.medication.tetrasiklin) ||
                    0}
                  )
                </b>
              </li>
              <li>
                Klindamisin:{" "}
                <b>
                  (
                  {(state.regmal?.medication &&
                    state.regmal?.medication.klindamisin) ||
                    0}
                  )
                </b>
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <th>Tanggal Pengobatan</th>
          <td>:</td>
          <td>{state.regmal?.medication_date}</td>
        </tr>
        <tr>
          <th>Status pengobatan standar</th>
          <td>:</td>
          <td>{state.regmal?.standard_medication_status}</td>
        </tr>
        <tr>
          <th>Jika tidak diobati standar, alasannya?</th>
          <td>:</td>
          <td>{state.regmal?.not_standard_medication_reason}</td>
        </tr>
        <tr>
          <th>Perawatan</th>
          <td>:</td>
          <td>{state.regmal?.treatment}</td>
        </tr>
        <tr>
          <th>Kematian Akibat Malaria</th>
          <td>:</td>
          <td>{state.regmal?.death_with_malaria}</td>
        </tr>
        <tr>
          <th>Dirujuk Ke Faskes Lain</th>
          <td>:</td>
          <td>{state.regmal?.referred_to_other_faskes}</td>
        </tr>
        <tr>
          <th>Alasan Dirujuk</th>
          <td>:</td>
          <td>{state.regmal?.ref_reason}</td>
        </tr>
        <tr>
          <th>Tanggal Rujukan</th>
          <td>:</td>
          <td>{state.regmal?.ref_date}</td>
        </tr>
        <tr>
          <th>Provinsi Rujukan</th>
          <td>:</td>
          <td>
            {isEmpty(state.regmal?.ref_province_id)
              ? null
              : state.regmal?.ref_province_id?.split("|")[1]}
          </td>
        </tr>
        <tr>
          <th>Kabupaten Rujukan</th>
          <td>:</td>
          <td>
            {isEmpty(state.regmal?.ref_district_id)
              ? null
              : state.regmal?.ref_district_id?.split("|")[1]}
          </td>
        </tr>
        <tr>
          <th>Fasyankes Rujukan</th>
          <td>:</td>
          <td>
            {isEmpty(state.regmal?.ref_fasyankes_id)
              ? null
              : state.regmal?.ref_fasyankes_id?.split("|")[1]}
          </td>
        </tr>
      </table>
    </div>
  );
};

export default DetailRegmal1;
