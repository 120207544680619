import Button from "components/atoms/Button";
import InputCheckbox from "components/atoms/InputCheckbox";
import InputDate from "components/atoms/InputDate";
import InputField from "components/atoms/InputField";
import InputRadio from "components/atoms/InputRadio";
import InputSelect from "components/atoms/InputSelect";
import { BreadcrumbNav } from "components/molecules";
import { useFormik } from "formik";
import moment from "moment";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  errorToastNotif,
  getDataFromLocalStorage,
  successToastNotif,
} from "utils/common";
import {
  DiseaseDegreeOptions,
  GametositOptions,
  LabCheckingOptionsRujukan,
  PageMode,
  RecurrenceOptions,
  SymthomaticOptions,
  TreatmentOptions,
  YesAndNoOptions,
} from "utils/constant";
import Rujukan from "services/Rujukan";
import { useParams } from "react-router-dom";
import medicationStatus from "pages/DataKasus/RegisterMalaria1/components/StandarMedication";

const RujukanService = new Rujukan();

const FormComponent = ({ pageMode, handlePageModeChange }) => {
  const [itemToEdit, setItemToEdit] = useState(null);

  const { currentUser } = useSelector((state) => state.authReducer);

  const formik = useFormik({
    initialValues: {
      regmal_id: "",
      ktp: "",
      name: "",
      age: "",
      gender: "",
      source_fasyankes_id: "",
      ref_fasyankes_id: "",
      ref_date: "",
      ref_patient_arrival_date: "",
      is_lab_rechecking: "",
      lab_rechecking_type: "",
      reg_number: "",
      slide_code: "",
      total_rdt: "",
      lab_rechecking_date: "",
      parasyte: [],
      disease_degree: "",
      body_temperature: "",
      sympthomatic: "",
      other_sympthomatic: "",
      sympthomatic_date: "",
      gametosit: "",
      parasyte_density: "",
      weight: "",
      medication_date: "",
      medication: "",
      standard_medication_status: "",
      dhp_tablet: "",
      primaquin_tablet: "",
      kina_tablet: "",
      artesunat_injeksi: "",
      kina_injeksi: "",
      doksisiklin: "",
      tetrasiklin: "",
      klindamisin: "",
      treatment: "",
      death_with_malaria: "",
      status: "",
    },
    validate: (values) => {
      const errors = {};

      if (isArrive) {
        if (!values.ref_patient_arrival_date) {
          errors.ref_patient_arrival_date =
            "Tanggal pasien rujukan datang wajib diisi";
        }

        if (!values.is_lab_rechecking) {
          errors.is_lab_rechecking =
            "Apakah dilakukan pemeriksaan ulang wajib diisi";
        }

        if (values.is_lab_rechecking === "Ya") {
          if (!values.lab_rechecking_type) {
            errors.lab_rechecking_type = "Jenis pemeriksaan wajib diisi";
          }

          if (!values.lab_rechecking_date) {
            errors.lab_rechecking_date = "Tanggal pemeriksaan wajib disii";
          }
          if (!values.parasyte || values.parasyte.length === 0) {
            errors.parasyte = "Parasyte wajib diisi";
          }
        }

        if (!values.treatment) {
          errors.treatment = "Perawatan wajib diisi";
        }

        if (!values.death_with_malaria) {
          errors.death_with_malaria = "Kematian dengan malaria wajib diisi";
        }
      }

      return errors;
    },
    onSubmit: async (values) => {
      let medication = {
        dhp_tablet: values.dhp_tablet,
        primaquin_tablet: values.primaquin_tablet,
        kina_injeksi: values.kina_injeksi,
        kina_tablet: values.kina_tablet,
        doksisiklin: values.doksisiklin,
        tetrasiklin: values.tetrasiklin,
        klindamisin: values.klindamisin,
        artesunat_injeksi: values.artesunat_injeksi,
      };

      let payload = {
        regmal_id: values.regmal_id,
        ktp: values.ktp,
        name: values.name,
        age: values.age,
        gender: values.gender,
        source_fasyankes_id: values.source_fasyankes_id,
        ref_fasyankes_id: values.ref_fasyankes_id,
        ref_date: values.ref_date,
        ref_patient_arrival_date: values.ref_patient_arrival_date,
        is_lab_rechecking: values.is_lab_rechecking,
        lab_rechecking_type: values.lab_rechecking_type,
        slide_code: values.slide_code,
        total_rdt: values.total_rdt,
        lab_rechecking_date: values.lab_rechecking_date,
        parasyte: values.parasyte,
        disease_degree: values.disease_degree,
        body_temperature: values.body_temperature,
        sympthomatic: values.sympthomatic,
        sympthomatic_date: values.sympthomatic_date,
        gametosit: values.gametosit,
        parasyte_density: values.parasyte_density,
        weight: values.weight,
        medication_date: values.medication_date,
        medication: medication,
        standard_medication_status: values.standard_medication_status,
        treatment: values.treatment,
        death_with_malaria: values.death_with_malaria,
        status: "Sudah",
      };

      try {
        if (isArrive) {
          await RujukanService.update(payload, itemToEdit?.id)
            .then((res) => {
              handlePageModeChange(PageMode.view);
              successToastNotif("Data Rujukan Berhasil diupdate");
            })
            .catch((err) => {
              console.log(err);
              errorToastNotif(err);
            });
        } else {
          await RujukanService.update(
            { status: "Pasien Tidak Datang" },
            itemToEdit?.id
          )
            .then((res) => {
              handlePageModeChange(PageMode.view);
              successToastNotif("Data Rujukan Berhasil diupdate");
            })
            .catch((err) => {
              console.log(err);
              errorToastNotif(err);
            });
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  const [isArrive, setIsArrive] = useState(
    formik.values.is_patient_arrive == undefined ? true : false
  );

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    const value = e.target.value;

    if (value === "P.Mix") {
      const newValues = isChecked ? ["P.Mix"] : [];
      formik.setFieldValue("parasyte", newValues);
    } else {
      const newValues = isChecked
        ? [...formik.values.parasyte, value]
        : formik.values.parasyte.filter((item) => item !== value);
      formik.setFieldValue("parasyte", newValues);
    }
  };

  const generateSlideCode = () => {
    let slideCode = "";

    const districtId = currentUser?.district_id?.split("|")[0];
    const fasyankesId = currentUser?.fasyankes_id?.split("|")[0];
    const regNumber = formik.values.reg_number || 0;
    const monthLabChecking =
      formik.values.lab_rechecking_date?.split("-")[1] || 0;
    const yearLabChecking =
      formik.values.lab_rechecking_date?.split("-")[0] || 0;

    slideCode = `${districtId}/${fasyankesId}/${regNumber}/${monthLabChecking}/${yearLabChecking}`;

    formik.setFieldValue("slide_code", slideCode);
  };

  const generateStatusMedication = () => {
    let statusMedication = medicationStatus(formik.values);
    if (statusMedication == "Standard") {
      formik.setFieldValue("standard_medication_status", "Standard");
      formik.setFieldValue("not_standard_medication_reason", "");
      formik.setFieldValue("other_not_standard_medication_reason", "");
    } else {
      formik.setFieldValue("standard_medication_status", "Tidak Standard");
    }
  };

  const getRecurrenceOptions = (parasyteValue) => {
    const commonOptions = [{ label: "Baru", value: "Baru" }];

    if (
      parasyteValue?.includes("P.Ovale") ||
      parasyteValue?.includes("P.Vivax") ||
      parasyteValue?.includes("P.Mix")
    ) {
      return [...commonOptions, { label: "Relaps", value: "Relaps" }];
    }

    return commonOptions;
  };

  useEffect(() => {
    generateStatusMedication();
  }, [
    formik.values.disease_degree,
    formik.values.weight,
    formik.values.age,
    formik.values.age_type,
    formik.values.gender,
    formik.values.pregnancy,
    formik.values.recurrence,
    formik.values.parasyte,
    formik.values.dhp_tablet,
    formik.values.primaquin_tablet,
    formik.values.kina_tablet,
    formik.values.kina_injeksi,
    formik.values.artesunat_injeksi,
    formik.values.doksisiklin,
    formik.values.tetrasiklin,
    formik.values.klindamisin,
  ]);

  useEffect(() => {
    if (pageMode === PageMode.edit) {
      const selectedItem = JSON.parse(getDataFromLocalStorage("SELECTED_ITEM"));
      setItemToEdit(selectedItem);
      if (`selectedItem`?.status == "Pasien Tidak Datang") {
        setIsArrive(false);
      }

      formik.setValues({
        regmal_id: selectedItem?.regmal_id,
        ktp: selectedItem?.ktp,
        name: selectedItem?.name,
        age: selectedItem?.age,
        age_type: selectedItem?.age_type,
        pregnancy: selectedItem?.regmal.pregnancy,
        recurrence: selectedItem?.recurrence,
        gender: selectedItem?.gender,
        source_fasyankes_id: selectedItem?.source_fasyankes_id,
        ref_fasyankes_id: selectedItem?.ref_fasyankes_id,
        ref_date: selectedItem?.ref_date,
        ref_patient_arrival_date: selectedItem?.ref_patient_arrival_date,
        is_lab_rechecking: selectedItem?.is_lab_rechecking,
        lab_rechecking_date: selectedItem?.lab_rechecking_date,
        slide_code: selectedItem?.slide_code,
        total_rdt: selectedItem?.total_rdt,
        lab_rechecking_type: selectedItem?.lab_rechecking_type,
        parasyte: selectedItem?.parasyte,
        disease_degree: selectedItem?.disease_degree,
        body_temperature: selectedItem?.body_temperature,
        sympthomatic: selectedItem?.sympthomatic,
        sympthomatic_date: selectedItem?.sympthomatic_date,
        gametosit: selectedItem?.gametosit,
        parasyte_density: selectedItem?.parasyte_density,
        weight: selectedItem?.weight,
        medication_date: selectedItem?.medication_date,
        dhp_tablet: selectedItem?.medication?.dhp_tablet,
        primaquin_tablet: selectedItem?.medication?.primaquin_tablet,
        kina_tablet: selectedItem?.medication?.kina_tablet,
        kina_injeksi: selectedItem?.medication?.kina_injeksi,
        artesunat_injeksi: selectedItem?.medication?.artesunat_injeksi,
        klindamisin: selectedItem?.medication?.klindamisin,
        doksisiklin: selectedItem?.medication?.doksisiklin,
        tetrasiklin: selectedItem?.medication?.tetrasiklin,
        standard_medication_status: selectedItem?.standard_medication_status,
        treatment: selectedItem?.treatment,
        death_with_malaria: selectedItem?.death_with_malaria,
        is_patient_arrive:
          selectedItem?.status == "Pasien Tidak Datang" ? "Tidak" : null,
      });
    }
  }, []);

  useEffect(() => {
    if (formik.values.lab_rechecking_type === "Mikroskop") {
      generateSlideCode();
    }
  }, [
    formik.values.reg_number,
    formik.values.lab_rechecking_date,
    formik.values.lab_rechecking_type,
  ]);

  return (
    <>
      <BreadcrumbNav
        items={[
          {
            label: "Regmal 1 - Rujukan",
            onClick: () => handlePageModeChange(PageMode.view),
          },
          { label: "Edit Data Rujukan" },
        ]}
      />
      <h1 className="page-title">Edit Data Rujukan</h1>

      <div className="table-responsive">
        <table className="table table-hover">
          <tbody>
            <tr>
              <td width={"300"}>Nama</td>
              <td width={"10"}>:</td>
              <td>
                <span style={{ fontWeight: "600" }}>{itemToEdit?.name}</span>
              </td>
            </tr>
            <tr>
              <td width={"300"}>NIK</td>
              <td width={"10"}>:</td>
              <td>
                <span style={{ fontWeight: "600" }}>{itemToEdit?.ktp}</span>
              </td>
            </tr>
            <tr>
              <td width={"300"}>Jenis Kelamin</td>
              <td width={"10"}>:</td>
              <td>
                <span style={{ fontWeight: "600" }}>{itemToEdit?.gender}</span>
              </td>
            </tr>
            {/* only show if jenis kelamin perempuan */}
            {itemToEdit?.gender === "Perempuan" && (
              <tr>
                <td width={"300"}>Status Kehamilan</td>
                <td width={"10"}>:</td>
                <td>
                  <span style={{ fontWeight: "600" }}>
                    {itemToEdit?.regmal?.pregnancy}
                  </span>
                </td>
              </tr>
            )}
            <tr>
              <td width={"300"}>Usia</td>
              <td width={"10"}>:</td>
              <td>
                <span style={{ fontWeight: "600" }}>
                  {itemToEdit?.age} {itemToEdit?.age_type}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="form-grouping">
        <p
          className="warning"
          style={{ marginBottom: "24px", marginTop: "24px" }}
        >
          NOTE<span>*(WAJIB DIISI)</span>
        </p>

        <InputRadio
          label="Apakah Pasien datang ke faskes rujukan?"
          name="is_patient_arrive"
          options={YesAndNoOptions}
          onChange={(e) => {
            const selectedValue = e.target.value;
            formik.handleChange("is_patient_arrive")(selectedValue);
            setIsArrive(selectedValue == "Ya" ? true : false);
          }}
          onBlur={formik.handleBlur}
          selectedOption={formik.values.is_patient_arrive}
          error={
            formik.errors.is_patient_arrive && formik.touched.is_patient_arrive
          }
          errorMessage={formik.errors.is_patient_arrive}
          isRequired={true}
        />

        {!isArrive && (
          <div className="warning-message">
            <div className="message">Pasien Tidak Datang</div>
          </div>
        )}

        {isArrive && (
          <>
            <InputDate
              label={"Tanggal Pasien Rujukan Datang"}
              name={"ref_patient_arrival_date"}
              placeholder={"Pilih Tanggal"}
              dateFormat={"YYYY-MM-DD"}
              onChange={(e) => {
                const selectedDate = moment(e).format("YYYY-MM-DD");
                formik.handleChange("ref_patient_arrival_date")(selectedDate);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.ref_patient_arrival_date}
              error={
                formik.errors.ref_patient_arrival_date &&
                formik.touched.ref_patient_arrival_date
              }
              errorMessage={formik.errors.ref_patient_arrival_date}
              isRequired={true}
            />

            <InputRadio
              label="Apakah pasien yang dirujuk dilakukan pemeriksaan lab ulang?"
              name="is_lab_rechecking"
              options={YesAndNoOptions}
              onChange={(e) => {
                const selectedValue = e.target.value;
                formik.handleChange("is_lab_rechecking")(selectedValue);
              }}
              onBlur={formik.handleBlur}
              selectedOption={formik.values.is_lab_rechecking}
              error={
                formik.errors.is_lab_rechecking &&
                formik.touched.is_lab_rechecking
              }
              errorMessage={formik.errors.is_lab_rechecking}
              isRequired={true}
            />

            {formik.values.is_lab_rechecking === "Ya" && (
              <>
                <InputSelect
                  label={"Jenis Pemeriksaan"}
                  name={"lab_rechecking_type"}
                  placeholder={"Pilih Pemeriksaan"}
                  data={LabCheckingOptionsRujukan}
                  onChange={(e) => {
                    const selectedValue = e ? e.value : "";
                    formik.handleChange("lab_rechecking_type")(selectedValue);

                    if (selectedValue === "Mikroskop") {
                      formik.setFieldValue(
                        "parasyte",
                        formik.values.parasyte?.filter(
                          (item) => item !== "P>Mix"
                        )
                      );
                    }
                  }}
                  onBlur={formik.handleBlur}
                  value={LabCheckingOptionsRujukan.find(
                    (item) => item.value === formik.values.lab_rechecking_type
                  )}
                  error={
                    formik.errors.lab_rechecking_type &&
                    formik.touched.lab_rechecking_type
                  }
                  errorMessage={formik.errors.lab_rechecking_type}
                  isRequired={true}
                />

                {formik.values.lab_rechecking_type === "Mikroskop" && (
                  <InputField
                    label={"Nomor Registrasi Pasien"}
                    name={"reg_number"}
                    maxLengthVal={20}
                    placeholder={"Masukkan Nomor Registrasi Pasien"}
                    {...formik.getFieldProps("reg_number")}
                  />
                )}

                <InputField
                  label={"Jumlah RDT yang digunakan"}
                  name={"total_rdt"}
                  placeholder={"Masukkan Jumlah RDT"}
                  {...formik.getFieldProps("total_rdt")}
                  error={formik.errors.total_rdt && formik.touched.total_rdt}
                  errorMessage={formik.errors.total_rdt}
                  isRequired={true}
                />

                <InputDate
                  label={"Tanggal Pemeriksaan Lab"}
                  name={"lab_rechecking_date"}
                  placeholder={"Pilih Tanggal"}
                  dateFormat={"YYYY-MM-DD"}
                  onChange={(e) => {
                    const selectedDate = moment(e).format("YYYY-MM-DD");
                    formik.handleChange("lab_rechecking_date")(selectedDate);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.lab_rechecking_date}
                  error={
                    formik.errors.lab_rechecking_date &&
                    formik.touched.lab_rechecking_date
                  }
                  errorMessage={formik.errors.lab_rechecking_date}
                  isRequired={true}
                />

                {formik.values.lab_rechecking_type === "Mikroskop" && (
                  <InputField
                    label={"Kode Slide"}
                    name={"slide_code"}
                    placeholder={"Kode Slide Otomatis"}
                    value={formik.values.slide_code}
                    disabled={true}
                  />
                )}

                <InputCheckbox
                  label="Jenis Parasit"
                  name="parasyte"
                  options={[
                    { label: "P.Falciparum", value: "P.Falciparum" },
                    { label: "P.Malariae", value: "P.Malariae" },
                    { label: "Probable Knowlesi", value: "Probable Knowlesi" },
                    { label: "P.Vivax", value: "P.Vivax" },
                    { label: "P.Ovale", value: "P.Ovale" },
                    ...(formik.values.lab_rechecking_type === "RDT"
                      ? [{ label: "P.Mix", value: "P.Mix" }]
                      : []),
                  ]}
                  onChange={(e) => handleCheckboxChange(e)}
                  onBlur={formik.handleBlur}
                  selectedOption={formik.values.parasyte}
                  isItalic={true}
                  error={formik.errors.parasyte && formik.touched.parasyte}
                  errorMessage={formik.errors.parasyte}
                  isRequired={true}
                />
              </>
            )}

            <InputRadio
              label="Derajat Penyakit Malaria"
              name="disease_degree"
              options={DiseaseDegreeOptions}
              onChange={(e) => {
                const selectedValue = e.target.value;
                formik.handleChange("disease_degree")(selectedValue);
              }}
              onBlur={formik.handleBlur}
              selectedOption={formik.values.disease_degree}
            />

            <div className="row">
              <div className="col-sm-6">
                <InputField
                  label={"Suhu Tubuh"}
                  name={"body_temperature"}
                  placeholder={"Masukkan Suhu Tubuh"}
                  type={"number"}
                  maxLengthVal={3}
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    formik.handleChange("body_temperature")(selectedValue);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.body_temperature}
                />
              </div>
              <div className="col-sm-6">
                <InputSelect
                  label={"Gejala"}
                  name={"sympthomatic"}
                  placeholder={"Pilih Gejala"}
                  data={SymthomaticOptions}
                  onChange={(e) => {
                    const selectedValue = e ? e.value : "";
                    formik.handleChange("sympthomatic")(selectedValue);
                    if (selectedValue !== "Lainnya") {
                      formik.setFieldValue("other_sympthomatic", "");
                    }
                  }}
                  onBlur={formik.handleBlur}
                  value={SymthomaticOptions?.find(
                    (item) => item.value === formik.values.sympthomatic
                  )}
                />
                {formik.values.sympthomatic === "Lainnya" && (
                  <InputField
                    name={"other_sympthomatic"}
                    placeholder={"Masukkan Gejala Lainnya"}
                    type={"text"}
                    {...formik.getFieldProps("other_sympthomatic")}
                    error={
                      formik.errors.other_sympthomatic &&
                      formik.touched.other_sympthomatic
                    }
                    errorMessage={formik.errors.other_sympthomatic}
                  />
                )}
              </div>
            </div>

            {formik.values.sympthomatic !== "Tidak bergejala" &&
              formik.values.sympthomatic !== null &&
              formik.values.sympthomatic !== "" && (
              <InputDate
                label={"Tanggal Gejala"}
                name={"sympthomatic_date"}
                placeholder={"Pilih Tanggal"}
                dateFormat={"YYYY-MM-DD"}
                onChange={(e) => {
                  const selectedDate = moment(e).format("YYYY-MM-DD");
                  formik.handleChange("sympthomatic_date")(selectedDate);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.sympthomatic_date}
                error={
                  formik.errors.sympthomatic_date &&
                    formik.touched.sympthomatic_date
                }
                errorMessage={formik.errors.sympthomatic_date}
                isRequired={
                  formik.values.sympthomatic === "Tidak bergejala"
                    ? false
                    : true
                }
              />
            )}

            <InputRadio
              label="Gametosit"
              name="gametosit"
              options={GametositOptions}
              onChange={(e) => {
                const selectedValue = e.target.value;
                formik.handleChange("gametosit")(selectedValue);
              }}
              onBlur={formik.handleBlur}
              selectedOption={formik.values.gametosit}
            />

            <div className="row">
              <div className="col-sm-6">
                <InputField
                  label={"Kepadatan Parasit(Per mikro liter)"}
                  name={"parasyte_density"}
                  placeholder={"Masukkan Kepadatan Parasit"}
                  type={"number"}
                  maxLengthVal={11}
                  {...formik.getFieldProps("parasyte_density")}
                />
              </div>
              <div className="col-sm-6">
                <InputField
                  label={"Berat Badan (Kg)"}
                  name={"weight"}
                  placeholder={"Masukkan Berat Badan"}
                  type={"number"}
                  maxLengthVal={11}
                  {...formik.getFieldProps("weight")}
                />
              </div>
            </div>

            <InputSelect
              label={"Kekambuhan"}
              name={"recurrence"}
              placeholder={"Pilih Kekambuhan"}
              data={getRecurrenceOptions(formik.values.parasyte)}
              onChange={(e) => {
                const selectedValue = e ? e.value : "";
                formik.handleChange("recurrence")(selectedValue);
              }}
              onBlur={formik.handleBlur}
              value={RecurrenceOptions?.find(
                (item) => item.value === formik.values.recurrence
              )}
              error={formik.errors.recurrence && formik.touched.recurrence}
              errorMessage={formik.errors.recurrence}
              isRequired={true}
            />

            <hr />
            <h3 className="subtitle-section">Pengobatan</h3>
            <InputDate
              label={"Tanggal Pengobatan"}
              name={"medication_date"}
              placeholder={"Pilih Tanggal"}
              dateFormat={"YYYY-MM-DD"}
              onChange={(e) => {
                const selectedDate = moment(e).format("YYYY-MM-DD");
                formik.handleChange("medication_date")(selectedDate);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.medication_date}
            />
            <hr />
            <div className="row">
              <div className="col-sm-6">
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "#313131",
                  }}
                >
                  DHP Tablet (Tablet)
                </p>
              </div>
              <div className="col-sm-6">
                <InputField
                  name={"dhp_tablet"}
                  placeholder={"0"}
                  type={"decimal"}
                  {...formik.getFieldProps("dhp_tablet")}
                />
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-sm-6">
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "#313131",
                  }}
                >
                  Primaquin Tablet (Tablet)
                </p>
              </div>
              <div className="col-sm-6">
                <InputField
                  name={"primaquin_tablet"}
                  placeholder={"0"}
                  type={"decimal"}
                  {...formik.getFieldProps("primaquin_tablet")}
                />
                <div className="warning-message" style={{ marginTop: "12px" }}>
                  <div className="message">
                    Primaquin tidak boleh diberikan kepada ibu hamil, menyusui,
                    dan anak di bawah usia 6 bulan
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-sm-6">
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "#313131",
                  }}
                >
                  Artesunat Injeksi (Vial)
                </p>
              </div>
              <div className="col-sm-6">
                <InputField
                  name={"artesunat_injeksi"}
                  placeholder={"0"}
                  type={"decimal"}
                  {...formik.getFieldProps("artesunat_injeksi")}
                />
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-sm-6">
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "#313131",
                  }}
                >
                  Kina Tablet (Tablet)
                </p>
              </div>
              <div className="col-sm-6">
                <InputField
                  name={"kina_tablet"}
                  placeholder={"0"}
                  type={"decimal"}
                  {...formik.getFieldProps("kina_tablet")}
                />
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-sm-6">
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "#313131",
                  }}
                >
                  Kina Injeksi (Ampul)
                </p>
              </div>
              <div className="col-sm-6">
                <InputField
                  name={"kina_injeksi"}
                  placeholder={"0"}
                  type={"decimal"}
                  {...formik.getFieldProps("kina_injeksi")}
                />
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-sm-6">
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "#313131",
                  }}
                >
                  Dosisiklin (Tablet)
                </p>
              </div>
              <div className="col-sm-6">
                <InputField
                  name={"doksisiklin"}
                  placeholder={"0"}
                  type={"decimal"}
                  {...formik.getFieldProps("doksisiklin")}
                />
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-sm-6">
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "#313131",
                  }}
                >
                  Tetrasiklin (Tablet)
                </p>
              </div>
              <div className="col-sm-6">
                <InputField
                  name={"tetrasiklin"}
                  placeholder={"0"}
                  type={"decimal"}
                  {...formik.getFieldProps("tetrasiklin")}
                />
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-sm-6">
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "#313131",
                  }}
                >
                  Klindamisin (Tablet)
                </p>
              </div>
              <div className="col-sm-6">
                <InputField
                  name={"Klindamisin"}
                  placeholder={"0"}
                  type={"decimal"}
                  {...formik.getFieldProps("klindamisin")}
                />
              </div>
            </div>
            <hr />
            <InputField
              label={"Standar Pengobatan"}
              name={"standard_medication_status"}
              placeholder={"Masukkan Standar Pengobatan"}
              disabled={true}
              value={formik.values.standard_medication_status}
              {...formik.getFieldProps("standard_medication_status")}
            />
            <InputSelect
              label={"Perawatan"}
              name={"treatment"}
              placeholder={"Pilih Perawatan"}
              data={TreatmentOptions}
              onChange={(e) => {
                const selectedValue = e ? e.value : "";
                formik.handleChange("treatment")(selectedValue);
              }}
              onBlur={formik.handleBlur}
              value={TreatmentOptions?.find(
                (item) => item.value === formik.values.treatment
              )}
              error={formik.errors.treatment && formik.touched.treatment}
              errorMessage={formik.errors.treatment}
              isRequired={true}
            />
            <InputRadio
              label="Apakah Kematian dengan Malaria?"
              name="death_with_malaria"
              options={YesAndNoOptions}
              onChange={(e) => {
                const selectedValue = e.target.value;
                formik.handleChange("death_with_malaria")(selectedValue);
              }}
              onBlur={formik.handleBlur}
              selectedOption={formik.values.death_with_malaria}
              error={
                formik.errors.death_with_malaria &&
                formik.touched.death_with_malaria
              }
              errorMessage={formik.errors.death_with_malaria}
              isRequired={true}
            />
          </>
        )}
        <hr />

        <div className="form_action">
          <Button
            onClick={() => handlePageModeChange(PageMode.view)}
            variant={"tertiary"}
            size={"normal"}
          >
            Kembali
          </Button>
          {isArrive ? (
            <Button
              variant={"primary"}
              size={"normal"}
              isDisabled={
                formik.isSubmitting ||
                !formik.values.ref_patient_arrival_date ||
                !formik.values.is_lab_rechecking ||
                (formik.values.sympthomatic !== "Tidak bergejala" &&
                  formik.values.sympthomatic &&
                  !formik.values.sympthomatic_date) ||
                !formik.values.treatment ||
                !formik.values.death_with_malaria
              }
              onClick={formik.handleSubmit}
            >
              Simpan Data
            </Button>
          ) : (
            <Button
              variant={"primary"}
              size={"normal"}
              onClick={formik.handleSubmit}
            >
              Simpan Data
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default FormComponent;
