import { errHandler } from "utils/common";
import api from "utils/api";

export default class Laporan {
  ZeroReport = (params) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/laporan/zero-report`, { params: params })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };
  Regmal1 = (params) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/laporan/regmal1`, { params: params })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };
  Regmal1Excel = (params) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/laporan/regmal1/excel`, {
          params: params,
          responseType: "blob",
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };
  RekapRegmal2 = (params) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/laporan/rekap-regmal2`, { params: params })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };
  RekapRegmalRujukan = (params) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/laporan/rujukan`, { params: params })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  Regmal2 = (params) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/laporan/regmal2`, { params: params })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  MalariaKIA = (params) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/laporan/malaria-kia`, { params: params })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };
  TarikData1 = (params) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/laporan/tarik-data1`, { params: params })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };
  TarikData1Public = (params) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/laporan/tarik-data1-public`, { params: params })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };
  TarikData1Private = (params) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/laporan/tarik-data1-private`, { params: params })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  LogistikED = (params) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/laporan/logistic-ed`, { params: params })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  KasusPE = (params) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/laporan/pe`, { params: params })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  // regmal 2 public
  Regmal2Public = (params) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/laporan/regmal2-public`, { params: params })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  // regmal 2 private
  Regmal2Private = (params) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/laporan/regmal2-private`, { params: params })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  LogistikStokOut = (params) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/laporan/logistic-stock-out`, { params: params })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  LogistikStok = (params) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/laporan/logistic-stock`, { params: params })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  DataReseptifitas = (params) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/laporan/reseptifitas`, { params: params })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  LogistikStokUse = (params) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/laporan/logistic-use`, { params: params })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  IdentifikasiJentik = (params) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/laporan/vektor-jentik`, { params: params })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  IdentifikasiNyamuk = (params) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/laporan/vektor-nyamuk`, { params: params })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  KelengkapanPerBulan = (params) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/laporan/completness-report`, { params: params })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  PengendalianVektor = (params) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/laporan/pengendalian-vektor`, { params: params })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  DataDanMonitoringKader = (params) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/laporan/cadre`, { params: params })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  LaporanKinerjaKader = (params) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/laporan/cadre-performance`, { params: params })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  LaporanKinerjaKaderMMP = (params) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/laporan/cadre-performance-mmp`, { params: params })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  MMPDanMonitoringKaderMMP = (params) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/laporan/cadre-mmp`, { params: params })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  DataDasarPenduduk = (params) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/laporan/population`, { params: params })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  ValidasiKasusIndigenous = (params) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/laporan/validasi-indigenous`, { params: params })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };
}
