import React, { Component } from "react";
import { ErrorMessage, PageMode } from "utils/constant";
import Table from "./components/Table";
import Form from "./components/Form";
import LogisticReqStock from "services/LogisticRequestStock";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import { ArrowLeft } from "react-feather";
import Datetime from "react-datetime";
import { getCurrentUserFromLocalStorage } from "utils/common";

const LogisticReqStockService = new LogisticReqStock();

const logisticOpt = [
  { value: "DHP Dispersible", label: "DHP Dispersible" },
  { value: "DHP", label: "DHP" },
  { value: "Primaquin", label: "Primaquin" },
  { value: "Artesunat Inj", label: "Artesunat Inj" },
  { value: "Kina tablet", label: "Kina tablet" },
  { value: "Kina Inj", label: "Kina Inj" },
  { value: "Doksisiklin", label: "Doksisiklin" },
  { value: "RDT", label: "RDT" },
  { value: "LLIN", label: "LLIN" },
  { value: "Insektisida", label: "Insektisida" },
  { value: "Larvasida", label: "Larvasida" },
  { value: "Lab Kit", label: "Lab Kit" },
  { value: "Giemsa", label: "Giemsa" },
  { value: "Minyak Imersi", label: "Minyak Imersi" },
  { value: "Blood Slide", label: "Blood Slide" },
  { value: "Slide Box", label: "Slide Box" },
  { value: "Blood Lancet", label: "Blood Lancet" },
  { value: "Methanol", label: "Methanol" },
  { value: "Spray Can", label: "Spray Can" },
  { value: "Mist Blower", label: "Mist Blower" },
  {
    value: "Microscope Binocular",
    label: "Microscope Binocular",
  },
  {
    value: "Microscope Trinocular",
    label: "Microscope Trinocular",
  },
  {
    value: "Microscope Teaching",
    label: "Microscope Teaching",
  },
];

export default class KabupatenRequestStock extends Component {
  _isMounted = true;

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      limit: 10,
      data: [],
      mode: PageMode.view,
      loading: true,
      search: "",
      selecetdItem: null,
      currentPage: 1,
      level: "District",

      selected_year: "",
      selected_month: "",
      selected_request_type: "",
      selected_logistic: "",
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.getAllReqStocks();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  changeMode = (mode) => {
    this.setState({ mode }, () => {
      if (mode === PageMode.view) this.getAllReqStocks();
    });
  };

  getAllReqStocks = async () => {

    const currentUser = getCurrentUserFromLocalStorage();

    await LogisticReqStockService.getAll({
      page: this.state.page,
      limit: this.state.limit,
      searchBy: "originator_id,year_submission,request_type,logistic_type",
      searchValue: `${currentUser.id},${this.state.selected_year},${this.state.selected_request_type},${this.state.selected_logistic}`,
    })
      .then((data) => {
        if (this._isMounted) {
          this.setState({ loading: false, data });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
        });
      });
  };

  onFilterData = () => {

    const currentUser = getCurrentUserFromLocalStorage();

    this.setState({ loading: true, selectedItem: null }, async () => {
      await LogisticReqStockService.getAll({
        page: this.state.page,
        limit: this.state.limit,
        searchBy: "year_submission,request_type,logistic_type,originator_id",
        searchValue: `${this.state.selected_year},${this.state.selected_request_type},${this.state.selected_logistic},${currentUser.id}`,
      })
        .then((data) => {
          if (this._isMounted) {
            this.setState({ loading: false, data });
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({ loading: false });
        });
    });
  };

  getFile = (id, name) => {
    this.setState({ loading: true }, async () => {
      await LogisticReqStockService.getFile(id)
        .then((data) => {
          if (this._isMounted) {
            this.setState({ loading: false });
            const file = new Blob([data], { type: "application/pdf", name });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({ loading: false });
        });
    });
  };

  getLetterFile = (id, name) => {
    this.setState({ loading: true }, async () => {
      await LogisticReqStockService.getFullfillFile(id)
        .then((data) => {
          if (this._isMounted) {
            this.setState({ loading: false });
            const file = new Blob([data], { type: "application/pdf", name });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({ loading: false });
        });
    });
  };

  onEdit = (id) => {
    this.setState({ selectedItem: id }, () => {
      this.changeMode(PageMode.edit);
    });
  };

  onRemove = async (id) => {
    confirmAlert({
      title: "Anda yakin ingin Menghapus data?",
      message: "Are you sure want to delete this data?",
      buttons: [
        {
          label: "Ya",
          onClick: () => {
            LogisticReqStockService.remove(id)
              .then((res) => {
                this.getAllReqStocks();
                toast.success("Data Permintaan Berhasil Dihapus", {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              })
              .catch((err) => {
                console.log(err);

                if (err.resonse.data.message === ErrorMessage.UserNotVerified) {
                  toast.error("Akun anda belum diverifikasi", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                }

                if (
                  err.response.data.message ===
                  ErrorMessage.UserFaskesNotHaveAccess
                ) {
                  toast.error("Akun anda tidak memiliki akses", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                }

              });
          },
        },
        {
          label: "Tidak",
        },
      ],
    });
  };

  onPageChange = (page) => {
    this.setState({ page }, () => {
      this.getAllReqStocks();
    });
  };

  onSearch = (e) => {
    const search = e.target.value;
    this.setState({ search }, () => {
      this.getAllReqStocks();
    });
  };

  render() {
    const { data, mode, loading, page, selectedItem } = this.state;
    return (
      <div className="dashboard-wrapper">
        {mode !== PageMode.view && (
          <div
            className="my-3"
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.changeMode(PageMode.view);
            }}
          >
            <ArrowLeft />
            <span>Kembali</span>
          </div>
        )}

        <h1 className="title font-weight-bold">
          Input Logistik - Input Permintaan Kabupaten
        </h1>
        <div className="content-wrapper mt-4">
          {mode === PageMode.view && (
            <>
              <div className="filter-data">
                <div className="row justify-content-start py-3">
                  <div className="col-sm-6">
                    <form className="form-horizontal">
                      <div className="form-group row justify-content-start">
                        <label className="col-sm-3 col-form-label align-self-center">
                          Tahun
                        </label>
                        <div className="col-sm-4 align-self-center">
                          <Datetime
                            dateFormat="YYYY"
                            timeFormat={false}
                            closeOnClickOutside={true}
                            closeOnSelect={true}
                            onChange={(e) =>
                              this.setState({
                                selected_year: moment(e).format("YYYY"),
                              })
                            }
                            value={this.state.selected_year}
                            inputProps={{
                              name: "filter_year",
                              placeholder: "Pilih Tahun",
                              autoComplete: "off",
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group row justify-content-start">
                        <label className="col-sm-3 col-form-label align-self-center">
                          Jenis Permintaan
                        </label>
                        <div className="col-sm-4 align-self-center">
                          <select
                            name="filter_request_type"
                            id="filter_request_type"
                            className="form-control"
                            onChange={(e) =>
                              this.setState({
                                selected_request_type: e.target.value,
                              })
                            }
                            value={this.state.selected_request_type}
                          >
                            <option value="" disabled selected>
                              Pilih Jenis Permintaan
                            </option>
                            <option value="Tahunan">Tahunan</option>
                            <option value="Insidentil">Insidentil</option>
                          </select>
                        </div>
                      </div>
                      <div className="form-group row justify-content-start">
                        <label className="col-sm-3 col-form-label align-self-center">
                          Jenis Logistik
                        </label>
                        <div className="col-sm-4 align-self-center">
                          <select
                            name="filter_logistic_type"
                            id="filter_logistic_type"
                            className="form-control"
                            onChange={(e) =>
                              this.setState({
                                selected_logistic: e.target.value,
                              })
                            }
                            value={this.state.selected_logistic}
                          >
                            <option value="" disabled selected>
                              Pilih Logistik
                            </option>
                            {logisticOpt.map((item) => (
                              <option key={item.label} value={item.value}>
                                {item.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="form-group row justify-content-start">
                        <div className="col-sm-7">
                          <button
                            className="btn btn-teal btn-block"
                            onClick={(e) => {
                              e.preventDefault();
                              this.onFilterData();
                            }}
                          >
                            Terapkan
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <Table
                data={data}
                loading={loading}
                currentPage={page}
                changeMode={this.changeMode}
                onEdit={this.onEdit}
                onRemove={this.onRemove}
                onSearch={this.onSearch}
                onPageChange={this.onPageChange}
                onGetFile={this.getFile}
                onGetLetter={this.getLetterFile}
              />
            </>
          )}
          {(mode === PageMode.add || mode === PageMode.edit) && (
            <Form
              mode={mode}
              changeMode={this.changeMode}
              requestStock={selectedItem}
            />
          )}
        </div>
      </div>
    );
  }
}
