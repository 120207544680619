/* eslint-disable indent */
import React, { Component } from "react";
import { Route } from "react-router-dom";
import { getCurrentUserFromLocalStorage } from "utils/common";
import { UserLevel } from "utils/constant";
import { Footer, Header, Sidebar } from "../../components";
import {
  CadreMonitoring,
  Dashboard,
  PeCases,
  RegmalSecondComponent,
  User,
  UserVerification,
} from "../../pages";

import DataDasarPenduduk from "pages/DataDasarPenduduk";
import DataEndemisitas from "pages/DataEndemisitas";
import CadrePerformance from "pages/DataKasus/CadreMonitoring/CadrePerformance";
import ExpiredStock from "pages/LogistikFaskes/ExpiredStock";
import InputStock from "pages/LogistikFaskes/InputStock";
import OffStock from "pages/LogistikFaskes/OffStock";
import RequestRecap from "pages/LogistikFaskes/RequestRecap";
import RequestStock from "pages/LogistikFaskes/RequestStock";
import FasyankesRequestFulfillment from "pages/LogistikKabupaten/FasyankesRequestFulfillment";
import KabupatenExpiredStock from "pages/LogistikKabupaten/KabupatenExpiredStock";
import KabupatenInputStock from "pages/LogistikKabupaten/KabupatenInputStock";
import KabupatenRequestRecap from "pages/LogistikKabupaten/KabupatenRequestRecap";
import KabupatenRequestStock from "pages/LogistikKabupaten/KabupatenRequestStock";
import KabupatenRequestFulfillment from "pages/LogistikProvinsi/KabupatenRequestFulfillment";
import ProvinsiExpiredStock from "pages/LogistikProvinsi/ProvinsiExpiredStock";
import ProvinsiInputStock from "pages/LogistikProvinsi/ProvinsiInputStock";
import ProvinsiRequestStock from "pages/LogistikProvinsi/ProvinsiRequestStock";
import ProvinsiRequestFulfillment from "pages/LogistikPusat/ProvinsiRequestFulfillment";
import ProvinsiRequestRecap from "pages/LogistikProvinsi/ProvinsiRequestRecap";
import ZeroReporting from "pages/DataKasus/ZeroReporting";
import DataDasarFaskesPage from "pages/DataDasarFaskes";
import IndigenousValidation from "pages/IndigenousValidation";
import IndigenousFeedback from "pages/IndigenousFeedback";
import UploadSyncForm from "pages/UploadSync";
import Pelapor from "pages/Repoter";
import IndigenousDetail from "pages/IndigenousValidation/components/Detail";
import MalariaScreening from "pages/DataKasus/MalariaScreening";
import AchievedData from "pages/DataKasus/MalariaScreening/AchievmentData";
import DataCapaianRumahSakit from "pages/DataKasus/DataCapaianRumahSakit";
import FlickIdentification from "pages/Vektor/FlickIdentification";
import LarvaBreedingIntervention from "pages/Vektor/FlickIdentification/LarvaBreedingIntervention";
import MoquitoIdentificationData from "pages/Vektor/MoquitoIdentificationData";
import IRSResultReport from "pages/Vektor/IRSResultReport";
import LaporanPage from "pages/Laporan";

// Page Analisis
import AnalisisPage from "pages/Analisis";
import DataPenduduk from "pages/DataPenduduk";
import DetailLaporan from "pages/Laporan/components/DetailLaporan";
import MMP from "pages/MMP";
import CadreMMPPerformance from "pages/MMP/CadreMMP/CadrePerformance";

import RegisterMalaria1 from "pages/DataKasus/RegisterMalaria1";
import DetailRegmal1 from "pages/DataKasus/RegisterMalaria1/components/Detail";
import RegisterMalaria1Rujukan from "pages/DataKasus/RegisterMalaria1Rujukan";
import DetailRujukanPage from "pages/DataKasus/RegisterMalaria1Rujukan/DetailRujukanPage";
import NotifikasiKasusImpor from "pages/NotifikasiKasusImpor";
import NotifikasiKasusImporDetail from "pages/NotifikasiKasusImpor/components/DetailComponent";
import NotifikasiPEFeedback from "pages/DataKasus/NotifikasiPEFeedback";

// Page Laporan
import LZeroReport from "pages/Laporan/ZeroReport";
import LRegmal1 from "pages/Laporan/Regmal1";
import LRegmal2 from "pages/Laporan/Regmal2";
import LRekapRegmal2 from "pages/Laporan/RekapRegmal2";
import LMalariaKIA from "pages/Laporan/MalariaKIA";
import LTarikData1 from "pages/Laporan/TarikData1";
import LTarikData1Public from "pages/Laporan/TarikData1Public";
import LTarikData1Private from "pages/Laporan/TarikData1Private";
import LogistikStok from "pages/Laporan/LogistikStok";
import LKasusPE from "pages/Laporan/KasusPE";
import LRegmal2Public from "pages/Laporan/Regmal2Public";
import LRegmal2Private from "pages/Laporan/Regmal2Private";
import LRegmalRujukan from "pages/Laporan/RegmalRujukan";
import LogistikPemakaian from "pages/Laporan/LogistikPemakaian";
import LDataReseptifitas from "pages/Laporan/DataReseptifitas";
import LLogistikStok from "pages/Laporan/LogistikStok";
import LLogistikStokOut from "pages/Laporan/LogistikStokOut";
import LLogistikED from "pages/Laporan/LogistikStokED";
import LIdentifikasiJentik from "pages/Laporan/IdentifikasiJentik";
import LIdentifikasiNyamuk from "pages/Laporan/IdentifikasiNyamuk";
import LPengendalianVektor from "pages/Laporan/PengendalianVektor";
import LKelengkapanPublik from "pages/Laporan/KelengkapanPublik";
import LKelengkapanPrivate from "pages/Laporan/KelengkapanPrivate";
import LDataDanMonitoringKader from "pages/Laporan/DataDanMonitoringKader";
import LKinerjaKader from "pages/Laporan/KinerjaKader";
import LDataMMPDanaKinerjaKaderMMP from "pages/Laporan/MMPDanMonitoringKaderMMP";
import LaporanDataDasarPenduduk from "pages/Laporan/DataDasarPenduduk";
import LaporanKinerjaKaderMMP from "pages/Laporan/KinerjaKaderMMP";
import LaporanValidasiKasusIndigenous from "pages/Laporan/ValidasiKasusIndigenous";
import LaporanKelengkapanNasional from "pages/Laporan/KelengkapanNasional";

class MainApp extends Component {
  render() {
    const currentUser = getCurrentUserFromLocalStorage();

    return (
      <div className="wrapper">
        <Header />
        <Sidebar />
        <div className="main-panel">
          <div className="container">
            <div
              className="page-inner"
              style={{
                padding: "24px",
              }}
            >
              <Route path="/" component={Dashboard} exact />
              <Route path="/analisis" component={AnalisisPage} />

              {currentUser && currentUser.role !== "Viewer" && (
                <>
                  <Route path="/laporan" component={LaporanPage} exact />
                  <Route
                    path="/laporan/detail/:module"
                    component={DetailLaporan}
                  />

                  <Route path="/laporan/zero-report" component={LZeroReport} />
                  <Route path="/laporan/regmal1" component={LRegmal1} />
                  <Route path="/laporan/regmal2" component={LRegmal2} />
                  <Route
                    path="/laporan/rekap-regmal2"
                    component={LRekapRegmal2}
                  />
                  <Route path="/laporan/malaria-kia" component={LMalariaKIA} />
                  <Route path="/laporan/tarik-data1" component={LTarikData1} />
                  <Route
                    path="/laporan/tarik-data1-publik"
                    component={LTarikData1Public}
                  />
                  <Route
                    path="/laporan/tarik-data1-private"
                    component={LTarikData1Private}
                  />
                  <Route
                    path="/laporan/logistik-pemakaian"
                    component={LogistikPemakaian}
                  />
                  <Route path="/laporan/kasus-pe" component={LKasusPE} />
                  <Route
                    path="/laporan/regmal2-public"
                    component={LRegmal2Public}
                  />
                  <Route
                    path="/laporan/regmal2-private"
                    component={LRegmal2Private}
                  />
                  <Route path="/laporan/rujukan" component={LRegmalRujukan} />

                  {/* Laporan data reseptifitas */}
                  <Route
                    path="/laporan/reseptifitas"
                    component={LDataReseptifitas}
                  />

                  {/* Laporan logistik stok out */}
                  <Route
                    path="/laporan/logistik-stok-out"
                    component={LLogistikStokOut}
                  />
                  {/* Laporan logistik ED */}
                  <Route path="/laporan/logistik-ed" component={LLogistikED} />
                  <Route
                    path="/laporan/logistik-stok"
                    component={LLogistikStok}
                  />

                  {/* identifikasi jentik */}
                  <Route
                    path="/laporan/identifikasi-jentik"
                    component={LIdentifikasiJentik}
                  />

                  {/* identifikasi nyamuk */}
                  <Route
                    path="/laporan/identifikasi-nyamuk"
                    component={LIdentifikasiNyamuk}
                  />

                  {/* pengendalian vektor */}
                  <Route
                    path="/laporan/pengendalian-vektor"
                    component={LPengendalianVektor}
                  />

                  {/* kelengkapan per bulan publik */}
                  <Route
                    path="/laporan/kelengkapan-perbulan-publik"
                    component={LKelengkapanPublik}
                  />
                  {/* kelengkapan per bulan private */}

                  <Route
                    path="/laporan/kelengkapan-perbulan-private"
                    component={LKelengkapanPrivate}
                  />

                  {/* kelengkapan laporan nasional */}
                  <Route
                    path="/laporan/kelengkapan-perbulan-nasional"
                    component={LaporanKelengkapanNasional}
                  />

                  {/* laporan data dan monitoring kader */}
                  <Route
                    path="/laporan/data-dan-monitoring-kader"
                    component={LDataDanMonitoringKader}
                  />
                  <Route
                    path="/laporan/kinerja-kader"
                    component={LKinerjaKader}
                  />
                  <Route
                    path="/laporan/kinerja-kader-mmp"
                    component={LaporanKinerjaKaderMMP}
                  />
                  <Route
                    path="/laporan/mmp-dan-monitoring-kader-mmp"
                    component={LDataMMPDanaKinerjaKaderMMP}
                  />

                  <Route
                    path="/laporan/data-dasar-penduduk"
                    component={LaporanDataDasarPenduduk}
                  />

                  <Route
                    path="/laporan/validasi-kasus-indigenous"
                    component={LaporanValidasiKasusIndigenous}
                  />

                  <Route path="/data-penduduk" component={DataPenduduk} />

                  {currentUser && currentUser.level === UserLevel.Fasyankes && (
                    <>
                      {currentUser.fasyankes_type_id === "jnsfas-01" && (
                        <>
                          <Route path="/mmp" component={MMP} />
                          <Route
                            path="/cadre-mmp-performance/:id"
                            component={CadreMMPPerformance}
                          />
                          <Route path="/pe-kasus" component={PeCases} />
                          <Route
                            path="/monitoring-kader"
                            component={CadreMonitoring}
                          />
                          <Route
                            path="/kinerja-kader/:id"
                            component={CadrePerformance}
                          />
                          <Route
                            path="/skrining-malaria"
                            component={MalariaScreening}
                          />
                          <Route
                            path="/data-capaian/:id"
                            component={AchievedData}
                          />
                        </>
                      )}

                      <Route
                        path="/notifikasi-pe-feedback"
                        component={NotifikasiPEFeedback}
                      />

                      {currentUser.fasyankes_type_id !== "jnsfas-01" && (
                        <Route
                          path="/data-capaian"
                          component={DataCapaianRumahSakit}
                        />
                      )}

                      <Route
                        path="/logistik-input-stok"
                        component={InputStock}
                      />
                      <Route path="/logistik-putus-stok" component={OffStock} />
                      <Route
                        path="/logistik-expired-stok"
                        component={ExpiredStock}
                      />
                      <Route
                        path="/logistik-input-permintaan"
                        component={RequestStock}
                      />
                      <Route
                        path="/logistik-rekap-permintaan"
                        component={RequestRecap}
                      />
                      <Route
                        path="/data-dasar-penduduk"
                        component={DataDasarPenduduk}
                      />

                      <Route
                        path="/register-malaria-1"
                        component={RegisterMalaria1}
                      />
                      <Route
                        path="/detail-register-malaria-1/:id"
                        component={DetailRegmal1}
                      />
                      <Route
                        path="/regmal-dua"
                        component={RegmalSecondComponent}
                      />
                      <Route
                        path="/regmal-rujukan"
                        component={RegisterMalaria1Rujukan}
                      />
                      {/* <Route path="/detail-rujukan/:id" component={DetailRegmal} /> */}
                      <Route
                        path="/detail-rujukan/:id"
                        component={DetailRujukanPage}
                      />
                      <Route path="/zero-report" component={ZeroReporting} />

                      {currentUser &&
                        currentUser.fasyankes_type_id === "jnsfas-01" && (
                          <>
                            <Route
                              path="/upload-sync"
                              component={UploadSyncForm}
                            />
                            <Route
                              path="/umpan-balik-indigenous"
                              component={IndigenousFeedback}
                            />
                          </>
                        )}

                      <Route path="/data-pelapor" component={Pelapor} />
                      <Route
                        path="/data-identifikasi-jentik"
                        component={FlickIdentification}
                      />
                      <Route
                        path="/data-intervensi-perindukan-nyamuk/:id"
                        component={LarvaBreedingIntervention}
                      />
                      <Route
                        path="/data-identifikasi-nyamuk"
                        component={MoquitoIdentificationData}
                      />
                      <Route
                        path="/laporan-pengendalian-vektor"
                        component={IRSResultReport}
                      />
                    </>
                  )}

                  {currentUser && currentUser.level === UserLevel.District && (
                    <>
                      <Route
                        path="/data-dasar-faskes"
                        component={DataDasarFaskesPage}
                      />
                      <Route path="/data-pelapor" component={Pelapor} />
                      <Route
                        path="/validasi-indigenous"
                        component={IndigenousValidation}
                      />
                      <Route
                        path="/detail-indigenous/:id"
                        component={IndigenousDetail}
                      />
                      <Route
                        path="/logistik-kabupaten-input-stok"
                        component={KabupatenInputStock}
                      />
                      <Route
                        path="/logistik-kabupaten-expired-stok"
                        component={KabupatenExpiredStock}
                      />
                      <Route
                        path="/logistik-kabupaten-input-permintaan"
                        component={KabupatenRequestStock}
                      />
                      <Route
                        path="/logistik-kabupaten-rekap-permintaan"
                        component={KabupatenRequestRecap}
                      />
                      <Route
                        path="/logistik-pemenuhan-permintaan-faskes"
                        component={FasyankesRequestFulfillment}
                      />
                      <Route
                        path="/user-verification"
                        component={UserVerification}
                      />
                      <Route
                        path="/notifikasi-kasus-impor"
                        component={NotifikasiKasusImpor}
                      />
                      <Route
                        path="/notifikasi-kasus-impor-detail/:id"
                        component={NotifikasiKasusImporDetail}
                      />
                    </>
                  )}

                  {currentUser && currentUser.level === UserLevel.Province && (
                    <>
                      <Route path="/data-pelapor" component={Pelapor} />
                      <Route
                        path="/logistik-provinsi-input-stok"
                        component={ProvinsiInputStock}
                      />
                      <Route
                        path="/logistik-provinsi-expired-stok"
                        component={ProvinsiExpiredStock}
                      />
                      <Route
                        path="/logistik-provinsi-input-permintaan"
                        component={ProvinsiRequestStock}
                      />
                      <Route
                        path="/logistik-provinsi-rekap-permintaan"
                        component={ProvinsiRequestRecap}
                      />
                      <Route
                        path="/logistik-pemenuhan-permintaan-kabupaten"
                        component={KabupatenRequestFulfillment}
                      />
                    </>
                  )}

                  {currentUser && currentUser.level === UserLevel.National && (
                    <>
                      <Route
                        path="/data-dasar-penduduk"
                        component={DataDasarPenduduk}
                      />
                      <Route
                        path="/data-endemisitas"
                        component={DataEndemisitas}
                      />
                      <Route
                        path="/logistik-pemenuhan-permintaan-provinsi"
                        component={ProvinsiRequestFulfillment}
                      />
                      <Route path="/user" component={User} />
                      <Route
                        path="/user-verification"
                        component={UserVerification}
                      />
                    </>
                  )}
                </>
              )}
              {/* not found page */}
              {/* <Route path="*" component={NotFoundPage} /> */}
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default MainApp;
