import React, { Component } from "react";
import { ErrorMessage, PageMode, UserLevel } from "utils/constant";
import Table from "./components/Table";
import Form from "./components/Form";

import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import { ArrowLeft } from "react-feather";
import Reporter from "services/Reporter";
import InputDateTime from "components/InputDateTime";
import { Button } from "components";
import { connect } from "react-redux";

const ReporterService = new Reporter();

class Pelapor extends Component {
  _isMounted = true;

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      limit: 10,
      data: [],
      mode: PageMode.view,
      loading: true,
      search: "",
      selecetdItem: null,
      currentPage: 1,
      year: "",
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.getAllReporters();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  changeMode = (mode) => {
    this.setState({ mode }, () => {
      if (mode === PageMode.view) this.getAllReporters();
    });
  };

  handleInputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  getAllReporters = () => {
    this.setState({ loading: true, selectedItem: null }, async () => {
      const { year, page, limit } = this.state;
      const { user } = this.props;

      let searchBy = "";
      let searchValue = "";
      switch (user.level) {
        case UserLevel.Fasyankes:
          searchBy += "fasyankes_id,level";
          searchValue += `${user.fasyankes_id},${user.level}`;
          break;
        case UserLevel.District:
          searchBy += "district_id,level";
          searchValue += `${user.district_id},${user.level}`;
          break;
        case UserLevel.Province:
          searchBy += "province_id,level";
          searchValue += `${user.province_id},${user.level}`;
          break;
      }

      await ReporterService.getAll({
        page,
        limit,
        searchBy: `${searchBy}${year !== "" ? `,year` : ""}`,
        searchValue: `${searchValue}${year !== "" ? `,${year}` : ""}`,
      })
        .then((data) => {
          if (this._isMounted) {
            this.setState({ loading: false, data });
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({ loading: false });
        });
    });
  };

  onEdit = (item) => {
    this.setState({ selecetdItem: item }, () => {
      this.changeMode(PageMode.edit);
    });
  };

  onRemove = async (id) => {
    confirmAlert({
      title: "Anda yakin ingin Menghapus data?",
      message: "Are you sure want to delete this data?",
      buttons: [
        {
          label: "Ya",
          onClick: async () => {
            await ReporterService.remove(id)
              .then((res) => {
                this.getAllReporters();
                toast.success("Data Zero Report Berhasil Dihapus", {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              })
              .catch((err) => {
                console.log(err);

                if (err.resonse.data.message === ErrorMessage.UserNotVerified) {
                  toast.error("Akun anda belum diverifikasi", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                }

                if (
                  err.response.data.message ===
                  ErrorMessage.UserFaskesNotHaveAccess
                ) {
                  toast.error("Akun anda tidak memiliki akses", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                }

              });
          },
        },
        {
          label: "Tidak",
        },
      ],
    });
  };

  onPageChange = (page) => {
    this.setState({ page }, () => {
      this.getAllReporters();
    });
  };

  onSearch = (e) => {
    const search = e.target.value;
    this.setState({ search }, () => {
      this.getAllReporters();
    });
  };

  onFilter = () => {
    this.getAllReporters();
  };

  render() {
    const { data, mode, loading, page } = this.state;
    return (
      <div className="dashboard-wrapper">
        {mode !== PageMode.view && (
          <div
            className="my-3"
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.changeMode(PageMode.view);
            }}
          >
            <ArrowLeft />
            <span>Kembali</span>
          </div>
        )}

        <h1 className="title font-weight-bold">Data Pelapor</h1>
        <div className="content-wrapper mt-4">
          {mode === PageMode.view && (
            <>
              <div className="row">
                <div className="col">
                  <div className="form-inline">
                    <InputDateTime
                      name="year"
                      dateFormat="YYYY"
                      onChange={(e) =>
                        this.handleInputChange({
                          preventDefault: () => {},
                          target: {
                            name: "year",
                            value: moment(e).format("YYYY"),
                          },
                        })
                      }
                      placeholder="Pilih Tahun"
                      value={this.state.year}
                    />

                    <div className="form-group d-flex">
                      <Button onClick={this.onFilter} isPrimary>
                        Terapkan
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <Table
                user={this.props.user}
                data={data}
                loading={loading}
                currentPage={page}
                changeMode={this.changeMode}
                onEdit={this.onEdit}
                onRemove={this.onRemove}
                onSearch={this.onSearch}
                onPageChange={this.onPageChange}
              />
            </>
          )}
          {(mode === PageMode.add || mode === PageMode.edit) && (
            <Form
              mode={mode}
              changeMode={this.changeMode}
              data={this.state.selecetdItem}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authReducer.currentUser,
  };
};

export default connect(mapStateToProps)(Pelapor);
