import React from 'react';

export default function SidebarWrapper({children}) {
  return (
    <div className="sidebar sidebar-style-2">
      <div className="sidebar-wrapper scrollbar scrollbar-inner">
        <div className="sidebar-content">
          {children}
        </div>
      </div>
    </div>
  );
}
