import React, { Component } from "react";
import { AlertTriangle } from "react-feather";
import { PageMode } from "../../../utils/constant";
import Table from "./components/Table";
import Form from "./components/Form";
import PeFokus from "../../../services/PeFokus";
import PeCases from "../../../services/PeKasus";

const PeCasesServices = new PeCases();
const PEFokusServices = new PeFokus();

export default class PeFocus extends Component {
  _isMounted = true;

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      limit: 10,
      data: [],
      mode: PageMode.view,
      loading: true,
      selectedItem: null,
      currentPage: 1,
      peCases: [],
      id: "",
      search: ""
    };
  }

  componentDidMount = () => {
    this._isMounted = true;
    this.getAllPeFocus();
    this.getPeKasus();
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  changeMode = (mode) => {
    this.setState({ mode }, () => {
      if (mode === PageMode.view) this.getAllPeFocus();
    });
  };

  getAllPeFocus = () => {
    this.setState({ loading: true, selectedItem: null }, async () => {
      await PEFokusServices.getAll({
        page: this.state.page,
        limit: this.state.limit,
        search: this.state.search,
      })
        .then((res) => {
          if (this._isMounted) {
            this.setState({ loading: false, data: res });
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({ loading: false });
        });
    });
  };

  getPeKasus = () => {
    this.setState({ loading: true, selectedItem: null }, async () => {
      await PeCasesServices.getAll({
        page: this.state.page,
        limit: this.state.limit,
        search: this.state.search
      })
        .then((res) => {
          if (this._isMounted) {
            this.setState({ loading: false, peCases: res.pe_cases });
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({ loading: false });
        });
    });
  };

  onEdit = (dataset) => {
    this.setState({ selectedItem: dataset }, () => {
      this.changeMode(PageMode.edit);
    });
  };

  onRemove = async (id) => {
    if (window.confirm(`Yakin Ingin Menghapus Data ?`)) {
      await PEFokusServices.remove(id)
        .then((res) => {
          this.props.changeMode("view");
          this.setState({
            id: id
          });
        })
        .catch((err) => {
          let error = "error_administrator_text";
          if (err && err.response && err.response.data) {
            const validation = errValidation(err.response.data);
            if (validation.includes("violates-key-constraint")) {
              error = "this data is using in another table";
            }
          }

          window.alert(error);
        });
    }
  };
  onSearch = (e) => {
    const search = e.target.value;
    this.setState({ search }, () => {
      this.getAllPeFocus();
    });
  };

  onPageChange = (page, limit) => {
    this.setState({ page }, () => {
      this.getAllPeFocus();
    });
  };

  render() {
    const {
      data,
      mode,
      loading,
      page,
      peCases,
      selectedItem
    } = this.state;
    
    return (
      <div className="dashboard-wrapper">
        <div className="title">Data Penyelidikan Epidemiologi Fokus</div>
        <div className="alert-warning p-2">
          <AlertTriangle /> Pengisian data hanya dilakukan sekali, jika
            terjadi kesalahan pengisian atau kesalahan data maka lakukan edit
            data dan tidak mengisikan data yang sama agar tidak terjadi
            redudansi data.
        </div>
        <div className="content-wrapper mt-4">
          {mode === PageMode.view && (
            <Table
              data={data}
              loading={loading}
              currentPage={page}
              onEdit={this.onEdit}
              onSearch={this.onSearch}
              changeMode={this.changeMode}
              onPageChange={this.onPageChange}
            />
          )}
          {(mode === PageMode.add || mode === PageMode.edit) && (
            <Form
              mode={mode}
              changeMode={this.changeMode}
              peCase={peCases}
              peFocus={selectedItem}
              onRemove={this.onRemove}
            />
          )}
        </div>
      </div>
    );
  }
}
