import { flexRender } from "@tanstack/react-table";
import React from "react";
import "./TableData.scss";
import {
  FiChevronLeft,
  FiChevronRight,
  FiChevronsLeft,
  FiChevronsRight,
} from "react-icons/fi";
import { HashLoader } from "react-spinners";

const TableDataComponent = ({
  tableInstance,
  loading,
  onPageChange,
  currentPage,
  nextPage,
  limit,
  previousPage,
  total,
  columnLength,
}) => {
  const totalPage = Math.ceil(total / limit);
  
  return (
    <>
      <div className="table-responsive">
        <table>
          <thead>
            {tableInstance.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={columnLength}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <HashLoader color="#36d7b7" size={100} />
                  </div>
                </td>
              </tr>
            ) : tableInstance.getRowModel().rows.length === 0 ? (
              <tr>
                <td colSpan={columnLength}>
                  <p style={{ textAlign: "center" }}>
                    Tidak ada data yang tersedia.
                  </p>
                </td>
              </tr>
            ) : (
              tableInstance.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      {/* Pagination */}
      <div className="pagination-container">
        <div className="pagination-left">
          <p>Tampilkan</p>
          <select name="" id="" disabled>
            <option value="">10</option>
          </select>
          <p>dari {total} data</p>
        </div>
        <div className="pagination-right">
          <button onClick={() => onPageChange(1)}>
            <FiChevronsLeft />
          </button>
          <button
            onClick={() => onPageChange(previousPage)}
            disabled={!previousPage}
          >
            <FiChevronLeft />
          </button>
          <div className="info-page">
            <p>Halaman</p>
            <div className="active-page">
              <p>{currentPage}</p>
            </div>
            <p>Dari {totalPage} Halaman</p>
          </div>
          <button onClick={() => onPageChange(nextPage)} disabled={!nextPage}>
            <FiChevronRight />
          </button>
          <button>
            <FiChevronsRight onClick={() => onPageChange(totalPage)} />
          </button>
        </div>
      </div>
    </>
  );
};

export default TableDataComponent;
