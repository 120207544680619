const dhpResult = (weight, dhp) => {
  weight = parseInt(weight);
  dhp = parseFloat(dhp);

  if (
    (weight <= 5 && dhp == 1) ||
    (weight > 5 && weight <= 6 && dhp == 1.5) ||
    (weight > 6 && weight <= 10 && dhp == 1.5) ||
    (weight > 10 && weight <= 17 && dhp == 3.0) ||
    (weight > 17 && weight <= 30 && dhp == 4.5) ||
    (weight > 30 && weight <= 40 && dhp == 6.0) ||
    (weight > 40 && weight <= 60 && dhp == 9.0) ||
    (weight > 60 && weight <= 80 && dhp == 12.0) ||
    (weight > 80 && dhp == 15.0)
  ) {
    return true;
  } else {
    return false;
  }
};

const primaquineResult = (weight, primaquine, val) => {
  weight = parseInt(weight);
  primaquine = parseFloat(primaquine);

  if (
    (weight <= 5 && primaquine === val[0]) ||
    (weight > 5 && weight <= 6 && primaquine == val[1]) ||
    (weight > 6 && weight <= 10 && primaquine == val[2]) ||
    (weight > 10 && weight <= 17 && primaquine == val[3]) ||
    (weight > 17 && weight <= 30 && primaquine == val[4]) ||
    (weight > 30 && weight <= 40 && primaquine == val[5]) ||
    (weight > 40 && weight <= 60 && primaquine == val[6]) ||
    (weight > 60 && weight <= 80 && primaquine == val[7]) ||
    (weight > 80 && primaquine == val[8])
  ) {
    return true;
  } else {
    return false;
  }
};

const kinaResult = (weight, kina) => {
  weight = parseInt(weight);
  kina = parseFloat(kina);

  if (
    (weight <= 5 && kina > 0 && kina < 10.5) ||
    (weight > 5 && weight <= 6 && kina == 10.5) ||
    (weight > 6 && weight <= 10 && kina == 10.5) ||
    (weight > 10 && weight <= 17 && kina == 21.0) ||
    (weight > 17 && weight <= 33 && kina == 31.5) ||
    (weight > 33 && weight <= 40 && kina == 42.0) ||
    (weight > 40 && weight <= 60 && kina == 52.5) ||
    (weight > 60 && kina == 63.0)
  ) {
    return true;
  } else {
    return false;
  }
};

const parasyteName = {
  falciparum: "P.Falciparum",
  malariae: "P.Malariae",
  probableKnowlesi: "Probable Knowlesi",
  vivax: "P.Vivax",
  ovale: "P.Ovale",
  mix: "P.Mix",
};

export { dhpResult, kinaResult, primaquineResult, parasyteName };
