import React from "react";
import { tableColumns } from "./constants";
import { keys } from "lodash";
import { monthConverter } from "utils/common";

const TableComponent = ({
  reportsData,
  currentLevel,
  tableRef,
  reportInfo,
}) => {
  const calculateTotal = () => {
    return reportsData.reduce(
      (total, item) => {
        total.total_malaria_unchecked +=
          parseInt(item?.total_malaria_unchecked) || 0;
        total.up5_jum_neg_mikros += parseInt(item?.up5_jum_neg_mikros) || 0;
        total.up5_jum_neg_rdt += parseInt(item?.up5_jum_neg_rdt) || 0;
        total.bw5_jum_neg_mikros += parseInt(item?.bw5_jum_neg_mikros) || 0;
        total.bw5_jum_neg_rdt += parseInt(item?.bw5_jum_neg_rdt) || 0;
        total.pos_mal += parseInt(item?.pos_mal) || 0;
        total.bw5_mikros_pe += parseInt(item?.bw5_mikros_pe) || 0;
        total.bw5_rdt_pe += parseInt(item?.bw5_rdt_pe) || 0;
        total.at5_mikros_pe += parseInt(item?.at5_mikros_pe) || 0;
        total.at5_rdt_pe += parseInt(item?.at5_rdt_pe) || 0;
        total.total_suspek +=
          parseInt(item?.up5_jum_neg_mikros ?? 0) +
          parseInt(item?.up5_jum_neg_rdt ?? 0) +
          parseInt(item?.bw5_jum_neg_mikros ?? 0) +
          parseInt(item?.bw5_jum_neg_rdt ?? 0) +
          parseInt(item?.pos_mal ?? 0) +
          parseInt(item?.bw5_mikros_pe ?? 0) +
          parseInt(item?.bw5_rdt_pe ?? 0) +
          parseInt(item?.at5_mikros_pe ?? 0) +
          parseInt(item?.at5_rdt_pe ?? 0);

        return total;
      },
      {
        total_malaria_unchecked: 0,
        up5_jum_neg_mikros: 0,
        up5_jum_neg_rdt: 0,
        bw5_jum_neg_mikros: 0,
        bw5_jum_neg_rdt: 0,
        pos_mal: 0,
        bw5_mikros_pe: 0,
        bw5_rdt_pe: 0,
        at5_mikros_pe: 0,
        at5_rdt_pe: 0,
        total_suspek: 0,
      }
    );
  };

  const total = calculateTotal();

  console.log(total);

  let levelName = "";
  if (currentLevel === "national") {
    levelName = "Nasional";
  } else if (currentLevel === "province") {
    levelName = "Provinsi";
  } else if (currentLevel === "district") {
    levelName = "Kabupaten/Kota";
  } else {
    levelName = "Fasyankes";
  }

  let periodeName = "";
  if (reportInfo?.statusPeriode === "yearly") {
    periodeName = "Tahunan";
  } else {
    periodeName = "Bulanan";
  }

  let data = reportInfo?.fasyankes;
  let parts = data?.split(",");
  let faskesName = parts?.map(function (part) {
    return part?.split("|")[1];
  });
  let result = faskesName?.join(", ");
  
  const renderItemValue = (value) => {
    if (typeof(value) === "object" && value?.length > 0) {
      return `${value}`.replaceAll(",", ", ");
    }
    return value;
  };

  const getFieldValue = (data, filedKey) => {

    keys = filedKey.split(".");
    if (keys.length > 1) {
      let dataValue = data;
      for (let e of keys) {
        dataValue = dataValue[e];
      }
      return dataValue;
    }
    return data[filedKey];
  };

  return (
    <div
      className="table-responsive"
      style={{ height: "560px", overflow: "scroll" }}
      ref={tableRef}
    >
      <table>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none" }}>
            <h3>SISMAL v3, REGMAL 1 RUJUKAN</h3>
          </th>
        </tr>
      </table>
      <table>
        <thead>
          <tr style={{ border: "none" }}>
            <th
              style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}
              width="200"
            >
              Periode Laporan
            </th>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              : {periodeName}
            </th>
          </tr>
          <tr style={{ border: "none" }}>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              Tahun
            </th>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              : {reportInfo?.periode?.year}
            </th>
          </tr>
          {reportInfo?.periode?.month ? (
            <tr style={{ border: "none" }}>
              <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
                Bulan
              </th>
              <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
                : {monthConverter(reportInfo?.periode?.month)}
              </th>
            </tr>
          ) : (
            <></>
          )}
          <tr style={{ border: "none" }}>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              Level
            </th>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              : {levelName}
            </th>
          </tr>
          <tr style={{ border: "none" }}>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              Provinsi
            </th>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              :{" "}
              {!reportInfo?.province
                ? "Semua Provinsi"
                : reportInfo?.province?.split("|")[1]}
            </th>
          </tr>
          <tr style={{ border: "none" }}>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              Kab/Kota
            </th>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              :{" "}
              {!reportInfo?.district
                ? "Semua Kab/Kota"
                : reportInfo?.district?.split("|")[1]}
            </th>
          </tr>
          <tr style={{ border: "none" }}>
            <th style={{ border: "none", paddingTop: 0 }}>Fasyankes</th>
            <th style={{ border: "none", paddingTop: 0 }}>
              : {!reportInfo?.fasyankes ? "Semua Fasyankes" : result}
            </th>
          </tr>
        </thead>
      </table>
      <table>
        <thead>
          <tr>
            <th>No.</th>
            {Object.keys(tableColumns[currentLevel]).map((key) => (
              <th key={tableColumns[currentLevel][key]}>{key}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {reportsData ? (
            reportsData?.map((item, index) => (
              <tr key={item?.id}>
                <td>{index + 1}</td>
                {Object.keys(tableColumns[currentLevel]).map((key) => (
                  <td key={tableColumns[currentLevel][key]}>
                    {renderItemValue(
                      getFieldValue(item, tableColumns[currentLevel][key])
                    )}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={13}>Tidak Ada Data</td>
            </tr>
          )}
          {currentLevel !== "fasyankes" ? (
            <tr style={{ backgroundColor: "#43766C" }}>
              {reportsData ? (
                <td
                  style={{ color: "#fff" }}
                  colSpan={
                    Object.keys(tableColumns[currentLevel]).filter((key) =>
                      [
                        "Provinsi Rujukan",
                        "Kab/Kota Rujukan",
                        "Faskes Rujukan",
                      ].includes(key)
                    ).length + 1
                  }
                >
                  Total
                </td>
              ) : (
                <></>
              )}
              {reportsData ? (
                Object.keys(tableColumns[currentLevel])
                  .filter(
                    (key) =>
                      ![
                        "Provinsi Rujukan",
                        "Kab/Kota Rujukan",
                        "Faskes Rujukan",
                      ].includes(key)
                  )
                  .map((key) => (
                    <td key={key} style={{ color: "#fff" }}>
                      {reportsData.reduce(
                        (prev, curr) =>
                          prev +
                          parseInt(curr[tableColumns[currentLevel][key]]),
                        0
                      )}
                    </td>
                  ))
              ) : (
                <></>
              )}
            </tr>
          ) : (
            <></>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TableComponent;
