import React, { Component, Fragment } from "react";
import User from "../../services/User";
import { PageMode } from "../../utils/constant";
import Table from "./components/Table";
import Form from "./components/Form";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import { isEmpty } from "utils/common";

const UserService = new User();

class UsersPage extends Component {
  _isMounted = true;
  state = {
    page: 1,
    limit: 10,
    data: [],
    mode: PageMode.view,
    loading: true,
    search: "",
    total: "",
    selectedItem: null,
    currentPage: 1,

    level: "",
    role: "",
  };

  componentDidMount() {
    this._isMounted = true;
    this.getAllUsers();
  }

  handleInputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  getAllUsers = async () => {
    const { page, limit, search, level, role } = this.state;
    let searchBy = [];
    let searchValue = [];

    if (!isEmpty(search)) {
      searchBy.push("first_name");
      searchValue.push(search);
    }
    if (!isEmpty(level)) {
      searchBy.push("level");
      searchValue.push(level);
    }
    if (!isEmpty(role)) {
      searchBy.push("role");
      searchValue.push(role);
    }

    await UserService.getAll({
      page,
      limit,
      searchBy: searchBy.join(),
      searchValue: searchValue.join(),
    })
      .then((data) => {
        if (this._isMounted) {
          this.setState({
            loading: false,
            data,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
        });
      });
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  changeMode = (mode) => {
    this.setState({ mode }, () => {
      if (mode === PageMode.view) this.getAllUsers();
    });
  };

  onEdit = (user) => {
    this.setState({ selectedItem: user }, () => {
      this.changeMode(PageMode.edit);
    });
  };

  onRemove = async (id) => {
    confirmAlert({
      title: "Anda yakin ingin menghapus data ini?",
      message: "Are you sure want to delete this data?",
      buttons: [
        {
          label: "Ya",
          onClick: () => {
            UserService.remove(id)
              .then((res) => {
                this.getAllUsers();
                toast.success("Data Pengguna Berhasil Dihapus", {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              })
              .catch((err) => {
                console.log(err);
              });
          },
        },
        {
          label: "Tidak",
        },
      ],
    });
  };

  onPageChange = (page) => {
    this.setState({ page }, () => {
      this.getAllUsers();
    });
  };

  onSearch = (e) => {
    const search = e.target.value;
    this.setState({ search }, () => {
      this.getAllUsers();
    });
  };

  onFilter = () => {
    this.getAllUsers();
  };

  render() {
    const { data, mode, loading, selectedItem, page } = this.state;

    return (
      <Fragment>
        {mode === PageMode.view && (
          <Table
            data={data}
            loading={loading}
            onEdit={this.onEdit}
            onRemove={this.onRemove}
            onSearch={this.onSearch}
            changeMode={this.changeMode}
            currentPage={page}
            onPageChange={this.onPageChange}
            handleInputChange={this.handleInputChange}
            values={this.state}
            onFilter={this.onFilter}
          />
        )}
        {(mode === PageMode.add || mode === PageMode.edit) && (
          <Form mode={mode} changeMode={this.changeMode} data={selectedItem} />
        )}
      </Fragment>
    );
  }
}

export default UsersPage;
