import { Button, InputSelect, TableComponent } from "components";
import React, { Component } from "react";
import { PlusCircle } from "react-feather";
import { PageMode, UserLevel, UserRoles } from "utils/constant";

class Table extends Component {
  render() {
    const {
      data,
      changeMode,
      loading,
      onSearch,
      onEdit,
      onRemove,
      onPageChange,
      handleInputChange,
      values,
      onFilter
    } = this.props;

    return (
      <div className="dashboard-wrapper">
        <h1 className="title font-weight-bold">Manajemen Data Pengguna</h1>
        <div className="py-3">
          <div className="row">
            <div className="col">
              <div className="form-inline">
                <InputSelect
                  label="Level"
                  name="level"
                  placeholder="Pilih"
                  data={[
                    { value: UserLevel.Fasyankes, label: UserLevel.Fasyankes },
                    { value: UserLevel.District, label: UserLevel.District },
                    { value: UserLevel.Province, label: UserLevel.Province },
                    { value: UserLevel.National, label: UserLevel.National },
                  ]}
                  onChange={handleInputChange}
                  value={values.level}
                  isInline={true}
                />
                <InputSelect
                  label="Hak Akses"
                  name="role"
                  placeholder="Pilih"
                  data={[
                    { value: UserRoles.Admin, label: "Administrator" },
                    { value: UserRoles.User, label: "Pengguna" },
                    { value: "Viewer", label: "Tamu" },
                  ]}
                  onChange={handleInputChange}
                  value={values.role}
                  isInline={true}
                />
                <div className="form-group d-flex justify-content-end">
                  <Button onClick={onFilter} isPrimary>
                    Terapkan
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control bg-light"
                  placeholder="Cari berdasarkan nama"
                  onChange={(e) => onSearch(e)}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-8" style={{ padding: "10px" }}>
              <button
                className="btn btn-teal font-weight-bold"
                onClick={() => changeMode(PageMode.add)}
              >
                <PlusCircle className="mr-2" />
                Tambah Data
              </button>
            </div>
          </div>
          <TableComponent
            loading={loading}
            data={data?.users}
            headers={[
              "Nama Pengguna",
              "Username",
              "Level",
              "Hak Akses",
              "Status",
            ]}
            cols={["first_name", "username", "level", "role", "is_active"]}
            btnEdit={{
              className: "btn btn-outline-primary btn-sm font-weight-bold mr-3",
              btnName: "Edit",
              onClick: onEdit,
            }}
            btnRemove={{
              className: "btn btn-outline-danger btn-sm font-weight-bold",
              btnName: "Hapus",
              onClick: onRemove,
            }}
            currentPage={data?.currentPage}
            total={data?.total}
            limit={data?.limit}
            nextPage={data?.nextPage}
            previousPage={data?.previousPag}
            onPageChange={onPageChange}
          />
        </div>
      </div>
    );
  }
}

export default Table;
