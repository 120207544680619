import React from "react";
import { monthConverter } from "utils/common";
import { UserLevel } from "utils/constant";

const TableComponent = ({
  currentLevel,
  reportsData,
  tableRef,
  reportInfo,
}) => {
  // const calculateTotal = () => {
  //   if (
  //     currentLevel === UserLevel.National.toLowerCase() ||
  //     currentLevel === UserLevel.Province.toLocaleLowerCase()
  //   ) {
  //     return reportsData.reduce(
  //       (total, item) => {
  //         total.total_faskes += parseInt(item.total_faskes) || 0;
  //         total.total_faskes_zero_report +=
  //           parseInt(item.total_faskes_zero_report) || 0;

  //         return total;
  //       },
  //       {
  //         total_faskes: 0,
  //         total_faskes_zero_report: 0,
  //       }
  //     );
  //   } else {
  //     return reportsData.reduce(
  //       (total, item) =>
  //         total + (item.is_zero_reporting?.toLowerCase() === "ya" ? 1 : 0),
  //       0
  //     );
  //   }
  // };

  const renderTableByLevel = () => {
    switch (currentLevel) {
      case UserLevel.National.toLowerCase():
        return (
          <>
            <thead>
              <tr>
                <th rowSpan={3}>No.</th>
                <th rowSpan={3}>Provinsi</th>
                <th rowSpan={3}>Kabupaten/Kota</th>
                <th rowSpan={3}>Jumlah Desa</th>
                <th rowSpan={3}>Jumlah Desa Dilakukan IRS</th>
                <th rowSpan={3}>Jumlah Desa Dibagikan Kelambu</th>
                <th colSpan={8}>
                  Jumlah Insektisida Yang Digunakan Berdasarkan Jenisnya
                </th>
                <th rowSpan={3}>Jumlah Total Sasaran Rumah</th>
                <th rowSpan={3}>Jumlah Rumah Yang di Semprot</th>
                <th rowSpan={3}>Jumlah Target Pembagian Kelambu</th>
                <th rowSpan={3}>Jumlah Kelambu Yang Dibagikan</th>
              </tr>
              <tr>
                <th colSpan={2}>Organophospate</th>
                <th colSpan={2}>Karbamat</th>
                <th colSpan={2}>Piretroid</th>
                <th colSpan={2}>Neonikotinoid</th>
              </tr>
              <tr>
                {/* Organosphate */}
                <th>Kg</th>
                <th>Liter</th>
                {/* Karbamat */}
                <th>Kg</th>
                <th>Liter</th>
                {/* Piretroid */}
                <th>Kg</th>
                <th>Liter</th>
                {/* Neonikotinoid */}
                <th>Kg</th>
                <th>Liter</th>
              </tr>
            </thead>
            <tbody>
              {reportsData?.length > 0 ? (
                reportsData?.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item?.province}</td>
                    <td>{item?.district}</td>
                    <td>{item?.jml_desa}</td>
                    <td>{item?.jml_desa_irs}</td>
                    <td>{item?.jml_desa_dibagikan_kelambu}</td>
                    <td>{item?.kg_organophospate}</td>
                    <td>{item?.liter_organophospate}</td>
                    <td>{item?.kg_karbamat}</td>
                    <td>{item?.liter_karbamat}</td>
                    <td>{item?.kg_piretroid}</td>
                    <td>{item?.liter_piretroid}</td>
                    <td>{item?.kg_neonikotinoid}</td>
                    <td>{item?.liter_neonikotinoid}</td>
                    <td>{item?.jml_sasaran_rumah}</td>
                    <td>{item?.jml_rumah_disemprot}</td>
                    <td>{item?.jml_target_pembagian_kelambu}</td>
                    <td>{item?.jml_kelambu_dibagikan}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <div className="alert alert-danger">Tidak ada data</div>
                </tr>
              )}
            </tbody>
          </>
        );
      case UserLevel.Province.toLowerCase():
        return (
          <>
            <thead>
              <tr>
                <th rowSpan={3}>No.</th>
                <th rowSpan={3}>Kabupaten/Kota</th>
                <th rowSpan={3}>Jumlah Desa</th>
                <th rowSpan={3}>Jumlah Desa Dilakukan IRS</th>
                <th rowSpan={3}>Jumlah Desa Dibagikan Kelambu</th>
                <th colSpan={8}>
                  Jumlah Insektisida Yang Digunakan Berdasarkan Jenisnya
                </th>
                <th rowSpan={3}>Jumlah Total Sasaran Rumah</th>
                <th rowSpan={3}>Jumlah Rumah Yang di Semprot</th>
                <th rowSpan={3}>Jumlah Target Pembagian Kelambu</th>
                <th rowSpan={3}>Jumlah Kelambu Yang Dibagikan</th>
              </tr>
              <tr>
                <th colSpan={2}>Organophospate</th>
                <th colSpan={2}>Karbamat</th>
                <th colSpan={2}>Piretroid</th>
                <th colSpan={2}>Neonikotinoid</th>
              </tr>
              <tr>
                {/* Organosphate */}
                <th>Kg</th>
                <th>Liter</th>
                {/* Karbamat */}
                <th>Kg</th>
                <th>Liter</th>
                {/* Piretroid */}
                <th>Kg</th>
                <th>Liter</th>
                {/* Neonikotinoid */}
                <th>Kg</th>
                <th>Liter</th>
              </tr>
            </thead>
            <tbody>
              {reportsData?.length > 0 ? (
                reportsData?.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item?.district}</td>
                    <td>{item?.jml_desa}</td>
                    <td>{item?.jml_desa_irs}</td>
                    <td>{item?.jml_desa_dibagikan_kelambu}</td>
                    <td>{item?.kg_organophospate}</td>
                    <td>{item?.liter_organophospate}</td>
                    <td>{item?.kg_karbamat}</td>
                    <td>{item?.liter_karbamat}</td>
                    <td>{item?.kg_piretroid}</td>
                    <td>{item?.liter_piretroid}</td>
                    <td>{item?.kg_neonikotinoid}</td>
                    <td>{item?.liter_neonikotinoid}</td>
                    <td>{item?.jml_sasaran_rumah}</td>
                    <td>{item?.jml_rumah_disemprot}</td>
                    <td>{item?.jml_target_pembagian_kelambu}</td>
                    <td>{item?.jml_kelambu_dibagikan}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <div className="alert alert-danger">Tidak ada data</div>
                </tr>
              )}
            </tbody>
          </>
        );
      case UserLevel.District.toLowerCase():
        return (
          <>
            <thead>
              <tr>
                <th rowSpan={3}>No.</th>
                <th rowSpan={3}>Fasyankes</th>
                <th rowSpan={3}>Jumlah Desa</th>
                <th rowSpan={3}>Jumlah Desa Dilakukan IRS</th>
                <th rowSpan={3}>Jumlah Desa Dibagikan Kelambu</th>
                <th colSpan={8}>
                  Jumlah Insektisida Yang Digunakan Berdasarkan Jenisnya
                </th>
                <th rowSpan={3}>Jumlah Total Sasaran Rumah</th>
                <th rowSpan={3}>Jumlah Rumah Yang di Semprot</th>
                <th rowSpan={3}>Jumlah Target Pembagian Kelambu</th>
                <th rowSpan={3}>Jumlah Kelambu Yang Dibagikan</th>
              </tr>
              <tr>
                <th colSpan={2}>Organophospate</th>
                <th colSpan={2}>Karbamat</th>
                <th colSpan={2}>Piretroid</th>
                <th colSpan={2}>Neonikotinoid</th>
              </tr>
              <tr>
                {/* Organosphate */}
                <th>Kg</th>
                <th>Liter</th>
                {/* Karbamat */}
                <th>Kg</th>
                <th>Liter</th>
                {/* Piretroid */}
                <th>Kg</th>
                <th>Liter</th>
                {/* Neonikotinoid */}
                <th>Kg</th>
                <th>Liter</th>
              </tr>
            </thead>
            <tbody>
              {reportsData?.length > 0 ? (
                reportsData?.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item?.fasyankes}</td>
                    <td>{item?.jml_desa}</td>
                    <td>{item?.jml_desa_irs}</td>
                    <td>{item?.jml_desa_dibagikan_kelambu}</td>
                    <td>{item?.kg_organophospate}</td>
                    <td>{item?.liter_organophospate}</td>
                    <td>{item?.kg_karbamat}</td>
                    <td>{item?.liter_karbamat}</td>
                    <td>{item?.kg_piretroid}</td>
                    <td>{item?.liter_piretroid}</td>
                    <td>{item?.kg_neonikotinoid}</td>
                    <td>{item?.liter_neonikotinoid}</td>
                    <td>{item?.jml_sasaran_rumah}</td>
                    <td>{item?.jml_rumah_disemprot}</td>
                    <td>{item?.jml_target_pembagian_kelambu}</td>
                    <td>{item?.jml_kelambu_dibagikan}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <div className="alert alert-danger">Tidak ada data</div>
                </tr>
              )}
            </tbody>
          </>
        );
      default:
        return (
          <>
            <thead>
              <tr>
                <th>No.</th>
                <th>Nama Desa</th>
                <th>Nama Dusun</th>
                <th>Jumlah Penduduk di Dusun Tersebut</th>
                <th>Jenis Pengendalian Vektor</th>
                <th>Tanggal IRS</th>
                <th>Jenis Insektisida</th>
                <th>Jumlah Insektisida Yang Digunakan</th>
                <th>Satuan Insektisida</th>
                <th>Jumlah Total Sasaran Rumah</th>
                <th>Jumlah Rumah Yang di Semprot</th>
                <th>Tanggal Distribusi Kelambu</th>
                <th>Jumlah Target Pembagian Kelambu</th>
                <th>Jumlah Kelambu Yang Dibagikan</th>
              </tr>
            </thead>
            <tbody>
              {reportsData?.length > 0 ? (
                reportsData?.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item?.desa}</td>
                    <td>{item?.dusun}</td>
                    <td>{item?.jml_penduduk_dusun}</td>
                    <td>{item?.jenis_pengendalian_vector}</td>
                    <td>{item?.tanggal_irs}</td>
                    <td>{item?.jenis_insektisida}</td>
                    <td>{item?.jml_insektisida}</td>
                    <td>{item?.satuan_insektisida}</td>
                    <td>{item?.jml_sasaran_rumah}</td>
                    <td>{item?.jml_rumah_disemprot}</td>
                    <td>{item?.tgl_distribusi_kelambu}</td>
                    <td>{item?.jml_target_pembagian_kelambu}</td>
                    <td>{item?.jml_kelambu_dibagikan}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <div className="alert alert-danger">Tidak ada data</div>
                </tr>
              )}
            </tbody>
          </>
        );
    }
  };

  let levelName = "";
  if (currentLevel === "national") {
    levelName = "Nasional";
  } else if (currentLevel === "province") {
    levelName = "Provinsi";
  } else if (currentLevel === "district") {
    levelName = "Kabupaten/Kota";
  } else {
    levelName = "Fasyankes";
  }

  let periodeName = "";
  if (reportInfo?.statusPeriode === "yearly") {
    periodeName = "Tahunan";
  } else {
    periodeName = "Bulanan";
  }

  let data = reportInfo?.fasyankes;
  let parts = data?.split(",");
  let faskesName = parts?.map(function (part) {
    return part?.split("|")[1];
  });
  let result = faskesName?.join(", ");

  return (
    <div
      className="table-table-responsive"
      style={{ height: "560px", overflow: "scroll" }}
      ref={tableRef}
    >
      <table>
        <tr style={{ border: "none", padding: 0 }}>
          <th style={{ border: "none", paddingBottom: "0" }}>
            <h3>SISMAL v3, PENGENDALIAN VEKTOR</h3>
          </th>
        </tr>
      </table>
      <table>
        <tr style={{ border: "none" }}>
          <th
            width="200"
            style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}
          >
            Periode Laporan
          </th>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            : {periodeName}
          </th>
        </tr>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            Tahun
          </th>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            : {reportInfo?.periode?.year}
          </th>
        </tr>
        {reportInfo?.statusPeriode === "monthly" && (
          <tr style={{ border: "none" }}>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              Bulan
            </th>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              : {monthConverter(reportInfo?.periode?.month)}
            </th>
          </tr>
        )}
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            Level
          </th>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            : {levelName}
          </th>
        </tr>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            Provinsi
          </th>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            :{" "}
            {!reportInfo?.province
              ? "Semua Provinsi"
              : reportInfo?.province?.split("|")[1]}
          </th>
        </tr>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            Kab/Kota
          </th>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            :{" "}
            {!reportInfo?.district
              ? "Semua Kab/Kota"
              : reportInfo?.district?.split("|")[1]}
          </th>
        </tr>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingTop: 0 }}>Fasyankes</th>
          <th style={{ border: "none", paddingTop: 0 }}>
            : {!reportInfo?.fasyankes ? "Semua Fasyankes" : result}
          </th>
        </tr>
      </table>
      <table>{renderTableByLevel()}</table>
    </div>
  );
};

export default TableComponent;
