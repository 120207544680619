import React, { Component } from "react";
import { TableComponent } from "components";

export default class Table extends Component {
  render() {
    const { data, loading, onEdit, onGetFile, onGetLetter } = this.props;
    return (
      <TableComponent
        loading={loading}
        data={data?.logistic_request_stocks}
        headers={[
          "Status",
          "Provinsi",
          "Kabupaten",
          "Kecamatan",
          "Fasyankes",
          "Nomor Dokumen/Surat (SBBK/BAST)",
          "Jenis Permintaan",
          "Jenis Logistik",
          "Jumlah yang diminta",
          "Satuan",
          "Surat Permintaan",
          "Jumlah yang dipenuhi",
          "Satuan",
          "Surat Pemenuhan",
        ]}
        cols={[
          "status",
          ["province_id", "|", 1],
          ["district_id", "|", 1],
          ["subdistrict_id", "|", 1],
          ["fasyankes_id", "|", 1],
          "letter_number",
          "request_type",
          "logistic_type",
          "request_total",
          "unit",
          "letter_upload",
          "fulfilled_total",
          "fulfilled_unit",
          "fulfilled_letter_upload",
        ]}
        btnEdit={{ onClick: onEdit }}
        currentPage={data?.currentPage}
        total={data?.total}
        limit={data?.limit}
        nextPage={data?.nextPage}
        prevPage={data?.prevPage}
        onPageChange={this.props.onPageChange}
        onGetFile={onGetFile}
        onGetLetter={onGetLetter}
      />
    );
  }
}
