import { errHandler } from "utils/common";
import api from "utils/api";

export default class Populasi {
  getAll = (opt = { page: 1, limit: 10, search: null }) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/user_verifications`, { params: opt })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  getNotVerified = (opt = { page: 1, limit: 10, year }) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/user_verifications/not_verified`, { params: opt })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  getOne = (id) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/user_verification/${id}`)
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  add = (payload) => {
    return new Promise((resolve, reject) => {
      api
        .post("/api/user_verification?device=Online", payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  update = (payload, id) => {
    return new Promise((resolve, reject) => {
      api
        .patch(`/api/user_verification/${id}?device=Online`, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  remove = (id) => {
    return new Promise((resolve, reject) => {
      api
        .delete(`/api/user_verification/${id}?device=Online`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };
}
