import {
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import ActionButton from "components/atoms/ActionButton";
import InputField from "components/atoms/InputField";
import TableDataComponent from "components/organisms/TableData";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import KasusImpor from "services/KasusImpor";
import { useHistory } from "react-router-dom";
import { MonthOptions, PageMode } from "utils/constant";
import {
  removeDataFromLocalStorage,
  setDataToLocalStorage,
} from "utils/common";
import Button from "components/atoms/Button";
import * as XLSX from "xlsx";
import InputSelect from "components/atoms/InputSelect";
import InputDate from "components/atoms/InputDate";

const KasusImporService = new KasusImpor();

const TableComponent = ({ changeMode }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [dataState, setDataState] = useState({
    page: 1,
    limit: 10,
    data: [],
    totalData: 0,
    currentPage: 1,
    nextPage: null,
    previousPage: null,
  });
  const [filterState, setFilterState] = useState({
    selected_year: "",
    selected_month: "",
  });

  const { currentUser } = useSelector((state) => state.authReducer);
  const history = useHistory();
  const tableRef = useRef(null);

  const fetchKasusImporData = async () => {
    setIsLoading(true);
    try {
      let searchBy = `case_classification`;
      let searchValue = `Import|Import Relaps`;

      const searchByDate = filterState?.selected_year && filterState?.selected_month ? "|YYYY-MM"
        : filterState?.selected_year ? "|YYYY" : filterState?.selected_month ? "|MM" : "";
      const searchValueDate = filterState?.selected_year && filterState?.selected_month
        ? `,${filterState?.selected_year}-${filterState?.selected_month}`
        : filterState?.selected_year ? `,${filterState?.selected_year}`
          : filterState?.selected_month ? `,${filterState?.selected_month}` : "";

      if (filterState.selected_year || filterState.selected_month) {
        searchBy += `,patient_visite_date${searchByDate}`;
        searchValue += searchValueDate;
      }

      const response = await KasusImporService.getAll({
        district_id: currentUser?.district_id?.split("|")[0],
        page: dataState.page,
        limit: dataState.limit,
        searchBy,
        searchValue,
        search: searchKeyword ?? "",
      });

      setDataState((prevState) => ({
        ...prevState,
        data: response.pe_case_import_case,
        limit: response.limit,
        currentPage: response.currentPage,
        totalData: response.total,
        nextPage: response.nextPage,
        previousPage: response.previousPage,
      }));
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAllKasusImporData = async () => {
    setIsLoading(true);
    try {
      // Assuming backend supports setting a high limit to fetch all data
      const response = await KasusImporService.getAll({
        district_id: currentUser?.district_id?.split("|")[0],
        page: 1,
        limit: 999999,
        searchBy: "case_classification",
        searchValue: "Import|Import Relaps",
        search: searchKeyword ?? "",
      });

      return response.pe_case_import_case;
    } catch (error) {
      console.error("Failed to fetch all data", error);
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    document.title = "SISMAL | Notifikasi Kasus Impor";
    removeDataFromLocalStorage("SELECTED_ITEM");
  }, []);

  useEffect(() => {
    fetchKasusImporData();
  }, [dataState.page, searchKeyword]);

  const onPageChange = (page) => {
    setDataState((prevState) => ({
      ...prevState,
      page: page,
    }));
  };

  const onDetailData = (data) => {
    setDataToLocalStorage("SELECTED_ITEM", JSON.stringify(data));
    changeMode(PageMode.detail);
  };

  const columns = [
    {
      header: "Aksi",
      accessorKey: "action",
      cell: ({ row }) => (
        <div className="button-actions">
          <ActionButton
            onClick={() => onDetailData(row.original)}
            btnType={"detail"}
          />
        </div>
      ),
    },
    {
      header: "Nama",
      accessorKey: "name",
    },
    {
      header: "Usia",
      accessorKey: "age",
      cell: ({ row }) => `${row.original.age} ${row.original.age_type}`,
    },
    {
      header: "Pekerjaan",
      accessorKey: "job",
    },
    // Pencatat
    {
      header: "Faskes Pencatat",
      accessorKey: "originator_fasyankes_name",
    },
    {
      header: "Kab Pencatat",
      accessorKey: "originator_district_name",
    },
    {
      header: "Provinsi Pencatat",
      accessorKey: "originator_province_name",
    },
    // Pelaku
    {
      header: "Faskes Yang Melakukan PE",
      accessorKey: "user_fasyankes",
    },
    {
      header: "Kab Yang Melakukan PE",
      accessorKey: "user_district",
    },
    {
      header: "Provinsi Yang Melakukan PE",
      accessorKey: "user_province",
    },
    {
      header: "Kegiatan Penemuan Kasus",
      accessorKey: "discovery_activities",
    },
    {
      header: "Tanggal Kunjungan Pasien",
      accessorKey: "patient_visite_date",
    },
    {
      header: "Tanggal Wawancara",
      accessorKey: "case_interview_date",
    },
    {
      header: "Jenis Parasit",
      accessorKey: "parasyte",
      cell: ({ row }) =>
        row.original.parasyte ? row.original.parasyte?.join(", ") : "-",
    },
    {
      header: "Kekambuhan",
      accessorKey: "recurrence",
    },
    {
      header: "Tanggal Kasus Awal",
      accessorKey: "initial_case_date",
    },
    {
      header: "Status Pengobatan",
      accessorKey: "standard_medication_status",
    },
    {
      header: "Kematian dengan Malaria",
      accessorKey: "death_with_malaria",
    },
    {
      header: "Penularan",
      accessorKey: "transmission",
    },
    {
      header: "Asal Penularan",
      accessorKey: "transmission_origin",
    },
    {
      header: "Provinsi Asal Penularan",
      accessorKey: "province_id",
      cell: ({ row }) => row.original.province_id && row.original.province_id?.split("|")[1],
    },
    {
      header: "Kabupaten/Kota Asal Penularan",
      accessorKey: "district_id",
      cell: ({ row }) => row.original.district_id && row.original.district_id?.split("|")[1],
    },
    {
      header: "Kecamatan Asal Penularan",
      accessorKey: "subdistrict_id",
      cell: ({ row }) => row.original.subdistrict_id && row.original.subdistrict_id?.split("|")[1],
    },
    {
      header: "Faskes Asal Penularan",
      accessorKey: "fasyankes_id",
      cell: ({ row }) => row.original.fasyankes_id && row.original.fasyankes_id?.split("|")[1],
    },
    {
      header: "Desa Asal Penularan",
      accessorKey: "urbanvillage_id",
      cell: ({ row }) => row.original.urbanvillage_id && row.original.urbanvillage_id?.split("|")[1],
    },
    {
      header: "Dusun Asal Penularan",
      accessorKey: "sub_village",
    },
    {
      header: "Alamat Lengkap",
      accessorKey: "address_of_infection",
    },
    {
      header: "No. Telepon Pasien",
      accessorKey: "phone_number",
    },
  ];

  const tableInstance = useReactTable({
    columns,
    data: dataState?.data,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const exportToExcel = async () => {
    const allData = await fetchAllKasusImporData();

    const exportData = [
      columns.map((col) => col.header),
      ...allData.map((row) => {
        return columns.map((col) => {
          if (col.accessorKey) {
            const value = row[col.accessorKey];

            if (col.accessorKey === "age") {
              return `${row.age} ${row.age_type}`;
            }
            if (col.accessorKey === "parasyte") {
              return row.parasyte ? row.parasyte.join(", ") : "-";
            }
            if (typeof value === "string") {
              return value.split("|")[1] || value;
            }
            return value ?? "-";
          }
          return "-";
        });
      }),
    ];

    const worksheet = XLSX.utils.aoa_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Kasus Impor Data");

    XLSX.writeFile(workbook, "Laporan_Notifikasi_Kasus_Impor_All.xlsx");
  };

  return (
    <>
      <h1 className="page-title">Notifikasi Kasus Impor</h1>
      <div className="card-filter">
        <div className="filter-item">
          <InputDate
            placeholder={"Pilih Tahun"}
            name={"selected_year"}
            dateFormat={"YYYY"}
            onChange={(e) => {
              if (e) {
                const selectedYear = moment(e).format("YYYY");
                setFilterState({
                  ...filterState,
                  selected_year: selectedYear,
                });
              } else {
                setFilterState({
                  ...filterState,
                  selected_year: "",
                });
              }
            }}
            value={filterState.selected_year}
          />
        </div>
        <div className="filter-item">
          <InputSelect
            placeholder={"Pilih Bulan"}
            data={MonthOptions}
            onChange={(e) => {
              const selectedValue = e ? e.value : "";
              setFilterState({
                ...filterState,
                selected_month: selectedValue,
              });
            }}
            value={MonthOptions.find(
              (item) => item.value === filterState.selected_month
            )}
          />
        </div>
        <Button
          variant={"primary"}
          size={"normal"}
          onClick={() => fetchKasusImporData()}
        >
          Terapkan
        </Button>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="search" style={{ width: "25rem" }}>
          <InputField
            placeholder={"Cari nama/faskes disini..."}
            onChange={(e) =>
              setSearchKeyword(
                e.target.value && e.target.value.length >= 3
                  ? e.target.value
                  : ""
              )
            }
          />
        </div>
        <Button
          variant={"secondary"}
          size={"normal"}
          style={{
            border: "1px solid #18B3AB",
          }}
          onClick={exportToExcel}
        >
          Export Excel
        </Button>
      </div>

      {/* table component here */}
      <TableDataComponent
        columnLength={columns.length}
        tableInstance={tableInstance}
        onPageChange={onPageChange}
        loading={isLoading}
        currentPage={dataState?.currentPage}
        limit={dataState?.limit}
        nextPage={dataState?.nextPage}
        previousPage={dataState?.previousPage}
        total={dataState?.totalData}
        tableRef={tableRef}
      />
    </>
  );
};

export default TableComponent;
