export const tableColumns = {
  fasyankes: {
    "Faskes Rujukan": "faskes_rujukan",
    "Nama Pasien": "nama_pasien",
    "Usia Pasien": "usia_pasien",
    "Satuan (Tahun/Bulan)": "usia_satuan",
    "Jenis Kelamin": "jenis_kelamin",
    "Faskes Asal": "faskes_asal",
    "Tanggal dirujuk": "tanggal_dirujuk",
    "Tanggal Pasien Rujukan Datang": "tanggal_pasien_rujukan_datang",
    "Pemeriksaan Lab di Fasyankes Rujukan": "pemeriksaan_lab_di_rujukan",
    "Jenis Pemeriksaan": "jenis_pemeriksaan",
    "Jumlah RDT terpakai": "jumlah_rdt_terpakai",
    "Tanggal Pemeriksaan Lab": "tanggal_pemeriksaan",
    "Jenis Parasit di Fasyankes Pencatat": "jenis_parasit_pencatat",
    "Jenis Parasit di Fasyankes Rujukan": "jenis_parasit_rujukan",
    "Derajat Penyakit Malaria": "drajat_penyakit_malaria",
    "Suhu tubuh (Celcius)": "suhu_tubuh",
    Gejala: "gejala",
    "Tanggal Gejala": "tanggal_gejala",
    Gametosit: "gametosit",
    "Kepadatan Parasit": "kepadatan_parasit",
    "Berat Badan (Kg)": "berat_badan",
    "Jenis Kekambuhan": "kekambuhan",
    "Tanggal Pengobatan": "tanggal_pengobatan",
    "DHP Tablet": "obat.dhp_tablet",
    "Primaquin Tablet": "obat.primaquin_tablet",
    "Kina Tablet": "obat.kina_tablet",
    "Artesunat Injeksi (Ampul)": "obat.artesunat_injeksi",
    "Kina Injeksi (Ampul)": "obat.kina_injeksi",
    "Doksisiklin (tablet)": "obat.doksisiklin",
    Klindamisin: "obat.klindamisin",
    "Status Pengobatan": "status_pengobatan",
    "Jenis Perawatan": "jenis_perawatan",
    "Kematian Dengan Malaria": "kematian_dengan_malaria",
  },
  province: {
    "Kab/Kota Rujukan": "kab_rujukan",
    "Jumlah Kasus Positif yang Dirujuk": "jml_kasus_positif_dirujuk",
    "Jumlah Kasus Positif yang Tidak Datang pada Faskes Rujukan":
      "jml_kasus_tidak_datang",
    "Jumlah Kasus Positif yang Datang pada Faskes Rujukan": "jml_kasus_datang",
    "Jumlah Pemeriksaan Lab di Fasyankes Rujukan": "jml_pemeriksaan_lab",
    "Jumlah Pemeriksaan Mikroskop": "jml_pemeriksaan_mikroskop",
    "Jumlah Pemeriksaan RDT": "jml_pemeriksaan_rdt",
    "Jumlah RDT terpakai": "jml_rdt_terpakai",
    "Jumlah Parasit P. falciparum": "falsiparum",
    "Jumlah Parasit P. vivax": "vivax",
    "Jumlah Parasit P. ovale": "ovale",
    "Jumlah Parasit P. malariae": "malariae",
    "Jumlah Parasit Probable knowlesi": "knowlesi",
    "Jumlah Parasit P. mix": "mix",
    "Jumlah Kasus Baru": "jml_kasus_baru",
    "Jumlah Kasus Relaps": "jml_kasus_relaps",
    "Jumlah Pengobatan Standar": "jml_pengobatan_standar",
    "Jumlah Pengobatan Tidak Standar": "jml_pengobatan_tidak_standar",
    "Jumlah Rawat Jalan": "jml_rawat_jalan",
    "Jumlah Rawat Inap": "jml_rawat_inap",
    "Jumlah Kematian Dengan Malaria": "jml_kematian_malaria",
  },
  district: {
    "Faskes Rujukan": "faskes_rujukan",
    "Jumlah Kasus Positif yang Dirujuk": "jml_kasus_positif_dirujuk",
    "Jumlah Kasus Positif yang Tidak Datang pada Faskes Rujukan":
      "jml_kasus_tidak_datang",
    "Jumlah Kasus Positif yang Datang pada Faskes Rujukan": "jml_kasus_datang",
    "Jumlah Pemeriksaan Lab di Fasyankes Rujukan": "jml_pemeriksaan_lab",
    "Jumlah Pemeriksaan Mikroskop": "jml_pemeriksaan_mikroskop",
    "Jumlah Pemeriksaan RDT": "jml_pemeriksaan_rdt",
    "Jumlah RDT terpakai": "jml_rdt_terpakai",
    "Jumlah Parasit P. falciparum": "falsiparum",
    "Jumlah Parasit P. vivax": "vivax",
    "Jumlah Parasit P. ovale": "ovale",
    "Jumlah Parasit P. malariae": "malariae",
    "Jumlah Parasit Probable knowlesi": "knowlesi",
    "Jumlah Parasit P. mix": "mix",
    "Jumlah Kasus Baru": "jml_kasus_baru",
    "Jumlah Kasus Relaps": "jml_kasus_relaps",
    "Jumlah Pengobatan Standar": "jml_pengobatan_standar",
    "Jumlah Pengobatan Tidak Standar": "jml_pengobatan_tidak_standar",
    "Jumlah Rawat Jalan": "jml_rawat_jalan",
    "Jumlah Rawat Inap": "jml_rawat_inap",
    "Jumlah Kematian Dengan Malaria": "jml_kematian_malaria",
  },
  national: {
    "Provinsi Rujukan": "prov_rujukan",
    "Kab/Kota Rujukan": "kab_rujukan",
    "Jumlah Kasus Positif yang Dirujuk": "jml_kasus_positif_dirujuk",
    "Jumlah Kasus Positif yang Tidak Datang pada Faskes Rujukan":
      "jml_kasus_tidak_datang",
    "Jumlah Kasus Positif yang Datang pada Faskes Rujukan": "jml_kasus_datang",
    "Jumlah Pemeriksaan Lab di Fasyankes Rujukan": "jml_pemeriksaan_lab",
    "Jumlah Pemeriksaan Mikroskop": "jml_pemeriksaan_mikroskop",
    "Jumlah Pemeriksaan RDT": "jml_pemeriksaan_rdt",
    "Jumlah RDT terpakai": "jml_rdt_terpakai",
    "Jumlah Parasit P. falciparum": "falsiparum",
    "Jumlah Parasit P. vivax": "vivax",
    "Jumlah Parasit P. ovale": "ovale",
    "Jumlah Parasit P. malariae": "malariae",
    "Jumlah Parasit Probable knowlesi": "knowlesi",
    "Jumlah Parasit P. mix": "mix",
    "Jumlah Kasus Baru": "jml_kasus_baru",
    "Jumlah Kasus Relaps": "jml_kasus_relaps",
    "Jumlah Pengobatan Standar": "jml_pengobatan_standar",
    "Jumlah Pengobatan Tidak Standar": "jml_pengobatan_tidak_standar",
    "Jumlah Rawat Jalan": "jml_rawat_jalan",
    "Jumlah Rawat Inap": "jml_rawat_inap",
    "Jumlah Kematian Dengan Malaria": "jml_kematian_malaria",
  },
};
