import api from "utils/api";
import { errHandler } from "utils/common";

export default class PEFeedback {
  getAll = (params) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/pe_cases/feedback-case`, { params })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };
}
