import React, { useEffect } from "react";
import authLogo from "assets/logos/auth_logo.png";
import { Link } from "react-router-dom";
import { Fade } from "react-reveal";
import AlertMessage from "components/AlertMessage";
import config from "utils/config";

export default function Landing() {
  useEffect(() => {
    document.title = "SISMAL | Selamat Datang Di SISMAL v3";
  }, []);

  return (
    <Fade>
      <div className="login">
        <div className="wrapper wrapper-login wrapper-login-full p-0">
          <div className="login-aside w-40 d-flex flex-column align-items-center justify-content-center text-center bg-secondary-gradient">
            <img src={authLogo} alt="Logo SISMAL" style={{ width: "50%" }} />
          </div>
          <div className="login-aside w-50 d-flex align-items-center justify-content-center bg-white">
            <div className="container container-login container-transparent animated fadeIn">
              <h3
                className="text-center text-uppercase font-weight-bold"
                style={{ color: "#18b3ab" }}
              >
                Selamat Datang di SISMAL v3
              </h3>

              {config.appMode === "development" && (
                <AlertMessage message="Ini adalah aplikasi latihan" />
              )}

              <Link
                to="/login"
                className="btn btn-teal-outline btn-block py-3 font-weight-bold"
              >
                Login ke SISMAL v3
              </Link>

              <p className="font-weight-bold" style={{ marginTop: 75 }}>
                Note :{" "}
              </p>
              <ul>
                <li>
                  Jika anda seorang petugas (Nakes, Kader, dll) dan ingin
                  menginputkan data malaria silahkan menggunakan tombol{" "}
                  <b>{"Login ke SISMAL v3"}</b>
                </li>
              </ul>

              <div className="copyright" style={{ marginTop: "100px" }}>
                <p>
                  Copyright &copy; SISMAL v3 | Kementerian Kesehatan Republik
                  Indonesia
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fade>
  );
}
