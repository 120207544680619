import React, { Component } from "react";
import { ErrorMessage, PageMode, requestStatus, Source } from "utils/constant";
import { toast } from "react-toastify";
import { ArrowLeft } from "react-feather";
import InputDateTime from "components/InputDateTime";
import moment from "moment";
import { Button, InputField, InputSelect } from "components";
import IRSResult from "services/IRSResult";
import Wilayah from "services/Wilayah";
import InputCheckbox from "components/InputCheckbox";

const IRSResultService = new IRSResult();
const WilayahService = new Wilayah();

export default class FormComponent extends Component {
  _isMounted = true;

  constructor(props) {
    super(props);
    this.defaultValue = {
      fasyankes_id: {
        value: this.props.currentUser.fasyankes_id,
        isValid: true,
        message: "",
      },
      irs_date: { value: "", isValid: true, message: "" },
      urbanvillage_id: { value: "", isValid: true, message: "" },
      sub_village: { value: "", isValid: true, message: "" },
      total_population_sub_village: {
        value: "",
        isValid: true,
        message: "",
      },
      vector_controll_type: { value: [], isValid: true, message: "" },
      insecticide_type: { value: "", isValid: true, message: "" },
      total_insecticide: {
        value: "",
        isValid: true,
        message: "",
      },
      total_insecticide_unit: { value: "", isValid: true, message: "" },
      total_targeted_house: { value: "", isValid: true, message: "" },
      total_sprayed_house: { value: "", isValid: true, message: "" },
      net_distribution_date: { value: "", isValid: true, message: "" },
      population_net_distribution: { value: "", isValid: true, message: "" },
      total_net_distribution: { value: "", isValid: true, message: "" },

      /**
       * Data Desa
       */
      urbanvillages: [],

      isLoading: false,
    };
    this.state = Object.assign({}, this.defaultValue);
  }

  componentDidMount() {
    this._isMounted = true;
    this.getUrbanvillagesByCurrentUser();

    let obj = Object.assign({}, this.state);

    if (this.props.mode === PageMode.edit) {
      const { data } = this.props;

      obj.fasyankes_id.value = data.fasyankes_id;
      obj.irs_date.value = data.irs_date;
      obj.urbanvillage_id.value = data.urbanvillage_id;
      obj.sub_village.value = data.sub_village;
      obj.total_population_sub_village.value =
        data.total_population_sub_village;
      obj.vector_controll_type.value = data.vector_controll_type;
      obj.irs_date.value = data.irs_date;
      obj.insecticide_type.value =
        data.insecticide_type === null ? "" : data.insecticide_type;
      obj.total_insecticide.value = data.total_insecticide;
      obj.total_insecticide_unit.value =
        data.total_insecticide_unit === null ? "" : data.total_insecticide_unit;
      obj.total_targeted_house.value = data.total_targeted_house;
      obj.total_sprayed_house.value = data.total_sprayed_house;
      obj.net_distribution_date.value = data.net_distribution_date;
      obj.population_net_distribution.value = data.population_net_distribution;
      obj.total_net_distribution.value = data.total_net_distribution;
    }

    this.setState(obj);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getUrbanvillagesByCurrentUser = async () => {
    const { currentUser } = this.props;

    await WilayahService.getAll(Source.URBANVILLAGE, {
      searchBy: "subdistrict_id",
      searchValue: currentUser && currentUser.subdistrict_id?.split("|")[0],
      page: 1,
      limit: 9999,
    })
      .then((res) => {
        this.setState({
          urbanvillages: res.urbanvillage,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    let obj = Object.assign({}, this.state[name]);
    obj.value = value;

    this.setState({
      [name]: { value: value, isValid: true, message: "" },
    });
  };

  handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    const { name } = e.target;

    let obj = Object.assign({}, this.state[name]);

    if (isChecked) {
      obj.value.push(e.target.value);
    }
    if (!isChecked) {
      obj.value = obj.value.filter((item) => item !== e.target.value);
    }

    this.setState(
      {
        [name]: obj,
      },
      () => {
        if (!this.state.vector_controll_type.value.includes("Kelambu")) {
          this.setState({
            net_distribution_date: {
              value: "",
              isValid: true,
              message: "",
            },
            population_net_distribution: {
              value: "",
              isValid: true,
              message: "",
            },
            total_net_distribution: { value: "", isValid: true, message: "" },
          });
        }

        if (!this.state.vector_controll_type.value.includes("IRS")) {
          this.setState({
            irs_date: { value: "", isValid: true, message: "" },
            insecticide_type: { value: "", isValid: true, message: "" },
            total_insecticide: { value: "", isValid: true, message: "" },
            total_insecticide_unit: { value: "", isValid: true, message: "" },
            total_targeted_house: { value: "", isValid: true, message: "" },
            total_sprayed_house: { value: "", isValid: true, message: "" },
          });
        }
      }
    );
  };

  nullConverter = (value) => {
    if (value === "") {
      return null;
    }
    return value;
  };

  onSave = async (e) => {
    e.preventDefault();

    const isValid = this._validationHandler();

    if (isValid) {
      this.setState({
        isLoading: true,
      });

      let payload = {
        fasyankes_id: this.nullConverter(this.state.fasyankes_id.value),
        urbanvillage_id: this.nullConverter(this.state.urbanvillage_id.value),
        sub_village: this.nullConverter(this.state.sub_village.value),
        total_population_sub_village: this.nullConverter(
          this.state.total_population_sub_village.value
        ),
        irs_date: this.nullConverter(this.state.irs_date.value),
        vector_controll_type: this.state.vector_controll_type.value,
        insecticide_type: this.nullConverter(this.state.insecticide_type.value),
        total_insecticide: this.nullConverter(
          this.state.total_insecticide.value
        ),
        total_insecticide_unit: this.nullConverter(
          this.state.total_insecticide_unit.value
        ),
        total_targeted_house: this.nullConverter(
          this.state.total_targeted_house.value
        ),
        total_sprayed_house: this.nullConverter(
          this.state.total_sprayed_house.value
        ),
        net_distribution_date: this.nullConverter(
          this.state.net_distribution_date.value
        ),
        population_net_distribution: this.nullConverter(
          this.state.population_net_distribution.value
        ),
        total_net_distribution: this.nullConverter(
          this.state.total_net_distribution.value
        ),
      };

      switch (this.props.mode) {
        case PageMode.add:
          await IRSResultService.add(payload)
            .then((res) => {
              this.setState({
                isLoading: false,
              });

              this.setState(this.defaultValue, () => {
                this.props.changeMode("view");
              });

              toast.success("Data Berhasil Ditambahkan!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            })
            .catch((err) => {
              console.log(err);

              this.setState({
                isLoading: false,
              });

              if (err.response.status === requestStatus.BadRequest) {
                toast.error(ErrorMessage.UserFaskesNotHaveAccess, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }

              if (err.response.status === requestStatus.Forbidden) {
                toast.error(ErrorMessage.UserNotVerified, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }

              if (err.response.status === requestStatus.Conflict) {
                toast.error(ErrorMessage.DataAlreadyExist, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }if (err.response.status === requestStatus.ServerError) {
                toast.error(ErrorMessage.InternalServerError, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
            });
          break;
        case PageMode.edit:
          await IRSResultService.update(payload, this.props.data.id)
            .then((res) => {
              this.setState({
                isLoading: false,
              });

              this.setState(this.defaultValue, () => {
                this.props.changeMode("view");
              });

              toast.success("Data Berhasil Diubah!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            })
            .catch((err) => {
              console.log(err);

              this.setState({
                isLoading: false,
              });

              if (err.response.status === requestStatus.BadRequest) {
                toast.error(ErrorMessage.UserFaskesNotHaveAccess, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }

              if (err.response.status === requestStatus.Forbidden) {
                toast.error(ErrorMessage.UserNotVerified, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }

              if (err.response.status === requestStatus.Conflict) {
                toast.error(ErrorMessage.DataAlreadyExist, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }if (err.response.status === requestStatus.ServerError) {
                toast.error(ErrorMessage.InternalServerError, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
            });
          break;
      }
    }
  };

  _validationHandler = () => {
    const {
      urbanvillage_id,
      sub_village,
      irs_date,
      vector_controll_type,
      insecticide_type,
      total_insecticide,
      total_insecticide_unit,
      total_targeted_house,
      total_sprayed_house,
      net_distribution_date,
      population_net_distribution,
      total_net_distribution,
    } = this.state;

    let isValid = true;

    if (urbanvillage_id.value === "" || null) {
      this.setState({
        urbanvillage_id: {
          ...urbanvillage_id,
          isValid: false,
          message: "Kelurahan tidak boleh kosong",
        },
      });
      isValid = false;
    }

    if (sub_village.value === "" || null) {
      this.setState({
        sub_village: {
          ...sub_village,
          isValid: false,
          message: "Nama Dusun tidak boleh kosong",
        },
      });
      isValid = false;
    }

    if (vector_controll_type.value.includes("IRS")) {
      if (irs_date.value === "" || null) {
        this.setState({
          irs_date: {
            ...irs_date,
            isValid: false,
            message: "Tanggal IRS tidak boleh kosong",
          },
        });
        isValid = false;
      }

      if (insecticide_type.value === "" || null) {
        this.setState({
          insecticide_type: {
            ...insecticide_type,
            isValid: false,
            message: "Jenis Insektisida tidak boleh kosong",
          },
        });
        isValid = false;
      }

      if (total_insecticide.value === "" || null) {
        this.setState({
          total_insecticide: {
            ...total_insecticide,
            isValid: false,
            message: "Jumlah Insekstisida yang digunakan tidak boleh kosong",
          },
        });
        isValid = false;
      }

      if (total_insecticide_unit.value === "" || null) {
        this.setState({
          total_insecticide_unit: {
            ...total_insecticide_unit,
            isValid: false,
            message:
              "total_insecticide_unit Insekstisida yang digunakan tidak boleh kosong",
          },
        });
        isValid = false;
      }

      if (total_targeted_house.value === "" || null) {
        this.setState({
          total_targeted_house: {
            ...total_targeted_house,
            isValid: false,
            message: "Jumlah total sasaran rumah tidak boleh kosong",
          },
        });
        isValid = false;
      }

      if (total_sprayed_house.value === "" || null) {
        this.setState({
          total_sprayed_house: {
            ...total_sprayed_house,
            isValid: false,
            message: "Jumlah rumah yang di semprot tidak boleh kosong",
          },
        });
        isValid = false;
      }
    }

    if (vector_controll_type.value.includes("Kelambu")) {
      if (net_distribution_date.value === "" || null) {
        this.setState({
          net_distribution_date: {
            ...net_distribution_date,
            isValid: false,
            message: "Tanggal Distribusi Kelambu tidak boleh kosong",
          },
        });
        isValid = false;
      }

      if (population_net_distribution.value === "" || null) {
        this.setState({
          population_net_distribution: {
            ...population_net_distribution,
            isValid: false,
            message: "Populasi target pembagian kelambu tidak boleh kosong",
          },
        });
        isValid = false;
      }
      if (total_net_distribution.value === "" || null) {
        this.setState({
          total_net_distribution: {
            ...total_net_distribution,
            isValid: false,
            message: "Jumlah kelambu yang dibagikan tidak boleh kosong",
          },
        });
        isValid = false;
      }
    }

    return isValid;
  };

  _mapper = (e) => {
    const options = [];
    e.map((x) => {
      options.push({ value: `${x.id}|${x.name}`, label: x.name });
    });
    return options;
  };

  render() {
    return (
      <div className="dashboard-wrapper">
        <div className="container">
          {this.props.mode !== PageMode.view && (
            <div
              className="my-3"
              style={{ cursor: "pointer" }}
              onClick={() => this.props.changeMode(PageMode.view)}
            >
              <ArrowLeft />
              <span>Kembali</span>
            </div>
          )}

          <h1 className="title font-weight-bold" style={{ padding: "10px" }}>
            Form Laporan Hasil Pengendalian Vektor
          </h1>

          <p className="font-weight-bold" style={{ padding: "0 10px" }}>
            NOTE: <span className="text-danger">*(WAJIB DIISI)</span>
          </p>

          <div className="form-wrapper">
            <InputSelect
              label={"Nama Desa"}
              name="urbanvillage_id"
              placeholder="--PILIH--"
              data={this._mapper(this.state.urbanvillages)}
              value={this.state.urbanvillage_id.value}
              onChange={this.handleInputChange}
              isRequired={true}
              hasError={!this.state.urbanvillage_id.isValid}
              errorMessage={this.state.urbanvillage_id.message}
            />

            <InputField
              label={"Nama Dusun"}
              name="sub_village"
              type={"text"}
              placeholder="Nama Dusun..."
              onChange={this.handleInputChange}
              value={this.state.sub_village.value}
              isRequired={true}
              hasError={!this.state.sub_village.isValid}
              errorMessage={this.state.sub_village.message}
            />

            <InputField
              label={"Jumlah penduduk di dusun tersebut (Opsional)"}
              name="total_population_sub_village"
              type={"numberOnly"}
              placeholder="0"
              onChange={this.handleInputChange}
              value={this.state.total_population_sub_village.value}
            />

            <div className="form-group">
              <label htmlFor="vektor_control_type">
                Jenis Pengendalian Vektor (Bisa memilih lebih dari satu){" "}
                <sup className="text-danger">*</sup>
              </label>
              <div className="form-check">
                <InputCheckbox
                  label="IRS"
                  name="vector_controll_type"
                  onChange={this.handleCheckboxChange}
                  value="IRS"
                  isChecked={this.state.vector_controll_type.value.includes(
                    "IRS"
                  )}
                />
                <InputCheckbox
                  label="Kelambu"
                  name="vector_controll_type"
                  onChange={this.handleCheckboxChange}
                  value="Kelambu"
                  isChecked={this.state.vector_controll_type.value.includes(
                    "Kelambu"
                  )}
                />
              </div>
            </div>

            {this.state.vector_controll_type.value.includes("IRS") && (
              <>
                <InputDateTime
                  label={"Tanggal IRS"}
                  name="irs_date"
                  dateFormat={"YYYY-MM-DD"}
                  placeholder="YYYY-MM-DD"
                  value={this.state.irs_date.value}
                  onChange={(e) =>
                    this.handleInputChange({
                      target: {
                        name: "irs_date",
                        isValid: true,
                        value: moment(e).format("YYYY-MM-DD"),
                      },
                    })
                  }
                  isRequired={true}
                  hasError={!this.state.irs_date.isValid}
                  errorMessage={this.state.irs_date.message}
                />
                <InputSelect
                  label={"Jenis Insektisida"}
                  name={"insecticide_type"}
                  placeholder="--PILIH--"
                  data={[
                    { label: "Organophospate", value: "Organophospate" },
                    {
                      label: "Karbamat",
                      value: "Karbamat",
                    },
                    { label: "Piretroid", value: "Piretroid" },
                    { label: "Neonikotinoid", value: "Neonikotinoid" },
                  ]}
                  onChange={this.handleInputChange}
                  value={this.state.insecticide_type.value}
                  isRequired={true}
                  hasError={!this.state.insecticide_type.isValid}
                  errorMessage={this.state.insecticide_type.message}
                />
                <div className="row">
                  <div className="col-6">
                    <InputField
                      label={"Jumlah Insektisida yang digunakan"}
                      type={"numberOnly"}
                      placeholder="0"
                      name="total_insecticide"
                      onChange={this.handleInputChange}
                      value={this.state.total_insecticide.value}
                      isRequired={true}
                      hasError={!this.state.total_insecticide.isValid}
                      errorMessage={this.state.total_insecticide.message}
                    />
                  </div>
                  <div className="col-6">
                    <InputSelect
                      label={"Satuan"}
                      name={"total_insecticide_unit"}
                      placeholder="--PILIH--"
                      data={[
                        { label: "Kg", value: "Kg" },
                        { label: "Liter", value: "Liter" },
                      ]}
                      onChange={this.handleInputChange}
                      value={this.state.total_insecticide_unit.value}
                      isRequired={true}
                      hasError={!this.state.total_insecticide_unit.isValid}
                      errorMessage={this.state.total_insecticide_unit.message}
                    />
                  </div>
                </div>
                <InputField
                  label={"Jumlah total sasaran rumah"}
                  type={"numberOnly"}
                  placeholder="0"
                  name="total_targeted_house"
                  onChange={this.handleInputChange}
                  value={this.state.total_targeted_house.value}
                  isRequired={true}
                  hasError={!this.state.total_targeted_house.isValid}
                  errorMessage={this.state.total_targeted_house.message}
                />
                <InputField
                  label={"Jumlah rumah yang disemprot"}
                  type={"numberOnly"}
                  placeholder="0"
                  name="total_sprayed_house"
                  onChange={this.handleInputChange}
                  value={this.state.total_sprayed_house.value}
                  isRequired={true}
                  hasError={!this.state.total_sprayed_house.isValid}
                  errorMessage={this.state.total_sprayed_house.message}
                />
              </>
            )}

            {this.state.vector_controll_type.value.includes("Kelambu") && (
              <>
                <InputDateTime
                  label={"Tanggal Distribusi Kelambu"}
                  name="net_distribution_date"
                  dateFormat={"YYYY-MM-DD"}
                  placeholder="YYYY-MM-DD"
                  value={this.state.net_distribution_date.value}
                  onChange={(e) =>
                    this.handleInputChange({
                      target: {
                        name: "net_distribution_date",
                        isValid: true,
                        value: moment(e).format("YYYY-MM-DD"),
                      },
                    })
                  }
                  isRequired={true}
                  hasError={!this.state.irs_date.isValid}
                  errorMessage={this.state.irs_date.message}
                />
                <InputField
                  label={"Jumlah target pembagian kelambu"}
                  type="numberOnly"
                  placeholder="0"
                  name="population_net_distribution"
                  onChange={this.handleInputChange}
                  value={this.state.population_net_distribution.value}
                  isRequired={true}
                  hasError={!this.state.population_net_distribution.isValid}
                  errorMessage={this.state.population_net_distribution.message}
                />
                <InputField
                  label={"Jumlah kelambu yang dibagikan"}
                  type="numberOnly"
                  placeholder="0"
                  name="total_net_distribution"
                  onChange={this.handleInputChange}
                  value={this.state.total_net_distribution.value}
                  isRequired={true}
                  hasError={!this.state.total_net_distribution.isValid}
                  errorMessage={this.state.total_net_distribution.message}
                />
              </>
            )}

            <div className="form-group d-flex justify-content-end">
              <Button onClick={() => this.props.changeMode("view")} isSecondary>
                Batalkan
              </Button>
              <Button onClick={this.onSave} isPrimary>
                Simpan Data
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
