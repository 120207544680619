import { errHandler } from "../utils/common";
import api from "../utils/api";

export default class Fasyankes {
  getAll = (
    source,
    opt = { searchBy: "", searchValue: "", page: 1, limit: 999, excludePkm: false }
  ) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/facility/${source}`, { params: opt })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };
}
