import React from "react";
import { PageMode } from "utils/constant";
import TableComponent from "./components/Table";
import FormComponent from "./components/Form";

const DataFaskesRujukan = ({ pageMode, changeMode }) => {
  const handlePageModeChange = (page) => {
    changeMode(page);
  };

  return (
    <>
      {pageMode === PageMode.view && (
        <TableComponent handlePageModeChange={handlePageModeChange} />
      )}
      {pageMode === PageMode.edit && (
        <FormComponent
          handlePageModeChange={handlePageModeChange}
          pageMode={pageMode}
        />
      )}
    </>
  );
};

export default DataFaskesRujukan;
