import React from "react";
import "./BreadcrumbNav.scss";
import { Link } from "react-router-dom";

const BreadcrumbNav = ({ items, onClick }) => {
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        {items?.map((item, index) => (
          <li
            key={index}
            className={`breadcrumb-item ${
              index === items.length - 1 ? "active" : ""
            }`}
            aria-current={index === items.length - 1 ? "page" : ""}
          >
            {index === items.length - 1 ? (
              <p style={{ margin: 0, padding: 0, cursor: "pointer" }}>
                {item.label}
              </p>
            ) : (
              <p
                style={{ margin: 0, padding: 0, cursor: "pointer" }}
                onClick={item.onClick}
              >
                {item.label}
              </p>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default BreadcrumbNav;
