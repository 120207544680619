import React, { Component } from "react";
import { PageMode } from "../../../utils/constant";
import Table from "./components/Table";
import Form from "./components/Form";
import Kader from "../../../services/Kader";
import { ArrowLeft } from "react-feather";
import { connect } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import { ErrorMessage } from "formik";
import { toast } from "react-toastify";

const KaderService = new Kader();
class CadreMonitoring extends Component {
  _isMounted = true;

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      mode: PageMode.view,
      loading: true,
      search: "",
      selectedItem: null,
      page: 1,
      limit: 10,
      currentPage: 1,
    };
  }

  componentDidMount() {
    document.title = "SISMAL | Data dan Monitoring Kader";

    this._isMounted = true;
    this.getAllData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  changeMode = (mode) => {
    this.setState({ mode }, () => {
      if (mode === PageMode.view) this.getAllData();
    });
  };

  getAllData = () => {
    const { user } = this.props;
    const { search, page, limit } = this.state;
    let searchBy = "fasyankes_id";
    let searchValue = user.fasyankes_id;

    if (search !== "") {
      searchBy += ",name";
      searchValue += `,${search}`;
    }

    this.setState({ loading: true, selectedItem: null }, async () => {
      await KaderService.getAll({
        page,
        limit,
        searchBy,
        searchValue,
      })
        .then((data) => {
          if (this._isMounted) {
            this.setState({ loading: false, data });
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({ loading: false });
        });
    });
  };

  onEdit = (id) => {
    this.setState({ selectedItem: id }, () => {
      this.changeMode(PageMode.edit);
    });
  };

  onPageChange = (page) => {
    this.setState({ page }, () => {
      this.getAllData();
    });
  };

  onSearch = (e) => {
    const search = e.target.value;
    this.setState({ search, page: 1 }, () => {
      this.getAllData();
    });
  };

  handleChecked = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      this.state.types_of_breeding.push(value);
      this.setState({
        ...this.props.data,
        types_of_mosquito_breeding: { value: this.state.types_of_breeding },
      });
    } else {
      let index = this.state.types_of_mosquito_breeding.indexOf(e.target.value);
      this.state.types_of_mosquito_breeding.splice(index, 1);
      this.setState({
        types_of_mosquito_breeding: this.state.types_of_mosquito_breeding,
      });
    }
  };

  onRemove = async (kaderId) => {
    confirmAlert({
      title: "Anda yakin ingin Menghapus data?",
      message: "Are you sure want to delete this data?",
      buttons: [
        {
          label: "Ya",
          onClick: () => {
            KaderService.remove(kaderId)
              .then((res) => {
                this.getAllData();
                toast.success("Data Kader Endemis Berhasil Dihapus", {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              })
              .catch((err) => {
                if (err?.response?.data?.message === "Validation Error") {
                  toast.error(
                    "Data kader ini terikat dengan data regmal 1, regmal 2 dan atau kinerja kader",
                    {
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    }
                  );
                }

                if (
                  err?.response?.data?.message === ErrorMessage.UserNotVerified
                ) {
                  toast.error("Akun anda belum diverifikasi", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                }

                if (
                  err?.response?.data?.message ===
                  ErrorMessage.UserFaskesNotHaveAccess
                ) {
                  toast.error("Akun anda tidak memiliki akses", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                }
              });
          },
        },
        {
          label: "Tidak",
        },
      ],
    });
  };

  render() {
    const { data, mode, loading, page, selectedItem } = this.state;

    return (
      <div className="dashboard-wrapper">
        {mode !== PageMode.view && (
          <div
            className="my-3"
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.changeMode(PageMode.view);
            }}
          >
            <ArrowLeft />
            <span>Kembali</span>
          </div>
        )}

        <h1 className="title font-weight-bold" style={{ margin: "0" }}>
          Data Kader
        </h1>
        <div className="content-wrapper mt-4">
          {mode === PageMode.view && (
            <Table
              data={data}
              loading={loading}
              currentPage={page}
              onEdit={this.onEdit}
              onAddPerformance={this.onAddPerformance}
              onRemove={this.onRemove}
              onSearch={this.onSearch}
              changeMode={this.changeMode}
              onPageChange={this.onPageChange}
              currentUser={this.props.user}
            />
          )}
          {(mode === PageMode.add || mode === PageMode.edit) && (
            <Form
              mode={mode}
              changeMode={this.changeMode}
              cadre={selectedItem}
              handleChecked={this.handleChecked}
              user={this.props.user}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authReducer.currentUser,
  };
};

export default connect(mapStateToProps)(CadreMonitoring);
