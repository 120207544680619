import React from "react";
import PropTypes from "prop-types";

const Button = ({
  children,
  onClick,
  isPrimary,
  isSecondary,
  isPrimaryBlock,
  isDisabled,
  ...props
}) => {
  return (
    <button
      className={`btn ${isPrimary ? "btn-teal" : ""} ${
        isSecondary ? "btn-teal-outline mr-2" : ""
      } ${
        isPrimaryBlock ? "btn-block btn-sign-in mt-3 mt-sm-0" : ""
      } px-5 font-weight-bold`}
      onClick={onClick}
      disabled={isDisabled}
      {...props}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isPrimary: PropTypes.bool,
  isSecondary: PropTypes.bool,
};

export default Button;
