import React from "react";
import "./FormStepIndicator.scss";
import { FiFileText, FiActivity, FiCheck } from "react-icons/fi";

const FormStepIndicatorComponent = ({ step }) => {
  const getStepBarColor = (currentStep) => {
    switch (currentStep) {
      case 1:
        return "#18B3AB";
      case 2:
        return "#D0F4F2";
      default:
        return "#ECEDF0";
    }
  };

  const getTextColor = (currentStep) => {
    if (currentStep === step) {
      return "#1A1A1A";
    } else {
      return "#A3A3A3";
    }
  };

  return (
    <div className="step-indicator-container">
      <div className="step-indicator-item">
        <div className="step-indicator-wrap">
          <div className="step-indicator-icon">
            {step !== 1 ? <FiCheck size={16} /> : <FiFileText size={16} />}
          </div>
          <div className="step-indicator-title">
            <p style={{ color: getTextColor(1) }}>Step 1: Data populasi</p>
          </div>
        </div>
        <div
          className="step-indicator-bar"
          style={{ backgroundColor: getStepBarColor(step) }}
        />
      </div>
      <div className="step-indicator-item">
        <div className="step-indicator-wrap">
          <div className="step-indicator-icon">
            {step > 2 ? <FiCheck size={16} /> : <FiActivity size={16} />}
          </div>
          <div className="step-indicator-title">
            <p style={{ color: getTextColor(2) }}>Step 2: Intervensi</p>
          </div>
        </div>
        <div
          className="step-indicator-bar"
          style={{ backgroundColor: getStepBarColor(step - 1) }}
        />
      </div>
    </div>
  );
};

export default FormStepIndicatorComponent;
