import React, { Component } from "react";
import DashboardWrap from "components/DashboardWrap";
import { Button, TableComponent } from "components";
import { PageMode } from "utils/constant";
import InputDateTime from "components/InputDateTime";
import RequestStock from "services/LogisticRequestStock";
import { connect } from "react-redux";
import moment from "moment";
import { getCurrentUserFromLocalStorage } from "utils/common";

const LogisticRequestStockService = new RequestStock();
class RequestRecap extends Component {
  _isMounted = true;

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      limit: 10,
      data: [],
      mode: PageMode.view,
      loading: false,
      year_filter: moment().format("YYYY"),
      level: "Fasyankes",
    };
  }

  componentDidMount() {
    document.title = "SISMAL | Rekap Permintaan";

    this._isMounted = true;
    this.getAllRequestRecap();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getAllRequestRecap = () => {
    this.setState({ loading: true, selectedItem: null }, async () => {
      await LogisticRequestStockService.getAll({
        page: this.state.page,
        limit: this.state.limit,
        searchBy: "year_submission,fasyankes_id",
        searchValue: `${this.state.year_filter},${this.props.user.fasyankes_id}`,
      })
        .then((data) => {
          if (this._isMounted) {
            this.setState({
              loading: false,
              data: data,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({ loading: false });
        });
    });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  onPageChange = (page) => {
    this.setState({ page }, () => {
      this.getAllRequestRecap();
    });
  };

  render() {
    const { data, loading } = this.state;

    const currentUser = getCurrentUserFromLocalStorage();

    return (
      <DashboardWrap title="Rekap Permintaan">
        {/* create inline label input and button */}
        <div className="form-inline">
          <div className="form-group">
            <InputDateTime
              name="year_filter"
              dateFormat="YYYY"
              placeholder="Tahun Pengajuan"
              value={this.state.year_filter}
              timeFormat={false}
              onChange={(e) =>
                this.handleInputChange({
                  preventDefault: () => {},
                  target: {
                    name: "year_filter",
                    value: moment(e).format("YYYY"),
                  },
                })
              }
            />
          </div>
          <Button onClick={this.getAllRequestRecap} isPrimary>
            Terapkan
          </Button>
        </div>

        <hr />
        <div className="row">
          <div className="col-md-12">
            {currentUser && currentUser.fasyankes_type_id === "jnsfas-01" && (
              <TableComponent
                loading={loading}
                data={data?.logistic_request_stocks}
                headers={[
                  "Tahun Pengajuan",
                  "Provinsi",
                  "Kabupaten",
                  "Kecamatan",
                  "Fasyankes",
                  "No Surat",
                  "Jenis Permintaan",
                  "Jenis Logistik",
                  "Jumlah Yang Diminta",
                  "Satuan",
                  "Status",
                  "Jumlah yang dipenuhi",
                  "Satuan",
                ]}
                cols={[
                  "year_submission",
                  ["province_id", "|", 1],
                  ["district_id", "|", 1],
                  ["subdistrict_id", "|", 1],
                  ["fasyankes_id", "|", 1],
                  "letter_number",
                  "request_type",
                  "logistic_type",
                  "request_total",
                  "unit",
                  "status",
                  "fulfilled_total",
                  "fulfillet_unit",
                ]}
                currentPage={data?.currentPage}
                total={data?.total}
                limit={data?.limit}
                nextPage={data?.nextPage}
                previousPage={data?.previousPage}
                onPageChange={this.onPageChange}
                withoutActionBtn={true}
              />
            )}

            {currentUser && currentUser.fasyankes_type_id !== "jnsfas-01" && (
              <TableComponent
                loading={loading}
                data={data?.logistic_request_stocks}
                headers={[
                  "Tahun Pengajuan",
                  "Provinsi",
                  "Kabupaten",
                  "Fasyankes",
                  "No Surat",
                  "Jenis Permintaan",
                  "Jenis Logistik",
                  "Jumlah Yang Diminta",
                  "Satuan",
                  "Status",
                  "Jumlah yang dipenuhi",
                  "Satuan",
                ]}
                cols={[
                  "year_submission",
                  ["province_id", "|", 1],
                  ["district_id", "|", 1],
                  ["fasyankes_id", "|", 1],
                  "letter_number",
                  "request_type",
                  "logistic_type",
                  "request_total",
                  "unit",
                  "status",
                  "fulfilled_total",
                  "fulfillet_unit",
                ]}
                currentPage={data?.currentPage}
                total={data?.total}
                limit={data?.limit}
                nextPage={data?.nextPage}
                previousPage={data?.previousPage}
                onPageChange={this.onPageChange}
                withoutActionBtn={true}
              />
            )}
          </div>
        </div>
      </DashboardWrap>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authReducer.currentUser,
  };
};

export default connect(mapStateToProps, null)(RequestRecap);
