import Button from "components/atoms/Button";
import InputDate from "components/atoms/InputDate";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MMPCadre from "services/MMPCadre";
import MMPCadrePerfomance from "services/MMPCadrePerfomance";
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import ActionButton from "components/atoms/ActionButton";
import TableDataComponent from "components/organisms/TableData";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import { errorToastNotif } from "utils/common";
import { ErrorMessage } from "utils/constant";

const KaderMMPService = new MMPCadre();
const KinerjaKaderMMPService = new MMPCadrePerfomance();

const TableComponent = ({ handleEditData }) => {
  const [state, setState] = useState({
    page: 1,
    limit: 10,
    selectedYear: "",
    isLoading: true,
    data: [],
    cadreData: null,
    totaData: 0,
    currentPage: 1,
    nextpage: null,
    previousPage: null,
  });

  const { id } = useParams();

  const getCadreMMPById = async () => {
    await KaderMMPService.getOne(id)
      .then((res) => {
        setState((prevState) => ({
          ...prevState,
          cadreData: res,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCadreMMPKinerja = async () => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    const { page, limit, selectedYear } = state;
    await KinerjaKaderMMPService.getAll({
      searchBy: `mmp_cadre_id${selectedYear !== "" ? ",year" : ""}`,
      searchValue: `${id}${selectedYear !== "" ? `,${selectedYear}` : ""}`,
      page,
      limit,
    })
      .then((res) => {
        setState((prevState) => ({
          ...prevState,
          data: res.cadre_performance_mmp,
        }));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  useEffect(() => {
    getCadreMMPById();
    getCadreMMPKinerja();
  }, [state.page]);

  const onPageChange = (page) => {
    setState((prevState) => ({
      ...prevState,
      page: page,
    }));
  };

  const handleFilterData = () => {
    getCadreMMPKinerja();
  };

  const handleRemoveData = (id) => {
    try {
      confirmAlert({
        title: "Anda yakin ingin menghapus data ini?",
        message: "are you sure want to delete this data?",
        buttons: [
          {
            label: "Ya",
            onClick: async () =>
              await KinerjaKaderMMPService.remove(id)
                .then((res) => {
                  getCadreMMPKinerja();
                  toast.success("Data berhasil dihapus", {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                })
                .catch((err) => {
                  console.log(err);
                  if (
                    err.resonse.data.message === ErrorMessage.UserNotVerified
                  ) {
                    toast.error("Akun anda belum diverifikasi", {
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                  }

                  if (
                    err.response.data.message ===
                    ErrorMessage.UserFaskesNotHaveAccess
                  ) {
                    toast.error("Akun anda tidak memiliki akses", {
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                  }
                }),
          },
          {
            label: "Tidak",
          },
        ],
      });
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      header: "Aksi",
      accessorKey: "action",
      cell: ({ row }) => (
        <div className="button-actions">
          <ActionButton
            onClick={() => handleEditData(row.original)}
            btnType={"edit"}
          />
          <ActionButton
            onClick={() => handleRemoveData(row.original.id)}
            btnType={"delete"}
          />
        </div>
      ),
    },
    {
      header: "ID MMP",
      accessorKey: "assignment_place_mmp_id",
    },
    {
      header: "Tahun",
      accessorKey: "year",
    },
    {
      header: "Bulan",
      accessorKey: "month",
    },
    {
      header: "Jumlah Orang Yang Menjadi Tanggung Jawab Kader",
      accessorKey: "total_person_responsibility",
    },
    {
      header: "Jumlah Orang Baru Yang Masuk",
      accessorKey: "total_person_in",
    },
    {
      header: "Jumlah Orang Baru Yang Keluar",
      accessorKey: "total_person_out",
    },
    {
      header: "Jumlah Pemeriksaan Pada Orang Yang Masuk",
      accessorKey: "total_person_check_in",
    },
    {
      header: "Jumlah Pemeriksaan Pada Orang Yang Keluar",
      accessorKey: "total_person_check_out",
    },
  ];

  const tableInstance = useReactTable({
    columns,
    data: state?.data,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <>
      {/* Filter data */}
      <div className="filter-data-container">
        <div className="cadre-information">
          <div className="info-label">Nama Kader</div>
          <div className="info-value">{state?.cadreData?.name}</div>
        </div>
        <hr />
        <div className="filter-item">
          <div className="filter-label">Periode Laporan</div>
          <div className="filter-control">
            <InputDate
              placeholder={"Pilih Tahun"}
              dateFormat={"YYYY"}
              onChange={(e) => {
                setState({
                  ...state,
                  selectedYear: moment(e).format("YYYY"),
                });
              }}
            />
            <Button
              variant={"primary"}
              size={"normal"}
              onClick={handleFilterData}
            >
              Terapkan
            </Button>
          </div>
        </div>
      </div>
      {/* Table Data */}
      <TableDataComponent
        columnLength={columns?.length}
        tableInstance={tableInstance}
        onPageChange={onPageChange}
        loading={state.isLoading}
        currentPage={state.currentPage}
        limit={state.limit}
        nextPage={state.nextpage}
        previousPage={state.previousPage}
        total={state.totaData}
      />
    </>
  );
};

export default TableComponent;
