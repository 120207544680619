import React, { Component } from "react";
import { ErrorMessage, PageMode, requestStatus } from "utils/constant";
import ZeroRepor from "services/ZeroReport";
import { Button, InputField, InputSelect } from "components";
import { connect } from "react-redux";
import InputDateTime from "components/InputDateTime";
import moment from "moment";
import { toast } from "react-toastify";

const ZeroReporService = new ZeroRepor();

class Form extends Component {
  _isMounted = true;

  constructor(props) {
    super(props);
    this.defaultValue = {
      fasyankes_id: this.props.user.fasyankes_id,
      year_month: { value: "", isValid: true, message: "" },
      is_zero_reporting: { value: "", isValid: true, message: "" },
      total_positive_malaria: 0,
      total_negative_malaria: 0,
      loading: true,

      hideIsZeroReportField: false,

      disabledSubmit: true,
      isLoading: false,
    };
    this.state = Object.assign({}, this.defaultValue);
  }

  componentDidMount() {
    this._isMounted = true;

    let obj = Object.assign({}, this.state);
    if (this.props.mode === PageMode.edit) {
      const { zeroReport } = this.props;
      this.getTotalMalaria(
        `${zeroReport.year}-${zeroReport.month}`,
        zeroReport.fasyankes_id
      );
      obj.fasyankes_id = zeroReport.fasyankes_id;
      obj.year_month.value = `${zeroReport.year}-${zeroReport.month}`;
      obj.is_zero_reporting.value = zeroReport.is_zero_reporting;
    }
    obj.loading = false;
    this.setState(obj);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onInputChange = (e) => {
    const { name, value } = e.target;
    let obj = Object.assign({}, this.state[name]);
    obj.value = value;
    this.setState({
      [name]: obj,
    });

    if (name === "year_month") {
      this.getTotalMalaria(value, this.state.fasyankes_id);
    }
  };

  nullConverter = (value) => {
    if(value === ""){
      return null;
    }
    return value;
  }

  onSave = async (e) => {
    e.preventDefault();
    
    const isValid = this.validationHandler();
    if (isValid) {

      this.setState({
        isLoading: true,
      });

      let payload = {
        fasyankes_id: this.nullConverter(this.state.fasyankes_id),
        year: this.nullConverter(this.state.year_month.value?.split("-")[0]),
        month: this.nullConverter(this.state.year_month.value?.split("-")[1]),
        is_zero_reporting: this.nullConverter(this.state.is_zero_reporting.value),
      };

      switch (this.props.mode) {
        case PageMode.add:
          await ZeroReporService.add(payload)
            .then((res) => {
              this.setState({
                isLoading: false,
              });

              this.setState(this.defaultValue, () => {
                this.props.changeMode("view");
              });
              toast.success("Data Zero Reporting Berhasil Ditambahkan", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            })
            .catch((err) => {
              this.setState({
                isLoading: false,
              });

              console.log(err);

              if (err.response.status === requestStatus.BadRequest) {
                toast.error(ErrorMessage.UserFaskesNotHaveAccess, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }

              if (err.response.status === requestStatus.Forbidden) {
                toast.error(ErrorMessage.UserNotVerified, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }

              if (err.response.status === requestStatus.Conflict) {
                toast.error(ErrorMessage.DataAlreadyExist, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }if (err.response.status === requestStatus.ServerError) {
                toast.error(ErrorMessage.InternalServerError, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
            });

          break;
        case PageMode.edit:
          await ZeroReporService.update(payload, this.props.zeroReport.id)
            .then((res) => {
              this.setState(this.defaultValue, () => {
                this.props.changeMode("view");
              });
              toast.success("Data Zero Report Berhasil Diupdate", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            })
            .catch((err) => {
              console.log(err);

              if (err.response.status === requestStatus.BadRequest) {
                toast.error(ErrorMessage.UserFaskesNotHaveAccess, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }

              if (err.response.status === requestStatus.Forbidden) {
                toast.error(ErrorMessage.UserNotVerified, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }

              if (err.response.status === requestStatus.Conflict) {
                toast.error(ErrorMessage.DataAlreadyExist, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }if (err.response.status === requestStatus.ServerError) {
                toast.error(ErrorMessage.InternalServerError, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
            });

          break;
      }
    }
  };

  validationHandler = () => {
    const {
      year_month,
      is_zero_reporting,
      total_positive_malaria,
      total_negative_malaria,
    } = this.state;

    let isValid = true;

    if (!year_month.value) {
      year_month.isValid = false;
      year_month.message = "Tahun dan Bulan tidak boleh kosong";
      isValid = false;
    }

    if (total_negative_malaria === 0 || total_positive_malaria === 0) {
      if (!is_zero_reporting.value) {
        is_zero_reporting.isValid = false;
        is_zero_reporting.message = "Is Zero Reporting tidak boleh kosong";
        isValid = false;
      }
    }

    this.setState({
      year_month,
      is_zero_reporting,
    });

    return isValid;
  };

  getTotalMalaria = (date, fasyankes_id) => {
    this.setState({ loading: true }, async () => {
      await ZeroReporService.getTotalMalaria({ date, fasyankes_id })
        .then((data) => {
          if (this._isMounted) {
            this.setState({ loading: false, ...data });
            this.disabledSubmit(data);
            if (
              data.total_positive_malaria > 0 ||
              data.total_negative_malaria > 0
            ) {
              this.setState({ hideIsZeroReportField: true });
              toast.error("Tidak Dapat melakukan input Zero Report", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            } else {
              this.setState({ hideIsZeroReportField: false });
            }
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({ loading: false });
        });
    });
  };

  disabledSubmit = ({ total_negative_malaria, total_positive_malaria }) => {
    return this.setState({
      disabledSubmit:
        total_negative_malaria !== 0 || total_positive_malaria !== 0,
    });
  };

  render() {
    return (
      <div className="form-wrapper">
        <div className="row">
          <div className="col-sm-6">
            <table className="table">
              <tr>
                <td>FASYANKES</td>
                <th>:</th>
                <th>{this.props.user.fasyankes_id?.split("|")[1]}</th>
              </tr>
            </table>
          </div>
        </div>

        <p className="font-weight-bold d-flex justify-content-start">
          NOTE <span className="text-danger">*(WAJIB DIISI)</span>
        </p>

        <InputDateTime
          label="Tahun dan Bulan"
          name="year_month"
          dateFormat="YYYY-MM"
          placeholder="Tahun dan Bulan"
          value={this.state.year_month.value}
          onChange={(e) =>
            this.onInputChange({
              target: {
                name: "year_month",
                isValid: true,
                value: moment(e).format("YYYY-MM"),
              },
            })
          }
          isRequired={true}
          hasError={!this.state.year_month.isValid}
          errorMessage={this.state.year_month.message}
          isValidDate={(current) => {
            return moment(current.format("YYYY-MM")).isSameOrBefore(
              moment().subtract(1, "months").format("YYYY-MM")
            );
          }}
        />

        <InputField
          label="Jumlah pemeriksaan positif malaria"
          type="text"
          value={this.state.total_positive_malaria}
          isRequired={true}
          isDisabled={true}
        />

        <InputField
          label="Jumlah pemeriksaan negatif malaria*"
          type="text"
          value={this.state.total_negative_malaria}
          isRequired={true}
          isDisabled={true}
        />

        <InputSelect
          label="Laporan Zero Reporting (dalam satu bulan tidak ada pemeriksaan malaria baik positif maupun negatif)"
          name="is_zero_reporting"
          placeholder="Pilih"
          data={[{ value: "Ya", label: "Ya" }]}
          onChange={this.onInputChange}
          value={this.state.is_zero_reporting.value}
          isRequired={true}
          hasError={!this.state.is_zero_reporting.isValid}
          errorMessage={this.state.is_zero_reporting.message}
          isHidden={this.state.hideIsZeroReportField}
        />

        <div className="form-group d-flex justify-content-end">
          <Button onClick={() => this.props.changeMode("view")} isSecondary>
            Batalkan
          </Button>
          <Button
            onClick={this.onSave}
            isPrimary
            disabled={this.state.disabledSubmit}
            isDisabled={this.state.isLoading}
          >
            {this.state.isLoading ? "Mohon Tunggu..." : "Simpan Data"}
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authReducer.currentUser,
  };
};

export default connect(mapStateToProps)(Form);
