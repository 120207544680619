import React from "react";
import { monthConverter } from "utils/common";
import { UserLevel } from "utils/constant";

const TableComponent = ({
  currentLevel,
  reportsData,
  tableRef,
  reportInfo,
}) => {
  const calculateTotal = () => {
    return reportsData.reduce(
      (total, item) => {
        total.items.push(item);

        total.total_sasaran_bumil += parseInt(item.sasaran_ibu_hamil ?? 0);
        total.total_sasaran_balita += parseInt(item.target_balita_sakit ?? 0);
        total.total_bumil_k1 += parseInt(item.jml_capaian_bumil_k1 ?? 0);
        total.total_bumil_scren += parseInt(
          item.jml_bumil_screening_malaria ?? 0
        );
        total.total_bumil_scren_kelambu += parseInt(
          item.jml_bumil_diberi_kelambu ?? 0
        );
        total.total_kunj_balita_sakit += parseInt(
          item.jml_kunjungan_balita_sakit ?? 0
        );
        total.total_kunj_balita_sakit_perika_mal += parseInt(
          item.jml_balita_sakit_diperiksa_malaria ?? 0
        );

        return total;
      },
      {
        total_sasaran_bumil: 0,
        total_sasaran_balita: 0,
        total_bumil_k1: 0,
        total_bumil_scren: 0,
        total_bumil_scren_kelambu: 0,
        total_bumil_scren_kelambu: 0,
        total_kunj_balita_sakit: 0,
        total_kunj_balita_sakit_perika_mal: 0,
        items: [],
      }
    );
  };

  let levelName = "";
  if (currentLevel === "national") {
    levelName = "Nasional";
  } else if (currentLevel === "province") {
    levelName = "Provinsi";
  } else if (currentLevel === "district") {
    levelName = "Kabupaten/Kota";
  } else {
    levelName = "Fasyankes";
  }

  let periodeName = "";

  if (reportInfo?.statusPeriode === "yearly") {
    periodeName = "Tahunan";
  } else {
    periodeName = "Bulanan";
  }

  let data = reportInfo?.fasyankes;
  let parts = data?.split(",");
  let faskesName = parts?.map(function (part) {
    return part?.split("|")[1];
  });
  let result = faskesName?.join(", ");

  const renderTableByLevel = () => {
    const total = calculateTotal();

    switch (currentLevel) {
      case UserLevel.National.toLowerCase():
        return (
          <table>
            <thead>
              <tr>
                <th>No.</th>
                <th>Provinsi</th>
                <th>Kab/Kota</th>
                <th>Sasaran Ibu Hamil</th>
                <th>Sasaran Kunjungan Balita Sakit</th>
                <th>Jumlah Capaian Bumil K1</th>
                <th>Jumlah Bumil Skrining Malaria</th>
                <th>Jumlah Bumil Diberi Kelambu</th>
                <th>Jumlah Kunjungan Balita Sakit</th>
                <th>Jumlah Balita Sakit Diperiksa Malaria</th>
              </tr>
            </thead>
            <tbody>
              {reportsData.length !== 0 ? (
                reportsData?.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item?.provinsi}</td>
                    <td>{item?.kabupaten}</td>
                    <td>{parseInt(item?.sasaran_ibu_hamil ?? 0)}</td>
                    <td>{parseInt(item?.target_balita_sakit ?? 0)}</td>
                    <td>{parseInt(item?.jml_capaian_bumil_k1 ?? 0)}</td>
                    <td>{parseInt(item?.jml_bumil_screening_malaria ?? 0)}</td>
                    <td>{parseInt(item?.jml_bumil_diberi_kelambu ?? 0)}</td>
                    <td>{parseInt(item?.jml_kunjungan_balita_sakit ?? 0)}</td>
                    <td>
                      {parseInt(item?.jml_balita_sakit_diperiksa_malaria ?? 0)}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={10}>Tidak Ada Data</td>
                </tr>
              )}
              <tr style={{ backgroundColor: "#43766C" }}>
                <td style={{ color: "#FFF" }} colSpan={3}>
                  Total
                </td>
                <td style={{ color: "#FFF" }}>{total.total_sasaran_bumil}</td>
                <td style={{ color: "#FFF" }}>{total.total_sasaran_balita}</td>
                <td style={{ color: "#FFF" }}>{total.total_bumil_k1}</td>
                <td style={{ color: "#FFF" }}>{total.total_bumil_scren}</td>
                <td style={{ color: "#FFF" }}>
                  {total.total_bumil_scren_kelambu}
                </td>
                <td style={{ color: "#FFF" }}>
                  {total.total_kunj_balita_sakit}
                </td>
                <td style={{ color: "#FFF" }}>
                  {total.total_kunj_balita_sakit_perika_mal}
                </td>
              </tr>
            </tbody>
          </table>
        );
      case UserLevel.Province.toLowerCase():
        return (
          <table>
            <thead>
              <tr>
                <th>No.</th>
                <th>Provinsi</th>
                <th>Kab/Kota</th>
                <th>Sasaran Ibu Hamil</th>
                <th>Sasaran Kunjungan Balita Sakit</th>
                <th>Jumlah Capaian Bumil K1</th>
                <th>Jumlah Bumil Skrining Malaria</th>
                <th>Jumlah Bumil Diberi Kelambu</th>
                <th>Jumlah Kunjungan Balita Sakit</th>
                <th>Jumlah Balita Sakit Diperiksa Malaria</th>
              </tr>
            </thead>
            <tbody>
              {reportsData.length !== 0 ? (
                reportsData?.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item?.provinsi}</td>
                    <td>{item?.kabupaten}</td>
                    <td>{parseInt(item?.sasaran_ibu_hamil ?? 0)}</td>
                    <td>{parseInt(item?.target_balita_sakit ?? 0)}</td>
                    <td>{parseInt(item?.jml_capaian_bumil_k1 ?? 0)}</td>
                    <td>{parseInt(item?.jml_bumil_screening_malaria ?? 0)}</td>
                    <td>{parseInt(item?.jml_bumil_diberi_kelambu ?? 0)}</td>
                    <td>{parseInt(item?.jml_kunjungan_balita_sakit ?? 0)}</td>
                    <td>
                      {parseInt(item?.jml_balita_sakit_diperiksa_malaria ?? 0)}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={9}>Tidak Ada Data</td>
                </tr>
              )}
              <tr style={{ backgroundColor: "#43766C" }}>
                <td style={{ color: "#FFF" }} colSpan={3}>
                  Total
                </td>
                <td style={{ color: "#FFF" }}>{total.total_sasaran_bumil}</td>
                <td style={{ color: "#FFF" }}>{total.total_sasaran_balita}</td>
                <td style={{ color: "#FFF" }}>{total.total_bumil_k1}</td>
                <td style={{ color: "#FFF" }}>{total.total_bumil_scren}</td>
                <td style={{ color: "#FFF" }}>
                  {total.total_bumil_scren_kelambu}
                </td>
                <td style={{ color: "#FFF" }}>
                  {total.total_kunj_balita_sakit}
                </td>
                <td style={{ color: "#FFF" }}>
                  {total.total_kunj_balita_sakit_perika_mal}
                </td>
              </tr>
            </tbody>
          </table>
        );
      case UserLevel.District.toLowerCase():
        return (
          <table>
            <thead>
              <tr>
                <th>No.</th>
                <th>Kabupaten</th>
                <th>Faskes</th>
                <th>Sasaran Ibu Hamil</th>
                <th>Sasaran Kunjungan Balita Sakit</th>
                <th>Jumlah Capaian Bumil K1</th>
                <th>Jumlah Bumil Skrining Malaria</th>
                <th>Jumlah Bumil Diberi Kelambu</th>
                <th>Jumlah Kunjungan Balita Sakit</th>
                <th>Jumlah Balita Sakit Diperiksa Malaria</th>
              </tr>
            </thead>
            <tbody>
              {reportsData.length !== 0 ? (
                reportsData?.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item?.kabupaten}</td>
                    <td>{item?.faskes}</td>
                    <td>{parseInt(item?.sasaran_ibu_hamil ?? 0)}</td>
                    <td>{parseInt(item?.target_balita_sakit ?? 0)}</td>
                    <td>{parseInt(item?.jml_capaian_bumil_k1 ?? 0)}</td>
                    <td>{parseInt(item?.jml_bumil_screening_malaria ?? 0)}</td>
                    <td>{parseInt(item?.jml_bumil_diberi_kelambu ?? 0)}</td>
                    <td>{parseInt(item?.jml_kunjungan_balita_sakit ?? 0)}</td>
                    <td>
                      {parseInt(item?.jml_balita_sakit_diperiksa_malaria ?? 0)}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={11}>Tidak Ada Data</td>
                </tr>
              )}
              <tr style={{ backgroundColor: "#43766C" }}>
                <td style={{ color: "#FFF" }} colSpan={3}>
                  Total
                </td>
                <td style={{ color: "#FFF" }}>{total.total_sasaran_bumil}</td>
                <td style={{ color: "#FFF" }}>{total.total_sasaran_balita}</td>
                <td style={{ color: "#FFF" }}>{total.total_bumil_k1}</td>
                <td style={{ color: "#FFF" }}>{total.total_bumil_scren}</td>
                <td style={{ color: "#FFF" }}>
                  {total.total_bumil_scren_kelambu}
                </td>
                <td style={{ color: "#FFF" }}>
                  {total.total_kunj_balita_sakit}
                </td>
                <td style={{ color: "#FFF" }}>
                  {total.total_kunj_balita_sakit_perika_mal}
                </td>
              </tr>
            </tbody>
          </table>
        );
      default:
        return (
          <table>
            <thead>
              <tr>
                <th>No.</th>
                <th>Faskes</th>
                <th>Sasaran Ibu Hamil</th>
                <th>Sasaran Kunjungan Balita Sakit</th>
                <th>Bulan Capaian</th>
                <th>Jumlah Capaian Bumil K1</th>
                <th>Jumlah Bumil Skrining Malaria</th>
                <th>Jumlah Bumil Diberi Kelambu</th>
                <th>Jumlah Kunjungan Balita Sakit</th>
                <th>Jumlah Balita Sakit Diperiksa Malaria</th>
              </tr>
            </thead>
            <tbody>
              {reportsData.length !== 0 ? (
                reportsData?.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item?.faskes}</td>
                    <td>{parseInt(item?.sasaran_ibu_hamil ?? 0)}</td>
                    <td>{parseInt(item?.target_balita_sakit ?? 0)}</td>
                    <td>{monthConverter(item?.bulan_capaian)}</td>
                    <td>{parseInt(item?.jml_capaian_bumil_k1 ?? 0)}</td>
                    <td>{parseInt(item?.jml_bumil_screening_malaria ?? 0)}</td>
                    <td>{parseInt(item?.jml_bumil_diberi_kelambu ?? 0)}</td>
                    <td>{parseInt(item?.jml_kunjungan_balita_sakit ?? 0)}</td>
                    <td>
                      {parseInt(item?.jml_balita_sakit_diperiksa_malaria ?? 0)}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={11}>Tidak Ada Data</td>
                </tr>
              )}
              <tr style={{ backgroundColor: "#43766C" }}>
                <td style={{ color: "#FFF" }} colSpan={2}>
                  Total
                </td>
                <td style={{ color: "#FFF" }}>
                  {total.total_sasaran_bumil / total.items.length}
                </td>
                <td style={{ color: "#FFF" }}>{total.total_sasaran_balita / total.items.length}</td>
                <td style={{ color: "#FFF" }}>-</td>
                <td style={{ color: "#FFF" }}>{total.total_bumil_k1}</td>
                <td style={{ color: "#FFF" }}>{total.total_bumil_scren}</td>
                <td style={{ color: "#FFF" }}>
                  {total.total_bumil_scren_kelambu}
                </td>
                <td style={{ color: "#FFF" }}>
                  {total.total_kunj_balita_sakit}
                </td>
                <td style={{ color: "#FFF" }}>
                  {total.total_kunj_balita_sakit_perika_mal}
                </td>
              </tr>
            </tbody>
          </table>
        );
    }
  };

  return (
    <div
      className="table-table-responsive"
      style={{ height: "560px", overflow: "scroll" }}
      ref={tableRef}
    >
      <table>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none" }}>
            <h3>SISMAL v3, MALARIA KIA</h3>
          </th>
        </tr>
      </table>
      <table>
        <tr style={{ border: "none" }}>
          <th
            width="200"
            style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}
          >
            Periode Laporan
          </th>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            : {periodeName}
          </th>
        </tr>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            Tahun
          </th>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            : {reportInfo?.periode?.year}
          </th>
        </tr>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            Bulan
          </th>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            : {monthConverter(reportInfo?.periode?.month_start)} -{" "}
            {monthConverter(reportInfo?.periode?.month_end)}
          </th>
        </tr>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            Level
          </th>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            : {levelName}
          </th>
        </tr>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            Provinsi
          </th>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            :{" "}
            {!reportInfo?.province
              ? "Semua Provinsi"
              : reportInfo?.province?.split("|")[1]}
          </th>
        </tr>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            Kab/Kota
          </th>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            :{" "}
            {!reportInfo?.district
              ? "Semua Kab/Kota"
              : reportInfo?.district?.split("|")[1]}
          </th>
        </tr>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingTop: 0 }}>Fasyankes</th>
          <th style={{ border: "none", paddingTop: 0 }}>
            : {!reportInfo?.fasyankes ? "Semua Fasyankes" : result}
          </th>
        </tr>
      </table>
      {renderTableByLevel()}
    </div>
  );
};

export default TableComponent;
