import React from 'react';
import DateTime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import PropTypes from "prop-types";

const InputDateTime = ({
  label,
  name,
  isRequired,
  isHidden,
  placeholder,
  onChange,
  onBlur,
  onKeyPress,
  value,
  hasError,
  errorMessage,
  dateFormat,
  isValidDate,
  isInline = false,
  isDisabled,
  ...props
}) => {

  return (
    <div
      className={`form-group ${hasError ? "has-error" : ""}`}
      style={isHidden ? { display: "none" } : {}}>
      <label className={isInline ? 'mr-2' : ''}>{label} {isRequired ? <sup className='text-danger'>*</sup> : ""}</label>
      <DateTime
        dateFormat={dateFormat}
        timeFormat={false}
        closeOnClickOutside={true}
        closeOnSelect={true}
        onChange={onChange}
        onBlur={onBlur}
        onKeyPress={onKeyPress}
        value={value}
        inputProps={{ placeholder: placeholder, name: name, autoComplete: "off", disabled: isDisabled }}
        {...props}
        isValidDate={isValidDate}
      />
      {hasError && <small className="text-danger">{errorMessage}</small>}
    </div>
  );
};

InputDateTime.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  isRequired: PropTypes.bool,
  isHidden: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyPress: PropTypes.func,
  value: PropTypes.string,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  dateFormat: PropTypes.string,
};

export default InputDateTime;