import React, { Component } from "react";
import { PlusCircle } from "react-feather";
import { TableComponent } from "components";
import { UserLevel } from "utils/constant";

export default class Table extends Component {
  render() {
    const { data, changeMode, loading, onEdit, onRemove, onPageChange, user } =
      this.props;

    let headers = [];
    let cols = [];
    if (user.level === UserLevel.Province) {
      headers = ["Tahun", "Provinsi", "Nama Petugas", "No Telepon", "Email"];
      cols = [
        "year",
        ["province_id", "|", 1],
        "operator_name",
        "phone_number",
        "email",
      ];
    } else {
      headers = [
        "Tahun",
        "Provinsi",
        "Kab/Kota",
        "Nama Petugas",
        "No Telepon",
        "Email",
      ];
      cols = [
        "year",
        ["province_id", "|", 1],
        ["district_id", "|", 1],
        "operator_name",
        "phone_number",
        "email",
      ];
    }

    return (
      <div>
        <div className="row">
          <div className="col-sm-8">
            <button
              className="btn btn-teal font-weight-bold"
              onClick={() => changeMode("add")}
            >
              <PlusCircle className="mr-2" />
              Tambah Data
            </button>
          </div>
        </div>

        <TableComponent
          loading={loading}
          data={data?.populations}
          headers={headers}
          cols={cols}
          btnEdit={{ onClick: onEdit }}
          btnRemove={{ onClick: onRemove }}
          currentPage={data?.currentPage}
          total={data?.total}
          limit={data?.limit}
          nextPage={data?.nextPage}
          previousPage={data?.previousPag}
          onPageChange={onPageChange}
        />
      </div>
    );
  }
}
