import React from 'react';
import PropTypes from 'prop-types';

function DashboardWrap({ children, title }) {
  return (
    <div className="dashboard-wrapper">
      <h1 className='title font-weight-bold'>{title}</h1>
      <div className="content-wrapper mt-4">
        {children}
      </div>
    </div>
  );
}

DashboardWrap.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
};

export default DashboardWrap;