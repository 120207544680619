import { errHandler } from "../utils/common";
import api from "../utils/api";

export default class RegmalSecond {
  getAll = (opt = { page: 1, limit: 10, searchBy: "", searchValue: "" }) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/second_regmals/id`, { params: opt })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  add = (payload) => {
    return new Promise((resolve, reject) => {
      api
        .post("/api/second_regmal?device=Online", payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  update = (payload, id) => {
    return new Promise((resolve, reject) => {
      api
        .patch(`/api/second_regmal/id/${id}?device=Online`, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  remove = (id) => {
    return new Promise((resolve, reject) => {
      api
        .delete(`/api/second_regmal/${id}?device=Online`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };
}
