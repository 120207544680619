import { Button, InputField, InputSelect } from "components";
import InputDateTime from "components/InputDateTime";
import React, { Component } from "react";
import "react-datetime/css/react-datetime.css";
import { MinusCircle } from "react-feather";
import { connect } from "react-redux";
import Endemisitas from "services/Endemisitas";
import Wilayah from "services/Wilayah";
import { ErrorMessage, PageMode, requestStatus, Source } from "utils/constant";
import { toast } from "react-toastify";
import moment from "moment";
import { isEmpty } from "utils/common";

const EndemisitasService = new Endemisitas();
const WilayahService = new Wilayah();
const defaultAttr = (e) => {
  return {
    value: e ?? "",
    isValid: true,
    message: "",
  };
};
const endemisitasAttr = () => {
  return {
    district_id: defaultAttr(""),
    district_name: defaultAttr(""),
    endemisitas_year_before: defaultAttr(""),
    population: defaultAttr(""),
    eliminasi: defaultAttr(""),
  };
};

class Form extends Component {
  _isMounted = true;

  constructor(props) {
    super(props);
    this.onDrop = (files) => {
      this.setState({ files });
    };
    this.defaultValue = {
      province_id: defaultAttr(""),
      year: defaultAttr(""),

      endemisitasDynamic: [
        {
          index: 0,
          ...endemisitasAttr(),
        },
      ],

      districts: [],
    };
    this.state = Object.assign({}, this.defaultValue);
  }

  componentDidMount() {
    this._isMounted = true;
    let obj = Object.assign({}, this.state);
    if (this.props.mode === PageMode.edit) {
      const { data } = this.props;
      this.getDistrict(data.province_id);
      obj.province_id.value = data.province_id;
      obj.year.value = data.year;
      obj.endemisitasDynamic[0].district_id.value = data.district.id;
      obj.endemisitasDynamic[0].district_name.value = data.district.name;
      obj.endemisitasDynamic[0].endemisitas_year_before.value =
        data.endemisitas_year_before;
      obj.endemisitasDynamic[0].population.value = data.population;
      obj.endemisitasDynamic[0].eliminasi.value = data.eliminasi;
      this.setState(obj);
    } else {
      this.setState(obj);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onInputChange = (e) => {
    const { name, value } = e.target;
    let obj = Object.assign({}, defaultAttr(value));
    this.setState({ [name]: obj });
  };

  onEndemisitasChange = (e, item) => {
    const data = this.state.endemisitasDynamic;
    const { name, value } = e.target;

    data.map((x) => {
      if (x.index === item.index) {
        x[name] = defaultAttr(value);
      }
    });

    this.setState({ endemisitasDynamic: data });
  };

  onSave = async (e) => {
    e.preventDefault();
    if (this.onValidate()) {
      const { endemisitasDynamic } = this.state;
      let basePayload = {
        province_id: this.state.province_id.value,
        year: this.state.year.value,
      };

      switch (this.props.mode) {
        case PageMode.add:
          await Promise.all(
            endemisitasDynamic.map(async (x) => {
              return await EndemisitasService.add({
                ...basePayload,
                district_id: x.district_id.value,
                endemisitas_year_before: x.endemisitas_year_before.value,
                population: x.population.value,
                eliminasi: x.eliminasi.value,
              });
            })
          ).then((res) => {
            toast.success("Data Endemisitas Berhasil ditambahkan", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            this.setState(this.defaultValue, () => {
              this.props.changeMode("view");
            });
          });
          break;
        case PageMode.edit:
          const payload = {
            ...basePayload,
            district_id: endemisitasDynamic[0].district_id.value,
            endemisitas_year_before:
              endemisitasDynamic[0].endemisitas_year_before.value,
            population: endemisitasDynamic[0].population.value,
            eliminasi: endemisitasDynamic[0].eliminasi.value,
          };
          await EndemisitasService.update(payload, this.props.data.id)
            .then((res) => {
              toast.success("Data Endemisitas Berhasil diperbarui", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              this.setState(this.defaultValue, () => {
                this.props.changeMode("view");
              });
            })
            .catch((err) => {
              console.log(err);

              if (err.response.status === requestStatus.BadRequest) {
                toast.error(ErrorMessage.UserFaskesNotHaveAccess, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }

              if (err.response.status === requestStatus.Forbidden) {
                toast.error(ErrorMessage.UserNotVerified, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }

              if (err.response.status === requestStatus.Conflict) {
                toast.error(ErrorMessage.DataAlreadyExist, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }if (err.response.status === requestStatus.ServerError) {
                toast.error(ErrorMessage.InternalServerError, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
            });
          break;
      }
    }
  };

  onValidate = () => {
    let isValid = true;
    if (!this.validationHandler("year", "Tahun pengajuan wajib diisi")) {
      isValid = false;
    }
    if (!this.validationHandler("province_id", "Tahun pengajuan wajib diisi")) {
      isValid = false;
    }

    const customValidate = (item, msg) => {
      const cusValid = item.value !== "";
      return {
        value: item.value,
        isValid: cusValid,
        message: cusValid ? "" : msg,
      };
    };
    const newEndemisitasDynamic = this.state.endemisitasDynamic.map((e) => {
      e.district_id = customValidate(e.district_id, "Kabupaten wajib dipilih");
      e.district_name = customValidate(
        e.district_name,
        "Kabupaten wajib dipilih"
      );
      e.endemisitas_year_before = customValidate(
        e.endemisitas_year_before,
        "Endemisitas wajib dipilih"
      );
      if (!e.district_id.isValid) isValid = false;
      if (!e.district_name.isValid) isValid = false;
      if (!e.endemisitas_year_before.isValid) isValid = false;
      return e;
    });
    this.setState({ endemisitasDynamic: newEndemisitasDynamic });
    return isValid;
  };

  onYearChange = (year) => {
    this.getDistricsByProvince(year);
  };

  splitVal = (val) => {
    return val?.split("|")[1];
  };

  validationHandler = (name, errorMsg) => {
    let isValid = true;
    if (
      this.state[name].value === "" ||
      this.state[name].value === null ||
      this.state[name].value === undefined
    ) {
      this.setState({
        [name]: {
          value: "",
          isValid: false,
          message: `${errorMsg}`,
        },
      });
      isValid = false;
    }
    return isValid;
  };

  getDistrict = async (id) => {
    await WilayahService.getAll(Source.DISTRICT, {
      page: 1,
      limit: 999,
      searchBy: "province_id",
      searchValue: id,
    })
      .then((res) => {
        this.setState({
          districts: res.district,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getDistricsByProvince = async (year) => {
    const { province_id } = this.state;
    await EndemisitasService.getAvail({ province_id: province_id.value, year })
      .then(async (res) => {
        let initEndemisitas = [];
        if (res) {
          res.forEach((e, i) => {
            initEndemisitas.push({
              index: i,
              district_id: defaultAttr(e.id),
              district_name: defaultAttr(e.name),
              endemisitas_year_before: defaultAttr(""),
              population: defaultAttr(""),
              eliminasi: defaultAttr(""),
            });
          });
        }

        if (initEndemisitas.length > 0) {
          this.setState({
            endemisitasDynamic: initEndemisitas,
          });
        } else {
          this.setState({
            urbanvillages,
            endemisitasDynamic: [
              {
                index: 0,
                ...endemisitasAttr(),
              },
            ],
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  mapper = (e) => {
    const options = [];
    e.map((x) => {
      options.push({ value: `${x.id}`, label: x.name });
    });
    return options;
  };

  render() {
    const { endemisitasDynamic, province_id, districts, year } = this.state;
    const { provincies, mode } = this.props;

    return (
      <div className="form-wrapper">
        <div className="form-group">
          <p className="font-weight-bold">
            NOTE <span className="text-danger">*(WAJIB DIISI)</span>
          </p>
        </div>
        <InputSelect
          label="Provinsi"
          data={this.mapper(provincies)}
          name="province_id"
          placeholder="Pilih"
          onChange={(e) => {
            this.setState({
              year: defaultAttr(""),
              endemisitasDynamic: [
                {
                  index: 0,
                  ...endemisitasAttr(),
                },
              ],
            });
            this.onInputChange(e);
            this.getDistrict(e.target.value);
          }}
          value={province_id.value}
          isRequired={true}
          hasError={!province_id.isValid}
          errorMessage={province_id.message}
          isDisabled={mode === PageMode.edit ? true : false}
        />

        <InputDateTime
          label="Tahun"
          name="year"
          dateFormat="YYYY"
          timeFormat={false}
          onChange={(e) => {
            const year = moment(e).format("YYYY");
            this.onInputChange({
              preventDefault: () => {},
              target: {
                name: "year",
                value: year,
              },
            });
            this.onYearChange(year);
          }}
          placeholder="Pilih Tahun"
          closeOnClickOutside={true}
          value={this.state.year.value}
          isInline={true}
          isRequired={true}
          hasError={!this.state.year.isValid}
          errorMessage={this.state.year.message}
          isDisabled={
            this.state.province_id.value === "" || mode === PageMode.edit
              ? true
              : false
          }
        />

        <>
          {endemisitasDynamic.map((item, idx) => {
            return (
              <div className="row" key={item.index}>
                <div className="col">
                  <InputSelect
                    label="Kabupaten"
                    name="district_name"
                    placeholder="Pilih"
                    data={this.mapper(districts)}
                    value={item.district_id.value}
                    isRequired={true}
                    hasError={!item.district_name.isValid}
                    errorMessage={item.district_name.message}
                    isDisabled={true}
                  />
                </div>
                <div className="col">
                  <InputField
                    label="Jumlah penduduk"
                    name="population"
                    type="numberOnly"
                    placeholder="0"
                    onChange={(e) => this.onEndemisitasChange(e, item)}
                    value={item.population.value}
                    isRequired={true}
                    hasError={!item.population.isValid}
                    errorMessage={item.population.message}
                    isDisabled={isEmpty(year.value)}
                  />
                </div>
                <div className="col">
                  <InputSelect
                    label={`Kab/Kota Eliminasi Malaria ${
                      !isEmpty(year.value) ? `(${year.value})` : ""
                    }`}
                    name="eliminasi"
                    placeholder="Pilih"
                    data={[
                      { value: "Ya", label: "Ya" },
                      { value: "Tidak", label: "Tidak" },
                    ]}
                    onChange={(e) => this.onEndemisitasChange(e, item)}
                    value={item.eliminasi.value}
                    isRequired={true}
                    hasError={!item.eliminasi.isValid}
                    errorMessage={item.eliminasi.message}
                    isDisabled={isEmpty(year.value)}
                  />
                </div>
                <div className="col">
                  <InputSelect
                    label={`Endemisitas Tahun Sebelumnya ${
                      !isEmpty(year.value)
                        ? `(${parseInt(year.value) - 1})`
                        : ""
                    }`}
                    name="endemisitas_year_before"
                    placeholder="Pilih"
                    data={[
                      { value: "Eliminasi", label: "Eliminasi" },
                      { value: "Endemis Rendah", label: "Endemis Rendah" },
                      { value: "Endemis Sedang", label: "Endemis Sedang" },
                      { value: "Endemis Tinggi I", label: "Endemis Tinggi I" },
                      {
                        value: "Endemis Tinggi II",
                        label: "Endemis Tinggi II",
                      },
                      {
                        value: "Endemis Tinggi III",
                        label: "Endemis Tinggi III",
                      },
                    ]}
                    onChange={(e) => this.onEndemisitasChange(e, item)}
                    value={item.endemisitas_year_before.value}
                    isRequired={true}
                    hasError={!item.endemisitas_year_before.isValid}
                    errorMessage={item.endemisitas_year_before.message}
                    isDisabled={isEmpty(year.value)}
                  />
                </div>
                <div className="col-1 align-self-center">
                  {mode !== PageMode.edit && (
                    <div style={{ paddingTop: "30px" }}>
                      <button
                        className="btn btn-outline-danger btn-sm font-weight-bold mr-2"
                        onClick={() => {
                          const data = this.state.endemisitasDynamic;
                          data.splice(idx, 1);
                          this.setState({ endemisitasDynamic: data });
                        }}
                      >
                        <MinusCircle size={30} className="mr-2" /> Hapus
                      </button>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </>

        <div className="form-group d-flex justify-content-end">
          <Button onClick={() => this.props.changeMode("view")} isSecondary>
            Batalkan
          </Button>
          <Button onClick={this.onSave} isPrimary>
            Simpan Data
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authReducer.currentUser,
  };
};

export default connect(mapStateToProps)(Form);
