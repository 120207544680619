import React, { useEffect, useState } from "react";
import { PageMode } from "utils/constant";
import TableComponent from "./components/TableComponent";

const IndigenousFeedback = () => {
  const [pageMode, setPageMode] = useState(PageMode.view);

  useEffect(() => {
    document.title = "SISMAL | Laporan Data Indigenous";
  }, []);

  return (
    <div className="dashboard-wrapper">
      {pageMode === PageMode.view && <TableComponent />}
    </div>
  );
};

export default IndigenousFeedback;
