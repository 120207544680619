import { BreadcrumbNav } from "components/molecules";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  LogisticInputStockOptions,
  LogisticInputStockOptionsNonPKM,
  MonthOptions,
  PageMode,
} from "utils/constant";
import { useFormik } from "formik";
import moment from "moment";
import LogisticInputStock from "services/LogisticInputStock";
import {
  errorToastNotif,
  getDataFromLocalStorage,
  successToastNotif,
} from "utils/common";
import InputDate from "components/atoms/InputDate";
import InputSelect from "components/atoms/InputSelect";
import InputField from "components/atoms/InputField";
import Button from "components/atoms/Button";

const LogisticInputStockService = new LogisticInputStock();

const Form = ({ pageMode, changeMode }) => {
  const [selectedItem, setSelectedItem] = useState(null);

  const { currentUser } = useSelector((state) => state.authReducer);

  const formik = useFormik({
    initialValues: {
      province_id: "",
      district_id: "",
      year: "",
      month: "",
      logistic_type: "",
      stock: "",
      batch_number: "",
      ed_date: "",
      level: "",
    },
    validate: (values) => {
      const errors = {};

      if (!values.year) {
        errors.year = "Tahun Wajib diisi";
      }

      if (!values.month) {
        errors.month = "Bulan wajib diisi";
      }

      if (!values.logistic_type) {
        errors.logistic_type = "Jenis Logistik wajib diisi";
      }

      if (!values.stock) {
        errors.stock = "Stok wajib diisi";
      } else if (/^0\d+/.test(values.stock)) {
        errors.stock = "Stok tidak boleh diawali dengan angka 0";
      }

      if (
        formik.values.logistic_type !== "Microscope Binocular (Buah)" &&
        formik.values.logistic_type !== "Microscope Trinocular (Buah)" &&
        formik.values.logistic_type !== "Mist Blower (Buah)" &&
        formik.values.logistic_type !== "Lab Kit (Paket)" &&
        formik.values.logistic_type !== "Giemsa (CC)" &&
        formik.values.logistic_type !== "Minyak Imersi (CC)" &&
        formik.values.logistic_type !== "Blood Slide (Box)" &&
        formik.values.logistic_type !== "Blood Lancet (Box)" &&
        formik.values.logistic_type !== "Methanol (CC)" &&
        formik.values.logistic_type !== "LLIN (Lembar)" &&
        formik.values.logistic_type !== "Spray Can (Buah)" &&
        formik.values.logistic_type !== "Larvasida (Kg)" &&
        formik.values.logistic_type !== "Larvasida (Liter)" &&
        formik.values.logistic_type !== "Insektisida (Kg)" &&
        formik.values.logistic_type !== "Insektisida (Liter)" &&
        formik.values.logistic_type !== "Slide Box (Buah)" &&
        formik.values.logistic_type !== "Microscope Teaching (Buah)" &&
        formik.values.stock > 0
      ) {
        if (!values.batch_number) {
          errors.batch_number = "Nomor Batch & Sumber Pendanaan wajib diisi";
        }
      }

      if (
        formik.values.logistic_type !== "Microscope Binocular (Buah)" &&
        formik.values.logistic_type !== "Microscope Trinocular (Buah)" &&
        formik.values.logistic_type !== "Mist Blower (Buah)" &&
        formik.values.logistic_type !== "Lab Kit (Paket)" &&
        formik.values.logistic_type !== "Giemsa (CC)" &&
        formik.values.logistic_type !== "Minyak Imersi (CC)" &&
        formik.values.logistic_type !== "Blood Slide (Box)" &&
        formik.values.logistic_type !== "Blood Lancet (Box)" &&
        formik.values.logistic_type !== "LLIN (Lembar)" &&
        formik.values.logistic_type !== "Spray Can (Buah)" &&
        formik.values.logistic_type !== "Slide Box (Buah)" &&
        formik.values.logistic_type !== "Microscope Teaching (Buah)" &&
        formik.values.stock > 0
      ) {
        if (!values.ed_date) {
          errors.ed_date = "Tanggal Kadaluarsa wajib diisi";
        }
      }

      return errors;
    },
    onSubmit: async (values) => {
      let payload = {
        province_id: currentUser?.province_id,
        district_id: currentUser?.district_id,
        year: moment(values.year).format("YYYY"),
        month: values.month,
        logistic_type: values.logistic_type,
        stock: values.stock,
        batch_number: values.batch_number,
        ed_date: values.ed_date,
        level: "District",
      };

      switch (pageMode) {
        case PageMode.add:
          await LogisticInputStockService.add(payload)
            .then((res) => {
              changeMode(PageMode.view);
              successToastNotif("Data Input Logistik Berhasil Ditambahkan");
            })
            .catch((err) => {
              console.log(err);
              errorToastNotif(err);
            });
          break;
        case PageMode.edit:
          await LogisticInputStockService.update(payload, selectedItem?.id)
            .then((res) => {
              changeMode(PageMode.view);
              successToastNotif("Data Input Logistik Berhasil Diperbarui");
            })
            .catch((err) => {
              console.log(err);
              errorToastNotif(err);
            });
          break;
      }
    },
  });

  const renderFieldNoBatchByCondition = () => {
    const value = formik.values.logistic_type;
    if (
      value !== "Microscope Binocular (Buah)" &&
      value !== "Microscope Trinocular (Buah)" &&
      value !== "Mist Blower (Buah)" &&
      value !== "Lab Kit (Paket)" &&
      value !== "Methanol (CC)" &&
      value !== "Blood Slide (Box)" &&
      value !== "Blood Lancet (Box)" &&
      value !== "Spray Can (Buah)" &&
      value !== "Slide Box (Buah)" &&
      value !== "Microscope Teaching (Buah)" &&
      formik.values.stock > 0
    ) {
      return (
        <InputField
          label={"Nomor Batch & Sumber Pendanaan"}
          name={"batch_number"}
          type={"text"}
          placeholder={"Masukkan Nomor Batch & Sumber Pendanaan"}
          {...formik.getFieldProps("batch_number")}
          error={formik.errors.batch_number && formik.touched.batch_number}
          errorMessage={formik.errors.batch_number}
          isRequired={true}
          disabled={pageMode === PageMode.edit}
        />
      );
    } else {
      return null;
    }
  };

  const renderFieldExpiredDateByCondition = () => {
    const value = formik.values.logistic_type;
    if (
      value !== "Microscope Binocular (Buah)" &&
      value !== "Microscope Trinocular (Buah)" &&
      value !== "Mist Blower (Buah)" &&
      value !== "Lab Kit (Paket)" &&
      value !== "Blood Slide (Box)" &&
      value !== "Blood Lancet (Box)" &&
      value !== "Spray Can (Buah)" &&
      value !== "Slide Box (Buah)" &&
      value !== "Microscope Teaching (Buah)" &&
      formik.values.stock > 0
    ) {
      return (
        <InputDate
          label={"Tanggal Expired"}
          name={"ed_date"}
          dateFormat={"YYYY-MM-DD"}
          placeholder={"Masukkan Tanggal Expired"}
          onChange={(e) => {
            const selectedDate = moment(e).format("YYYY-MM-DD");
            formik.handleChange("ed_date")(selectedDate);
          }}
          onBlur={formik.handleBlur}
          value={formik.values.ed_date}
          error={formik.errors.ed_date && formik.touched.ed_date}
          errorMessage={formik.errors.ed_date}
          isRequired={true}
        />
      );
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (pageMode === PageMode.edit) {
      const dataFromStorage = JSON.parse(
        getDataFromLocalStorage("SELECTED_ITEM")
      );

      setSelectedItem(dataFromStorage);

      formik.setValues({
        ...dataFromStorage,
      });
    }
  }, []);

  return (
    <>
      <BreadcrumbNav
        items={[
          {
            label: "Input Logistik - Input Stok",
            onClick: () => changeMode(PageMode.view),
          },
          {
            label:
              pageMode === PageMode.add
                ? "Tambah Data Input Stok"
                : "Edit Data Input Stok",
          },
        ]}
      />
      <h1 className="page-title">
        {pageMode === PageMode.add
          ? "Tambah Data Input Stok"
          : "Edit Data Input Stok"}
      </h1>
      {/* Informasi wilayah */}
      <div className="informasi-wilayah">
        <section className="list-item">
          <p className="item-label">Provinsi</p>
          <p className="item-value">
            {currentUser?.province_id?.split("|")[1]}
          </p>
        </section>
        <hr />
        <section className="list-item">
          <p className="item-label">Kab/Kota</p>
          <p className="item-value">
            {currentUser?.district_id?.split("|")[1]}
          </p>
        </section>
      </div>

      <div className="form-grouping">
        <p className="warning" style={{ marginBottom: "24px" }}>
          NOTE<span>*(WAJIB DIISI)</span>
        </p>

        <InputDate
          label={"Tahun"}
          name={"year"}
          placeholder={"Pilih Tahun"}
          dateFormat={"YYYY"}
          onChange={(e) => {
            const selectedYear = moment(e).format("YYYY");
            formik.handleChange("year")(selectedYear);
          }}
          onBlur={formik.handleBlur}
          value={formik.values.year}
          error={formik.errors.year && formik.touched.year}
          errorMessage={formik.errors.year}
          isRequired={true}
          isDisabled={pageMode === PageMode.edit}
        />

        <InputSelect
          label={"Bulan"}
          name={"month"}
          placeholder={"Pilih Bulan"}
          data={MonthOptions}
          onChange={(e) => {
            const selectedValue = e ? e.value : "";
            formik.handleChange("month")(selectedValue);
          }}
          onBlur={formik.handleBlur}
          value={MonthOptions.find(
            (item) => item.value === formik.values.month
          )}
          error={formik.errors.month && formik.touched.month}
          errorMessage={formik.errors.month}
          isRequired={true}
          isDisabled={pageMode === PageMode.edit}
        />

        <InputSelect
          label={"Jenis Logistik"}
          name={"logistic_type"}
          placeholder={"Pilih Jenis Logistik"}
          data={LogisticInputStockOptions}
          onChange={(e) => {
            const selectedValue = e ? e.value : "";
            formik.handleChange("logistic_type")(selectedValue);
          }}
          onBlur={formik.handleBlur}
          value={LogisticInputStockOptions.find(
            (item) => item.value === formik.values.logistic_type
          )}
          error={formik.errors.logistic_type && formik.touched.logistic_type}
          errorMessage={formik.errors.logistic_type}
          isRequired={true}
          isDisabled={pageMode === PageMode.edit}
        />

        <InputField
          label={"Stok"}
          name={"stock"}
          type={"decimal"}
          maxLengthVal={12}
          placeholder={"Masukkan Jumlah Stok"}
          {...formik.getFieldProps("stock")}
          error={formik.errors.stock && formik.touched.stock}
          errorMessage={formik.errors.stock}
          isRequired={true}
        />

        {renderFieldNoBatchByCondition()}

        {renderFieldExpiredDateByCondition()}

        <div className="form_action">
          <Button
            variant={"tertiary"}
            size={"normal"}
            onClick={() => changeMode(PageMode.view)}
          >
            Batalkan
          </Button>
          <Button
            variant={"primary"}
            size={"normal"}
            onClick={formik.handleSubmit}
            isDisabled={
              formik.isSubmitting ||
              !formik.values.year ||
              !formik.values.month ||
              !formik.values.logistic_type ||
              !formik.values.stock
            }
          >
            Simpan Data
          </Button>
        </div>
      </div>
    </>
  );
};

export default Form;
