import {
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import ActionButton from "components/atoms/ActionButton";
import Button from "components/atoms/Button";
import InputField from "components/atoms/InputField";
import TableDataComponent from "components/organisms/TableData";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { useSelector } from "react-redux";
import FlickIdentification from "services/FlickIdentification";
import {
  errorToastNotif,
  setDataToLocalStorage,
  successToastNotif,
} from "utils/common";
import { PageMode } from "utils/constant";
import { useHistory } from "react-router-dom";

const FlickIdentificationService = new FlickIdentification();

const TableComponent = ({ changeMode }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [dataState, setDataState] = useState({
    page: 1,
    limit: 10,
    data: [],
    totalData: 0,
    currentPage: 1,
    nextPage: null,
    previousPage: null,
  });

  const { currentUser } = useSelector((state) => state.authReducer);
  const history = useHistory();

  const fetchFlickIdentificationData = async () => {
    setIsLoading(true);
    try {
      let searchBy = `fasyankes_id${
        searchKeyword !== "" ? `,urbanvillage_id` : ""
      }`;
      let searchValue = `${currentUser?.fasyankes_id}${
        searchKeyword !== "" ? `,${searchKeyword}` : ""
      }`;

      const response = await FlickIdentificationService.getAll({
        page: dataState.page,
        limit: dataState.limit,
        searchBy,
        searchValue,
      });

      setDataState((prevState) => ({
        ...prevState,
        data: response.vector_larva_identifications,
        limit: response.limit,
        currentPage: response.currentPage,
        totalData: response.total,
        nextPage: response.nextPage,
        previousPage: response.previousPage,
      }));
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchFlickIdentificationData();
  }, [dataState.page, searchKeyword]);

  const onPageChange = (page) => {
    setDataState((prevState) => ({
      ...prevState,
      page: page,
    }));
  };

  const onEditData = (item) => {
    setDataToLocalStorage("SELECTED_ITEM", JSON.stringify(item));
    changeMode(PageMode.edit);
  };

  const onRemoveData = (id) => {
    confirmAlert({
      title: "Apakah anda yakin ingin menghapus data?",
      message: "Data akan dihapus secara permanen",
      buttons: [
        {
          label: "Ya",
          onClick: async () => {
            await FlickIdentificationService.remove(id)
              .then((res) => {
                fetchFlickIdentificationData();
                successToastNotif("Data Identifikasi Jentik Berhasil Dihapus");
              })
              .catch((err) => {
                console.log(err);
                errorToastNotif(err);
              });
          },
        },
        {
          label: "Tidak",
        },
      ],
    });
  };

  const columns = [
    {
      header: "Aksi",
      accessorKey: "action",
      cell: ({ row }) => (
        <div className="button-actions">
          <ActionButton
            onClick={() => onEditData(row.original)}
            btnType={"edit"}
          />
          <ActionButton
            onClick={() => onRemoveData(row.original.id)}
            btnType={"delete"}
          />
          {row.original.anopheles_larva_found === "Ya" && (
            <ActionButton
              onClick={() =>
                history.push(
                  `/data-intervensi-perindukan-nyamuk/${row.original.id}`
                )
              }
              btnType={"intervensi"}
            />
          )}
        </div>
      ),
    },
    {
      header: "Tanggal Survey",
      accessorKey: "survey_date",
    },
    {
      header: "Nama Desa",
      accessorKey: "urbanvillage_id",
      cell: ({ row }) => row.original.urbanvillage_id?.split("|")[1],
    },
    {
      header: "Nama Dusun",
      accessorKey: "sub_village",
    },
    {
      header: "Koordinat",
      accessorKey: "coordinate",
    },
    {
      header: "Jenis Tempat Perindukan",
      accessorKey: "type_of_breeding_place"
    },
    {
      header: "Jumlah Cidukan",
      accessorKey: "total_catch",
    },
    {
      header: "Ditemukan Jentik Anopheles",
      accessorKey: "anopheles_larva_found",
    },
  ];

  const tableInstance = useReactTable({
    columns,
    data: dataState?.data,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <>
      <h1 className="page-title">
        Data Identifikasi Jentik & Intervensi Tempat Perindukan
      </h1>
      <div
        className="action-wrapper"
        style={{ marginTop: "24px", marginBottom: "24px" }}
      >
        <div className="action-button">
          {currentUser?.mode === "Online" && (
            <Button
              variant={"primary"}
              size={"normal"}
              onClick={() => changeMode(PageMode.add)}
            >
              Tambah Data
            </Button>
          )}
        </div>
        <div className="action-search">
          <InputField
            placeholder={"Cari berdasarkan nama desa..."}
            onChange={(e) => {
              setSearchKeyword(e.target.value);
            }}
            value={searchKeyword}
          />
        </div>
      </div>

      {/* Table data component */}
      <TableDataComponent
        columnLength={columns.length}
        tableInstance={tableInstance}
        onPageChange={onPageChange}
        loading={isLoading}
        currentPage={dataState?.currentPage}
        limit={dataState?.limit}
        nextPage={dataState?.nextPage}
        previousPage={dataState?.previousPage}
        total={dataState?.totalData}
      />
    </>
  );
};

export default TableComponent;
