import React, { Component } from "react";
import { PlusCircle } from "react-feather";
import { TableComponent } from "components";
import Datetime from "react-datetime";

export default class Table extends Component {
  render() {
    const {
      onFilterData,
      handleChangeYear,
      data,
      changeMode,
      loading,
      onPageChange,
      onEdit,
      onRemove,
      currentUser,
    } = this.props;

    return (
      <div>
        <div className="filter-data">
          <div className="row justify-content-start py-3">
            <div className="col-sm-6">
              <form className="form-horizontal">
                <div className="form-group row justify-content-start">
                  <label className="col-sm-3 col-form-label align-self-center">
                    Pilih Tahun
                  </label>
                  <div className="col-sm-4 align-self-center">
                    <Datetime
                      dateFormat="YYYY"
                      timeFormat={false}
                      closeOnClickOutside={true}
                      closeOnSelect={true}
                      onChange={handleChangeYear}
                      value={this.props.selected_year}
                      inputProps={{
                        name: "selected_year",
                        placeholder: "Pilih Tahun",
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row justify-content-start">
                  <div className="col-sm-7">
                    <button
                      className="btn btn-teal btn-block"
                      onClick={(e) => {
                        e.preventDefault();
                        onFilterData();
                      }}
                    >
                      Terapkan
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <hr />

        <div className="row">
          <div className="col-sm-4 align-self-center">
            {currentUser && currentUser.mode === "Online" && (
              <button
                className="btn btn-teal font-weight-bold"
                onClick={() => changeMode("add")}
              >
                <PlusCircle className="mr-2" />
                Tambah Data Sasaran
              </button>
            )}
          </div>
        </div>

        <TableComponent
          loading={loading}
          data={data?.kia_screening_targets}
          headers={[
            "Tahun Capaian",
            "Fasyankes",
            "Jumlah Sasaran Ibu Hamil",
            "Jumlah Sasaran Kunjungan Balita Sakit",
          ]}
          cols={[
            "year",
            ["fasyankes_id", "|", 1],
            "total_target_pregnant",
            "total_target_toddler",
          ]}
          btnEdit={{ onClick: onEdit }}
          btnRemove={{ onClick: onRemove }}
          btnAchievment={true}
          urlPathAchievment="data-capaian"
          currentPage={data?.currentPage}
          total={data?.total}
          limit={data?.limit}
          nextPage={data?.nextPage}
          previousPage={data?.previousPage}
          onPageChange={onPageChange}
        />
      </div>
    );
  }
}
