import InputDate from "components/atoms/InputDate";
import InputSelect from "components/atoms/InputSelect";
import React, { useEffect } from "react";
import { useState } from "react";
import { FiArrowDown, FiArrowUp } from "react-icons/fi";
import { MonthOptions, PageMode } from "utils/constant";
import RegmalSecond from "services/RegmalSecond";
import { useSelector } from "react-redux";
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import ActionButton from "components/atoms/ActionButton";
import TableDataComponent from "components/organisms/TableData";
import {
  errorToastNotif,
  monthValueConverter,
  setDataToLocalStorage,
  splitter,
} from "utils/common";
import Button from "components/atoms/Button";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import moment from "moment";

const RegmalSecondService = new RegmalSecond();

const TableComponent = ({ changeMode }) => {
  const [showFilter, setShowFilter] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [filterState, setFilterState] = useState({
    year: "",
    month: "",
  });
  const [regmalState, setRegmalState] = useState({
    page: 1,
    limit: 10,
    data: [],
    totalData: 0,
    currentPage: 1,
    nextPage: null,
    previousPage: null,
  });

  const { currentUser } = useSelector((state) => state.authReducer);

  const getRegmalSecondData = async () => {
    setIsLoading(true);
    try {
      const response = await RegmalSecondService.getAll({
        page: regmalState.page,
        limit: regmalState.limit,
        searchBy: "fasyankes_id,year_report,month_report",
        searchValue: `${currentUser?.fasyankes_id},${filterState.year},${filterState.month}`,
      });

      setRegmalState((prevState) => ({
        ...prevState,
        data: response.second_regmals,
        limit: response.limit,
        currentPage: response.currentPage,
        totalData: response.total,
        nextPage: response.nextPage,
        previousPage: response.previousPage,
      }));
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getRegmalSecondData();
  }, [regmalState.page]);

  const onPageChange = (page) => {
    setRegmalState((prevState) => ({
      ...prevState,
      page: page,
    }));
  };

  const onRemoveData = (id) => {
    confirmAlert({
      title: "Apakah anda yakin ingin menghapus data?",
      message: "Data akan dihapus secara permanen",
      buttons: [
        {
          label: "Ya",
          onClick: async () => {
            await RegmalSecondService.remove(id)
              .then((res) => {
                getRegmalSecondData();
                toast.success("Data Register Malaria 2 Berhasil Dihapus", {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  theme: "colored",
                });
              })
              .catch((err) => {
                console.log(err);
                errorToastNotif(err);
              });
          },
        },
        {
          label: "Tidak",
        },
      ],
    });
  };

  const onEditData = (item) => {
    setDataToLocalStorage("SELECTED_ITEM", JSON.stringify(item));
    changeMode(PageMode.edit);
  };

  const columns = [
    {
      header: "Aksi",
      accessorKey: "action",
      cell: ({ row }) => (
        <div className="button-actions">
          <ActionButton
            onClick={() => onEditData(row.original)}
            btnType={"edit"}
          />
          <ActionButton
            onClick={() => onRemoveData(row.original.id)}
            btnType={"delete"}
          />
        </div>
      ),
    },
    {
      header: "Tahun",
      accessorKey: "year_report",
    },
    {
      header: "Bulan",
      accessorKey: "month_report",
      cell: ({ row }) => monthValueConverter(row.original.month_report),
    },
    {
      header: "Provinsi",
      accessorKey: "province_id",
      cell: ({ row }) => row.original.province_id?.split("|")[1],
    },
    {
      header: "Kabupaten/Kota",
      accessorKey: "district_id",
      cell: ({ row }) => row.original.district_id?.split("|")[1],
    },
    {
      header: "Fasyankes",
      accessorKey: "fasyankes_id",
      cell: ({ row }) => row.original.fasyankes_id?.split("|")[1],
    },
    {
      header: "Kegiatan Penemuan",
      accessorKey: "discovery_activities",
    },
  ];

  if (currentUser?.fasyankes_type_id === "jnsfas-01") {
    columns.push({
      header: "Asal Penemuan",
      accessorKey: "case_from",
    });
  }

  const tableInstance = useReactTable({
    columns,
    data: regmalState?.data,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <>
      <h1 className="page-title">Register Malaria 2</h1>

      {/* Filter */}
      <div
        className="filter"
        style={{ marginTop: "24px", marginBottom: "24px" }}
      >
        <div className="filter-header">
          <p>FILTER</p>
          <button
            className="btn_toggle_filter"
            onClick={() => setShowFilter(!showFilter)}
          >
            {showFilter ? <FiArrowUp size={18} /> : <FiArrowDown size={18} />}
          </button>
        </div>
        {showFilter && (
          <div className="filter-body">
            <div className="filter-item">
              <div className="filter-label">
                <p>Tahun Laporan</p>
              </div>
              <div className="filter-field">
                <InputDate
                  placeholder={"Pilih Tahun"}
                  dateFormat={"YYYY"}
                  onChange={(e) => {
                    const selectedyear = moment(e).format("YYYY");
                    setFilterState({
                      ...filterState,
                      year: selectedyear,
                    });
                  }}
                  value={filterState.year}
                />
              </div>
            </div>
            <div className="filter-item">
              <div className="filter-label">
                <p>Bulan Laporan</p>
              </div>
              <div className="filter-field">
                <InputSelect
                  placeholder={"Pilih Bulan"}
                  data={MonthOptions}
                  onChange={(e) => {
                    const selectedValue = e ? e.value : "";
                    setFilterState({
                      ...filterState,
                      month: selectedValue,
                    });
                  }}
                  value={MonthOptions.find(
                    (item) => item.value === filterState.month
                  )}
                />
              </div>
            </div>
            <div className="filter-action">
              <Button
                variant={"primary"}
                size={"normal"}
                onClick={() => getRegmalSecondData()}
              >
                Terapkan
              </Button>
            </div>
          </div>
        )}
      </div>

      {/* Actions */}
      <div className="action-wrapper">
        <div className="action-button">
          {currentUser && currentUser.mode === "Online" && (
            <Button
              variant={"primary"}
              size={"normal"}
              onClick={() => changeMode(PageMode.add)}
            >
              Tambah Data Register Malaria 2
            </Button>
          )}
        </div>
      </div>

      <TableDataComponent
        columnLength={columns?.length}
        tableInstance={tableInstance}
        onPageChange={onPageChange}
        loading={isLoading}
        currentPage={regmalState.currentPage}
        limit={regmalState.limit}
        nextPage={regmalState.nextPage}
        previousPage={regmalState.previousPage}
        total={regmalState.totalData}
      />
    </>
  );
};

export default TableComponent;
