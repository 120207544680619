import React, { useEffect, useState } from "react";
import "./ModalDesaForm.scss";
import Button from "components/atoms/Button";
import InputRadio from "components/atoms/InputRadio";
import InputField from "components/atoms/InputField";
import InputSelect from "components/atoms/InputSelect";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  addPopulation,
  editPopulation,
  itemPopulationToEdit,
} from "redux/actions/populationAction";
import { toast } from "react-toastify";
import { PageMode } from "utils/constant";
import Populasi from "services/Populasi";

const PopulasiService = new Populasi();

const ModalDesaForm = ({ urbanvillages, pageMode }) => {
  const [isLoadingChecking, setIsLoadingChecking] = useState(false);

  const dispatch = useDispatch();
  const itemToEdit = useSelector((state) => state.populationReducer.itemToEdit);

  const urbanVillagesData = useSelector(
    (state) => state.populationReducer.urbanVillages
  );

  const existingUrbanVillageIds = urbanVillagesData.map(
    (village) => village.urbanvillage_id
  );

  const formik = useFormik({
    initialValues: {
      have_urbanvillage_id: "",
      urbanvillage_name: "",
      urbanvillage_id: "",
      total_population: "",
      reseptifitas: "",
    },
    validationSchema: Yup.object({
      have_urbanvillage_id: Yup.string().required(
        "Status kode desa wajib dipilih"
      ),
      urbanvillage_name: Yup.string().required("Nama desa wajib diisi"),
      urbanvillage_id: Yup.string().required("Kode desa wajib diisi"),
      total_population: Yup.number("Total populasi berupa angka").required(
        "Total populasi wajib diisi"
      ),
      reseptifitas: Yup.string().required("Data reseptifitas wajib dipilih"),
    }),
    onSubmit: (values) => {
      if (values) {
        if (!itemToEdit) {
          dispatch(
            addPopulation({
              id: new Date().getTime(),
              ...values,
            })
          );

          toast.success(`Data Desa Berhasil di Tambah`, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
            theme: "colored",
          });
        } else {
          dispatch(
            editPopulation({
              id: itemToEdit.id,
              ...values,
            })
          );

          toast.success("Data Desa Berhasil di Update", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
            theme: "colored",
          });

          if (pageMode === PageMode.edit) {
            dispatch(itemPopulationToEdit(values));
          } else {
            dispatch(itemPopulationToEdit(null));
          }
        }
        formik.resetForm();
      }
    },
  });

  const checkVillageId = async (value) => {
    if (formik.values.have_urbanvillage_id === "Tidak" || value.length <= 10) {
      setIsLoadingChecking(true);
      try {
        const response = await PopulasiService.checkVillageId(value);
        if (response.status === 200) {
          formik.setErrors({
            urbanvillage_id: "Kode desa sudah ada",
          });
        } else {
          formik.setErrors({ urbanvillage_id: "" });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoadingChecking(false);
      }
    }
  };

  const handleUrbanVillageSelectChange = (selectedValue) => {
    const selectedUrbanVillage = urbanvillages.find(
      (urbanvillage) => urbanvillage.name === selectedValue
    );

    if (selectedUrbanVillage) {
      formik.setFieldValue("urbanvillage_name", selectedValue);
      formik.setFieldValue("urbanvillage_id", selectedUrbanVillage.id);
    }
  };

  useEffect(() => {
    if (itemToEdit) {
      formik.setValues({
        have_urbanvillage_id: itemToEdit?.have_urbanvillage_id,
        urbanvillage_name: itemToEdit?.urbanvillage_name,
        urbanvillage_id: itemToEdit?.urbanvillage_id,
        total_population: itemToEdit?.total_population,
        reseptifitas: itemToEdit?.reseptifitas,
      });
    }
  }, [itemToEdit]);

  return (
    <div
      className="modal fade"
      id="formDesaModal"
      tabIndex={-1}
      aria-labelledby="formDesaModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="formDesaModalLabel">
              {itemToEdit === null ? "Tambah Desa" : "Edit Desa"}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div
            className="modal-body"
            style={{ display: "flex", flexDirection: "column", gap: "16px" }}
          >
            <InputRadio
              label="Ada Kode Desa?"
              name="have_urbanvillage_id"
              options={[
                { label: "Ya", value: "Ya" },
                { label: "Tidak", value: "Tidak" },
              ]}
              selectedOption={formik.values.have_urbanvillage_id}
              onChange={(e) => {
                const selectedValue = e.target.value;
                formik.handleChange("have_urbanvillage_id")(selectedValue);
                if (selectedValue !== "Ya") {
                  formik.setFieldValue("urbanvillage_name", "");
                  formik.setFieldValue("urbanvillage_id", "");
                }
              }}
              error={
                formik.errors.have_urbanvillage_id &&
                formik.touched.have_urbanvillage_id
              }
              errorMessage={formik.errors.have_urbanvillage_id}
              disabled={pageMode === PageMode.edit}
              isRequired
            />

            {pageMode === PageMode.edit ? (
              <InputField
                type={"text"}
                label={"Nama Desa"}
                name={"urbanvillage_name"}
                placeholder={"Isi nama desa disini"}
                onChange={(e) => {
                  const value = e.target.value;
                  formik.handleChange("urbanvillage_name")(value);
                  if (formik.values.have_urbanvillage_id === "Tidak") {
                    checkVillageId(formik.values.urbanvillage_id);
                  }
                }}
                onBlur={(e) => {
                  formik.handleBlur(e);
                  if (formik.values.have_urbanvillage_id === "Tidak") {
                    checkVillageId(formik.values.urbanvillage_id);
                  }
                }}
                value={formik.values.urbanvillage_name}
                error={
                  formik.errors.urbanvillage_name &&
                  formik.touched.urbanvillage_name
                }
                errorMessage={formik.errors.urbanvillage_name}
                disabled={pageMode === PageMode.edit}
                isRequired
              />
            ) : (
              <>
                {formik.values.have_urbanvillage_id === "Ya" ? (
                  <InputSelect
                    useDefaultElement={true}
                    label={"Nama Desa"}
                    name={"urbanvillage_name"}
                    placeholder={"Pilih Desa"}
                    data={urbanvillages
                      .filter(
                        (urbanvillage) =>
                          !existingUrbanVillageIds.includes(urbanvillage.id)
                      )
                      .map((urbanvillage) => ({
                        label: urbanvillage.name,
                        value: urbanvillage.name,
                      }))}
                    onChange={(e) => {
                      const selectedValue = e ? e.value : "";
                      formik.handleChange("urbanvillage_name")(selectedValue);
                      handleUrbanVillageSelectChange(selectedValue);
                    }}
                    onBlur={(e) => {
                      formik.handleBlur(e);
                      if (formik.values.have_urbanvillage_id === "Tidak") {
                        checkVillageId(formik.values.urbanvillage_id);
                      }
                    }}
                    value={
                      formik.values.urbanvillage_name
                        ? {
                          label: formik.values.urbanvillage_name,
                          value: formik.values.urbanvillage_name,
                        }
                        : null
                    }
                    error={
                      formik.errors.urbanvillage_name &&
                      formik.touched.urbanvillage_name
                    }
                    errorMessage={formik.errors.urbanvillage_name}
                    isRequired
                  />
                ) : (
                  <InputField
                    type={"text"}
                    label={"Nama Desa"}
                    name={"urbanvillage_name"}
                    placeholder={"Isi nama desa disini"}
                    onChange={(e) => {
                      const value = e.target.value;
                      formik.handleChange("urbanvillage_name")(value);
                      if (formik.values.have_urbanvillage_id === "Tidak") {
                        checkVillageId(formik.values.urbanvillage_id);
                      }
                    }}
                    onBlur={(e) => {
                      formik.handleBlur(e);
                      if (formik.values.have_urbanvillage_id === "Tidak") {
                        checkVillageId(formik.values.urbanvillage_id);
                      }
                    }}
                    value={formik.values.urbanvillage_name}
                    error={
                      formik.errors.urbanvillage_name &&
                      formik.touched.urbanvillage_name
                    }
                    errorMessage={formik.errors.urbanvillage_name}
                    isRequired
                  />
                )}
              </>
            )}

            <InputField
              type={"number"}
              label={"Kode Desa"}
              name={"urbanvillage_id"}
              placeholder={"Isi kode desa disini"}
              maxLengthVal={10}
              onChange={(e) => {
                const value = e.target.value;
                formik.handleChange("urbanvillage_id")(value);
                if (formik.values.have_urbanvillage_id === "Tidak") {
                  checkVillageId(e.target.value);
                }
              }}
              onBlur={(e) => {
                formik.handleBlur(e);
                if (formik.values.have_urbanvillage_id === "Tidak") {
                  checkVillageId(formik.values.urbanvillage_id);
                }
              }}
              value={formik.values.urbanvillage_id}
              disabled={formik.values.have_urbanvillage_id === "Ya"}
              error={
                formik.errors.urbanvillage_id && formik.touched.urbanvillage_id
              }
              errorMessage={formik.errors.urbanvillage_id}
              isRequired
            />
            <InputField
              type={"number"}
              label={"Jumlah Penduduk"}
              name={"total_population"}
              placeholder={"Isi total populasi disini"}
              onChange={(e) => {
                const value = e.target.value;
                formik.handleChange("total_population")(value);
                if (formik.values.have_urbanvillage_id === "Tidak") {
                  checkVillageId(formik.values.urbanvillage_id);
                }
              }}
              onBlur={(e) => {
                formik.handleBlur(e);
                if (formik.values.have_urbanvillage_id === "Tidak") {
                  checkVillageId(formik.values.urbanvillage_id);
                }
              }}
              value={formik.values.total_population}
              error={
                formik.errors.total_population &&
                formik.touched.total_population
              }
              errorMessage={formik.errors.total_population}
              maxLengthVal={7}
              isRequired
            />
            <InputRadio
              label="Data Reseptifitas"
              name="reseptifitas"
              options={[
                { label: "Ya", value: "Ya" },
                { label: "Tidak", value: "Tidak" },
                { label: "NA", value: "NA" },
              ]}
              onChange={(e) => {
                const selectedValue = e.target.value;
                formik.handleChange("reseptifitas")(selectedValue);
                if (formik.values.have_urbanvillage_id === "Tidak") {
                  checkVillageId(formik.values.urbanvillage_id);
                }
              }}
              onBlur={(e) => {
                formik.handleBlur(e);
                if (formik.values.have_urbanvillage_id === "Tidak") {
                  checkVillageId(formik.values.urbanvillage_id);
                }
              }}
              selectedOption={formik.values.reseptifitas}
              error={formik.errors.reseptifitas && formik.touched.reseptifitas}
              errorMessage={formik.errors.reseptifitas}
              isRequired
            />
          </div>
          <div className="modal-footer">
            <Button
              variant={"tertiary"}
              size={"normal"}
              data-dismiss="modal"
              onClick={() => {
                if (pageMode !== PageMode.edit) {
                  dispatch(itemPopulationToEdit(null));
                }
              }}
            >
              Batal
            </Button>
            <Button
              onClick={formik.handleSubmit}
              variant={"primary"}
              size={"normal"}
              data-dismiss="modal"
              isDisabled={
                !formik.values.have_urbanvillage_id ||
                !formik.values.urbanvillage_name ||
                !formik.values.urbanvillage_id ||
                !formik.values.total_population ||
                !formik.values.reseptifitas ||
                // !formik.isValid ||
                isLoadingChecking
              }
            >
              Simpan Desa
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalDesaForm;
