import { errHandler } from "utils/common";
import api from "utils/api";

export default class EndemisCadrePerformance {
  getAll = (opt = { searchBy: "", searchValue: "", page: 1, limit: 10 }) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/cadre_performances/id`, { params: opt })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  getCalculate = (opt = { year: "", month: "", cadre_id: "" }) => {
    return new Promise((resolve, reject) => {
      api
        .get("/api/cadre_performance/calculate", { params: opt })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  create = (payload) => {
    return new Promise((resolve, reject) => {
      api
        .post("/api/cadre_performance", payload, {
          params: { device: "Online" },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  update = (payload, id) => {
    return new Promise((resolve, reject) => {
      api
        .patch(`/api/cadre_performance/id/${id}`, payload, {
          params: { device: "Online" },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  remove = (id) => {
    return new Promise((resolve, reject) => {
      api
        .delete(`/api/cadre_performance/${id}`, {
          params: { device: "Online" },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };
}
