import {
  ADD_POPULATION,
  EDIT_POPULATION,
  DELETE_POPULATION,
  ITEM_POPULATION_TO_EDIT,
  CLEAR_POPULATION,
  SET_MODAL_ADD_MODE,
  SET_MODAL_EDIT_MODE,
} from "./actionType";

export const addPopulation = (payload) => ({ type: ADD_POPULATION, payload });

export const editPopulation = (payload) => ({ type: EDIT_POPULATION, payload });

export const deletePopulation = (id) => ({
  type: DELETE_POPULATION,
  payload: id,
});

export const itemPopulationToEdit = (payload) => ({
  type: ITEM_POPULATION_TO_EDIT,
  payload,
});

export const clearPopulation = () => ({
  type: CLEAR_POPULATION,
});

export const setModalAddMode = () => ({ type: SET_MODAL_ADD_MODE });
export const setModalEditMode = () => ({ type: SET_MODAL_EDIT_MODE });
