import React, { Component, Fragment } from "react";
import { PageMode } from "utils/constant";
import FormComponent from "./components/Form";
import TableComponent from "./components/Table";
import MosquitoIdentification from "services/MoquitoIdentification";
import { connect } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";

const MosquitoIdentificationService = new MosquitoIdentification();

class MosquitoIdentificationData extends Component {
  _isMounted = true;

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      limit: 10,
      data: [],
      mode: PageMode.view,
      loading: true,
      search: "",
      selectedItem: null,
      currentPage: 1,
    };
  }

  componentDidMount() {
    document.title = "SISMAL | Data Identifikasi Nyamuk";

    this._isMounted = true;
    this.getAllMosquiotIdentifications();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  changeMode = (mode) => {
    this.setState({ mode }, () => {
      if (mode === PageMode.view) this.getAllMosquiotIdentifications();
    });
  };

  onPageChange = (page) => {
    this.setState({ page }, () => {
      this.getAllMosquiotIdentifications();
    });
  };

  getAllMosquiotIdentifications = () => {
    this.setState(
      {
        loading: true,
        selectedItem: null,
      },
      async () => {
        const { page, limit, search } = this.state;
        const { user } = this.props;
        const searchBy = `fasyankes_id${search !== "" ? `,sub_village` : ""}`;
        const searchValue = `${user.fasyankes_id}${
          search !== "" ? `,${search}` : ""
        }`;

        await MosquitoIdentificationService.getAll({
          page: page,
          limit: limit,
          searchBy,
          searchValue,
        })
          .then((data) => {
            if (this._isMounted) {
              this.setState({ loading: false, data: data });
            }
          })
          .catch((err) => {
            console.log(err);
            this.setState({ loading: false });
          });
      }
    );
  };

  onEdit = (item) => {
    this.setState(
      {
        selectedItem: item,
      },
      () => {
        this.changeMode(PageMode.edit);
      }
    );
  };

  onRemove = async (id) => {
    confirmAlert({
      title: "Anda yakin ingin Menghapus data?",
      message: "Data akan dihapus permanen",
      buttons: [
        {
          label: "Ya",
          onClick: () => {
            MosquitoIdentificationService.remove(id)
              .then((res) => {
                this.getAllMosquiotIdentifications();
                toast.success("Data Berhasil Dihapus!", {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              })
              .catch((err) => {
                console.log(err);
              });
          },
        },
        {
          label: "Tidak",
        },
      ],
    });
  };

  onSearch = (e) => {
    const search = e.target.value;
    this.setState({ search, page: 1 }, () => {
      this.getAllMosquiotIdentifications();
    });
  };

  render() {
    const { data, mode, loading, page, selectedItem } = this.state;

    return (
      <Fragment>
        {mode === PageMode.view && (
          <TableComponent
            data={data}
            loading={loading}
            onEdit={this.onEdit}
            onRemove={this.onRemove}
            onSearch={this.onSearch}
            changeMode={this.changeMode}
            currenPage={page}
            onPageChange={this.onPageChange}
          />
        )}
        {(mode === PageMode.add || mode === PageMode.edit) && (
          <FormComponent
            mode={mode}
            changeMode={this.changeMode}
            data={selectedItem}
            currentUser={this.props.user}
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authReducer.currentUser,
  };
};

export default connect(mapStateToProps, null)(MosquitoIdentificationData);
