import api from "utils/api";
import { errHandler } from "utils/common";

export default class KasusImpor {
  getAll = (
    opt = {
      page: 1,
      limit: 10,
      searchBy: "",
      searchValue: "",
      district_id: "",
    }
  ) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/pe_cases/import-case`, { params: opt })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };
}
