import { BreadcrumbNav } from "components/molecules";
import React from "react";
import Rujukan from "services/Rujukan";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";

const RujukanService = new Rujukan();

const DetailRujukanPage = () => {
  const [dataRujukan, setDataRujukan] = useState(null);
  const { id } = useParams();

  const history = useHistory();

  const fetchDetailRujukan = async () => {
    try {
      const response = await RujukanService.getOne(id);
      setDataRujukan(response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchDetailRujukan();
  }, []);

  return (
    <div className="dashboard-wrapper">
      <BreadcrumbNav
        items={[
          {
            label: "Regmal 1 - Rujukan",
            onClick: () => history.push("/regmal-rujukan"),
          },
          { label: "Detail Data Rujukan", link: "#" },
        ]}
      />
      <h1 className="page-title">Detail Data Rujukan</h1>
      <div className="row">
        <div className="col-12">
          <div className="table-responsive">
            <table className="table table-striped">
              <tbody>
                <tr>
                  <td width={"300px"}>Pasien Warga Negara Asing</td>
                  <td>:</td>
                  <td>{dataRujukan?.is_foreign_nationality}</td>
                </tr>
                <tr>
                  <td>Pasien Tidak Memiliki KTP</td>
                  <td>:</td>
                  <td>{dataRujukan?.not_have_ktp}</td>
                </tr>
                <tr>
                  <td>NIK</td>
                  <td>:</td>
                  <td>{dataRujukan?.ktp}</td>
                </tr>
                <tr>
                  <td>Nama</td>
                  <td>:</td>
                  <td>{dataRujukan?.name}</td>
                </tr>
                <tr>
                  <td>Jenis Kelamin</td>
                  <td>:</td>
                  <td>{dataRujukan?.gender}</td>
                </tr>
                <tr>
                  <td>Usia</td>
                  <td>:</td>
                  <td>
                    {dataRujukan?.age} {dataRujukan?.age_type}
                  </td>
                </tr>
                <tr>
                  <td>Faskes Pencatat</td>
                  <td>:</td>
                  <td>{dataRujukan?.source_fasyankes_id?.split("|")[1]}</td>
                </tr>
                <tr>
                  <td>Tanggal Rujukan</td>
                  <td>:</td>
                  <td>{dataRujukan?.ref_date}</td>
                </tr>
                <tr>
                  <td>Jenis Parasit</td>
                  <td>:</td>
                  <td>
                    {dataRujukan?.parasyte && dataRujukan.parasyte.join(", ")}
                  </td>
                </tr>
                <tr>
                  <td>Derajat Malaria</td>
                  <td>:</td>
                  <td>{dataRujukan?.disease_degree}</td>
                </tr>
                <tr>
                  <td>Suhu Tubuh</td>
                  <td>:</td>
                  <td>{dataRujukan?.body_temperature}</td>
                </tr>
                <tr>
                  <td>Gejala</td>
                  <td>:</td>
                  <td>{dataRujukan?.sympthomatic}</td>
                </tr>
                <tr>
                  <td>Tanggal Gejala</td>
                  <td>:</td>
                  <td>{dataRujukan?.sympthomatic_date}</td>
                </tr>
                <tr>
                  <td>Gametosit</td>
                  <td>:</td>
                  <td>{dataRujukan?.gametosit}</td>
                </tr>
                <tr>
                  <td>Kepadatan Parasit</td>
                  <td>:</td>
                  <td>{dataRujukan?.parasyte_density}</td>
                </tr>
                <tr>
                  <td>Berat Badan</td>
                  <td>:</td>
                  <td>{dataRujukan?.weight} Kg</td>
                </tr>
                <tr>
                  <td>Tanggal Pengobatan</td>
                  <td>:</td>
                  <td>{dataRujukan?.medication_date}</td>
                </tr>
                <tr>
                  <td>Pengobatan</td>
                  <td>:</td>
                  <td>
                    <ul>
                      <li>
                        DHP Tablet :{" "}
                        <b>
                          (
                          {(dataRujukan?.medication &&
                            dataRujukan?.medication.dhp_tablet) ||
                            0}
                          )
                        </b>
                      </li>
                      <li>
                        Primaquin Tablet :{" "}
                        <b>
                          (
                          {(dataRujukan?.medication &&
                            dataRujukan?.medication.primaquin_tablet) ||
                            0}
                          )
                        </b>
                      </li>
                      <li>
                        Artesunat Injeksi :{" "}
                        <b>
                          (
                          {(dataRujukan?.medication &&
                            dataRujukan?.medication.artesunat_injeksi) ||
                            0}
                          )
                        </b>
                      </li>
                      <li>
                        Kina Tablet :{" "}
                        <b>
                          (
                          {(dataRujukan?.medication &&
                            dataRujukan?.medication.kina_tablet) ||
                            0}
                          )
                        </b>
                      </li>
                      <li>
                        Kina Injeksi :{" "}
                        <b>
                          (
                          {(dataRujukan?.medication &&
                            dataRujukan?.medication.kina_injeksi) ||
                            0}
                          )
                        </b>
                      </li>
                      <li>
                        Doksisiklin :{" "}
                        <b>
                          (
                          {(dataRujukan?.medication &&
                            dataRujukan?.medication.doksisiklin) ||
                            0}
                          )
                        </b>
                      </li>
                      <li>
                        Tetrasiklin :{" "}
                        <b>
                          (
                          {(dataRujukan?.medication &&
                            dataRujukan?.medication.tetrasiklin) ||
                            0}
                          )
                        </b>
                      </li>
                      <li>
                        Klindamisin:{" "}
                        <b>
                          (
                          {(dataRujukan?.medication &&
                            dataRujukan?.medication.klindamisin) ||
                            0}
                          )
                        </b>
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>Standar Pengobatan</td>
                  <td>:</td>
                  <td>{dataRujukan?.standard_medication_status}</td>
                </tr>
                <tr>
                  <td>Perawatan</td>
                  <td>:</td>
                  <td>{dataRujukan?.treatment}</td>
                </tr>
                <tr>
                  <td>Kematian Oleh Malaria</td>
                  <td>:</td>
                  <td>{dataRujukan?.death_with_malaria}</td>
                </tr>
                <tr>
                  <td>Telah Sampai Di Faskes Rujukan</td>
                  <td>:</td>
                  <td>
                    {dataRujukan?.status === "Belum" && (
                      <span className="badge badge-warning">Belum</span>
                    )}
                    {dataRujukan?.status === "Sudah" && (
                      <span className="badge badge-success">Sudah</span>
                    )}
                    {dataRujukan?.status === "Pasien Tidak Datang" && (
                      <span className="badge badge-danger">
                        Pasien Tidak Datang
                      </span>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailRujukanPage;
