import React, { Component, Fragment } from "react";
import { PageMode } from "../../../utils/constant";
import Table from "./components/Table";
import Form from "./components/Form";
import Fup from "../../../services/FUP";

const FupService = new Fup();

export default class FUP extends Component {
  _isMounted = true;

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      limit: 10,
      data: [],
      mode: PageMode.view,
      loading: true,
      search: "",
      total: "",
      selectedItem: null,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.getAllFUP();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  changeMode = (mode) => {
    this.setState({ mode }, () => {
      if (mode === PageMode.view) this.getAllFUP();
    });
  };

  getAllFUP = () => {
    this.setState({ loading: true, selectedItem: null }, async () => {
      await FupService.getAll({
        page: this.state.page,
        limit: this.state.limit,
        search: this.state.search,
      })
        .then((data) => {
          if (this._isMounted) {
            this.setState({ loading: false, data });
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({ loading: false });
        });
    });
  };

  onEdit = (fup) => {
    this.setState({ selectedItem: fup }, () => {
      this.changeMode(PageMode.edit);
    });
  };

  onRemove = async (fupId) => {
    if (window.confirm("Apakah anda yakin ingin menghapus data ini?")) {
      await FupService.remove(fupId)
        .then((data) => {
          if (this._isMounted) {
            this.getAllFUP();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  onPageChange = (page, limit) => {
    this.setState({ page }, () => {
      this.getAllFUP();
    });
  };

  onSearch = (e) => {
    const search = e.target.value;
    this.setState({ search }, () => {
      this.getAllFUP();
    });
  };

  render() {
    const { data, mode, loading, page, selectedItem } = this.state;

    return (
      <Fragment>
        {mode === PageMode.view && (
          <Table
            data={data}
            loading={loading}
            onEdit={this.onEdit}
            onRemove={this.onRemove}
            onSearch={this.onSearch}
            changeMode={this.changeMode}
            currentPage={page}
            onPageChange={this.onPageChange}
          />
        )}
        {(mode === PageMode.add || mode === PageMode.edit) && (
          <Form mode={mode} changeMode={this.changeMode} data={selectedItem} />
        )}
      </Fragment>
    );
  }
}
