import { errHandler } from "utils/common";
import { loggedOut } from "../redux/actions/authAction";
import api from "../utils/api";

export default class Auth {
  loggedIn = (payload) => {
    return new Promise((resolve, reject) => {
      api
        .post("/api/login", payload)
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          // if (err.response && err.response.status === 401) {
          //   reject("unauthorized");
          // }
          reject(err);
        });
    });
  };

  loggedOut = () => {
    window.location = "/landing";
    window.store.dispatch(loggedOut());
  };
}
