import { TableComponent } from "components";
import React, { Component } from "react";
import { ArrowLeft, PlusCircle } from "react-feather";
import { Link } from "react-router-dom";

export default class Table extends Component {
  render() {
    const {
      targetData,
      data,
      onEdit,
      onRemove,
      changeMode,
      loading,
      onPageChange,
    } = this.props;

    return (
      <div>
        <div className="dashboard-wrapper">
          <div className="navigation mb-3">
            <Link
              to="/skrining-malaria"
              style={{ color: "black", textDecoration: "none" }}
            >
              <ArrowLeft />
              <span>Tabel Sasaran</span>
            </Link>
          </div>

          <h1 className="title font-weight-bold">Data Capaian</h1>
          <div className="content-wrapper mt-4">
            <div className="row">
              <div className="col-sm-6">
                <table className="table">
                  <tr>
                    <td>FASYANKES</td>
                    <th>:</th>
                    <th>
                      {targetData.fasyankes_id &&
                        targetData.fasyankes_id?.split("|")[1]}
                    </th>
                  </tr>
                  <tr>
                    <td>TAHUN</td>
                    <th>:</th>
                    <th>{targetData.year}</th>
                  </tr>
                </table>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-8">
                <button
                  className="btn btn-teal font-weight-bold"
                  onClick={() => changeMode("add")}
                >
                  <PlusCircle className="mr-2" />
                  Tambah Data Capaian
                </button>
              </div>
            </div>
            <hr />

            <TableComponent
              loading={loading}
              data={data?.kia_screening_achieveds}
              headers={[
                "Bulan",
                "Jumlah Capaian Ibu Hamil Yang Melakukan K1",
                "Jumlah Bumil Diskrining Malaria",
                "Jumlah Ibu Hamil Yang Diberikan Kelambu Pada Saat K1",
                "Jumlah Kunjungan Balita Sakit",
                "Jumlah Balita Sakit Saat Diperiksa Malaria",
              ]}
              cols={[
                "month",
                "total_pregnant_achieved_k1",
                "total_pregnant_screening_malaria",
                "total_pregnant_screening_net_k1",
                "total_visit_toddler",
                "total_toddler_sick",
              ]}
              btnEdit={{ onClick: onEdit }}
              btnRemove={{ onClick: onRemove }}
              currentPage={data?.currentPage}
              total={data?.total}
              limit={data?.limit}
              nextPage={data?.nextPage}
              previousPage={data?.previousPag}
              onPageChange={onPageChange}
            />
          </div>
        </div>
      </div>
    );
  }
}
