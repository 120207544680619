import { BreadcrumbNav } from "components/molecules";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  CadreTupoksiOptions,
  EducationOptions,
  FundSourceCadreOptions,
  GenderOptions,
  PageMode,
  Source,
  YesAndNoOptions,
} from "utils/constant";
import MMPCadre from "services/MMPCadre";
import { toast } from "react-toastify";
import { errorToastNotif, nullConverter } from "utils/common";
import InputCheckbox from "components/atoms/InputCheckbox";
import InputField from "components/atoms/InputField";
import InputSelect from "components/atoms/InputSelect";
import InputRadio from "components/atoms/InputRadio";
import InputDate from "components/atoms/InputDate";
import Wilayah from "services/Wilayah";
import Button from "components/atoms/Button";
import moment from "moment";

const KaderMMPService = new MMPCadre();
const WilayahService = new Wilayah();

const FormComponent = ({ selectedItem, pageMode, handlePageModeChange }) => {
  const [urbanvillages, setUrbanvillages] = useState([]);

  const { currentUser } = useSelector((state) => state.authReducer);

  const formik = useFormik({
    initialValues: {
      is_foreign_nationality: "Tidak",
      not_have_ktp: "Tidak",
      ktp: "",
      name: "",
      age: "",
      gender: "",
      last_education: "",
      is_sk_cadre: "",
      sk_date: "",
      last_training_date: "",
      village_assignments: [],
      fund_source_cadre: "",
      other_fund_source_cadre: "",
      cadre_tupoksi: [],
      other_cadre_tupoksi: "",
    },
    validate: (values) => {
      const errors = {};

      if(values.ktp){
        if (values.ktp.length < 16) {
          errors.ktp = "Nomor KTP 16 digit";
        }
      }

      if (!values.name) {
        errors.name = "Nama kader wajib diisi";
      }

      if (!values.age) {
        errors.age = "Usia kader wajib diisi";
      } else if (!/^[1-9][0-9]*$/.test(values.age)) {
        errors.age = "Usia tidak boleh di awali dengan 0";
      } else if (values.age.length > 2) {
        errors.age = "Usia maksimal 2 digit angka";
      }

      if (!values.gender) {
        errors.gender = "Jenis kelamin wajib diisi";
      }

      if (!values.last_education) {
        errors.last_education = "Pendidikan Terakhir wajib diisi";
      }

      if (!values.is_sk_cadre) {
        errors.is_sk_cadre = "Apakah mempunyai SK kader wajib dipilih";
      }

      if (
        !values.village_assignments ||
        values.village_assignments.length === 0
      ) {
        errors.village_assignments = "Desa tempat bertugas wajib diisi";
      }

      if (!values.fund_source_cadre) {
        errors.fund_source_cadre = "Sumber dana kader wajib diisi";
      }

      return errors;
    },
    onSubmit: async (values) => {
      const payload = {
        fasyankes_id: currentUser?.fasyankes_id,
        is_foreign_nationality: values.is_foreign_nationality,
        not_have_ktp: values.not_have_ktp,
        ktp: nullConverter(values.ktp),
        name: values.name,
        age: values.age,
        gender: values.gender,
        last_education: values.last_education,
        is_sk_cadre: values.is_sk_cadre,
        sk_date: nullConverter(values.sk_date),
        last_training_date: nullConverter(values.last_training_date),
        village_assignments: values.village_assignments?.map(
          (item) => item.value
        ),
        fund_source_cadre:
          values.fund_source_cadre === "Lainnya"
            ? `Lainnya_${values.other_fund_source_cadre}`
            : values.fund_source_cadre,
        cadre_tupoksi: values.cadre_tupoksi.includes("Lainnya")
          ? [...values.cadre_tupoksi, values.other_cadre_tupoksi]
          : values.cadre_tupoksi.length === 0
            ? nullConverter(values.cadre_tupoksi)
            : values.cadre_tupoksi,
      };

      switch (pageMode) {
        case PageMode.add:
          await KaderMMPService.add(payload)
            .then((res) => {
              handlePageModeChange(PageMode.view);
              toast.success("Data kader MMP berhasil ditambahkan", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                theme: "colored",
                draggable: true,
              });
            })
            .catch((err) => {
              console.log(err);
              errorToastNotif(err);
            });
          break;
        case PageMode.edit:
          await KaderMMPService.update(payload, selectedItem?.id)
            .then((res) => {
              handlePageModeChange(PageMode.view);
              toast.success("Data kader MMP berhasil diupdate", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                theme: "colored",
                draggable: true,
              });
            })
            .catch((err) => {
              console.log(err);
              errorToastNotif(err);
            });
          break;
      }
    },
  });

  const getUrbanvillagesByCurrentUser = async () => {
    try {
      const response = await WilayahService.getAll(Source.URBANVILLAGE, {
        searchBy: "subdistrict_id",
        searchValue: currentUser?.subdistrict_id?.split("|")[0],
        page: 1,
        limit: 9999,
      });
      setUrbanvillages(response.urbanvillage);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    const value = e.target.value;
    const newValues = isChecked
      ? [...formik.values.cadre_tupoksi, value]
      : formik.values.cadre_tupoksi?.filter((item) => item !== value);
    formik.setFieldValue("cadre_tupoksi", newValues);
  };

  useEffect(() => {
    getUrbanvillagesByCurrentUser();
  }, []);

  useEffect(() => {
    if (pageMode === PageMode.edit) {
      const village_assignments = selectedItem?.village_assignments?.map(
        (item) => {
          return {
            value: item,
            label: item?.split("|")[1],
          };
        }
      );

      let fundSourceCadre = "";
      let otherFundSourceCadre = "";

      if (
        FundSourceCadreOptions?.filter(
          (e) => e.value === selectedItem.fund_source_cadre
        ).length > 0
      ) {
        fundSourceCadre = selectedItem.fund_source_cadre;
      } else {
        fundSourceCadre = "Lainnya";
        otherFundSourceCadre = selectedItem.fund_source_cadre?.split("_")[1];
      }

      formik.setValues({
        is_foreign_nationality: selectedItem.is_foreign_nationality,
        not_have_ktp: selectedItem.not_have_ktp,
        ktp: selectedItem.ktp,
        name: selectedItem.name,
        age: selectedItem.age,
        gender: selectedItem.gender,
        last_education: selectedItem.last_education,
        is_sk_cadre: selectedItem.is_sk_cadre,
        sk_date:
          selectedItem.sk_date &&
          moment(selectedItem.sk_date).format("YYYY-MM-DD"),
        last_training_date:
          selectedItem.last_training_date &&
          moment(selectedItem.last_training_date).format("YYYY-MM-DD"),
        village_assignments: village_assignments,
        fund_source_cadre: fundSourceCadre,
        other_fund_source_cadre: otherFundSourceCadre,
        cadre_tupoksi: selectedItem.cadre_tupoksi || [],
        other_cadre_tupoksi:
          selectedItem.cadre_tupoksi && selectedItem.cadre_tupoksi.length > 0
            ? selectedItem.cadre_tupoksi[selectedItem.cadre_tupoksi.length - 1]
            : "",
      });
    }
  }, [selectedItem]);

  return (
    <>
      <BreadcrumbNav
        items={[
          {
            label: "Data Kader MMP",
            onClick: () => handlePageModeChange(PageMode.view),
          },
          {
            label:
              pageMode === PageMode.add
                ? "Tambah Data Kader MMP"
                : "Edit Data Kader MMP",
          },
        ]}
      />

      <h1 className="page-title">
        {pageMode === PageMode.add
          ? "Tambah Data Kader MMP"
          : "Edit Data Kader MMP"}
      </h1>

      <div className="form-grouping">
        <p
          className="warning"
          style={{ marginTop: "24px", marginBottom: "24px" }}
        >
          NOTE<span>*(WAJIB DIISI)</span>
        </p>

        <InputField
          label={"NIK"}
          name={"ktp"}
          placeholder={"Masukkan Nomor KTP / NIK"}
          type={"number"}
          maxLengthVal={16}
          {...formik.getFieldProps("ktp")}
          error={
            formik.values.ktp !== "" && formik.errors.ktp && formik.touched.ktp
          }
          errorMessage={formik.values.ktp !== "" && formik.errors.ktp}
        />

        <div className="row">
          <div className="col-sm-6">
            <InputField
              label={"Nama"}
              name={"name"}
              placeholder={"Masukkan Nama Kader"}
              type={"text"}
              {...formik.getFieldProps("name")}
              error={formik.errors.name && formik.touched.name}
              errorMessage={formik.errors.name}
              isRequired={true}
              disabled={pageMode === PageMode.edit}
            />
          </div>
          <div className="col-sm-6">
            <InputField
              label={"Usia"}
              name={"age"}
              placeholder={"Masukkan Usia Kader"}
              type={"number"}
              maxLengthVal={2}
              {...formik.getFieldProps("age")}
              error={formik.errors.age && formik.touched.age}
              errorMessage={formik.errors.age}
              isRequired={true}
              disabled={pageMode === PageMode.edit}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6">
            <InputSelect
              label={"Jenis Kelamin"}
              name={"gender"}
              placeholder={"Pilih Jenis kelamin"}
              data={GenderOptions}
              onChange={(e) => {
                const selectedValue = e ? e.value : "";
                formik.handleChange("gender")(selectedValue);
              }}
              onBlur={formik.handleBlur}
              value={GenderOptions.find(
                (option) => option.value === formik.values.gender
              )}
              error={formik.errors.gender && formik.touched.gender}
              errorMessage={formik.errors.gender}
              isRequired={true}
              disabled={pageMode === PageMode.edit}
            />
          </div>
          <div className="col-sm-6">
            <InputSelect
              label={"Pendidikan Terakhir"}
              name={"gender"}
              placeholder={"Pilih Pendidikan Terakhir"}
              data={EducationOptions}
              onChange={(e) => {
                const selectedValue = e ? e.value : "";
                formik.handleChange("last_education")(selectedValue);
              }}
              onBlur={formik.handleBlur}
              value={EducationOptions.find(
                (option) => option.value === formik.values.last_education
              )}
              error={
                formik.errors.last_education && formik.touched.last_education
              }
              errorMessage={formik.errors.last_education}
              isRequired={true}
              disabled={pageMode === PageMode.edit}
            />
          </div>
        </div>

        <InputRadio
          label="Apakah mempunyai SK Penunjukan Kader?"
          name="is_sk_cadre"
          options={YesAndNoOptions}
          onChange={(e) => {
            const selectedValue = e.target.value;
            formik.handleChange("is_sk_cadre")(selectedValue);
            if (selectedValue !== "Ya") {
              formik.setFieldValue("sk_date", "");
            }
          }}
          onBlur={formik.handleBlur}
          selectedOption={formik.values.is_sk_cadre}
          error={formik.errors.is_sk_cadre && formik.touched.is_sk_cadre}
          errorMessage={formik.errors.is_sk_cadre}
          isRequired={true}
        />

        {formik.values.is_sk_cadre === "Ya" && (
          <InputDate
            dateFormat={"YYYY-MM-DD"}
            label={"Tanggal Penetapan SK"}
            name={"sk_date"}
            placeholder={"Masukkan tanggal penetapan SK"}
            onChange={(e) => {
              const selectedDate = moment(e).format("YYYY-MM-DD");
              formik.handleChange("sk_date")(selectedDate);
            }}
            onBlur={formik.handleBlur}
            value={formik.values.sk_date}
          />
        )}

        <InputDate
          dateFormat={"YYYY-MM-DD"}
          label={"Tanggal Pelatihan Terakhir"}
          name={"last_training_date"}
          placeholder={"Masukkan tanggal pelatihan terakhir"}
          onChange={(e) => {
            const selectedDate = moment(e).format("YYYY-MM-DD");
            formik.handleChange("last_training_date")(selectedDate);
          }}
          onBlur={formik.handleBlur}
          value={formik.values.last_training_date}
        />

        <InputSelect
          label={"Pilih desa tempat bertugas"}
          name={"village_assignments"}
          placeholder={"Pilih Desa Tempat Bertugas"}
          data={urbanvillages?.map((item) => ({
            label: item.name,
            value: `${item.id}|${item.name}`,
          }))}
          onChange={(selectedValue) => {
            formik.setFieldValue("village_assignments", selectedValue);
          }}
          onBlur={formik.handleBlur}
          value={formik.values.village_assignments}
          error={
            formik.errors.village_assignments &&
            formik.touched.village_assignments
          }
          errorMessage={formik.errors.village_assignments}
          isMultipleSelect={true}
          isRequired={true}
        />

        <InputSelect
          label={"Sumber Pendanaan Kader"}
          name={"fund_source_cadre"}
          placeholder={"Pilih sumber pendanaan kader"}
          data={FundSourceCadreOptions}
          onChange={(e) => {
            const selectedValue = e ? e.value : "";
            formik.handleChange("fund_source_cadre")(selectedValue);
          }}
          onBlur={formik.handleBlur}
          value={FundSourceCadreOptions.find(
            (option) => option.value === formik.values.fund_source_cadre
          )}
          error={
            formik.errors.fund_source_cadre && formik.touched.fund_source_cadre
          }
          errorMessage={formik.errors.fund_source_cadre}
          isRequired={true}
        />

        {formik.values.fund_source_cadre === "Lainnya" && (
          <InputField
            name={"other_fund_source_cadre"}
            placeholder={"Masukkan nama sumber pendanaan"}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.other_fund_source_cadre}
            error={
              formik.errors.other_fund_source_cadre &&
              formik.touched.other_fund_source_cadre
            }
            errorMessage={formik.errors.other_fund_source_cadre}
          />
        )}

        <InputCheckbox
          label="Tupoksi Kader"
          name="cadre_tupoksi"
          options={CadreTupoksiOptions}
          onChange={(e) => handleCheckboxChange(e)}
          onBlur={formik.handleBlur}
          selectedOption={formik.values.cadre_tupoksi}
        />

        {formik.values.cadre_tupoksi.includes("Lainnya") && (
          <InputField
            name={"other_cadre_tupoksi"}
            placeholder={"Masukkan tupoksi kader"}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.other_cadre_tupoksi}
            error={
              formik.errors.other_cadre_tupoksi &&
              formik.touched.other_cadre_tupoksi
            }
            errorMessage={formik.errors.other_cadre_tupoksi}
          />
        )}

        {/* Action button */}
        <div className="button_action">
          <Button
            variant={"tertiary"}
            size={"normal"}
            onClick={() => handlePageModeChange(PageMode.view)}
          >
            Batalkan
          </Button>
          <Button
            type={"submit"}
            variant={"primary"}
            size={"normal"}
            onClick={formik.handleSubmit}
            isDisabled={
              formik.isSubmitting ||
              !formik.values.name ||
              !formik.values.age ||
              !formik.values.gender ||
              !formik.values.last_education ||
              !formik.values.is_sk_cadre ||
              formik.values.village_assignments?.length === 0 ||
              !formik.values.fund_source_cadre ||
              (formik.values.ktp !== "" && formik.errors.ktp)
            }
          >
            Simpan Data
          </Button>
        </div>
      </div>
    </>
  );
};

export default FormComponent;
