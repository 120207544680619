import React from "react";
import { UserLevel } from "utils/constant";

const TableComponent = ({
  currentLevel,
  reportsData,
  tableRef,
  reportInfo,
}) => {
  const calculateTotal = () => {
    return reportsData.reduce(
      (total, item) => {
        total.total_data_desa += parseInt(item.total_desa ?? 0);
        total.total_respt_ya += parseInt(item.respt_ya ?? 0);
        total.total_respt_tidak += parseInt(item.respt_tidak ?? 0);
        total.total_respt_na += parseInt(item.respt_na ?? 0);
        total.total_fokus_aktif += parseInt(item.fokus_aktif ?? 0);
        total.total_fokus_non_aktif += parseInt(item.fokus_non_aktif ?? 0);
        total.total_fokus_bebas += parseInt(item.fokus_bebas ?? 0);
        total.total_non_fokus += parseInt(item.non_fokus ?? 0);

        return total;
      },
      {
        total_data_desa: 0,
        total_respt_ya: 0,
        total_respt_tidak: 0,
        total_respt_na: 0,
        total_fokus_aktif: 0,
        total_fokus_non_aktif: 0,
        total_fokus_bebas: 0,
        total_non_fokus: 0,
      }
    );
  };

  let levelName = "";
  if (currentLevel === "national") {
    levelName = "Nasional";
  } else if (currentLevel === "province") {
    levelName = "Provinsi";
  } else if (currentLevel === "district") {
    levelName = "Kabupaten/Kota";
  } else {
    levelName = "Fasyankes";
  }

  let periodeName = "";

  if (reportInfo?.statusPeriode === "yearly") {
    periodeName = "Tahunan";
  }

  let data = reportInfo?.fasyankes;
  let parts = data?.split(",");
  let faskesName = parts?.map(function (part) {
    return part?.split("|")[1];
  });
  let result = faskesName?.join(", ");

  const total = calculateTotal();

  return (
    <div
      className="table-table-responsive"
      style={{ height: "560px", overflow: "scroll" }}
      ref={tableRef}
    >
      <table>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none" }}>
            <h3>SISMAL v3, DATA RESEPTIFITAS DAN FOKUS</h3>
          </th>
        </tr>
      </table>
      <table>
        <thead>
          <tr style={{ border: "none" }}>
            <th
              width="200"
              style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}
            >
              Periode Laporan
            </th>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              : {periodeName}
            </th>
          </tr>
          <tr style={{ border: "none" }}>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              Tahun
            </th>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              : {reportInfo?.periode?.year}
            </th>
          </tr>
          <tr style={{ border: "none" }}>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              Level
            </th>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              : {levelName}
            </th>
          </tr>
          <tr style={{ border: "none" }}>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              Provinsi
            </th>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              :{" "}
              {!reportInfo?.province
                ? "Semua Provinsi"
                : reportInfo?.province?.split("|")[1]}
            </th>
          </tr>
          <tr style={{ border: "none" }}>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              Kab/Kota
            </th>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              :{" "}
              {!reportInfo?.district
                ? "Semua Kab/Kota"
                : reportInfo?.district?.split("|")[1]}
            </th>
          </tr>
          <tr style={{ border: "none" }}>
            <th style={{ border: "none", paddingTop: 0 }}>Fasyankes</th>
            <th style={{ border: "none", paddingTop: 0 }}>
              : {!reportInfo?.fasyankes ? "Semua Fasyankes" : result}
            </th>
          </tr>
        </thead>
      </table>
      <table>
        <thead>
          <tr>
            <th rowSpan={2}>No.</th>
            {currentLevel === UserLevel.National.toLowerCase() && (
              <th rowSpan={2}>Provinsi</th>
            )}
            {(currentLevel === UserLevel.Province.toLowerCase() ||
              currentLevel === UserLevel.National.toLowerCase()) && (
              <th rowSpan={2}>Kabupaten/Kota</th>
            )}
            {currentLevel === UserLevel.District.toLowerCase() && (
              <th rowSpan={2}>Fasyankes</th>
            )}
            {(currentLevel === UserLevel.Province.toLowerCase() ||
              currentLevel === UserLevel.District.toLowerCase() ||
              currentLevel === UserLevel.National.toLowerCase()) && (
              <th rowSpan={2}>Jumlah Desa</th>
            )}
            {currentLevel === UserLevel.Fasyankes.toLowerCase() && (
              <>
                <th rowSpan={2}>Nama Desa</th>
                <th rowSpan={2}>Kode Desa</th>
              </>
            )}
            <th colSpan={3}>Reseptifitas</th>
            <th colSpan={3}>Klasifikasi Desa Fokus</th>
          </tr>
          <tr>
            <th>Jumlah Desa Reseptif</th>
            <th>Jumlah Desa Tidak Reseptif</th>
            <th>Jumlah Desa N/A</th>
            <th>Fokus Aktif</th>
            <th>Fokus Non Aktif</th>
            <th>Fokus Bebas</th>
            <th>Non Fokus</th>
          </tr>
        </thead>
        <tbody>
          {reportsData?.length !== 0 ? (
            reportsData?.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                {currentLevel === UserLevel.National.toLowerCase() && (
                  <td>{item?.province}</td>
                )}
                {(currentLevel === UserLevel.Province.toLowerCase() ||
                  currentLevel === UserLevel.National.toLowerCase()) && (
                  <td>{item?.district}</td>
                )}
                {currentLevel === UserLevel.District.toLowerCase() && (
                  <td>{item?.fasyankes}</td>
                )}
                {(currentLevel === UserLevel.Province.toLowerCase() ||
                  currentLevel === UserLevel.District.toLowerCase() ||
                  currentLevel === UserLevel.National.toLowerCase()) && (
                  <td>{item?.total_desa}</td>
                )}
                {currentLevel === UserLevel.Fasyankes.toLowerCase() && (
                  <>
                    <td>{item?.nama_desa}</td>
                    <td>{item?.kode_desa}</td>
                  </>
                )}
                <td>{item?.respt_ya}</td>
                <td>{item?.respt_tidak}</td>
                <td>{item?.respt_na}</td>
                <td>{item?.fokus_aktif}</td>
                <td>{item?.fokus_non_aktif}</td>
                <td>{item?.fokus_bebas}</td>
                <td>{item?.non_fokus}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={10}>Tidak ada data</td>
            </tr>
          )}
          <tr style={{ backgroundColor: "#43766C" }}>
            <td
              style={{ color: "#FFF" }}
              colSpan={
                currentLevel === UserLevel.National.toLowerCase()
                  ? 4
                  : currentLevel === UserLevel.Fasyankes.toLowerCase()
                  ? 3
                  : 2
              }
            >
              Total
            </td>
            {(currentLevel === UserLevel.Province.toLowerCase() ||
              currentLevel === UserLevel.District.toLowerCase()) && (
              <td style={{ color: "#FFF" }}>{total.total_data_desa}</td>
            )}
            <td style={{ color: "#FFF" }}>{total.total_respt_ya}</td>
            <td style={{ color: "#FFF" }}>{total.total_respt_tidak}</td>
            <td style={{ color: "#FFF" }}>{total.total_respt_na}</td>
            <td style={{ color: "#FFF" }}>{total.total_fokus_aktif}</td>
            <td style={{ color: "#FFF" }}>{total.total_fokus_non_aktif}</td>
            <td style={{ color: "#FFF" }}>{total.total_fokus_bebas}</td>
            <td style={{ color: "#FFF" }}>{total.total_non_fokus}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default TableComponent;
