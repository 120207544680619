import React from "react";
import PropTypes from "prop-types";

const InputCheckbox = ({
  label,
  onChange,
  name,
  value,
  isChecked,
  labelStyle,
  isDisabled,
}) => {
  return (
    <div className="custom-control custom-checkbox d-block">
      <input
        type="checkbox"
        className="custom-control-input"
        id={label}
        name={name}
        value={value}
        onChange={onChange}
        checked={isChecked}
        disabled={isDisabled}
      />
      <label
        htmlFor={label}
        className="custom-control-label"
        style={labelStyle}
      >
        {label}
      </label>
    </div>
  );
};

InputCheckbox.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

InputCheckbox.defaultProps = {
  label: "",
  onChange: () => {},
  name: "",
  value: "",
};

export default InputCheckbox;
