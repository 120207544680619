import React, { Component } from "react";
import { PageMode, Source } from "utils/constant";
import Table from "./components/Table";
import Modal from "./components/Modal";
import PeKasus from "services/PeKasus";
import Fasyankes from "services/Fasyankes";
import { getCurrentUserFromLocalStorage } from "utils/common";
import Datetime from "react-datetime";
import moment from "moment";

const PeKasusService = new PeKasus();
const FasyankesService = new Fasyankes();

const monthOpt = [
  { value: "01", label: "Januari" },
  { value: "02", label: "Februari" },
  { value: "03", label: "Maret" },
  { value: "04", label: "April" },
  { value: "05", label: "Mei" },
  { value: "06", label: "Juni" },
  { value: "07", label: "Juli" },
  { value: "08", label: "Agustus" },
  { value: "09", label: "September" },
  { value: "10", label: "Oktober" },
  { value: "11", label: "November" },
  { value: "12", label: "Desember" },
];

export default class IndigenousValidation extends Component {
  _isMounted = true;

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      limit: 10,
      data: [],
      mode: PageMode.view,
      loading: true,
      selecetdItem: null,
      currentPage: 1,
      search: "",
      fasyankes: [],

      selected_year: "",
      selected_month: "",

      selected_fasyankes: "",
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.getAllIndigenous();
    this.getFasyankesByDistrict();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  changeMode = (mode) => {
    this.setState({ mode }, () => {
      if (mode === PageMode.view) this.getAllIndigenous();
    });
  };

  getAllIndigenous = () => {
    const user = getCurrentUserFromLocalStorage();

    const searchByCondition = this.state.selected_fasyankes
      ? "case_classification,district_id,fasyankes_id"
      : "case_classification,district_id";
    const searchValueCondition = this.state.selected_fasyankes
      ? `Indigenous,${user.district_id},${this.state.selected_fasyankes}`
      : `Indigenous,${user.district_id}`;

    let state = { loading: true, selectedItem: null };
    this.setState(state, async () => {
      await PeKasusService.getAll({
        page: this.state.page,
        limit: this.state.limit,
        searchBy: searchByCondition,
        searchValue: searchValueCondition,
        includeRegmal: true,
      })
        .then((data) => {
          if (this._isMounted) {
            this.setState({ loading: false, data: data });
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({ loading: false });
        });
    });
  };

  onFilterData = () => {
    const user = getCurrentUserFromLocalStorage();
    const searchByCondition = this.state.selected_fasyankes
      ? "case_classification,district_id,fasyankes_id,case_interview_date|YYYY-MM"
      : "case_classification,district_id,case_interview_date|YYYY-MM";
    const searchValueCondition = this.state.selected_fasyankes
      ? `Indigenous,${user.district_id},${this.state.selected_fasyankes},${moment(
        this.state.selected_year
      ).year()}-${this.state.selected_month}`
      : `Indigenous,${user.district_id},${moment(this.state.selected_year).year()}-${
        this.state.selected_month
      }`;

    this.setState({ loading: true, selectedItem: null }, async () => {
      await PeKasusService.getAll({
        page: this.state.page,
        limit: this.state.limit,
        searchBy: searchByCondition,
        searchValue: searchValueCondition,
        includeRegmal: true,
      })
        .then((data) => {
          if (this._isMounted) {
            this.setState({ loading: false, data: data });
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({ loading: false });
        });
    });
  };

  getFasyankesByDistrict = async () => {
    const user = getCurrentUserFromLocalStorage();

    await FasyankesService.getAll(Source.FASYANKES, {
      searchBy: "district_id",
      searchValue: user.district_id?.split("|")[0],
      page: 1,
      limit: 999,
      excludePkm: false,
    })
      .then((res) => {
        this.setState({
          fasyankes: res.fasyankes,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onValidate = (item) => {
    this.setState({ selecetdItem: item }, () => {
      this.changeMode(PageMode.edit);
    });
  };

  onPageChange = (page) => {
    this.setState({ page }, () => {
      this.getAllIndigenous();
    });
  };

  onSearch = (e) => {
    const search = e.target.value;
    this.setState({ search }, () => {
      this.getAllIndigenous();
    });
  };

  onSelectedItem = (e) => {
    this.setState({ selectedItem: e });
  };

  render() {
    const { data, mode, loading, page, selectedItem } = this.state;
    return (
      <div className="dashboard-wrapper">
        <h1 className="title font-weight-bold">
          Validasi Kab/Kota (Data Indigenous)
        </h1>
        <div className="content-wrapper mt-4">
          {mode === PageMode.view && (
            <>
              <div className="filter-data">
                <div className="row justify-content-start py-3">
                  <div className="col-sm-6">
                    <form className="form-horizontal">
                      <div className="form-group row justify-content-start">
                        <label className="col-sm-3 col-form-label align-self-center">
                          Tahun
                        </label>
                        <div className="col-sm-4 align-self-center">
                          <Datetime
                            dateFormat="YYYY"
                            timeFormat={false}
                            closeOnClickOutside={true}
                            closeOnSelect={true}
                            onChange={(e) =>
                              this.setState({
                                selected_year: moment(e).format("YYYY"),
                              })
                            }
                            value={this.state.selected_year}
                            inputProps={{
                              name: "filter_year",
                              placeholder: "Pilih Tahun",
                              autoComplete: "off",
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group row justify-content-start">
                        <label className="col-sm-3 col-form-label align-self-center">
                          Bulan
                        </label>
                        <div className="col-sm-4 align-self-center">
                          <select
                            name="filter_month"
                            id="filter_month"
                            className="form-control"
                            onChange={(e) =>
                              this.setState({ selected_month: e.target.value })
                            }
                            value={this.state.selected_month}
                          >
                            <option value="" disabled selected>
                              Pilih Bulan
                            </option>
                            {monthOpt.map((item) => (
                              <option key={item.label} value={item.value}>
                                {item.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="form-group row justify-content-start">
                        <label className="col-sm-3 col-form-label align-self-center">
                          Fasyankes
                        </label>
                        <div className="col-sm-4 align-self-center">
                          <select
                            name="filter_faskes"
                            id="filter_faskes"
                            className="form-control"
                            onChange={(e) =>
                              this.setState({
                                selected_fasyankes: e.target.value,
                              })
                            }
                            value={this.state.selected_fasyankes}
                          >
                            <option value="" disabled selected>
                              Pilih Fasyankes
                            </option>
                            {/* mapping state fasyankes */}
                            {this.state.fasyankes.map((item) => (
                              <option
                                key={item.id}
                                value={`${item.id}|${item.name}`}
                              >
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="form-group row justify-content-start">
                        <div className="col-sm-7">
                          <button
                            className="btn btn-teal btn-block"
                            disabled={
                              !this.state.selected_month ||
                              !this.state.selected_year ||
                              !this.state.selected_fasyankes
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              this.onFilterData();
                            }}
                          >
                            Terapkan
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <Table
                data={data}
                loading={loading}
                currentPage={page}
                changeMode={this.changeMode}
                onValidate={this.onValidate}
                onRemove={this.onRemove}
                onSearch={this.onSearch}
                onPageChange={this.onPageChange}
                onSelectedItem={this.onSelectedItem}
              />
            </>
          )}
          {(mode === PageMode.add || mode === PageMode.edit) && (
            <>
              <Table
                data={data}
                loading={loading}
                currentPage={page}
                changeMode={this.changeMode}
                onValidate={this.onValidate}
                onRemove={this.onRemove}
                onSearch={this.onSearch}
                onPageChange={this.onPageChange}
                onSelectedItem={this.onSelectedItem}
              />
              <Modal
                mode={mode}
                changeMode={this.changeMode}
                data={selectedItem}
              />
            </>
          )}
        </div>
      </div>
    );
  }
}
