import complicationRule1 from "./complicationRule1";
import complicationRule10 from "./complicationRule10";
import complicationRule11 from "./complicationRule11";
import complicationRule12 from "./complicationRule12";
import complicationRule2 from "./complicationRule2";
import complicationRule3 from "./complicationRule3";
import complicationRule4 from "./complicationRule4";
import complicationRule5 from "./complicationRule5";
import complicationRule6 from "./complicationRule6";
import complicationRule7 from "./complicationRule7";
import complicationRule8 from "./complicationRule8";
import complicationRule9 from "./complicationRule9";
import nonComplicationRule1 from "./nonComplicationRule1";
import nonComplicationRule2 from "./nonComplicationRule2";
import nonComplicationRule3 from "./nonComplicationRule3";
import nonComplicationRule4 from "./nonComplicationRule4";
import nonComplicationRule5 from "./nonComplicationRule5";
import nonComplicationRule6 from "./nonComplicationRule6";
import nonComplicationRule7 from "./nonComplicationRule7";
import nonComplicationRuleOld1 from "./nonComplicationRuleOld1";
import nonComplicationRuleOld2 from "./nonComplicationRuleOld2";
import nonComplicationRuleOld3 from "./nonComplicationRuleOld3";
import nonComplicationRuleOld4 from "./nonComplicationRuleOld4";
import nonComplicationRuleOld5 from "./nonComplicationRuleOld5";
import nonComplicationRuleOld6 from "./nonComplicationRuleOld6";
import nonComplicationRuleOld7 from "./nonComplicationRuleOld7";
import nonComplicationRuleOld8 from "./nonComplicationRuleOld8";

function medicationStatus(value) {
  let statusMedication = "Tidak Standard";
  const disease_degree = value.disease_degree;

  if (value.lab_checking_type !== "Tanpa Pemeriksaan") {
    if (disease_degree == "Tanpa Komplikasi") {
      if (value.recurrence == "Baru") {
        if (
          nonComplicationRule1(value) ||
          nonComplicationRule2(value) ||
          nonComplicationRule3(value) ||
          nonComplicationRule4(value) ||
          nonComplicationRule5(value) ||
          nonComplicationRuleOld1(value) ||
          nonComplicationRuleOld2(value) ||
          nonComplicationRuleOld3(value) ||
          nonComplicationRuleOld4(value) ||
          nonComplicationRuleOld5(value) ||
          nonComplicationRuleOld6(value) ||
          nonComplicationRuleOld7(value) ||
          nonComplicationRuleOld8(value)
        ) {
          statusMedication = "Standard";
        }
      }
      if (value.recurrence == "Relaps") {
        if (
          nonComplicationRule1(value) ||
          nonComplicationRule2(value) ||
          nonComplicationRule3(value) ||
          nonComplicationRule4(value) ||
          nonComplicationRule5(value) ||
          nonComplicationRule6(value) ||
          nonComplicationRule7(value)
        ) {
          statusMedication = "Standard";
        }
      }
    } else if (disease_degree == "Komplikasi (Malaria Berat)") {
      if (
        complicationRule1(value) ||
        complicationRule2(value) ||
        complicationRule3(value) ||
        complicationRule4(value) ||
        complicationRule5(value) ||
        complicationRule6(value) ||
        complicationRule7(value) ||
        complicationRule8(value) ||
        complicationRule9(value) ||
        complicationRule10(value) ||
        complicationRule11(value) ||
        complicationRule12(value)
      ) {
        statusMedication = "Standard";
      }
    }
  }

  return statusMedication;
}

export default medicationStatus;
