import Button from "components/atoms/Button";
import InputField from "components/atoms/InputField";
import React from "react";
import { PageMode } from "utils/constant";
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import ActionButton from "components/atoms/ActionButton";
import TableDataComponent from "components/organisms/TableData";
import { useHistory } from "react-router-dom";

const Table = ({
  data,
  changeMode,
  loading,
  onSearch,
  onPageChange,
  onEdit,
  onRemove,
  currentUser,
}) => {
  const { cadres, currentPage, limit, nextPage, previousPage, total } = data;
  const history = useHistory();

  const columns = [
    {
      header: "Aksi",
      accessorKey: "action",
      cell: ({ row }) => (
        <div className="button-actions">
          <ActionButton onClick={() => onEdit(row.original)} btnType={"edit"} />
          <ActionButton
            onClick={() => onRemove(row.original.id)}
            btnType={"delete"}
          />
          <ActionButton
            onClick={() => history.push(`/kinerja-kader/${row.original.id}`)}
            btnType={"kinerja"}
          />
        </div>
      ),
    },
    {
      header: "Asal Kader",
      accessorKey: "cadre_origin",
    },
    {
      header: "Nama Kader",
      accessorKey: "name",
    },
    {
      header: "Usia (Tahun)",
      accessorKey: "age",
    },
    {
      header: "Jenis Kelamin",
      accessorKey: "gender",
    },
    {
      header: "Pendidikan Terakhir",
      accessorKey: "last_education",
    },
    {
      header: "Tanggal Penetapan SK",
      accessorKey: "sk_date",
    },
    {
      header: "Tanggal Pelatihan Terakhir",
      accessorKey: "last_training_date",
    },
    {
      header: "Sumber Pendanaan Kader",
      accessorKey: "fund_source_cadre",
    },
  ];

  const tableInstance = useReactTable({
    columns,
    data: cadres,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <>
      {/* action button */}
      <div className="action-wrapper">
        <div className="action-button">
          {currentUser && currentUser.mode === "Online" && (
            <Button
              variant={"primary"}
              size={"normal"}
              onClick={() => changeMode(PageMode.add)}
            >
              Tambah Data Kader
            </Button>
          )}
        </div>
        <div className="action-search">
          <InputField
            placeholder={"Cari berdasarkan nama kader"}
            onChange={onSearch}
          />
        </div>
      </div>
      {/* table data */}
      <TableDataComponent
        columnLength={columns?.length}
        tableInstance={tableInstance}
        onPageChange={onPageChange}
        loading={loading}
        currentPage={currentPage}
        limit={limit}
        nextPage={nextPage}
        previousPage={previousPage}
        total={total}
      />
    </>
  );
};

export default Table;
