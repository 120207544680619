import React, { Component, Fragment } from "react";
import { PageMode, UserLevel, Source, ErrorMessage } from "utils/constant";
import Table from "./components/Table";
import Form from "./components/Form";
import Populasi from "services/Populasi";
import TableApproval from "./components/TableApproval";
import { connect } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import Wilayah from "services/Wilayah";
import { BreadcrumbNav } from "components/molecules";

const PopulasiService = new Populasi();
const WilayahService = new Wilayah();

class DataDasarPenduduk extends Component {
  _isMounted = true;

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      limit: 10,
      data: [],
      mode: PageMode.view,
      loading: true,
      searchValue: null,
      searchBy: null,
      selectedItem: null,
      provincies: [],
      currentPage: 1,
      selectedTab: 1,
    };
  }

  componentDidMount() {
    document.title = "SISMAL | Data Dasar Penduduk";

    this._isMounted = true;
    const { user } = this.props;
    if (user.level !== UserLevel.National) {
      this.getAllData();
    }
    this.getProvincies();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  changeMode = (mode) => {
    this.setState({ mode }, () => {
      if (mode === PageMode.view) this.getAllData();
    });
  };

  getAllData = async () => {
    this.setState({ loading: true, selectedItem: null });
    const { page, limit, searchValue, searchBy } = this.state;
    const { user } = this.props;

    await PopulasiService.getAll({
      page,
      limit,
      searchBy: `fasyankes_id${searchBy ? `,${searchBy}` : ""}`,
      searchValue: `${user.fasyankes_id}${
        searchValue ? `,${searchValue}` : ""
      }`,
    })
      .then((data) => {
        if (this._isMounted) {
          this.setState({
            loading: false,
            data,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        if (this._isMounted) {
          this.setState({ loading: false });
        }
      });
  };

  onEdit = (id) => {
    this.setState({ selectedItem: id }, () => {
      this.changeMode(PageMode.edit);
    });
  };

  onRemove = async (id) => {
    confirmAlert({
      title: "Anda yakin ingin Menghapus data?",
      message: "Are you sure want to delete this data?",
      buttons: [
        {
          label: "Ya",
          onClick: () => {
            PopulasiService.remove(id)
              .then((res) => {
                this.getAllData();
                toast.success("Data Dasar Berhasil Dihapus", {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              })
              .catch((err) => {
                console.log(err);

                if (err.resonse.data.message === ErrorMessage.UserNotVerified) {
                  toast.error("Akun anda belum diverifikasi", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                }

                if (
                  err.response.data.message ===
                  ErrorMessage.UserFaskesNotHaveAccess
                ) {
                  toast.error("Akun anda tidak memiliki akses", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                }
              });
          },
        },
        {
          label: "Tidak",
        },
      ],
    });
  };

  onPageChange = (page) => {
    this.setState({ page }, () => {
      this.getAllData();
    });
  };

  onSearch = (e) => {
    const searchVal = e.target.value;
    this.setState(
      { searchBy: "urbanvillage_id", searchValue: searchVal },
      () => {
        this.getAllData();
      }
    );
  };

  onFilter = (e) => {
    this.setState({ page: 1, searchBy: "year", searchValue: e }, () => {
      this.getAllData();
    });
  };

  getProvincies = async () => {
    await WilayahService.getAll(Source.PROVINCE, { page: 1, limit: 999 })
      .then((res) => {
        this.setState({
          provincies: res.province,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const { mode, selectedItem, provincies, data, loading, page } = this.state;
    const { user } = this.props;
    return (
      <div className="dashboard-wrapper">
        {mode === PageMode.add && (
          <BreadcrumbNav
            items={[
              { label: "Data Dasar Penduduk", onClick: () => this.changeMode(PageMode.view) },
              { label: "Tambah Data Dasar Penduduk" },
            ]}
          />
        )}

        {user?.level === UserLevel.National && (
          <>
            <Fragment>
              {mode === PageMode.view && (
                <TableApproval onEdit={this.onEdit} provincies={provincies} />
              )}
              {(mode === PageMode.add || mode === PageMode.edit) && (
                <Form
                  mode={mode}
                  changeMode={this.changeMode}
                  population={selectedItem}
                />
              )}
            </Fragment>
          </>
        )}

        {user?.level !== UserLevel.National && (
          <>
            {mode === PageMode.view && (
              <Table
                data={data}
                loading={loading}
                currentPage={page}
                onEdit={this.onEdit}
                onRemove={this.onRemove}
                onSearch={this.onSearch}
                changeMode={this.changeMode}
                onPageChange={this.onPageChange}
                onFilter={this.onFilter}
                currentUser={this.props.user}
              />
            )}
            {(mode === PageMode.add || mode === PageMode.edit) && (
              <Form
                mode={mode}
                changeMode={this.changeMode}
                population={selectedItem}
              />
            )}
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authReducer.currentUser,
  };
};

export default connect(mapStateToProps)(DataDasarPenduduk);
