import { Button, InputField, InputSelect } from "components";
import InputDateTime from "components/InputDateTime";
import React, { Component } from "react";
import "react-datetime/css/react-datetime.css";
import { MinusCircle } from "react-feather";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import UserVerification from "services/UserVerification";
import Wilayah from "services/Wilayah";
import { PageMode, Source } from "utils/constant";

const UserVerService = new UserVerification();
const WilayahService = new Wilayah();

const defaultAttr = (e) => {
  return {
    value: e ?? "",
    isValid: true,
    message: "",
  };
};

class Form extends Component {
  _isMounted = true;

  constructor(props) {
    super(props);
    this.onDrop = (files) => {
      this.setState({ files });
    };
    this.defaultValue = {
      province_id: defaultAttr(""),
      district_id: defaultAttr(""),
      year: defaultAttr(""),
      provincies: [],
      districts: [],
      userVerDynamic: [],
    };
    this.state = Object.assign({}, this.defaultValue);
  }

  componentDidMount() {
    this._isMounted = true;
    let obj = Object.assign({}, this.state);
    const { data, user, mode } = this.props;

    if (user.level === "National") {
      if (mode === PageMode.edit) {
        obj.province_id = defaultAttr(data.user.province_id);
        obj.district_id = defaultAttr(data.user.district_id);
        obj.year = defaultAttr(data.year);
        obj.userVerDynamic = [
          {
            index: 0,
            user_id: defaultAttr(data.user.id),
            username: defaultAttr(data.user.username),
            first_name: defaultAttr(data.user.first_name),
            last_name: defaultAttr(data.user.last_name),
            mode: defaultAttr(data.mode),
          },
        ];
      }
    }

    if (user.level === "District") {
      obj.province_id = defaultAttr(user.province_id);
      obj.district_id = defaultAttr(user.district_id);
    }

    this.setState(obj, () => {
      this.getProvincies();
      if (mode === PageMode.edit) {
        const prov_id = data.user.province_id?.split("|")[0];
        this.getDistrictsByProvince(prov_id);
      }
      if (user.level === "District") {
        const prov_id = user.province_id?.split("|")[0];
        this.getDistrictsByProvince(prov_id);
      }
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onInputChange = (e) => {
    const { name, value } = e.target;
    let obj = Object.assign({}, defaultAttr(value));
    if (name === "district_id") {
      this.setState({
        [name]: obj,
        year: defaultAttr(null),
        userVerDynamic: [],
      });
    } else {
      this.setState({ [name]: obj });
    }
  };

  onUserVerChange = (e, item) => {
    const data = this.state.userVerDynamic;
    const { name, value } = e.target;

    data.map((x) => {
      if (x.index === item.index) {
        x[name] = defaultAttr(value);

        if (name === "urbanvillage_name") {
          const urbanvillage = this.state.users.find((x) => x.name === value);

          if (urbanvillage) {
            x.urbanvillage_id = defaultAttr(urbanvillage.id);
          }
        }

        if (name === "have_urbanvillage_id") {
          x.urbanvillage_id = defaultAttr("");
          x.urbanvillage_name = defaultAttr("");
        }
      }
    });

    this.setState({ populationDynamic: data });
  };

  onSave = async (e) => {
    e.preventDefault();
    if (this.onValidate()) {
      const { userVerDynamic } = this.state;
      let basePayload = {
        year: this.state.year.value,
      };
      switch (this.props.mode) {
        case PageMode.add:
          Promise.all(
            userVerDynamic.map(async (x) => {
              return await UserVerService.add({
                ...basePayload,
                user_id: x.user_id.value,
                mode: x.mode.value,
              }).catch((err) => {
                console.log(err);
              });
            })
          ).then((res) => {
            this.setState(this.defaultValue, () => {
              this.props.changeMode("view");
            });

            toast.success("User berhasil di verifikasi", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          });
          break;
        case PageMode.edit:
          const payload = {
            ...basePayload,
            user_id: userVerDynamic[0].user_id.value,
            mode: userVerDynamic[0].mode.value,
          };
          await UserVerService.update(payload, this.props.data.id)
            .then((res) => {
              this.setState(this.defaultValue, () => {
                this.props.changeMode("view");
              });

              toast.success("Data verifikasi user berhasil diperbarui", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            })
            .catch((err) => {
              console.log(err);
            });
          break;
      }
    }
  };

  onValidate = () => {
    const { level } = this.props;
    let isValid = true;

    if (!this.validationHandler("year", "Tahun wajib diisi")) {
      isValid = false;
    }
    if (level === "Fasyankes" || level === "District") {
      if (!this.validationHandler("province_id", "Provinsi wajib diisi")) {
        isValid = false;
      }
    }
    if (level === "Fasyankes") {
      if (!this.validationHandler("district_id", "kabupaten wajib diisi")) {
        isValid = false;
      }
    }

    const customValidate = (item, msg) => {
      const cusValid = item.value !== "";
      return {
        value: item.value,
        isValid: cusValid,
        message: cusValid ? "" : msg,
      };
    };

    const newUserVerDynamic = this.state.userVerDynamic.map((e) => {
      e.mode = customValidate(e.mode, "Mode wajib dipilih");
      if (!e.mode.isValid) isValid = false;
      return e;
    });
    this.setState({ populationDynamic: newUserVerDynamic });

    return isValid;
  };

  onYearChange = (year) => {
    this.getUserByDistrict(year);
  };

  splitVal = (val) => {
    return val?.split("|")[1];
  };

  validationHandler = (name, errorMsg) => {
    let isValid = true;
    if (
      this.state[name].value === "" ||
      this.state[name].value === null ||
      this.state[name].value === undefined
    ) {
      this.setState({
        [name]: {
          value: "",
          isValid: false,
          message: `${errorMsg}`,
        },
      });
      isValid = false;
    }
    return isValid;
  };

  getUserByDistrict = async (year) => {
    const { level } = this.props;
    const { district_id, province_id } = this.state;

    let searchBy;
    let searchValue;
    if (level === "Province") {
      searchBy = "level";
      searchValue = `Province`;
    } else if (level === "District") {
      searchBy = "level,province_id";
      searchValue = `District,${province_id.value?.split("|")[0]}`;
    } else {
      searchBy = "level,district_id";
      searchValue = `Fasyankes,${district_id.value?.split("|")[0]}`;
    }

    await UserVerService.getNotVerified({
      searchBy,
      searchValue,
      page: 1,
      limit: 999,
      year,
    })
      .then(async (res) => {
        if (res) {
          const users = res.user_verifications;
          let initUserVer = [];
          users.forEach((e, i) => {
            initUserVer.push({
              index: i,
              user_id: defaultAttr(e.id),
              username: defaultAttr(e.username),
              first_name: defaultAttr(e.first_name),
              last_name: defaultAttr(e.last_name),
              mode: defaultAttr(""),
            });
          });

          this.setState({
            userVerDynamic: initUserVer,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  mapper = (e) => {
    const options = [];
    e.map((x) => {
      options.push({ value: `${x.id}|${x.name}`, label: x.name });
    });
    return options;
  };

  getProvincies = async () => {
    await WilayahService.getAll(Source.PROVINCE, { page: 1, limit: 999 })
      .then((res) => {
        this.setState({
          provincies: res.province,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getDistrictsByProvince = async (id) => {
    await WilayahService.getAll(Source.DISTRICT, {
      searchBy: "province_id",
      searchValue: id,
      page: 1,
      limit: 999,
    })
      .then((res) => {
        this.setState({ districts: res.district });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const { userVerDynamic, province_id, provincies, district_id, districts } =
      this.state;
    const { mode, user, level } = this.props;

    return (
      <div className="form-wrapper">
        <div className="form-group">
          <p className="font-weight-bold">
            NOTE <span className="text-danger">*(WAJIB DIISI)</span>
          </p>
        </div>

        {(level === "Fasyankes" || level === "District") && (
          <InputSelect
            label="Provinsi"
            name="province_id"
            placeholder="Pilih"
            data={this.mapper(provincies)}
            onChange={(e) => {
              this.onInputChange(e);
              this.getDistrictsByProvince(e.target.value?.split("|")[0]);
            }}
            value={province_id.value}
            isRequired={true}
            hasError={!province_id.isValid}
            errorMessage={province_id.message}
            isDisabled={user?.level === "District" ? true : false}
          />
        )}
        {level === "Fasyankes" && (
          <InputSelect
            label="Kabupaten"
            name="district_id"
            placeholder="Pilih"
            data={this.mapper(districts)}
            onChange={this.onInputChange}
            value={district_id.value}
            isDisabled={
              !province_id.value || user?.level === "District" ? true : false
            }
            isRequired={true}
            hasError={!district_id.isValid}
            errorMessage={district_id.message}
          />
        )}
        <InputDateTime
          label="Tahun"
          name="year"
          dateFormat="YYYY"
          timeFormat={false}
          onChange={(e) => {
            const year = moment(e).format("YYYY");
            this.onInputChange({
              preventDefault: () => {},
              target: {
                name: "year",
                value: year,
              },
            });
            this.onYearChange(year);
          }}
          placeholder="Pilih Tahun"
          closeOnClickOutside={true}
          value={this.state.year.value}
          isRequired={true}
          hasError={!this.state.year.isValid}
          errorMessage={this.state.year.message}
        />

        <>
          {userVerDynamic.map((item, idx) => {
            return (
              <div className="row" key={item.index}>
                <div className="col-3">
                  <InputField
                    type="text"
                    label="Username"
                    name="username"
                    onChange={(e) => this.onUserVerChange(e, item)}
                    value={item.username.value}
                    isRequired={true}
                    hasError={!item.username.isValid}
                    errorMessage={item.username.message}
                    isDisabled={true}
                  />
                </div>
                <div className="col-3">
                  <InputField
                    type="text"
                    label="Nama Depan"
                    name="first_name"
                    onChange={(e) => this.onUserVerChange(e, item)}
                    value={item.first_name.value}
                    isRequired={true}
                    hasError={!item.first_name.isValid}
                    errorMessage={item.first_name.message}
                    isDisabled={true}
                  />
                </div>
                <div className="col-3">
                  <InputField
                    type="text"
                    label="Nama Belakang"
                    name="last_name"
                    onChange={(e) => this.onUserVerChange(e, item)}
                    value={item.last_name.value}
                    isRequired={true}
                    hasError={!item.last_name.isValid}
                    errorMessage={item.last_name.message}
                    isDisabled={true}
                  />
                </div>
                <div className="col-2">
                  <InputSelect
                    label="Mode"
                    name="mode"
                    placeholder="Pilih"
                    data={[
                      { value: "Online", label: "Online" },
                      { value: "Offline Mobile", label: "Offline Mobile" },
                      { value: "Offline Desktop", label: "Offline Desktop" },
                    ]}
                    onChange={(e) => this.onUserVerChange(e, item)}
                    value={item.mode.value}
                    isRequired={true}
                    hasError={!item.mode.isValid}
                    errorMessage={item.mode.message}
                  />
                </div>
                {mode !== PageMode.edit && (
                  <div className="col-1 align-self-center">
                    <div style={{ paddingTop: "30px" }}>
                      <button
                        className="btn btn-outline-danger btn-sm font-weight-bold mr-2"
                        onClick={() => {
                          const data = this.state.userVerDynamic;
                          data.splice(idx, 1);
                          this.setState({ userVerDynamic: data });
                        }}
                      >
                        <MinusCircle size={30} className="mr-2" /> Hapus
                      </button>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </>

        <div className="form-group d-flex justify-content-end">
          <Button
            onClick={() => this.props.changeMode(PageMode.view)}
            isSecondary
          >
            Batalkan
          </Button>
          <Button onClick={this.onSave} isPrimary>
            Simpan Data
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authReducer.currentUser,
  };
};

export default connect(mapStateToProps)(Form);
