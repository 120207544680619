const { parasyteName, dhpResult } = require("./helper");

function complicationRule11(value) {
  let statusMedication = false;

  const dhp_tbl = parseFloat(value.dhp_tablet) || 0;
  const primaquin_tbl = parseFloat(value.primaquin_tablet) || 0;
  const kina_tbl = parseFloat(value.kina_tablet) || 0;
  const kina_inj = parseFloat(value.kina_injeksi) || 0;
  const doksisiklin = parseFloat(value.doksisiklin) || 0;
  const tetrasiklin = parseFloat(value.tetrasiklin) || 0;
  const klindamisin = parseFloat(value.klindamisin) || 0;
  const artesunat_inj = parseFloat(value.artesunat_injeksi) || 0;
  const age = parseFloat(value.age) || 0;
  const age_type = value.age_type || "";
  const weight = parseInt(value.weight) || 0;
  const parasyte = value.parasyte || [];
  const isPregnancy = value.pregnancy === "Hamil";

  const defaultCondition = () => {
    if (!(parasyte.length === 1 && parasyte[0] === parasyteName.malariae)) {
      return;
    }
    if (!(doksisiklin == 0)) {
      return;
    }
    if (!(tetrasiklin == 0)) {
      return;
    }
    if (!(klindamisin == 0)) {
      return;
    }
    if (!(artesunat_inj > 0)) {
      return;
    }
    if (!(kina_inj == 0)) {
      return;
    }
    if (!dhpResult(weight, dhp_tbl)) {
      return;
    }
    if (!(kina_tbl == 0)) {
      return;
    }
    if (!(primaquin_tbl == 0)) {
      return;
    }
    statusMedication = true;
  };

  if (isPregnancy) {
    defaultCondition();
  } else {
    if (age_type == "Bulan" && age < 6) {
      defaultCondition();
    }
  }

  return statusMedication;
}

export default complicationRule11;
