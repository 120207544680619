import { TableComponent } from "components";
import React, { Component } from "react";
import { PlusCircle } from "react-feather";
import Datetime from "react-datetime";

export default class Table extends Component {
  render() {
    const {
      onFilterData,
      handleChangeYear,
      currentUser,
      data,
      onEdit,
      onRemove,
      changeMode,
      loading,
      onPageChange,
    } = this.props;

    return (
      <div>
        <div className="dashboard-wrapper">
          <h1 className="title font-weight-bold">Data Capaian</h1>
          <div className="content-wrapper mt-4">
            <div className="row">
              <div className="col-sm-6">
                <table className="table">
                  <tr>
                    <td>FASYANKES</td>
                    <th>:</th>
                    <th>
                      {currentUser.fasyankes_id &&
                        currentUser.fasyankes_id?.split("|")[1]}
                    </th>
                  </tr>
                </table>
              </div>
            </div>
            <div className="filter-data">
              <div className="row justify-content-start py-3">
                <div className="col-sm-6">
                  <form className="form-horizontal">
                    <div className="form-group row justify-content-start">
                      <label className="col-sm-3 col-form-label align-self-center">
                        Pilih Tahun
                      </label>
                      <div className="col-sm-4 align-self-center">
                        <Datetime
                          dateFormat="YYYY"
                          timeFormat={false}
                          closeOnClickOutside={true}
                          closeOnSelect={true}
                          onChange={handleChangeYear}
                          value={this.props.selected_year}
                          inputProps={{
                            name: "selected_year",
                            placeholder: "Pilih Tahun",
                          }}
                        />
                      </div>
                    </div>
                    <div className="form-group row justify-content-start">
                      <div className="col-sm-7">
                        <button
                          className="btn btn-teal btn-block"
                          onClick={(e) => {
                            e.preventDefault();
                            onFilterData();
                          }}
                        >
                          Terapkan
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="row justify-content-between">
              <div className="col-sm-6 align-self-center">
                <button
                  className="btn btn-teal font-weight-bold"
                  onClick={() => changeMode("add")}
                >
                  <PlusCircle className="mr-2" />
                  Tambah Data Capaian
                </button>
              </div>
            </div>
            <hr />
            <TableComponent
              loading={loading}
              data={data?.kia_screening_achieveds}
              headers={[
                "Tahun",
                "Bulan",
                "Jumlah Capaian Ibu Hamil Yang Melakukan K1",
                "Jumlah Bumil Diskrining Malaria",
                "Jumlah Ibu Hamil Yang Diberikan Kelambu Pada Saat K1",
                "Jumlah Kunjungan Balita Sakit",
                "Jumlah Balita Sakit Saat Diperiksa Malaria",
              ]}
              cols={[
                "year",
                "month",
                "total_pregnant_achieved_k1",
                "total_pregnant_screening_malaria",
                "total_pregnant_screening_net_k1",
                "total_visit_toddler",
                "total_toddler_sick",
              ]}
              btnEdit={{ onClick: onEdit }}
              btnRemove={{ onClick: onRemove }}
              currentPage={data?.currentPage}
              total={data?.total}
              limit={data?.limit}
              nextPage={data?.nextPage}
              previousPage={data?.previousPag}
              onPageChange={onPageChange}
            />
          </div>
        </div>
      </div>
    );
  }
}
