import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import authReducer from "./authReducer";
import populationReducer from "./populationReducer";
import pageReducer from "./pageReducer";
import showNotifReducer from "./showNotifReducer";

const history = (history) =>
  combineReducers({
    router: connectRouter(history),
    authReducer,
    populationReducer,
    pageReducer,
    showNotifReducer,
  });

export default history;
