import React, { Component } from "react";
import { Link } from "react-router-dom";
import Auth from "../../services/Auth";
import { confirmAlert } from "react-confirm-alert";
import { connect } from "react-redux";
import ToggleNav from "./ToggleNav";
import "./Header.scss";
import Notification from "services/Notification";
import { ReactComponent as BellFill } from "../../assets/icons/bell_fill.svg";

const AuthService = new Auth();
const NotificationService = new Notification();

class Header extends Component {
  handleLogout = () => {
    confirmAlert({
      title: "Anda yakin ingin keluar?",
      message: "Beberapa data mungkin belum tersimpan",
      buttons: [
        {
          label: "Ya",
          onClick: () => AuthService.loggedOut(),
        },
        {
          label: "Tidak",
        },
      ],
    });
  };

  constructor(props) {
    super(props);
    this.state = {
      importCase: 0,
      feedbackCase: 0,
      indigenousValidation: 0,
      peCase: 0,
      loading: true,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.getNotification();
  }

  getNotification = () => {
    this.setState({ loading: true, notif: null }, async () => {
      await NotificationService.get({
        regionId: `${this.props.user.username}`,
      })
        .then((data) => {
          if (this._isMounted) {
            this.setState({
              loading: false,
              importCase: data["import-case"] || 0,
              peCase: data["pe-case"] || 0,
              feedbackCase: data["feedback-case"] || 0,
              indigenousValidation: data["indigenous-validation"] || 0,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({ loading: false });
        });
    });
  };

  clearNotifications = () => {
    this.setState({ loading: true }, async () => {
      await NotificationService.reset({
        regionId: `${this.props.user.username}`,
      })
        .then((data) => {
          if (this._isMounted) {
            this.setState({
              loading: false,
              importCase: 0,
              peCase: 0,
              indigenousValidation: 0,
              feedbackCase: 0,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({ loading: false });
        });
    });
  };

  render() {
    const { user, showNotif } = this.props;
    const { importCase, feedbackCase, indigenousValidation, peCase } =
      this.state;
    const showbBtnReset =
      importCase > 0 ||
      feedbackCase > 0 ||
      indigenousValidation > 0 ||
      peCase > 0;

    return (
      <div className="main-header">
        <div className="logo-header" data-background-color="white">
          <Link to="/dashboard" className="logo">
            <img
              src="/dist/img/logo/logo_kemkes.png"
              alt="navbar brand"
              className="navbar-brand"
              height="50"
            />
          </Link>
          <ToggleNav />
        </div>
        <nav
          className="navbar navbar-header navbar-expand-lg"
          data-background-color="bg3"
        >
          <div className="container-fluid">
            <ul className="navbar-nav topbar-nav ml-md-auto align-items-center">
              {(user.level === "District" || user.level === "Fasyankes") && (
                <li
                  className={
                    showNotif.show
                      ? "nav-item dropdown hidden-caret submenu show"
                      : "nav-item dropdown hidden-caret"
                  }
                >
                  <Link
                    className="dropdown-toggle profile-pic mr-2"
                    data-toggle="dropdown"
                    to="#"
                    aria-expanded={showNotif.show}
                  >
                    <BellFill
                      width="30px"
                      height="30px"
                      fill={showbBtnReset ? "#FF0000" : "#808080"}
                    />
                  </Link>
                  <ul
                    className={
                      showNotif.show
                        ? "dropdown-menu dropdown-user animated fadeIn show"
                        : "dropdown-menu dropdown-user animated fadeIn"
                    }
                  >
                    <div className="dropdown-user-scroll scrollbar-outer">
                      {/* Notification list */}
                      {user.level === "District" && (
                        <>
                          <li>
                            <Link
                              className="dropdown-item"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                              to="/notifikasi-kasus-impor"
                            >
                              Notifikasi Kasus Impor
                              <span className="badge badge-warning">
                                {importCase}
                              </span>
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                              to="/validasi-indigenous"
                            >
                              Validasi Indigenous
                              <span className="badge badge-warning">
                                {indigenousValidation}
                              </span>
                            </Link>
                          </li>
                        </>
                      )}
                      {user.level === "Fasyankes" && (
                        <>
                          {user.fasyankes_type_id === "jnsfas-01" && (
                            <li>
                              <Link
                                className="dropdown-item"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                                to="/pe-kasus"
                              >
                                PE Kasus
                                <span className="badge badge-warning">
                                  {peCase}
                                </span>
                              </Link>
                            </li>
                          )}
                          <li>
                            <Link
                              className="dropdown-item"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                              to="/notifikasi-pe-feedback"
                            >
                              PE Feedback
                              <span className="badge badge-warning">
                                {feedbackCase}
                              </span>
                            </Link>
                          </li>
                        </>
                      )}
                      {showbBtnReset && (
                        <li>
                          <div className="dropdown-divider" />
                          <span
                            className="dropdown-item text-danger"
                            style={{ cursor: "pointer" }}
                            onClick={this.clearNotifications}
                          >
                            Hapus notifikasi
                          </span>
                        </li>
                      )}
                    </div>
                  </ul>
                </li>
              )}

              <li className="nav-item dropdown hidden-caret">
                <Link
                  className="dropdown-toggle profile-pic"
                  data-toggle="dropdown"
                  to="#"
                  aria-expanded="false"
                >
                  <div className="avatar-sm">
                    <img
                      src={`https://ui-avatars.com/api/?name=${this.props.user.first_name}+${this.props.user.last_name}`}
                      alt={`${this.props.user.first_name} ${this.props.user.last_name}`}
                      className="avatar-img rounded-circle"
                    />
                  </div>
                </Link>
                <ul className="dropdown-menu dropdown-user animated fadeIn">
                  <div className="dropdown-user-scroll scrollbar-outer">
                    <li>
                      <div className="user-box">
                        <div className="avatar-lg">
                          <img
                            src={`https://ui-avatars.com/api/?name=${this.props.user.first_name}+${this.props.user.last_name}`}
                            alt={`${this.props.user.first_name} ${this.props.user.last_name}`}
                            className="avatar-img rounded"
                          />
                        </div>
                        <div className="u-text">
                          <h4>
                            {this.props.user &&
                              this.props.user.first_name +
                                " " +
                                this.props.user.last_name}
                          </h4>
                          <p
                            style={{
                              fontSize: "10px",
                              fontWeight: "bold",
                            }}
                          >
                            {this.props.user.fasyankes_id?.split("|")[1]}
                          </p>
                          <p className="text-muted">
                            <span className="badge badge-success">
                              {this.props.user && this.props.user.role}
                            </span>
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="dropdown-divider" />
                      <span
                        className="dropdown-item text-danger"
                        style={{ cursor: "pointer" }}
                        onClick={this.handleLogout}
                      >
                        Logout
                      </span>
                    </li>
                  </div>
                </ul>
              </li>
            </ul>
          </div>
        </nav>
        {/* End Navbar */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authReducer.currentUser,
    showNotif: state.showNotifReducer,
  };
};

export default connect(mapStateToProps)(Header);
