import moment from "moment";
import React, { Component } from "react";
import { Button, InputSelect, TableComponent } from "../../../../components";
import InputDateTime from "components/InputDateTime";

export default class Table extends Component {
  constructor(props) {
    super(props);
    this.defaultValue = {
      year: { value: "" },
      month: { value: "" },
    };
    this.state = Object.assign({}, this.defaultValue);
  }

  componentDidMount() {
    this._isMounted = true;
    const { year, month } = this.state;
    const { getData, parentStateChanger, user } = this.props;
    getData(
      "fasyankes_id",
      `${user.fasyankes_id}`
    );
    parentStateChanger("loading", false);
  }

  handleInputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const isValid = value ? true : false;

    this.setState({ [name]: { value, isValid } });
  };

  onSubmitFilter = (e) => {
    e.preventDefault();
    const { year, month } = this.state;
    const { getData, user } = this.props;

    let filterBy, filterVal;
    if (month.value == "") {
      filterBy = "patient_visite_date|YYYY,fasyankes_id";
      filterVal = `${moment(year.value).year()},${user.fasyankes_id}`;
    } else {
      filterBy = "patient_visite_date|YYYY-MM,fasyankes_id";
      filterVal = `${moment(year.value).year()}-${month.value},${user.fasyankes_id}`;
    }
    getData(filterBy, filterVal);
  };

  render() {
    const { data, loading, onPageChange, onViewPeCase } = this.props;

    return (
      <>
        <div className="row justify-content-start py-3">
          <div className="col-sm-12">
            <div className="form-inline">
              <InputDateTime
                label="Tahun laporan"
                name="year"
                dateFormat="YYYY"
                onChange={(e) =>
                  this.handleInputChange({
                    preventDefault: () => { },
                    target: { name: "year", value: e },
                  })
                }
                placeholder={'Pilih Tahun'}
                value={this.state.year.value}
                isInline={true}
              />

              <InputSelect
                isInline={true}
                label="Bulan laporan"
                name="month"
                onChange={this.handleInputChange}
                value={this.state.month.value}
                placeholder="Pilih"
                data={[
                  {
                    label: "Januari",
                    value: "01",
                  },
                  {
                    label: "Februari",
                    value: "02",
                  },
                  {
                    label: "Maret",
                    value: "03",
                  },
                  {
                    label: "April",
                    value: "04",
                  },
                  {
                    label: "Mei",
                    value: "05",
                  },
                  {
                    label: "Juni",
                    value: "06",
                  },
                  {
                    label: "Juli",
                    value: "07",
                  },
                  {
                    label: "Agustus",
                    value: "08",
                  },
                  {
                    label: "September",
                    value: "09",
                  },
                  {
                    label: "Oktober",
                    value: "10",
                  },
                  {
                    label: "November",
                    value: "11",
                  },
                  {
                    label: "Desember",
                    value: "12",
                  },
                ]}
              />

              <div className="form-group d-flex justify-content-end">
                <Button onClick={this.onSubmitFilter} isPrimary>
                  Terapkan
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-sm-12">
            <TableComponent
              loading={loading}
              data={data?.regmals}
              headers={[
                "Dirujuk Ke Faskes Lain",
                "Nomor KTP",
                "Nama",
                "Jenis Kelamin",
                "Faskes Pencatat",
                "Faskes Domisili",
              ]}
              cols={[
                "referred_to_other_faskes",
                "ktp",
                "name",
                "gender",
                ["fasyankes_origin_id", "|", 1],
                ["fasyankes_id", "|", 1],
              ]}
              btnAddPeCase={{ onClick: onViewPeCase }}
              currentPage={data?.currentPage}
              total={data?.total}
              limit={data?.limit}
              nextPage={data?.nextPage}
              previousPage={data?.previousPage}
              onPageChange={onPageChange}
            />
          </div>
        </div>
      </>
    );
  }
}
