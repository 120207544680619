import React, { useState, useEffect } from "react";
import styles from "./InputSelect.module.scss";
import Select, { components } from "react-select";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    minHeight: "44px",
    border: state.isFocused ? "1px solid #18b3ab" : "1px solid #ecedf0",
    boxShadow: state.isFocused
      ? "0px 2px 12px 0px rgba(0, 0, 0, 0.08), 0px 1px 4px 0px rgba(0, 0, 0, 0.08)"
      : "none",
    "&:hover": {
      border: "1px solid #ecedf0",
    },
  }),
  option: (provided, state) => ({
    ...provided,
  }),
};

const InputSelect = ({
  label,
  name,
  error,
  disabled,
  value,
  onChange,
  onBlur,
  errorMessage,
  data,
  style,
  hasShadow,
  isRequired,
  placeholder,
  isMultipleSelect,
  isClearable = true,
  ...props
}) => {
  const [selectedOptions, setSelectedOptions] = useState(value || []);
  const [isAllSelected, setIsAllSelected] = useState(false);

  const inputClasses = [styles.input];

  if (error) {
    inputClasses.push(styles.error);
  }

  if (hasShadow) {
    inputClasses.push(styles.shadowEffect);
  }

  useEffect(() => {
    setSelectedOptions(value || []);
  }, [value]);

  useEffect(() => {
    if (selectedOptions.length === data.length) {
      setIsAllSelected(true);
    } else {
      setIsAllSelected(false);
    }
  }, [selectedOptions, data]);

  const handleSelectChange = (selected) => {
    setSelectedOptions(selected || []);
    onChange(selected || []);
  };

  const handleSelectAllChange = () => {
    if (!isAllSelected) {
      setSelectedOptions(data);
      onChange(data);
    } else {
      setSelectedOptions([]);
      onChange([]);
    }
    setIsAllSelected(!isAllSelected);
  };

  // Custom MenuList to add Select All checkbox
  const MenuList = (props) => {
    return (
      <>
        <div style={{ padding: "8px 12px", borderBottom: "1px solid #ecedf0" }}>
          <input
            type="checkbox"
            checked={isAllSelected}
            onChange={handleSelectAllChange}
            disabled={disabled}
          />{" "}
          Select All
        </div>
        <components.MenuList {...props}>{props.children}</components.MenuList>
      </>
    );
  };

  return (
    <div className={styles.formContainer}>
      {label && (
        <label htmlFor={label}>
          {label}{" "}
          {isMultipleSelect && (
            <span
              style={{
                fontSize: "12px",
                color: "#313131",
                fontWeight: "400",
              }}
            >
              (Dapat memilih lebih dari satu)
            </span>
          )}
          {isRequired ? <sup style={{ color: "#D91919" }}>*</sup> : null}{" "}
        </label>
      )}

      <Select
        name={name}
        isMulti={isMultipleSelect}
        className={inputClasses.join(" ")}
        value={selectedOptions}
        onChange={handleSelectChange}
        onBlur={onBlur}
        isDisabled={disabled}
        isClearable={isClearable}
        options={data}
        placeholder={placeholder}
        styles={customStyles}
        getOptionLabel={(data) => data.label}
        getOptionValue={(data) => data.value}
        components={isMultipleSelect && { MenuList }}
        {...props}
      />

      {error && <span className={styles.invalidInput}>{errorMessage}</span>}
    </div>
  );
};

export default InputSelect;
