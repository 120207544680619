import {
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import ActionButton from "components/atoms/ActionButton";
import Button from "components/atoms/Button";
import InputDate from "components/atoms/InputDate";
import InputField from "components/atoms/InputField";
import InputSelect from "components/atoms/InputSelect";
import TableDataComponent from "components/organisms/TableData";
import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { confirmAlert } from "react-confirm-alert";
import { useSelector } from "react-redux";
import Populasi from "services/Populasi";
import { errorToastNotif, successToastNotif } from "utils/common";

const PopulasiService = new Populasi();

const TableApproval = ({ provincies, onEdit }) => {
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedProvince, setSelectedProvince] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [searchKeyword, setSearchKeyword] = useState("");

  const [dataState, setDataState] = useState({
    page: 1,
    limit: 10,
    data: [],
    totalData: 0,
    currentPage: 1,
    nextPage: null,
    previousPage: null,
  });

  const fetchPopulationData = async () => {
    setIsLoading(true);
    try {
      let searchBy = "province_id";
      let searchValue = selectedProvince;

      if (searchKeyword) {
        searchBy += `,urbanvillage_id`;
        searchValue += `,${searchKeyword}`;
      }

      if (selectedYear) {
        searchBy += `,year`;
        searchValue += `,${selectedYear}`;
      }

      if (selectedStatus) {
        searchBy += `,status`;
        searchValue += `,${selectedStatus}`;
      }

      const response = await PopulasiService.getAll({
        page: dataState.page,
        limit: dataState.limit,
        searchBy,
        searchValue,
      });

      setDataState((prevState) => ({
        ...prevState,
        data: response?.populations,
        limit: response?.limit,
        currentPage: response?.currentPage,
        totalData: response?.total,
        nextPage: response?.nextPage,
        previousPage: response?.previousPage,
      }));
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPopulationData();
  }, [dataState.page, searchKeyword]);

  const onDataSearch = (e) => {
    const keyword = e.target.value;
    setSearchKeyword(keyword);
  };

  const onPageChange = (page) => {
    setDataState((prevState) => ({
      ...prevState,
      page: page,
    }));
  };

  const handleApprove = (id, status) => {
    confirmAlert({
      title: "Approve Data Desa",
      message: "Status data desa akan diubah menjadi approve",
      buttons: [
        {
          label: "Ya",
          onClick: async () => {
            await PopulasiService.update({ status }, id)
              .then((res) => {
                fetchPopulationData();
                successToastNotif("Data Desa Berhasil Di Approve");
              })
              .catch((err) => {
                console.log(err);
                errorToastNotif(err);
              });
          },
        },
        {
          label: "Tidak",
        },
      ],
    });
  };

  const handleReject = (id, status) => {
    confirmAlert({
      title: "Reject Data Desa",
      message: "Status data desa akan diubah menjadi reject",
      buttons: [
        {
          label: "Ya",
          onClick: async () => {
            await PopulasiService.update({ status }, id)
              .then((res) => {
                fetchPopulationData();
                successToastNotif("Data Desa Berhasil Di Reject");
              })
              .catch((err) => {
                console.log(err);
                errorToastNotif(err);
              });
          },
        },
        {
          label: "Tidak",
        },
      ],
    });
  };

  const columns = [
    {
      header: "Aksi",
      accessorKey: "action",
      cell: ({ row }) => {
        if (
          row.original.status === "Approved" ||
          row.original.status === "Rejected"
        ) {
          return null;
        }
        return (
          <div className="button-actions">
            {row.original.status === "Menunggu Approval" && (
              <ActionButton
                btnType={"edit"}
                onClick={() => onEdit(row.original)}
              />
            )}
            <ActionButton
              btnType={"approve"}
              onClick={() => handleApprove(row.original.id, "Approved")}
            />
            <ActionButton
              btnType={"reject"}
              onClick={() => handleReject(row.original.id, "Rejected")}
            />
          </div>
        );
      },
    },
    {
      header: "Status",
      accessorKey: "status",
      cell: ({ row }) => (
        <>
          {row.original.status === "Menunggu Approval" && (
            <span className="badge badge-warning">Menunggu Approval</span>
          )}
          {row.original.status === "Approved" && (
            <span className="badge badge-success">Diterima</span>
          )}
          {row.original.status === "Rejected" && (
            <span className="badge badge-danger">Ditolak</span>
          )}
        </>
      ),
    },
    {
      header: "Provinsi",
      accessorKey: "province_id",
      cell: ({ row }) => row.original.province_id?.split("|")[1],
    },
    {
      header: "Kabupaten/Kota",
      accessorKey: 'district_id',
      cell: ({ row }) => row.original.district_id?.split("|")[1],
    },
    {
      header: 'Kecamatan',
      accessorKey: 'subdistrict_id',
      cell: ({ row }) => row.original.subdistrict_id?.split("|")[1],
    },
    {
      header: 'Fasyankes',
      accessorKey: 'fasyankes_id',
      cell: ({ row }) => row.original.fasyankes_id?.split("|")[1],
    },
    {
      header: "Tahun",
      accessorKey: "year",
    },
    {
      header: "Kode Desa",
      accessorKey: "urbanvillage_id",
      cell: ({ row }) => row.original.urbanvillage_id?.split("|")[0],
    },
    {
      header: "Nama Desa",
      accessorKey: "urbanvillage_id",
      cell: ({ row }) => row.original.urbanvillage_id?.split("|")[1],
    },
    {
      header: "Jumlah Penduduk",
      accessorKey: "total_population",
    },
    {
      header: "Reseptifitas",
      accessorKey: "reseptifitas"
    },
  ];

  const tableInstance = useReactTable({
    columns,
    data: dataState?.data,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <>
      <h1 className="page-title">Data Dasar Penduduk - Pusat</h1>
      <div className="card-filter">
        <div className="filter-item">
          <InputDate
            placeholder={"Pilih Tahun"}
            name={"selected_year"}
            dateFormat={"YYYY"}
            onChange={(e) => {
              const selectedYear = moment(e).format("YYYY");
              setSelectedYear(selectedYear);
            }}
            value={selectedYear}
          />
        </div>
        <div className="filter-item">
          <InputSelect
            placeholder={"Pilih Provinsi"}
            data={provincies.map((item) => {
              return {
                label: item.name,
                value: `${item.id}|${item.name}`,
              };
            })}
            onChange={(e) => {
              const selectedValue = e ? e.value : "";
              setSelectedProvince(selectedValue);
            }}
            value={provincies
              .map((item) => {
                return {
                  label: item.name,
                  value: `${item.id}|${item.name}`,
                };
              })
              .find((item) => item.value === selectedProvince)}
          />
        </div>
        <div className="filter-item">
          <InputSelect
            placeholder={"Pilih Status"}
            data={[
              { label: "Diterima", value: "Approved" },
              { label: "Menunggu Approval", value: "Menunggu Approval" },
            ]}
            onChange={(e) => {
              const selectedValue = e ? e.value : "";
              setSelectedStatus(selectedValue);
            }}
            value={[
              { label: "Diterima", value: "Approved" },
              { label: "Menunggu Approval", value: "Menunggu Approval" },
            ].find((item) => item.value === selectedStatus)}
          />
        </div>
        <Button
          variant={"primary"}
          size={"normal"}
          onClick={() => fetchPopulationData()}
        >
          Terapkan
        </Button>
      </div>
      <div className="action-wrapper">
        <div className="action-button" />
        <div className="action-search">
          <InputField
            placeholder={"Cari menggunakan nama desa"}
            onChange={onDataSearch}
            value={searchKeyword}
          />
        </div>
      </div>
      <TableDataComponent
        columnLength={columns?.length}
        tableInstance={tableInstance}
        onPageChange={onPageChange}
        loading={isLoading}
        currentPage={dataState?.currentPage}
        limit={dataState?.limit}
        nextPage={dataState?.nextPage}
        previousPage={dataState?.previousPage}
        total={dataState?.totalData}
      />
    </>
  );
};

export default TableApproval;
