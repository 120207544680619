import { Button, InputField, InputSelect } from "components";
import React, { Component } from "react";
import { ErrorMessage, PageMode, requestStatus } from "utils/constant";
import Achieved from "services/Achieved";
import InputDateTime from "components/InputDateTime";
import moment from "moment";
import { toast } from "react-toastify";
import { ArrowLeft } from "react-feather";

const AchievedService = new Achieved();

export default class Form extends Component {
  _isMounted = true;

  constructor(props) {
    super(props);
    this.defaultValue = {
      fasyankes_id: {
        value: this.props.currentUser.fasyankes_id,
        isValid: true,
        message: "",
      },

      year: { value: moment().format("YYYY"), isValid: true, message: "" },
      month: { value: "", isValid: true, message: "" },
      total_pregnant_achieved_k1: { value: "", isValid: true, message: "" },
      total_pregnant_screening_malaria: {
        value: "",
        isValid: true,
        message: "",
      },
      total_pregnant_screening_net_k1: {
        value: "",
        isValid: true,
        message: "",
      },
      total_visit_toddler: { value: "", isValid: true, message: "" },
      total_toddler_sick: { value: "", isValid: true, message: "" },
    };

    this.state = Object.assign({}, this.defaultValue);
  }

  componentDidMount() {
    this._isMounted = true;
    let obj = Object.assign({}, this.defaultValue);
    if (this.props.mode === PageMode.edit) {
      const { data } = this.props;

      obj.fasyankes_id.value = data.fasyankes_id;
      obj.year.value = data.year;
      obj.month.value = data.month;
      obj.total_pregnant_achieved_k1.value = data.total_pregnant_achieved_k1;
      obj.total_pregnant_screening_malaria.value =
        data.total_pregnant_screening_malaria;
      obj.total_pregnant_screening_net_k1.value =
        data.total_pregnant_screening_net_k1;
      obj.total_visit_toddler.value = data.total_visit_toddler;
      obj.total_toddler_sick.value = data.total_toddler_sick;
    }
    this.setState(obj);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    let obj = Object.assign({}, this.state[name]);
    obj.value = value;

    if (name === "month") {
      this.setState(
        {
          [name]: { value: value, isValid: true, message: "" },
        },
        () => {
          this.onGetTotalBumilBalita();
        }
      );
    }

    this.setState({
      [name]: obj,
    });
  };

  onGetTotalBumilBalita = async () => {
    const { fasyankes_id, year, month } = this.state;

    await AchievedService.getTotalBumilBalita({
      fasyankes_id: fasyankes_id.value,
      year: year.value,
      month: month.value,
    })
      .then((res) => {

        this.setState({
          total_pregnant_screening_malaria: {
            value: res.total_bumil,
            isValid: true,
            message: "",
          },
          total_toddler_sick: {
            value: res.total_balita,
            isValid: true,
            message: "",
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onSave = async (e) => {
    e.preventDefault();

    const isValid = this.validationHandler();

    if (isValid) {
      let payload = {
        fasyankes_id: this.state.fasyankes_id.value,
        year: this.state.year.value,
        month: this.state.month.value,
        total_pregnant_achieved_k1: this.state.total_pregnant_achieved_k1.value,
        total_pregnant_screening_malaria:
          this.state.total_pregnant_screening_malaria.value,
        total_pregnant_screening_net_k1:
          this.state.total_pregnant_screening_net_k1.value,
        total_visit_toddler: this.state.total_visit_toddler.value,
        total_toddler_sick: this.state.total_toddler_sick.value,
      };
      switch (this.props.mode) {
        case PageMode.add:
          await AchievedService.add(payload)
            .then((res) => {
              this.setState(this.defaultValue, () => {
                this.props.changeMode("view");
              });
              toast.success("Data capaian berhasil Ditambahkan", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            })
            .catch((err) => {
              console.log(err);

              if (err.response.status === requestStatus.BadRequest) {
                toast.error(ErrorMessage.UserFaskesNotHaveAccess, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }

              if (err.response.status === requestStatus.Forbidden) {
                toast.error(ErrorMessage.UserNotVerified, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }

              if (err.response.status === requestStatus.Conflict) {
                toast.error(ErrorMessage.DataAlreadyExist, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }if (err.response.status === requestStatus.ServerError) {
                toast.error(ErrorMessage.InternalServerError, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
            });
          break;
        case PageMode.edit:
          await AchievedService.update(payload, this.props.data.id)
            .then((res) => {
              this.setState(this.defaultValue, () => {
                this.props.changeMode("view");
              });
              toast.success("Data capaian Berhasil Diperbarui", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            })
            .catch((err) => {
              console.log(err);

              if (err.response.status === requestStatus.BadRequest) {
                toast.error(ErrorMessage.UserFaskesNotHaveAccess, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }

              if (err.response.status === requestStatus.Forbidden) {
                toast.error(ErrorMessage.UserNotVerified, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }

              if (err.response.status === requestStatus.Conflict) {
                toast.error(ErrorMessage.DataAlreadyExist, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }if (err.response.status === requestStatus.ServerError) {
                toast.error(ErrorMessage.InternalServerError, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
            });
          break;
      }
    }
  };

  validationHandler = () => {
    const {
      year,
      month,
      total_pregnant_achieved_k1,
      total_pregnant_screening_malaria,
      total_pregnant_screening_net_k1,
      total_visit_toddler,
      total_toddler_sick,
    } = this.state;

    let isValid = true;

    if (year.value === "") {
      year.isValid = false;
      year.message = "Tahun tidak boleh kosong";
      isValid = false;
    }

    if (month.value === "") {
      month.isValid = false;
      month.message = "Bulan tidak boleh kosong";
      isValid = false;
    }

    if (total_pregnant_achieved_k1.value === "") {
      total_pregnant_achieved_k1.isValid = false;
      total_pregnant_achieved_k1.message =
        "Jumlah capaian ibu hamil yang melakukan K1 tidak boleh kosong";
      isValid = false;
    }

    if (total_pregnant_screening_malaria.value === "") {
      total_pregnant_screening_malaria.isValid = false;
      total_pregnant_screening_malaria.message =
        "Jumlah ibu hamil yang di skrining malaria tidak boleh kosong";
      isValid = false;
    }

    if (total_pregnant_screening_net_k1.value === "") {
      total_pregnant_screening_net_k1.isValid = false;
      total_pregnant_screening_net_k1.message =
        "Jumlah ibu hamil yang diberikan kelambu pada saat k1 tidak boleh kosong";
      isValid = false;
    }

    if (total_visit_toddler.value === "") {
      total_visit_toddler.isValid = false;
      total_visit_toddler.message =
        "Jumlah kunjungan balita sakit tidak boleh kosong";
      isValid = false;
    }

    if (total_toddler_sick.value === "") {
      total_toddler_sick.isValid = false;
      total_toddler_sick.message =
        "Jumlah balita sakit yang Diperiksa malaria tida boleh kosong";
      isValid = false;
    }

    this.setState({
      year,
      month,
      total_pregnant_achieved_k1,
      total_pregnant_screening_malaria,
      total_pregnant_screening_net_k1,
      total_visit_toddler,
      total_toddler_sick,
    });

    return isValid;
  };

  render() {
    return (
      <div className="dashboard-wrapper">
        <div
          className="my-3"
          style={{ cursor: "pointer" }}
          onClick={() => {
            this.props.changeMode("view");
          }}
        >
          <ArrowLeft />
          <span>Kembali</span>
        </div>

        <div className="title font-weight-bold">Tambah Data Capaian</div>
        <div className="content-wrapper mt-4">
          <div className="row">
            <div className="col-12">
              <div className="form-wrapper">
                <InputDateTime
                  label="Tahun"
                  name="year"
                  dateFormat="YYYY"
                  value={this.state.year.value}
                  placeholder="Tahun"
                  onChange={(e) => {
                    this.handleInputChange({
                      target: { name: "year", value: moment(e).format("YYYY") },
                    });
                  }}
                  isRequired={true}
                  hasError={!this.state.year.isValid}
                  errorMessage={this.state.year.message}
                  isDisabled={this.props.mode === PageMode.edit}
                />

                <InputSelect
                  label="Bulan"
                  name="month"
                  onChange={this.handleInputChange}
                  placeholder="Pilih bulan"
                  data={[
                    { label: "Januari", value: "01" },
                    { label: "Februari", value: "02" },
                    { label: "Maret", value: "03" },
                    { label: "April", value: "04" },
                    { label: "Mei", value: "05" },
                    { label: "Juni", value: "06" },
                    { label: "Juli", value: "07" },
                    { label: "Agustus", value: "08" },
                    { label: "September", value: "09" },
                    { label: "Oktober", value: "10" },
                    { label: "November", value: "11" },
                    { label: "Desember", value: "12" },
                  ]}
                  value={this.state.month.value}
                  isRequired={true}
                  hasError={!this.state.month.isValid}
                  errorMessage={this.state.month.message}
                  isDisabled={this.props.mode === PageMode.edit}
                />

                <InputField
                  label="Jumlah capaian ibu hamil yang melakukan k1"
                  name="total_pregnant_achieved_k1"
                  type="numberOnly"
                  onChange={this.handleInputChange}
                  value={this.state.total_pregnant_achieved_k1.value}
                  placeholder="0"
                  isRequired={true}
                  hasError={!this.state.total_pregnant_achieved_k1.isValid}
                  errorMessage={this.state.total_pregnant_achieved_k1.message}
                />

                <InputField
                  label="Jumlah ibu hamil di skrining malaria"
                  name="total_pregnant_screening_malaria"
                  type="numberOnly"
                  onChange={this.handleInputChange}
                  value={this.state.total_pregnant_screening_malaria.value}
                  placeholder="0"
                  isRequired={true}
                  hasError={
                    !this.state.total_pregnant_screening_malaria.isValid
                  }
                  errorMessage={
                    this.state.total_pregnant_screening_malaria.message
                  }
                  isReadOnly
                />

                <InputField
                  label="Jumlah ibu hamil yang diberikan kelambu pada saat K1"
                  name="total_pregnant_screening_net_k1"
                  type="numberOnly"
                  onChange={this.handleInputChange}
                  value={this.state.total_pregnant_screening_net_k1.value}
                  placeholder="0"
                  isRequired={true}
                  hasError={!this.state.total_pregnant_screening_net_k1.isValid}
                  errorMessage={
                    this.state.total_pregnant_screening_net_k1.message
                  }
                />

                <InputField
                  label="Jumlah kunjungan balita sakit"
                  name="total_visit_toddler"
                  type="numberOnly"
                  onChange={this.handleInputChange}
                  value={this.state.total_visit_toddler.value}
                  placeholder="0"
                  isRequired={true}
                  hasError={!this.state.total_visit_toddler.isValid}
                  errorMessage={this.state.total_visit_toddler.message}
                />

                <InputField
                  label="Jumlah balita sakit yang Diperiksa malaria"
                  name="total_toddler_sick"
                  type="numberOnly"
                  onChange={this.handleInputChange}
                  value={this.state.total_toddler_sick.value}
                  placeholder="0"
                  isRequired={true}
                  hasError={!this.state.total_toddler_sick.isValid}
                  errorMessage={this.state.total_toddler_sick.message}
                  isReadOnly
                />

                <div className="form-group d-flex justify-content-end">
                  <Button
                    onClick={() => this.props.changeMode("view")}
                    isSecondary
                  >
                    Batalkan
                  </Button>
                  <Button onClick={this.onSave} isPrimary>
                    Simpan
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
