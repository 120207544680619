import {
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { InputField } from "components";
import ActionButton from "components/atoms/ActionButton";
import Button from "components/atoms/Button";
import TableDataComponent from "components/organisms/TableData";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import MMPCadre from "services/MMPCadre";
import { PageMode } from "utils/constant";

const KaderMMPService = new MMPCadre();

const TableComponent = ({ handlePageModeChange, handleEditData }) => {
  const [state, setState] = useState({
    page: 1,
    limit: 10,
    searchKeyword: "",
    isLoading: true,
    data: [],
    totalData: 0,
    currentPage: 1,
    nextPage: null,
    previousPage: null,
  });

  const { currentUser } = useSelector((state) => state.authReducer);
  const history = useHistory();

  const getAllKaderMMP = async () => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    try {
      let searchBy = "fasyankes_id";
      let searchValue = currentUser?.fasyankes_id;

      if (state.searchKeyword !== "") {
        searchBy += ",name";
        searchValue += `,${state.searchKeyword}`;
      }

      const response = await KaderMMPService.getAll({
        page: state.page,
        limit: state.limit,
        searchBy,
        searchValue,
      });
      setState((prevState) => ({
        ...prevState,
        data: response.cadres,
        limit: response.limit,
        currentPage: response.currentPage,
        totalData: response.total,
        nextPage: response.nextPage,
        previousPage: response.previousPage,
      }));
    } catch (error) {
      console.log(error);
    } finally {
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    }
  };

  useEffect(() => {
    getAllKaderMMP();
  }, [state.page, state.searchKeyword]);

  const onPageChange = (page) => {
    setState((prevState) => ({
      ...prevState,
      page: page,
    }));
  };

  const onDataSearch = (e) => {
    const keyword = e.target.value;
    setState({
      ...state,
      searchKeyword: keyword,
    });
  };

  const columns = [
    {
      header: "Aksi",
      accessorKey: "action",
      cell: ({ row }) => (
        <div className="button-actions">
          <ActionButton
            onClick={() => {
              const selectedData = row.original;
              handleEditData(selectedData);
            }}
            btnType={"edit"}
          />
          <ActionButton
            onClick={() =>
              history.push(`/cadre-mmp-performance/${row.original.id}`)
            }
            btnType={"kinerja"}
          />
        </div>
      ),
    },
    {
      header: "Nama Kader",
      accessorKey: "name",
    },
    {
      header: "Usia (Tahun)",
      accessorKey: "age",
    },
    {
      header: "Jenis kelamin",
      accessorKey: "gender",
    },
    {
      header: "Pendidikan Terakhir",
      accessorKey: "last_education",
    },
    {
      header: "Tanggal Penetapan SK",
      accessorKey: "sk_date",
    },
    {
      header: "Tanggal Pelatihan Terakhir",
      accessorKey: "last_training_date",
    },
    {
      header: "Sumber Pendanaan",
      accessorKey: "fund_source_cadre",
    },
  ];

  const tableInstance = useReactTable({
    columns,
    data: state?.data,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <>
      <div className="action-wrapper">
        <div className="action-button">
          {currentUser && currentUser.mode === "Online" && (
            <Button
              variant={"primary"}
              size={"normal"}
              onClick={() => handlePageModeChange(PageMode.add)}
            >
              Tambah Data Kader MMP
            </Button>
          )}
        </div>
        <div className="action-search">
          <InputField
            placeholder={"Cari berdasarkan nama kader"}
            onChange={onDataSearch}
            value={state.searchKeyword}
          />
        </div>
      </div>
      <TableDataComponent
        columnLength={columns?.length}
        tableInstance={tableInstance}
        onPageChange={onPageChange}
        loading={state.isLoading}
        currentPage={state.currentPage}
        limit={state.limit}
        nextPage={state.nextPage}
        previousPage={state.previousPage}
        total={state.totalData}
      />
    </>
  );
};

export default TableComponent;
