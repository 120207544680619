import React from "react";
import styles from "./InputField.module.scss";
import propTypes from "prop-types";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { useState } from "react";

const InputField = ({
  type,
  label,
  boldLabel,
  name,
  error,
  disabled,
  value,
  onChange,
  onBlur,
  hasShadow,
  placeholder,
  errorMessage,
  isRequired,
  maxLengthVal,
  isDecimal,
  ...props
}) => {
  const [hidePassword, setHidePassword] = useState(true);

  const inputClasses = [styles.input];

  if (error) {
    inputClasses.push(styles.error);
  }

  const renderInputField = () => {
    switch (type) {
      case "textarea":
        return (
          <textarea
            name={name}
            rows="4"
            className={inputClasses.join(" ")}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            disabled={disabled}
            placeholder={placeholder}
            {...props}
          ></textarea>
        );
      case "number":
        return (
          <input
            type="text"
            name={name}
            className={inputClasses.join(" ")}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            inputMode="numeric"
            pattern="[0-9]*"
            onKeyPress={(e) => {
              const isDigit = /[0-9]/.test(e.key);
              if (e.key === ',') {
                e.preventDefault();
              }
              const isDecimalPoint =
                e.key === "." && !e.target.value.includes(".");
              if (isDecimal) {
                if (!isDigit && !isDecimalPoint) {
                  e.preventDefault();
                }
              } else {
                if (!isDigit) {
                  e.preventDefault();
                }
              }
            }}
            maxLength={maxLengthVal}
            disabled={disabled}
            placeholder={placeholder}
            {...props}
          />
        );
      case "decimal":
        return (
          <input
            type="text"
            name={name}
            className={inputClasses.join(" ")}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            inputMode="decimal"
            pattern="[0-9.]*"
            onKeyPress={(e) => {
              if (e.key === ",") {
                e.preventDefault();
              }
              if (!/^\d*\.?\d*$/.test(e.target.value + e.key)) {
                e.preventDefault();
              }
            }}
            maxLength={maxLengthVal}
            disabled={disabled}
            placeholder={placeholder}
            {...props}
          />
        );
      case "password":
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <input
              type={hidePassword ? "password" : "text"}
              style={{ width: "100%" }}
              name={name}
              className={inputClasses.join(" ")}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              disabled={disabled}
              placeholder={placeholder}
              {...props}
            />
            <div
              style={{
                height: "45px",
                width: "45px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid #eee",
                borderRadius: "8px",
              }}
            >
              <div
                style={{ cursor: "pointer" }}
                onClick={() => setHidePassword(!hidePassword)}
              >
                {hidePassword ? (
                  <FiEye size={"18"} />
                ) : (
                  <FiEyeOff size={"18"} />
                )}
              </div>
            </div>
          </div>
        );
      default:
        return (
          <input
            type={"text"}
            name={name}
            className={inputClasses.join(" ")}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            disabled={disabled}
            placeholder={placeholder}
            maxLength={maxLengthVal}
            {...props}
          />
        );
    }
  };

  return (
    <div className={styles.formContainer}>
      <label htmlFor={label} className={boldLabel ? 'font-weight-bold' : ''}>
        {label} {isRequired ? <sup style={{ color: "#D91919" }}>*</sup> : null}{" "}
      </label>
      {renderInputField()}
      {error && <span className={styles.invalidInput}>{errorMessage}</span>}
    </div>
  );
};

export default InputField;
