import Button from "components/atoms/Button";
import { BreadcrumbNav } from "components/molecules";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import {
  getDataFromLocalStorage,
  removeDataFromLocalStorage,
} from "utils/common";
import { PageMode } from "utils/constant";

const DetailComponent = ({ changeMode }) => {
  const [selectedData, setSelectedData] = useState(null);

  useEffect(() => {
    const selectedItem = getDataFromLocalStorage("SELECTED_ITEM");
    const parsedData = JSON.parse(selectedItem);

    setSelectedData(parsedData);
  }, []);

  return (
    <>
      <BreadcrumbNav
        items={[
          { label: "Notifikasi Kasus Impor", link: "/notifikasi-kasus-impor" },
          { label: "Detail Notifikasi Kasus Impor", link: "#" },
        ]}
      />
      <h1 className="page-title">Detail Notifikasi Kasus Impor</h1>

      <div className="detail-data-wrapper">
        <div className="detail-data-item">
          <div className="label">Nama</div>
          <div className="separator">:</div>
          <div className="value">{selectedData?.name}</div>
        </div>
        <div className="detail-data-item">
          <div className="label">Usia</div>
          <div className="separator">:</div>
          <div className="value">{`${selectedData?.age} ${selectedData?.age_type}`}</div>
        </div>
        <div className="detail-data-item">
          <div className="label">Pekerjaan</div>
          <div className="separator">:</div>
          <div className="value">{selectedData?.job}</div>
        </div>
        {/* Pencatat */}
        <div className="detail-data-item">
          <div className="label">Faskes Pencatat</div>
          <div className="separator">:</div>
          <div className="value">
            {selectedData?.originator_fasyankes_name}
          </div>
        </div>
        <div className="detail-data-item">
          <div className="label">Provinsi Pencatat</div>
          <div className="separator">:</div>
          <div className="value">{selectedData?.originator_province_name}</div>
        </div>
        <div className="detail-data-item">
          <div className="label">Kabupaten Pencatat</div>
          <div className="separator">:</div>
          <div className="value">{selectedData?.originator_district_name}</div>
        </div>
        {/* Pelaku */}
        <div className="detail-data-item">
          <div className="label">Faskes Yang Melakukan PE</div>
          <div className="separator">:</div>
          <div className="value">
            {selectedData?.user_fasyankes}
          </div>
        </div>
        <div className="detail-data-item">
          <div className="label">Provinsi Yang Melakukan PE</div>
          <div className="separator">:</div>
          <div className="value">{selectedData?.user_province}</div>
        </div>
        <div className="detail-data-item">
          <div className="label">Kabupaten Yang Melakukan PE</div>
          <div className="separator">:</div>
          <div className="value">{selectedData?.user_district}</div>
        </div>
        <div className="detail-data-item">
          <div className="label">Kegiatan Penemuan Kasus</div>
          <div className="separator">:</div>
          <div className="value">{selectedData?.discovery_activities}</div>
        </div>
        <div className="detail-data-item">
          <div className="label">Tanggal Kunjungan Pasien</div>
          <div className="separator">:</div>
          <div className="value">{selectedData?.patient_visite_date}</div>
        </div>
        <div className="detail-data-item">
          <div className="label">Tanggal Wawancara</div>
          <div className="separator">:</div>
          <div className="value">{selectedData?.case_interview_date}</div>
        </div>
        <div className="detail-data-item">
          <div className="label">Jenis Parasit</div>
          <div className="separator">:</div>
          <div className="value" style={{ fontStyle: "italic" }}>
            {selectedData?.parasyte?.join(", ")}
          </div>
        </div>
        <div className="detail-data-item">
          <div className="label">Kekambuhan</div>
          <div className="separator">:</div>
          <div className="value">{selectedData?.recurrence}</div>
        </div>
        <div className="detail-data-item">
          <div className="label">Tanggal Kasus Awal</div>
          <div className="separator">:</div>
          <div className="value">{selectedData?.initial_case_date}</div>
        </div>
        <div className="detail-data-item">
          <div className="label">Status Pengobatan</div>
          <div className="separator">:</div>
          <div className="value">{selectedData?.standard_medication_status}</div>
        </div>
        <div className="detail-data-item">
          <div className="label">Kematian dengan Malaria</div>
          <div className="separator">:</div>
          <div className="value">{selectedData?.death_with_malaria}</div>
        </div>
        <div className="detail-data-item">
          <div className="label">Penularan</div>
          <div className="separator">:</div>
          <div className="value">{selectedData?.transmission}</div>
        </div>
        <div className="detail-data-item">
          <div className="label">Asal Penularan</div>
          <div className="separator">:</div>
          <div className="value">{selectedData?.transmission_origin}</div>
        </div>

        {/* Pelaku */}
        <div className="detail-data-item">
          <div className="label">Nama Provinsi Asal Penularan</div>
          <div className="separator">:</div>
          <div className="value">
            {selectedData?.province_id?.split("|")[1]}
          </div>
        </div>
        <div className="detail-data-item">
          <div className="label">Nama Kab/Kota Asal Penularan</div>
          <div className="separator">:</div>
          <div className="value">
            {selectedData?.district_id?.split("|")[1]}
          </div>
        </div>
        <div className="detail-data-item">
          <div className="label">Nama Kecamatan Asal Penularan</div>
          <div className="separator">:</div>
          <div className="value">
            {selectedData?.subdistrict_id?.split("|")[1]}
          </div>
        </div>
        <div className="detail-data-item">
          <div className="label">Nama Puskesmas Asal Penularan</div>
          <div className="separator">:</div>
          <div className="value">
            {selectedData?.fasyankes_id?.split("|")[1]}
          </div>
        </div>
        <div className="detail-data-item">
          <div className="label">Nama Desa Asal Penularan</div>
          <div className="separator">:</div>
          <div className="value">
            {selectedData?.urbanvillage_id?.split("|")[1]}
          </div>
        </div>
        <div className="detail-data-item">
          <div className="label">Nama Dusun Asal Penularan</div>
          <div className="separator">:</div>
          <div className="value">{selectedData?.sub_village}</div>
        </div>
        <div className="detail-data-item">
          <div className="label">Alamat Lengkap Asal Penularan</div>
          <div className="separator">:</div>
          <div className="value">{selectedData?.address_of_infection}</div>
        </div>
        <div className="detail-data-item">
          <div className="label">No Telepon Pasien Malaria</div>
          <div className="separator">:</div>
          <div className="value">{selectedData?.phone_number}</div>
        </div>
      </div>
      <div
        style={{
          marginTop: "1rem",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant={"primary"}
          size={"normal"}
          onClick={() => {
            changeMode(PageMode.view);
            removeDataFromLocalStorage("SELECTED_ITEM");
          }}
        >
          Kembali
        </Button>
      </div>
    </>
  );
};

export default DetailComponent;
