import React, { Component } from "react";
import { PlusCircle } from "react-feather";
import { TableComponent } from "components";

export default class Table extends Component {
  render() {
    const {
      data,
      changeMode,
      loading,
      onEdit,
      onRemove,
      onPageChange,
      onGetFile,
      onGetLetter,
    } = this.props;

    return (
      <div>
        <div className="row">
          <div className="col-sm-8">
            <button
              className="btn btn-teal font-weight-bold"
              onClick={() => changeMode("add")}
            >
              <PlusCircle className="mr-2" />
              Tambah Data
            </button>
          </div>
        </div>

        <TableComponent
          loading={loading}
          data={data?.logistic_request_stocks}
          headers={[
            "Tahun Pengajuan",
            "Provinsi",
            "No Surat",
            "Jenis Permintaan",
            "Jenis Logistik",
            "Jumlah yang diminta",
            "Satuan",
            "Jumlah yang dipenuhi",
            "Satuan",
            "Status",
            "Surat Permintaan",
            "Surat Pemenuhan",
          ]}
          cols={[
            "year_submission",
            ["province_id", "|", 1],
            "letter_number",
            "request_type",
            "logistic_type",
            "request_total",
            "unit",
            "fulfilled_total",
            "fulfilled_unit",
            "status",
            "letter_upload",
            "fulfilled_letter_upload"
          ]}
          btnEdit={{ onClick: onEdit }}
          btnRemove={{ onClick: onRemove }}
          currentPage={data?.currentPage}
          total={data?.total}
          limit={data?.limit}
          nextPage={data?.nextPage}
          previousPage={data?.previousPage}
          onPageChange={onPageChange}
          onGetFile={onGetFile}
          onGetLetter={onGetLetter}
        />
      </div>
    );
  }
}
