import React from "react";
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import { useSelector } from "react-redux";
import Button from "components/atoms/Button";
import { PageMode } from "utils/constant";
import TableDataComponent from "components/organisms/TableData";
import { useState } from "react";
import LarvaBreeding from "services/LarvaBreeding";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import {
  errorToastNotif,
  setDataToLocalStorage,
  successToastNotif,
} from "utils/common";
import { confirmAlert } from "react-confirm-alert";
import ActionButton from "components/atoms/ActionButton";
import { BreadcrumbNav } from "components/molecules";
import { useHistory } from "react-router-dom";

const LarvaBreedingService = new LarvaBreeding();

const TableComponent = ({ changeMode }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [dataState, setDataState] = useState({
    page: 1,
    limit: 10,
    data: [],
    totalData: 0,
    currentPage: 1,
    nextPage: null,
    previousPage: null,
  });

  const { currentUser } = useSelector((state) => state.authReducer);
  const params = useParams();
  const history = useHistory();

  const fetchLarvaBreedingData = async () => {
    setIsLoading(true);
    try {
      const response = await LarvaBreedingService.getAll({
        searchBy: `vector_larva_identification_id`,
        searchValue: params.id,
      });

      setDataState((prevState) => ({
        ...prevState,
        data: response.vector_larva_interventions,
        limit: response.limit,
        currentPage: response.currentPage,
        totalData: response.total,
        nextPage: response.nextPage,
        previousPage: response.previousPage,
      }));
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchLarvaBreedingData();
  }, [dataState.page]);

  const onPageChange = (page) => {
    setDataState((prevState) => ({
      ...prevState,
      page: page,
    }));
  };

  const onEditData = (item) => {
    console.log(item);
    setDataToLocalStorage("SELECTED_ITEM", JSON.stringify(item));
    changeMode(PageMode.edit);
  };

  const onRemoveData = (id) => {
    confirmAlert({
      title: "Apakah anda yakin ingin menghapus data?",
      message: "Data akan dihapus secara permanen",
      buttons: [
        {
          label: "Ya",
          onClick: async () => {
            await LarvaBreedingService.remove(id)
              .then((res) => {
                fetchLarvaBreedingData();
                successToastNotif(
                  "Data Intervensi Perindukan Nyamuk Berhasil Dihapus"
                );
              })
              .catch((err) => {
                console.log(err);
                errorToastNotif(err);
              });
          },
        },
      ],
    });
  };

  const columns = [
    {
      header: "Aksi",
      accessorKey: "action",
      cell: ({ row }) => (
        <div className="button-actions">
          <ActionButton
            onClick={() => onEditData(row.original)}
            btnType={"edit"}
          />
          <ActionButton
            onClick={() => onRemoveData(row.original.id)}
            btnType={"delete"}
          />
        </div>
      ),
    },
    {
      header: "Tanggal Kegiatan Intervensi",
      accessorKey: "intervention_date",
    },
    {
      header: "Itervensi Yang Telah Dilakukan",
      accessorKey: "intervention_done",
    },
    {
      header: "Jenis Larvasidasi",
      accessorKey: "type_larvicide",
    },
    {
      header: "Jumlah Larvasidasi",
      accessorKey: "total_larvicide",
    },
    {
      header: "Satuan Larvasidasi",
      accessorKey: "total_larvicide_unit",
    },
  ];

  const tableInstance = useReactTable({
    columns,
    data: dataState?.data,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <>
      <h1 className="page-title">Data Intervensi Perindukan Nyamuk</h1>
      <div
        className="action-wrapper"
        style={{ marginTop: "24px", marginBottom: "24px" }}
      >
        <div className="action-button">
          {currentUser?.mode === "Online" && (
            <Button
              variant={"primary"}
              size={"normal"}
              onClick={() => changeMode(PageMode.add)}
            >
              Tambah Data Intervensi Perindukan
            </Button>
          )}
        </div>
      </div>
      {/* table component */}
      <TableDataComponent
        columnLength={columns.length}
        tableInstance={tableInstance}
        onPageChange={onPageChange}
        loading={isLoading}
        currentPage={dataState?.currentPage}
        limit={dataState?.limit}
        nextPage={dataState?.nextPage}
        previousPage={dataState?.previousPage}
        total={dataState?.totalData}
      />
    </>
  );
};

export default TableComponent;
