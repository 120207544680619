import React, { Component } from "react";
import { PlusCircle } from "react-feather";
import { TableComponent } from "components";

export default class Table extends Component {
  render() {
    const {
      data,
      changeMode,
      loading,
      onEdit,
      onRemove,
      onPageChange,
      currentUser,
    } = this.props;

    return (
      <div>
        <div className="row">
          <div className="col-sm-8">
            {currentUser && currentUser.mode === "Online" && (
              <button
                className="btn btn-teal font-weight-bold"
                onClick={() => changeMode("add")}
              >
                <PlusCircle className="mr-2" />
                Tambah Data Zero Reporting
              </button>
            )}
          </div>
        </div>

        <TableComponent
          loading={loading}
          data={data?.zero_reports}
          headers={["Tahun", "Bulan", "Laporan Zero Reporting"]}
          cols={["year", "month", "is_zero_reporting"]}
          btnEdit={{ onClick: onEdit }}
          btnRemove={{ onClick: onRemove }}
          currentPage={data?.currentPage}
          total={data?.total}
          limit={data?.limit}
          nextPage={data?.nextPage}
          previousPage={data?.previousPag}
          onPageChange={onPageChange}
        />
      </div>
    );
  }
}
