import React, { useState } from "react";
import "./Analisis.scss";
import FilterDataComponent from "./components/FilterData";
import { useEffect } from "react";

const AnalisisPage = () => {
  useEffect(() => {
    document.title = "SISMAL | Analisis";
  }, []);

  return (
    <div className="dashboard-wrapper">
      <h1 className="page-title">Analisis</h1>
      <p className="page-subtitle">
        {/* Lorem ipsum dolor sit, amet consectetur adipisicing. */}
      </p>
      <FilterDataComponent />
    </div>
  );
};

export default AnalisisPage;
