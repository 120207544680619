import React, { Component, Fragment } from "react";
import { PageMode } from "utils/constant";
import FormComponent from "./components/Form";
import TableComponent from "./components/Table";
import IRSResult from "services/IRSResult";
import { connect } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";

const IRSResultService = new IRSResult();

class IRSResultData extends Component {
  _isMounted = true;

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      limit: 10,
      data: [],
      mode: PageMode.view,
      loading: true,
      search: "",
      selectedItem: null,
      currentPage: 1,
    };
  }

  componentDidMount() {
    window.document.title = "SISMAL | Laporan Hasil Pengendalian Vektor";

    this._isMounted = true;
    this.getIRSResultReports();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  changeMode = (mode) => {
    this.setState({ mode }, () => {
      if (mode === PageMode.view) this.getIRSResultReports();
    });
  };

  onPageChange = (page) => {
    this.setState({ page }, () => {
      this.getIRSResultReports();
    });
  };

  getIRSResultReports = async () => {
    const { page, limit, search } = this.state;
    const { user } = this.props;
    const searchBy = `fasyankes_id${search !== "" ? `,sub_village` : ""}`;
    const searchValue = `${user.fasyankes_id}${
      search !== "" ? `,${search}` : ""
    }`;

    await IRSResultService.getAll({
      page: page,
      limit: limit,
      searchBy,
      searchValue,
    })
      .then((res) => {
        this.setState({
          loading: false,
          data: res,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onEdit = (item) => {
    this.setState(
      {
        selectedItem: item,
      },
      () => {
        this.changeMode(PageMode.edit);
      }
    );
  };

  onRemove = async (id) => {
    confirmAlert({
      title: "Anda yakin ingin Menghapus data?",
      message: "Data akan dihapus permanen",
      buttons: [
        {
          label: "Ya",
          onClick: () => {
            IRSResultService.remove(id)
              .then((res) => {
                this.getIRSResultReports();
                toast.success("Data Berhasil Dihapus!", {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              })
              .catch((err) => {
                console.log(err);
              });
          },
        },
        {
          label: "Tidak",
        },
      ],
    });
  };

  onSearch = (e) => {
    const search = e.target.value;
    this.setState({ search, page: 1 }, () => {
      this.getIRSResultReports();
    });
  };

  render() {
    const { data, mode, loading, page, selectedItem } = this.state;

    return (
      <Fragment>
        {mode === PageMode.view && (
          <TableComponent
            data={data}
            loading={loading}
            onEdit={this.onEdit}
            onRemove={this.onRemove}
            onSearch={this.onSearch}
            changeMode={this.changeMode}
            currenPage={page}
            onPageChange={this.onPageChange}
          />
        )}
        {(mode === PageMode.add || mode === PageMode.edit) && (
          <FormComponent
            mode={mode}
            changeMode={this.changeMode}
            data={selectedItem}
            currentUser={this.props.user}
            parentId={this.props.match.params.id}
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authReducer.currentUser,
  };
};

export default connect(mapStateToProps, null)(IRSResultData);
