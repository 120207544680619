import React, { Component } from "react";
import { PlusCircle } from "react-feather";
import { TableComponent as TableData } from "components";

export default class TableComponent extends Component {
  render() {
    const {
      data,
      changeMode,
      loading,
      onSearch,
      onEdit,
      onRemove,
      onPageChange,
    } = this.props;

    return (
      <div className="dashboard-wrapper">
        <h1 className="title font-weight-bold">
          Data Laporan Hasil Pengendalian Vektor
        </h1>
        <div className="py-3">
          <div className="row">
            <div className="col-sm-8">
              <button
                className="btn btn-teal font-weight-bold"
                onClick={() => changeMode("add")}
              >
                <PlusCircle className="mr-2" />
                Tambah Data Hasil Pengendalian Vektor
              </button>
            </div>
            <div className="col-sm-4">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control bg-light"
                  placeholder="Cari Nama Dusun..."
                  onChange={(e) => onSearch(e)}
                />
              </div>
            </div>
          </div>
        </div>

        <TableData
          loading={loading}
          data={data?.vector_irs_reports}
          headers={[
            "Nama Desa",
            "Nama Dusun",
            "Jumlah Penduduk Di Dusun Tersebut",
            "Jenis Pengendalian Vektor",
            "Tanggal IRS",
            "Jenis Insektisida",
            "Jumlah Insektisida Yang Digunakan",
            "Satuan Insektisida",
            "Jumlah Total Sasaran Rumah",
            "Jumlah Rumah Yang Di Semprot",
            "Tanggal Distribusi Kelambu",
            "Jumlah Target Pembagian Kelambu",
            "Jumlah Kelambu Yang Di Bagikan",
          ]}
          cols={[
            ["urbanvillage_id", "|", 1],
            "sub_village",
            "total_population_sub_village",
            "vector_controll_type",
            "irs_date",
            "insecticide_type",
            "total_insecticide",
            "total_insecticide_unit",
            "total_targeted_house",
            "total_sprayed_house",
            "net_distribution_date",
            "population_net_distribution",
            "total_net_distribution",
          ]}
          btnEdit={{ onClick: onEdit }}
          btnRemove={{ onClick: onRemove }}
          currentPage={data?.current_page}
          total={data?.total}
          limit={data?.limit}
          nextPage={data?.next_page}
          previousPage={data?.previous_page}
          onPageChange={onPageChange}
        />
      </div>
    );
  }
}
