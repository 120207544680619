import { errHandler } from "utils/common";
import api from "utils/api";

export default class Populasi {
  getAll = (opt = { page: 1, limit: 10, search: null }) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/populations`, { params: opt })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  getByProv = (opt = { page: 1, limit: 10, province_id, year }) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/population/prov`, { params: opt })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  getOne = (id) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/population/${id}`)
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  add = (payload) => {
    return new Promise((resolve, reject) => {
      api
        .post("/api/population?device=Online", payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  update = (payload, id) => {
    return new Promise((resolve, reject) => {
      api
        .patch(`/api/population/${id}?device=Online`, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  remove = (id) => {
    return new Promise((resolve, reject) => {
      api
        .delete(`/api/population/${id}?device=Online`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  // Check village id
  checkVillageId = (id) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/population/check/${id}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };
}
