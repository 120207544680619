import { isEmpty } from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { Edit, PlusCircle } from "react-feather";
import { PageMode } from "utils/constant";

export default class Table extends Component {
  dateHandler = (date) => {
    if (!isEmpty(date)) {
      return moment(date).format("DD-MM-YYYY");
    }
    return "";
  };

  render() {
    const { changeMode, dataRegmal, data } = this.props;

    return (
      <div>
        <div className="row">
          <div className="col-sm-12">
            {data ? (
              <button className="btn btn-outline-primary" onClick={() => changeMode(PageMode.edit)}>
                <Edit size={20} className="mr-1" />
                Edit Data
              </button>
            ) : (
              <button
                className="btn btn-teal font-weight-bold"
                onClick={() => changeMode(PageMode.add)}
              >
                <PlusCircle className="mr-2" />
                Tambah Data
              </button>
            )}
          </div>
        </div>

        <div className="mt-5">
          <table className="table table-borderless">
            <tbody>
              <tr>
                <td>NIK</td>
                <td>
                  <b>{dataRegmal.ktp}</b>
                </td>
              </tr>
              <tr>
                <td>Nama</td>
                <td>
                  <b>{dataRegmal.name}</b>
                </td>
              </tr>
              <tr>
                <td>Alamat lengkap dan Nomor telepon pasien</td>
                <td>
                  <b>{dataRegmal.address}</b>
                </td>
              </tr>
              <tr>
                <td>Tanggal Kunjungan</td>
                <td>
                  <b>{dataRegmal.patient_visite_date}</b>
                </td>
              </tr>
              <tr>
                <td>Faskes Pencatat</td>
                <td>
                  <b>
                    {dataRegmal.fasyankes_origin_id
                      ? dataRegmal.fasyankes_origin_id?.split("|")[1]
                      : ""}
                  </b>
                </td>
              </tr>
              <tr>
                <td>Kabupaten Pencatat</td>
                <td>
                  <b>{dataRegmal.district_pencatat}</b>
                </td>
              </tr>
              <tr>
                <td>Tanggal Wawancara</td>
                <td>
                  <b>
                    {data &&
                      data.case_interview_date &&
                      moment(data.case_interview_date).format("YYYY-MM-DD")}
                  </b>
                </td>
              </tr>
              <tr>
                <td>Survei Kontak</td>
                <td>
                  <b>{data && data.contact_survey}</b>
                </td>
              </tr>
              <tr>
                <td>Tanggal Survei Kontak</td>
                <td>
                  <b>
                    {data &&
                      data.contact_survey_date &&
                      moment(data.contact_survey_date).format("YYYY-MM-DD")}
                  </b>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <h5 className="text-uppercase font-weight-bold">
                    Hasil pemeriksaan negatif dari survei kontak
                  </h5>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <h5>Usia {"<"} 5 Tahun</h5>
                </td>
              </tr>
              <tr>
                <td>Jumlah Pemeriksaan Mikroskopis (Negatif)</td>
                <td>
                  <b>{data && data.total_microscopic_inspection_under_five_year}</b>
                </td>
              </tr>
              <tr>
                <td>Jumlah Pemeriksaan RDT (Negatif)</td>
                <td>
                  <b>{data && data.total_rdt_inspection_under_five_year}</b>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <h5>Usia {">="} 5 Tahun </h5>
                </td>
              </tr>
              <tr>
                <td>Jumlah Pemeriksaan Mikroskopis (Negatif)</td>
                <td>
                  <b>{data && data.total_microscopic_inspection_over_five_year}</b>
                </td>
              </tr>
              <tr>
                <td>Jumlah Pemeriksaan RDT (Negatif)</td>
                <td>
                  <b>{data && data.total_rdt_inspection_over_five_year}</b>
                </td>
              </tr>
              <tr>
                <td>Identifikasi daerah perindukan nyamuk</td>
                <td>
                  <b>{data && data.identify_mosquito_breeding_area}</b>
                </td>
              </tr>
              <tr>
                <td>Tanggal Identifikasi Daerah Perindukan Nyamuk</td>
                <td>
                  <b>
                    {data &&
                      data.identify_mosquito_breeding_area_date &&
                      moment(data.identify_mosquito_breeding_area_date).format("YYYY-MM-DD")}
                  </b>
                </td>
              </tr>
              <tr>
                <td>Kekambuhan</td>
                <th>{data && data.recurrence}</th>
              </tr>
              <tr>
                <td>Tanggal Kasus Awal</td>
                <td>
                  <b>
                    {data &&
                      data.initial_case_date &&
                      moment(data.initial_case_date).format("YYYY-MM-DD")}
                  </b>
                </td>
              </tr>
              <tr>
                <td>Penularan</td>
                <th>{data && data.transmission}</th>
              </tr>
              <tr>
                <td>Asal Penularan</td>
                <th>{data && data.transmission_origin}</th>
              </tr>
              <tr>
                <td>Nama Provinsi Asal Penularan</td>
                <th>{data && data.province_id && data.province_id?.split("|")[1]}</th>
              </tr>
              <tr>
                <td>Nama Kab/Kota Asal Penularan</td>
                <th>{data && data.district_id && data.district_id?.split("|")[1]}</th>
              </tr>
              <tr>
                <td>Nama Kecamatan Asal Penularan</td>
                <th>{data && data.subdistrict_id && data.subdistrict_id?.split("|")[1]}</th>
              </tr>
              <tr>
                <td>Nama Puskesmas Asal Penularan</td>
                <th>{data && data.fasyankes_id && data.fasyankes_id?.split("|")[1]}</th>
              </tr>
              <tr>
                <td>Nama Desa Asal Penularan</td>
                <th>{data && data.urbanvillage_id && data.urbanvillage_id?.split("|")[1]}</th>
              </tr>
              <tr>
                <td>Nama Dusun Asal Penularan</td>
                <th>{data && data.sub_village}</th>
              </tr>
              <tr>
                <td>Alamat Lengkap Asal Penularan</td>
                <th>{data && data.address_of_infection}</th>
              </tr>
              <tr>
                <td>Nomor Telepon Pasien</td>
                <th>{data && data.phone_number}</th>
              </tr>
              <tr>
                <td>Kasus Pada MMP</td>
                <th>{data && data.mmp_case}</th>
              </tr>
              <tr>
                <td>ID MMP</td>
                <th>{data && data.mmp_id}</th>
              </tr>
              <tr>
                <td>Negara Asal Penularan</td>
                <th>{data && data.country_origin}</th>
              </tr>
              <tr>
                <td>Alamat lengkap di negara asal</td>
                <th>{data && data.address}</th>
              </tr>
              <tr>
                <td>Klasifikasi Kasus</td>
                <th>{data && data.case_classification}</th>
              </tr>
              <tr>
                <td>Jika Indigenous</td>
                <th>{data && data.indigenous}</th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
