import React, { Component } from "react";
import { PageMode } from "utils/constant";
import Table from "./components/Table";
import Form from "./components/Form";
import { connect } from "react-redux";

class UserVerifiacation extends Component {
  _isMounted = true;

  constructor(props) {
    super(props);
    this.state = {
      mode: PageMode.view,
      loading: true,
      selectedItem: null,
      level: "Fasyankes",
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  changeMode = (mode, level) => {
    this.setState({ mode, level });
  };

  onEdit = (data) => {
    this.setState({ selectedItem: data }, () => {
      this.changeMode(PageMode.edit);
    });
  };

  tableView = () => {
    const { user } = this.props;

    return (
      <Table onEdit={this.onEdit} changeMode={this.changeMode} user={user} />
    );
  };

  render() {
    const { mode, selectedItem, level } = this.state;
    return (
      <div className="dashboard-wrapper">
        <div className="title font-weight-bold">Verifikasi User</div>
        <div className="content-wrapper mt-4">
          {mode === PageMode.view && this.tableView()}
          {(mode === PageMode.add || mode === PageMode.edit) && (
            <Form
              mode={mode}
              changeMode={this.changeMode}
              data={selectedItem}
              user={this.props.user}
              level={level}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authReducer.currentUser,
  };
};

export default connect(mapStateToProps)(UserVerifiacation);
