import React from "react";
import styles from "./InputCheckbox.module.scss";
import propTypes from "prop-types";

const InputCheckbox = ({
  label,
  name,
  options,
  selectedOption,
  onChange,
  onBlur,
  error,
  errorMessage,
  isRequired,
  disabled,
  isItalic,
}) => {
  const inputClasses = [styles.input];
  if (error) {
    inputClasses.push(styles.error);
  }

  const isPMixSelected = selectedOption?.includes("P.Mix");

  return (
    <div className={styles.formContainer}>
      {label && (
        <label htmlFor={label} style={{ marginBottom: "8px" }}>
          {label}{" "}
          {isRequired ? <sup style={{ color: "#D91919" }}>*</sup> : null}{" "}
        </label>
      )}
      <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        {options?.map((option) => (
          <label
            key={option.value}
            style={{
              display: "flex",
              alignItems: "center",
              fontWeight: "400",
              fontStyle: isItalic ? "italic" : "normal",
            }}
          >
            <input
              type="checkbox"
              name={name}
              value={option.value}
              checked={selectedOption?.includes(option.value)}
              onChange={onChange}
              onBlur={onBlur}
              disabled={
                disabled || (isPMixSelected && option.value !== "P.Mix")
              }
            />
            {option.label}
          </label>
        ))}
      </div>
      {error && <span className={styles.invalidInput}>{errorMessage}</span>}
    </div>
  );
};

export default InputCheckbox;

InputCheckbox.propTypes = {
  label: propTypes.string,
  name: propTypes.string.isRequired,
  options: propTypes.arrayOf(
    propTypes.shape({
      label: propTypes.string.isRequired,
      value: propTypes.string.isRequired,
    })
  ).isRequired,
  onChange: propTypes.func.isRequired,
  error: propTypes.bool,
  errorMessage: propTypes.string,
};
