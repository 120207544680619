import React from "react";
import { useState } from "react";
import { PageMode } from "utils/constant";
import TableComponent from "./components/TableComponent";
import DetailComponent from "./components/DetailComponent";
import "./index.scss";

const NotifikasiKasusImpor = () => {
  const [pageMode, setPageMode] = useState(PageMode.view);

  const changePageMode = (mode) => {
    setPageMode(mode);
  };

  return (
    <div className="dashboard-wrapper">
      {pageMode === PageMode.view && (
        <TableComponent changeMode={changePageMode} />
      )}
      {pageMode === PageMode.detail && (
        <DetailComponent changeMode={changePageMode} />
      )}
    </div>
  );
};

export default NotifikasiKasusImpor;
