import React from "react";
import styles from "./ActionButton.module.scss";
import {
  FiCheckCircle,
  FiEdit3,
  FiEye,
  FiPlusCircle,
  FiTrash,
  FiXCircle,
} from "react-icons/fi";

const ActionButton = ({ btnType, onClick }) => {
  switch (btnType) {
    case "edit":
      return (
        <button onClick={onClick} className={styles.btnEdit}>
          <FiEdit3 size={16} />
          <span>Edit</span>
        </button>
      );
    case "kinerja":
      return (
        <button onClick={onClick} className={styles.btnKinerja}>
          <FiPlusCircle size={16} />
          <span>Tambah Kinerja</span>
        </button>
      );
    case "delete":
      return (
        <button onClick={onClick} className={styles.btnDelete}>
          <FiTrash size={16} />
          <span>Hapus</span>
        </button>
      );
    case "approve":
      return (
        <button onClick={onClick} className={styles.btnKinerja}>
          <FiCheckCircle size={16} />
          <span>Terima</span>
        </button>
      );
    case "reject":
      return (
        <button onClick={onClick} className={styles.btnDelete}>
          <FiXCircle size={16} />
          <span>Tolak</span>
        </button>
      );
    case "detail":
      return (
        <button onClick={onClick} className={styles.btnDetail}>
          <FiEye size={16} />
          <span>Lihat</span>
        </button>
      );
    case "intervensi":
      return (
        <button onClick={onClick} className={styles.btnKinerja}>
          <FiPlusCircle size={16} />
          <span>Intervensi Perindukan Nyamuk</span>
        </button>
      );
    default:
      return null;
  }
};

export default ActionButton;
