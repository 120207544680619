import { errHandler } from "../utils/common";
import api from "../utils/api";

export default class RegmalFirst {
  regmal = (payload) => {
    return new Promise((resolve, reject) => {
      api
        .post("/api/upload_sync/regmal", payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };
  secondRegmal = (payload) => {
    return new Promise((resolve, reject) => {
      api
        .post("/api/upload_sync/second_regmal", payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };
  population = (payload) => {
    return new Promise((resolve, reject) => {
      api
        .post("/api/upload_sync/population", payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };
  zeroReport = (payload) => {
    return new Promise((resolve, reject) => {
      api
        .post("/api/upload_sync/zero_report", payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };
  peCase = (payload) => {
    return new Promise((resolve, reject) => {
      api
        .post("/api/upload_sync/pe_case", payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };
  cadreEndemis = (payload) => {
    return new Promise((resolve, reject) => {
      api
        .post("/api/upload_sync/cadre_endemis", payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };
  mmp = (payload) => {
    return new Promise((resolve, reject) => {
      api
        .post("/api/upload_sync/cadre_mmp", payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };
  logistictInputStoct = (payload) => {
    return new Promise((resolve, reject) => {
      api
        .post("/api/upload_sync/logistict_input_stock", payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };
  logistictExpiredStock = (payload) => {
    return new Promise((resolve, reject) => {
      api
        .post("/api/upload_sync/logistict_expired_stock", payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };
  logistictOffStock = (payload) => {
    return new Promise((resolve, reject) => {
      api
        .post("/api/upload_sync/logistict_off_stock", payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };
  regmalReference = (payload) => {
    return new Promise((resolve, reject) => {
      api
        .post("/api/upload_sync/regmal_reference", payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };
  kiaScreening = (payload) => {
    return new Promise((resolve, reject) => {
      api
        .post("/api/upload_sync/kia_screening", payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };
  getHistorySync = () => {
    return new Promise((resolve, reject) => {
      api
        .get("/api/upload-sync-queues")
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };
}
