export const tableColumns = {
  'DHP Dispersible Rusak': 'DHP Dispersible Rusak',
  'DHP Dispersible Expired': 'DHP Dispersible Expired',
  'DHP (Tablet) Rusak': 'DHP (Tablet) Rusak',
  'DHP (Tablet) Expired': 'DHP (Tablet) Expired',
  'Primaquin (Tablet) Rusak': 'Primaquin (Tablet) Rusak',
  'Primaquin (Tablet) Expired': 'Primaquin (Tablet) Expired',
  'Artesunat Inj (Vial) Rusak': 'Artesunat Inj (Vial) Rusak',
  'Artesunat Inj (Vial) Expired': 'Artesunat Inj (Vial) Expired',
  'Kina (Tablet) Rusak': 'Kina (Tablet) Rusak',
  'Kina (Tablet) Expired': 'Kina (Tablet) Expired',
  'Kina Inj (Ampul) Rusak': 'Kina Inj (Ampul) Rusak',
  'Kina Inj (Ampul) Expired': 'Kina Inj (Ampul) Expired',
  'Doksisiklin (Tablet) Rusak': 'Doksisiklin (Tablet) Rusak',
  'Doksisiklin (Tablet) Expired': 'Doksisiklin (Tablet) Expired',
  'RDT (Test) Rusak': 'RDT (Test) Rusak',
  'RDT (Test) Expired': 'RDT (Test) Expired',
  'LLIN (Lembar) Rusak': 'LLIN (Lembar) Rusak',
  'LLIN (Lembar) Expired': 'LLIN (Lembar) Expired',
  'Insektisida (Kg) Rusak': 'Insektisida (Kg) Rusak',
  'Insektisida (Kg) Expired': 'Insektisida (Kg) Expired',
  'Insektisida (Liter) Rusak': 'Insektisida (Liter) Rusak',
  'Insektisida (Liter) Expired': 'Insektisida (Liter) Expired',
  'Larvasida (Kg) Rusak': 'Larvasida (Kg) Rusak',
  'Larvasida (Kg) Expired': 'Larvasida (Kg) Expired',
  'Larvasida (Liter) Rusak': 'Larvasida (Liter) Rusak',
  'Larvasida (Liter) Expired': 'Larvasida (Liter) Expired',
  'Lab Kit (Paket) Rusak': 'Lab Kit (Paket) Rusak',
  'Lab Kit (Paket) Expired': 'Lab Kit (Paket) Expired',
  'Giemsa (Botol) Rusak': 'Giemsa (Botol) Rusak',
  'Giemsa (Botol) Expired': 'Giemsa (Botol) Expired',
  'Giemsa (CC) Rusak': 'Giemsa (CC) Rusak',
  'Giemsa (CC) Expired': 'Giemsa (CC) Expired',
  'Minyak Imersi (Botol) Rusak': 'Minyak Imersi (Botol) Rusak',
  'Minyak Imersi (Botol) Expired': 'Minyak Imersi (Botol) Expired',
  'Minyak Imersi (CC) Rusak': 'Minyak Imersi (CC) Rusak',
  'Minyak Imersi (CC) Expired': 'Minyak Imersi (CC) Expired',
  'Blood Slide (Box) Rusak': 'Blood Slide (Box) Rusak',
  'Blood Slide (Box) Expired': 'Blood Slide (Box) Expired',
  'Slide Box (Buah) Rusak': 'Slide Box (Buah) Rusak',
  'Slide Box (Buah) Expired': 'Slide Box (Buah) Expired',
  'Blood Lancet (Box) Rusak': 'Blood Lancet (Box) Rusak',
  'Blood Lancet (Box) Expired': 'Blood Lancet (Box) Expired',
  'Methanol (CC) Rusak': 'Methanol (CC) Rusak',
  'Methanol (CC) Expired': 'Methanol (CC) Expired',
  'Methanol (Botol) Rusak': 'Methanol (Botol) Rusak',
  'Methanol (Botol) Expired': 'Methanol (Botol) Expired',
  'Spray Can (Buah) Rusak': 'Spray Can (Buah) Rusak',
  'Spray Can (Buah) Expired': 'Spray Can (Buah) Expired',
  'Mist Blower (Buah) Rusak': 'Mist Blower (Buah) Rusak',
  'Mist Blower (Buah) Expired': 'Mist Blower (Buah) Expired',
  'Microscope Binocular (Buah) Rusak': 'Microscope Binocular (Buah) Rusak',
  'Microscope Binocular (Buah) Expired': 'Microscope Binocular (Buah) Expired',
  'Microscope Trinocular (Buah) Rusak': 'Microscope Trinocular (Buah) Rusak',
  'Microscope Trinocular (Buah) Expired': 'Microscope Trinocular (Buah) Expired',
  'Microscope Teaching (Buah) Rusak': 'Microscope Teaching (Buah) Rusak',
  'Microscope Teaching (Buah) Expired': 'Microscope Teaching (Buah) Expired',
};

export const tableColumnByLevel = (level) => {
  const tableColumnsByLevel = {
    "fasyankes": {
      "Area": "name",
      ...tableColumns
    },
    "district": {
      "Area": "name",
      // "Level":"level",
      ...tableColumns
    },
    "province": {
      "Provinsi": "parent_name",
      "Area": "name",
      ...tableColumns
    },
    "national": {
      "Provinsi": "parent_name",
      "Area": "name",
      ...tableColumns
    }
  };
  return tableColumnsByLevel[level];
};
