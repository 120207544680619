import { errHandler, isEmpty } from "utils/common";
import api from "utils/api";

export default class FlickIdentification {
  getAll = (opt = { page: 1, limit: 10, searchBy: "", searchValue: "" }) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/vector_larva_identifications`, { params: opt })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  getOne = (id) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/vector_larva_identification/${id}`)
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  add = (payload) => {
    return new Promise((resolve, reject) => {
      const data = new FormData();
      for (const [key, val] of Object.entries(payload)) {
        if (!isEmpty(val)) {
          if (key === "larva_phase") {
            if (payload[key].length > 0) {
              payload[key].forEach((e) => {
                if (e) data.append(key, e);
              });
            }
          } else {
            data.append(key, val);
          }
        }
      }

      api
        .post(`/api/vector_larva_identification?device=Online`, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  update = (payload, id) => {
    return new Promise((resolve, reject) => {
      const data = new FormData();
      for (const [key, val] of Object.entries(payload)) {
        if (!isEmpty(val)) {
          if (key === "larva_phase") {
            payload[key].forEach((e) => {
              if (e) data.append(key, e);
            });
          } else {
            data.append(key, val);
          }
        }
      }
      api
        .patch(`/api/vector_larva_identification/${id}?device=Online`, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  remove = (id) => {
    return new Promise((resolve, reject) => {
      api
        .delete(`/api/vector_larva_identification/${id}?device=Online`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  getImage = (id) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/vector_larva_identification/image/${id}`, {
          responseType: "blob",
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };
}
