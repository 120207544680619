import * as XLSX from "xlsx";

export const exportToExcel = (
  tableRef,
  filename = "untitled.xlsx",
  sheetName = "Sheet 1"
) => {
  const table = tableRef.current;
  const wb = XLSX.utils.table_to_book(table, { sheet: sheetName });
  XLSX.writeFile(wb, filename);
};
