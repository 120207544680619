import React, { Component, Fragment } from "react";
import Kader from "services/Kader";
import KaderPerformance from "services/EndemisCadrePerformance";
import { ErrorMessage, PageMode } from "utils/constant";
import TableComponent from "./components/Table";
import FormComponent from "./components/Form";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";

const KaderService = new Kader();
const KaderPerformanceService = new KaderPerformance();

export default class CadrePerformance extends Component {
  _isMounted = true;
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      limit: 10,
      cadreData: {},
      cadrePerformances: [],
      loading: true,
      mode: PageMode.view,
      selectedItem: null,
      currentPage: 1,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.getCadreDataById();
    this.getCadrePerformancesByCadre();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  changeMode = (mode) => {
    this.setState({ mode }, () => {
      if (mode === PageMode.view) {
        this.getCadreDataById();
        this.getCadrePerformancesByCadre();
      }
    });
  };

  onPageChange = (page) => {
    this.setState({ page }, () => {
      this.getCadrePerformancesByCadre();
    });
  };

  getCadreDataById = async () => {
    const cadreId = this.props.match.params.id;

    await KaderService.getOne(cadreId)
      .then((res) => {
        this.setState({
          loading: false,
          cadreData: res,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getCadrePerformancesByCadre = async () => {
    const endemisCadreId = this.props.match.params.id;

    await KaderPerformanceService.getAll({
      searchBy: "cadre_id",
      searchValue: endemisCadreId,
    })
      .then((res) => {
        this.setState({
          cadrePerformances: res,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onEdit = (item) => {
    this.setState({ selectedItem: item }, () => {
      this.changeMode(PageMode.edit);
    });
  };

  onRemove = (id) => {
    confirmAlert({
      title: "Anda yakin ingin menghapus data ini?",
      message: "Are you sure want to delete this data?",
      buttons: [
        {
          label: "Ya",
          onClick: () =>
            KaderPerformanceService.remove(id)
              .then((data) => {
                if (this._isMounted) {
                  this.getCadreDataById();
                  this.getCadrePerformancesByCadre();
                }
                toast.success("Data berhasil dihapus", {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              })
              .catch((err) => {
                const errMsg = err.response.data.message ?? "";
                toast.error(
                  <>
                    Data gagal dihapus! <br />
                    Error: {errMsg}
                  </>,
                  {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  }
                );
                console.log(err);

                if (err.resonse.data.message === ErrorMessage.UserNotVerified) {
                  toast.error("Akun anda belum diverifikasi", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                }

                if (
                  err.response.data.message ===
                  ErrorMessage.UserFaskesNotHaveAccess
                ) {
                  toast.error("Akun anda tidak memiliki akses", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                }

              }),
        },
        {
          label: "Tidak",
        },
      ],
    });
  };

  render() {
    const { cadreData, cadrePerformances, mode, selectedItem } = this.state;
    return (
      <Fragment>
        {mode === PageMode.view && (
          <TableComponent
            cadreData={cadreData}
            data={cadrePerformances}
            onEdit={this.onEdit}
            onRemove={this.onRemove}
            changeMode={this.changeMode}
            loading={this.state.loading}
            onPageChange={this.onPageChange}
          />
        )}
        {(mode === PageMode.add || mode === PageMode.edit) && (
          <FormComponent
            mode={mode}
            cadreId={cadreData.id}
            changeMode={this.changeMode}
            data={selectedItem}
          />
        )}
      </Fragment>
    );
  }
}
