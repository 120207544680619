import React, { useEffect } from "react";
import "./index.scss";
import { useState } from "react";
import { PageMode } from "utils/constant";
import DataFaskesAsal from "./DataFaskesAsal";
import DataFaskesRujukan from "./DataFaskesRujukan";

const RegisterMalaria1Rujukan = () => {
  const MENU_NAME = {
    FaskesAsal: "FASKES_ASAL",
    FaskesRujukan: "FASKES_RUJUKAN",
  };

  const [activeMenu, setActiveMenu] = useState(MENU_NAME.FaskesAsal);
  const [pageMode, setPageMode] = useState(PageMode.view);

  const handleSetMenuActiveClick = (menu) => {
    setActiveMenu(menu);
  };

  const handlePageModeChange = (page) => {
    setPageMode(page);
  };

  useEffect(() => {
    document.title = "SISMAL | Register Malaria 1 Rujukan";
  }, []);

  return (
    <div className="dashboard-wrapper">
      {pageMode === PageMode.view && (
        <>
          <h1 className="page-title">Regmal 1 - Rujukan</h1>
          <div className="navigation-module" style={{ marginTop: "24px" }}>
            <button
              onClick={() => handleSetMenuActiveClick(MENU_NAME.FaskesAsal)}
              className={activeMenu === MENU_NAME.FaskesAsal ? "active" : ""}
            >
              Data Faskes Asal
            </button>
            <button
              onClick={() => handleSetMenuActiveClick(MENU_NAME.FaskesRujukan)}
              className={activeMenu === MENU_NAME.FaskesRujukan ? "active" : ""}
            >
              Data Faskes Rujukan
            </button>
          </div>
        </>
      )}

      {/* Main content here */}
      <div className="main-content">
        {activeMenu === MENU_NAME.FaskesAsal && (
          <DataFaskesAsal
            pageMode={pageMode}
            changeMode={handlePageModeChange}
          />
        )}
        {activeMenu === MENU_NAME.FaskesRujukan && (
          <DataFaskesRujukan
            pageMode={pageMode}
            changeMode={handlePageModeChange}
          />
        )}
      </div>
    </div>
  );
};

export default RegisterMalaria1Rujukan;
