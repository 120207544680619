import { errHandler } from "../utils/common";
import api from "../utils/api";

export default class GroupMmp {
  getAll = (
    opt = { page: 1, limit: 10, searchBy: null, searchValue: null }
  ) => {
    return new Promise((resolve, reject) => {
      api
        .get("/api/mmps/id", { params: opt })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  incrementMmpId = () => {
    return new Promise((resolve, reject) => {
      api
        .get("/api/mmp/generate-mmp-id")
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  add = (payload) => {
    return new Promise((resolve, reject) => {
      api
        .post("/api/mmp?device=Online", payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  update = (payload, id) => {
    return new Promise((resolve, reject) => {
      api
        .patch(`/api/mmp/id/${id}?device=Online`, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  remove = (id) => {
    return new Promise((resolve, reject) => {
      api
        .delete(`/api/mmp/${id}?device=Online`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };
}
