import React from "react";
import authLogo from "assets/logos/auth_logo.png";
import maintenanceImg from "assets/maintenance.png";
import { Link } from "react-router-dom";
import { Fade } from "react-reveal";
import moment from "moment";

export default function Maintenance() {
  return (
    <Fade>
      <div className="login">
        <div className="wrapper wrapper-login wrapper-login-full p-0">
          <div className="login-aside w-40 d-flex flex-column align-items-center justify-content-center text-center bg-secondary-gradient">
            <img src={authLogo} alt="Logo SISMAL" style={{ width: "50%" }} />
          </div>
          <div className="login-aside w-50 d-flex align-items-center justify-content-center bg-white">
            <div className="container container-login p-0 container-transparent animated fadeIn text-center">
              <img
                src={maintenanceImg}
                alt="Maintenance Image"
                className="img-fluid mb-5"
              />
              <h2
                className="text-center text-uppercase font-weight-bold"
                style={{ color: "#18b3ab" }}
              >
                SISMAL V3 SEDANG DALAM PEMELIHARAAN
              </h2>
              <p className="mx-auto" style={{ maxWidth: "320px" }}>
                Pemeliharaan akan berlangsung hingga Minggu 21 April pukul 23:59
                WIB. Mohon maaf atas ketidak nyamanannya
              </p>
              <div className="copyright" style={{ marginTop: "100px" }}>
                <p className="small">
                  Copyright © {moment().format("YYYY")} SISMAL v3 | Kementerian
                  Kesehatan Republik Indonesia.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fade>
  );
}
