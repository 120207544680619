import {
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import ActionButton from "components/atoms/ActionButton";
import InputField from "components/atoms/InputField";
import TableDataComponent from "components/organisms/TableData";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { PageMode } from "utils/constant";
import {
  removeDataFromLocalStorage,
  setDataToLocalStorage,
} from "utils/common";
import PEFeedback from "services/PEFeedback";
import InputRadio from "components/atoms/InputRadio";

const PEFeedbackService = new PEFeedback();

const TableComponent = ({ changeMode }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [dataState, setDataState] = useState({
    page: 1,
    limit: 10,
    data: [],
    totalData: 0,
    currentPage: 1,
    nextPage: null,
    previousPage: null,
  });

  const { currentUser } = useSelector((state) => state.authReducer);
  const [searchKeyword, setSearchKeyword] = useState();
  const [filter, setFilter] = useState({
    searchBy: ["reg_fasyankes_id"],
    searchValue: [currentUser.fasyankes_id],
  });
  const [rb, setRb] = useState("");

  const fetchPEFeedbackData = async () => {
    setIsLoading(true);
    try {
      const response = await PEFeedbackService.getAll({
        search: searchKeyword,
        searchBy: filter.searchBy?.join(","),
        searchValue: filter.searchValue?.join(","),
      });

      setDataState((prevState) => ({
        // ...prevState,
        data: response.pe_case_feedback_case,
        limit: response.limit,
        currentPage: response.currentPage,
        totalData: response.total,
        nextPage: response.nextPage,
        previousPage: response.previousPage,
      }));

      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    document.title = "SISMAL | Notifikasi PE Feedback";
    removeDataFromLocalStorage("SELECTED_ITEM");
  }, []);

  useEffect(() => {
    fetchPEFeedbackData();
  }, [
    dataState.page,
    searchKeyword,
    filter,
    filter.searchValue,
    filter.searchBy,
  ]);

  const onPageChange = (page) => {
    setDataState((prevState) => ({
      ...prevState,
      page: page,
    }));
  };

  const onDetailData = (data) => {
    setDataToLocalStorage("SELECTED_ITEM", JSON.stringify(data));
    changeMode(PageMode.detail);
  };

  const columns = [
    {
      header: "Aksi",
      accessorKey: "action",
      cell: ({ row }) => (
        <div className="button-actions">
          <ActionButton
            onClick={() => onDetailData(row.original)}
            btnType={"detail"}
          />
        </div>
      ),
    },
    {
      header: "Nama",
      accessorKey: "name",
    },
    {
      header: "Usia",
      accessorKey: "age",
      cell: ({ row }) => `${row.original.age} ${row.original.age_type}`,
    },
    {
      header: "Pekerjaan",
      accessorKey: "job",
    },
    {
      header: "Jenis Parasit",
      accessorKey: "parasyte",
      cell: ({ row }) =>
        row.original.parasyte ? row.original.parasyte?.join(", ") : "",
    },
    {
      header: "Status",
      accessorKey: "pe_status",
      cell: ({ row }) =>
        row.original.pe_status ? (
          <span className="badge badge-success">Sudah di PE</span>
        ) : (
          <span className="badge badge-danger">Belum di PE</span>
        ),
    },
    {
      header: "Faskes Pencatat",
      accessorKey: "faskes_pencatat_regmal",
      cell: ({ row }) =>
        row.original.faskes_pencatat_regmal
          ? row.original.faskes_pencatat_regmal
          : "-",
    },
    {
      header: "Kab Pencatat",
      accessorKey: "district_pencatat_regmal",
      cell: ({ row }) =>
        row.original.district_pencatat_regmal
          ? row.original.district_pencatat_regmal
          : "-",
    },
    {
      header: "Provinsi Pencatat",
      accessorKey: "province_pencatat_regmal",
      cell: ({ row }) =>
        row.original.province_pencatat_regmal
          ? row.original.province_pencatat_regmal
          : "-",
    },
    {
      header: "Faskes Domisili",
      accessorKey: "fasyankes_dom",
      cell: ({ row }) =>
        row.original.fasyankes_dom
          ? row.original.fasyankes_dom?.split("|")[1]
          : "-",
    },
    {
      header: "Faskes Yang Melakukan PE",
      accessorKey: "fasyankes_dom",
      cell: ({ row }) =>
        row.original.pe_status ? row.original.fasyankes_dom?.split("|")[1] : "-",
    },
    {
      header: "Kab Yang Melakukan PE",
      accessorKey: "district_dom",
      cell: ({ row }) =>
        row.original.pe_status ? row.original.district_dom?.split("|")[1] : "-",
    },
    {
      header: "Provinsi Yang Melakukan PE",
      accessorKey: "province_dom",
      cell: ({ row }) =>
        row.original.pe_status ? row.original.province_dom?.split("|")[1] : "-",
    },
    {
      header: "Kegiatan Penemuan Kasus",
      accessorKey: "discovery_activities",
    },
    {
      header: "Kekambuhan",
      accessorKey: "recurrence",
    },
    {
      header: "Tanggal Kasus Awal",
      accessorKey: "initial_case_date",
    },
    {
      header: "Klasifikasi Kasus",
      accessorKey: "case_classification",
    },
  ];

  const tableInstance = useReactTable({
    columns,
    data: dataState?.data,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h1 className="page-title">Notifikasi PE Feedback</h1>
      </div>

      <div className="filter-container">
        <div className="filter-content">
          <p className="filter-label">Status</p>
          <InputRadio
            name="filter"
            onChange={(e) => {
              const selectedValue = e.target.value;
              setRb(selectedValue);
              switch (selectedValue) {
                case "true":
                  setFilter({
                    searchBy: ["reg_fasyankes_id", "pe_status"],
                    searchValue: [currentUser.fasyankes_id, selectedValue],
                  });
                  break;
                case "false":
                  setFilter({
                    searchBy: ["reg_fasyankes_id", "pe_status"],
                    searchValue: [currentUser.fasyankes_id, selectedValue],
                  });
                  break;
                default:
                  setFilter({
                    searchBy: ["reg_fasyankes_id"],
                    searchValue: [currentUser.fasyankes_id],
                  });
                  break;
              }
            }}
            selectedOption={rb}
            value={[{ label: "Semua Status", value: null }]}
            options={[
              { label: "Belum di PE", value: "false" },
              { label: "Sudah di PE", value: "true" },
              { label: "Semua Status", value: "" },
            ]}
          />
        </div>
        <div className="filter-action">
          {/* <Button variant={"primary"} size={"normal"} onClick={() => {}}>
            Terapkan
          </Button> */}
          <div className="search" style={{ width: "25rem" }}>
            <InputField
              placeholder={"Cari nama/faskes disini..."}
              onChange={(e) =>
                setSearchKeyword(
                  e.target.value && e.target.value.length >= 3
                    ? e.target.value
                    : ""
                )
              }
            />
          </div>
        </div>
      </div>

      {/* table component here */}
      <TableDataComponent
        columnLength={columns.length}
        tableInstance={tableInstance}
        onPageChange={onPageChange}
        loading={isLoading}
        currentPage={dataState.currentPage}
        limit={dataState.limit}
        nextPage={dataState.nextPage}
        previousPage={dataState.previousPage}
        total={dataState.totalData}
      />
    </>
  );
};

export default TableComponent;
