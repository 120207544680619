/* eslint-disable max-len */
import React, { useEffect, useState } from "react";
import "./FilterData.scss";
import { ArrowDown, ArrowUp } from "react-feather";
import InputSelect from "components/atoms/InputSelect";
import InputRadio from "components/atoms/InputRadio";
import Button from "components/atoms/Button";
import InputDate from "components/atoms/InputDate";
import moment from "moment";
import Wilayah from "services/Wilayah";
import Analysis from "services/Analysis";
import Fasyankes from "services/Fasyankes";
import { Source, UserLevel } from "utils/constant";
import { getCurrentUserFromLocalStorage } from "utils/common";
import { HashLoader } from "react-spinners";
import MyMap from "./Map";
import { toast } from "react-toastify";

const WilayahService = new Wilayah();
const FasyankesService = new Fasyankes();
const AnalysisService = new Analysis();

const indicatorPetaOpt = [
  {
    label: "Peta Endemisitas",
    value: "indikator_26",
  },
  {
    label: "Peta Fokus",
    value: "indikator_28",
  },
  {
    label: "Peta Reseptifitas",
    value: "indikator_29",
  },
];

const initialPeriodDate = {
  year: null,
};

const initialDate = {
  year: "",
};

const initialDomicile = {
  provincies: [],
  districts: [],
  fasyankes: [],
};

const FilterDataComponent = () => {
  const currentUser = getCurrentUserFromLocalStorage();

  const initialDomicileSelected = {
    provincies: currentUser?.province_id,
    districts: currentUser?.district_id,
    fasyankes: currentUser?.fasyankes_id,
  };

  const [chartData, setChartData] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const [showFilter, setShowFilter] = useState(true);
  const [showChart, setShowChart] = useState(false);
  const [dateString, setDateString] = useState("");
  const [date, setDate] = useState(initialDate);
  const [domicile, setDomicile] = useState(initialDomicile);
  const [domicileSelected, setDomicileSelected] = useState(
    initialDomicileSelected
  );
  const [selectPeriode, setSelectPeriode] = useState("");
  const [selectLevel, setSelectedLevel] = useState(currentUser.level);
  const [levelData, setLevelData] = useState([]);
  const [selectedIndikator, setSelectedIndikator] = useState("");

  const [periodDate, setPeriodDate] = useState(initialPeriodDate);
  const [currentDomicileSelected, setCurrentDomicileSelected] = useState({});
  const [currentDomicile, setCurrentDomicile] = useState("");

  // total denum proposi plasmodium
  const [totalData, setTotalData] = useState(0);

  const renderPeriodeFilter = () => {
    return (
      <InputDate
        placeholder={"Pilih Tahun"}
        dateFormat={"YYYY"}
        onChange={(e) => {
          setDate({ ...date, year: moment(e).format("YYYY") });
          setPeriodDate({ year: e });
        }}
        value={periodDate.year}
      />
    );
  };

  const renderLevelFilter = () => {
    if (selectLevel === UserLevel.Province) {
      return (
        <div style={{ display: "flex", flexDirection: "row", gap: "1.2rem" }}>
          <div style={{ flex: "1" }}>
            <InputSelect
              placeholder={"Pilih Provinsi"}
              data={domicile.provincies}
              onChange={(e) => {
                const value = e ? e.value : null;
                setDomicileSelected({
                  ...domicileSelected,
                  provincies: value,
                  districts: null,
                  fasyankes: null,
                });
              }}
              value={
                domicileSelected.provincies
                  ? domicile.provincies.find(
                    (f) => f.value === domicileSelected.provincies
                  )
                  : domicileSelected.provincies
              }
              isDisabled={
                currentUser.level === UserLevel.National ? false : true
              }
            />
          </div>
        </div>
      );
    } else if (selectLevel === UserLevel.District) {
      return (
        <div style={{ display: "flex", flexDirection: "row", gap: "1.2rem" }}>
          <div style={{ flex: "1" }}>
            <InputSelect
              placeholder={"Pilih Provinsi"}
              data={domicile.provincies}
              onChange={(e) => {
                const value = e ? e.value : null;
                setDomicileSelected({
                  ...domicileSelected,
                  provincies: value,
                  districts: null,
                  fasyankes: null,
                });
              }}
              value={
                domicileSelected.provincies
                  ? domicile.provincies.find(
                    (f) => f.value === domicileSelected.provincies
                  )
                  : domicileSelected.provincies
              }
              isDisabled={
                currentUser.level === UserLevel.National ? false : true
              }
            />
          </div>
          <div style={{ flex: "1" }}>
            <InputSelect
              placeholder={"Pilih Kab/Kota"}
              data={domicile.districts}
              onChange={(e) => {
                const value = e ? e.value : null;
                setDomicileSelected({
                  ...domicileSelected,
                  districts: value,
                  fasyankes: null,
                });
              }}
              value={
                domicileSelected.districts
                  ? domicile.districts.find(
                    (f) => f.value === domicileSelected.districts
                  )
                  : domicileSelected.districts
              }
              isDisabled={
                currentUser.level === UserLevel.National ||
                  currentUser.level === UserLevel.Province
                  ? false
                  : true
              }
            />
          </div>
        </div>
      );
    } else if (selectLevel === UserLevel.Fasyankes) {
      return (
        <div style={{ display: "flex", flexDirection: "row", gap: "1.2rem" }}>
          <div style={{ flex: "1" }}>
            <InputSelect
              placeholder={"Pilih Provinsi"}
              data={domicile.provincies}
              onChange={(e) => {
                const value = e ? e.value : null;
                setDomicileSelected({
                  ...domicileSelected,
                  provincies: value,
                  districts: null,
                  fasyankes: null,
                });
              }}
              value={
                domicileSelected.provincies
                  ? domicile.provincies.find(
                    (f) => f.value === domicileSelected.provincies
                  )
                  : domicileSelected.provincies
              }
              isDisabled={
                currentUser?.level === UserLevel.National ? false : true
              }
            />
          </div>
          <div style={{ flex: "1" }}>
            <InputSelect
              placeholder={"Pilih Kab/Kota"}
              data={domicile.districts}
              onChange={(e) => {
                const value = e ? e.value : null;
                setDomicileSelected({
                  ...domicileSelected,
                  districts: value,
                  fasyankes: null,
                });
              }}
              value={
                domicileSelected.districts
                  ? domicile.districts.find(
                    (f) => f.value === domicileSelected.districts
                  )
                  : domicileSelected.districts
              }
              isDisabled={
                currentUser?.level === UserLevel.National ||
                  currentUser?.level === UserLevel.Province
                  ? false
                  : true
              }
            />
          </div>
          <div style={{ flex: "1" }}>
            <InputSelect
              placeholder={"Pilih Faskes"}
              data={domicile.fasyankes}
              onChange={(e) => {
                const value = e ? e.value : null;
                setDomicileSelected({ ...domicileSelected, fasyankes: value });
              }}
              value={
                domicileSelected.fasyankes
                  ? domicile.fasyankes.find(
                    (f) => f.value === domicileSelected.fasyankes
                  )
                  : domicileSelected.fasyankes
              }
              isDisabled={
                currentUser?.level === UserLevel.National ||
                  currentUser?.level === UserLevel.Province ||
                  currentUser?.level === UserLevel.District
                  ? false
                  : true
              }
            />
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  const handleApplyFilter = async () => {
    setShowChart(true);
    setLoading(true);
    if (currentUser.level && selectLevel) {
      let dateSelected = date.year;
      let levelIdSelected = "";
      if (selectLevel === UserLevel.Fasyankes) {
        levelIdSelected = domicileSelected.fasyankes;
      } else if (selectLevel === UserLevel.District) {
        levelIdSelected = domicileSelected.districts;
      } else if (selectLevel === UserLevel.Province) {
        levelIdSelected = domicileSelected.provincies;
      } else if (selectLevel === UserLevel.National) {
        levelIdSelected = "1";
      }

      const levelIdValue = levelIdSelected?.includes("|")
        ? levelIdSelected?.split("|")[0]
        : levelIdSelected;
      if (dateSelected && selectedIndikator) {
        await getAnalysis(
          dateSelected,
          selectLevel.toLowerCase(),
          levelIdValue,
          selectedIndikator
        );
      }
      setDateString(dateSelected);
      if (selectLevel === "National") {
        setCurrentDomicile("Nasional");
      } else if (selectLevel === "Province") {
        setCurrentDomicile(domicileSelected.provincies?.split("|")[1] || "");
      } else if (selectLevel === "District") {
        setCurrentDomicile(
          `${domicileSelected.provincies?.split("|")[1] || ""} - ${domicileSelected.districts?.split("|")[1] || ""
          }`
        );
      } else {
        setCurrentDomicile(
          `${domicileSelected.provincies?.split("|")[1] || ""} - ${domicileSelected.districts?.split("|")[1] || ""
          } - ${domicileSelected.fasyankes?.split("|")[1] || ""}`
        );
      }
    }
    setLoading(false);
  };

  const getAllProvincies = async () => {
    try {
      const response = await WilayahService.getAll(Source.PROVINCE, {
        page: 1,
        limit: 999,
      });

      setDomicile((prevState) => ({
        ...prevState,
        provincies: response.province.map((province) => {
          return {
            label: province.name,
            value: `${province.id}|${province.name}`,
          };
        }),
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const getAllDistrictsByProvince = async (provinceId) => {
    try {
      const response = await WilayahService.getAll(Source.DISTRICT, {
        page: 1,
        limit: 9999,
        searchBy: `province_id`,
        searchValue: provinceId?.split("|")[0],
      });

      const data = response.district.map((item) => ({
        label: item.name,
        value: `${item.id}|${item.name}`,
      }));

      setDomicile((prevState) => ({
        ...prevState,
        districts: data,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const getAllFasyankesByDistrict = async (districtId) => {
    try {
      const response = await FasyankesService.getAll(Source.FASYANKES, {
        page: 1,
        limit: 9999,
        searchBy: `district_id`,
        searchValue: districtId?.split("|")[0],
        excludePkm: false,
      });

      const data = response.fasyankes.map((item) => ({
        label: item.name,
        value: `${item.id}|${item.name}`,
      }));

      setDomicile((prevState) => ({
        ...prevState,
        fasyankes: data,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const getAnalysis = async (date, level, level_id, indikator) => {
    try {
      let response = null;
      if (selectedIndikator === "indikator_26") {
        response = await AnalysisService.getPetaEndemis({
          year: new Date(date).getFullYear(),
          level_id,
          level,
        });
      } else if (selectedIndikator === "indikator_28") {
        response = await AnalysisService.getPetaFokus({
          year: new Date(date).getFullYear(),
          level_id,
          level,
        });
      } else if (selectedIndikator === "indikator_29") {
        response = await AnalysisService.getPetaReseptifitas({
          year: new Date(date).getFullYear(),
          level_id,
          level,
        });
      }

      setChartData(response.data);
      setTotalData(response.total);
    } catch (error) {
      toast.error(`Gagal: ${error.response.data.message}`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
        theme: "colored",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllProvincies();
  }, []);

  useEffect(() => {
    if (domicileSelected.provincies) {
      getAllDistrictsByProvince(domicileSelected.provincies);
    }
  }, [domicileSelected.provincies]);

  useEffect(() => {
    if (domicileSelected.districts) {
      getAllFasyankesByDistrict(domicileSelected.districts);
    }
  }, [domicileSelected.districts]);

  useEffect(() => {
    switch (currentUser.level) {
      case UserLevel.Fasyankes:
        setLevelData([{ label: "Fasyankes", value: UserLevel.Fasyankes }]);
        break;
      case UserLevel.District:
        setLevelData([
          { label: "Kabupaten", value: UserLevel.District },
          { label: "Fasyankes", value: UserLevel.Fasyankes },
        ]);
        break;
      case UserLevel.Province:
        setLevelData([
          { label: "Provinsi", value: UserLevel.Province },
          { label: "Kabupaten", value: UserLevel.District },
          { label: "Fasyankes", value: UserLevel.Fasyankes },
        ]);
        break;

      default:
        setLevelData([
          { label: "Nasional", value: UserLevel.National },
          { label: "Provinsi", value: UserLevel.Province },
          { label: "Kabupaten", value: UserLevel.District },
          { label: "Fasyankes", value: UserLevel.Fasyankes },
        ]);
        break;
    }
  }, []);

  return (
    <>
      <div className="filter">
        <div className="filter-header">
          <p>FILTER</p>
          <button
            className="btn_toggle_filter"
            onClick={() => setShowFilter(!showFilter)}
          >
            {showFilter ? <ArrowUp size={18} /> : <ArrowDown size={18} />}
          </button>
        </div>
        {showFilter && (
          <div className="filter-body">
            <div className="filter-item">
              <div className="filter-label">
                <p>Indikator</p>
              </div>
              <div className="filter-field">
                <InputSelect
                  placeholder={"Pilih Indikator"}
                  data={indicatorPetaOpt}
                  onChange={(e) => {
                    const selectedValue = e ? e.value : "";
                    setSelectedIndikator(selectedValue);
                    setShowChart(false);
                  }}
                />
              </div>
            </div>
            <div className="filter-item">
              <div className="filter-label">
                <p>Periode Laporan</p>
              </div>
              <div className="filter-field">
                <div>{renderPeriodeFilter()}</div>
              </div>
            </div>
            <div className="filter-item">
              <div className="filter-label">
                <p>Level</p>
              </div>
              <div className="filter-field">
                <InputRadio
                  options={
                    selectedIndikator === "indikator_26"
                      ? levelData.filter(
                        (r) =>
                          r.value === UserLevel.National ||
                          r.value === UserLevel.Province ||
                          r.value === UserLevel.District
                      ) :
                      (selectedIndikator === "indikator_28" || selectedIndikator === "indikator_29") ?
                        levelData.filter(
                          (r) =>
                            r.value !== UserLevel.National
                        ) : levelData
                  }
                  onChange={(e) => {
                    setSelectedLevel(e.target.value);
                    setPeriodDate(initialPeriodDate);
                  }}
                  selectedOption={selectLevel}
                />
                <div className="advanced-filter">{renderLevelFilter()}</div>
              </div>
            </div>
            <div className="filter-action">
              <Button
                variant={"primary"}
                size={"normal"}
                onClick={() => handleApplyFilter()}
                isDisabled={!date.year}
              >
                Terapkan
              </Button>
            </div>
          </div>
        )}
      </div>
      {isLoading && (
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            marginTop: "5rem",
          }}
        >
          <HashLoader size={100} color="#18b3ab" />
        </div>
      )}
      {selectedIndikator && dateString && selectLevel && !isLoading && (
        <div className="chart-container">
          <div className="chart-header">
            {
              indicatorPetaOpt.find(
                (r) => r.value === selectedIndikator
              ).label
            }
          </div>
          <div className="chart-body">
            <div className="data-information">
              <p>
                <span className="title-label">Level:</span>
                {currentDomicile}
              </p>
              <p>
                <span className="title-label">Periode Laporan:</span>
                {date.year}
              </p>
            </div>
            {showChart && (
              <>
                {(selectedIndikator === "indikator_26" ||
                  selectedIndikator === "indikator_28" ||
                  selectedIndikator === "indikator_29") && (
                    <div className="map">
                      <MyMap 
                        geoJson={chartData} 
                        indicator={selectedIndikator} 
                        level={selectLevel} 
                        levelData={levelData}
                        period={date.year} />
                    </div>
                  )}
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default FilterDataComponent;
