import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import Rujukan from "services/Rujukan";
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import ActionButton from "components/atoms/ActionButton";
import TableDataComponent from "components/organisms/TableData";
import { useHistory } from "react-router-dom";
import InputField from "components/atoms/InputField";
import InputDate from "components/atoms/InputDate";
import InputSelect from "components/atoms/InputSelect";
import Button from "components/atoms/Button";
import { MonthOptions } from "utils/constant";

const RujukanService = new Rujukan();

const TableComponent = () => {
  const [isLoading, setIsLoading] = useState(true);

  const [state, setState] = useState({
    page: 1,
    limit: 10,
    searchKeyword: "",
    isLoading: true,
    data: [],
    totalData: 0,
    currentPage: 1,
    nextPage: null,
    previousPage: null,
  });

  const [filterState, setFilterState] = useState({
    selected_year: "",
    selected_month: "",
  });

  const { currentUser } = useSelector((state) => state.authReducer);
  const history = useHistory();

  const getDataFaskesAsal = async () => {
    setIsLoading(true);
    try {
      let searchBy = "source_fasyankes_id";
      let searchValue = currentUser?.fasyankes_id;

      if (state.searchKeyword !== "") {
        searchBy += ",name";
        searchValue += `,${state.searchKeyword}`;
      }

      const searchByDate = filterState?.selected_year && filterState?.selected_month ? "|YYYY-MM"
        : filterState?.selected_year ? "|YYYY" : filterState?.selected_month ? "|MM" : "";
      const searchValueDate = filterState?.selected_year && filterState?.selected_month
        ? `,${filterState?.selected_year}-${filterState?.selected_month}`
        : filterState?.selected_year ? `,${filterState?.selected_year}`
          : filterState?.selected_month ? `,${filterState?.selected_month}` : "";

      if (filterState.selected_year || filterState.selected_month) {
        searchBy += `,ref_date`;
        searchValue += searchValueDate;
      }

      const response = await RujukanService.getAll({
        page: 1,
        limit: 10,
        searchBy,
        searchValue,
      });

      setState((prevState) => ({
        ...prevState,
        data: response.regmal_references,
        limit: response.limit,
        currentPage: response.currentPage,
        totalData: response.total,
        nextPage: response.nextPage,
        previousPage: response.previousPage,
      }));
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDataFaskesAsal();
  }, [state.page, state.searchKeyword]);

  const onPageChange = (page) => {
    setState((prevState) => ({
      ...prevState,
      page: page,
    }));
  };

  const onDataSearch = (e) => {
    const keyword = e.target.value;
    setState({
      ...state,
      searchKeyword: keyword,
    });
  };

  const columns = [
    {
      header: "Aksi",
      accessorKey: "action",
      cell: ({ row }) => (
        <div className="button-actions">
          <ActionButton
            btnType={"detail"}
            onClick={() => history.push(`/detail-rujukan/${row.original.id}`)}
          />
        </div>
      ),
    },
    {
      header: "Tindak Lanjut Faskes Rujukan",
      accessorKey: "status",
      cell: ({ row }) => {
        const status = row.original.status;
        let badgeClassName;
        if (status === "Belum") {
          badgeClassName = "badge badge-warning";
        } else if (status === "Sudah") {
          badgeClassName = "badge badge-success";
        } else {
          badgeClassName = "badge badge-danger";
        }

        return (
          <div>
            <span className={badgeClassName}>{status}</span>
          </div>
        );
      },
    },
    {
      header: "Tanggal Dirujuk",
      accessorKey: "ref_date",
    },
    {
      header: "NIK",
      accessorKey: "ktp",
    },
    {
      header: "Nama",
      accessorKey: "name",
    },
    {
      header: "Usia",
      accessorKey: "age",
      cell: ({ row }) => {
        return `${row.original.age} ${row.original?.age_type}`;
      }
    },
    {
      header: "Jenis Kelamin",
      accessorKey: "gender",
    },
    {
      header: "Faskes Asal",
      accessorKey: "source_fasyankes_id",
      cell: ({ row }) => row.original?.source_fasyankes_id?.split("|")[1],
    },
    {
      header: "Faskes Rujukan",
      accessorKey: "ref_fasyankes_id",
      cell: ({ row }) => row.original?.ref_fasyankes_id?.split("|")[1],
    },
  ];

  const tableInstance = useReactTable({
    columns,
    data: state?.data,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <>
      <div className="card-filter">
        <div className="filter-item">
          <InputDate
            placeholder={"Pilih Tahun"}
            name={"selected_year"}
            dateFormat={"YYYY"}
            onChange={(e) => {
              if(e){
                const selectedYear = moment(e).format("YYYY");
                setFilterState({
                  ...filterState,
                  selected_year: selectedYear,
                });
              }else{
                setFilterState({
                  ...filterState,
                  selected_year: "",
                });
              }
            }}
            value={filterState.selected_year}
          />
        </div>
        <div className="filter-item">
          <InputSelect
            placeholder={"Pilih Bulan"}
            data={MonthOptions}
            onChange={(e) => {
              const selectedValue = e ? e.value : "";
              setFilterState({
                ...filterState,
                selected_month: selectedValue,
              });
            }}
            value={MonthOptions.find(
              (item) => item.value === filterState.selected_month
            )}
          />
        </div>
        <Button
          variant={"primary"}
          size={"normal"}
          onClick={() => getDataFaskesAsal()}
        >
          Terapkan
        </Button>
      </div>

      <div className="action-wrapper">
        <div className="action-button" />
        <div className="action-search">
          <InputField
            placeholder={"Cari data berdasarkan nama pasien..."}
            onChange={onDataSearch}
            value={state.searchKeyword}
          />
        </div>
      </div>
      {/* table component */}
      <TableDataComponent
        columnLength={columns?.length}
        tableInstance={tableInstance}
        onPageChange={onPageChange}
        loading={isLoading}
        currentPage={state.currentPage}
        limit={state.limit}
        nextPage={state.nextPage}
        previousPage={state.previousPage}
        total={state.totalData}
      />
    </>
  );
};

export default TableComponent;
