import { useFormik } from "formik";
import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  errorToastNotif,
  getDataFromLocalStorage,
  nullConverter,
  successToastNotif,
} from "utils/common";
import {
  PageMode,
  Source,
  TraitBreedingOptions,
  TypeBreedingPlaceOptions,
  YesAndNoOptions,
} from "utils/constant";
import FlickIdentification from "services/FlickIdentification";
import Wilayah from "services/Wilayah";
import { useEffect } from "react";
import { BreadcrumbNav } from "components/molecules";
import InputDate from "components/atoms/InputDate";
import moment from "moment";
import InputSelect from "components/atoms/InputSelect";
import InputField from "components/atoms/InputField";
import InputCheckbox from "components/atoms/InputCheckbox";
import Button from "components/atoms/Button";
import MapsComponents from "components/Maps";
import Dropzone from "react-dropzone";

const FlickIdentificationService = new FlickIdentification();
const WilayahService = new Wilayah();

const FormComponent = ({ changeMode, pageMode }) => {
  const [urbanvillages, setUrbanvillages] = useState([]);
  const [itemToEdit, setItemToEdit] = useState(null);
  const [files, setFiles] = useState([]);
  const [onEditFileName, setOnEditFileName] = useState("");
  const [showFileError, setShowFileError] = useState(false);

  const { currentUser } = useSelector((state) => state.authReducer);

  const formik = useFormik({
    initialValues: {
      survey_date: "",
      urbanvillage_id: "",
      sub_village: "",
      focus_id: "",
      coordinate: "",
      address: "",
      type_of_breeding_place: "",
      other_type_of_breeding_place: "",
      total_catch: "",
      anopheles_larva_found: "",
      larva_phase: [],
      larva_density_per_catch: "",
      area_breeding_place: "",
      depth_breeding_place: "",
      plan_exist_breeding_place: "",
      rubbish_exist_breeding_place: "",
      trait_breeding_place: "",
    },
    validate: (values) => {
      const errors = {};

      if (!values.survey_date) {
        errors.survey_date = "Tanggal Survey wajib diisi";
      }

      if (!values.urbanvillage_id) {
        errors.urbanvillage_id = "Desa wajib diisi";
      }

      if (!values.sub_village) {
        errors.sub_village = "Dusun wajib diisi";
      }

      if (!values.type_of_breeding_place) {
        errors.type_of_breeding_place = "Tipe Tempat Jentik wajib diisi";
      }

      if (!values.anopheles_larva_found) {
        errors.anopheles_larva_found = "Jumlah Larva Ditemukan wajib diisi";
      }

      return errors;
    },
    onSubmit: async (values) => {
      const payload = {
        fasyankes_id: currentUser?.fasyankes_id,
        survey_date: nullConverter(values.survey_date),
        urbanvillage_id: nullConverter(values.urbanvillage_id),
        sub_village: nullConverter(values.sub_village),
        focus_id: nullConverter(values.focus_id),
        coordinate: nullConverter(values.coordinate),
        address: nullConverter(values.address),
        type_of_breeding_place:
          values.type_of_breeding_place === "Lainnya"
            ? `Lainnya_${values.other_type_of_breeding_place}`
            : values.type_of_breeding_place,
        total_catch: nullConverter(values.total_catch),
        anopheles_larva_found: nullConverter(values.anopheles_larva_found),
        larva_phase: nullConverter(values.larva_phase),
        larva_density_per_catch: nullConverter(values.larva_density_per_catch),
        area_breeding_place: nullConverter(values.area_breeding_place),
        depth_breeding_place: nullConverter(values.depth_breeding_place),
        plan_exist_breeding_place: nullConverter(
          values.plan_exist_breeding_place
        ),
        rubbish_exist_breeding_place: nullConverter(values.rubbish_exist_breeding_place),
        trait_breeding_place: nullConverter(values.trait_breeding_place),
      };

      switch (pageMode) {
        case PageMode.add:
          await FlickIdentificationService.add({
            ...payload,
            image: files[0] || null,
          })
            .then((res) => {
              changeMode(PageMode.view);
              successToastNotif(
                "Data Identifikasi Jentik Berhasil Ditambahkan"
              );
            })
            .catch((err) => {
              console.log(err);
              errorToastNotif(err);
            });
          break;
        case PageMode.edit:
          await FlickIdentificationService.update(payload, itemToEdit?.id)
            .then((res) => {
              changeMode(PageMode.view);
              successToastNotif("Data Identifikasi Jentik Berhasil Diperbarui");
            })
            .catch((err) => {
              console.log(err);
              errorToastNotif(err);
            });
          break;
      }
    },
  });

  const fetchUrbanvillagesByCurrentUser = async () => {
    try {
      const response = await WilayahService.getAll(Source.URBANVILLAGE, {
        page: 1,
        limit: 9999,
        searchBy: `subdistrict_id`,
        searchValue: currentUser?.subdistrict_id?.split("|")[0],
      });

      const mappedData = response.urbanvillage.map((item) => {
        return {
          label: item.name,
          value: `${item.id}|${item.name}`,
        };
      });
      setUrbanvillages(mappedData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchUrbanvillagesByCurrentUser();
  }, []);

  useEffect(() => {
    if (pageMode === PageMode.edit) {
      const selectedItem = JSON.parse(getDataFromLocalStorage("SELECTED_ITEM"));
      setItemToEdit(selectedItem);

      let selectedTypeBreeding = "";
      let otherTypeBreeding = "";

      if (
        TypeBreedingPlaceOptions.filter(
          (e) => e.value === selectedItem?.type_of_breeding_place
        ).length > 0
      ) {
        selectedTypeBreeding = selectedItem?.type_of_breeding_place;
      } else {
        selectedTypeBreeding = "Lainnya";
        otherTypeBreeding = selectedItem?.type_of_breeding_place?.split("_")[1];
      }

      setOnEditFileName(selectedItem?.image);

      formik.setValues({
        survey_date: selectedItem?.survey_date,
        urbanvillage_id: selectedItem?.urbanvillage_id,
        sub_village: selectedItem?.sub_village,
        focus_id: selectedItem?.focus_id,
        coordinate: selectedItem?.coordinate,
        address: selectedItem?.address,
        type_of_breeding_place: selectedTypeBreeding,
        other_type_of_breeding_place: otherTypeBreeding,
        total_catch: selectedItem?.total_catch,
        anopheles_larva_found: selectedItem?.anopheles_larva_found,
        larva_phase: selectedItem?.larva_phase,
        larva_density_per_catch: selectedItem?.larva_density_per_catch,
        area_breeding_place: selectedItem?.area_breeding_place,
        depth_breeding_place: selectedItem?.depth_breeding_place,
        plan_exist_breeding_place: selectedItem?.plan_exist_breeding_place,
        rubbish_exist_breeding_place: selectedItem?.rubbish_exist_breeding_place,
        trait_breeding_place: selectedItem?.trait_breeding_place,
      });
    }
  }, []);

  const handleMapChange = (address, coordinate) => {
    const coordinateValue = Object.values(coordinate).toString();
    formik.setFieldValue("coordinate", coordinateValue);
  };

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    const value = e.target.value;

    const newValues = isChecked
      ? [...formik.values.larva_phase, value]
      : formik.values.larva_phase.filter((item) => item !== value);
    formik.setFieldValue("larva_phase", newValues);
  };

  return (
    <>
      <BreadcrumbNav
        items={[
          {
            label: "Identifikasi Jentik",
            onClick: () => changeMode(PageMode.view),
          },
          {
            label:
              pageMode === PageMode.add
                ? "Tambah Data Identifikasi Jentik"
                : "Edit Data Identifikasi Jentik",
          },
        ]}
      />
      <h1 className="page-title">
        Form Data Identifikasi Jentik & Intervensi Tempat Perindukan
      </h1>
      <div className="form-grouping">
        <p
          className="warning"
          style={{ marginTop: "24px", marginBottom: "24px" }}
        >
          NOTE <span>*(WAJIB DIISI)</span>
        </p>
        <InputDate
          label={"Tanggal Survey"}
          name={"survey_date"}
          placeholder={"Pilih Tanggal"}
          dateFormat={"YYYY-MM-DD"}
          onChange={(e) => {
            const selectedDate = moment(e).format("YYYY-MM-DD");
            formik.handleChange("survey_date")(selectedDate);
          }}
          onBlur={formik.handleBlur}
          value={formik.values.survey_date}
          error={formik.errors.survey_date && formik.touched.survey_date}
          errorMessage={formik.errors.survey_date}
          isRequired={true}
        />
        <InputSelect
          label={"Pilih Desa"}
          name={"urbanvillage_id"}
          placeholder={"Pilih Desa"}
          data={urbanvillages}
          onChange={(e) => {
            const selectedValue = e ? e.value : "";
            formik.handleChange("urbanvillage_id")(selectedValue);
          }}
          onBlur={formik.handleBlur}
          value={urbanvillages?.find(
            (item) => item.value === formik.values.urbanvillage_id
          )}
          error={
            formik.errors.urbanvillage_id && formik.touched.urbanvillage_id
          }
          errorMessage={formik.errors.urbanvillage_id}
          isRequired={true}
        />
        <InputField
          label={"Nama Dusun"}
          name={"sub_village"}
          placeholder={"Masukkan Nama Dusun"}
          {...formik.getFieldProps("sub_village")}
          error={formik.errors.sub_village && formik.touched.sub_village}
          errorMessage={formik.errors.sub_village}
          isRequired={true}
        />
        {/* maps here */}
        <div className="maps-input">
          <label htmlFor="" style={{ marginBottom: "12px", fontWeight: "600" }}>
            Pilih Lokasi
          </label>
          <MapsComponents
            onChangeLocation={(address, coordinate) => {
              handleMapChange(address, coordinate);
            }}
          />
        </div>
        {/* coordinate here */}
        <InputField
          placeholder={"Titik koordinat akan muncul"}
          name="coordinate"
          value={formik.values.coordinate}
          readOnly={true}
          error={formik.errors.coordinate && formik.touched.coordinate}
          errorMessage={formik.errors.coordinate}
        />
        {/* image here */}
        {/* Dropzone Component Here */}
        <div className="form-group">
          <label className="image">Upload gambar perindukan (Opsional)</label>
          <Dropzone
            maxFiles={1}
            accept="image/*"
            onDrop={(acceptedFiles, fileRejections) => {
              if (fileRejections.length > 0) {
                if (fileRejections[0].errors[0].code === "file-too-large") {
                  toast.error("Ukuran file tidak boleh lebih dari 300 Kb");
                } else if (
                  fileRejections[0].errors[0].code === "file-invalid-type"
                ) {
                  toast.error("Format file tidak sesuai");
                }
              }

              if (acceptedFiles.length > 0) {
                setFiles(acceptedFiles);
              }
            }}
            maxSize={1024 * 300}
          >
            {({ getRootProps, getInputProps }) => (
              <section className="container">
                <div {...getRootProps({ className: "dropzone" })}>
                  <input {...getInputProps()} />
                  <div className="dz-message">
                    <div className="icon">
                      <i className="flaticon-file"></i>
                    </div>
                    <h5>Upload gambar perindukan</h5>
                    <div className="note">Drag & Drop or</div>
                    <button className="btn btn-teal">
                      Pilih File dari perangkat anda
                    </button>
                    <div className="note">Gambar maksimal 300 KB</div>
                  </div>
                </div>
                <aside>
                  <h4>Files</h4>
                  <ul>
                    {files.map((file) => (
                      <li key={file.name}>
                        {file.name} - {file.size} bytes
                      </li>
                    ))}
                  </ul>
                  {files.length < 1 && pageMode === PageMode.edit && (
                    <ul>
                      <li>{onEditFileName}</li>
                    </ul>
                  )}
                  {/* {this.state.showFileError && this.state.files.length < 1 && (
                    <small className="text-danger">File wajib dipilih</small>
                  )} */}
                  {showFileError && files.length < 1 && (
                    <small className="text-danger">File wajib dipilih</small>
                  )}
                </aside>
              </section>
            )}
          </Dropzone>
        </div>
        {/* End of Dropzone Component */}

        <InputSelect
          label={"Jenis Tempat Perindukan"}
          name={"type_of_breeding_place"}
          placeholder={"Pilih Jenis Tempat Perindukan"}
          data={TypeBreedingPlaceOptions}
          onChange={(e) => {
            const selectedValue = e ? e.value : "";
            formik.handleChange("type_of_breeding_place")(selectedValue);

            if (selectedValue !== "Lainnya") {
              formik.setFieldValue("other_type_of_breeding_place", "");
            }
          }}
          onBlur={formik.handleBlur}
          value={TypeBreedingPlaceOptions.find(
            (item) => item.value === formik.values.type_of_breeding_place
          )}
          error={
            formik.errors.type_of_breeding_place &&
            formik.touched.type_of_breeding_place
          }
          errorMessage={formik.errors.type_of_breeding_place}
          isRequired={true}
        />

        {formik.values.type_of_breeding_place === "Lainnya" && (
          <InputField
            name="other_type_of_breeding_place"
            placeholder={"Masukkan Jenis Tempat Perindukan Lainnya"}
            {...formik.getFieldProps("other_type_of_breeding_place")}
            error={
              formik.errors.other_type_of_breeding_place &&
              formik.touched.other_type_of_breeding_place
            }
            errorMessage={formik.errors.other_type_of_breeding_place}
          />
        )}

        <InputField
          label={"Jumlah Cidukan (Opsional)"}
          name="total_catch"
          placeholder={"Masukkan Jumlah Cidukan"}
          type={"number"}
          {...formik.getFieldProps("total_catch")}
        />
        <InputSelect
          label={"Ditemukan Jentik Anopheles"}
          name={"anopheles_larva_found"}
          placeholder={"Pilih Penemuan Jentik Anopheles"}
          data={YesAndNoOptions}
          onChange={(e) => {
            const selectedValue = e ? e.value : "";
            formik.handleChange("anopheles_larva_found")(selectedValue);
            if (selectedValue === "Tidak") {
              formik.setFieldValue("larva_phase", []);
              formik.setFieldValue("larva_density_per_catch", "");
              formik.setFieldValue("area_breeding_place", "");
              formik.setFieldValue("depth_breeding_place", "");
              formik.setFieldValue("plan_exist_breeding_place", "");
              formik.setFieldValue("rubbish_exist_breeding_place", "");
              formik.setFieldValue("trait_breeding_place", "");
            }
          }}
          onBlur={formik.handleBlur}
          value={YesAndNoOptions.find(
            (item) => item.value === formik.values.anopheles_larva_found
          )}
          error={
            formik.errors.anopheles_larva_found &&
            formik.touched.anopheles_larva_found
          }
          errorMessage={formik.errors.anopheles_larva_found}
          isRequired={true}
        />

        {formik.values.anopheles_larva_found === "Ya" && (
          <>
            <InputCheckbox
              label="Fase Jentik (Bisa Memilih lebih dari satu) -  (Opsional)"
              name="larva_phase"
              options={[
                { label: "Instar 1", value: "Instar 1" },
                { label: "Instar 2", value: "Instar 2" },
                { label: "Instar 3", value: "Instar 3" },
              ]}
              onChange={(e) => handleCheckboxChange(e)}
              onBlur={formik.handleBlur}
              selectedOption={formik.values.larva_phase}
            />
            <InputField
              label={"Kepadatan Jentik Per Cidukan (Opsional)"}
              name={"larva_density_per_catch"}
              placeholder={"Masukkan Jumlah Kepadatan Jentik Per Cidukan"}
              type={"number"}
              {...formik.getFieldProps("larva_density_per_catch")}
            />
            <InputField
              label={"Luas (m²) Tempat Perindukan (Opsional)"}
              name={"area_breeding_place"}
              placeholder={"Masukkan Jumlah Kepadatan Jentik Per Cidukan"}
              type={"number"}
              {...formik.getFieldProps("area_breeding_place")}
            />
            <InputField
              label={"Kedalaman (cm) Tempat Perindukan (Opsional)"}
              name={"depth_breeding_place"}
              placeholder={"Masukkan Jumlah Kepadatan Jentik Per Cidukan"}
              type={"number"}
              {...formik.getFieldProps("depth_breeding_place")}
            />
            <InputSelect
              label={"Adanya tanaman pada tempat perindukan (Opsional)"}
              name="plan_exist_breeding_place"
              placeholder={"PILIH"}
              data={YesAndNoOptions}
              onChange={(e) => {
                const selectedValue = e ? e.value : "";
                formik.handleChange("plan_exist_breeding_place")(selectedValue);
              }}
              onBlur={formik.handleBlur}
              value={YesAndNoOptions.find(
                (item) => item.value === formik.values.plan_exist_breeding_place
              )}
            />
            <InputSelect
              label={"Adanya sampah pada tempat perindukan (Opsional)"}
              name="rubbish_exist_breeding_place"
              placeholder={"PILIH"}
              data={YesAndNoOptions}
              onChange={(e) => {
                const selectedValue = e ? e.value : "";
                formik.handleChange("rubbish_exist_breeding_place")(
                  selectedValue
                );
              }}
              onBlur={formik.handleBlur}
              value={YesAndNoOptions.find(
                (item) =>
                  item.value === formik.values.rubbish_exist_breeding_place
              )}
            />
            <InputSelect
              label={"Sifat tempat perindukan (Opsional)"}
              name="trait_breeding_place"
              placeholder={"PILIH"}
              data={TraitBreedingOptions}
              onChange={(e) => {
                const selectedValue = e ? e.value : "";
                formik.handleChange("trait_breeding_place")(selectedValue);
              }}
              onBlur={formik.handleBlur}
              value={TraitBreedingOptions.find(
                (item) => item.value === formik.values.trait_breeding_place
              )}
            />
          </>
        )}

        <div className="button_action">
          <Button
            variant={"tertiary"}
            size={"normal"}
            onClick={() => changeMode(PageMode.view)}
          >
            Batalkan
          </Button>
          <Button
            type={"submit"}
            variant={"primary"}
            size={"normal"}
            onClick={formik.handleSubmit}
            isDisabled={formik.isSubmitting}
          >
            Simpan Data
          </Button>
        </div>
      </div>
    </>
  );
};

export default FormComponent;
