import { errHandler } from "../utils/common";
import api from "../utils/api";
export default class Edemisitas {
  getAll = (
    opt = { page: 1, limit: 10, searchBy: null, searchValue: null }
  ) => {
    return new Promise((resolve, reject) => {
      api
        .get("/api/endemisitas", { params: opt })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };
  getAvail = (opt = { province_id, year }) => {
    return new Promise((resolve, reject) => {
      api
        .get("/api/endemisitas_avail", { params: opt })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };
  add = (payload) => {
    return new Promise((resolve, reject) => {
      api
        .post("/api/endemisitas?device=Online", payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };
  update = (payload, peCasesId) => {
    return new Promise((resolve, reject) => {
      api
        .patch(`/api/endemisitas/${peCasesId}?device=Online`, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };
  remove = (peCasesId) => {
    return new Promise((resolve, reject) => {
      api
        .delete(`/api/endemisitas/${peCasesId}?device=Online`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };
}
