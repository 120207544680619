import api from "../utils/api";
import { errHandler } from "../utils/common";

export default class Wilayah {

  getAll = (source, opt = { searchBy: '', searchValue: '', page: 1, limit: 10, }) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/wilayah/${source}`, { params: opt })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };
}
