import React from "react";
import { monthConverter } from "utils/common";
import { UserLevel } from "utils/constant";

const TableComponent = ({
  currentLevel,
  reportsData,
  tableRef,
  reportInfo,
}) => {
  const renderTableByLevel = () => {
    switch (currentLevel) {
      case UserLevel.National.toLowerCase():
        return (
          <>
            <thead>
              <tr>
                <th rowSpan={2}>No.</th>
                <th rowSpan={2}>Kabupaten</th>
                <th rowSpan={2}>Kabupaten</th>
                <th rowSpan={2}>Jumlah Kader</th>
                <th colSpan={2}>Asal Kader</th>
                <th colSpan={2}>Jenis Kelamin</th>
                <th colSpan={8}>Pendidikan Terakhir</th>
                <th colSpan={2}>Kepemilikan SK Penunjukan kader</th>
                <th colSpan={3}>Sumber Pendanaan Kader</th>
                <th colSpan={4}>Tupoksi Kader</th>
              </tr>
              <tr>
                {/* asal kader */}
                <td>Perdhaki</td>
                <td>Bukan Perdhaki</td>
                {/* jenis kelamin */}
                <td>Laki-laki</td>
                <td>Perempuan</td>
                {/* pendidikan terakhir */}
                <td>Tidak Tamat SD</td>
                <td>Tamat SD</td>
                <td>SMP</td>
                <td>SMA</td>
                <td>DIPLOMA</td>
                <td>Sarjana</td>
                <td>Magister</td>
                <td>Doktor</td>
                {/* kepemilikan SK */}
                <td>Ya</td>
                <td>Tidak</td>
                {/* sumber pendanaan */}
                <td>APBD</td>
                <td>Dana Kampung</td>
                <td>Lainnya</td>
                {/* tupoksi kader */}
                <td>Penemuan kasus</td>
                <td>Promkes</td>
                <td>Surveilans</td>
                <td>Vektor</td>
              </tr>
            </thead>
            <tbody>
              {reportsData.length > 0 ? (
                reportsData?.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item?.province}</td>
                    <td>{item?.district}</td>
                    <td>{item?.total_cadre}</td>
                    <td>{item?.total_perdhaki}</td>
                    <td>{item?.total_nonperdhaki}</td>
                    <td>{item?.total_male}</td>
                    <td>{item?.total_female}</td>
                    <td>{item?.total_nonsd}</td>
                    <td>{item?.total_sd}</td>
                    <td>{item?.total_smp}</td>
                    <td>{item?.total_sma}</td>
                    <td>{item?.total_d3}</td>
                    <td>{item?.total_s1}</td>
                    <td>{item?.total_s2}</td>
                    <td>{item?.total_s3}</td>
                    <td>{item?.total_sk}</td>
                    <td>{item?.total_nonsk}</td>
                    <td>{item?.total_apbd}</td>
                    <td>{item?.total_danakampung}</td>
                    <td>{item?.total_lainnya}</td>
                    <td>{item?.total_penkasus}</td>
                    <td>{item?.total_promkes}</td>
                    <td>{item?.total_surveilans}</td>
                    <td>{item?.total_vector}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>Tidak ada data</td>
                </tr>
              )}
            </tbody>
          </>
        );
      case UserLevel.Province.toLowerCase():
        return (
          <>
            <thead>
              <tr>
                <th rowSpan={2}>No.</th>
                <th rowSpan={2}>Kabupaten</th>
                <th rowSpan={2}>Kabupaten</th>
                <th rowSpan={2}>Jumlah Kader</th>
                <th colSpan={2}>Asal Kader</th>
                <th colSpan={2}>Jenis Kelamin</th>
                <th colSpan={8}>Pendidikan Terakhir</th>
                <th colSpan={2}>Kepemilikan SK Penunjukan kader</th>
                <th colSpan={3}>Sumber Pendanaan Kader</th>
                <th colSpan={4}>Tupoksi Kader</th>
              </tr>
              <tr>
                {/* asal kader */}
                <td>Perdhaki</td>
                <td>Bukan Perdhaki</td>
                {/* jenis kelamin */}
                <td>Laki-laki</td>
                <td>Perempuan</td>
                {/* pendidikan terakhir */}
                <td>Tidak Tamat SD</td>
                <td>Tamat SD</td>
                <td>SMP</td>
                <td>SMA</td>
                <td>DIPLOMA</td>
                <td>Sarjana</td>
                <td>Magister</td>
                <td>Doktor</td>
                {/* kepemilikan SK */}
                <td>Ya</td>
                <td>Tidak</td>
                {/* sumber pendanaan */}
                <td>APBD</td>
                <td>Dana Kampung</td>
                <td>Lainnya</td>
                {/* tupoksi kader */}
                <td>Penemuan kasus</td>
                <td>Promkes</td>
                <td>Surveilans</td>
                <td>Vektor</td>
              </tr>
            </thead>
            <tbody>
              {reportsData.length > 0 ? (
                reportsData?.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item?.province}</td>
                    <td>{item?.district}</td>
                    <td>{item?.total_cadre}</td>
                    <td>{item?.total_perdhaki}</td>
                    <td>{item?.total_nonperdhaki}</td>
                    <td>{item?.total_male}</td>
                    <td>{item?.total_female}</td>
                    <td>{item?.total_nonsd}</td>
                    <td>{item?.total_sd}</td>
                    <td>{item?.total_smp}</td>
                    <td>{item?.total_sma}</td>
                    <td>{item?.total_d3}</td>
                    <td>{item?.total_s1}</td>
                    <td>{item?.total_s2}</td>
                    <td>{item?.total_s3}</td>
                    <td>{item?.total_sk}</td>
                    <td>{item?.total_nonsk}</td>
                    <td>{item?.total_apbd}</td>
                    <td>{item?.total_danakampung}</td>
                    <td>{item?.total_lainnya}</td>
                    <td>{item?.total_penkasus}</td>
                    <td>{item?.total_promkes}</td>
                    <td>{item?.total_surveilans}</td>
                    <td>{item?.total_vector}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>Tidak ada data</td>
                </tr>
              )}
            </tbody>
          </>
        );
      case UserLevel.District.toLowerCase():
        return (
          <>
            <thead>
              <tr>
                <th rowSpan={2}>No.</th>
                <th rowSpan={2}>Kabupaten</th>
                <th rowSpan={2}>Fasyankes</th>
                <th rowSpan={2}>Jumlah Kader</th>
                <th colSpan={2}>Asal Kader</th>
                <th colSpan={2}>Jenis Kelamin</th>
                <th colSpan={8}>Pendidikan Terakhir</th>
                <th colSpan={2}>Kepemilikan SK Penunjukan kader</th>
                <th colSpan={3}>Sumber Pendanaan Kader</th>
                <th colSpan={4}>Tupoksi Kader</th>
              </tr>
              <tr>
                {/* asal kader */}
                <td>Perdhaki</td>
                <td>Bukan Perdhaki</td>
                {/* jenis kelamin */}
                <td>Laki-laki</td>
                <td>Perempuan</td>
                {/* pendidikan terakhir */}
                <td>Tidak Tamat SD</td>
                <td>Tamat SD</td>
                <td>SMP</td>
                <td>SMA</td>
                <td>DIPLOMA</td>
                <td>Sarjana</td>
                <td>Magister</td>
                <td>Doktor</td>
                {/* kepemilikan SK */}
                <td>Ya</td>
                <td>Tidak</td>
                {/* sumber pendanaan */}
                <td>APBD</td>
                <td>Dana Kampung</td>
                <td>Lainnya</td>
                {/* tupoksi kader */}
                <td>Penemuan kasus</td>
                <td>Promkes</td>
                <td>Surveilans</td>
                <td>Vektor</td>
              </tr>
            </thead>
            <tbody>
              {reportsData.length > 0 ? (
                reportsData?.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item?.district}</td>
                    <td>{item?.fasyankes}</td>
                    <td>{item?.total_cadre}</td>
                    <td>{item?.total_perdhaki}</td>
                    <td>{item?.total_nonperdhaki}</td>
                    <td>{item?.total_male}</td>
                    <td>{item?.total_female}</td>
                    <td>{item?.total_nonsd}</td>
                    <td>{item?.total_sd}</td>
                    <td>{item?.total_smp}</td>
                    <td>{item?.total_sma}</td>
                    <td>{item?.total_d3}</td>
                    <td>{item?.total_s1}</td>
                    <td>{item?.total_s2}</td>
                    <td>{item?.total_s3}</td>
                    <td>{item?.total_sk}</td>
                    <td>{item?.total_nonsk}</td>
                    <td>{item?.total_apbd}</td>
                    <td>{item?.total_danakampung}</td>
                    <td>{item?.total_lainnya}</td>
                    <td>{item?.total_penkasus}</td>
                    <td>{item?.total_promkes}</td>
                    <td>{item?.total_surveilans}</td>
                    <td>{item?.total_vector}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>Tidak ada data</td>
                </tr>
              )}
            </tbody>
          </>
        );
      default:
        return (
          <>
            <thead>
              <tr>
                <th>No</th>
                <th>NIK</th>
                <th>Asal Kader</th>
                <th>
                  Nama SR <br /> (Perdhaki)
                </th>
                <th>
                  Nama SSR <br /> (Perdhaki)
                </th>
                <th>Nama</th>
                <th>Usia</th>
                <th>Jenis Kelamin</th>
                <th>Pendidikan Terakhir</th>
                <th>Apakah mempunyai SK penunjukan kader</th>
                <th>Tanggal pelatihan terakhir</th>
                <th>Desa tempat bertugas</th>
                <th>Sumber pendanaan kader</th>
                <th>Tupoksi kader</th>
              </tr>
            </thead>
            <tbody>
              {reportsData.length > 0 ? (
                reportsData?.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item?.ktp}</td>
                    <td>{item?.cadre_origin}</td>
                    <td>{item?.sr_id}</td>
                    <td>{item?.ssr_id}</td>
                    <td>{item?.name}</td>
                    <td>{item?.age}</td>
                    <td>{item?.gender}</td>
                    <td>{item?.last_education}</td>
                    <td>{item?.is_sk_cadre}</td>
                    <td>{item?.last_training_date}</td>
                    <td>{item?.village_assignment}</td>
                    <td>{item?.fund_source_cadre}</td>
                    <td>{item?.cadre_tupoksi}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={14}>Tidak Ada Data</td>
                </tr>
              )}
              {/* <tr style={{ backgroundColor: "#43766C" }}>
                <td style={{ color: "#FFF" }} colSpan={6}>
                  Total
                </td>
                <td style={{ color: "#FFF" }}>{total}</td>
              </tr> */}
            </tbody>
          </>
        );
    }
  };

  let levelName = "";
  if (currentLevel === "national") {
    levelName = "Nasional";
  } else if (currentLevel === "province") {
    levelName = "Provinsi";
  } else if (currentLevel === "district") {
    levelName = "Kabupaten/Kota";
  } else {
    levelName = "Fasyankes";
  }

  let periodeName = "";
  if (reportInfo?.statusPeriode === "yearly") {
    periodeName = "Tahunan";
  } else {
    periodeName = "Bulanan";
  }

  let data = reportInfo?.fasyankes;
  let parts = data?.split(",");
  let faskesName = parts?.map(function (part) {
    return part?.split("|")[1];
  });
  let result = faskesName?.join(", ");

  return (
    <div
      className="table-table-responsive"
      style={{ height: "560px", overflow: "scroll" }}
      ref={tableRef}
    >
      <table>
        <tr style={{ border: "none", padding: 0 }}>
          <th style={{ border: "none", paddingBottom: "0" }}>
            <h3>SISMAL v3, MMP DAN MONITORING KADER MMP</h3>
          </th>
        </tr>
      </table>
      <table>
        <tr style={{ border: "none" }}>
          <th
            width="200"
            style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}
          >
            Periode Laporan
          </th>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            : {periodeName}
          </th>
        </tr>
        {reportInfo?.statusPeriode === "monthly" && (
          <tr style={{ border: "none" }}>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              Bulan
            </th>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              : {monthConverter(reportInfo?.periode?.month)}
            </th>
          </tr>
        )}
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            Level
          </th>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            : {levelName}
          </th>
        </tr>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            Provinsi
          </th>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            :{" "}
            {!reportInfo?.province
              ? "Semua Provinsi"
              : reportInfo?.province?.split("|")[1]}
          </th>
        </tr>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            Kab/Kota
          </th>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            :{" "}
            {!reportInfo?.district
              ? "Semua Kab/Kota"
              : reportInfo?.district?.split("|")[1]}
          </th>
        </tr>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingTop: 0 }}>Fasyankes</th>
          <th style={{ border: "none", paddingTop: 0 }}>
            : {!reportInfo?.fasyankes ? "Semua Fasyankes" : result}
          </th>
        </tr>
      </table>
      <table>{renderTableByLevel()}</table>
    </div>
  );
};

export default TableComponent;
