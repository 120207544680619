import Button from "components/atoms/Button";
import InputCheckbox from "components/atoms/InputCheckbox";
import InputDate from "components/atoms/InputDate";
import { useFormik } from "formik";
import moment from "moment";
import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  errorToastNotif,
  getDataFromLocalStorage,
  nullConverter,
  successToastNotif,
} from "utils/common";
import { LarvacideTypeOptions, PageMode, UnitOptions } from "utils/constant";
import { useParams } from "react-router-dom";
import LarvaBreeding from "services/LarvaBreeding";
import { useEffect } from "react";
import InputField from "components/atoms/InputField";
import InputSelect from "components/atoms/InputSelect";
import { BreadcrumbNav } from "components/molecules";

const LarvaBreedingService = new LarvaBreeding();

const FormComponent = ({ changeMode, pageMode }) => {
  const [itemToEdit, setItemToEdit] = useState(null);

  const { currentUser } = useSelector((state) => state.authReducer);
  const params = useParams();

  const formik = useFormik({
    initialValues: {
      intervention_date: "",
      intervention_done: [],
      other_intervention_done: "",
      type_larvicide: "",
      total_larvicide: "",
      total_larvicide_unit: "",
    },
    validate: (values) => {
      const errors = {};

      if (!values.intervention_date) {
        errors.intervention_date = "Tanggal Intervensi wajib diisi";
      }

      if (!values.intervention_done || values.intervention_done.length === 0) {
        errors.intervention_done =
          "Intervensi yang telah dilakukan wajib dipilih setidaknya satu";
      }

      return errors;
    },
    onSubmit: async (values) => {
      const payload = {
        vector_larva_identification_id: params.id,
        intervention_date: nullConverter(values.intervention_date),
        intervention_done: nullConverter(values.intervention_done),
        type_larvicide: nullConverter(values.type_larvicide),
        total_larvicide: nullConverter(values.total_larvicide),
        total_larvicide_unit: nullConverter(values.total_larvicide_unit),
      };

      switch (pageMode) {
        case PageMode.add:
          await LarvaBreedingService.add(payload)
            .then((res) => {
              changeMode(PageMode.view);
              successToastNotif(
                "Data Intervensi Perindukan Nyamuk berhasil ditambahkan"
              );
            })
            .catch((err) => {
              console.log(err);
              errorToastNotif(err);
            });
          break;
        case PageMode.edit:
          await LarvaBreedingService.update(payload, itemToEdit?.id)
            .then((res) => {
              changeMode(PageMode.view);
              successToastNotif(
                "Data Intervensi Perindukan Nyamuk berhasil diupdate"
              );
            })
            .catch((err) => {
              console.log(err);
              errorToastNotif(err);
            });
          break;
      }
    },
  });

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    const value = e.target.value;

    const newValues = isChecked
      ? [...formik.values.intervention_done, value]
      : formik.values.intervention_done.filter((item) => item !== value);
    formik.setFieldValue("intervention_done", newValues);
  };

  useEffect(() => {
    if (pageMode === PageMode.edit) {
      const selectedItem = JSON.parse(getDataFromLocalStorage("SELECTED_ITEM"));
      setItemToEdit(selectedItem);

      formik.setValues({
        intervention_date: selectedItem?.intervention_date,
        intervention_done: selectedItem?.intervention_done || [],
        other_intervention_done:
          selectedItem.intervention_done &&
            selectedItem.intervention_done.length > 0
            ? selectedItem.intervention_done[selectedItem.intervention_done.length - 1]
            : "",
        type_larvicide: selectedItem?.type_larvicide,
        total_larvicide: selectedItem?.total_larvicide,
        total_larvicide_unit: selectedItem?.total_larvicide_unit,
      });
    }
  }, []);

  return (
    <>
      <BreadcrumbNav
        items={[
          {
            label: "Intervensi Perindukan Nyamuk",
            onClick: () => changeMode(PageMode.view),
          },
          {
            label:
              pageMode === PageMode.add
                ? "Tambah Data Intervensi Perindukan Nyamuk"
                : "Edit Data Intervensi Perindukan Nyamuk",
          },
        ]}
      />
      <h1 className="page-title">Form Data Intervensi Perindukan Nyamuk</h1>
      <div className="form-grouping">
        <p
          className="warning"
          style={{ marginTop: "24px", marginBottom: "24px" }}
        >
          NOTE <span>*(WAJIB DIISI)</span>
        </p>

        <InputDate
          label={"Tanggal Kegiatan Intervensi"}
          name="intervention_date"
          placeholder={"Pilih Tanggal Intervensi"}
          dateFormat={"YYYY-MM-DD"}
          onChange={(e) => {
            const selectedDate = moment(e).format("YYYY-MM-DD");
            formik.handleChange("intervention_date")(selectedDate);
          }}
          onBlur={formik.handleBlur}
          value={formik.values.intervention_date}
          error={
            formik.errors.intervention_date && formik.touched.intervention_date
          }
          errorMessage={formik.errors.intervention_date}
          isRequired={true}
        />
        <InputCheckbox
          label="Intervensi yang telah dilakukan (Bisa memilih lebih dari satu)"
          name="intervention_done"
          options={[
            { label: "Larvasidasi", value: "Larvasidasi" },
            { label: "Penebaran Ikan", value: "Penebaran Ikan" },
            { label: "Modifikasi Lingkungan", value: "Modifikasi Lingkungan" },
            { label: "Manipulasi Lingkungan", value: "Manipulasi Lingkungan" },
            { label: "Lainnya", value: "Lainnya" },
          ]}
          onChange={(e) => handleCheckboxChange(e)}
          error={
            formik.errors.intervention_done && formik.touched.intervention_done
          }
          errorMessage={formik.errors.intervention_done}
          onBlur={formik.handleBlur}
          selectedOption={formik.values.intervention_done}
          isRequired={true}
        />

        {formik.values.intervention_done.includes("Lainnya") && (
          <InputField
            placeholder={"Intervensi Lainnya"}
            name={"other_intervention_done"}
            {...formik.getFieldProps("other_intervention_done")}
            error={
              formik.errors.other_intervention_done &&
              formik.touched.other_intervention_done
            }
            errorMessage={formik.errors.other_intervention_done}
          />
        )}

        {formik.values.intervention_done.includes("Larvasidasi") && (
          <>
            <InputSelect
              label={"Jenis Larvasidasi"}
              name={"type_larvicide"}
              placeholder={"Pilih Larvasidasi"}
              data={LarvacideTypeOptions}
              onChange={(e) => {
                const selectedValue = e ? e.value : "";
                formik.handleChange("type_larvicide")(selectedValue);
              }}
              onBlur={formik.handleBlur}
              value={LarvacideTypeOptions?.find(
                (item) => item.value === formik.values.type_larvicide
              )}
            />
            <div className="row">
              <div className="col-sm-6">
                <InputField
                  label={"Jumlah Larvasida"}
                  name={"total_larvicide"}
                  placeholder={"0"}
                  type={"number"}
                  isDecimal={true}
                  {...formik.getFieldProps("total_larvicide")}
                />
              </div>
              <div className="col-sm-6">
                <InputSelect
                  label={"Satuan"}
                  name={"total_larvicide_unit"}
                  placeholder={"Pilih Satuan"}
                  data={UnitOptions}
                  onChange={(e) => {
                    const selectedValue = e.value;
                    formik.handleChange("total_larvicide_unit")(selectedValue);
                  }}
                  onBlur={formik.handleBlur}
                  value={UnitOptions?.find(
                    (item) => item.value === formik.values.type_larvicide
                  )}
                />
              </div>
            </div>
          </>
        )}

        <div className="button_action">
          <Button
            variant={"tertiary"}
            size={"normal"}
            onClick={() => changeMode(PageMode.view)}
          >
            Batalkan
          </Button>
          <Button
            type={"submit"}
            variant={"primary"}
            size={"normal"}
            onClick={formik.handleSubmit}
            isDisabled={formik.isSubmitting}
          >
            Simpan Data
          </Button>
        </div>
      </div>
    </>
  );
};

export default FormComponent;
