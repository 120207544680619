import React from "react";
import { monthConverter } from "utils/common";
import { UserLevel } from "utils/constant";

const TableComponent = ({
  reportsData,
  currentLevel,
  tableRef,
  reportInfo,
}) => {
  const calculateTotal = () => {
    return reportsData.reduce(
      (total, item) => {
        total.r1_m_pcd += parseInt(item.r1_m_pcd ?? 0);
        total.r1_m_mtbs += parseInt(item.r1_m_mtbs ?? 0);
        total.r1_m_bumil += parseInt(item.r1_m_bumil ?? 0);
        total.r1_m_fup += parseInt(item.r1_m_fup ?? 0);
        total.r1_m_mbs += parseInt(item.r1_m_mbs ?? 0);
        total.r1_m_survkontak += parseInt(item.r1_m_survkontak ?? 0);
        total.r1_m_nonpe += parseInt(item.r1_m_nonpe ?? 0);
        total.r1_m_kunjrumah += parseInt(item.r1_m_kunjrumah ?? 0);
        total.r1_m_survmigrasi += parseInt(item.r1_m_survmigrasi ?? 0);
        total.r1_m_pusling += parseInt(item.r1_m_pusling ?? 0);
        total.r1_m_posyandu += parseInt(item.r1_m_posyandu ?? 0);
        total.r1_r_pcd += parseInt(item.r1_r_pcd ?? 0);
        total.r1_r_mtbs += parseInt(item.r1_r_mtbs ?? 0);
        total.r1_r_bumil += parseInt(item.r1_r_bumil ?? 0);
        total.r1_r_fup += parseInt(item.r1_r_fup ?? 0);
        total.r1_r_mbs += parseInt(item.r1_r_mbs ?? 0);
        total.r1_r_survkontak += parseInt(item.imersibtl ?? 0);
        total.r1_r_nonpe += parseInt(item.r1_r_nonpe ?? 0);
        total.r1_r_kunjrumah += parseInt(item.r1_r_kunjrumah ?? 0);
        total.r1_r_survmigrasi += parseInt(item.r1_r_survmigrasi ?? 0);
        total.r1_r_pusling += parseInt(item.r1_r_pusling ?? 0);
        total.r1_r_posyandu += parseInt(item.r1_r_posyandu ?? 0);
        total.r2_m_pcd += parseInt(item.r2_m_pcd ?? 0);
        total.r2_m_mtbs += parseInt(item.r2_m_mtbs ?? 0);
        total.r2_m_bumil += parseInt(item.r2_m_bumil ?? 0);
        total.r2_m_fup += parseInt(item.r2_m_fup ?? 0);
        total.r2_m_mbs += parseInt(item.r2_m_mbs ?? 0);
        total.r2_m_survkontak += parseInt(item.r2_m_survkontak ?? 0);
        total.r2_m_nonpe += parseInt(item.r2_m_nonpe ?? 0);
        total.r2_m_kunjrumah += parseInt(item.r2_m_kunjrumah ?? 0);
        total.r2_m_survmigrasi += parseInt(item.r2_m_survmigrasi ?? 0);
        total.r2_m_pusling += parseInt(item.r2_m_pusling ?? 0);
        total.r2_m_posyandu += parseInt(item.r2_m_posyandu ?? 0);
        total.r2_r_pcd += parseInt(item.r2_r_pcd ?? 0);
        total.r2_r_mtbs += parseInt(item.r2_r_mtbs ?? 0);
        total.r2_r_bumil += parseInt(item.r2_r_bumil ?? 0);
        total.r2_r_fup += parseInt(item.r2_r_fup ?? 0);
        total.r2_r_mbs += parseInt(item.r2_r_mbs ?? 0);
        total.r2_r_survkontak += parseInt(item.r2_r_survkontak ?? 0);
        total.r2_r_nonpe += parseInt(item.r2_r_nonpe ?? 0);
        total.r2_r_kunjrumah += parseInt(item.r2_r_kunjrumah ?? 0);
        total.r2_r_survmigrasi += parseInt(item.r2_r_survmigrasi ?? 0);
        total.r2_r_pusling += parseInt(item.r2_r_pusling ?? 0);
        total.r2_r_posyandu += parseInt(item.r2_r_posyandu ?? 0);
        total.total_m_pcd +=
          parseInt(item.r1_m_pcd ?? 0) + parseInt(item.r2_m_pcd ?? 0);
        total.total_m_mtbs +=
          parseInt(item.r1_m_mtbs ?? 0) + parseInt(item.r2_m_mtbs ?? 0);
        total.total_m_bumil +=
          parseInt(item.r1_m_bumil ?? 0) + parseInt(item.r2_m_bumil ?? 0);
        total.total_m_fup +=
          parseInt(item.r1_m_fup ?? 0) + parseInt(item.r2_m_fup ?? 0);
        total.total_m_mbs +=
          parseInt(item.r1_m_mbs ?? 0) + parseInt(item.r2_m_mbs ?? 0);
        total.total_m_survkontak +=
          parseInt(item.r1_m_survkontak ?? 0) + parseInt(item.r2_m_survkontak ?? 0);
        total.total_m_nonpe +=
          parseInt(item.r1_m_nonpe ?? 0) + parseInt(item.r2_m_nonpe ?? 0);
        total.total_m_kunjrumah +=
          parseInt(item.r1_m_kunjrumah ?? 0) + parseInt(item.r2_m_kunjrumah ?? 0);
        total.total_m_survmigrasi +=
          parseInt(item.r1_m_survmigrasi ?? 0) + parseInt(item.r2_m_survmigrasi ?? 0);
        total.total_m_pusling +=
          parseInt(item.r1_m_pusling ?? 0) + parseInt(item.r2_m_pusling ?? 0);
        total.total_m_posyandu +=
          parseInt(item.r1_m_posyandu ?? 0) + parseInt(item.r2_m_posyandu ?? 0);
        total.total_r_pcd +=
          parseInt(item.r1_r_pcd ?? 0) + parseInt(item.r2_r_pcd ?? 0);
        total.total_r_mtbs +=
          parseInt(item.r1_r_mtbs ?? 0) + parseInt(item.r2_r_mtbs ?? 0);
        total.total_r_bumil +=
          parseInt(item.r1_r_bumil ?? 0) + parseInt(item.r2_r_bumil ?? 0);
        total.total_r_fup +=
          parseInt(item.r1_r_fup ?? 0) + parseInt(item.r2_r_fup ?? 0);
        total.total_r_mbs +=
          parseInt(item.r1_r_mbs ?? 0) + parseInt(item.r2_r_mbs ?? 0);
        total.total_r_survkontak +=
          parseInt(item.r1_r_survkontak ?? 0) + parseInt(item.r2_r_survkontak ?? 0);
        total.total_r_nonpe +=
          parseInt(item.r1_r_nonpe ?? 0) + parseInt(item.r2_r_nonpe ?? 0);
        total.total_r_kunjrumah +=
          parseInt(item.r1_r_kunjrumah ?? 0) + parseInt(item.r2_r_kunjrumah ?? 0);
        total.total_r_survmigrasi +=
          parseInt(item.r1_r_survmigrasi ?? 0) + parseInt(item.r2_r_survmigrasi ?? 0);
        total.total_r_pusling +=
          parseInt(item.r1_r_pusling ?? 0) + parseInt(item.r2_r_pusling ?? 0);
        total.total_r_posyandu +=
          parseInt(item.r1_r_posyandu ?? 0) + parseInt(item.r2_r_posyandu ?? 0);
        total.total_pcd +=
          parseInt(item.r1_m_pcd ?? 0) +
            parseInt(item.r1_r_pcd ?? 0) +
            parseInt(item?.r2_m_pcd ?? 0) +
            parseInt(item?.r2_r_pcd ?? 0);
        total.total_mtbs +=
          parseInt(item.r1_m_mtbs ?? 0) +
            parseInt(item.r1_r_mtbs ?? 0) +
            parseInt(item?.r2_m_mtbs ?? 0) +
            parseInt(item?.r2_r_mtbs ?? 0);
        total.total_bumil +=
          parseInt(item.r1_m_bumil ?? 0) +
            parseInt(item.r1_r_bumil ?? 0) +
            parseInt(item?.r2_m_bumil ?? 0) +
            parseInt(item?.r2_r_bumil ?? 0);
        total.total_fup +=
          parseInt(item.r1_m_fup ?? 0) +
            parseInt(item.r1_r_fup ?? 0) +
            parseInt(item?.r2_m_fup ?? 0) +
            parseInt(item?.r2_r_fup ?? 0);
        total.total_mbs +=
          parseInt(item.r1_m_mbs ?? 0) +
            parseInt(item.r1_r_mbs ?? 0) +
            parseInt(item?.r2_m_mbs ?? 0) +
            parseInt(item?.r2_r_mbs ?? 0);
        total.total_survkontak +=
          parseInt(item.r1_m_survkontak ?? 0) +
            parseInt(item.r1_r_survkontak ?? 0) +
            parseInt(item?.r2_m_survkontak ?? 0) +
            parseInt(item?.r2_r_survkontak ?? 0);
        total.total_nonpe +=
          parseInt(item.r1_m_nonpe ?? 0) +
            parseInt(item.r1_r_nonpe ?? 0) +
            parseInt(item?.r2_m_nonpe ?? 0) +
            parseInt(item?.r2_r_nonpe ?? 0);
        total.total_kunjrumah +=
          parseInt(item.r1_m_kunjrumah ?? 0) +
            parseInt(item.r1_r_kunjrumah ?? 0) +
            parseInt(item?.r2_m_kunjrumah ?? 0) +
            parseInt(item?.r2_r_kunjrumah ?? 0);
        total.total_survmigrasi +=
          parseInt(item?.r1_m_survmigrasi ?? 0) +
          parseInt(item?.r1_r_survmigrasi ?? 0) +
          parseInt(item?.r2_m_survmigrasi ?? 0) +
          parseInt(item?.r2_r_survmigrasi ?? 0);
        total.total_pusling +=
          parseInt(item.r1_m_pusling ?? 0) +
            parseInt(item.r1_r_pusling ?? 0) +
            parseInt(item?.r2_m_pusling ?? 0) +
            parseInt(item?.r2_r_pusling ?? 0);
        total.total_posyandu +=
          parseInt(item.r1_m_posyandu ?? 0) +
            parseInt(item.r1_r_posyandu ?? 0) +
            parseInt(item?.r2_m_posyandu ?? 0) +
            parseInt(item?.r2_r_posyandu ?? 0);

        return total;
      },
      {
        r1_m_pcd: 0,
        r1_m_mtbs: 0,
        r1_m_bumil: 0,
        r1_m_fup: 0,
        r1_m_mbs: 0,
        r1_m_survkontak: 0,
        r1_m_nonpe: 0,
        r1_m_kunjrumah: 0,
        r1_m_survmigrasi: 0,
        r1_m_pusling: 0,
        r1_m_posyandu: 0,
        r1_r_pcd: 0,
        r1_r_mtbs: 0,
        r1_r_bumil: 0,
        r1_r_fup: 0,
        r1_r_mbs: 0,
        r1_r_survkontak: 0,
        r1_r_nonpe: 0,
        r1_r_kunjrumah: 0,
        r1_r_survmigrasi: 0,
        r1_r_pusling: 0,
        r1_r_posyandu: 0,
        r2_m_pcd: 0,
        r2_m_mtbs: 0,
        r2_m_bumil: 0,
        r2_m_fup: 0,
        r2_m_mbs: 0,
        r2_m_survkontak: 0,
        r2_m_nonpe: 0,
        r2_m_kunjrumah: 0,
        r2_m_survmigrasi: 0,
        r2_m_pusling: 0,
        r2_m_posyandu: 0,
        r2_r_pcd: 0,
        r2_r_mtbs: 0,
        r2_r_bumil: 0,
        r2_r_fup: 0,
        r2_r_mbs: 0,
        r2_r_survkontak: 0,
        r2_r_nonpe: 0,
        r2_r_kunjrumah: 0,
        r2_r_survmigrasi: 0,
        r2_r_pusling: 0,
        r2_r_posyandu: 0,
        total_m_pcd: 0,
        total_m_mtbs: 0,
        total_m_bumil: 0,
        total_m_fup: 0,
        total_m_mbs: 0,
        total_m_survkontak: 0,
        total_m_nonpe: 0,
        total_m_kunjrumah: 0,
        total_m_survmigrasi: 0,
        total_m_pusling: 0,
        total_m_posyandu: 0,
        total_r_pcd: 0,
        total_r_mtbs: 0,
        total_r_bumil: 0,
        total_r_fup: 0,
        total_r_mbs: 0,
        total_r_survkontak: 0,
        total_r_nonpe: 0,
        total_r_kunjrumah: 0,
        total_r_survmigrasi: 0,
        total_r_pusling: 0,
        total_r_posyandu: 0,
        total_pcd: 0,
        total_mtbs: 0,
        total_bumil: 0,
        total_fup: 0,
        total_mbs: 0,
        total_survkontak: 0,
        total_nonpe: 0,
        total_kunjrumah: 0,
        total_survmigrasi: 0,
        total_pusling: 0,
        total_posyandu: 0,
      }
    );
  };

  
  const total = calculateTotal();

  let levelName = "";
  if (currentLevel === "national") {
    levelName = "Nasional";
  } else if (currentLevel === "province") {
    levelName = "Provinsi";
  } else if (currentLevel === "district") {
    levelName = "Kabupaten/Kota";
  } else {
    levelName = "Fasyankes";
  }

  let periodeName = "";
  if (reportInfo?.statusPeriode === "yearly") {
    periodeName = "Tahunan";
  } else {
    periodeName = "Bulanan";
  }

  let data = reportInfo?.fasyankes;
  let parts = data?.split(",");
  let faskesName = parts?.map(function (part) {
    return part?.split("|")[1];
  });
  let result = faskesName?.join(", ");

  return (
    <div
      className="table-responsive"
      style={{ height: "560px", overflow: "scroll" }}
      ref={tableRef}
    >
      <table>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none" }}>
            <h3>SISMAL v3, REGMAL 2 PUBLIC</h3>
          </th>
        </tr>
      </table>
      <table>
        <thead>
          <tr style={{ border: "none" }}>
            <th
              width="200"
              style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}
            >
              Periode Laporan
            </th>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              : {periodeName}
            </th>
          </tr>
          <tr style={{ border: "none" }}>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              Tahun
            </th>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              : {reportInfo?.periode?.year}
            </th>
          </tr>
          <tr style={{ border: "none" }}>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              Bulan
            </th>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              : {monthConverter(reportInfo?.periode?.month_start)} -{" "}
              {monthConverter(reportInfo?.periode?.month_end)}
            </th>
          </tr>
          <tr style={{ border: "none" }}>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              Level
            </th>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              : {levelName}
            </th>
          </tr>
          <tr style={{ border: "none" }}>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              Provinsi
            </th>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              :{" "}
              {!reportInfo?.province
                ? "Semua Provinsi"
                : reportInfo?.province?.split("|")[1]}
            </th>
          </tr>
          <tr style={{ border: "none" }}>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              Kab/Kota
            </th>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              :{" "}
              {!reportInfo?.district
                ? "Semua Kab/Kota"
                : reportInfo?.district?.split("|")[1]}
            </th>
          </tr>
          <tr style={{ border: "none" }}>
            <th style={{ border: "none", paddingTop: 0 }}>Fasyankes</th>
            <th style={{ border: "none", paddingTop: 0 }}>
              : {!reportInfo?.fasyankes ? "Semua Fasyankes" : result}
            </th>
          </tr>
        </thead>
      </table>
      <table>
        <thead>
          <tr>
            <th rowSpan={2}>No.</th>
            {currentLevel === UserLevel.National.toLowerCase() && (
              <>
                <th rowSpan={2}>Provinsi</th>
                <th rowSpan={2}>Kabupaten/Kota</th>
              </>
            )}
            {currentLevel !== UserLevel.National.toLowerCase() && (
              <th rowSpan={2}>Wilayah</th>
            )}
            <th colSpan={11}>Positif Mikroskop</th>
            <th colSpan={11}>Positif RDT</th>
            <th colSpan={11}>Negatif Mikroskop</th>
            <th colSpan={11}>Negatif RDT</th>
            <th colSpan={11}>Mikroskop (Positif + Negatif)</th>
            <th colSpan={11}>RDT (Positif + Negatif)</th>
            <th colSpan={11}>TOTAL (Positif + Negatif)</th>
          </tr>
          <tr>
            {/* Positif Mikroskop (Regmal 1) */}
            <th>PCD</th>
            <th>Skrining Balita/MTBS</th>
            <th>Skrining Bumil</th>
            <th>FUP</th>
            <th>MBS</th>
            <th>Survei Kontak</th>
            <th>Survei Kontak Non PE</th>
            <th>Kunjungan Rumah</th>
            <th>Surveilans Migrasi</th>
            <th>Pusling</th>
            <th>Posyandu</th>
            {/* Positif RDT (Regmal 1) */}
            <th>PCD</th>
            <th>Skrining Balita/MTBS</th>
            <th>Skrining Bumil</th>
            <th>FUP</th>
            <th>MBS</th>
            <th>Survei Kontak</th>
            <th>Survei Kontak Non PE</th>
            <th>Kunjungan Rumah</th>
            <th>Surveilans Migrasi</th>
            <th>Pusling</th>
            <th>Posyandu</th>
            {/* Negatif Mikroskop (Regmal 2) */}
            <th>PCD</th>
            <th>Skrining Balita/MTBS</th>
            <th>Skrining Bumil</th>
            <th>FUP</th>
            <th>MBS</th>
            <th>Survei Kontak</th>
            <th>Survei Kontak Non PE</th>
            <th>Kunjungan Rumah</th>
            <th>Surveilans Migrasi</th>
            <th>Pusling</th>
            <th>Posyandu</th>
            {/* Negatif RDT (Regmal 2) */}
            <th>PCD</th>
            <th>Skrining Balita/MTBS</th>
            <th>Skrining Bumil</th>
            <th>FUP</th>
            <th>MBS</th>
            <th>Survei Kontak</th>
            <th>Survei Kontak Non PE</th>
            <th>Kunjungan Rumah</th>
            <th>Surveilans Migrasi</th>
            <th>Pusling</th>
            <th>Posyandu</th>
            {/* Mikroskop (Positif + Negatif) */}
            <th>PCD</th>
            <th>Skrining Balita/MTBS</th>
            <th>Skrining Bumil</th>
            <th>FUP</th>
            <th>MBS</th>
            <th>Survei Kontak</th>
            <th>Survei Kontak Non PE</th>
            <th>Kunjungan Rumah</th>
            <th>Surveilans Migrasi</th>
            <th>Pusling</th>
            <th>Posyandu</th>
            {/* RDT (Positif + Negatif) */}
            <th>PCD</th>
            <th>Skrining Balita/MTBS</th>
            <th>Skrining Bumil</th>
            <th>FUP</th>
            <th>MBS</th>
            <th>Survei Kontak</th>
            <th>Survei Kontak Non PE</th>
            <th>Kunjungan Rumah</th>
            <th>Surveilans Migrasi</th>
            <th>Pusling</th>
            <th>Posyandu</th>
            {/* TOTAL (Positif + Negatif) */}
            <th>PCD</th>
            <th>Skrining Balita/MTBS</th>
            <th>Skrining Bumil</th>
            <th>FUP</th>
            <th>MBS</th>
            <th>Survei Kontak</th>
            <th>Survei Kontak Non PE</th>
            <th>Kunjungan Rumah</th>
            <th>Surveilans Migrasi</th>
            <th>Pusling</th>
            <th>Posyandu</th>
          </tr>
        </thead>
        <tbody>
          {reportsData ? (
            reportsData?.map((item, index) => (
              <tr key={item?.id}>
                <td>{index + 1}</td>
                {currentLevel === UserLevel.National.toLowerCase() && (
                  <>
                    <td>{item?.namaprop}</td>
                    <td>{item?.namakab}</td>
                  </>
                )}
                {currentLevel !== UserLevel.National.toLowerCase() && (
                  <td>{item?.wilayah}</td>
                )}
                {/* positif mikroskop regmal 1 */}
                <td>{item?.r1_m_pcd ?? 0}</td>
                <td>{item?.r1_m_mtbs ?? 0}</td>
                <td>{item?.r1_m_bumil ?? 0}</td>
                <td>{item?.r1_m_fup ?? 0}</td>
                <td>{item?.r1_m_mbs ?? 0}</td>
                <td>{item?.r1_m_survkontak ?? 0}</td>
                <td>{item?.r1_m_nonpe ?? 0}</td>
                <td>{item?.r1_m_kunjrumah ?? 0}</td>
                <td>{item?.r1_m_survmigrasi ?? 0}</td>
                <td>{item?.r1_m_pusling ?? 0}</td>
                <td>{item?.r1_m_posyandu ?? 0}</td>
                {/* positif rdt regmal 1 */}
                <td>{item?.r1_r_pcd ?? 0}</td>
                <td>{item?.r1_r_mtbs ?? 0}</td>
                <td>{item?.r1_r_bumil ?? 0}</td>
                <td>{item?.r1_r_fup ?? 0}</td>
                <td>{item?.r1_r_mbs ?? 0}</td>
                <td>{item?.r1_r_survkontak ?? 0}</td>
                <td>{item?.r1_r_nonpe ?? 0}</td>
                <td>{item?.r1_r_kunjrumah ?? 0}</td>
                <td>{item?.r1_r_survmigrasi ?? 0}</td>
                <td>{item?.r1_r_pusling ?? 0}</td>
                <td>{item?.r1_r_posyandu ?? 0}</td>
                {/* negatif mikroskop regmal 2 */}
                <td>{item?.r2_m_pcd ?? 0}</td>
                <td>{item?.r2_m_mtbs ?? 0}</td>
                <td>{item?.r2_m_bumil ?? 0}</td>
                <td>{item?.r2_m_fup ?? 0}</td>
                <td>{item?.r2_m_mbs ?? 0}</td>
                <td>{item?.r2_m_survkontak ?? 0}</td>
                <td>{item?.r2_m_nonpe ?? 0}</td>
                <td>{item?.r2_m_kunjrumah ?? 0}</td>
                <td>{item?.r2_m_survmigrasi ?? 0}</td>
                <td>{item?.r2_m_pusling ?? 0}</td>
                <td>{item?.r2_m_posyandu ?? 0}</td>
                {/* negatif rdt regmal 2 */}
                <td>{item?.r2_r_pcd ?? 0}</td>
                <td>{item?.r2_r_mtbs ?? 0}</td>
                <td>{item?.r2_r_bumil ?? 0}</td>
                <td>{item?.r2_r_fup ?? 0}</td>
                <td>{item?.r2_r_mbs ?? 0}</td>
                <td>{item?.r2_r_survkontak ?? 0}</td>
                <td>{item?.r2_r_nonpe ?? 0}</td>
                <td>{item?.r2_r_kunjrumah ?? 0}</td>
                <td>{item?.r2_r_survmigrasi ?? 0}</td>
                <td>{item?.r2_r_pusling ?? 0}</td>
                <td>{item?.r2_r_posyandu ?? 0}</td>
                {/* mikroskop pos + neg */}
                <td>
                  {parseInt(item?.r1_m_pcd ?? 0) +
                    parseInt(item?.r2_m_pcd ?? 0)}
                </td>
                <td>
                  {parseInt(item?.r1_m_mtbs ?? 0) +
                    parseInt(item?.r2_m_mtbs ?? 0)}
                </td>
                <td>
                  {parseInt(item?.r1_m_bumil ?? 0) +
                    parseInt(item?.r2_m_bumil ?? 0)}
                </td>
                <td>
                  {parseInt(item?.r1_m_fup ?? 0) +
                    parseInt(item?.r2_m_fup ?? 0)}
                </td>
                <td>
                  {parseInt(item?.r1_m_mbs ?? 0) +
                    parseInt(item?.r2_m_mbs ?? 0)}
                </td>
                <td>
                  {parseInt(item?.r1_m_survkontak ?? 0) +
                    parseInt(item?.r2_m_survkontak ?? 0)}
                </td>
                <td>
                  {parseInt(item?.r1_m_nonpe ?? 0) +
                    parseInt(item?.r2_m_nonpe ?? 0)}
                </td>
                <td>
                  {parseInt(item?.r1_m_kunjrumah ?? 0) +
                    parseInt(item?.r2_m_kunjrumah ?? 0)}
                </td>
                <td>
                  {parseInt(item?.r1_m_survmigrasi ?? 0) +
                    parseInt(item?.r2_m_survmigrasi ?? 0)}
                </td>
                <td>
                  {parseInt(item?.r1_m_pusling ?? 0) +
                    parseInt(item?.r2_m_pusling ?? 0)}
                </td>
                <td>
                  {parseInt(item?.r1_m_posyandu ?? 0) +
                    parseInt(item?.r2_m_posyandu ?? 0)}
                </td>
                {/* rdt pos + neg */}
                <td>
                  {parseInt(item?.r1_r_pcd ?? 0) +
                    parseInt(item?.r2_r_pcd ?? 0)}
                </td>
                <td>
                  {parseInt(item?.r1_r_mtbs ?? 0) +
                    parseInt(item?.r2_r_mtbs ?? 0)}
                </td>
                <td>
                  {parseInt(item?.r1_r_bumil ?? 0) +
                    parseInt(item?.r2_r_bumil ?? 0)}
                </td>
                <td>
                  {parseInt(item?.r1_r_fup ?? 0) +
                    parseInt(item?.r2_r_fup ?? 0)}
                </td>
                <td>
                  {parseInt(item?.r1_r_mbs ?? 0) +
                    parseInt(item?.r2_r_mbs ?? 0)}
                </td>
                <td>
                  {parseInt(item?.r1_r_survkontak ?? 0) +
                    parseInt(item?.r2_r_survkontak ?? 0)}
                </td>
                <td>
                  {parseInt(item?.r1_r_nonpe ?? 0) +
                    parseInt(item?.r2_r_nonpe ?? 0)}
                </td>
                <td>
                  {parseInt(item?.r1_r_kunjrumah ?? 0) +
                    parseInt(item?.r2_r_kunjrumah ?? 0)}
                </td>
                <td>
                  {parseInt(item?.r1_r_survmigrasi ?? 0) +
                    parseInt(item?.r2_r_survmigrasi ?? 0)}
                </td>
                <td>
                  {parseInt(item?.r1_r_pusling ?? 0) +
                    parseInt(item?.r2_r_pusling ?? 0)}
                </td>
                <td>
                  {parseInt(item?.r1_r_posyandu ?? 0) +
                    parseInt(item?.r2_r_posyandu ?? 0)}
                </td>
                {/* total pos + neg */}
                <td>
                  {parseInt(item?.r1_m_pcd ?? 0) +
                    parseInt(item?.r1_r_pcd ?? 0) +
                    parseInt(item?.r2_m_pcd ?? 0) +
                    parseInt(item?.r2_r_pcd ?? 0)}
                </td>
                <td>
                  {parseInt(item?.r1_m_mtbs ?? 0) +
                    parseInt(item?.r1_r_mtbs ?? 0) +
                    parseInt(item?.r2_m_mtbs ?? 0) +
                    parseInt(item?.r2_r_mtbs ?? 0)}
                </td>
                <td>
                  {parseInt(item?.r1_m_bumil ?? 0) +
                    parseInt(item?.r1_r_bumil ?? 0) +
                    parseInt(item?.r2_m_bumil ?? 0) +
                    parseInt(item?.r2_r_bumil ?? 0)}
                </td>
                <td>
                  {parseInt(item?.r1_m_fup ?? 0) +
                    parseInt(item?.r1_r_fup ?? 0) +
                    parseInt(item?.r2_m_fup ?? 0) +
                    parseInt(item?.r2_r_fup ?? 0)}
                </td>
                <td>
                  {parseInt(item?.r1_m_mbs ?? 0) +
                    parseInt(item?.r1_r_mbs ?? 0) +
                    parseInt(item?.r2_m_mbs ?? 0) +
                    parseInt(item?.r2_r_mbs ?? 0)}
                </td>
                <td>
                  {parseInt(item?.r1_m_survkontak ?? 0) +
                    parseInt(item?.r1_r_survkontak ?? 0) +
                    parseInt(item?.r2_m_survkontak ?? 0) +
                    parseInt(item?.r2_r_survkontak ?? 0)}
                </td>
                <td>
                  {parseInt(item?.r1_m_nonpe ?? 0) +
                    parseInt(item?.r1_r_nonpe ?? 0) +
                    parseInt(item?.r2_m_nonpe ?? 0) +
                    parseInt(item?.r2_r_nonpe ?? 0)}
                </td>
                <td>
                  {parseInt(item?.r1_m_kunjrumah ?? 0) +
                    parseInt(item?.r1_r_kunjrumah ?? 0) +
                    parseInt(item?.r2_m_kunjrumah ?? 0) +
                    parseInt(item?.r2_r_kunjrumah ?? 0)}
                </td>
                <td>
                  {parseInt(item?.r1_m_survmigrasi ?? 0) +
                    parseInt(item?.r1_r_survmigrasi ?? 0) +
                    parseInt(item?.r2_m_survmigrasi ?? 0) +
                    parseInt(item?.r2_r_survmigrasi ?? 0)}
                </td>
                <td>
                  {parseInt(item?.r1_m_pusling ?? 0) +
                    parseInt(item?.r1_r_pusling ?? 0) +
                    parseInt(item?.r2_m_pusling ?? 0) +
                    parseInt(item?.r2_r_pusling ?? 0)}
                </td>
                <td>
                  {parseInt(item?.r1_m_posyandu ?? 0) +
                    parseInt(item?.r1_r_posyandu ?? 0) +
                    parseInt(item?.r2_m_posyandu ?? 0) +
                    parseInt(item?.r2_r_posyandu ?? 0)}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={56}>Tidak Ada Data</td>
            </tr>
          )}
          <tr style={{ backgroundColor: "#43766C" }}>
            {currentLevel === UserLevel.National.toLowerCase() ? (
              <td style={{ color: "#FFF" }} colSpan={3}>
                Total
              </td>
            ) : (
              <td style={{ color: "#FFF" }} colSpan={2}>
                Total
              </td>
            )}
            <td style={{ color: "#FFF" }}>{total.r1_m_pcd}</td>
            <td style={{ color: "#FFF" }}>{total.r1_m_mtbs}</td>
            <td style={{ color: "#FFF" }}>{total.r1_m_bumil}</td>
            <td style={{ color: "#FFF" }}>{total.r1_m_fup}</td>
            <td style={{ color: "#FFF" }}>{total.r1_m_mbs}</td>
            <td style={{ color: "#FFF" }}>{total.r1_m_survkontak}</td>
            <td style={{ color: "#FFF" }}>{total.r1_m_nonpe}</td>
            <td style={{ color: "#FFF" }}>{total.r1_m_kunjrumah}</td>
            <td style={{ color: "#FFF" }}>{total.r1_m_survmigrasi}</td>
            <td style={{ color: "#FFF" }}>{total.r1_m_pusling}</td>
            <td style={{ color: "#FFF" }}>{total.r1_m_posyandu}</td>
            <td style={{ color: "#FFF" }}>{total.r1_r_pcd}</td>
            <td style={{ color: "#FFF" }}>{total.r1_r_mtbs}</td>
            <td style={{ color: "#FFF" }}>{total.r1_r_bumil}</td>
            <td style={{ color: "#FFF" }}>{total.r1_r_fup}</td>
            <td style={{ color: "#FFF" }}>{total.r1_r_mbs}</td>
            <td style={{ color: "#FFF" }}>{total.r1_r_survkontak}</td>
            <td style={{ color: "#FFF" }}>{total.r1_r_nonpe}</td>
            <td style={{ color: "#FFF" }}>{total.r1_r_kunjrumah}</td>
            <td style={{ color: "#FFF" }}>{total.r1_r_survmigrasi}</td>
            <td style={{ color: "#FFF" }}>{total.r1_r_pusling}</td>
            <td style={{ color: "#FFF" }}>{total.r1_r_posyandu}</td>
            <td style={{ color: "#FFF" }}>{total.r2_m_pcd}</td>
            <td style={{ color: "#FFF" }}>{total.r2_m_mtbs}</td>
            <td style={{ color: "#FFF" }}>{total.r2_m_bumil}</td>
            <td style={{ color: "#FFF" }}>{total.r2_m_fup}</td>
            <td style={{ color: "#FFF" }}>{total.r2_m_mbs}</td>
            <td style={{ color: "#FFF" }}>{total.r2_m_survkontak}</td>
            <td style={{ color: "#FFF" }}>{total.r2_m_nonpe}</td>
            <td style={{ color: "#FFF" }}>{total.r2_m_kunjrumah}</td>
            <td style={{ color: "#FFF" }}>{total.r2_m_survmigrasi}</td>
            <td style={{ color: "#FFF" }}>{total.r2_m_pusling}</td>
            <td style={{ color: "#FFF" }}>{total.r2_m_posyandu}</td>
            <td style={{ color: "#FFF" }}>{total.r2_r_pcd}</td>
            <td style={{ color: "#FFF" }}>{total.r2_r_mtbs}</td>
            <td style={{ color: "#FFF" }}>{total.r2_r_bumil}</td>
            <td style={{ color: "#FFF" }}>{total.r2_r_fup}</td>
            <td style={{ color: "#FFF" }}>{total.r2_r_mbs}</td>
            <td style={{ color: "#FFF" }}>{total.r2_r_survkontak}</td>
            <td style={{ color: "#FFF" }}>{total.r2_r_nonpe}</td>
            <td style={{ color: "#FFF" }}>{total.r2_r_kunjrumah}</td>
            <td style={{ color: "#FFF" }}>{total.r2_r_survmigrasi}</td>
            <td style={{ color: "#FFF" }}>{total.r2_r_pusling}</td>
            <td style={{ color: "#FFF" }}>{total.r2_r_posyandu}</td>
            <td style={{ color: "#FFF" }}>{total.total_m_pcd}</td>
            <td style={{ color: "#FFF" }}>{total.total_m_mtbs}</td>
            <td style={{ color: "#FFF" }}>{total.total_m_bumil}</td>
            <td style={{ color: "#FFF" }}>{total.total_m_fup}</td>
            <td style={{ color: "#FFF" }}>{total.total_m_mbs}</td>
            <td style={{ color: "#FFF" }}>{total.total_m_survkontak}</td>
            <td style={{ color: "#FFF" }}>{total.total_m_nonpe}</td>
            <td style={{ color: "#FFF" }}>{total.total_m_kunjrumah}</td>
            <td style={{ color: "#FFF" }}>{total.total_m_survmigrasi}</td>
            <td style={{ color: "#FFF" }}>{total.total_m_pusling}</td>
            <td style={{ color: "#FFF" }}>{total.total_m_posyandu}</td>
            <td style={{ color: "#FFF" }}>{total.total_r_pcd}</td>
            <td style={{ color: "#FFF" }}>{total.total_r_mtbs}</td>
            <td style={{ color: "#FFF" }}>{total.total_r_bumil}</td>
            <td style={{ color: "#FFF" }}>{total.total_r_fup}</td>
            <td style={{ color: "#FFF" }}>{total.total_r_mbs}</td>
            <td style={{ color: "#FFF" }}>{total.total_r_survkontak}</td>
            <td style={{ color: "#FFF" }}>{total.total_r_nonpe}</td>
            <td style={{ color: "#FFF" }}>{total.total_r_kunjrumah}</td>
            <td style={{ color: "#FFF" }}>{total.total_r_survmigrasi}</td>
            <td style={{ color: "#FFF" }}>{total.total_r_pusling}</td>
            <td style={{ color: "#FFF" }}>{total.total_r_posyandu}</td>
            <td style={{ color: "#FFF" }}>{total.total_pcd}</td>
            <td style={{ color: "#FFF" }}>{total.total_mtbs}</td>
            <td style={{ color: "#FFF" }}>{total.total_bumil}</td>
            <td style={{ color: "#FFF" }}>{total.total_fup}</td>
            <td style={{ color: "#FFF" }}>{total.total_mbs}</td>
            <td style={{ color: "#FFF" }}>{total.total_survkontak}</td>
            <td style={{ color: "#FFF" }}>{total.total_nonpe}</td>
            <td style={{ color: "#FFF" }}>{total.total_kunjrumah}</td>
            <td style={{ color: "#FFF" }}>{total.total_survmigrasi}</td>
            <td style={{ color: "#FFF" }}>{total.total_pusling}</td>
            <td style={{ color: "#FFF" }}>{total.total_posyandu}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default TableComponent;
