const {
  ADD_POPULATION,
  EDIT_POPULATION,
  DELETE_POPULATION,
  ITEM_POPULATION_TO_EDIT,
  CLEAR_POPULATION,
} = require("redux/actions/actionType");

const initialState = {
  urbanVillages: [],
  itemToEdit: null,
  modalMode: null,
};

const populationReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_POPULATION:
      return {
        ...state,
        urbanVillages: [...state.urbanVillages, action.payload],
      };
    case EDIT_POPULATION:
      const updatedUrbanVillages = state.urbanVillages.map((urbanVillage) =>
        urbanVillage.id === action.payload.id ? action.payload : urbanVillage
      );

      return {
        ...state,
        urbanVillages: updatedUrbanVillages,
      };
    case DELETE_POPULATION:
      const filterUrbanVillages = state.urbanVillages.filter(
        (urbanVillage) => urbanVillage.id !== action.payload
      );
      return {
        ...state,
        urbanVillages: filterUrbanVillages,
      };
    case ITEM_POPULATION_TO_EDIT:
      return {
        ...state,
        itemToEdit: action.payload,
      };
    case CLEAR_POPULATION:
      return {
        ...state,
        urbanVillages: [],
      };
    default:
      return state;
  }
};

export default populationReducer;
