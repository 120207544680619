import React, { useState } from "react";
import { PageMode } from "utils/constant";
import "./index.scss";
import { BreadcrumbNav } from "components/molecules";
import Button from "components/atoms/Button";
import InputDate from "components/atoms/InputDate";
import TableComponent from "./components/Table";
import FormComponent from "./components/Form";

const CadreMMPPerformance = () => {
  const [pageMode, setPageMode] = useState(PageMode.view);
  const [selectedItem, setSelectedItem] = useState(null);

  const handlePageModeChange = (page) => {
    setPageMode(page);
  };

  const handleEditData = (item) => {
    setPageMode(PageMode.edit);
    setSelectedItem(item);
  };

  return (
    <div className="dashboard-wrapper">
      <BreadcrumbNav
        items={[
          { label: "Data MMP", link: "/mmp" },
          { label: "Data Kinerja Kader", link: "#" },
        ]}
      />

      <div className="custom-page-header">
        <h1 className="page-title">
          {pageMode === PageMode.add
            ? "Tambah"
            : pageMode === PageMode.edit
              ? "Edit"
              : ""}{" "}
          Data Kinerja Kader MMP Bulanan
        </h1>
        {pageMode === PageMode.view && (
          <Button
            variant={"primary"}
            size={"normal"}
            onClick={() => handlePageModeChange(PageMode.add)}
          >
            Tambah Data Kinerja Kader
          </Button>
        )}
      </div>

      {pageMode === PageMode.view && (
        <TableComponent
          handleEditData={handleEditData}
          handlePageModeChange={handlePageModeChange}
          pageMode={pageMode}
        />
      )}
      {(pageMode === PageMode.add || pageMode === PageMode.edit) && (
        <FormComponent
          selectedItem={selectedItem}
          handlePageModeChange={handlePageModeChange}
          pageMode={pageMode}
        />
      )}
    </div>
  );
};

export default CadreMMPPerformance;
