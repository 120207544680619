import { errHandler } from "../utils/common";
import api from "../utils/api";
export default class PeCases {
  getAll = (
    opt = {
      page: 1,
      limit: 10,
      searchBy: null,
      searchValue: null,
      includeRegmal: false,
    }
  ) => {
    return new Promise((resolve, reject) => {
      api
        .get("/api/pe_cases/id", { params: opt })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  getOne = (id) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/pe_case/id/${id}`)
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  add = (payload) => {
    return new Promise((resolve, reject) => {
      api
        .post("/api/pe_case?device=Online", payload)
        .then((res) => {
          if (payload.case_classification.includes("Import")) {
            if (payload.district_id) {
              api.post("/api/notification/incr/import-case", {
                regionId: payload.district_id?.split("|")[0],
              });
            }
          }
          return res;
        })
        .then((res) => {
          if (payload.case_classification.includes("Indigenous")) {
            if (payload.district_id) {
              api.post("/api/notification/incr/indigenous-validation", {
                regionId: payload.district_id?.split("|")[0],
              });
            }
          }
          return res;
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };
  update = (payload, peCasesId) => {
    return new Promise((resolve, reject) => {
      api
        .patch(`/api/pe_case/id/${peCasesId}?device=Online`, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };
  remove = (peCasesId) => {
    return new Promise((resolve, reject) => {
      api
        .delete(`/api/pe_case/${peCasesId}?device=Online`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };
}
