import React from "react";
import styles from "./Button.module.scss";
import classNames from "classnames";

const Button = ({
  type,
  variant,
  size,
  children,
  onClick,
  isDisabled,
  ...props
}) => {
  const buttonClasses = classNames(
    styles.button,
    styles[variant],
    styles[size]
  );
  return (
    <button
      type={type}
      className={buttonClasses}
      onClick={onClick}
      disabled={isDisabled}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
