import Button from "components/atoms/Button";
import InputDate from "components/atoms/InputDate";
import InputSelect from "components/atoms/InputSelect";
import moment from "moment";
import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { MonthOptions } from "utils/constant";
import PeKasus from "services/PeKasus";
import { useEffect } from "react";
import {
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import TableDataComponent from "components/organisms/TableData";

const PeKasusService = new PeKasus();

const TableComponent = () => {
  const [filterState, setFilterState] = useState({
    filter_year: "",
    filter_month: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [applyFilter, setApplyFilter] = useState(false);

  const [dataState, setDataState] = useState({
    page: 1,
    limit: 10,
    data: [],
    totalData: 0,
    currentPage: 1,
    nextPage: null,
    previousPage: null,
  });

  const { currentUser } = useSelector((state) => state.authReducer);

  const fetchIndigenousFeedbackData = async () => {
    try {
      let searchBy = "case_classification,fasyankes_id";
      let searchValue = `Indigenous,${currentUser?.fasyankes_id}`;

      const searchByDate = filterState?.filter_year && filterState?.filter_month ? "|YYYY-MM"
        : filterState?.filter_year ? "|YYYY" : filterState?.filter_month ? "|MM" : "";
      const searchValueDate = filterState?.filter_year && filterState?.filter_month
        ? `,${filterState?.filter_year}-${filterState?.filter_month}`
        : filterState?.filter_year ? `,${filterState?.filter_year}`
          : filterState?.filter_month ? `,${filterState?.filter_month}` : "";

      if (applyFilter) {
        searchBy += `,case_interview_date${searchByDate}`;
        searchValue += searchValueDate;
      }
      const response = await PeKasusService.getAll({
        page: dataState.page,
        limit: dataState.limit,
        searchBy,
        searchValue,
        includeRegmal: true
      });

      setDataState((prevState) => ({
        ...prevState,
        data: response.pe_cases,
        limit: response.limit,
        currentPage: response.currentPage,
        totalData: response.total,
        nextPage: response.nextPage,
        previousPage: response.previousPage,
      }));
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchIndigenousFeedbackData();
  }, [dataState.page]);

  const onPageChange = (page) => {
    setDataState((prevState) => ({
      ...prevState,
      page: page,
    }));
  };

  const columns = [
    {
      header: "Status",
      accessorKey: "case_classification",
    },
    {
      header: "Nama Faskes",
      accessorKey: "fasyankes_id",
      cell: ({ row }) => `${row.original.fasyankes_id ? row.original.fasyankes_id?.split("|")[1] : ""}`,
    },
    {
      header: "Nama Pasien",
      accessorKey: "name",
    },
    {
      header: "Asal Desa Penularan",
      accessorKey: "urbanvillage_id",
      cell: ({ row }) => `${row.original.urbanvillage_id ? row.original.urbanvillage_id?.split("|")[1] : ""}`,
    },
    {
      header: "Asal Penularan",
      accessorKey: "transmission_origin",
    },
    {
      header: "Kekambuhan",
      accessorKey: "recurrence",
    },
  ];

  const tableInstance = useReactTable({
    columns,
    data: dataState?.data,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <>
      <h1 className="page-title">Umpan Balik Indigenous</h1>
      <div className="card-filter">
        <div className="filter-item">
          <InputDate
            placeholder={"Pilih Tahun"}
            dateFormat={"YYYY"}
            onChange={(e) => {
              if (e) {
                const selectedYear = moment(e).format("YYYY");
                setFilterState({
                  ...filterState,
                  filter_year: selectedYear,
                });
              } else {
                setFilterState({
                  ...filterState,
                  filter_year: "",
                });
              }
            }}
            value={filterState.filter_year}
          />
        </div>
        <div className="filter-item">
          <InputSelect
            placeholder={"Pilih Bulan"}
            data={MonthOptions}
            onChange={(e) => {
              const selectedvalue = e ? e.value : "";
              setFilterState({
                ...filterState,
                filter_month: selectedvalue,
              });
            }}
            value={MonthOptions.find(
              (item) => item.value === filterState.filter_month
            )}
          />
        </div>
        <Button
          variant={"primary"}
          size={"normal"}
          onClick={() => {
            setApplyFilter(true);
            fetchIndigenousFeedbackData();
          }}
        >
          Terapkan
        </Button>
      </div>
      {/* table component here */}
      <TableDataComponent
        columnLength={columns?.length}
        tableInstance={tableInstance}
        onPageChange={onPageChange}
        loading={isLoading}
        currentPage={dataState.currentPage}
        limit={dataState.limit}
        nextPage={dataState.nextPage}
        previousPage={dataState.previousPage}
        total={dataState.totalData}
      />
    </>
  );
};

export default TableComponent;
