import {
  clearLocalStorage,
  getCurrentUserFromLocalStorage,
  getTokenFromLocalStorage,
  setCurrentUserToLocalStorage,
  setTokenToLocalStorage,
} from "../../utils/common";
import { LOGIN, LOGOUT } from "../actions/actionType";

const initialState = {
  token: getTokenFromLocalStorage(),
  currentUser: getCurrentUserFromLocalStorage(),
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      let loginState = Object.assign({}, state);
      const { token, currentUser } = action.payload;

      if (token) {
        setTokenToLocalStorage(token);
        loginState.token = token;
      }

      if (currentUser) {
        setCurrentUserToLocalStorage(currentUser);
        loginState.currentUser = currentUser;
      }
      
      return loginState;
    case LOGOUT:
      clearLocalStorage();
      let logoutState = {};
      return logoutState;
    default:
      return state;
  }
};

export default authReducer;
