import React from "react";
import styles from "./InputRadio.module.scss";
import propTypes from "prop-types";

const InputRadio = ({
  label,
  name,
  options,
  selectedOption,
  onChange,
  error,
  errorMessage,
  isRequired,
  disabled,
}) => {
  const inputClasses = [styles.input];

  if (error) {
    inputClasses.push(styles.error);
  }

  return (
    <div className={styles.formContainer}>
      {label && (
        <label htmlFor={label} style={{ marginBottom: "8px" }}>
          {label}{" "}
          {isRequired ? <sup style={{ color: "#D91919" }}>*</sup> : null}{" "}
        </label>
      )}
      <div style={{ display: "flex", gap: "10px" }}>
        {options?.map((option) => (
          <label
            key={option.value}
            style={{
              display: "flex",
              alignItems: "center",
              fontWeight: "400"
            }}
          >
            <input
              type="radio"
              name={name}
              value={option.value}
              checked={selectedOption === option.value}
              onChange={onChange}
              disabled={disabled}
            />
            {option.label}
          </label>
        ))}
      </div>
      {error && <span className={styles.invalidInput}>{errorMessage}</span>}
    </div>
  );
};

export default InputRadio;

InputRadio.propTypes = {
  label: propTypes.string,
  name: propTypes.string.isRequired,
  options: propTypes.arrayOf(
    propTypes.shape({
      label: propTypes.string.isRequired,
      value: propTypes.string.isRequired,
    })
  ).isRequired,
  selectedOption: propTypes.string.isRequired,
  onChange: propTypes.func.isRequired,
  error: propTypes.bool,
  errorMessage: propTypes.string,
};
