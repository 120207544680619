import InputField from "components/atoms/InputField";
import { BreadcrumbNav } from "components/molecules";
import React, { useEffect } from "react";
import {
  CaseFromOptions,
  MonthOptions,
  OperatorOptions,
  PageMode,
  YesAndNoOptions,
} from "utils/constant";
import { useFormik } from "formik";
import { useState } from "react";
import InputDate from "components/atoms/InputDate";
import moment from "moment";
import InputSelect from "components/atoms/InputSelect";
import { useSelector } from "react-redux";
import Kader from "services/Kader";
import UKBM from "services/UKBM";
import { FiAlertTriangle } from "react-icons/fi";
import Button from "components/atoms/Button";
import {
  errorToastNotif,
  getDataFromLocalStorage,
  nullConverter,
} from "utils/common";
import RegmalSecond from "services/RegmalSecond";
import { toast } from "react-toastify";

const KaderService = new Kader();
const UKBMService = new UKBM();
const RegmalSecondService = new RegmalSecond();

const FormComponent = ({ changeMode, pageMode }) => {
  const { currentUser } = useSelector((state) => state.authReducer);

  const [srData, setSrData] = useState([]);
  const [ssrData, setSsrData] = useState([]);
  const [cadresData, setCadresData] = useState([]);
  const [itemToEdit, setItemToEdit] = useState(null);

  const formik = useFormik({
    initialValues: {
      year_report: "",
      month_report: "",
      is_domicile_outside: "Tidak",
      case_from: "",
      other_case_from: "",
      discovery_activities: "",
      sr_id: "",
      ssr_id: "",
      operator: "",
      isCadreEndemis: "",
      cadres_id: [],
      total_malaria_unchecked: "",
      total_negative_microskopis_checking_under_five_year: "",
      total_negative_rdt_checking_under_five_year: "",
      total_negative_microskopis_checking_over_five_year: "",
      total_negative_rdt_checking_over_five_year: "",
      mbs_date: "",
    },
    validate: (values) => {
      const errors = {};

      if (!values.year_report) {
        errors.year_report = "Tahun Laporan wajib diisi";
      }

      if (!values.month_report) {
        errors.month_report = "Bulan Laporan wajib diisi";
      }

      if (currentUser?.fasyankes_type_id === "jnsfas-01") {
        if (!values.case_from) {
          errors.case_from = "Asal penemuan wajib dipilih";
        }

        if (values.case_from === "Lainnya") {
          if (!values.other_case_from) {
            errors.other_case_from = "Asal penemuan kasus lainnya wajib diisi";
          }
        }

        if (values.case_from === "Perdhaki") {
          if (!values.sr_id) {
            errors.sr_id = "SR wajib dipilih";
          }
          if (!values.ssr_id) {
            errors.ssr_id = "SSR wajib dispilih";
          }
        }
      }

      if (!values.discovery_activities) {
        errors.discovery_activities = "Kegiatan penemuan wajib dipilih";
      }

      if (values.discovery_activities === "MBS") {
        if (!values.mbs_date) {
          errors.mbs_date = "Tanggal MBS wajib diisi";
        }
        if (!values.operator) {
          errors.operator = "Petugas wajib dipilih";
        }
        if (values.operator === "Kader") {
          if (!values.isCadreEndemis) {
            errors.isCadreEndemis =
              "Apakah penemuan oleh kader endemis wajib dipilih";
          }
        }
        if (values.isCadreEndemis === "Ya") {
          if (!values.cadres_id || values.cadres_id.length === 0) {
            errors.cadres_id = "Kader wajib dipilih setidaknya satu";
          }
        }
      } else if (values.discovery_activities === "Survei Kontak") {
        if (!values.operator) {
          errors.operator = "Petugas wajib dipilih";
        }
        if (values.operator === "Kader") {
          if (!values.isCadreEndemis) {
            errors.isCadreEndemis =
              "Apakah penemuan oleh kader endemis wajib dipilih";
          }
        }
        if (values.isCadreEndemis === "Ya") {
          if (!values.cadres_id || values.cadres_id.length === 0) {
            errors.cadres_id = "Kader wajib dipilih setidaknya satu";
          }
        }
      } else if (values.discovery_activities === "Survei Non PE") {
        if (values.operator === "Kader") {
          if (!values.isCadreEndemis) {
            errors.isCadreEndemis =
              "Apakah penemuan oleh kader endemis wajib dipilih";
          }
        }
        if (values.isCadreEndemis === "Ya") {
          if (!values.cadres_id || values.cadres_id.length === 0) {
            errors.cadres_id = "Kader wajib dipilih setidaknya satu";
          }
        }
      } else if (values.discovery_activities === "Kunjungan Rumah") {
        if (values.operator === "Kader") {
          if (!values.isCadreEndemis) {
            errors.isCadreEndemis =
              "Apakah penemuan oleh kader endemis wajib dipilih";
          }
        }
        if (values.isCadreEndemis === "Ya") {
          if (!values.cadres_id || values.cadres_id.length === 0) {
            errors.cadres_id = "Kader wajib dipilih setidaknya satu";
          }
        }
      } else if (values.discovery_activities === "Surveilans Migrasi") {
        if (values.operator === "Kader") {
          if (!values.isCadreEndemis) {
            errors.isCadreEndemis =
              "Apakah penemuan oleh kader endemis wajib dipilih";
          }
        }
        if (values.isCadreEndemis === "Ya") {
          if (!values.cadres_id || values.cadres_id.length === 0) {
            errors.cadres_id = "Kader wajib dipilih setidaknya satu";
          }
        }
      } else if (values.discovery_activities === "MTBS") {
        if (!values.operator) {
          errors.operator = "Petugas wajib dipilih";
        }
        if (values.operator === "Kader") {
          if (!values.isCadreEndemis) {
            errors.isCadreEndemis =
              "Apakah penemuan oleh kader endemis wajib dipilih";
          }
        }
        if (values.isCadreEndemis === "Ya") {
          if (!values.cadres_id || values.cadres_id.length === 0) {
            errors.cadres_id = "Kader wajib dipilih setidaknya satu";
          }
        }
      }

      if (values.discovery_activities === "Skrining Bumil") {
        if (!values.total_negative_microskopis_checking_over_five_year) {
          errors.total_negative_microskopis_checking_over_five_year =
            "Jumlah Pemeriksaan Mikroskopi wajib diisi";
        }
        if (!values.total_negative_rdt_checking_over_five_year) {
          errors.total_negative_rdt_checking_over_five_year =
            "Jumlah Pemeriksaan RDT wajib diisi";
        }
      } else if (values.discovery_activities === "MTBS") {
        if (!values.total_negative_microskopis_checking_under_five_year) {
          errors.total_negative_microskopis_checking_under_five_year =
            "Jumlah pemeriksaan mikroskopis wajib diisi";
        }
        if (!values.total_negative_rdt_checking_under_five_year) {
          errors.total_negative_rdt_checking_under_five_year =
            "Jumlah pemeriksaan RDT wajib diisi";
        }
      } else {
        // if (!values.total_negative_microskopis_checking_over_five_year) {
        //   errors.total_negative_microskopis_checking_over_five_year =
        //     "Jumlah Pemeriksaan Mikroskopi wajib diisi";
        // }
        // if (!values.total_negative_rdt_checking_over_five_year) {
        //   errors.total_negative_rdt_checking_over_five_year =
        //     "Jumlah Pemeriksaan RDT wajib diisi";
        // }
        // if (!values.total_negative_microskopis_checking_under_five_year) {
        //   errors.total_negative_microskopis_checking_under_five_year =
        //     "Jumlah pemeriksaan mikroskopis wajib diisi";
        // }
        // if (!values.total_negative_rdt_checking_under_five_year) {
        //   errors.total_negative_rdt_checking_under_five_year =
        //     "Jumlah pemeriksaan RDT wajib diisi";
        // }
      }

      return errors;
    },
    onSubmit: async (values) => {
      let payload = {
        province_id: nullConverter(currentUser?.province_id),
        district_id: nullConverter(currentUser?.district_id),
        subdistrict_id: nullConverter(currentUser?.subdistrict_id),
        fasyankes_id: nullConverter(currentUser?.fasyankes_id),
        year_report: nullConverter(moment(values.year_report).format("YYYY")),
        month_report: nullConverter(values.month_report),
        is_domicile_outside: nullConverter(values.is_domicile_outside),
        case_from:
          values.case_from === "Lainnya"
            ? `Lainnya_${values.other_case_from}`
            : values.case_from,
        discovery_activities: nullConverter(values.discovery_activities),
        sr_id: nullConverter(values.sr_id),
        ssr_id: nullConverter(values.ssr_id),
        operator: nullConverter(values.operator),
        cadres_id: values.cadres_id?.map((item) => item.value),
        total_malaria_unchecked: nullConverter(values.total_malaria_unchecked),
        total_negative_microskopis_checking_over_five_year: nullConverter(
          values.total_negative_microskopis_checking_over_five_year
        ),
        total_negative_microskopis_checking_under_five_year: nullConverter(
          values.total_negative_microskopis_checking_under_five_year
        ),
        total_negative_rdt_checking_over_five_year: nullConverter(
          values.total_negative_rdt_checking_over_five_year
        ),
        total_negative_rdt_checking_under_five_year: nullConverter(
          values.total_negative_rdt_checking_under_five_year
        ),
        mbs_date: nullConverter(values.mbs_date),
      };

      switch (pageMode) {
        case PageMode.add:
          await RegmalSecondService.add(payload)
            .then((res) => {
              changeMode(PageMode.view);
              toast.success("Data Register Malaria 2 Berhasil Ditambahkan", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
            })
            .catch((err) => {
              console.log(err);
              errorToastNotif(err);
            });
          break;
        case PageMode.edit:
          await RegmalSecondService.update(payload, itemToEdit?.id)
            .then((res) => {
              changeMode(PageMode.view);
              toast.success("Data Register Malaria 2 Berhasil Diupdate", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
            })
            .catch((err) => {
              console.log(err);
              errorToastNotif(err);
            });
          break;
      }
    },
  });

  const getAllCadresData = async () => {
    try {
      const response = await KaderService.getAll({
        page: 1,
        limit: 9999,
        searchBy: "fasyankes_id",
        searchValue: `${currentUser?.fasyankes_id}`,
      });
      const mappedData = response.cadres.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      setCadresData(mappedData);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllSrData = async () => {
    try {
      const response = await UKBMService.getAllSR("sr", "", "");
      const mappedData = response.sr.map((item) => {
        return {
          label: item.sr_name,
          value: item.id,
        };
      });
      setSrData(mappedData);
    } catch (error) {
      console.log(error);
    }
  };

  const getSsrDataBySr = async (value) => {
    try {
      const response = await UKBMService.getSSRbySR("ssr", "sr_id", value);

      const mappedData = response.ssr.map((item) => {
        return {
          label: item.ssr_name,
          value: item.id,
        };
      });

      setSsrData(mappedData);
    } catch (error) {
      console.log(error);
    }
  };

  const discoveryActivitiesOptions = () => {
    if (formik.values.case_from === "Perdhaki") {
      return [
        {
          label: "Pasif",
          options: [
            { value: "PCD", label: "PCD" },
            { value: "MBS", label: "MBS" },
          ],
        },
        {
          label: "Aktif",
          options: [
            {
              value: "Skrining Balita Sakit/MTBS",
              label: "Skrining Balita Sakit/MTBS",
            },
            { value: "Skrining Bumil", label: "Skrining Bumil" },
            { value: "Survei Non PE", label: "Survei Non PE" },
            { value: "Kunjungan Rumah", label: "Kunjungan Rumah" },
          ],
        },
      ];
    } else if (currentUser?.fasyankes_type_id !== "jnsfas-01") {
      return [
        {
          label: "Pasif",
          options: [
            { value: "PCD", label: "PCD" },
            {
              value: "Skrining Balita Sakit/MTBS",
              label: "Skrining Balita Sakit/MTBS",
            },
            { value: "Skrining Bumil", label: "Skrining Bumil" },
            { value: "FUP", label: "FUP" },
          ],
        },
        {
          label: "Aktif",
          options: [
            { value: "MBS", label: "MBS" },
            { value: "Survei Non PE", label: "Survei Non PE" },
            { value: "Kunjungan Rumah", label: "Kunjungan Rumah" },
            {
              value: "Surveilans Migrasi",
              label: "Surveilans Migrasi",
            },
            { value: "Pusling", label: "Pusling" },
            { value: "Posyandu", label: "Posyandu" },
          ],
        },
      ];
    } else {
      return [
        {
          label: "Pasif",
          options: [
            { value: "PCD", label: "PCD" },
            {
              value: "Skrining Balita Sakit/MTBS",
              label: "Skrining Balita Sakit/MTBS",
            },
            { value: "Skrining Bumil", label: "Skrining Bumil" },
            { value: "FUP", label: "FUP" },
          ],
        },
        {
          label: "Aktif",
          options: [
            { value: "MBS", label: "MBS" },
            { value: "Survei Non PE", label: "Survei Non PE" },
            { value: "Kunjungan Rumah", label: "Kunjungan Rumah" },
            {
              value: "Surveilans Migrasi",
              label: "Surveilans Migrasi",
            },
            { value: "Pusling", label: "Pusling" },
            { value: "Posyandu", label: "Posyandu" },
          ],
        },
      ];
    }
  };

  useEffect(async () => {
    await getAllCadresData();
    await getAllSrData();
    await getSsrDataBySr(formik.values.sr_id);
  }, []);

  useEffect(() => {
    // Jika mode edit
    if (pageMode === PageMode.edit) {
      const selectedItem = getDataFromLocalStorage("SELECTED_ITEM");
      const parsedData = JSON.parse(selectedItem);
      setItemToEdit(parsedData);

      let caseFrom = "";
      let otherCaseFrom = "";

      if (
        CaseFromOptions.filter((e) => e.value === parsedData?.case_from)
          .length > 0
      ) {
        caseFrom = parsedData?.case_from;
      } else {
        caseFrom = "Lainnya";
        otherCaseFrom = parsedData?.case_from?.split("_")[1];
      }

      let selectedCadres = [];

      if (cadresData.length > 0) {
        const data = parsedData.cadres_id?.map((cadreId) => {
          const filteredCadre =
            cadresData && cadresData?.find((item) => item?.value === cadreId);
          return filteredCadre;
        });
        selectedCadres = data;
      }
      
      formik.setValues({
        year_report: parsedData?.year_report,
        month_report: parsedData?.month_report,
        is_domicile_outside: parsedData?.is_domicile_outside,
        domicile_province_id: parsedData?.domicile_province_id,
        domicile_district_id: parsedData?.domicile_district_id,
        domicile_subdistrict_id: parsedData?.domicile_subdistrict_id,
        domicile_fasyankes_id: parsedData?.domicile_fasyankes_id,
        case_from: caseFrom,
        other_case_from: otherCaseFrom,
        discovery_activities: parsedData?.discovery_activities,
        sr_id: parsedData?.sr_id,
        ssr_id: parsedData?.ssr_id,
        operator: parsedData?.operator,
        isCadreEndemis: parsedData?.cadres_id !== null ? "Ya" : "Tidak",
        cadres_id: selectedCadres,
        total_malaria_unchecked: parsedData?.total_malaria_unchecked,
        total_negative_microskopis_checking_under_five_year:
          parsedData?.total_negative_microskopis_checking_under_five_year,
        total_negative_microskopis_checking_over_five_year:
          parsedData?.total_negative_microskopis_checking_over_five_year,
        total_negative_rdt_checking_under_five_year:
          parsedData?.total_negative_rdt_checking_under_five_year,
        total_negative_rdt_checking_over_five_year:
          parsedData?.total_negative_rdt_checking_over_five_year,
        mbs_date:
          parsedData?.mbs_date &&
          moment(parsedData?.mbs_date).format("YYYY-MM-DD"),
      });
    }
  }, [cadresData]);

  const renderOperatorFieldByCondition = () => {
    if (
      formik.values.discovery_activities === "MBS" ||
      formik.values.discovery_activities === "Survei Kontak" ||
      formik.values.discovery_activities === "Survei Non PE" ||
      formik.values.discovery_activities === "Kunjungan Rumah" ||
      formik.values.discovery_activities === "Surveilans Migrasi" ||
      formik.values.discovery_activities === "Skrining Bumil" ||
      formik.values.discovery_activities === "MTBS" ||
      formik.values.discovery_activities === "FUP" ||
      formik.values.discovery_activities === "Posyandu"
    ) {
      return (
        <InputSelect
          label={"Petugas"}
          name={"operator"}
          placeholder={"Pilih Petugas"}
          data={OperatorOptions}
          onChange={(e) => {
            const selectedValue = e ? e.value : "";
            formik.handleChange("operator")(selectedValue);

            if (selectedValue !== "Kader") {
              formik.setFieldValue("isCadreEndemis", "");
            }
          }}
          onBlur={formik.handleBlur}
          value={OperatorOptions.find(
            (item) => item.value === formik.values.operator
          )}
          error={formik.errors.operator && formik.touched.operator}
          errorMessage={formik.errors.operator}
          isRequired={true}
        />
      );
    } else {
      return null;
    }
  };

  const discoveryActivities = formik.values.discovery_activities;

  const matchingOption = discoveryActivitiesOptions().find((item) => {
    return item.options.some((option) => option.value === discoveryActivities);
  });

  const selectedDiscoveryActivity = matchingOption?.options.find(
    (item) => item.value === discoveryActivities
  );

  const isButtonDisabled = (values) => {
    const {
      year_report,
      month_report,
      case_from,
      sr_id,
      ssr_id,
      discovery_activities,
      total_negative_microskopis_checking_over_five_year,
      total_negative_rdt_checking_over_five_year,
      total_negative_microskopis_checking_under_five_year,
      total_negative_rdt_checking_under_five_year,
    } = values;

    if (!year_report || !month_report) {
      return true;
    }

    if (currentUser?.fasyankes_type_id === "jnsfas-01" && !case_from) {
      return true;
    }

    if (case_from === "Perdhaki" && (!sr_id || !ssr_id)) {
      return true;
    }

    if (!discovery_activities) {
      return true;
    }

    if (
      discovery_activities === "Skrining Bumil" &&
      (!total_negative_microskopis_checking_over_five_year ||
        !total_negative_rdt_checking_over_five_year)
    ) {
      return true;
    }

    if (
      discovery_activities === "MTBS" &&
      (!total_negative_microskopis_checking_under_five_year ||
        !total_negative_rdt_checking_under_five_year)
    ) {
      return true;
    }

    return false;
  };


  return (
    <>
      <BreadcrumbNav
        items={[
          {
            label: "Register Malaria 2",
            onClick: () => changeMode(PageMode.view),
          },
          {
            label:
              pageMode === PageMode.add
                ? "Tambah Data Register Malaria 2"
                : "Edit Data Register Malaria 2",
          },
        ]}
      />

      <h1 className="page-title">
        {pageMode === PageMode.add
          ? "Tambah Data Register Malaria 2"
          : "Edit Data Register Malaria 2"}
      </h1>

      <div className="form-grouping">
        <p
          className="warning"
          style={{ marginTop: "24px", marginBottom: "24px" }}
        >
          NOTE<span>*(WAJIB DIISI)</span>
        </p>

        <div className="row">
          <div className="col-sm-6">
            <InputDate
              label={"Tahun Laporan"}
              name={"year_report"}
              placeholder={"Pilih Tahun Laporan"}
              dateFormat={"YYYY"}
              onChange={(e) => {
                const selectedYear = moment(e).format("YYYY");
                formik.handleChange("year_report")(selectedYear);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.year_report}
              error={formik.errors.year_report && formik.touched.year_report}
              errorMessage={formik.errors.year_report}
              isRequired={true}
              isDisabled={pageMode === PageMode.edit}
            />
          </div>
          <div className="col-sm-6">
            <InputSelect
              label={"Bulan Laporan"}
              name={"month_report"}
              placeholder={"Pilih Bulan"}
              data={MonthOptions}
              onChange={(e) => {
                const selectedValue = e ? e.value : "";
                formik.handleChange("month_report")(selectedValue);
              }}
              onBlur={formik.handleBlur}
              value={MonthOptions.find(
                (item) => item.value === formik.values.month_report
              )}
              error={formik.errors.month_report && formik.touched.month_report}
              errorMessage={formik.errors.month_report}
              isRequired={true}
              isDisabled={pageMode === PageMode.edit}
            />
          </div>
        </div>

        {currentUser?.fasyankes_type_id === "jnsfas-01" && (
          <>
            <InputSelect
              label={"Asal Penemuan"}
              name={"case_from"}
              placeholder={"Pilih Asal Penemuan"}
              data={CaseFromOptions}
              onChange={(e) => {
                const selectedValue = e ? e.value : "";
                formik.handleChange("case_from")(selectedValue);
                if (selectedValue !== "Perdhaki") {
                  formik.setFieldValue("sr_id", "");
                  formik.setFieldValue("ssr_id", "");
                }
              }}
              onBlur={formik.handleBlur}
              value={CaseFromOptions.find(
                (item) => item.value === formik.values.case_from
              )}
              error={formik.errors.case_from && formik.touched.case_from}
              errorMessage={formik.errors.case_from}
              isRequired={true}
              isDisabled={pageMode === PageMode.edit}
            />

            {formik.values.case_from === "Lainnya" && (
              <InputField
                name={"other_case_from"}
                placeholder={"Masukkan Asal Penemuan Lainnya"}
                {...formik.getFieldProps("other_case_from")}
                error={
                  formik.errors.other_case_from &&
                  formik.touched.other_case_from
                }
                errorMessage={formik.errors.other_case_from}
              />
            )}
          </>
        )}

        <InputSelect
          label={"Kegiatan Penemuan"}
          name={"discovery_activities"}
          placeholder={"Pilih Kegiatan Penemuan"}
          data={discoveryActivitiesOptions()}
          onChange={(e) => {
            const selectedValue = e ? e.value : "";
            formik.handleChange("discovery_activities")(selectedValue);

            if (
              selectedValue === "PCD" ||
              selectedValue === "Pusling" ||
              selectedValue !== "MBS"
            ) {
              formik.setFieldValue("mbs_date", "");
              formik.setFieldValue("operator", "");
              formik.setFieldValue("isCadreEndemis", "");
              formik.setFieldValue("cadres_id", []);
            }

            if (
              selectedValue === "Skrining Balita Sakit/MTBS" ||
              selectedValue === "Skrining Bumil"
            ) {
              formik.setFieldValue(
                "total_negative_microskopis_checking_over_five_year",
                ""
              );
              formik.setFieldValue(
                "total_negative_rdt_checking_over_five_year",
                ""
              );
            }

            // clear field
            formik.setFieldValue(
              "total_negative_microskopis_checking_over_five_year",
              ""
            );
            formik.setFieldValue(
              "total_negative_microskopis_checking_under_five_year",
              ""
            );
            formik.setFieldValue(
              "total_negative_rdt_checking_over_five_year",
              ""
            );
            formik.setFieldValue(
              "total_negative_rdt_checking_under_five_year",
              ""
            );
          }}
          onBlur={formik.handleBlur}
          value={selectedDiscoveryActivity}
          error={
            formik.errors.discovery_activities &&
            formik.touched.discovery_activities
          }
          errorMessage={formik.errors.discovery_activities}
          isRequired={true}
          isDisabled={pageMode === PageMode.edit}
        />

        {formik.values.discovery_activities === "MBS" && (
          <InputDate
            label={"Tanggal MBS"}
            name={"mbs_date"}
            placeholder={"Pilih Tanggal MBS"}
            dateFormat={"YYYY-MM-DD"}
            onChange={(e) => {
              const selectedDate = moment(e).format("YYYY-MM-DD");
              formik.handleChange("mbs_date")(selectedDate);
            }}
            onBlur={formik.handleBlur}
            value={formik.values.mbs_date}
            error={formik.errors.mbs_date && formik.touched.mbs_date}
            errorMessage={formik.errors.mbs_date}
            isRequired={true}
          />
        )}

        {/* Render Operator Field Based On Condition */}
        {renderOperatorFieldByCondition()}

        {formik.values.operator === "Kader" && (
          <InputSelect
            label={"Apakah Penemuan Kasus oleh Kader Endemis Tinggi?"}
            name={"isCadreEndemis"}
            placeholder={"Pilih"}
            data={YesAndNoOptions}
            onChange={(e) => {
              const selectedValue = e ? e.value : "";
              formik.handleChange("isCadreEndemis")(selectedValue);
              if (selectedValue !== "Ya") {
                formik.setFieldValue("cadres_id", []);
              }
            }}
            onBlur={formik.handleBlur}
            value={YesAndNoOptions.find(
              (item) => item.value === formik.values.isCadreEndemis
            )}
            error={
              formik.errors.isCadreEndemis && formik.touched.isCadreEndemis
            }
            errorMessage={formik.errors.isCadreEndemis}
            isRequired={true}
          />
        )}

        {formik.values.isCadreEndemis === "Ya" && (
          <>
            <InputSelect
              label={"Nama Kader"}
              name={"cadres_id"}
              placeholder={"Pilih Kader"}
              data={cadresData}
              onChange={(selectedValue) => {
                formik.setFieldValue("cadres_id", selectedValue);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.cadres_id}
              error={formik.errors.cadres_id && formik.touched.cadres_id}
              errorMessage={formik.errors.cadres_id}
              isMultipleSelect={true}
              isRequired={true}
            />
            <div className="warning-message">
              <div className="message">
                Bila pilihan kader tidak ada isian daftar list-nya, maka
                terlebih dahulu mengisi data kader pada menu Monitoring Kader
              </div>
            </div>
          </>
        )}

        <InputField
          label={"Jumlah Suspek Malaria Yang TIDAK DIPERIKSA"}
          boldLabel={true}
          name={"total_malaria_unchecked"}
          type={"number"}
          placeholder={"0"}
          {...formik.getFieldProps("total_malaria_unchecked")}
        />

        {/* Warning Message Here */}
        <div className="warning-message">
          <div className="icon">
            <FiAlertTriangle />
          </div>
          <div className="message">
            Suspek malaria daerah bebas malaria adalah semua orang yang
            mempunyai gejala malaria dengan riwayat berkunjung atau tinggal di
            daerah endemis DAN ATAU riwayat pernah sakit malaria endemis. Suspek
            malaria daerah endemis malaria adalah semua orang yang mempunyai
            gejala malaria.
          </div>
        </div>

        {formik.values.case_from === "Perdhaki" && (
          <div className="row">
            <div className="col-sm-6">
              <InputSelect
                label={"SR"}
                name={"sr_id"}
                placeholder={"Pilih SR"}
                data={srData}
                onChange={(e) => {
                  const selectedValue = e ? e.value : "";
                  formik.handleChange("sr_id")(selectedValue);
                  getSsrDataBySr(selectedValue);
                }}
                onBlur={formik.handleBlur}
                value={srData.find(
                  (item) => item.value === formik.values.sr_id
                )}
                error={formik.errors.sr_id && formik.touched.sr_id}
                errorMessage={formik.errors.sr_id}
                isRequired={true}
              />
            </div>
            <div className="col-sm-6">
              <InputSelect
                label={"SSR"}
                name={"ssr_id"}
                placeholder={"Pilih SSR"}
                data={ssrData}
                onChange={(e) => {
                  const selectedValue = e ? e.value : "";
                  formik.handleChange("ssr_id")(selectedValue);
                }}
                onBlur={formik.handleBlur}
                value={ssrData.find(
                  (item) => item.value === formik.values.ssr_id
                )}
                error={formik.errors.ssr_id && formik.touched.ssr_id}
                errorMessage={formik.errors.ssr_id}
                isRequired={true}
              />
            </div>
          </div>
        )}

        <h2 className="subtitle-section">
          Jumlah Suspek Malaria Yang DIPERIKSA :{" "}
        </h2>
        {formik.values.discovery_activities !== "MTBS" && (
          <>
            {/* Usia di atas 5 tahun */}
            <h3 className="subtitle-section">Usia {">="} 5 tahun</h3>
            <div className="row">
              <div className="col-sm-6">
                <InputField
                  label={"Jumlah Pemeriksaan Mikroskopis (Negatif)"}
                  name={"total_negative_microskopis_checking_over_five_year"}
                  type={"number"}
                  placeholder={"0"}
                  {...formik.getFieldProps(
                    "total_negative_microskopis_checking_over_five_year"
                  )}
                  error={
                    formik.errors
                      .total_negative_microskopis_checking_over_five_year &&
                    formik.touched
                      .total_negative_microskopis_checking_over_five_year
                  }
                  errorMessage={
                    formik.errors
                      .total_negative_microskopis_checking_over_five_year
                  }
                  isRequired={true}
                />
              </div>
              <div className="col-sm-6">
                <InputField
                  label={"Jumlah Pemeriksaan RDT (Negatif)"}
                  name={"total_negative_rdt_checking_over_five_year"}
                  type={"number"}
                  placeholder={"0"}
                  {...formik.getFieldProps(
                    "total_negative_rdt_checking_over_five_year"
                  )}
                  error={
                    formik.errors.total_negative_rdt_checking_over_five_year &&
                    formik.touched.total_negative_rdt_checking_over_five_year
                  }
                  errorMessage={
                    formik.errors.total_negative_rdt_checking_over_five_year
                  }
                  isRequired={true}
                />
              </div>
            </div>
          </>
        )}

        {formik.values.discovery_activities !== "Skrining Bumil" && (
          <>
            {/* Usia di bawah 5 tahun */}
            <h3 className="subtitle-section">Usia {"<"} 5 tahun</h3>
            <div className="row">
              <div className="col-sm-6">
                <InputField
                  label={"Jumlah Pemeriksaan Mikroskopis (Negatif)"}
                  name={"total_negative_microskopis_checking_under_five_year"}
                  type={"number"}
                  placeholder={"0"}
                  {...formik.getFieldProps(
                    "total_negative_microskopis_checking_under_five_year"
                  )}
                  error={
                    formik.errors
                      .total_negative_microskopis_checking_under_five_year &&
                    formik.touched
                      .total_negative_microskopis_checking_under_five_year
                  }
                  errorMessage={
                    formik.errors
                      .total_negative_microskopis_checking_under_five_year
                  }
                  isRequired={true}
                />
              </div>
              <div className="col-sm-6">
                <InputField
                  label={"Jumlah Pemeriksaan RDT (Negatif)"}
                  name={"total_negative_rdt_checking_under_five_year"}
                  type={"number"}
                  placeholder={"0"}
                  {...formik.getFieldProps(
                    "total_negative_rdt_checking_under_five_year"
                  )}
                  error={
                    formik.errors.total_negative_rdt_checking_under_five_year &&
                    formik.touched.total_negative_rdt_checking_under_five_year
                  }
                  errorMessage={
                    formik.errors.total_negative_rdt_checking_under_five_year
                  }
                  isRequired={true}
                />
              </div>
            </div>
          </>
        )}

        <div className="warning-message">
          <div className="message">
            Jika Penemuan melalui survei Kontak PE, maka jumlah penemuan dicatat di Menu PE
          </div>
        </div>

        <div className="form_action">
          <Button
            variant={"tertiary"}
            size={"normal"}
            onClick={() => changeMode(PageMode.view)}
          >
            Batalkan
          </Button>
          <Button
            variant={"primary"}
            size={"normal"}
            onClick={formik.handleSubmit}
            isDisabled={formik.isSubmitting || isButtonDisabled(formik.values)}
          >
            Simpan Data
          </Button>
        </div>
      </div>
    </>
  );
};

export default FormComponent;
