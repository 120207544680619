import axios from "axios";
import { getTokenFromLocalStorage } from "./common";
import config from "./config";

const api = axios.create({
  baseURL:
    config.baseUrl === undefined || config.baseUrl === ""
      ? "https://" + window.location.hostname
      : config.baseUrl,
});

api.interceptors.request.use((request) => {
  request.metadata = { started: performance.now() };
  request.headers.Authorization = getTokenFromLocalStorage();

  return request;
});

api.interceptors.response.use(
  (response) => {
    response.config.metadata.ended = performance.now();
    response.duration = Math.ceil(
      response.config.metadata.ended - response.config.metadata.started
    );
    return response;
  },
  (error) => {
    error.config.metadata.ended = performance.now();
    error.duration = Math.ceil(
      error.config.metadata.ended - error.config.metadata.started
    );
    return Promise.reject({ ...error });
  }
);

export default api;
