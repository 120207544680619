import {
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import ActionButton from "components/atoms/ActionButton";
import Button from "components/atoms/Button";
import InputDate from "components/atoms/InputDate";
import InputField from "components/atoms/InputField";
import TableDataComponent from "components/organisms/TableData";
import moment from "moment";
import React, { useEffect } from "react";
import { useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { useSelector } from "react-redux";
import DataDasarFaskes from "services/DataDasarFaskes";
import { setDataToLocalStorage } from "utils/common";
import { PageMode } from "utils/constant";

const DataDasarFaskesService = new DataDasarFaskes();

const TableComponent = ({ changeMode }) => {
  const [selectedYear, setSelectedYear] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [dataState, setDataState] = useState({
    page: 1,
    limit: 10,
    data: [],
    totalData: 0,
    currentPage: 1,
    nextPage: null,
    previousPage: null,
  });

  const { currentUser } = useSelector((state) => state.authReducer);

  const fetchDataDasarFaskes = async () => {
    setIsLoading(true);
    try {
      let searchBy = "district_id";
      let searchValue = currentUser?.district_id?.split("|")[0];

      if (searchKeyword) {
        searchBy += `,fasyankes_id`;
        searchValue += `,${searchKeyword}`;
      }

      if (selectedYear) {
        searchBy += `,year`;
        searchValue += `,${selectedYear}`;
      }

      const response = await DataDasarFaskesService.getAll({
        page: dataState.page,
        limit: dataState.limit,
        searchBy,
        searchValue,
      });

      setDataState((prevState) => ({
        ...prevState,
        data: response?.basic_data_fasyankes,
        limit: response?.limit,
        currentPage: response?.currentPage,
        totalData: response?.total,
        nextPage: response?.nextPage,
        previousPage: response?.previousPage,
      }));
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDataDasarFaskes();
  }, [dataState.page, searchKeyword]);

  const onPageChange = (page) => {
    setDataState((prevState) => ({
      ...prevState,
      page: page,
    }));
  };

  const onEditData = (item) => {
    setDataToLocalStorage("SELECTED_ITEM", JSON.stringify(item));
    changeMode(PageMode.edit);
  };

  const onDataSearch = (e) => {
    const keyword = e.target.value;
    setSearchKeyword(keyword);
  };

  const columns = [
    {
      header: "Aksi",
      accessorKey: "action",
      cell: ({ row }) => (
        <div className="button-actions">
          <ActionButton
            onClick={() => onEditData(row.original)}
            btnType={"edit"}
          />
        </div>
      ),
    },
    {
      header: "Tahun",
      accessorKey: "year",
    },
    {
      header: "Faskes Pemberi Layanan Malaria",
      accessorKey: "is_faskes_malaria_service",
    },
    {
      header: "Faskes Rujukan Obat Malaria",
      accessorKey: "is_faskes_malaria_medicine_service",
    },
    {
      header: "Faskes",
      accessorKey: "fasyankes_id",
      cell: ({ row }) => row.original.fasyankes_id?.split("|")[1],
    },
    {
      header: "Koordinat",
      accessorKey: "coordiante",
    },
  ];

  const tableInstance = useReactTable({
    columns,
    data: dataState?.data,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <>
      <h1 className="page-title">Data Dasar Faskes</h1>
      <div className="card-filter" style={{ maxWidth: "30rem" }}>
        <div className="filter-item">
          <InputDate
            placeholder={"Pilih Tahun"}
            name={"filter_year"}
            dateFormat={"YYYY"}
            onChange={(e) => {
              const selectedYear = moment(e).format("YYYY");
              setSelectedYear(selectedYear);
            }}
            value={selectedYear}
          />
        </div>
        <Button
          variant={"primary"}
          size={"normal"}
          onClick={() => fetchDataDasarFaskes()}
        >
          Terapkan
        </Button>
      </div>
      <div className="action-wrapper">
        <div className="action-button">
          <Button
            variant={"primary"}
            size={"normal"}
            onClick={() => changeMode(PageMode.add)}
          >
            Tambah Data Dasar Faskes
          </Button>
        </div>
        <div className="action-search">
          <InputField
            placeholder={"Cari berdasarkan nama faskes..."}
            onChange={onDataSearch}
            value={searchKeyword}
          />
        </div>
      </div>

      <TableDataComponent
        columnLength={columns?.length}
        tableInstance={tableInstance}
        onPageChange={onPageChange}
        loading={isLoading}
        currentPage={dataState?.currentPage}
        limit={dataState?.limit}
        nextPage={dataState?.nextPage}
        previousPage={dataState?.previousPage}
        total={dataState?.totalData}
      />
    </>
  );
};

export default TableComponent;
