const baseUrl = process.env.REACT_APP_BACKEND_URL;
const googleMapApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const maintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE === "true";
const appMode = process.env.REACT_APP_MODE;

let config = () => {
  return {
    baseUrl,
    googleMapApiKey,
    maintenanceMode,
    appMode,
  };
};

export default config();
