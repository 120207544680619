import React from "react";

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <footer className="footer py-4">
      <div className="container-fluid">
        <div className="copyright mx-auto">
          Copyright &copy; {year} |
          <a href="https://esismal.castellumdigital.org/">
            Kementerian Kesehatan RI
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
