import React, { Component } from "react";
import { ErrorMessage, PageMode, requestStatus } from "utils/constant";
import LogisticRequestStock from "services/LogisticRequestStock";
import { Button, InputField, InputSelect } from "components";
import InputDateTime from "components/InputDateTime";
import moment from "moment";
import { toast } from "react-toastify";
import Dropzone from "react-dropzone";

const LogisticRequestStockService = new LogisticRequestStock();
export default class Form extends Component {
  _isMounted = true;

  constructor(props) {
    super(props);
    this.onDrop = (acceptedFiles, fileRejections) => {
      if (fileRejections.length > 0) {
        if (fileRejections[0].errors[0].code === "file-too-large") {
          toast.error("Ukuran file tidak boleh lebih dari 100 Kb");
        } else if (fileRejections[0].errors[0].code === "file-invalid-type") {
          toast.error("File yang diupload harus berupa pdf");
        }
      }

      if (acceptedFiles.length > 0) {
        this.setState({
          files: acceptedFiles,
        });
      }
    };
    this.defaultValue = {
      year_submission: { value: "", isValid: true, message: "" },
      province_id: { value: "", isValid: true, message: "" },
      district_id: { value: "", isValid: true, message: "" },
      subdistrict_id: { value: "", isValid: true, message: "" },
      fasyankes_id: { value: "", isValid: true, message: "" },
      letter_date: { value: "", isValid: true, message: "" },
      letter_number: { value: "", isValid: true, message: "" },
      request_type: { value: "", isValid: true, message: "" },
      logistic_type: { value: "", isValid: true, message: "" },
      request_total: { value: "", isValid: true, message: "" },
      unit: { value: "", isValid: true, message: "" },
      fulfilled_total: { value: "", isValid: true, message: "" },
      fulfilled_unit: { value: "", isValid: true, message: "" },
      status: { value: "", isValid: true, message: "" },
      level: { value: "", isValid: true, message: "" },

      files: [],
      listUnitOptions: [],
      onEditFileName: "",
      showFileError: false,

      isLoading: false,
    };
    this.state = Object.assign({}, this.defaultValue);
  }

  componentDidMount() {
    this._isMounted = true;
    let obj = Object.assign({}, this.state);
    if (this.props.mode === PageMode.edit) {
      const { requestStock } = this.props;
      obj.year_submission.value = requestStock.year_submission;
      obj.province_id.value = requestStock.province_id;
      obj.district_id.value = requestStock.district_id;
      obj.subdistrict_id.value = requestStock.subdistrict_id;
      obj.fasyankes_id.value = requestStock.fasyankes_id;
      obj.letter_date.value = requestStock.letter_date;
      obj.letter_number.value = requestStock.letter_number;
      obj.request_type.value = requestStock.request_type;
      obj.logistic_type.value = requestStock.logistic_type;
      obj.request_total.value = requestStock.request_total;
      obj.unit.value = requestStock.unit;
      obj.fulfilled_total.value = requestStock.fulfilled_total;
      obj.fulfilled_unit.value = requestStock.fulfilled_unit;
      obj.onEditFileName = requestStock.fulfilled_letter_upload;
      obj.status.value = requestStock.status;

      obj.listUnitOptions = [];
      switch (requestStock.logistic_type) {
        case "DHP":
          obj.listUnitOptions.push({ value: "Tablet", label: "Tablet" });
          break;
        case "Primaquin":
          obj.listUnitOptions.push({ value: "Tablet", label: "Tablet" });
          break;
        case "Artesunat Inj":
          obj.listUnitOptions.push({ value: "Vial", label: "Vial" });
          break;
        case "Kina tablet":
          obj.listUnitOptions.push({ value: "Tablet", label: "Tablet" });
          break;
        case "Kina Inj":
          obj.listUnitOptions.push({ value: "Ampul", label: "Ampul" });
          break;
        case "Doksisiklin":
          obj.listUnitOptions.push({ value: "Tablet", label: "Tablet" });
          break;
        case "RDT":
          obj.listUnitOptions.push({ value: "Tes", label: "Tes" });
          break;
        case "LLIN":
          obj.listUnitOptions.push({ value: "Lembar", label: "Lembar" });
          break;
        case "Insektisida":
          obj.listUnitOptions.push({ value: "Kg", label: "Kg" });
          obj.listUnitOptions.push({ value: "Liter", label: "Liter" });
          break;
        case "Larvasida":
          obj.listUnitOptions.push({ value: "Kg", label: "Kg" });
          obj.listUnitOptions.push({ value: "Liter", label: "Liter" });
          break;
        case "Lab Kit":
          obj.listUnitOptions.push({ value: "Paket", label: "Paket" });
          break;
        case "Giemsa":
          obj.listUnitOptions.push({ value: "CC", label: "CC" });
          break;
        case "Minyak Imersi":
          obj.listUnitOptions.push({ value: "CC", label: "CC" });
          break;
        case "Blood Slide":
          obj.listUnitOptions.push({ value: "Box", label: "Box" });
          break;
        case "Slide Box":
          obj.listUnitOptions.push({ value: "Buah", label: "Buah" });
          break;
        case "Blood Lancet":
          obj.listUnitOptions.push({ value: "Box", label: "Box" });
          break;
        case "Methanol":
          obj.listUnitOptions.push({ value: "CC", label: "CC" });
          break;
        case "Spray Can":
          obj.listUnitOptions.push({ value: "Buah", label: "Buah" });
          break;
        case "Mist Blower":
          obj.listUnitOptions.push({ value: "Buah", label: "Buah" });
          break;
        case "Microscope Binocular":
          obj.listUnitOptions.push({ value: "Buah", label: "Buah" });
          break;
        case "Microscope Trinocular":
          obj.listUnitOptions.push({ value: "Buah", label: "Buah" });
          break;
        case "Microscope Teaching":
          obj.listUnitOptions.push({ value: "Buah", label: "Buah" });
          break;
        default:
          obj.listUnitOptions.push({ value: "Buah", label: "Buah" });
          obj.listUnitOptions.push({ value: "Tablet", label: "Tablet" });
          obj.listUnitOptions.push({ value: "Vial", label: "Vial" });
          obj.listUnitOptions.push({ value: "Box", label: "Box" });
          obj.listUnitOptions.push({ value: "Kg", label: "Kg" });
          obj.listUnitOptions.push({ value: "Liter", label: "Liter" });
          obj.listUnitOptions.push({ value: "Tes", label: "Tes" });
          obj.listUnitOptions.push({ value: "CC", label: "CC" });
          obj.listUnitOptions.push({ value: "Paket", label: "Paket" });
          obj.listUnitOptions.push({ value: "Ampul", label: "Ampul" });
          break;
      }
      this.setState(obj);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onInputChange = (e) => {
    const { name, value } = e.target;
    let obj = Object.assign({}, this.state);
    obj[name].value = value;
    this.setState(obj);
  };

  validationHandler = (name, errorMsg) => {
    let isValid = true;
    if (
      this.state[name].value === "" ||
      this.state[name].value === null ||
      this.state[name].value === undefined
    ) {
      this.setState({
        [name]: {
          value: "",
          isValid: false,
          message: `${errorMsg}`,
        },
      });
      isValid = false;
    }
    return isValid;
  };

  onValidate = () => {
    let isValid = true;
    if (
      !this.validationHandler(
        "fulfilled_total",
        "Jumlah yang dipenuhi wajib diisi"
      )
    ) {
      isValid = false;
    }
    if (
      !this.validationHandler(
        "fulfilled_unit",
        "Satuan yang dipenuhi wajib diisi"
      )
    ) {
      isValid = false;
    }
    if (this.state.files.length < 1) {
      isValid = false;
      this.setState({ showFileError: true });
    }
    return isValid;
  };

  onSave = async (e) => {
    e.preventDefault();
    if (this.onValidate()) {
      this.setState({
        loading: true,
      });

      let payload = {
        year_submission: this.state.year_submission.value,
        letter_number: this.state.letter_number.value,
        fulfilled_total: this.state.fulfilled_total.value,
        fulfilled_unit: this.state.fulfilled_unit.value,
        status: "Sudah Terpenuhi",
        file_fulfilled: this.state.files[0],
      };

      await LogisticRequestStockService.update(
        payload,
        this.props.requestStock.id
      )
        .then((res) => {
          this.setState({
            isLoading: false,
          });

          this.setState(this.defaultValue, () => {
            this.props.changeMode("view");
          });
          toast.success("Data Permintaan Berhasil Diperbarui", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        })
        .catch((err) => {
          this.setState({
            isLoading: false,
          });

          console.log(err);

          if (err.response.status === requestStatus.BadRequest) {
            toast.error(ErrorMessage.UserFaskesNotHaveAccess, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          if (err.response.status === requestStatus.Forbidden) {
            toast.error(ErrorMessage.UserNotVerified, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          if (err.response.status === requestStatus.Conflict) {
            toast.error(ErrorMessage.DataAlreadyExist, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        });
      this.setState(this.defaultValue, () => {
        this.props.changeMode("view");
      });
    }
  };

  render() {
    const { mode } = this.props;
    const { files, showFileError, status, onEditFileName } = this.state;
    const file = files.map((file) => (
      <li key={file.name}>
        {file.name} - {file.size} bytes
      </li>
    ));

    return (
      <div className="form-wrapper">
        <div className="row">
          <div className="col-sm-6">
            <table className="table">
              <tr>
                <td>PROVINSI</td>
                <th>:</th>
                <th>{this.state.province_id.value?.split("|")[1]}</th>
              </tr>
              <tr>
                <td>KAB/KOTA</td>
                <th>:</th>
                <th>{this.state.district_id.value?.split("|")[1]}</th>
              </tr>
              <tr>
                <td>FASYANKES</td>
                <th>:</th>
                <th>{this.state.fasyankes_id.value?.split("|")[1]}</th>
              </tr>
            </table>
          </div>
        </div>

        <p className="font-weight-bold d-flex justify-content-start">
          NOTE <span className="text-danger">*(WAJIB DIISI)</span>
        </p>

        <InputDateTime
          label="Tanggal Surat"
          name="letter_date"
          dateFormat="YYYY-MM-DD"
          timeFormat={false}
          onChange={(e) =>
            this.onInputChange({
              target: {
                name: "letter_date",
                value: moment(e).format("YYYY-MM-DD"),
              },
            })
          }
          closeOnClickOutside={true}
          value={this.state.letter_date.value}
          isRequired={true}
          isDisabled={true}
        />

        <InputField
          label="Nomor Dokumen/Surat (SBBK/BAST)"
          name="letter_number"
          type="text"
          placeholder="Nomor Dokumen/Surat (SBBK/BAST)"
          onChange={this.onInputChange}
          value={this.state.letter_number.value}
          isRequired={true}
          isDisabled={true}
        />

        <InputSelect
          label="Jenis Permintaan"
          name="request_type"
          placeholder="Pilih"
          data={[
            { value: "Tahunan", label: "Tahunan" },
            { value: "Insidentil", label: "Insidentil" },
          ]}
          onChange={this.onInputChange}
          value={this.state.request_type.value}
          isRequired={true}
          isDisabled={true}
        />

        <InputSelect
          label="Jenis logistik"
          name="logistic_type"
          placeholder="Pilih"
          data={[
            { value: "DHP", label: "DHP" },
            { value: "Primaquin", label: "Primaquin" },
            { value: "Artesunat Inj", label: "Artesunat Inj" },
            { value: "Kina (Tablet)", label: "Kina tablet" },
            { value: "Kina Inj (Ampul)", label: "Kina Inj" },
            { value: "Doksisiklin", label: "Doksisiklin" },
            { value: "RDT", label: "RDT" },
            { value: "LLIN", label: "LLIN" },
            { value: "Insektisida", label: "Insektisida" },
            { value: "Larvasida", label: "Larvasida" },
            { value: "Lab Kit", label: "Lab Kit" },
            { value: "Giemsa", label: "Giemsa" },
            { value: "Minyak Imersi", label: "Minyak Imersi" },
            { value: "Blood Slide", label: "Blood Slide" },
            { value: "Slide Box", label: "Slide Box" },
            { value: "Blood Lancet", label: "Blood Lancet" },
            { value: "Methanol", label: "Methanol" },
            { value: "Spray Can", label: "Spray Can" },
            { value: "Mist Blower", label: "Mist Blower" },
            {
              value: "Microscope Binocular",
              label: "Microscope Binocular",
            },
            {
              value: "Microscope Trinocular",
              label: "Microscope Trinocular",
            },
            {
              value: "Microscope Teaching",
              label: "Microscope Teaching",
            },
          ]}
          onChange={this.onInputChange}
          value={this.state.logistic_type.value}
          isRequired={true}
          isDisabled={true}
        />

        <InputField
          label="Jumlah yang diminta"
          name="request_total"
          type="numberOnly"
          placeholder="Jumlah yang diminta"
          onChange={this.onInputChange}
          value={this.state.request_total.value}
          isRequired={true}
          isDisabled={true}
        />

        <InputSelect
          label="Satuan yang diminta"
          name="unit"
          placeholder="Pilih"
          data={this.state.listUnitOptions}
          value={this.state.unit.value}
          onChange={this.onInputChange}
          isRequired={true}
          isDisabled={true}
        />

        <InputField
          label="Jumlah yang dipenuhi"
          name="fulfilled_total"
          type="numberOnly"
          placeholder="Jumlah yang dipenuhi"
          onChange={this.onInputChange}
          value={this.state.fulfilled_total.value}
          isRequired={true}
          hasError={!this.state.fulfilled_total.isValid}
          errorMessage={this.state.fulfilled_total.message}
        />

        <InputSelect
          label="Satuan yang dipenuhi"
          name="fulfilled_unit"
          placeholder="Pilih"
          data={this.state.listUnitOptions}
          onChange={this.onInputChange}
          value={this.state.fulfilled_unit.value}
          isRequired={true}
          hasError={!this.state.fulfilled_unit.isValid}
          errorMessage={this.state.fulfilled_unit.message}
        />

        <div className="form-group">
          <label className="required">Upload surat</label>
          <Dropzone
            maxFiles={1}
            accept={"application/pdf"}
            onDrop={this.onDrop}
            maxSize={100000}
          >
            {({ getRootProps, getInputProps }) => (
              <section className="container">
                <div {...getRootProps({ className: "dropzone" })}>
                  <input {...getInputProps()} />
                  <div className="dz-message">
                    <div className="icon">
                      <i className="flaticon-file"></i>
                    </div>
                    <h5>Upload file dalam bentuk pdf</h5>
                    <div className="note">Drag & Drop or</div>
                    <button className="btn btn-teal">
                      Pilih File dari perangkat anda
                    </button>
                    <div className="note">Maksimal besar file: 100KB</div>
                  </div>
                </div>
                <aside>
                  <h4>Files</h4>
                  <ul>{file}</ul>
                  {files.length < 1 &&
                    mode === PageMode.edit &&
                    status.value === "Sudah Terpenuhi" && (
                      <>
                        <ul>
                          <li>{onEditFileName}</li>
                        </ul>
                      </>
                    )}
                  {showFileError && files.length < 1 && (
                    <small className="text-danger">File wajib dipilih</small>
                  )}
                </aside>
              </section>
            )}
          </Dropzone>
        </div>

        <div className="form-group d-flex justify-content-end">
          <Button onClick={() => this.props.changeMode("view")} isSecondary>
            Batalkan
          </Button>
          <Button
            onClick={this.onSave}
            isPrimary
            isDisabled={this.state.isLoading}
          >
            {this.state.isLoading ? "Mohon Tunggu..." : "Simpan Data"}
          </Button>
        </div>
      </div>
    );
  }
}
