import React, { Component } from "react";
import { ErrorMessage, PageMode, requestStatus, Source } from "utils/constant";
import { toast } from "react-toastify";
import { ArrowLeft } from "react-feather";
import InputDateTime from "components/InputDateTime";
import moment from "moment";
import { Button, InputField, InputSelect } from "components";
import Maps from "components/Maps";
import MosquitoIdentification from "services/MoquitoIdentification";
import Wilayah from "services/Wilayah";

const WilayahService = new Wilayah();
const MosquitoIdentificationService = new MosquitoIdentification();

export default class FormComponent extends Component {
  _isMounted = true;

  constructor(props) {
    super(props);
    this.defaultValue = {
      fasyankes_id: {
        value: this.props.currentUser.fasyankes_id,
        isValid: true,
        message: "",
      },
      survey_date: { value: "", isValid: true, message: "" },
      urbanvillage_id: { value: "", isValid: true, message: "" },
      sub_village: { value: "", isValid: true, message: "" },
      implementation: { value: "", isValid: true, message: "" },
      coordinate: { value: "", isValid: true, message: "" },
      survey_type: { value: "", isValid: true, message: "" },
      survey_length: { value: "", isValid: true, message: "" },
      anopheles_mosquito_found: { value: "", isValid: true, message: "" },
      mosquito_species: { value: "", isValid: true, message: "" },
      mosquito_species: { value: "", isValid: true, message: "" },
      mbr_inside_house: { value: "", isValid: true, message: "" },
      mbr_outside_house: { value: "", isValid: true, message: "" },
      mbr_garden: { value: "", isValid: true, message: "" },
      // Data Desa
      urbanvillages: [],

      isLoading: false,
    };
    this.state = Object.assign({}, this.defaultValue);
  }

  componentDidMount() {
    this._isMounted = true;
    this.getUrbanvillagesByCurrentUser();

    let obj = Object.assign({}, this.state);

    if (this.props.mode === PageMode.edit) {
      const { data } = this.props;

      obj.fasyankes_id.value = data.fasyankes_id;
      obj.survey_date.value = data.survey_date;
      obj.urbanvillage_id.value = data.urbanvillage_id;
      obj.sub_village.value = data.sub_village;
      obj.implementation.value = data.implementation;
      obj.coordinate.value = data.coordinate;
      obj.survey_type.value = data.survey_type;
      obj.survey_length.value = data.survey_length;
      obj.anopheles_mosquito_found.value = data.anopheles_mosquito_found;
      obj.mosquito_species.value = data.mosquito_species;
      obj.mbr_inside_house.value = data.mbr_inside_house;
      obj.mbr_outside_house.value = data.mbr_outside_house;
      obj.mbr_garden.value = data.mbr_garden;
    }

    this.setState(obj);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getUrbanvillagesByCurrentUser = async () => {
    const { currentUser } = this.props;

    await WilayahService.getAll(Source.URBANVILLAGE, {
      searchBy: "subdistrict_id",
      searchValue: currentUser && currentUser.subdistrict_id?.split("|")[0],
      page: 1,
      limit: 999,
    })
      .then((res) => {
        this.setState({
          urbanvillages: res.urbanvillage,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    let obj = Object.assign({}, this.state[name]);
    obj.value = value;

    if (name === "anopheles_mosquito_found") {
      this.setState(
        {
          [name]: { value: value, isValid: true, message: "" },
        },
        () => {
          this.setState({
            mosquito_species: { value: "", isValid: true, message: "" },
            mbr_inside_house: { value: "", isValid: true, message: "" },
            mbr_outside_house: { value: "", isValid: true, message: "" },
            mbr_garden: { value: "", isValid: true, message: "" },
          });
        }
      );
    }

    if (name === "mosquito_species") {
      this.setState(
        {
          [name]: { value: value, isValid: true, message: "" },
        },
        () => {
          this.setState({
            mbr_inside_house: { value: "", isValid: true, message: "" },
            mbr_outside_house: { value: "", isValid: true, message: "" },
            mbr_garden: { value: "", isValid: true, message: "" },
          });
        }
      );
    }

    this.setState({
      [name]: obj,
    });
  };

  handleLocationChange = (address, coordinate) => {
    this.setState({
      address: { value: address, isValid: true, message: "" },
      coordinate: {
        value: Object.values(coordinate).toString(),
        isValid: true,
        message: "",
      },
    });
  };

  nullConverter = (value) => {
    if (value === "") {
      return null;
    }
    return value;
  };

  onSave = async (e) => {
    e.preventDefault();

    if (this._validationHandler()) {
      this.setState({
        isLoading: true,
      });

      let payload = {
        fasyankes_id: this.nullConverter(this.state.fasyankes_id.value),
        survey_date: this.nullConverter(this.state.survey_date.value),
        urbanvillage_id: this.nullConverter(this.state.urbanvillage_id.value),
        sub_village: this.nullConverter(this.state.sub_village.value),
        implementation: this.nullConverter(this.state.implementation.value),
        coordinate: this.nullConverter(this.state.coordinate.value),
        survey_type: this.nullConverter(this.state.survey_type.value),
        survey_length: this.nullConverter(this.state.survey_length.value),
        anopheles_mosquito_found: this.nullConverter(
          this.state.anopheles_mosquito_found.value
        ),
        mosquito_species: this.nullConverter(this.state.mosquito_species.value),
        mbr_inside_house: this.nullConverter(this.state.mbr_inside_house.value),
        mbr_outside_house: this.nullConverter(
          this.state.mbr_outside_house.value
        ),
        mbr_garden: this.nullConverter(this.state.mbr_garden.value),
      };

      switch (this.props.mode) {
        case PageMode.add:
          await MosquitoIdentificationService.add(payload)
            .then((res) => {
              this.setState({
                isLoading: false,
              });

              this.setState(this.defaultValue, () => {
                this.props.changeMode("view");
              });

              toast.success("Data berhasil ditambahkan", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            })
            .catch((err) => {
              console.log(err);

              this.setState({
                isLoading: false,
              });

              if (err.response.status === requestStatus.BadRequest) {
                toast.error(ErrorMessage.UserFaskesNotHaveAccess, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }

              if (err.response.status === requestStatus.Forbidden) {
                toast.error(ErrorMessage.UserNotVerified, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }

              if (err.response.status === requestStatus.Conflict) {
                toast.error(ErrorMessage.DataAlreadyExist, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
              if (err.response.status === requestStatus.ServerError) {
                toast.error(ErrorMessage.InternalServerError, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
            });
          break;
        case PageMode.edit:
          await MosquitoIdentificationService.update(
            payload,
            this.props.data.id
          )
            .then((res) => {
              this.setState({
                isLoading: false,
              });

              this.setState(this.defaultValue, () => {
                this.props.changeMode("view");
              });

              toast.success("Data berhasil diubah", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            })
            .catch((err) => {
              console.log(err);

              this.setState({
                isLoading: false,
              });

              if (err.response.status === requestStatus.BadRequest) {
                toast.error(ErrorMessage.UserFaskesNotHaveAccess, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }

              if (err.response.status === requestStatus.Forbidden) {
                toast.error(ErrorMessage.UserNotVerified, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }

              if (err.response.status === requestStatus.Conflict) {
                toast.error(ErrorMessage.DataAlreadyExist, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
              if (err.response.status === requestStatus.ServerError) {
                toast.error(ErrorMessage.InternalServerError, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
            });
          break;
      }
    }
  };

  _validationHandler = () => {
    const {
      fasyankes_id,
      survey_date,
      urbanvillage_id,
      sub_village,
      anopheles_mosquito_found,
    } = this.state;

    let isValid = true;

    if (fasyankes_id.value === "" || null) {
      this.setState({
        fasyankes_id: {
          ...fasyankes_id,
          isValid: false,
          message: "Fasyankes tidak boleh kosong",
        },
      });
      isValid = false;
    }

    if (survey_date.value === "" || null) {
      this.setState({
        survey_date: {
          ...survey_date,
          isValid: false,
          message: "Tanggal Survey tidak boleh kosong",
        },
      });
      isValid = false;
    }

    if (urbanvillage_id.value === "" || null) {
      this.setState({
        urbanvillage_id: {
          ...urbanvillage_id,
          isValid: false,
          message: "Kelurahan tidak boleh kosong",
        },
      });
      isValid = false;
    }

    if (sub_village.value === "" || null) {
      this.setState({
        sub_village: {
          ...sub_village,
          isValid: false,
          message: "Dusun tidak boleh kosong",
        },
      });
      isValid = false;
    }

    if (anopheles_mosquito_found.value === "" || null) {
      this.setState({
        anopheles_mosquito_found: {
          ...anopheles_mosquito_found,
          isValid: false,
          message: "Jumlah Larva Ditemukan tidak boleh kosong",
        },
      });
      isValid = false;
    }

    return isValid;
  };

  _mapper = (e) => {
    const options = [];
    e.map((x) => {
      options.push({ value: `${x.id}|${x.name}`, label: x.name });
    });
    return options;
  };

  render() {
    return (
      <div className="dashboard-wrapper">
        <div className="container">
          {this.props.mode !== PageMode.view && (
            <div
              className="my-3"
              style={{ cursor: "pointer" }}
              onClick={() => this.props.changeMode(PageMode.view)}
            >
              <ArrowLeft />
              <span>Kembali</span>
            </div>
          )}

          <h1 className="title font-weight-bold" style={{ padding: "10px" }}>
            Form Data Intervensi Nyamuk
          </h1>

          <p className="font-weight-bold" style={{ padding: "0 10px" }}>
            NOTE: <span className="text-danger">*(WAJIB DIISI)</span>
          </p>

          <div className="form-wrapper">
            <InputDateTime
              label={"Tanggal Survey"}
              name="survey_date"
              dateFormat={"YYYY-MM-DD"}
              placeholder="YYYY-MM-DD"
              value={this.state.survey_date.value}
              onChange={(e) =>
                this.handleInputChange({
                  target: {
                    name: "survey_date",
                    isValid: true,
                    value: moment(e).format("YYYY-MM-DD"),
                  },
                })
              }
              isRequired={true}
              hasError={!this.state.survey_date.isValid}
              errorMessage={this.state.survey_date.message}
              isDisabled={this.props.mode === PageMode.edit}
            />

            <InputSelect
              label={"Nama Desa"}
              name="urbanvillage_id"
              placeholder="--PILIH--"
              data={this._mapper(this.state.urbanvillages)}
              value={this.state.urbanvillage_id.value}
              onChange={this.handleInputChange}
              isRequired={true}
              hasError={!this.state.urbanvillage_id.isValid}
              errorMessage={this.state.urbanvillage_id.message}
            />

            <InputField
              label={"Nama Dusun"}
              name="sub_village"
              type={"text"}
              placeholder="Nama Dusun..."
              onChange={this.handleInputChange}
              value={this.state.sub_village.value}
              isRequired={true}
              hasError={!this.state.sub_village.isValid}
              errorMessage={this.state.sub_village.message}
            />

            <InputField
              label={"Pelaksana (Opsional)"}
              name="implementation"
              type={"text"}
              placeholder="Pelaksana..."
              onChange={this.handleInputChange}
              value={this.state.implementation.value}
            />

            {/* Maps Component Here */}
            {this.props.mode !== PageMode.edit && (
              <div className="form-group">
                <label htmlFor="location">
                  Pilih Lokasi
                </label>
                <Maps
                  onChangeLocation={(address, coordinate) => {
                    this.handleLocationChange(address, coordinate);
                  }}
                />
                <small className="text-muted">
                  Klik pada Maps untuk menentukan titik koordinat atau gunakan
                  cari lokasi
                </small>
              </div>
            )}
            {/* End of Maps Component */}

            <InputField
              type={"text"}
              name="coordinate"
              placeholder="Titik Koordinat akan muncul disini"
              value={this.state.coordinate.value}
              isReadOnly
              isRequired={false}
              hasError={!this.state.coordinate.isValid}
              errorMessage={this.state.coordinate.message}
            />

            <InputSelect
              label={"Jenis Survey (Opsional)"}
              name={"survey_type"}
              placeholder="--PILIH--"
              data={[
                { label: "Spot Survey", value: "Spot Survey" },
                { label: "Longitudinal", value: "Longitudinal" },
              ]}
              onChange={this.handleInputChange}
              value={this.state.survey_type.value}
            />

            <InputField
              label={"Lama Survey (Hari)"}
              type={"numberOnly"}
              placeholder="0"
              name="survey_length"
              onChange={this.handleInputChange}
              value={this.state.survey_length.value}
            />

            <InputSelect
              label={"Ditemukan Nyamuk Anopheles"}
              name={"anopheles_mosquito_found"}
              placeholder="--PILIH--"
              data={[
                { label: "Ditemukan", value: "Ditemukan" },
                { label: "Tidak", value: "Tidak" },
              ]}
              onChange={this.handleInputChange}
              value={this.state.anopheles_mosquito_found.value}
              isRequired={true}
              hasError={!this.state.anopheles_mosquito_found.isValid}
              errorMessage={this.state.anopheles_mosquito_found.message}
            />

            {this.state.anopheles_mosquito_found.value === "Ditemukan" && (
              <>
                <InputSelect
                  label={"Spesies Nyamuk Yang Ditemukan (Opsional)"}
                  name={"mosquito_species"}
                  placeholder="--PILIH--"
                  style={{
                    fontStyle: "italic",
                  }}
                  data={[
                    { label: "An. barbirostris", value: "An. barbirostris" },
                    { label: "An. aconitus", value: "An. aconitus" },
                    { label: "An. bancrofti", value: "An. bancrofti" },
                    { label: "An. balabacensis", value: "An. balabacensis" },
                    { label: "An. farauti", value: "An. farauti" },
                    { label: "An. flavirostris", value: "An. flavirostris" },
                    { label: "An. koliensis", value: "An. koliensis" },
                    { label: "An. letifer", value: "An. letifer" },
                    { label: "An. lodiowae", value: "An. lodiowae" },
                    { label: "An. maculatus", value: "An. maculatus" },
                    { label: "An. minimus", value: "An. minimus" },
                    { label: "An. punctulatus", value: "An. punctulatus" },
                    { label: "An. nigerrimus", value: "An. nigerrimus" },
                    { label: "An. sinensis", value: "An. sinensis" },
                    { label: "An. subpictus", value: "An. subpictus" },
                    { label: "An. sundaicus", value: "An. sundaicus" },
                    { label: "An. annularis", value: "An. annularis" },
                    { label: "An. barbumbrosus", value: "An. barbumbrosus" },
                    { label: "An. karwari", value: "An. karwari" },
                    { label: "An. kochi", value: "An. kochi" },
                    { label: "An. leucosphyrus", value: "An. leucosphyrus" },
                    { label: "An. parangensis", value: "An. parangensis" },
                    { label: "An. umbrosus", value: "An. umbrosus" },
                    { label: "An. vagus", value: "An. vagus" },
                    { label: "An. tessalatus", value: "An. tessalatus" },
                    { label: "An. peditaeniatus", value: "An. peditaeniatus" },
                  ]}
                  onChange={this.handleInputChange}
                  value={this.state.mosquito_species.value}
                />

                <InputField
                  label={
                    "MBR (Man Biting Rate) per orang per jam, lokasi dalam rumah (Opsional)"
                  }
                  type={"decimal"}
                  placeholder="0"
                  name="mbr_inside_house"
                  onChange={this.handleInputChange}
                  value={this.state.mbr_inside_house.value}
                />

                <InputField
                  label={
                    "MBR (Man Biting Rate) per orang per jam, lokasi luar rumah (Opsional)"
                  }
                  type={"decimal"}
                  placeholder="0"
                  name="mbr_outside_house"
                  onChange={this.handleInputChange}
                  value={this.state.mbr_outside_house.value}
                />

                <InputField
                  label={
                    "MBR (Man Biting Rate) per orang per jam, Kandang atau Kebun (Opsional)"
                  }
                  type={"decimal"}
                  placeholder="0"
                  name="mbr_garden"
                  onChange={this.handleInputChange}
                  value={this.state.mbr_garden.value}
                />
              </>
            )}
            <div className="form-group d-flex justify-content-end">
              <Button onClick={() => this.props.changeMode("view")} isSecondary>
                Batalkan
              </Button>
              <Button
                onClick={this.onSave}
                isPrimary
                isDisabled={this.state.isLoading}
              >
                {this.state.isLoading ? "Mohon Tunggu..." : "Simpan Data"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
