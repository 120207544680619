import React from "react";
import { monthConverter } from "utils/common";
import { UserLevel } from "utils/constant";

const TableComponent = ({
  currentLevel,
  reportsData,
  tableRef,
  reportInfo,
}) => {
  const calculateTotal = () => {
    return reportsData.reduce(
      (total, item) => {
        total.totalRow += 1;
        total.totalDataFaskes += parseInt(item.total_faskes ?? 0);
        total.totalDataMonth += parseInt(item.total_month ?? 0);
        total.totalDataComplete += parseFloat(item.total_complete ?? 0);
        total.totalDataPersentase += parseFloat(item.percent ?? 0);
        return total;
      },
      {
        totalRow: 0,
        totalDataFaskes: 0,
        totalDataMonth: 0,
        totalDataComplete: 0,
        totalDataPersentase: 0,
      }
    );
  };

  let levelName = "";
  if (currentLevel === "national") {
    levelName = "Nasional";
  } else if (currentLevel === "province") {
    levelName = "Provinsi";
  } else if (currentLevel === "district") {
    levelName = "Kabupaten/Kota";
  } else {
    levelName = "Fasyankes";
  }

  let periodeName = "";
  if (reportInfo?.statusPeriode === "yearly") {
    periodeName = "Tahunan";
  } else {
    periodeName = "Bulanan";
  }

  let data = reportInfo?.fasyankes;
  let parts = data?.split(",");
  let faskesName = parts?.map(function (part) {
    return part?.split("|")[1];
  });
  let result = faskesName?.join(", ");

  const renderTableByLevel = () => {
    const total = calculateTotal();

    switch (currentLevel) {
      case UserLevel.National.toLowerCase():
        return (
          <>
            <thead>
              <tr>
                <th>No.</th>
                <th>Nama Provinsi</th>
                <th>Nama Kabupaten/Kota</th>
                <th>Jumlah Faskes</th>
                <th>Jumlah Total Periode Laporan Bulanan</th>
                <th>Jumlah Total Faskes Kelengkapan Laporan Bulanan</th>
                <th>%</th>
              </tr>
            </thead>
            <tbody>
              {reportsData?.length > 0 ? (
                reportsData?.map((item, index) => (
                  <tr key={item?.id}>
                    <td>{index + 1}</td>
                    <td>{item?.province}</td>
                    <td>{item?.district}</td>
                    <td>{item?.total_faskes}</td>
                    <td>{item?.total_month}</td>
                    <td>{item?.total_complete}</td>
                    <td>{item?.percent} %</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <div className="alert alert-danger">Tidak ada data</div>
                </tr>
              )}
              <tr style={{ backgroundColor: "#43766C" }}>
                <td style={{ color: "#FFF" }} colSpan={3}>
                  Total
                </td>
                <td style={{ color: "#FFF" }}>{total.totalDataFaskes}</td>
                <td style={{ color: "#FFF" }}>{total.totalDataMonth}</td>
                <td style={{ color: "#FFF" }}>{total.totalDataComplete}</td>
                <td style={{ color: "#FFF" }}>
                  {(total.totalDataPersentase / total.totalRow).toFixed(2)} %
                </td>
              </tr>
            </tbody>
          </>
        );
      case UserLevel.Province.toLowerCase():
        return (
          <>
            <thead>
              <tr>
                <th>No.</th>
                <th>Nama Kabupaten/Kota</th>
                <th>Jumlah Faskes</th>
                <th>Jumlah Total Periode Laporan Bulanan</th>
                <th>Jumlah Total Faskes Kelengkapan Laporan Bulanan</th>
                <th>%</th>
              </tr>
            </thead>
            <tbody>
              {reportsData?.length > 0 ? (
                reportsData?.map((item, index) => (
                  <tr key={item?.id}>
                    <td>{index + 1}</td>
                    <td>{item?.district}</td>
                    <td>{item?.total_faskes}</td>
                    <td>{item?.total_month}</td>
                    <td>{item?.total_complete}</td>
                    <td>{item?.percent} %</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <div className="alert alert-danger">Tidak ada data</div>
                </tr>
              )}
              <tr style={{ backgroundColor: "#43766C" }}>
                <td style={{ color: "#FFF" }} colSpan={2}>
                  Total
                </td>
                <td style={{ color: "#FFF" }}>{total.totalDataFaskes}</td>
                <td style={{ color: "#FFF" }}>
                  {total.totalDataMonth}
                </td>
                <td style={{ color: "#FFF" }}>
                  {total.totalDataComplete}
                </td>
                <td style={{ color: "#FFF" }}>
                  {(total.totalDataPersentase / total.totalRow).toFixed(2)} %
                </td>
              </tr>
            </tbody>
          </>
        );
      case UserLevel.District.toLowerCase():
        return (
          <>
            <thead>
              <tr>
                <th>No.</th>
                <th>Nama Faskes</th>
                <th>Jumlah Total Periode Laporan Bulanan</th>
                <th>Jumlah Kelengkapan Laporan Bulanan</th>
                <th>%</th>
              </tr>
            </thead>
            <tbody>
              {reportsData?.length > 0 ? (
                reportsData?.map((item, index) => (
                  <tr key={item?.id}>
                    <td>{index + 1}</td>
                    <td>{item?.fasyankes}</td>
                    <td>{item?.total_month}</td>
                    <td>{item?.total_complete}</td>
                    <td>{item?.percent} %</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <div className="alert alert-danger">Tidak ada data</div>
                </tr>
              )}
              <tr style={{ backgroundColor: "#43766C" }}>
                <td style={{ color: "#FFF" }} colSpan={2}>
                  Total
                </td>
                <td style={{ color: "#FFF" }}>
                  {total.totalDataMonth}
                </td>
                <td style={{ color: "#FFF" }}>
                  {total.totalDataComplete}
                </td>
                <td style={{ color: "#FFF" }}>
                  {(total.totalDataPersentase / total.totalRow).toFixed(2)} %
                </td>
              </tr>
            </tbody>
          </>
        );
      default:
        return (
          <>
            <thead>
              <tr>
                <th>No.</th>
                <th>Nama Faskes</th>
                <th>Bulan Pelaporan</th>
                <th>Keterangan</th>
              </tr>
            </thead>
            <tbody>
              {reportsData?.length > 0 ? (
                reportsData?.map((item, index) => (
                  <tr key={item?.id}>
                    <td>{index + 1}</td>
                    <td>{item?.fasyankes}</td>
                    <td>{monthConverter(item?.month)}</td>
                    <td>{item?.ket}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <div className="alert alert-danger">Tidak ada data</div>
                </tr>
              )}
            </tbody>
          </>
        );
    }
  };

  return (
    <div
      className="table-table-responsive"
      style={{ height: "560px", overflow: "scroll" }}
      ref={tableRef}
    >
      <table>
        <tr style={{ border: "none", padding: 0 }}>
          <th style={{ border: "none", paddingBottom: "0" }} colSpan={2}>
            <h3>SISMAL v3, KELENGKAPAN PER BULAN PUBLIK</h3>
          </th>
        </tr>
      </table>
      <table>
        <tr style={{ border: "none" }}>
          <th
            width="200"
            style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}
          >
            Periode Laporan
          </th>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            : {periodeName}
          </th>
        </tr>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            Tahun
          </th>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            : {reportInfo?.periode?.year}
          </th>
        </tr>
        {reportInfo?.statusPeriode === "monthly" && (
          <tr style={{ border: "none" }}>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              Bulan
            </th>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              : {monthConverter(reportInfo?.periode?.month_start)} -{" "}
              {monthConverter(reportInfo?.periode?.month_end)}
            </th>
          </tr>
        )}
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            Level
          </th>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            : {levelName}
          </th>
        </tr>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            Provinsi
          </th>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            :{" "}
            {!reportInfo?.province
              ? "Semua Provinsi"
              : reportInfo?.province?.split("|")[1]}
          </th>
        </tr>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            Kab/Kota
          </th>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            :{" "}
            {!reportInfo?.district
              ? "Semua Kab/Kota"
              : reportInfo?.district?.split("|")[1]}
          </th>
        </tr>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingTop: 0 }}>Fasyankes</th>
          <th style={{ border: "none", paddingTop: 0 }}>
            : {!reportInfo?.fasyankes ? "Semua Fasyankes" : result}
          </th>
        </tr>
      </table>
      <table>{renderTableByLevel()}</table>
    </div>
  );
};

export default TableComponent;
