import { errHandler } from "../utils/common";
import api from "../utils/api";

export default class PeFokus {
  getAll = (opt = { page: 1, limit: 10, search: null }) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/api/pe-focuses/id`, { params: opt })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  add = (payload) => {
    return new Promise((resolve, reject) => {
      api
        .post("/api/pe-focus", payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  update = (payload, userId) => {
    return new Promise((resolve, reject) => {
      api
        .patch(`/api/pe-focus/id/${userId}`, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

  remove = (userId) => {
    return new Promise((resolve, reject) => {
      api
        .delete(`/api/pe-focus/${userId}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          errHandler(err);
        });
    });
  };

}
