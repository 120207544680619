import Button from "components/atoms/Button";
import moment from "moment";
import React, { useState } from "react";
import { Edit, PlusCircle, Trash2 } from "react-feather";
import "./Table.scss";
import InputField from "components/atoms/InputField";

// Require react table lib
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  getPaginationRowModel,
} from "@tanstack/react-table";
import { HashLoader } from "react-spinners";
import { ChevronLeft, ChevronRight } from "react-feather/dist";
import InputDate from "components/atoms/InputDate";
import { PageMode } from "utils/constant";
import { useDispatch } from "react-redux";
import { itemPopulationToEdit } from "redux/actions/populationAction";
import ActionButton from "components/atoms/ActionButton";
import { useHistory } from "react-router-dom";
import TableDataComponent from "components/organisms/TableData";

const Table = ({
  data,
  loading,
  onEdit,
  onRemove,
  onSearch,
  changeMode,
  onPageChange,
  onFilter,
  currentUser,
}) => {
  const { populations, currentPage, limit, nextPage, previousPage, total } =
    data;

  const history = useHistory();

  const [year, setYear] = useState("");
  const dispatch = useDispatch();

  const filterData = () => {
    onFilter(moment(year).format("YYYY"));
  };

  const columns = [
    {
      header: "Aksi",
      accessorKey: "actions",
      cell: ({ row }) => (
        <div className="button-actions">
          <ActionButton onClick={() => onEdit(row.original)} btnType={"edit"} />
          <ActionButton
            onClick={() => onRemove(row.original.id)}
            btnType={"delete"}
          />
        </div>
      ),
    },
    {
      header: "Tahun",
      accessorKey: "year",
    },
    {
      header: "Kode Desa",
      accessorKey: "urbanvillage_id",
      cell: ({ row }) => row.original.urbanvillage_id?.split("|")[0],
    },
    {
      header: "Nama Desa",
      accessorKey: "urbanvillage_name",
      cell: ({ row }) => row.original.urbanvillage_id?.split("|")[1],
    },
    {
      header: "Jumlah Penduduk",
      accessorKey: "total_population",
    },
    {
      header: "Reseptifitas",
      accessorKey: "reseptifitas",
    },
    {
      header: "Status",
      accessorKey: "status",
    },
    {
      header: "Nama Petugas",
      accessorKey: "operator_name",
    },
    {
      header: "No Telepon",
      accessorKey: "phone_number",
    },
    {
      header: "Email",
      accessorKey: "email",
    },
  ];

  const table = useReactTable({
    data: populations,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <div>
      <div className="page-title">Data Dasar Penduduk</div>
      {/* Filter  */}
      <div className="filter-year" style={{ marginTop: "24px" }}>
        <div style={{ width: "300px" }}>
          <InputDate
            name={"year"}
            dateFormat={"YYYY"}
            value={year}
            onChange={(e) => setYear(moment(e).format("YYYY"))}
            placeholder={"Pilih Tahun"}
          />
        </div>
        <Button variant={"primary"} size={"normal"} onClick={filterData}>
          Terapkan
        </Button>
      </div>

      {/* action button */}
      <div className="action-wrapper">
        <div className="action-button">
          <Button
            variant={"primary"}
            size={"normal"}
            onClick={() => {
              changeMode(PageMode.add);
              dispatch(itemPopulationToEdit(null));
            }}
          >
            <PlusCircle style={{ marginRight: "4px" }} />
            Tambah Data Penduduk
          </Button>
        </div>
        <div className="action-search">
          <InputField placeholder={"Cari nama desa..."} onChange={onSearch} />
        </div>
      </div>

      <TableDataComponent
        columnLength={columns?.length}
        tableInstance={table}
        onPageChange={onPageChange}
        loading={loading}
        currentPage={currentPage}
        limit={limit}
        nextPage={nextPage}
        previousPage={previousPage}
        total={total}
      />
    </div>
  );
};

export default Table;
