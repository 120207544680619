import Button from "components/atoms/Button";
import InputCheckbox from "components/atoms/InputCheckbox";
import InputDate from "components/atoms/InputDate";
import InputField from "components/atoms/InputField";
import InputSelect from "components/atoms/InputSelect";
import moment from "moment";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  LabCheckingOptions,
  OperatorOptions,
  RegmalCaseFromOptions,
  YesAndNoOptions,
} from "utils/constant";
const DiscoveryStatusStepForm = ({
  onPrevStep,
  onNextStep,
  cadresData,
  srData,
  ssrData,
  mbsDates,
  surveyContactCases,
  formik,
  getSurveyContacts,
}) => {
  const { currentUser } = useSelector((state) => state.authReducer);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    const value = e.target.value;

    if (value === "P.Mix") {
      const newValues = isChecked ? ["P.Mix"] : [];
      formik.setFieldValue("parasyte", newValues);
    } else {
      const newValues = isChecked
        ? [...formik.values.parasyte, value]
        : formik.values.parasyte.filter((item) => item !== value);
      formik.setFieldValue("parasyte", newValues);
    }
  };

  const discoveryActivitiesOpt = () => {
    if (formik.values.case_from === "Klinik Perdhaki") {
      if (
        (formik.values?.age < 5 && formik.values?.age_type === "Tahun") ||
        (formik.values?.age < 12 && formik.values?.age_type == "Bulan")
      ) {
        return [
          {
            label: "Pasif",
            options: [
              { value: "PCD", label: "PCD" },
              { value: "MBS", label: "MBS" },
              {
                value: "Skrining Balita Sakit/MTBS",
                label: "Skrining Balita Sakit/MTBS",
              },
            ],
          },
          {
            label: "Aktif",
            options: [
              { value: "Kunjungan Rumah", label: "Kunjungan Rumah" },
              {
                value: "Survei Non PE",
                label: "Survei Non PE",
              },
            ],
          },
        ];
      } else if (formik.values.pregnancy === "Hamil") {
        return [
          {
            label: "Pasif",
            options: [
              { value: "PCD", label: "PCD" },
              { value: "MBS", label: "MBS" },
              { value: "Skrining Bumil", label: "Skrining Bumil" },
            ],
          },
          {
            label: "Aktif",
            options: [
              { value: "Kunjungan Rumah", label: "Kunjungan Rumah" },
              {
                value: "Survei Non PE",
                label: "Survei Non PE",
              },
            ],
          },
        ];
      } else {
        return [
          {
            label: "Pasif",
            options: [
              { value: "PCD", label: "PCD" },
              { value: "MBS", label: "MBS" },
            ],
          },
          {
            label: "Aktif",
            options: [
              { value: "Kunjungan Rumah", label: "Kunjungan Rumah" },
              {
                value: "Survei Non PE",
                label: "Survei Non PE",
              },
            ],
          },
        ];
      }
    } else if (
      (formik.values?.age < 5 && formik.values?.age_type === "Tahun") ||
      (formik.values?.age < 12 && formik.values?.age_type == "Bulan")
    ) {
      return [
        {
          label: "Pasif",
          options: [
            { value: "PCD", label: "PCD" },
            {
              value: "Skrining Balita Sakit/MTBS",
              label: "Skrining Balita Sakit/MTBS",
            },
            { value: "FUP", label: "FUP" },
          ],
        },
        {
          label: "Aktif",
          options: [
            { value: "MBS", label: "MBS" },
            { value: "Survei Kontak", label: "Survei Kontak" },
            { value: "Survei Non PE", label: "Survei Non PE" },
            { value: "Kunjungan Rumah", label: "Kunjungan Rumah" },
            {
              value: "Surveilans Migrasi",
              label: "Surveilans Migrasi",
            },
            { value: "Pusling", label: "Pusling" },
            { value: "Posyandu", label: "Posyandu" },
          ],
        },
      ];
    } else if (formik.values.pregnancy === "Hamil") {
      return [
        {
          label: "Pasif",
          options: [
            { value: "PCD", label: "PCD" },
            { value: "Skrining Bumil", label: "Skrining Bumil" },
            { value: "FUP", label: "FUP" },
          ],
        },
        {
          label: "Aktif",
          options: [
            { value: "MBS", label: "MBS" },
            { value: "Survei Kontak", label: "Survei Kontak" },
            { value: "Survei Non PE", label: "Survei Non PE" },
            { value: "Kunjungan Rumah", label: "Kunjungan Rumah" },
            {
              value: "Surveilans Migrasi",
              label: "Surveilans Migrasi",
            },
            { value: "Pusling", label: "Pusling" },
            { value: "Posyandu", label: "Posyandu" },
          ],
        },
      ];
    } else if (
      formik.values.pregnancy === "Hamil" &&
      ((formik.values?.age < 5 && formik.values?.age_type === "Tahun") ||
        (formik.values?.age < 12 && formik.values?.age_type == "Bulan"))
    ) {
      return [
        {
          label: "Pasif",
          options: [
            { value: "PCD", label: "PCD" },
            {
              value: "Skrining Balita Sakit/MTBS",
              label: "Skrining Balita Sakit/MTBS",
            },
            { value: "Skrining Bumil", label: "Skrining Bumil" },
            { value: "FUP", label: "FUP" },
          ],
        },
        {
          label: "Aktif",
          options: [
            { value: "MBS", label: "MBS" },
            { value: "Survei Kontak", label: "Survei Kontak" },
            { value: "Survei Non PE", label: "Survei Non PE" },
            { value: "Kunjungan Rumah", label: "Kunjungan Rumah" },
            {
              value: "Surveilans Migrasi",
              label: "Surveilans Migrasi",
            },
            { value: "Pusling", label: "Pusling" },
            { value: "Posyandu", label: "Posyandu" },
          ],
        },
      ];
    } else {
      return [
        {
          label: "Pasif",
          options: [
            { value: "PCD", label: "PCD" },
            { value: "FUP", label: "FUP" },
          ],
        },
        {
          label: "Aktif",
          options: [
            { value: "MBS", label: "MBS" },
            { value: "Survei Kontak", label: "Survei Kontak" },
            { value: "Survei Non PE", label: "Survei Non PE" },
            { value: "Kunjungan Rumah", label: "Kunjungan Rumah" },
            {
              value: "Surveilans Migrasi",
              label: "Surveilans Migrasi",
            },
            { value: "Pusling", label: "Pusling" },
            { value: "Posyandu", label: "Posyandu" },
          ],
        },
      ];
    }
  };

  const discoveryActivitiesOptNonPKM = () => {
    if (formik.values.age < 5) {
      return [
        {
          label: "Pasif",
          options: [
            { value: "PCD", label: "PCD" },
            {
              value: "Skrining Balita Sakit/MTBS",
              label: "Skrining Balita Sakit/MTBS",
            },
            { value: "FUP", label: "FUP" },
          ],
        },
      ];
    } else if (formik.values.pregnancy === "Hamil") {
      return [
        {
          label: "Pasif",
          options: [
            { value: "PCD", label: "PCD" },
            { value: "Skrining Bumil", label: "Skrining Bumil" },
            { value: "FUP", label: "FUP" },
          ],
        },
      ];
    } else {
      return [
        {
          label: "Pasif",
          options: [
            { value: "PCD", label: "PCD" },
            { value: "FUP", label: "FUP" },
          ],
        },
      ];
    }
  };

  const discoveryActivitiesValue = formik.values.discovery_activities;

  let selectedDiscovertActivty = "";

  if (currentUser?.fasyankes_type_id === "jnsfas-01") {
    const mathingOption = discoveryActivitiesOpt().find((item) => {
      return item.options.some(
        (option) => option.value === discoveryActivitiesValue
      );
    });
    selectedDiscovertActivty = mathingOption?.options?.find(
      (item) => item.value === discoveryActivitiesValue
    );
  } else {
    const mathingOption = discoveryActivitiesOptNonPKM().find((item) => {
      return item.options.some(
        (option) => option.value === discoveryActivitiesValue
      );
    });
    selectedDiscovertActivty = mathingOption?.options?.find(
      (item) => item.value === discoveryActivitiesValue
    );
  }

  const renderOperatorFieldByCondition = () => {
    if (
      formik.values.discovery_activities === "MBS" ||
      formik.values.discovery_activities === "Survei Kontak" ||
      formik.values.discovery_activities === "Survei Non PE" ||
      formik.values.discovery_activities === "Kunjungan Rumah" ||
      formik.values.discovery_activities === "Surveilans Migrasi" ||
      formik.values.discovery_activities === "Skrining Bumil" ||
      formik.values.discovery_activities === "MTBS" ||
      formik.values.discovery_activities === "FUP" ||
      formik.values.discovery_activities === "Posyandu"
    ) {
      return (
        <InputSelect
          label={"Petugas"}
          name={"house_visit"}
          placeholder={"Pilih Petugas"}
          data={OperatorOptions}
          onChange={(e) => {
            const selectedValue = e ? e.value : "";
            formik.handleChange("house_visit")(selectedValue);
            if (selectedValue === "Nakes") {
              formik.setFieldValue("cadres_id", "");
              formik.setFieldValue("isCadre", "");
            }
          }}
          onBlur={formik.handleBlur}
          value={OperatorOptions?.find(
            (item) => item.value === formik.values.house_visit
          )}
          error={formik.errors.house_visit && formik.touched.house_visit}
          errorMessage={formik.errors.house_visit}
          isRequired={true}
        />
      );
    }
  };

  const isButtonDisabled = () => {
    const isCaseFromEmpty =
      !formik.values.case_from ||
      (formik.values.case_from === "Lainnya" && !formik.values.other_case_from);

    const isDiscoveryActivitiesEmpty = !formik.values.discovery_activities;

    const isPatientVisitDateEmpty = !formik.values.patient_visite_date;

    const isLabCheckingTypeWithParasyte =
      formik.values.lab_checking_type === "Mikroskop" ||
      formik.values.lab_checking_type === "RDT";

    const isParasyteEmpty =
      isLabCheckingTypeWithParasyte &&
      (!formik.values.parasyte ||
        formik.values.parasyte.length === 0 ||
        !formik.values.lab_checking_date);

    const isHouseVisitEmpty =
      formik.values.discovery_activities !== "PCD" &&
      formik.values.discovery_activities !== "Pusling" &&
      formik.values.discovery_activities !== "Skrining Balita Sakit/MTBS" &&
      !formik.values.house_visit;

    const isCadreEmpty =
      formik.values.house_visit === "Kader" && !formik.values.isCadre;

    const isCadreIdEmpty =
      formik.values.isCadre === "Ya" && !formik.values.cadres_id?.length;

    const isSrEmpty =
      formik.values.case_from === "Klinik Perdhaki" && !formik.values.sr_id;
    const isSsrEmpty =
      formik.values.case_from === "Klinik Perdhaki" && !formik.values.ssr_id;

    if (currentUser?.fasyankes_type_id === "jnsfas-01") {
      return (
        isCaseFromEmpty ||
        isDiscoveryActivitiesEmpty ||
        isPatientVisitDateEmpty ||
        isParasyteEmpty ||
        isHouseVisitEmpty ||
        isCadreEmpty ||
        isCadreIdEmpty ||
        isSrEmpty ||
        isSsrEmpty
      );
    } else {
      return (
        isDiscoveryActivitiesEmpty ||
        isPatientVisitDateEmpty ||
        isParasyteEmpty ||
        isHouseVisitEmpty ||
        isCadreEmpty ||
        isCadreIdEmpty
      );
    }
  };

  return (
    <>
      <h3 className="subtitle-section">Asal penemuan Kasus</h3>
      {currentUser?.fasyankes_type_id === "jnsfas-01" && (
        <div className="row">
          <div className="col-sm-6">
            <InputSelect
              label={"Asal Penemuan"}
              name={"case_from"}
              placeholder={"Pilih Asal Penemuan"}
              data={RegmalCaseFromOptions}
              onChange={(e) => {
                const selectedValue = e ? e.value : "";
                formik.handleChange("case_from")(selectedValue);
                if (selectedValue !== "Klinik Perdhaki") {
                  formik.setFieldValue("sr_id", "");
                  formik.setFieldValue("ssr_id", "");
                }
                if (selectedValue !== "Lainnya") {
                  formik.setFieldValue("other_case_from", "");
                }
              }}
              onBlur={formik.handleBlur}
              value={RegmalCaseFromOptions?.find(
                (item) => item.value === formik.values.case_from
              )}
              error={formik.errors.case_from && formik.touched.case_from}
              errorMessage={formik.errors.case_from}
              isRequired={true}
            />

            {formik.values.case_from === "Lainnya" && (
              <InputField
                name={"other_case_from"}
                placeholder={"Masukkan Asal Penemuan Lainnya"}
                {...formik.getFieldProps("other_case_from")}
                error={
                  formik.errors.other_case_from &&
                  formik.touched.other_case_from
                }
                errorMessage={formik.errors.other_case_from}
              />
            )}
          </div>
          <div className="col-sm-6">
            <InputSelect
              label={"Kegiatan Penemuan"}
              name={"discovery_activities"}
              placeholder={"Pilih Kegiatan Penemuan"}
              data={discoveryActivitiesOpt()}
              onChange={(e) => {
                const selectedValue = e ? e.value : "";
                formik.handleChange("discovery_activities")(selectedValue);
                if (selectedValue === "PCD" || selectedValue === "Pusling") {
                  formik.setFieldValue("house_visit", "");
                  formik.setFieldValue("isCadre", "");
                  formik.setFieldValue("cadres_id", "");
                  formik.setFieldValue("mbs_date", "");
                  formik.setFieldValue("survey_contact_date", "");
                  formik.setFieldValue("first_case_trigger_survey_contact", "");
                }
              }}
              onBlur={formik.handleBlur}
              value={selectedDiscovertActivty}
              error={
                formik.errors.discovery_activities &&
                formik.touched.discovery_activities
              }
              errorMessage={formik.errors.discovery_activities}
              isRequired={true}
            />
          </div>
        </div>
      )}
      {currentUser?.fasyankes_type_id !== "jnsfas-01" && (
        <InputSelect
          label={"Kegiatan Penemuan"}
          name={"discovery_activities"}
          placeholder={"Pilih Kegiatan Penemuan"}
          data={discoveryActivitiesOptNonPKM()}
          onChange={(e) => {
            const selectedValue = e ? e.value : "";
            formik.handleChange("discovery_activities")(selectedValue);
          }}
          onBlur={formik.handleBlur}
          value={selectedDiscovertActivty}
          error={
            formik.errors.discovery_activities &&
            formik.touched.discovery_activities
          }
          errorMessage={formik.errors.discovery_activities}
          isRequired={true}
        />
      )}
      {formik.values.case_from === "Klinik Perdhaki" && (
        <div className="row">
          <div className="col-sm-6">
            <InputSelect
              label={"Pilih SR"}
              name={"sr_id"}
              placeholder={"Pilih SR"}
              data={srData}
              onChange={(e) => {
                const selectedValue = e ? e.value : "";
                formik.handleChange("sr_id")(selectedValue);
                if (!selectedValue) {
                  formik.setFieldValue("ssr_id", "");
                }
              }}
              onBlur={formik.handleBlur}
              value={
                srData?.find((item) => item.value === formik.values.sr_id) || ""
              }
              error={formik.errors.sr_id && formik.touched.sr_id}
              errorMessage={formik.errors.sr_id}
              isRequired={true}
            />
          </div>
          <div className="col-sm-6">
            <InputSelect
              label={"Pilih SSR"}
              name={"ssr_id"}
              placeholder={"Pilih SSR"}
              data={ssrData}
              onChange={(e) => {
                const selectedValue = e ? e.value : "";
                formik.handleChange("ssr_id")(selectedValue);
              }}
              onBlur={formik.handleBlur}
              value={
                ssrData?.find((item) => item.value === formik.values.ssr_id) ||
                ""
              }
              error={formik.errors.ssr_id && formik.touched.ssr_id}
              errorMessage={formik.errors.ssr_id}
              isRequired={true}
              isDisabled={!formik.values.sr_id}
            />
          </div>
        </div>
      )}
      {formik.values.discovery_activities === "Survei Kontak" && (
        <>
          <InputDate
            label={"Tanggal Survei Kontak"}
            name={"survey_contact_date"}
            placeholder={"Pilih Tanggal Survei Kontak"}
            dateFormat={"YYYY-MM-DD"}
            onChange={(e) => {
              const selectedDate = moment(e).format("YYYY-MM-DD");
              formik.handleChange("survey_contact_date")(selectedDate);
              getSurveyContacts(selectedDate);
            }}
            onBlur={formik.handleBlur}
            value={formik.values.survey_contact_date}
            error={
              formik.errors.survey_contact_date &&
              formik.touched.survey_contact_date
            }
            errorMessage={formik.errors.survey_contact_date}
            isRequired={true}
          />
          <InputSelect
            label={"Kasus pertama yang menjadi pemicu survei kontak"}
            name={"first_case_trigger_survey_contact"}
            placeholder={"Pilih Kasus Kontak"}
            data={surveyContactCases}
            onChange={(e) => {
              const selectedValue = e ? e.value : "";
              formik.handleChange("first_case_trigger_survey_contact")(
                selectedValue
              );
            }}
            onBlur={formik.handleBlur}
            value={surveyContactCases?.find(
              (item) =>
                item.value === formik.values.first_case_trigger_survey_contact
            )}
          />
        </>
      )}
      {formik.values.discovery_activities === "MBS" && (
        <InputSelect
          label={"Tanggal MBS"}
          name={"mbs_date"}
          placeholder={"Pilih Tanggal MBS"}
          data={mbsDates}
          onChange={(e) => {
            const selectedValue = e ? e.value : "";
            formik.handleChange("mbs_date")(selectedValue);
          }}
          onBlur={formik.handleBlur}
          value={mbsDates?.find(
            (item) => item.value === formik.values.mbs_date
          )}
          error={formik.errors.mbs_date && formik.touched.mbs_date}
          errorMessage={formik.errors.mbs_date}
          isRequired={true}
        />
      )}
      {/* Render Operator Field Based on Condition */}
      {renderOperatorFieldByCondition()}
      {formik.values.house_visit === "Kader" && (
        <InputSelect
          label={"Apakah Penemuan Kasus oleh Kader Endemis Tinggi?"}
          name={"isCadre"}
          placeholder={"Pilih"}
          data={YesAndNoOptions}
          onChange={(e) => {
            const selectedValue = e ? e.value : "";
            formik.handleChange("isCadre")(selectedValue);
          }}
          onBlur={formik.handleBlur}
          value={YesAndNoOptions?.find(
            (item) => item.value === formik.values.isCadre
          )}
          error={formik.errors.isCadre && formik.touched.isCadre}
          errorMessage={formik.errors.isCadre}
          isRequired={true}
        />
      )}
      {formik.values.isCadre === "Ya" && (
        <>
          <InputSelect
            label={"Nama Kader"}
            name={"cadres_id"}
            placeholder={"Pilih Kader"}
            data={cadresData}
            onChange={(selectedValue) => {
              formik.setFieldValue("cadres_id", selectedValue);
            }}
            onBlur={formik.handleBlur}
            value={formik.values.cadres_id}
            error={formik.errors.cadres_id && formik.touched.cadres_id}
            errorMessage={formik.errors.cadres_id}
            isMultipleSelect={true}
            isRequired={true}
          />
          <div className="warning-message">
            <div className="message">
              Bila pilihan kader tidak ada isian daftar list-nya, maka terlebih
              dahulu mengisi data kader pada menu Monitoring Kader
            </div>
          </div>
        </>
      )}
      <InputSelect
        label={"Jenis Pemeriksaan"}
        name={"lab_checking_type"}
        placeholder={"Pilih Jenis Pemeriksaan"}
        data={LabCheckingOptions}
        onChange={(e) => {
          const selectedValue = e ? e.value : "";
          formik.handleChange("lab_checking_type")(selectedValue);

          if (selectedValue === "Tanpa Pemeriksaan") {
            formik.setFieldValue("reg_number", "");
            formik.setFieldValue("slide_code", "");
            formik.setFieldValue("total_rdt", "");
            formik.setFieldValue("lab_checking_date", "");
            formik.setFieldValue("parasyte", "");
          }

          if (selectedValue === "Mikroskop") {
            formik.setFieldValue(
              "parasyte",
              formik.values.parasyte &&
                formik.values.parasyte.filter((item) => item !== "P.Mix")
            );
          }
        }}
        onBlur={formik.handleBlur}
        value={LabCheckingOptions?.find(
          (item) => item.value === formik.values.lab_checking_type
        )}
        error={
          formik.errors.lab_checking_type && formik.touched.lab_checking_type
        }
        errorMessage={formik.errors.lab_checking_type}
        isRequired={true}
      />
      {formik.values.lab_checking_type !== "Tanpa Pemeriksaan" && (
        <InputField
          label={"Jumlah RDT yang digunakan"}
          name={"total_rdt"}
          type={"number"}
          placeholder={"Masukkan jumlah RDT yang digunakan"}
          {...formik.getFieldProps("total_rdt")}
          error={formik.errors.total_rdt && formik.touched.total_rdt}
          errorMessage={formik.errors.total_rdt}
          isRequired={true}
        />
      )}
      {formik.values.lab_checking_type === "Mikroskop" && (
        <InputField
          label={"Nomer Registrasi Pasien"}
          name={"reg_number"}
          placeholder={"Masukkan Nomor Registrasi"}
          maxLengthVal={20}
          type={"text"}
          {...formik.getFieldProps("reg_number")}
          error={formik.errors.reg_number && formik.touched.reg_number}
          errorMessage={formik.errors.reg_number}
          isRequired={true}
        />
      )}
      <div className="row">
        <div className="col">
          <InputDate
            label={"Tanggal Kunjungan Pasien"}
            name={"patient_visite_date"}
            placeholder={"Pilih Tanggal"}
            dateFormat={"YYYY-MM-DD"}
            onChange={(e) => {
              const selectedDate = moment(e).format("YYYY-MM-DD");
              formik.handleChange("patient_visite_date")(selectedDate);
            }}
            onBlur={formik.handleBlur}
            value={formik.values.patient_visite_date}
            error={
              formik.errors.patient_visite_date &&
              formik.touched.patient_visite_date
            }
            errorMessage={formik.errors.patient_visite_date}
            isRequired={true}
          />
        </div>
        {formik.values.lab_checking_type !== "Tanpa Pemeriksaan" && (
          <div className="col">
            <InputDate
              label={"Tanggal Pemeriksaan Lab"}
              name={"lab_checking_date"}
              placeholder={"Pilih Tanggal"}
              dateFormat={"YYYY-MM-DD"}
              onChange={(e) => {
                const selectedDate = moment(e).format("YYYY-MM-DD");
                formik.handleChange("lab_checking_date")(selectedDate);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.lab_checking_date}
              error={
                formik.errors.lab_checking_date &&
                formik.touched.lab_checking_date
              }
              errorMessage={formik.errors.lab_checking_date}
              isRequired={true}
            />
          </div>
        )}
      </div>

      {formik.values.lab_checking_type === "Mikroskop" && (
        <InputField
          label={"Kode slide"}
          name={"slide_code"}
          placeholder={"Kode Slide Otomatis"}
          value={formik.values.slide_code}
          disabled={true}
        />
      )}

      {(formik.values.lab_checking_type === "Mikroskop" ||
        formik.values.lab_checking_type === "RDT") && (
        <InputCheckbox
          label="Jenis Parasit"
          name="parasyte"
          options={[
            { label: "P.Falciparum", value: "P.Falciparum" },
            { label: "P.Malariae", value: "P.Malariae" },
            { label: "Probable Knowlesi", value: "Probable Knowlesi" },
            { label: "P.Vivax", value: "P.Vivax" },
            { label: "P.Ovale", value: "P.Ovale" },
            ...(formik.values.lab_checking_type === "RDT"
              ? [{ label: "P.Mix", value: "P.Mix" }]
              : []),
          ]}
          onChange={(e) => handleCheckboxChange(e)}
          onBlur={formik.handleBlur}
          selectedOption={formik.values.parasyte}
          isItalic={true}
          error={formik.errors.parasyte && formik.touched.parasyte}
          errorMessage={formik.errors.parasyte}
          isRequired={true}
        />
      )}
      <hr />
      <div className="form_action">
        <Button variant={"tertiary"} size={"normal"} onClick={onPrevStep}>
          Kembali
        </Button>
        <Button
          variant={"primary"}
          size={"normal"}
          onClick={onNextStep}
          isDisabled={isButtonDisabled()}
        >
          Selanjutnya
        </Button>
      </div>
    </>
  );
};

export default DiscoveryStatusStepForm;
