import React, { Component } from "react";
import UploadSync from "services/UploadSync";
import { Button, InputSelect } from "components";
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";
import { AlertTriangle, ArrowLeft, RefreshCw } from "react-feather";
import { PageMode } from "utils/constant";
import moment from "moment";
import { isEmpty } from "utils/common";

const UploadSyncService = new UploadSync();
const defaultAttr = (e) => {
  return {
    value: e ?? "",
    isValid: true,
    message: "",
  };
};

export default class UploadSyncForm extends Component {
  _isMounted = true;

  constructor(props) {
    super(props);
    this.onDrop = (files) => {
      const fileType = files[0].path?.split(".")[1];
      const errorMsg = [];
      const reader = new FileReader();

      if (fileType === "ev3") {
        reader.readAsText(files[0]);
        reader.onload = (e) => {
          const decoded = atob(e.target.result);
          const dataFile = JSON.parse(decoded);

          if (
            "module" in dataFile &&
            "fasyankes_id" in dataFile &&
            "data" in dataFile
          ) {
            if (dataFile.module !== this.state.module.value) {
              errorMsg.push("Modul yang dipilih dengan file tidak sesuai");
            }
            if (files[0].size > 1024 * 100) {
              errorMsg.push("Ukuran file melebihi 100Kb");
            }
          } else {
            errorMsg.push("File sinkronisasi tidak valid");
          }

          if (errorMsg.length < 1) {
            this.setState({ files });
          } else {
            toast.error(
              <>
                <ul>
                  {errorMsg.map((e, i) => {
                    return <li key={i}>{e}</li>;
                  })}
                </ul>
              </>,
              {
                position: "top-right",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
          }
        };
      } else {
        errorMsg.push("Format file tidak sesuai");
        toast.error(
          <>
            <ul>
              {errorMsg.map((e, i) => {
                return <li key={i}>{e}</li>;
              })}
            </ul>
          </>,
          {
            position: "top-right",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    };
    this.state = {
      loading: true,
      module: defaultAttr(""),

      files: [],
      showFileError: false,
      onEditFileName: "",

      data: [],
      mode: PageMode.view,
    };
  }

  componentDidMount() {
    window.document.title = "SISMAL | Upload Sinkronisasi";

    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  validationHandler = (name, errorMsg) => {
    let isValid = true;
    if (
      this.state[name].value === "" ||
      this.state[name].value === null ||
      this.state[name].value === undefined
    ) {
      this.setState({
        [name]: {
          value: "",
          isValid: false,
          message: `${errorMsg}`,
        },
      });
      isValid = false;
    }
    return isValid;
  };

  onValidate = () => {
    let isValid = true;
    if (!this.validationHandler("module", "Modul wajib dipilih")) {
      isValid = false;
    }
    if (this.state.files.length < 1) {
      isValid = false;
      this.setState({ showFileError: true });
    }
    return isValid;
  };

  onInputChange = (e) => {
    const { name, value } = e.target;
    let obj = Object.assign({}, defaultAttr(value));
    this.setState({ [name]: obj });
  };

  onSubmit = () => {
    if (this.onValidate()) {
      const reader = new FileReader();
      const { files, module } = this.state;
      if (files.length > 0) {
        reader.readAsText(files[0]);
        reader.onload = (e) => {
          const decoded = atob(e.target.result);
          const data = JSON.parse(decoded);
          let moduleUpload;
          let errorMsg = "Data gagal disimpan!";
          switch (module.value) {
            case "regmals":
              moduleUpload = "regmal";
              errorMsg =
                "Data gagal disimpan! Pastikan data Kader pada Regmal sudah terinput sebelumnya";
              break;
            case "second_regmals":
              moduleUpload = "secondRegmal";
              break;
            case "populations":
              moduleUpload = "population";
              break;
            case "mmp_and_cadre_monitoring":
              moduleUpload = "mmp";
              break;
            case "cadre_monitoring":
              moduleUpload = "cadreEndemis";
              break;
            case "zero_reporting":
              moduleUpload = "zeroReport";
              break;
            case "pe_cases":
              moduleUpload = "peCase";
              errorMsg =
                "Data gagal disimpan! Pastikan data Regmal pada PE Kasus sudah terinput sebelumnya";
              break;
            case "logistic_input_stock":
              moduleUpload = "logistictInputStoct";
              break;
            case "logistic_expired_stock":
              moduleUpload = "logistictExpiredStock";
              break;
            case "logistic_off_stock":
              moduleUpload = "logistictOffStock";
              break;
            case "kia_screening":
              moduleUpload = "kiaScreening";
              break;
          }

          if (moduleUpload) {
            this.setState({ loading: true }, async () => {
              await UploadSyncService[moduleUpload](data)
                .then((res) => {
                  if (this._isMounted) {
                    this.setState(
                      {
                        loading: false,
                        module: defaultAttr(""),
                        files: [],
                        showFileError: false,
                        onEditFileName: "",
                      },
                      () => {
                        toast.success(
                          "Data berhasil diupload, mohon cek status sinkronisasi",
                          {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                          }
                        );
                      }
                    );
                  }
                })
                .catch((err) => {
                  toast.error(errorMsg, {
                    position: "top-right",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                });
            });
          }
        };
      }
    }
  };

  alert = (type) => {
    let txt;
    switch (type) {
      case "regmals":
        txt = (
          <>
            Jika terdapat data pada modul <strong>Regmal</strong> yang memilih
            Kader maka pastikan anda sudah mengupload modul
            <strong>Data dan Monitoring Kader</strong> sebelum menguplod modul
            <strong>Regmal</strong>
          </>
        );
        break;
      case "pe_cases":
        txt = (
          <>
            Pastikan anda sudah mengupload modul <strong>Regmal</strong> sebelum
            menguplod modul <strong>PE Kasus</strong>
          </>
        );
        break;

      default:
        return <></>;
    }

    return (
      <p
        className="alert-warning px-2"
        style={{
          marginLeft: "10px",
          marginRight: "10px",
          paddingTop: "6px",
        }}
      >
        <AlertTriangle className="mr-2" size={18} />
        <span>Catatan</span>
        <p style={{ paddingBottom: "6px" }}>{txt}</p>
      </p>
    );
  };

  changeMode = (mode) => {
    this.setState({ mode }, () => {
      if (mode === "History") {
        this.getHistorySync();
      }
    });
  };

  getHistorySync = () => {
    this.setState({ loading: true, selectedItem: null }, async () => {
      await UploadSyncService.getHistorySync()
        .then((data) => {
          if (this._isMounted) {
            this.setState({ loading: false, data });
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({ loading: false });
        });
    });
  };

  render() {
    const { showFileError, files, mode, data, loading, module } = this.state;
    const file = files.map((file) => (
      <li key={file.name}>
        {file.name} - {file.size} bytes
      </li>
    ));

    return (
      <div className="dashboard-wrapper">
        {mode !== PageMode.view && (
          <div
            className="my-3"
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.changeMode(PageMode.view);
            }}
          >
            <ArrowLeft />
            <span>Kembali</span>
          </div>
        )}
        <h1 className="title font-weight-bold">
          {mode === "History" ? "Status Sinkronisasi" : "Upload Sinkronisasi"}
        </h1>
        <div className="content-wrapper mt-4">
          {mode === PageMode.view && (
            <div className="form-wrapper">
              <InputSelect
                label="Modul"
                name="module"
                placeholder="Pilih"
                data={[
                  { value: "populations", label: "Data Dasar Penduduk" },
                  { value: "zero_reporting", label: "Zero Report" },
                  { value: "regmals", label: "Regmal 1" },
                  { value: "second_regmals", label: "Regmal 2" },
                  { value: "pe_cases", label: "PE Kasus" },
                  { value: "mmp_and_cadre_monitoring", label: "MMP" },
                  { value: "cadre_monitoring", label: "Monitoring Kader" },
                  {
                    value: "logistic_input_stock",
                    label: "Logistik Input Stok",
                  },
                  { value: "logistic_off_stock", label: "Logistik Putus Stok" },
                  {
                    value: "logistic_expired_stock",
                    label: "Logistik Expired/Rusak",
                  },
                  { value: "kia_screening", label: "Integrasi Malaria-KIA" },
                ]}
                onChange={(e) => {
                  this.setState({ files: [] });
                  this.onInputChange(e);
                }}
                value={this.state.module.value}
                isRequired={true}
                hasError={!this.state.module.isValid}
                errorMessage={this.state.module.message}
              />

              {this.alert(this.state.module.value)}

              <div className="form-group">
                <label className="required">Upload File</label>
                <Dropzone
                  maxFiles={1}
                  onDrop={this.onDrop}
                  disabled={isEmpty(module.value)}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section className="container">
                      <div {...getRootProps({ className: "dropzone" })}>
                        <input {...getInputProps()} />
                        <div className="dz-message">
                          <div className="icon">
                            <i className="flaticon-file"></i>
                          </div>
                          <h5>Upload file</h5>
                          <div className="note">Drag & Drop or</div>
                          <button
                            disabled={isEmpty(module.value)}
                            className="btn btn-teal"
                          >
                            Pilih File dari perangkat anda
                          </button>
                          {/* <div className="note">Maksimal besar file: 100KB</div> */}
                        </div>
                      </div>
                      <aside>
                        <h4>Files</h4>
                        <ul>{file}</ul>
                        {showFileError && files.length < 1 && (
                          <small className="text-danger">
                            File wajib dipilih
                          </small>
                        )}
                      </aside>
                    </section>
                  )}
                </Dropzone>
              </div>

              <div className="form-group">
                <div className="d-flex justify-content-between">
                  <Button
                    onClick={() => this.changeMode("History")}
                    isSecondary
                  >
                    Status Sinkronisasi
                  </Button>
                  <Button onClick={this.onSubmit} isPrimary>
                    Simpan Data
                  </Button>
                </div>
              </div>
            </div>
          )}
          {mode === "History" && (
            <>
              <div className="d-flex justify-content-start">
                <Button onClick={() => this.getHistorySync()} isSecondary>
                  <RefreshCw className="mr-2" /> Refresh
                </Button>
              </div>
              <div className="mt-4">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Modul</th>
                      <th>Tanggal</th>
                      <th>Jam</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan={4} className="text-center">
                          loading...
                        </td>
                      </tr>
                    ) : (
                      data.length > 0 &&
                      data.map((item, idx) => {
                        return (
                          <tr key={idx}>
                            <td>{item.module_name}</td>
                            <td>
                              {moment(item.createdAt).format("YYYY-MM-DD")}
                            </td>
                            <td>{moment(item.createdAt).format("HH:mm:ss")}</td>
                            <td>{item.status}</td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}
