import { BreadcrumbNav } from "components/molecules";
import React, { useState } from "react";
import { PageMode, Source } from "utils/constant";
import FormStepIndicatorComponent from "./FormStepIndicator";
import PopulationFormStep from "./FormWizard/Population";
import InterventionFormStep from "./FormWizard/Intervention";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import GroupMmp from "services/GroupMmp";
import { errorToastNotif, successToastNotif } from "utils/common";
import Wilayah from "services/Wilayah";
import { useEffect } from "react";

const KelompokMMPService = new GroupMmp();
const WilayahService = new Wilayah();

const FormComponent = ({ pageMode, selectedItem, handlePageModeChange }) => {
  const [formStep, setFormStep] = useState(1);
  const [urbanvillages, setUrbanvillages] = useState([]);
  const [lastMmpId, setLastMmpId] = useState("");
  const [mmpTypeCode, setMmpTypeCode] = useState("");

  const { currentUser } = useSelector((state) => state.authReducer);

  const formik = useFormik({
    initialValues: {
      // state form step 1
      urbanvillage_id: "",
      sub_village: "",
      coordinate: "",
      mmp_type: "",
      group_leader_name: "",
      group_leader_contact: "",
      total_group_member: "",
      group_mmp_id: "",
      fasyankes_distance: "",
      fasyankes_access_desc: "",
      // state form step 2
      cadre_available: "",
      total_cadre: "",
      net_gift: "",
      total_net_distribution: "",
      kie: "",
      routine_mbs: "",
      in_person_monitoring: "",
      out_person_monitoring: "",
    },
    validate: (values) => {
      const errors = {};

      switch (formStep) {
        case 1:
          if (!values.urbanvillage_id) {
            errors.urbanvillage_id = "Desa tidak boleh kosong";
          }
          if (!values.sub_village) {
            errors.sub_village = "Nama dusun tidak boleh kosong";
          }
          if (!values.mmp_type) {
            errors.mmp_type = "Jenis MMP tidak boleh kosong";
          }
          if (!values.group_leader_name) {
            errors.group_leader_name = "Nama ketua kelompok tidak boleh kosong";
          }
          if (!values.group_leader_contact) {
            errors.group_leader_contact =
              "Nomor HP ketua kelompok tidak boleh kosong";
          }
          if (!values.total_group_member) {
            errors.total_group_member =
              "Jumlah anggota kelompok tidak boleh kosong";
          }
          if (!values.group_mmp_id) {
            errors.group_mmp_id = "ID Kelompok MMP tidak boleh kosong";
          }
          break;
        case 2:
          if (values.cadre_available === "Ya") {
            if (!values.total_cadre) {
              errors.total_cadre = "Jumlah kader tidak boleh kosong";
            }
          }
          if (values.net_gift === "Ya") {
            if (!values.total_net_distribution) {
              errors.total_net_distribution =
                "Jumlah kelambu yang di distribusikan tidak boleh kosong";
            }
          }
          if (!values.kie) {
            errors.kie = "KIE wajib diisi";
          }
          if (!values.routine_mbs) {
            errors.routine_mbs = "MBS Rutin wajib diisi";
          }
          if (!values.in_person_monitoring) {
            errors.in_person_monitoring = "Pemeriksaan orang masuk wajib diisi";
          }
          if (!values.out_person_monitoring) {
            errors.out_person_monitoring =
              "Pemeriksaan orang keluar wajib diisi";
          }
          break;
        default:
          return null;
      }
      return errors;
    },
    validateOnMount: false,
    onSubmit: async (values) => {
      try {
        const payload = {
          fasyankes_id: currentUser?.fasyankes_id,
          urbanvillage_id: values.urbanvillage_id,
          sub_village: values.sub_village,
          coordinate: values.coordinate,
          mmp_type: values.mmp_type,
          group_leader_name: values.group_leader_name,
          group_leader_contact: values.group_leader_contact,
          total_group_member: values.total_group_member,
          group_mmp_id: values.group_mmp_id,
          fasyankes_distance: values.fasyankes_distance,
          fasyankes_access_desc: values.fasyankes_access_desc,
          cadre_available: values.cadre_available,
          total_cadre: values.total_cadre,
          net_gift: values.net_gift,
          total_net_distribution: values.total_net_distribution,
          kie: values.kie,
          routine_mbs: values.routine_mbs,
          in_person_monitoring: values.in_person_monitoring,
          out_person_monitoring: values.out_person_monitoring,
        };

        switch (pageMode) {
          case PageMode.add:
            await KelompokMMPService.add(payload)
              .then((res) => {
                handlePageModeChange(PageMode.view);
                successToastNotif("Data Kelompok MMP Berhasil Ditambahkan");
              })
              .catch((err) => {
                console.log(err);
                errorToastNotif(err);
              });
            break;
          case PageMode.edit:
            await KelompokMMPService.update(payload, selectedItem?.id)
              .then((res) => {
                handlePageModeChange(PageMode.view);
                successToastNotif("Data Kelompok MMP Berhasil Diupdate");
              })
              .catch((err) => {
                console.log(err);
                errorToastNotif(err);
              });
            break;
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  const handleNextStep = () => {
    try {
      formik.validateForm();
      if (Object.keys(formik.errors).length === 0) {
        setFormStep(formStep + 1);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePrevStep = () => {
    setFormStep(formStep - 1);
  };

  const getUrbanvillagesBySubdistrict = async () => {
    try {
      const response = await WilayahService.getAll(Source.URBANVILLAGE, {
        searchBy: "subdistrict_id",
        searchValue: currentUser?.subdistrict_id?.split("|")[0],
      });

      const data = response.urbanvillage.map((urbanvillage) => ({
        label: urbanvillage.name,
        value: `${urbanvillage.id}|${urbanvillage.name}`,
      }));

      setUrbanvillages(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getIncrementLastMmpId = async () => {
    try {
      const response = await KelompokMMPService.incrementMmpId();
      setLastMmpId(response);
    } catch (error) {
      console.log(error);
    }
  };

  const generateMmpTypeCode = (value) => {
    console.log(value);
    switch (value) {
      case "Pekerja tambang":
        setMmpTypeCode("01");
        break;
      case "Pekerja hutan":
        setMmpTypeCode("02");
        break;
      case "Pekerja perkebunan":
        setMmpTypeCode("03");
        break;
      case "Indigenous people/Suku pedalaman/KAT":
        setMmpTypeCode("04");
        break;
      case "Pendatang sementara":
        setMmpTypeCode("05");
        break;
      default:
        return null;
    }
  };

  const handleMapChange = (address, coordinate) => {
    const coordinateValue = Object.values(coordinate).toString();
    formik.setFieldValue("coordinate", coordinateValue);
  };

  const generateGroupMmpId = () => {
    const fasyankesId = currentUser?.fasyankes_id?.split("|")[0];
    const urbanvillageId = formik.values?.urbanvillage_id?.split("|")[0];
    const value = `${fasyankesId}/${urbanvillageId}/${mmpTypeCode}/${lastMmpId}`;
    formik.setFieldValue("group_mmp_id", value);
  };

  useEffect(() => {
    getUrbanvillagesBySubdistrict();
    getIncrementLastMmpId();
  }, []);

  useEffect(() => {
    generateGroupMmpId();
  }, [formik.values.urbanvillage_id, formik.values.mmp_type, lastMmpId]);

  useEffect(() => {
    if (pageMode === PageMode.edit) {
      formik.setValues({
        ...selectedItem,
      });
    }
  }, []);

  const renderStepForm = () => {
    switch (formStep) {
      case 1:
        return (
          <PopulationFormStep
            urbanvillages={urbanvillages}
            handlePageModeChange={handlePageModeChange}
            formik={formik}
            lastMmpId={lastMmpId}
            mmpTypeCode={mmpTypeCode}
            generateGroupMmpId={generateGroupMmpId}
            generateMmpTypeCode={generateMmpTypeCode}
            handleMapsChange={handleMapChange}
            handleNextStep={handleNextStep}
          />
        );
      case 2:
        return (
          <InterventionFormStep
            handlePrevStep={handlePrevStep}
            formik={formik}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <BreadcrumbNav
        items={[
          {
            label: "Data Kelompok MMP",
            onClick: () => handlePageModeChange(PageMode.view)
          },
          {
            label:
              pageMode === PageMode.add
                ? "Tambah Data Kelompok MMP"
                : "Edit Data Kelompok MMP",
          },
        ]}
      />

      <h1 className="page-title">
        {pageMode === PageMode.add
          ? "Tambah Data Kelompok MMP"
          : "Edit Data Kelompok MMP"}
      </h1>

      <FormStepIndicatorComponent step={formStep} />

      <div className="form-grouping">
        <p
          className="warning"
          style={{ marginTop: "24px", marginBottom: "24px" }}
        >
          NOTE<span>*(WAJIB DIISI)</span>
        </p>

        {renderStepForm()}

      </div>
    </>
  );
};

export default FormComponent;
