import React from "react";
import { monthConverter } from "utils/common";
import { UserLevel } from "utils/constant";

const TableComponent = ({
  currentLevel,
  reportsData,
  tableRef,
  reportInfo,
}) => {
  const calculateTotal = () => {
    return reportsData.reduce(
      (total, item) => {
        total.totalRow += 1;
        total.totalKasusTervalidasi += parseInt(
          item.indigenous_kasus_tervalidasi ?? 0
        );
        total.totalKasusDilaporkan += parseInt(item.indigenous_kasus ?? 0);
        return total;
      },
      {
        totalRow: 0,
        totalKasusTervalidasi: 0,
        totalKasusDilaporkan: 0,
      }
    );
  };

  let levelName = "";
  if (currentLevel === "national") {
    levelName = "Nasional";
  } else if (currentLevel === "province") {
    levelName = "Provinsi";
  } else if (currentLevel === "district") {
    levelName = "Kabupaten/Kota";
  } else {
    levelName = "Fasyankes";
  }

  let periodeName = "";
  if (reportInfo?.statusPeriode === "yearly") {
    periodeName = "Tahunan";
  } else {
    periodeName = "Bulanan";
  }

  let data = reportInfo?.fasyankes;
  let parts = data?.split(",");
  let faskesName = parts?.map(function (part) {
    return part?.split("|")[1];
  });
  let result = faskesName?.join(", ");

  const renderTableByLevel = () => {
    const total = calculateTotal();

    switch (currentLevel) {
      case UserLevel.National.toLowerCase():
        return (
          <>
            <thead>
              <tr>
                <th>No.</th>
                <th>Nama Provinsi</th>
                <th>Nama Kabupaten/Kota</th>
                <th>Jumlah Kasus Indigenous Yang Dilaporkan</th>
                <th>Jumlah Kasus Indigenous Yang Tervalidasi</th>
                <th>% Kasus Indigenous Tervalidasi</th>
              </tr>
            </thead>
            <tbody>
              {reportsData?.length > 0 ? (
                reportsData?.map((item, index) => (
                  <tr key={item?.id}>
                    <td>{index + 1}</td>
                    <td>{item?.province}</td>
                    <td>{item?.district}</td>
                    <td>{item?.indigenous_kasus}</td>
                    <td>{item?.indigenous_kasus_tervalidasi}</td>
                    <td>{item?.persent} %</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <div className="alert alert-danger">Tidak ada data</div>
                </tr>
              )}
              <tr style={{ backgroundColor: "#43766C" }}>
                <td style={{ color: "#FFF" }} colSpan={3}>
                  Total
                </td>
                <td style={{ color: "#FFF" }}>{total.totalKasusDilaporkan}</td>
                <td style={{ color: "#FFF" }}>{total.totalKasusTervalidasi}</td>
                <td style={{ color: "#FFF" }}>
                  {(
                    (total.totalKasusTervalidasi / total.totalKasusDilaporkan) *
                    100
                  ).toFixed(2)} %
                </td>
              </tr>
            </tbody>
          </>
        );
      case UserLevel.Province.toLowerCase():
        return (
          <>
            <thead>
              <tr>
                <th>No.</th>
                <th>Nama Kabupaten/Kota</th>
                <th>Jumlah Kasus Indigenous Yang Dilaporkan</th>
                <th>Jumlah Kasus Indigenous Yang Tervalidasi</th>
                <th>% Kasus Indigenous Tervalidasi</th>
              </tr>
            </thead>
            <tbody>
              {reportsData?.length > 0 ? (
                reportsData?.map((item, index) => (
                  <tr key={item?.id}>
                    <td>{index + 1}</td>
                    <td>{item?.district}</td>
                    <td>{item?.indigenous_kasus}</td>
                    <td>{item?.indigenous_kasus_tervalidasi}</td>
                    <td>{item?.persent} %</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <div className="alert alert-danger">Tidak ada data</div>
                </tr>
              )}
              <tr style={{ backgroundColor: "#43766C" }}>
                <td style={{ color: "#FFF" }} colSpan={2}>
                  Total
                </td>
                <td style={{ color: "#FFF" }}>{total.totalKasusDilaporkan}</td>
                <td style={{ color: "#FFF" }}>{total.totalKasusTervalidasi}</td>
                <td style={{ color: "#FFF" }}>
                {(
                    (total.totalKasusTervalidasi / total.totalKasusDilaporkan) *
                    100
                  ).toFixed(2)} %
                </td>
              </tr>
            </tbody>
          </>
        );
      case UserLevel.District.toLowerCase():
        return (
          <>
            <thead>
              <tr>
                <th>No.</th>
                <th>Provinsi</th>
                <th>Kabupaten/Kota</th>
                <th>Faskes Pencatat</th>
                <th>Faskes yang melakukan PE</th>
                <th>Nama Pasien</th>
                <th>Tanggal Wawancara Kasus</th>
                <th>Alamat Asal Penularan</th>
                <th>Titik Koordinat Penularan</th>
                <th>Kontak Survey</th>
                <th>Tanggal Survey Kontak</th>
                <th>Kekambuhan</th>
                <th>Penularan</th>
                <th>Asal Penularan</th>
                <th>Status Validasi</th>
              </tr>
            </thead>
            <tbody>
              {reportsData?.length > 0 ? (
                reportsData?.map((item, index) => (
                  <tr key={item?.id}>
                    <td>{index + 1}</td>
                    <td>{item?.province}</td>
                    <td>{item?.district}</td>
                    <td>{item?.fasyankes_pencatat}</td>
                    <td>{item?.fasyankes_pe}</td>
                    <td>{item?.name}</td>
                    <td>{item?.tgl_wawancara}</td>
                    <td>{item?.alamat_penularan}</td>
                    <td>{item?.koordinat}</td>
                    <td>{item?.kontak_survey}</td>
                    <td>{item?.tgl_kontak_survey}</td>
                    <td>{item?.kekambuhan}</td>
                    <td>{item?.asal_penularan}</td>
                    <td>{item?.penularan}</td>
                    <td>{item?.asal_desa_penualran}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <div className="alert alert-danger">Tidak ada data</div>
                </tr>
              )}
            </tbody>
          </>
        );
      default:
        return (
          <>
            <thead>
              <tr>
                <th>No.</th>
                <th>Status</th>
                <th>Nama Faskes</th>
                <th>Nama Pasien</th>
                <th>Asal Desa Penularan</th>
                <th>Asal Penularan</th>
                <th>Kekambuhan</th>
              </tr>
            </thead>
            <tbody>
              {reportsData?.length > 0 ? (
                reportsData?.map((item, index) => (
                  <tr key={item?.id}>
                    <td>{index + 1}</td>
                    <td>{item?.status}</td>
                    <td>{item?.fasyankes_pencatat}</td>
                    <td>{item?.name}</td>
                    <td>{item?.alamat_penularan}</td>
                    <td>{item?.asal_penularan}</td>
                    <td>{item?.kekambuhan}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <div className="alert alert-danger">Tidak ada data</div>
                </tr>
              )}
            </tbody>
          </>
        );
    }
  };

  return (
    <div
      className="table-table-responsive"
      style={{ height: "560px", overflow: "scroll" }}
      ref={tableRef}
    >
      <table>
        <tr style={{ border: "none", padding: 0 }}>
          <th style={{ border: "none", paddingBottom: "0" }} colSpan={2}>
            <h3>SISMAL v3, VALIDASI KASUS INDIGENOUS</h3>
          </th>
        </tr>
      </table>
      <table>
        <tr style={{ border: "none" }}>
          <th
            width="200"
            style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}
          >
            Periode Laporan
          </th>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            : {periodeName}
          </th>
        </tr>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            Tahun
          </th>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            : {reportInfo?.periode?.year}
          </th>
        </tr>
        {reportInfo?.statusPeriode === "monthly" && (
          <tr style={{ border: "none" }}>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              Bulan
            </th>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              : {monthConverter(reportInfo?.periode?.month_start)} -{" "}
              {monthConverter(reportInfo?.periode?.month_end)}
            </th>
          </tr>
        )}
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            Level
          </th>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            : {levelName}
          </th>
        </tr>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            Provinsi
          </th>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            :{" "}
            {!reportInfo?.province
              ? "Semua Provinsi"
              : reportInfo?.province?.split("|")[1]}
          </th>
        </tr>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            Kab/Kota
          </th>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            :{" "}
            {!reportInfo?.district
              ? "Semua Kab/Kota"
              : reportInfo?.district?.split("|")[1]}
          </th>
        </tr>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingTop: 0 }}>Fasyankes</th>
          <th style={{ border: "none", paddingTop: 0 }}>
            : {!reportInfo?.fasyankes ? "Semua Fasyankes" : result}
          </th>
        </tr>
      </table>
      <table>{renderTableByLevel()}</table>
    </div>
  );
};

export default TableComponent;
