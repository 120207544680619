import React, { Component } from "react";
import "./index.scss";
import { BreadcrumbNav } from "components/molecules";
import InputField from "components/atoms/InputField";
import Button from "components/atoms/Button";
import { PlusCircle } from "react-feather";

export default class DataPenduduk extends Component {
  render() {
    return (
      <div className="page-container">
        {/* Breadcumbs */}
        <BreadcrumbNav
          items={[
            { label: "Data Dasar Penduduk", link: "/data-penduduk" },
            { label: "Tambah Data Penduduk", link: "#" },
          ]}
        />
        {/* Heading Title */}
        <h1 className="page-title">Tambah Data Penduduk</h1>
        <div className="data-information">
          <div className="data-container">
            <p className="label">Provinsi</p>
            <p className="value">Aceh</p>
          </div>
          <div className="data-container">
            <p className="label">Kab/Kota</p>
            <p className="value">Simeulue</p>
          </div>
          <div className="data-container">
            <p className="label">Simeulue Timur</p>
            <p className="value">Puskesmas Simeulue Timur</p>
          </div>
        </div>

        {/* Form */}
        <div className="row" style={{ marginTop: "24px" }}>
          <div className="col-6" style={{ marginBottom: "24px" }}>
            <InputField label={"Tahun"} placeholder={"Pilih Tahun"} />
          </div>
          <div className="col-6" style={{ marginBottom: "24px" }}>
            <InputField
              label={"Nama Petugas"}
              placeholder={"Isi nama petugas disini"}
            />
          </div>
          <div className="col-6" style={{ marginBottom: "24px" }}>
            <InputField
              label={"No Telepon"}
              placeholder={"Isi nomor telepon disini"}
            />
          </div>
          <div className="col-6" style={{ marginBottom: "24px" }}>
            <InputField label={"Email"} placeholder={"Isi email disini"} />
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Button
            variant={"secondary"}
            size={"normal"}
            style={{
              border: "1px solid #D0F4F2",
            }}
          >
            <PlusCircle /> Tambah Desa
          </Button>
        </div>

        {/* Action Button */}
        <div className="action-button">
          <Button variant={"tertiary"} size={"normal"}>
            Batalkan
          </Button>
          <Button variant={"primary"} size={"normal"}>
            Simpan Data
          </Button>
        </div>
      </div>
    );
  }
}
