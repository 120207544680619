import React from "react";
import {
  Archive,
  CheckCircle,
  ChevronsRight,
  Grid,
  Home,
  PlusCircle,
  Settings,
  Truck,
  User,
  Users,
  FileText,
  Box,
  TrendingUp,
  Bell,
} from "react-feather";
import { Link } from "react-router-dom";
import { UserLevel } from "utils/constant";
import { getCurrentUserFromLocalStorage } from "../../utils/common";
import SidebarWrapper from "./SidebarWrapper";
import "./Sidebar.scss";
import SideNavMenuComponent from "./SideNavMenu";

export default function Sidebar() {
  const currentUser = getCurrentUserFromLocalStorage();

  return (
    <SidebarWrapper>
      <ul className="nav nav-primary">
        <li className="nav-section">
          <span className="sidebar-mini-icon">
            <i className="fa fa-ellipsis-h" />
          </span>
          <h4 className="text-section">Dashboard</h4>
        </li>
        <SideNavMenuComponent
          path={"/"}
          textMenu={"Dashboard"}
          icon={<Grid size={24} />}
        />
        <SideNavMenuComponent
          path={"/analisis"}
          textMenu={"Analisis"}
          icon={<TrendingUp size={24} />}
        />

        {currentUser && currentUser.role !== "Viewer" && (
          <>
            <SideNavMenuComponent
              path={"/laporan"}
              textMenu={"Laporan"}
              icon={<FileText size={24} />}
            />
            <li className="nav-section border-top">
              <span className="sidebar-mini-icon">
                <i className="fa fa-ellipsis-h" />
              </span>
              <h4 className="text-section">Master Data</h4>
            </li>

            {currentUser && currentUser.level === UserLevel.Fasyankes && (
              <>
                <SideNavMenuComponent
                  path={
                    currentUser?.fasyankes_type_id === "jnsfas-01"
                      ? "/data-dasar-penduduk"
                      : "data-pelapor"
                  }
                  textMenu={
                    currentUser?.fasyankes_type_id === "jnsfas-01"
                      ? "Data Dasar"
                      : "Data Pelapor"
                  }
                  icon={<Users size={24} />}
                />

                <li className="nav-item">
                  <Link to="/zero-report">
                    <FileText size={18} className="mr-2" />
                    <p>Zero Report</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <a data-toggle="collapse" href="#master">
                    <PlusCircle size={18} className="mr-2" />
                    <p>Data Kasus</p>
                    <span className="caret" />
                  </a>
                  <div className="collapse" id="master">
                    <ul className="nav nav-collapse">
                      <li>
                        <Link to="/register-malaria-1">
                          <ChevronsRight className="icon-menu" />
                          <span className="sub-item">Register Malaria 1</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/regmal-rujukan">
                          <ChevronsRight className="icon-menu" />
                          <span className="sub-item">Regmal 1 - Rujukan</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/regmal-dua">
                          <ChevronsRight className="icon-menu" />
                          <span className="sub-item">Register Malaria 2</span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`${
                            currentUser.fasyankes_type_id === "jnsfas-01"
                              ? "/skrining-malaria"
                              : "/data-capaian"
                          }`}
                        >
                          <ChevronsRight className="icon-menu" />
                          <span className="sub-item">
                            Integrasi Malaria-KIA
                          </span>
                        </Link>
                      </li>
                      {currentUser.fasyankes_type_id === "jnsfas-01" && (
                        <>
                          <li>
                            <Link to="/pe-kasus">
                              <ChevronsRight className="icon-menu" />
                              <span className="sub-item">PE Kasus</span>
                            </Link>
                          </li>
                        </>
                      )}
                      <li>
                        <Link to="/notifikasi-pe-feedback">
                          <ChevronsRight className="icon-menu" />
                          <span className="sub-item">
                            Notifikasi PE Feedback
                          </span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>

                {currentUser.fasyankes_type_id === "jnsfas-01" && (
                  <>
                    <SideNavMenuComponent
                      path={"/mmp"}
                      textMenu={"MMP & Monitoring Kader MMP"}
                      icon={<Archive size={24} />}
                    />
                    <SideNavMenuComponent
                      path={"/monitoring-kader"}
                      textMenu={"Data dan Monitoring Kader"}
                      icon={<User size={24} />}
                    />
                    <li className="nav-item">
                      <a data-toggle="collapse" href="#logistik">
                        <Truck size={18} className="mr-2" />
                        <p>Logistik Fasyankes</p>
                        <span className="caret" />
                      </a>
                      <div className="collapse" id="logistik">
                        <ul className="nav nav-collapse">
                          <li>
                            <Link to="/logistik-input-stok">
                              <ChevronsRight className="icon-menu" />
                              <span className="sub-item">Input Stok</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="/logistik-putus-stok">
                              <ChevronsRight className="icon-menu" />
                              <span className="sub-item">Input Putus Stok</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="/logistik-expired-stok">
                              <ChevronsRight className="icon-menu" />
                              <span className="sub-item">
                                Input Expired/Rusak
                              </span>
                            </Link>
                          </li>
                          <li>
                            <Link to="/logistik-input-permintaan">
                              <ChevronsRight className="icon-menu" />
                              <span className="sub-item">Input Permintaan</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="/logistik-rekap-permintaan">
                              <ChevronsRight className="icon-menu" />
                              <span className="sub-item">Rekap Permintaan</span>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </>
                )}

                {currentUser &&
                  currentUser.fasyankes_type_id !== "jnsfas-01" && (
                    <li className="nav-item">
                      <a data-toggle="collapse" href="#logistik">
                        <Truck size={18} className="mr-2" />
                        <p>Logistik Rumah Sakit</p>
                        <span className="caret" />
                      </a>
                      <div className="collapse" id="logistik">
                        <ul className="nav nav-collapse">
                          <li>
                            <Link to="/logistik-input-stok">
                              <ChevronsRight className="icon-menu" />
                              <span className="sub-item">Input Stok</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="/logistik-putus-stok">
                              <ChevronsRight className="icon-menu" />
                              <span className="sub-item">Input Putus Stok</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="/logistik-expired-stok">
                              <ChevronsRight className="icon-menu" />
                              <span className="sub-item">
                                Input Expired/Rusak
                              </span>
                            </Link>
                          </li>
                          <li>
                            <Link to="/logistik-input-permintaan">
                              <ChevronsRight className="icon-menu" />
                              <span className="sub-item">Input Permintaan</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="/logistik-rekap-permintaan">
                              <ChevronsRight className="icon-menu" />
                              <span className="sub-item">Rekap Permintaan</span>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </li>
                  )}

                {currentUser.fasyankes_type_id === "jnsfas-01" && (
                  <>
                    <li className="nav-item">
                      <a data-toggle="collapse" href="#vektor">
                        <Box size={18} className="mr-2" />
                        <p>Vektor</p>
                        <span className="caret" />
                      </a>
                      <div className="collapse" id="vektor">
                        <ul className="nav nav-collapse">
                          <li>
                            <Link to="/data-identifikasi-jentik">
                              <ChevronsRight className="icon-menu" />
                              <span className="sub-item">
                                Identifikasi Jentik & Intervensi Tempat
                                Perindukan
                              </span>
                            </Link>
                          </li>
                          <li>
                            <Link to="/data-identifikasi-nyamuk">
                              <ChevronsRight className="icon-menu" />
                              <span className="sub-item">
                                Data Identifikasi Nyamuk
                              </span>
                            </Link>
                          </li>
                          <li>
                            <Link to="/laporan-pengendalian-vektor">
                              <ChevronsRight className="icon-menu" />
                              <span className="sub-item">
                                Laporan Hasil Pengendalian Vektor
                              </span>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li className="nav-item">
                      <Link to="/umpan-balik-indigenous">
                        <CheckCircle size={18} className="mr-2" />
                        <p>Laporan Data Indigenous</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/upload-sync">
                        <FileText size={18} className="mr-2" />
                        <p>Upload Sinkronisasi</p>
                      </Link>
                    </li>
                  </>
                )}
              </>
            )}

            {currentUser && currentUser.level === UserLevel.District && (
              <>
                <li className="nav-item">
                  <Link to="/data-dasar-faskes">
                    <Home size={18} className="mr-2" />
                    <p>Data Dasar Faskes</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/data-pelapor">
                    <Users size={18} className="mr-2" />
                    <p>Data Pelapor</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/validasi-indigenous">
                    <CheckCircle size={18} className="mr-2" />
                    <p>Validasi Indigenous</p>
                  </Link>
                </li>
                <SideNavMenuComponent
                  path={"/notifikasi-kasus-impor"}
                  textMenu={"Notifikasi Kasus Impor"}
                  icon={<Bell size={24} />}
                />
                <li className="nav-item">
                  <a data-toggle="collapse" href="#logistikKabupaten">
                    <Truck size={18} className="mr-2" />
                    <p>Logistik Kab/Kota</p>
                    <span className="caret" />
                  </a>
                  <div className="collapse" id="logistikKabupaten">
                    <ul className="nav nav-collapse">
                      <li>
                        <Link to="/logistik-kabupaten-input-stok">
                          <ChevronsRight className="icon-menu" />
                          <span className="sub-item">Input Stok</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/logistik-kabupaten-expired-stok">
                          <ChevronsRight className="icon-menu" />
                          <span className="sub-item">Input Expired/Rusak</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/logistik-kabupaten-input-permintaan">
                          <ChevronsRight className="icon-menu" />
                          <span className="sub-item">Input Permintaan</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/logistik-pemenuhan-permintaan-faskes">
                          <ChevronsRight className="icon-menu" />
                          <span className="sub-item">
                            Pemenuhan Permintaan Faskes
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/logistik-kabupaten-rekap-permintaan">
                          <ChevronsRight className="icon-menu" />
                          <span className="sub-item">Rekap Permintaan</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
                {/* <li className="nav-section">
              <span className="sidebar-mini-icon">
                <i className="fa fa-ellipsis-h" />
              </span>
              <h4 className="text-section">Administrator Menu</h4>
            </li> */}
                {/* <li className="nav-item">
              <a data-toggle="collapse" href="#administrator">
                <Settings size={18} className="mr-2" />
                <p>Administrator</p>
                <span className="caret" />
              </a>
              <div className="collapse" id="administrator">
                <ul className="nav nav-collapse">
                  <li>
                    <Link to="/user-verification">
                      <Users className="icon-menu" />
                      <span className="sub-item">Verifikasi User</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </li> */}
              </>
            )}

            {currentUser && currentUser.level === UserLevel.Province && (
              <>
                <li className="nav-item">
                  <Link to="/data-pelapor">
                    <Users size={18} className="mr-2" />
                    <p>Data Pelapor</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <a data-toggle="collapse" href="#logistikProvinsi">
                    <Truck size={18} className="mr-2" />
                    <p>Logistik Provinsi</p>
                    <span className="caret" />
                  </a>
                  <div className="collapse" id="logistikProvinsi">
                    <ul className="nav nav-collapse">
                      <li>
                        <Link to="/logistik-provinsi-input-stok">
                          <ChevronsRight className="icon-menu" />
                          <span className="sub-item">Input Stok</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/logistik-provinsi-expired-stok">
                          <ChevronsRight className="icon-menu" />
                          <span className="sub-item">Input Expired/Rusak</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/logistik-provinsi-input-permintaan">
                          <ChevronsRight className="icon-menu" />
                          <span className="sub-item">Input Permintaan</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/logistik-pemenuhan-permintaan-kabupaten">
                          <ChevronsRight className="icon-menu" />
                          <span className="sub-item">
                            Pemenuhan Permintaan Kab
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/logistik-provinsi-rekap-permintaan">
                          <ChevronsRight className="icon-menu" />
                          <span className="sub-item">Rekap Permintaan</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
              </>
            )}

            {currentUser && currentUser.level === UserLevel.National && (
              <>
                <li className="nav-item">
                  <Link to="/data-endemisitas">
                    <Users size={18} className="mr-2" />
                    <p>Data Endemisitas</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/data-dasar-penduduk">
                    <Users size={18} className="mr-2" />
                    <p>Data Dasar Penduduk</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <a data-toggle="collapse" href="#logistikPusat">
                    <Truck size={18} className="mr-2" />
                    <p>Logistik Pusat</p>
                    <span className="caret" />
                  </a>
                  <div className="collapse" id="logistikPusat">
                    <ul className="nav nav-collapse">
                      <li>
                        <Link to="/logistik-pemenuhan-permintaan-provinsi">
                          <ChevronsRight className="icon-menu" />
                          <span className="sub-item">
                            Pemenuhan Permintaan Provinsi
                          </span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="nav-section">
                  <span className="sidebar-mini-icon">
                    <i className="fa fa-ellipsis-h" />
                  </span>
                  <h4 className="text-section">Administrator Menu</h4>
                </li>
                <li className="nav-item">
                  <a data-toggle="collapse" href="#administrator">
                    <Settings size={18} className="mr-2" />
                    <p>Administrator</p>
                    <span className="caret" />
                  </a>
                  <div className="collapse" id="administrator">
                    <ul className="nav nav-collapse">
                      <li>
                        <Link to="/user">
                          <Users className="icon-menu" />
                          <span className="sub-item">Pengguna</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/user-verification">
                          <Users className="icon-menu" />
                          <span className="sub-item">Verifikasi User</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
              </>
            )}
          </>
        )}
      </ul>
    </SidebarWrapper>
  );
}
