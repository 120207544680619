import InputDate from "components/atoms/InputDate";
import InputRadio from "components/atoms/InputRadio";
import InputSelect from "components/atoms/InputSelect";
import { BreadcrumbNav } from "components/molecules";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { ArrowDown, ArrowUp } from "react-feather";
import { useSelector } from "react-redux";
import {
  LogisticInputStockOptions,
  MonthOptions,
  Source,
  UserLevel,
} from "utils/constant";
import Wilayah from "services/Wilayah";
import Fasyankes from "services/Fasyankes";
import Laporan from "services/Laporan";
import Button from "components/atoms/Button";
import { HashLoader } from "react-spinners";
import TableComponent from "./Table";
import { exportToExcel } from "utils/exportExcel";
import { generateMonthOptions } from "utils/common";
import { useHistory } from "react-router-dom";

const WilayahService = new Wilayah();
const FasyankesService = new Fasyankes();
const LaporanService = new Laporan();

const DetailLaporan = () => {
  const module = "Validasi Kasus Indigenous";

  const { currentUser } = useSelector((state) => state.authReducer);
  const tableRef = useRef(null);

  const history = useHistory();

  const [reportData, setReportData] = useState([]);
  const [showFilter, setShowFilter] = useState(true);
  const [showData, setShowData] = useState(false);
  const [levelData, setLevelData] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState(currentUser.level);
  const [currentLevel, setCurrentLevel] = useState("");
  const [levelIdValue, setLevelIdValue] = useState("");
  const [levelDataOptions, setLevelDataOptions] = useState({
    provinces: [],
    districts: [],
    fasyankes: [],
  });
  const [selectedProvince, setSelectedProvince] = useState(
    currentUser?.province_id
  );
  const [selectedDistrict, setSelectedDistrict] = useState(
    currentUser?.district_id
  );
  const [selectedFasyankes, setSelectedFasyankes] = useState(
    currentUser?.fasyankes_id
  );
  const [selectedStart, setSelectedStart] = useState("");
  const [selectedEnd, setSelectedEnd] = useState("");
  const [periodeLaporan, setPeriodeLaporan] = useState("yearly");
  const [periodeLaporanValue, setPeriodeLaporanValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [reportInfo, setReportInfo] = useState({});

  const fetchAllProvincies = async () => {
    try {
      const response = await WilayahService.getAll(Source.PROVINCE, {
        page: 1,
        limit: 9999,
      });
      setLevelDataOptions((prevState) => ({
        ...prevState,
        provinces: response.province.map((item) => {
          return {
            label: item.name,
            value: `${item.id}|${item.name}`,
          };
        }),
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDistrictByProvince = async (provinceId) => {
    try {
      const response = await WilayahService.getAll(Source.DISTRICT, {
        page: 1,
        limit: 9999,
        searchBy: "province_id",
        searchValue: provinceId,
      });

      setLevelDataOptions((prevState) => ({
        ...prevState,
        districts: response.district.map((item) => {
          return {
            label: item.name,
            value: `${item.id}|${item.name}`,
          };
        }),
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const fetchFasyankesByDistrict = async (districtId) => {
    try {
      const response = await FasyankesService.getAll(Source.FASYANKES, {
        page: 1,
        limit: 9999,
        searchBy: "district_id",
        searchValue: districtId,
      });

      setLevelDataOptions((prevState) => ({
        ...prevState,
        fasyankes: response.fasyankes.map((item) => {
          return {
            label: item.name,
            value: `${item.id}|${item.name}`,
          };
        }),
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const generateLevelOptions = (userLevel) => {
    switch (userLevel) {
      case UserLevel.Fasyankes:
        return [{ label: "Fasyankes", value: UserLevel.Fasyankes }];
      case UserLevel.District:
        return [
          { label: "Kabupaten/Kota", value: UserLevel.District },
          { label: "Fasyankes", value: UserLevel.Fasyankes },
        ];
      case UserLevel.Province:
        return [
          { label: "Provinsi", value: UserLevel.Province },
          { label: "Kabupaten/Kota", value: UserLevel.District },
          { label: "Fasyankes", value: UserLevel.Fasyankes },
        ];
      default:
        return [
          { label: "Nasional", value: UserLevel.National },
          { label: "Provinsi", value: UserLevel.Province },
          { label: "Kabupaten/Kota", value: UserLevel.District },
          { label: "Fasyankes", value: UserLevel.Fasyankes },
        ];
    }
  };

  useEffect(() => {
    if (currentUser.level === UserLevel.District) {
      setSelectedFasyankes("");
    } else if (currentUser.level === UserLevel.Province) {
      setSelectedDistrict("");
      setSelectedFasyankes("");
    } else if (currentUser.level === UserLevel.National) {
      setSelectedProvince("");
      setSelectedDistrict("");
      setSelectedFasyankes("");
    }
  }, [selectedLevel]);

  useEffect(() => {
    if (currentUser.level === UserLevel.Fasyankes) {
      setLevelIdValue(currentUser?.fasyankes_id);
    } else if (currentUser.level === UserLevel.District) {
      setLevelIdValue(currentUser?.district_id);
    } else if (currentUser.level === UserLevel.Province) {
      setLevelIdValue(currentUser?.province_id);
    } else {
      setLevelIdValue("");
    }
  }, []);

  useEffect(() => {
    fetchAllProvincies();

    if (selectedProvince) {
      fetchDistrictByProvince(selectedProvince?.split("|")[0]);
    }
    if (selectedDistrict) {
      fetchFasyankesByDistrict(selectedDistrict?.split("|")[0]);
    }
  }, [selectedProvince, selectedDistrict]);

  useEffect(() => {
    const levelOptions = generateLevelOptions(currentUser.level);
    setLevelData(levelOptions);
  }, [currentUser]);

  // periode laporan inputan
  const renderPeriodeFilter = () => {
    switch (periodeLaporan) {
      case "yearly":
        return (
          <InputDate
            value={periodeLaporanValue}
            onChange={(e) => {
              const selectedYear = moment(e).format("YYYY");
              setPeriodeLaporanValue({
                year: selectedYear,
                month_start: "01",
                month_end: "12",
              });
            }}
            placeholder={"Pilih Tahun"}
            dateFormat={"YYYY"}
          />
        );
      case "monthly":
        return (
          <div style={{ display: "flex", flexDirection: "row", gap: "1.2rem" }}>
            <div style={{ flex: "1" }}>
              <InputDate
                value={periodeLaporanValue}
                onChange={(e) => {
                  const selectedYear = moment(e).format("YYYY");
                  setPeriodeLaporanValue((prevPeriode) => ({
                    ...prevPeriode,
                    year: selectedYear,
                  }));
                }}
                placeholder={"Pilih Tahun"}
                dateFormat={"YYYY"}
              />
            </div>
            <div style={{ flex: "1" }}>
              <InputSelect
                data={MonthOptions}
                isClearable={false}
                placeholder={"Bulan Mulai"}
                onChange={(e) => {
                  setPeriodeLaporanValue((prevPeriode) => ({
                    ...prevPeriode,
                    month_start: e.value,
                  }));
                  setSelectedStart(e.value);
                  setSelectedEnd("");
                }}
              />
            </div>
            <div style={{ flex: "1" }}>
              <InputSelect
                data={generateMonthOptions(periodeLaporanValue.month_start)}
                isClearable={false}
                placeholder={"Bulan Akhir"}
                onChange={(e) => {
                  setPeriodeLaporanValue((prevPeriode) => ({
                    ...prevPeriode,
                    month_end: e.value,
                  }));
                  setSelectedEnd(e.value);
                }}
                value={
                  generateMonthOptions(periodeLaporanValue.month_start)?.find(
                    (item) => item.value === selectedEnd
                  ) || ""
                }
              />
            </div>
          </div>
        );
    }
  };

  // level options
  const renderLevelFilter = () => {
    switch (selectedLevel) {
      case UserLevel.Province:
        return (
          <div style={{ display: "flex", flexDirection: "row", gap: "1.2rem" }}>
            <div style={{ flex: "1" }}>
              <InputSelect
                isClearable={false}
                placeholder={"Pilih Provinsi"}
                data={levelDataOptions.provinces}
                onChange={(e) => {
                  setSelectedProvince(e.value);
                  setLevelIdValue(e.value);
                }}
                value={
                  levelDataOptions.provinces?.find(
                    (item) => item.value === selectedProvince
                  ) || ""
                }
                isDisabled={
                  currentUser.level === UserLevel.National ? false : true
                }
              />
            </div>
          </div>
        );
      case UserLevel.District:
        return (
          <div style={{ display: "flex", flexDirection: "row", gap: "1.2rem" }}>
            <div style={{ flex: "1" }}>
              <InputSelect
                isClearable={false}
                placeholder={"Pilih Provinsi"}
                data={levelDataOptions.provinces}
                onChange={(e) => {
                  setSelectedProvince(e.value);
                  setSelectedDistrict("");
                }}
                value={
                  levelDataOptions.provinces?.find(
                    (item) => item.value === selectedProvince
                  ) || ""
                }
                isDisabled={
                  currentUser.level === UserLevel.National ? false : true
                }
              />
            </div>
            <div style={{ flex: "1" }}>
              <InputSelect
                isClearable={false}
                placeholder={"Pilih Kab/Kota"}
                data={
                  currentUser?.level === UserLevel.District
                    ? levelDataOptions.districts?.filter(
                      (item) => item.value === currentUser?.district_id
                    )
                    : levelDataOptions.districts
                }
                onChange={(e) => {
                  setSelectedDistrict(e.value);
                  setLevelIdValue(e.value);
                }}
                value={
                  levelDataOptions.districts?.find(
                    (item) => item.value === selectedDistrict
                  ) || ""
                }
                isDisabled={
                  currentUser.level === UserLevel.National ||
                    currentUser.level === UserLevel.Province
                    ? false
                    : true
                }
              />
            </div>
          </div>
        );
      case UserLevel.Fasyankes:
        return (
          <div style={{ display: "flex", flexDirection: "row", gap: "1.2rem" }}>
            <div style={{ flex: "1" }}>
              <InputSelect
                isClearable={false}
                placeholder={"Pilih Provinsi"}
                data={levelDataOptions.provinces}
                onChange={(e) => {
                  setSelectedProvince(e.value);
                  setSelectedFasyankes("");
                  setSelectedDistrict("");
                }}
                value={
                  levelDataOptions.provinces?.find(
                    (item) => item.value === selectedProvince
                  ) || ""
                }
                isDisabled={
                  currentUser?.level === UserLevel.National ? false : true
                }
              />
            </div>
            <div style={{ flex: "1" }}>
              <InputSelect
                isClearable={false}
                placeholder={"Pilih Kab/Kota"}
                data={levelDataOptions.districts}
                onChange={(e) => {
                  setSelectedDistrict(e.value);
                  setSelectedFasyankes("");
                }}
                value={
                  levelDataOptions.districts?.find(
                    (item) => item.value === selectedDistrict
                  ) || ""
                }
                isDisabled={
                  currentUser?.level === UserLevel.National ||
                    currentUser?.level === UserLevel.Province
                    ? false
                    : true
                }
              />
            </div>
            <div style={{ flex: "1" }}>
              <InputSelect
                isClearable={false}
                isMultipleSelect={true}
                placeholder={"Pilih Fasyankes"}
                data={
                  currentUser.level === UserLevel.Fasyankes
                    ? levelDataOptions.fasyankes?.filter(
                      (item) => item.value === currentUser?.fasyankes_id
                    )
                    : levelDataOptions.fasyankes
                }
                onChange={(e) => {
                  let value = e.map(function (item) {
                    return item.value;
                  });
                  let resultValue = value.join(",");
                  setSelectedFasyankes(resultValue);
                  setLevelIdValue(resultValue);
                }}
                value={levelDataOptions.fasyankes?.filter((item) =>
                  selectedFasyankes?.includes(item.value)
                )}
                isDisabled={
                  currentUser?.level === UserLevel.National ||
                    currentUser?.level === UserLevel.Province ||
                    currentUser?.level === UserLevel.District
                    ? false
                    : true
                }
              />
            </div>
          </div>
        );
    }
  };

  const handleApplyFilter = async () => {
    setIsLoading(true);
    setShowData(false);

    const params = {
      level: selectedLevel.toLowerCase(),
      level_id: levelIdValue,
      period: periodeLaporan,
      year: periodeLaporanValue.year,
      date_start: `${periodeLaporanValue.year}-${periodeLaporanValue.month_start}`,
      date_end: `${periodeLaporanValue.year}-${periodeLaporanValue.month_end}`,
      is_private: false,
    };
    try {
      const response = await LaporanService.ValidasiKasusIndigenous(params);
      setReportInfo({
        statusPeriode: periodeLaporan,
        periode: periodeLaporanValue,
        province: selectedProvince,
        district: selectedDistrict,
        fasyankes: selectedFasyankes,
      });
      setReportData(response);
      setCurrentLevel(params.level);
    } catch (error) {
      console.log(error);
      setReportData([]);
    } finally {
      setIsLoading(false);
      setShowData(true);
    }
  };

  return (
    <div className="dashboard-wrapper">
      <BreadcrumbNav
        items={[
          { label: "Laporan", onClick: () => history.push("/laporan") },
          { label: module },
        ]}
      />
      <div className="filter">
        <div className="filter-header">
          <p>FILTER</p>
          <button
            className="btn_toggle_filter"
            onClick={() => setShowFilter(!showFilter)}
          >
            {showFilter ? <ArrowUp size={18} /> : <ArrowDown size={18} />}
          </button>
        </div>
        {showFilter && (
          <div className="filter-body">
            <div className="filter-item">
              <div className="filter-label">
                <p>Periode Laporan</p>
              </div>
              <div className="filter-field">
                <InputRadio
                  isRequired={true}
                  options={[
                    { label: "Tahunan", value: "yearly" },
                    { label: "Bulanan", value: "monthly" },
                  ]}
                  onChange={(e) => {
                    setPeriodeLaporan(e.target.value);
                    setPeriodeLaporanValue("");
                  }}
                  selectedOption={periodeLaporan}
                />
              </div>
            </div>
            <div className="filter-item">
              <div className="filter-label" />
              <div className="filter-field">
                <div className="advanced-filter">{renderPeriodeFilter()}</div>
              </div>
            </div>
            <div className="filter-item">
              <div className="filter-label">
                <p>Level</p>
              </div>
              <div className="filter-field">
                <InputRadio
                  options={levelData}
                  onChange={(e) => {
                    setSelectedLevel(e.target.value);

                    if (e.target.value != currentUser.level) {
                      setLevelIdValue("");
                    } else {
                      if (currentUser.level === UserLevel.Fasyankes) {
                        setLevelIdValue(currentUser?.fasyankes_id);
                      } else if (currentUser.level === UserLevel.District) {
                        setLevelIdValue(currentUser?.district_id);
                      } else if (currentUser.level === UserLevel.Province) {
                        setLevelIdValue(currentUser?.province_id);
                      } else {
                        setLevelIdValue("");
                      }
                    }
                  }}
                  selectedOption={selectedLevel}
                />
                <div className="advanced-filter">{renderLevelFilter()}</div>
              </div>
            </div>
            <div className="filter-item">
              <div className="filter-field">
                <div className="filter-condition">
                  <Button
                    variant={"primary"}
                    size={"normal"}
                    onClick={() => handleApplyFilter()}
                    isDisabled={
                      !selectedLevel ||
                      !periodeLaporan ||
                      !periodeLaporanValue ||
                      (selectedLevel.toLowerCase() !== "national" &&
                        !levelIdValue)
                    }
                  >
                    Terapkan
                  </Button>
                  {showData && (
                    <Button
                      variant={"secondary"}
                      size={"normal"}
                      onClick={() =>
                        exportToExcel(tableRef,
                          `LAPORAN_VALIDASI_KASUS_INDIGENOUS_${selectedLevel.toLowerCase() == "national" ? "Nasional" : levelIdValue?.split("|")[1]}_${moment().format('DDMMYYYY')}.xlsx`,
                          "Sheet 1")
                      }
                    >
                      Export Excel
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* loading spinner */}
      {isLoading && !showData && (
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            marginTop: "5rem",
          }}
        >
          <HashLoader size={100} color="#18b3ab" />
        </div>
      )}

      {showData && (
        <div className="table-container">
          <div className="data-title">
            <h2>{module}</h2>
          </div>
          <div className="data-main">
            <TableComponent
              currentLevel={currentLevel}
              reportsData={reportData}
              tableRef={tableRef}
              reportInfo={reportInfo}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default DetailLaporan;
