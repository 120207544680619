import React, { Component } from "react";
import { PlusCircle } from "react-feather";
import { Button, InputSelect, TableComponent } from "components";
import InputDateTime from "components/InputDateTime";
import * as XLSX from "xlsx";
import moment from "moment";

export default class Table extends Component {
  constructor(props) {
    super(props);
  }

  mapper = (e) => {
    const options = [];
    e.map((x) => {
      options.push({ value: `${x.id}|${x.name}`, label: x.name });
    });
    return options;
  };

  exportToExcel = () => {
    const { dataExport } = this.props;

    if (!dataExport || !dataExport.endemisitas || dataExport.endemisitas.length === 0) {
      alert("No dataExport available to export");
      return;
    }

    const headers = [
      "Tahun",
      "Provinsi",
      "Kabupaten",
      "Jumlah Penduduk",
      "Kab/Kota Eliminasi Malaria",
      "Endemisitas Tahun Sebelumnya",
    ];

    const worksheetData = dataExport.endemisitas.map((row) => ({
      Tahun: row.year,
      Provinsi: row.province.name,
      Kabupaten: row.district.name,
      "Jumlah Penduduk": row.population,
      "Kab/Kota Eliminasi Malaria": row.eliminasi,
      "Endemisitas Tahun Sebelumnya": row.endemisitas_year_before,
    }));

    const worksheet = XLSX.utils.json_to_sheet(worksheetData, { header: headers });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Endemisitas");

    // Save the workbook
    XLSX.writeFile(workbook, "Endemisitas.xlsx");
  };

  render() {
    const {
      data,
      changeMode,
      loading,
      onEdit,
      onRemove,
      onPageChange,
      handleInputChange,
      year,
      onFilter,
      provincies,
      province_id,
    } = this.props;

    return (
      <div>
        <hr />
        <div className="row">
          <div className="col">
            <div className="form-inline">
              <InputDateTime
                name="year"
                dateFormat="YYYY"
                onChange={(e) =>
                  handleInputChange({
                    preventDefault: () => {},
                    target: { name: "year", value: moment(e).format("YYYY") },
                  })
                }
                placeholder="Pilih Tahun"
                value={year}
              />
              <InputSelect
                label="Provinsi"
                name="province_id"
                placeholder="Pilih"
                data={this.mapper(provincies)}
                onChange={handleInputChange}
                value={province_id}
                isInline={true}
              />
              <div className="form-group d-flex">
                <Button onClick={onFilter} isPrimary>
                  Terapkan
                </Button>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-sm-8" style={{ padding: "10px" }}>
            <button
              className="btn btn-teal font-weight-bold"
              onClick={() => changeMode("add")}
            >
              <PlusCircle className="mr-2" />
              Tambah Data
            </button>
            <button
              className="btn btn-success font-weight-bold ml-2"
              onClick={this.exportToExcel}
            >
              Export to Excel
            </button>
          </div>
        </div>
        <TableComponent
          loading={loading}
          data={data?.endemisitas}
          headers={[
            "Tahun",
            "Provinsi",
            "Kabupaten",
            "Jumlah Penduduk",
            "Kab/Kota Eliminasi Malaria",
            "Endemisitas Tahun Sebelumnya",
          ]}
          cols={[
            "year",
            "province.name",
            "district.name",
            "population",
            "eliminasi",
            "endemisitas_year_before",
          ]}
          btnEdit={{ onClick: onEdit }}
          btnRemove={{ onClick: onRemove }}
          currentPage={data?.currentPage}
          total={data?.total}
          limit={data?.limit}
          nextPage={data?.nextPage}
          previousPage={data?.previousPage}
          onPageChange={onPageChange}
        />
      </div>
    );
  }
}
