import React from "react";
import { PageMode } from "utils/constant";
import TableComponent from "./components/Table";

const DataFaskesAsal = ({ pageMode, changeMode }) => {
  const handlePageModeChange = (page) => {
    changeMode(page);
  };

  return (
    <>
      {pageMode === PageMode.view && (
        <TableComponent handlePageModeChange={handlePageModeChange} />
      )}
    </>
  );
};

export default DataFaskesAsal;
