import React, { Component } from "react";
import { ErrorMessage, PageMode } from "utils/constant";
import Table from "./components/Table";
import Form from "./components/Form";

import ZeroReport from "services/ZeroReport";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import { ArrowLeft } from "react-feather";
import Datetime from "react-datetime";
import { connect } from "react-redux";

const ZeroReportService = new ZeroReport();

class ZeroReporting extends Component {
  _isMounted = true;

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      limit: 10,
      data: [],
      mode: PageMode.view,
      loading: true,
      search: "",
      selecetdItem: null,
      currentPage: 1,
      selected_year: "",
      selected_month: "",
    };
  }

  componentDidMount() {
    document.title = "SISMAL | Zero Report";

    this._isMounted = true;
    this.getAllZeroReports();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  changeMode = (mode) => {
    this.setState({ mode }, () => {
      if (mode === PageMode.view) this.getAllZeroReports();
    });
  };

  getAllZeroReports = () => {
    this.setState({ loading: true, selectedItem: null }, async () => {
      await ZeroReportService.getAll({
        page: this.state.page,
        limit: this.state.limit,
        searchBy: "fasyankes_id,year,month",
        searchValue: `${this.props.user.fasyankes_id},${this.state.selected_year},${this.state.selected_month}`,
      })
        .then((data) => {
          if (this._isMounted) {
            this.setState({ loading: false, data });
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({ loading: false });
        });
    });
  };

  onFilterData = () => {
    this.setState({ loading: true, selectedItem: null, page: 1 }, async () => {
      await ZeroReportService.getAll({
        page: this.state.page,
        limit: this.state.limit,
        searchBy: "year,month,fasyankes_id",
        searchValue: `${this.state.selected_year},${this.state.selected_month},${this.props.user.fasyankes_id}`,
      })
        .then((data) => {
          if (this._isMounted) {
            this.setState({ loading: false, data });
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({ loading: false });
        });
    });
  };

  onEdit = (item) => {
    this.setState({ selecetdItem: item }, () => {
      this.changeMode(PageMode.edit);
    });
  };

  onRemove = async (id) => {
    confirmAlert({
      title: "Anda yakin ingin Menghapus data?",
      message: "Are you sure want to delete this data?",
      buttons: [
        {
          label: "Ya",
          onClick: () => {
            ZeroReportService.remove(id)
              .then((res) => {
                this.getAllZeroReports();
                toast.success("Data Zero Report Berhasil Dihapus", {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              })
              .catch((err) => {
                console.log(err);

                if (err.resonse.data.message === ErrorMessage.UserNotVerified) {
                  toast.error("Akun anda belum diverifikasi", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                }

                if (
                  err.response.data.message ===
                  ErrorMessage.UserFaskesNotHaveAccess
                ) {
                  toast.error("Akun anda tidak memiliki akses", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                }
              });
          },
        },
        {
          label: "Tidak",
        },
      ],
    });
  };

  onPageChange = (page, limit) => {
    this.setState({ page }, () => {
      this.getAllZeroReports();
    });
  };

  onSearch = (e) => {
    const search = e.target.value;
    this.setState({ search }, () => {
      this.getAllZeroReports();
    });
  };

  render() {
    const { data, mode, loading, page } = this.state;
    return (
      <div className="dashboard-wrapper">
        {mode !== PageMode.view && (
          <div
            className="my-3"
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.changeMode(PageMode.view);
            }}
          >
            <ArrowLeft />
            <span>Kembali</span>
          </div>
        )}

        <h1 className="title font-weight-bold">Zero Reporting</h1>
        <div className="content-wrapper mt-4">
          {mode === PageMode.view && (
            <>
              <div className="filter-data">
                <div className="row justify-content-start py-3">
                  <div className="col-sm-6">
                    <form className="form-horizontal">
                      <div className="form-group row justify-content-start">
                        <label className="col-sm-3 col-form-label align-self-center">
                          Tahun Laporan
                        </label>
                        <div className="col-sm-4 align-self-center">
                          <Datetime
                            dateFormat="YYYY"
                            timeFormat={false}
                            closeOnClickOutside={true}
                            closeOnSelect={true}
                            onChange={(e) =>
                              this.setState({
                                selected_year: moment(e).format("YYYY"),
                              })
                            }
                            value={this.state.selected_year}
                            inputProps={{
                              name: "filter_year",
                              placeholder: "Tahun Laporan",
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group row justify-content-start">
                        <label className="col-sm-3 col-form-label align-self-center">
                          Bulan Laporan
                        </label>
                        <div className="col-sm-4 align-self-center">
                          <select
                            name="filter_month"
                            id="filter_month"
                            className="form-control"
                            onChange={(e) =>
                              this.setState({ selected_month: e.target.value })
                            }
                            value={this.state.selected_month}
                          >
                            <option value="">Pilih Bulan</option>
                            <option value="01">Januari</option>
                            <option value="02">Februari</option>
                            <option value="03">Maret</option>
                            <option value="04">April</option>
                            <option value="05">Mei</option>
                            <option value="06">Juni</option>
                            <option value="07">Juli</option>
                            <option value="08">Agustus</option>
                            <option value="09">September</option>
                            <option value="10">Oktober</option>
                            <option value="11">November</option>
                            <option value="12">Desember</option>
                          </select>
                        </div>
                      </div>
                      <div className="form-group row justify-content-start">
                        <div className="col-sm-7">
                          <button
                            className="btn btn-teal btn-block"
                            onClick={(e) => {
                              e.preventDefault();
                              this.onFilterData();
                            }}
                          >
                            Terapkan
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <Table
                data={data}
                loading={loading}
                currentPage={page}
                changeMode={this.changeMode}
                onEdit={this.onEdit}
                onRemove={this.onRemove}
                onSearch={this.onSearch}
                onPageChange={this.onPageChange}
                selectedMonth={this.state.selectedMonth}
                selectedYear={this.state.selectedYear}
                currentUser={this.props.user}
              />
            </>
          )}
          {(mode === PageMode.add || mode === PageMode.edit) && (
            <Form
              mode={mode}
              changeMode={this.changeMode}
              zeroReport={this.state.selecetdItem}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authReducer.currentUser,
  };
};

export default connect(mapStateToProps)(ZeroReporting);
