import React from "react";
import PropTypes from "prop-types";
import { CheckCircle, Edit, PlusCircle, Trash2, XCircle } from "react-feather";
import { Link } from "react-router-dom";
import { isEmpty } from "utils/common";
import { isNull } from "lodash";
import { FiEye } from "react-icons/fi";

const LEFT_PAGE = "LEFT";
const RIGHT_PAGE = "RIGHT";

const range = (from, to, step = 1) => {
  let i = from;
  const rangeArr = [];
  while (i <= to) {
    rangeArr.push(i);
    i += step;
  }
  return rangeArr;
};

const TableComponent = ({
  loading,
  data,
  headers,
  cols,
  btnEdit,
  btnRemove,
  btnPerformance,
  btnAchievment,
  btnApproval,
  btnIntervention,
  limit,
  currentPage,
  nextPage,
  total,
  onPageChange,
  urlPathPerformance,
  urlPathAchievment,
  urlPathIntervention,
  btnAddPeCase,
  withoutActionBtn,
  onGetFile,
  onGetLetter,
  onGetImage,
}) => {
  const actionBtn = (e) => (
    <div>
      {e.status !== "Sudah Terpenuhi" && btnEdit && (
        <button
          {...btnEdit}
          className={
            btnEdit.className ??
            "btn btn-outline-primary btn-sm font-weight-bold mr-2"
          }
          onClick={() => btnEdit.onClick(e)}
          style={{ cursor: "pointer" }}
        >
          <Edit size={15} /> {btnEdit.btnName ?? "Edit"}
        </button>
      )}

      {e.status !== "Sudah Terpenuhi" && btnRemove && (
        <button
          {...btnRemove}
          className={
            btnRemove.className ??
            "btn btn-outline-danger btn-sm font-weight-bold mr-2"
          }
          onClick={() => btnRemove.onClick(e.id)}
          style={{ cursor: "pointer" }}
        >
          <Trash2 size={15} /> {btnRemove.btnName ?? "Hapus"}
        </button>
      )}
      {btnPerformance && (
        <Link to={`${urlPathPerformance}/${e.id}`}>
          <button
            className="btn btn-outline-success btn-sm font-weight-bold mr-2"
            style={{ cursor: "pointer" }}
          >
            <PlusCircle size={15} /> Tambah Kinerja Kader
          </button>
        </Link>
      )}
      {btnAchievment && (
        <Link to={`${urlPathAchievment}/${e.id}`}>
          <button
            className="btn btn-outline-success btn-sm font-weight-bold mr-2"
            style={{ cursor: "pointer" }}
          >
            <PlusCircle size={15} /> Tambah data capaian
          </button>
        </Link>
      )}
      {e.anopheles_larva_found === "Ya" && btnIntervention && (
        <Link to={`${urlPathIntervention}/${e.id}`}>
          <button
            className="btn btn-outline-success btn-sm font-weight-bold mr-2"
            style={{ cursor: "pointer" }}
          >
            <PlusCircle size={15} /> Intervensi Perindukan Nyamuk
          </button>
        </Link>
      )}
      {btnAddPeCase && (
        <button
          className={`btn ${
            e.is_pe ? "btn-outline-primary" : "btn-outline-success"
          } btn-sm font-weight-bold`}
          onClick={() => btnAddPeCase.onClick(e)}
        >
          {
            e.is_pe ? <FiEye size={15} className="mr-1" /> : <PlusCircle size={15} className="mr-1" />
          }
          {
            e.is_pe ? "Lihat Data" : "Tambah PE Kasus"
          }
        </button>
      )}
      {btnApproval && (
        <>
          <button
            className="btn btn-outline-success btn-sm font-weight-bold mr-2"
            onClick={() => btnApproval.onClick(e.id, "Approved")}
            style={{ cursor: "pointer" }}
          >
            <CheckCircle size={15} /> Terima
          </button>
          <button
            className="btn btn-outline-danger btn-sm font-weight-bold mr-2"
            onClick={() => btnApproval.onClick(e.id)}
            style={{ cursor: "pointer" }}
          >
            <XCircle size={15} /> Tolak
          </button>
        </>
      )}
    </div>
  );

  const splitter = (e, delimiter, idx) => {
    if (isEmpty(e)) return "";
    return e?.split(delimiter)[idx];
  };

  const dateFormat = (e) => {
    return e?.split("T")[0];
  };

  const monthCoverter = (e) => {
    const month = [
      "Januari",
      "Februari",
      "Maret",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Agustus",
      "September",
      "Oktober",
      "November",
      "Desember",
    ];
    return month[e - 1];
  };

  const rows = () => {
    if (loading) {
      return (
        <tr>
          <td colSpan={headers.length + 1} className="text-center">
            loading...
          </td>
        </tr>
      );
    }
    if (data && data.length > 0) {
      return data.map((row, index) => {
        return (
          <tr key={index}>
            {!withoutActionBtn && <td>{actionBtn(row)}</td>}
            {cols.map((col, idx) => {
              return (
                <td key={idx}>
                  {Array.isArray(col) ? (
                    splitter(row[col[0]], col[1], col[2])
                  ) : col.includes("dob") ||
                    col.includes("sk_date") ||
                    col.includes("last_training_date") ? (
                    dateFormat(row[col])
                  ) : col.includes("month") ? (
                    monthCoverter(row[col])
                  ) : col === "is_active" ? (
                    <>
                      <span
                        className={`badge badge-${col ? "success" : "warning"}`}
                      >
                        {col ? "Aktif" : "Non aktif"}
                      </span>
                    </>
                  ) : col.includes(".") ? (
                    row[col?.split(".")[0]][col?.split(".")[1]]
                  ) : col === "letter_upload" ? (
                    <>
                      <a
                        href="#"
                        onClick={() => onGetFile(row.id, row.letter_upload)}
                      >
                        {row.letter_upload}
                      </a>
                    </>
                  ) : col === "fulfilled_letter_upload" ? (
                    <>
                      <a
                        href="#"
                        onClick={() =>
                          onGetLetter(row.id, row.fulfilled_letter_upload)
                        }
                      >
                        {row.fulfilled_letter_upload}
                      </a>
                    </>
                  ) : col === "image" ? (
                    <>
                      <a href="#" onClick={() => onGetImage(row.id, row.image)}>
                        {row.image}
                      </a>
                    </>
                  ) : (
                    row[col]
                  )}
                </td>
              );
            })}
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan={headers.length + 1} className="text-center">
            Tidak ada data
          </td>
        </tr>
      );
    }
  };

  const dataShowLength = () => {
    if (data) {
      if (data.length < limit) {
        return data.length;
      } else {
        return limit;
      }
    }
    return 0;
  };

  const fetchPageNumbers = () => {
    const pageNeighbours = 2;
    const totalPages = Math.ceil(total / limit);
    const totalNumbers = pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPage - pageNeighbours);
      const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);
      let pages = range(startPage, endPage);

      const hasLeftSpill = startPage > 2;
      const hasRightSpill = totalPages - endPage > 1;
      const spillOffset = totalNumbers - (pages.length + 1);

      if (hasLeftSpill && !hasRightSpill) {
        const extraPages = range(startPage - spillOffset, startPage - 1);
        pages = [LEFT_PAGE, ...extraPages, ...pages];
      } else if (!hasLeftSpill && hasRightSpill) {
        const extraPages = range(endPage + 1, endPage + spillOffset);
        pages = [...pages, ...extraPages, RIGHT_PAGE];
      } else {
        pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
      }
      return [1, ...pages, totalPages];
    }
    return range(1, totalPages);
  };

  const gotoPage = (page) => {
    const totalPage = Math.ceil(total / limit);
    const currentPage = Math.max(1, Math.min(page, totalPage));
    onPageChange(currentPage);
  };

  const handleClick = (page) => (evt) => {
    evt.preventDefault();
    gotoPage(page);
  };

  const handleMoveLeft = (evt) => {
    evt.preventDefault();
    gotoPage(currentPage - 5);
  };

  const handleMoveRight = (evt) => {
    evt.preventDefault();
    gotoPage(currentPage + 5);
  };

  return (
    <div className="table-responsive table-striped">
      <table className="table">
        <thead className="text-uppercase">
          <tr>
            {!withoutActionBtn && <th>Aksi</th>}
            {headers.map((item, idx) => {
              return <th key={idx}>{item}</th>;
            })}
          </tr>
        </thead>
        <tbody>{rows()}</tbody>
      </table>
      <div className="d-flex justify-content-between">
        <nav aria-label="Page navigation example">
          <ul className="pagination">
            {fetchPageNumbers().map((page, index) => {
              if (page === LEFT_PAGE) {
                return (
                  <li key={index} className="page-item">
                    <span
                      className="page-link"
                      aria-label="Previous"
                      onClick={handleMoveLeft}
                    >
                      <span aria-hidden="true">&laquo;</span>
                      <span className="sr-only">Previous</span>
                    </span>
                  </li>
                );
              } else if (page === RIGHT_PAGE) {
                return (
                  <li key={index} className="page-item">
                    <span
                      className="page-link"
                      aria-label="Next"
                      onClick={handleMoveRight}
                    >
                      <span aria-hidden="true">&raquo;</span>
                      <span className="sr-only">Next</span>
                    </span>
                  </li>
                );
              }

              return (
                <li
                  key={index}
                  className={`page-item${
                    currentPage === page ? " active" : ""
                  }`}
                >
                  <span className="page-link" onClick={handleClick(page)}>
                    {page}
                  </span>
                </li>
              );
            })}
          </ul>
        </nav>
        <div style={{ paddingTop: "8px" }}>
          Menampilkan {dataShowLength()} dari {total} data
        </div>
      </div>
    </div>
  );
};

TableComponent.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.array,
  cols: PropTypes.array,
  btnEdit: {
    btnName: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
  },
  btnRemove: {
    btnName: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
  },
  headers: PropTypes.array,
  limit: PropTypes.number,
  currentPage: PropTypes.number,
  nextPage: PropTypes.number,
  total: PropTypes.number,
};

export default TableComponent;
