import React from "react";
import "./style.css";

const AlertMessage = ({ message }) => {
  return (
    <div className="alert-wrapper">
      <p>{message}</p>
    </div>
  );
};

export default AlertMessage;
