import React from "react";
import { UserLevel } from "utils/constant";
import { tableColumnByLevel, tableColumns } from "./constants";

const TableComponent = ({
  currentLevel,
  reportsData,
  tableRef,
  reportInfo,
}) => {
  const calculateTotal = () => {
    return reportsData.reduce(
      (total, item) => {
        total.total_sasaran_bumil += parseInt(item.sasaran_bumil ?? 0);
        total.total_sasaran_balita += parseInt(item.sasaran_balita ?? 0);
        total.total_bumil_k1 += parseInt(item.bumil_k1 ?? 0);
        total.total_bumil_scren += parseInt(item.bumil_scren ?? 0);
        total.total_bumil_scren_kelambu += parseInt(
          item.bumil_scren_kelambu ?? 0
        );
        total.total_kunj_balita_sakit += parseInt(item.kunj_balita_sakit ?? 0);
        total.total_kunj_balita_sakit_perika_mal += parseInt(
          item.kunj_balita_sakit_perika_mal ?? 0
        );

        return total;
      },
      {
        total_sasaran_bumil: 0,
        total_sasaran_balita: 0,
        total_bumil_k1: 0,
        total_bumil_scren: 0,
        total_bumil_scren_kelambu: 0,
        total_bumil_scren_kelambu: 0,
        total_kunj_balita_sakit: 0,
        total_kunj_balita_sakit_perika_mal: 0,
      }
    );
  };

  let levelName = "";
  if (currentLevel === "national") {
    levelName = "Nasional";
  } else if (currentLevel === "province") {
    levelName = "Provinsi";
  } else if (currentLevel === "district") {
    levelName = "Kabupaten/Kota";
  } else {
    levelName = "Fasyankes";
  }

  let periodeName = "";

  if (reportInfo?.statusPeriode === "yearly") {
    periodeName = "Tahunan";
  } else {
    periodeName = "Bulanan";
  }

  let data = reportInfo?.fasyankes;
  let parts = data?.split(",");
  let faskesName = parts?.map(function (part) {
    return part?.split("|")[1];
  });
  let result = faskesName?.join(", ");

  const countData = (data, key) => {
    return data.length > 0
      ? data.reduce((total, item) => total + (parseInt(item[key]) ?? 0), 0)
      : 0;
  };

  return (
    <div
      className="table-table-responsive"
      style={{ height: "560px", overflow: "scroll" }}
      ref={tableRef}
    >
      <table>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none" }}>
            <h3>SISMAL v3, LOGISTIK ED/RUSAK</h3>
          </th>
        </tr>
      </table>
      <table>
        <thead>
          <tr style={{ border: "none" }}>
            <th
              width="200"
              style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}
            >
              Periode Laporan
            </th>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              : {periodeName}
            </th>
          </tr>
          <tr style={{ border: "none" }}>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              Tahun
            </th>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              : {reportInfo?.periode?.year}
            </th>
          </tr>
          <tr style={{ border: "none" }}>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              Bulan
            </th>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              : {reportInfo?.periode?.month}
            </th>
          </tr>
          <tr style={{ border: "none" }}>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              Level
            </th>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              : {levelName}
            </th>
          </tr>
          <tr style={{ border: "none" }}>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              Provinsi
            </th>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              :{" "}
              {!reportInfo?.province
                ? "Semua Provinsi"
                : reportInfo?.province?.split("|")[1]}
            </th>
          </tr>
          <tr style={{ border: "none" }}>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              Kab/Kota
            </th>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              :{" "}
              {!reportInfo?.district
                ? "Semua Kab/Kota"
                : reportInfo?.district?.split("|")[1]}
            </th>
          </tr>
          <tr style={{ border: "none" }}>
            <th style={{ border: "none", paddingTop: 0 }}>Fasyankes</th>
            <th style={{ border: "none", paddingTop: 0 }}>
              : {!reportInfo?.fasyankes ? "Semua Fasyankes" : result}
            </th>
          </tr>
        </thead>
      </table>
      {/* {renderTableByLevel()} */}
      <table>
        <thead>
          <tr>
            <th>No</th>
            {Object.keys(tableColumnByLevel(currentLevel)).map((key) => {
              return !Object.keys(tableColumns).includes(key) ? (
                <th>{key}</th>
              ) : (
                <th key={tableColumnByLevel(currentLevel)[key]}>{key}</th>
              );
            })}
            {/* {currentLevel === UserLevel.National.toLowerCase() && (
              <>
                <th rowSpan={2}>Provinsi</th>
                <th rowSpan={2}>Kabupaten/Kota</th>
              </>
            )}
            {(currentLevel === UserLevel.National.toLowerCase() ||
              currentLevel === UserLevel.Province.toLowerCase() ||
              currentLevel === UserLevel.District.toLowerCase()) && (
                <th rowSpan={2}>Jumlah Fasyankes</th>
              )}
            {currentLevel === UserLevel.Fasyankes.toLowerCase() && (
              <th rowSpan={2}>Fasyankes</th>
            )}
            <th colSpan={2}>
              Jumlah Faskes Melapor (Putus Stok / Tidak Putus Stok)
            </th>
            <th colSpan={2}>Jumlah Faskes Lebih Dari 7 Hari Putus Stok</th> */}
          </tr>
          {/* <tr>
            <th>DHP</th>
            <th>Primaquin</th>
          </tr> */}
        </thead>
        <tbody>
          {reportsData?.length > 0 &&
            reportsData?.map((item, index) => (
              <>
                <tr key={index}>
                  <td>{index + 1}</td>
                  {[UserLevel.Province.toLowerCase(), UserLevel.National.toLowerCase()].includes(
                    currentLevel
                  ) && <td>{item['parent_name']}</td>}
                  <td>{item['name']}</td>

                  <td key={'DHP Dispersible Rusak'}>{item['DHP Dispersible Rusak'] ?? 0}</td>
                  <td key={'DHP Dispersible Expired'}>{item['DHP Dispersible Expired'] ?? 0}</td>
                  <td key={'DHP (Tablet) Rusak'}>{item['DHP (Tablet) Rusak'] ?? 0}</td>
                  <td key={'DHP (Tablet) Expired'}>{item['DHP (Tablet) Expired'] ?? 0}</td>
                  <td key={'Primaquin (Tablet) Rusak'}>{item['Primaquin (Tablet) Rusak'] ?? 0}</td>
                  <td key={'Primaquin (Tablet) Expired'}>{item['Primaquin (Tablet) Expired'] ?? 0}</td>
                  <td key={'Artesunat Inj (Vial) Rusak'}>{item['Artesunat Inj (Vial) Rusak'] ?? 0}</td>
                  <td key={'Artesunat Inj (Vial) Expired'}>{item['Artesunat Inj (Vial) Expired'] ?? 0}</td>
                  <td key={'Kina (Tablet) Rusak'}>{item['Kina (Tablet) Rusak'] ?? 0}</td>
                  <td key={'Kina (Tablet) Expired'}>{item['Kina (Tablet) Expired'] ?? 0}</td>
                  <td key={'Kina Inj (Ampul) Rusak'}>{item['Kina Inj (Ampul) Rusak'] ?? 0}</td>
                  <td key={'Kina Inj (Ampul) Expired'}>{item['Kina Inj (Ampul) Expired'] ?? 0}</td>
                  <td key={'Doksisiklin (Tablet) Rusak'}>{item['Doksisiklin (Tablet) Rusak'] ?? 0}</td>
                  <td key={'Doksisiklin (Tablet) Expired'}>{item['Doksisiklin (Tablet) Expired'] ?? 0}</td>
                  <td key={'RDT (Test) Rusak'}>{item['RDT (Test) Rusak'] ?? 0}</td>
                  <td key={'RDT (Test) Expired'}>{item['RDT (Test) Expired'] ?? 0}</td>
                  <td key={'LLIN (Lembar) Rusak'}>{item['LLIN (Lembar) Rusak'] ?? 0}</td>
                  <td key={'LLIN (Lembar) Expired'}>{item['LLIN (Lembar) Expired'] ?? 0}</td>
                  <td key={'Insektisida (Kg) Rusak'}>{item['Insektisida (Kg) Rusak'] ?? 0}</td>
                  <td key={'Insektisida (Kg) Expired'}>{item['Insektisida (Kg) Expired'] ?? 0}</td>
                  <td key={'Insektisida (Liter) Rusak'}>{item['Insektisida (Liter) Rusak'] ?? 0}</td>
                  <td key={'Insektisida (Liter) Expired'}>{item['Insektisida (Liter) Expired'] ?? 0}</td>
                  <td key={'Larvasida (Kg) Rusak'}>{item['Larvasida (Kg) Rusak'] ?? 0}</td>
                  <td key={'Larvasida (Kg) Expired'}>{item['Larvasida (Kg) Expired'] ?? 0}</td>
                  <td key={'Larvasida (Liter) Rusak'}>{item['Larvasida (Liter) Rusak'] ?? 0}</td>
                  <td key={'Larvasida (Liter) Expired'}>{item['Larvasida (Liter) Expired'] ?? 0}</td>
                  <td key={'Lab Kit (Paket) Rusak'}>{item['Lab Kit (Paket) Rusak'] ?? 0}</td>
                  <td key={'Lab Kit (Paket) Expired'}>{item['Lab Kit (Paket) Expired'] ?? 0}</td>
                  <td key={'Giemsa (Botol) Rusak'}>{item['Giemsa (Botol) Rusak'] ?? 0}</td>
                  <td key={'Giemsa (Botol) Expired'}>{item['Giemsa (Botol) Expired'] ?? 0}</td>
                  <td key={'Giemsa (CC) Rusak'}>{item['Giemsa (CC) Rusak'] ?? 0}</td>
                  <td key={'Giemsa (CC) Expired'}>{item['Giemsa (CC) Expired'] ?? 0}</td>
                  <td key={'Minyak Imersi (Botol) Rusak'}>{item['Minyak Imersi (Botol) Rusak'] ?? 0}</td>
                  <td key={'Minyak Imersi (Botol) Expired'}>{item['Minyak Imersi (Botol) Expired'] ?? 0}</td>
                  <td key={'Minyak Imersi (CC) Rusak'}>{item['Minyak Imersi (CC) Rusak'] ?? 0}</td>
                  <td key={'Minyak Imersi (CC) Expired'}>{item['Minyak Imersi (CC) Expired'] ?? 0}</td>
                  <td key={'Blood Slide (Box) Rusak'}>{item['Blood Slide (Box) Rusak'] ?? 0}</td>
                  <td key={'Blood Slide (Box) Expired'}>{item['Blood Slide (Box) Expired'] ?? 0}</td>
                  <td key={'Slide Box (Buah) Rusak'}>{item['Slide Box (Buah) Rusak'] ?? 0}</td>
                  <td key={'Slide Box (Buah) Expired'}>{item['Slide Box (Buah) Expired'] ?? 0}</td>
                  <td key={'Blood Lancet (Box) Rusak'}>{item['Blood Lancet (Box) Rusak'] ?? 0}</td>
                  <td key={'Blood Lancet (Box) Expired'}>{item['Blood Lancet (Box) Expired'] ?? 0}</td>
                  <td key={'Methanol (CC) Rusak'}>{item['Methanol (CC) Rusak'] ?? 0}</td>
                  <td key={'Methanol (CC) Expired'}>{item['Methanol (CC) Expired'] ?? 0}</td>
                  <td key={'Methanol (Botol) Rusak'}>{item['Methanol (Botol) Rusak'] ?? 0}</td>
                  <td key={'Methanol (Botol) Expired'}>{item['Methanol (Botol) Expired'] ?? 0}</td>
                  <td key={'Spray Can (Buah) Rusak'}>{item['Spray Can (Buah) Rusak'] ?? 0}</td>
                  <td key={'Spray Can (Buah) Expired'}>{item['Spray Can (Buah) Expired'] ?? 0}</td>
                  <td key={'Mist Blower (Buah) Rusak'}>{item['Mist Blower (Buah) Rusak'] ?? 0}</td>
                  <td key={'Mist Blower (Buah) Expired'}>{item['Mist Blower (Buah) Expired'] ?? 0}</td>
                  <td key={'Microscope Binocular (Buah) Rusak'}>{item['Microscope Binocular (Buah) Rusak'] ?? 0}</td>
                  <td key={'Microscope Binocular (Buah) Expired'}>{item['Microscope Binocular (Buah) Expired'] ?? 0}</td>
                  <td key={'Microscope Trinocular (Buah) Rusak'}>{item['Microscope Trinocular (Buah) Rusak'] ?? 0}</td>
                  <td key={'Microscope Trinocular (Buah) Expired'}>{item['Microscope Trinocular (Buah) Expired'] ?? 0}</td>
                  <td key={'Microscope Teaching (Buah) Rusak'}>{item['Microscope Teaching (Buah) Rusak'] ?? 0}</td>
                  <td key={'Microscope Teaching (Buah) Expired'}>{item['Microscope Teaching (Buah) Expired'] ?? 0}</td>
                  {/* {Object.keys(tableColumnByLevel(currentLevel)).map((key) => (
                    <td key={tableColumnByLevel(currentLevel)[key]}>
                      {item[tableColumnByLevel(currentLevel)[key]] ?? 0}
                    </td>
                  ))} */}
                </tr>
                {(reportsData[index].parent_id !==
                  reportsData[index + 1]?.parent_id ||
                  index === reportsData.length - 1) &&
                  currentLevel === UserLevel.National.toLocaleLowerCase() && (
                    <tr style={{ backgroundColor: "#43766C" }}>
                      <td
                        style={{ color: "#FFF" }}
                        colSpan={
                          Object.keys(tableColumnByLevel(currentLevel)).filter(
                            (key) => !Object.keys(tableColumns).includes(key)
                          ).length + 1
                        }
                      >
                        {" "}
                        TOTAL{" " + item['name']?.split("_")[1]} 
                        {
                          reportsData.find(
                            (d) =>
                              d.id === d.parent_id && d.id === item.parent_id
                          )?.name
                        }
                      </td>
                      {Object.keys(tableColumns).map((key) => (
                        <td style={{ color: "#FFF" }} key={key}>
                          {reportsData
                            .filter((d) => d.parent_id === item.parent_id)
                            .reduce(
                              (a, b) => a + parseInt(b[tableColumns[key]] ?? 0),
                              0
                            )}
                        </td>
                      ))}
                    </tr>
                  )}
              </>
            ))}
          {reportsData?.length > 0 && (
            <tr style={{ backgroundColor: "#43766C" }}>
              <td
                style={{ color: "#FFF" }}
                colSpan={
                  Object.keys(tableColumnByLevel(currentLevel)).filter(
                    (key) => !Object.keys(tableColumns).includes(key)
                  ).length + 1
                }
              >
                {" "}
                TOTAL KESELURUHAN{" "}
              </td>
              {Object.keys(tableColumns).map((key) => (
                <td style={{ color: "#FFF" }} key={key}>
                  {reportsData.reduce(
                    (a, b) => a + (parseInt(b[tableColumns[key]] ?? "0") ?? 0),
                    0
                  )}
                </td>
              ))}
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TableComponent;
