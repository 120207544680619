import {
  GoogleMap,
  LoadScript,
  Marker,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import _ from "lodash";
import React, { Component } from "react";
import config from "../../utils/config";
export default class MapsComponents extends Component {
  state = {
    libraries: ["places"],
    markers: [
      {
        position: {
          lat: -6.2,
          lng: 106.816666,
        },
        key: "1",
      },
    ],
    // get lat lang from gps
    center: { lat: -6.2, lng: 106.816666 },
  };

  componentDidMount() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.setState({
          center: {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          },
          markers: [
            {
              position: {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              },
              key: "1",
            },
          ],
        });
      });
    }
  }

  onLoad = (ref) => (this.searchBox = ref);
  onPlacesChanged = () => {
    const places = this.searchBox.getPlaces();

    // Pass the selected location to parent
    const lnglat = JSON.stringify(places[0].geometry.location);
    const address = places[0].formatted_address;

    this.props.onChangeLocation(address, JSON.parse(lnglat));

    localStorage.setItem("coordinate", lnglat);
    localStorage.setItem("address", address);

    const markers = places?.map((place) => ({
      position: place.geometry.location,
    }));

    const center = _.get(markers, "0.position", this.state.center);

    this.setState({ markers, center });
  };

  render() {
    return (
      <LoadScript
        onUnmount={() => (this._isMounted = false)}
        googleMapsApiKey={config.googleMapApiKey}
        libraries={this.state.libraries}
      >
        <GoogleMap
          mapContainerStyle={{
            width: "100%",
            height: "30vh",
            borderRadius: "8px",
          }}
          center={
            this.state.center
          }
          zoom={15}
          onClick={
            // set coodinate to state
            (e) => {
              const lnglat = JSON.stringify(e.latLng);
              const address = "";

              this.props.onChangeLocation(address, JSON.parse(lnglat));

              localStorage.setItem("coordinate", lnglat);
              localStorage.setItem("address", address);

              this.setState({
                center: e.latLng,
                markers: [
                  {
                    position: e.latLng,
                  },
                ],
              });
            }
          }
        >
          <StandaloneSearchBox
            onLoad={this.onLoad}
            onPlacesChanged={this.onPlacesChanged}
          >
            <input
              className="form-control"
              type="text"
              placeholder="Cari Lokasi..."
              style={{
                border: "1px solid transparent",
                width: 300,
                boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
                textOverflow: "ellipses",
                position: "absolute",
                left: "50%",
                marginLeft: -120,
                marginTop: 7,
                borderRadius: "8px",
              }}
            />
          </StandaloneSearchBox>
          {this.state.markers?.map((marker, index) => (
            <Marker key={index} position={marker.position} />
          ))}
        </GoogleMap>
      </LoadScript>
    );
  }
}
