import React, { useEffect, useState } from "react";
import Button from "components/atoms/Button";
import InputRadio from "components/atoms/InputRadio";
import InputSelect from "components/atoms/InputSelect";
import { BreadcrumbNav } from "components/molecules";
import { ArrowDown, ArrowUp } from "react-feather";
import "./DetailLaporan.scss";
import InputDate from "components/atoms/InputDate";
import TableData from "./TableData";
import moment from "moment";
import Wilayah from "services/Wilayah";
import { Source, UserLevel } from "utils/constant";
import { useParams } from "react-router-dom";
import { getCurrentUserFromLocalStorage } from "utils/common";

const WilayahService = new Wilayah();

const DetailLaporan = () => {
  const params = useParams();
  const currentUser = getCurrentUserFromLocalStorage();
  const [provinciesOptions, setProvinciesOptions] = useState([]);

  const [showFilter, setShowFilter] = useState(true);
  const [selectPeriode, setSelectPeriode] = useState("");
  const [periodeValue, setPeriodeValue] = useState();
  const [selectLevel, setSelectedLevel] = useState(currentUser.level);
  const [levelValue, setLevelValue] = useState();
  const [selectedYear, setSelectedYear] = useState("");
  const [module, setModule] = useState(params.module);
  const [showData, setShowData] = useState(false);

  const [selectProvince, setSelectProvince] = useState({ disabled: true, value: currentUser.province_id });
  const [selectDistrict, setSelectDistrict] = useState({ disabled: true, value: currentUser.district_id });
  const [selectFasyankes, setSelectFasyankes] = useState({ disabled: true, value: currentUser.fasyankes_id });

  const getWeekNumber = (date) => {
    const d = new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    );
    const dayNum = d.getUTCDay() || 7;
    d.setUTCDate(d.getUTCDate() + 4 - dayNum);
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    return Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${day}-${month}-${year}`;
  };

  const generateWeeks = (year) => {
    const weeks = [];
    const startDate = new Date(year, 0, 1);
    const endDate = new Date(year, 11, 31);
    const currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      const weekStartDate = new Date(currentDate);
      const weekEndDate = new Date(currentDate);
      weekEndDate.setDate(currentDate.getDate() + 6);

      const weekLabel = `Minggu ${getWeekNumber(currentDate)}`;
      const weekRange = `${formatDate(weekStartDate)} - ${formatDate(
        weekEndDate
      )}`;

      weeks.push({ label: weekLabel, value: weekRange });

      currentDate.setDate(currentDate.getDate() + 7);
    }

    return weeks;
  };

  const renderPeriodeFilter = () => {
    if (selectPeriode === "Tahunan") {
      return <InputDate
        isRequired={true}
        onChange={e => setPeriodeValue({ year: moment(e).format("YYYY"), month: '' })}
        placeholder={"Pilih Tahun"} dateFormat={"YYYY"} />;
    } else if (selectPeriode === "Bulanan") {
      return (
        <div className="filter-condition">
          <InputDate
            isRequired={true}
            onChange={e => setPeriodeValue({ year: moment(e).format("YYYY"), month: moment(e).format("MM") })}
            placeholder={"Pilih Bulan"} dateFormat={"YYYY-MM"} />
        </div>
      );
    } else if (selectPeriode === "Mingguan") {
      const weeks = generateWeeks(selectedYear);
      if (selectedYear) {
        return (
          <div className="filter-condition">
            <InputDate
              placeholder={"Pilih Tahun"}
              dateFormat={"YYYY"}
              value={selectedYear}
              onChange={(e) => {
                setSelectedYear(moment(e).format("YYYY"));
              }}
            />
            <InputSelect
              placeholder={"Pilih Minggu"}
              data={weeks.map((week) => ({
                label: `${week.label} ${week.value}`,
                value: week.value,
              }))}
            />
          </div>
        );
      } else {
        return (
          <div className="filter-condition">
            <InputDate
              placeholder={"Pilih Tahun"}
              dateFormat={"YYYY"}
              value={selectedYear}
              onChange={(e) => {
                setSelectedYear(moment(e).format("YYYY"));
              }}
            />
          </div>
        );
      }
    } else if (selectPeriode === "Harian") {
      return (
        <div className="filter-condition">
          <InputDate label={"Dari"} placeholder={"Pilih Tanggal"} />
          <InputDate label={"Sampai"} placeholder={"Pilih Tanggal"} />
        </div>
      );
    } else {
      return null;
    }
  };

  const renderLevelFilter = () => {
    if (selectLevel === "Provinsi") {
      return (
        <InputSelect
          placeholder={"pilih Provinsi"}
          data={provinciesOptions}
        />
      );
    } else if (selectLevel === "Kabupaten") {
      return (
        <div className="filter-condition">
          <InputSelect
            placeholder={"Pilih Provinsi"}
            data={[
              { label: "Provinsi 1", value: "Provinsi 1" },
              { label: "Provinsi 2", value: "Provinsi 2" },
            ]}
          />
          <InputSelect
            placeholder={"Pilih Kab/Kota"}
            data={[
              { label: "Kabupaten 1", value: "Kabupaten 1" },
              { label: "Kabupaten 2", value: "Kabupaten 2" },
            ]}
          />
        </div>
      );
    } else if (selectLevel === "Fasyankes") {
      return (
        <div className="filter-condition">
          <InputSelect
            disabled={selectProvince.disabled}
            placeholder={"Pilih Provinsi"}
            value={{ label: selectProvince.value?.split("|")[1], value: selectProvince.value }}
          />
          <InputSelect
            disabled={selectDistrict.disabled}
            placeholder={"Pilih Kab/Kota"}
            value={{ label: selectDistrict.value?.split("|")[1], value: selectDistrict.value }}
          />
          <InputSelect
            disabled={selectFasyankes.disabled}
            placeholder={"Pilih Fasyankes"}
            value={{ label: selectFasyankes.value?.split("|")[1], value: selectFasyankes.value }}
          />
        </div>
      );
    } else {
      return null;
    }
  };

  const handleApplyFilter = () => {
    setShowData(true);
  };

  const getAllProvincies = async () => {
    try {
      const response = await WilayahService.getAll(Source.PROVINCE, {
        page: 1,
        limit: 999,
      });
      setProvinciesOptions(
        response.province.map((province) => {
          return {
            label: province.name,
            value: province.id,
          };
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (currentUser.level == UserLevel.Fasyankes) {
      setLevelValue(currentUser.fasyankes_id);
    } else if (currentUser.level == UserLevel.District) {
      setLevelValue(currentUser.district_id);
    } else if (currentUser.level == UserLevel.Province) {
      setLevelValue(currentUser.province_id);
    }

    getAllProvincies();
  }, []);

  useEffect(() => {

  }, []);


  return (
    <div className="dashboard-wrapper">
      <BreadcrumbNav
        items={[
          { label: "Laporan", link: "/laporan" },
          { label: module, link: "#" },
        ]}
      />

      {/* Filter Laporan */}
      <div className="filter">
        <div className="filter-header">
          <p>FILTER</p>
          <button
            className="btn_toggle_filter"
            onClick={() => setShowFilter(!showFilter)}
          >
            {showFilter ? <ArrowUp size={18} /> : <ArrowDown size={18} />}
          </button>
        </div>
        {showFilter && (
          <div className="filter-body">
            <div className="filter-item">
              <div className="filter-label">
                <p>Periode Laporan</p>
              </div>
              <div className="filter-field">
                <InputRadio
                  isRequired={true}
                  options={[
                    { label: "Tahunan", value: "Tahunan" },
                    { label: "Bulanan", value: "Bulanan" },
                    // { label: "Mingguan", value: "Mingguan" },
                    // { label: "Harian", value: "Harian" },
                  ]}
                  onChange={(e) => setSelectPeriode(e.target.value)}
                  selectedOption={selectPeriode}
                />
                <div className="advanced-filter">{renderPeriodeFilter()}</div>
              </div>
            </div>
            <div className="filter-item">
              <div className="filter-label">
                <p>Level</p>
              </div>
              <div className="filter-field">
                <InputRadio
                  value={{ level: selectLevel, value: selectLevel }}
                  options={[
                    // { label: "Nasional", value: "Nasional" },
                    // { label: "Provinsi", value: "Provinsi" },
                    // { label: "Kabupaten", value: "Kabupaten" },
                    { label: "Fasyankes", value: "Fasyankes" },
                  ]}
                  onChange={(e) => setSelectedLevel(e.target.value)}
                  selectedOption={selectLevel}
                />
                <div className="advanced-filter">{renderLevelFilter()}</div>
              </div>
            </div>
            <div className="filter-item">
              <div className="filter-field">
                <div className="advanced-filter">
                  <div className="filter-condition">
                    <Button variant={"primary"} onClick={() => handleApplyFilter()} size={"normal"}>
                      Terapkan
                    </Button>
                    <Button
                      variant={"secondary"}
                      size={"normal"}
                      style={{
                        border: "1px solid #18B3AB",
                      }}
                    >
                      Export Ke CSV
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Data */}
      {showData && (
        <div className="table-container">
          <div className="data-title">
            <h2>{module}</h2>
          </div>
          <div className="data-main">
            <TableData
              module={module}
              periode={{ periode: selectPeriode, value: periodeValue }}
              level={{ level: selectLevel, value: levelValue }} />
          </div>
        </div>
      )}
    </div>
  );
};

export default DetailLaporan;
